import util from '../../utilities/sharedUtilities'

export default {
  getMultiEditActionsFor (objectClass) {
    return this.sendRequest('/gorilla/' + objectClass + '/actions', [], [])
  },

  setMultiEditAction (objectClass, actionIdentifier, ids) {
    return this.sendPutRequest('/api/' + objectClass + '/' + actionIdentifier,
      [], ids.map(id => {
        return {
          key: 'ids[]',
          value: id
        }
      })
    )
  },
}
