<template>
  <v-container>
    <v-row no-gutters>
      <v-col
        cols="12"
        class="text-center"
      >
        <div
          v-for="(item, i) in Array(7)"
          :key="i"
          class="ma-1 week-box"
          :color="[5, 6].includes(i) ? 'grey lighten-1' : '#FF9800'"
          :style="styleRow1(i)"
        >
          {{ i === 3 ? '3' : '' }}
        </div>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
      >
        <div
          v-for="(item, i) in Array(7)"
          :key="i"
          class="ma-1 week-box"
          :color="[5, 6].includes(i) ? 'grey lighten-1' : '#FF9800'"
          :style="styleRow2(i)"
        >
          {{ i === 1 ? '7' : i === 0 ? '2' : '' }}
        </div>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
      >
        <div
          v-for="(item, i) in Array(7)"
          :key="i"
          class="ma-1 week-box"
          :color="[5, 6].includes(i) ? 'grey lighten-1' : '#FF9800'"
          :style="styleRow3(i)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'WeeksChart',

  methods: {
    styleRow1 (i) {
      const style = {}
      return {
        background: [5, 6].includes(i)
          ? '#EEEEEE'
          : [3].includes(i)
              ? '#FF9800'
              : '#A5D6A7',
      }
    },
    styleRow2 (i) {
      const style = {}
      return {
        background: [5, 6].includes(i)
          ? '#EEEEEE'
          : [0, 1].includes(i)
              ? '#FF9800'
              : '#A5D6A7'
      }
    },
    styleRow3 (i) {
      const style = {}
      return {
        background: [4, 5, 6].includes(i)
          ? '#EEEEEE'
          : [].includes(i)
              ? '#FF9800'
              : '#A5D6A7',
        border: i === 3 ? '2px solid #F44336' : '',
      }
    },
  },
}
</script>

<style>
.week-box {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  text-align: center;
  line-height: 25px;
}
.week-box:before {
  content: "\200B";
}
.week-box:after {
  content: "\200B";
}
</style>
