import Vue from 'vue'
import { updateField } from 'vuex-map-fields'
import dashboardMutations from '@/store/dashboardItems/mutations'

// Get sort direction for a list field
// Checks also what are current sort conditions
// Called when user has clicked on field sort button
const getFieldSortDirection = (state, { fieldName, secondary }) => {
  if (secondary && state.secondarySortings[fieldName]) {
    return state.secondarySortings[fieldName].sortBy === 'asc'
      ? 'desc'
      : 'asc'
  } else {
    // If already sorted by that field, return reverse order
    if (state.sortField === fieldName) {
      return state.sortBy === 'asc' ? 'desc' : 'asc'
    }
  }
  const field = state.layoutProfileItems
    .filter(profile => profile.name === fieldName)[0]
  return (field && ['date', 'datetime'].includes(field.type)) ? 'desc' : 'asc'
}

// Get breakdown search ids from url
// If there is some, it's used to show breakdown with only these items
// TODO - use Vue router to get query params
const getBreakdownSearchIds = () => {
  const query = window.location.hash.split('?')[1]
  let ids = []
  if (query) {
    query.split('&').forEach(value => {
      if (value.split('=')[0] === '_unique_ids') {
        // state.listBreakdownSearchIds = value.split('=')[1].split(',')
        //  .map(val => parseInt(val))
        ids = value.split('=')[1].split(',').map(i => parseInt(i))
      }
    })
  }
  return ids
}

export default {
  updateField,
  ...dashboardMutations,

  updateBreakdownSearchIds: (state) => {
    // Get breakdown search id's from url
    // Can use ONLY Vue Router "after hooks" when everything is in Vue.js
    const ids = getBreakdownSearchIds()
    if (JSON.stringify(ids) !== JSON.stringify(state.listBreakdownSearchIds)) {
      state.listBreakdownSearchIds = ids
    }
  },

  getAssociationForFromUri: (state) => {
    // TODO - change when will be using Vue router
    const urlAfterFor = window.location.hash.split('/for/')[1]
    if (urlAfterFor) {
      const [targetResources,
        targetId,
        targetAssoc
      ] = urlAfterFor.split('/')
      state.listTargetResources = targetResources
      state.listTargetId = targetId
      state.listTargetAssoc = targetAssoc
    } else {
      state.listTargetResources = null
      state.listTargetId = null
      state.listTargetAssoc = null
    }
  },

  hideMenuPopups: (state) => {
    state.showDatePickerMenu = false
    state.showHasManyListMenu = false
    state.showContentFullTextMenu = false
    state.showHeaderColumnStatesMenu = false
    state.showHeaderColumnUniqueValuesMenu = false
    state.listColumnMenuField = false
    // Vue.set(state.layoutProfileMenuProps, 'showDialog', false)
    // Vue.set(state.layoutProfileFormProps, 'showDialog', false)
    state.showHeaderActionsActionsMenu = false
    state.showListColumnsSelector = false
    state.showListTextLanesMenuMenu = false
    state.showHeaderActionsDownloadMenu = false
    state.showItemPicker = false
    // state.multiEditColumn = '' <- don't hide this, user can accidentaly scroll while editing text
    state.listHeaderDateFilterField = ''
  },

  resetListMeta: (state) => {
    Vue.set(state, 'metaInfo', {})
    Vue.set(state, 'layoutProfiles', [])
    Vue.set(state, 'selectedLayoutProfileId', null)
    // Vue.set(state, 'layoutProfile', [])
    Vue.set(state, 'layoutProfileItems', [])
    Vue.set(state, 'listFieldsByName', {})
    Vue.set(state, 'selectedItems', [])
    Vue.set(state, 'offsetItems', 0)
    Vue.set(state, 'scrollLeft', 0)
    Vue.set(state, 'fixedScrolledLeft', 0)
    Vue.set(state, 'scrolledTop', 0)
    Vue.set(state, 'listFilters', [])
    Vue.set(state, 'forbiddenListModels', [])
    Vue.set(state, 'superSearchTerm', null)
  },

  resetLayoutProfile: (state) => {
    // Vue.set(state, 'layoutProfile', [])
    Vue.set(state, 'layoutProfileItems', [])
    Vue.set(state, 'listFieldsByName', {})
    Vue.set(state, 'selectedItems', [])
    Vue.set(state, 'offsetItems', 0)
    Vue.set(state, 'scrolledLeft', 0)
    Vue.set(state, 'fixedScrolledLeft', 0)
    Vue.set(state, 'scrolledTop', 0)
    Vue.set(state, 'listFilters', [])
    Vue.set(state, 'superSearchTerm', null)
    Vue.set(state, 'cellPool', [])
  },

  resetListData: (state) => {
    state.loaderShowComplete = false
    // state.selectedId = null
    state.listVisibleItems = []
    state.listItems = []
    state.showListRefreshButton = false
    state.hoverRowIndex = null
    state.rowPool = []
    state.renderedAreaNumber = null
    state.firstItemIndex = 0
    state.lastItemIndex = 19
  },

  resetColumnWidths: (state) => {
    state.layoutProfileItems.forEach((column, index) => {
      Vue.set(state.layoutProfileItems[index], 'forcedWidth', null)
    })
  },

  updateColumnWidth: (state, { columnIndex, width }) => {
    Vue.set(state.layoutProfileItems[columnIndex], 'forcedWidth', width)
  },

  // Update list sort condition for a field
  updateSortField: (state, { fieldName, secondary, direction }) => {
    Vue.set(state, 'listItems', [])
    const sortBy = direction || getFieldSortDirection(state, {
      secondary,
      fieldName
    })
    if (secondary) {
      const sorting = {
        sortBy,
        sortField: fieldName
      }
      Vue.set(state.secondarySortings, fieldName, sorting)
      // Important to set first sortBy, because it uses previous sort field to decide order by
      Vue.set(state, 'sortField2', fieldName)
    } else {
      Vue.set(state, 'sortBy', sortBy)
      // Important to set first sortBy, because it uses previous sort field to decide order by
      Vue.set(state, 'sortField', fieldName)
      // Reset secondary sorting
      Vue.set(state, 'secondarySortings', {})
    }
  },

  resetListPageItemsBeforeFirstPageLoad: (state) => {
    state.listItems = []
    state.pagesRetrieved = []
    state.listHeaderDateFilterField = null
    state.showListRefreshButton = false
    // Reset scrolledTop and scrolling direction
    // so that items with new search hash are loaded correctly
    state.scrolledTop = 0
    state.scrollingDirection = 'down'
  },

  // Stores list items in the store for one loaded "virtual" page/patch
  updateListPageItems: (state, payload) => {
    let items = []
    // Check that listLoadHash is present and not changed
    if (payload.listLoadHash && state.listLoadHash !== payload.listLoadHash) {
      // Prevent saving list items because user has changed filters
      // opened another Layout Profile or switched module
      return
    }
    // Set items length = total if length is empty now
    if (state.listItems.length === 0) {
      // Create list items with length as total count returned
      // this will result full table scroll
      // Whole list should be refreshed, clear pages retrieved
      Vue.set(state, 'listItems', [...Array(payload.total)])
    }
    items = state.listItems
    payload.items.forEach((item, index) => {
      items[index + payload.offset] = item
    })
    Vue.set(state, 'listItems', items)
    state.totalLoadRequestsProcessed++
  },

  updateListTotalCount: (state, count) => {
    Vue.set(state, 'listTotalCount', count)
  },

  setFilterField: (state, payload) => {
    Vue.set(state.listFilters, payload.filterField, payload.filterValue)
  },

  resetListCount: (state) => {
    Vue.set(state, 'listTotalCount', null)
  },

  updateValues: (state, data) => {
    Object.keys(data).forEach(key => {
      const value = data[key]
      Vue.set(state, key, value)
    })
  },

  updateObjectStates: (state, payload) => {
    Vue.set(state.objectStatesByClass, payload.className, payload.states)
  },

  setSplitModeAltered: (state) => {
    state.splitModeAltered = true
  }
}
