export default {
  cardViewInfos: {},
  cardItems: [],
  cardColumns: [],
  cardDraggedItemColumn: null,
  selectedColumn: null,
  selectionData: null,
  cardActionItem: null,
  maxCardsOnColumn: 100,
}
