<template>
  <v-container
    class="pb-0 pr-0 pl-0 pt-0"
    style="height: 100%;"
    fluid
  >
    <v-dialog
      v-model="KPIModalDialog"
      fullscreen
      hide-overlay
      transition="expand-x-transition"
    >
      <KPI_Modal
        v-if="KPIModalDialog"
        :open-index="modalChartIndex"
        @close="KPIModalDialog = false"
      />
    </v-dialog>
    <v-row
      cols="12"
      class="my-0"
    >
      <DashboardTabs :show-shortcuts="showShortcutsOnTabsRow" />
    </v-row>
    <KPI
      v-if="$store.state.selectedDashboardTabId === 'kpi'"
      @showModal="showModalChart"
    />
    <DashboardItems
      v-else
      :show-shortcuts="!showShortcutsOnTabsRow"
    />
  </v-container>
</template>

<script>
import DashboardItems from './DashboardItems'
import KPI from './KPI/KPI'
import KPI_Modal from './KPI/KPI_Modal'
import DashboardTabs from './DashboardTabs'

export default {
  name: 'DashboardContainer',

  components: {
    DashboardTabs,
    KPI_Modal,
    KPI,
    DashboardItems
  },

  data () {
    return {
      dashboardItems: [],
      loading: false,
      KPIModalDialog: false,
      modalChartIndex: 0,
    }
  },

  computed: {
    showShortcutsOnTabsRow () {
      // Check if there is enough space to fit tabs and shortcuts to a single row
      const minPxForOneElement = 230
      return (this.$store.state.innerWidth / (this.$store.state.shortcuts.length + this.$store.state.dashboardTabs.length)) > minPxForOneElement
    },
  },

  methods: {
    showModalChart (index) {
      this.modalChartIndex = index
      this.KPIModalDialog = true
    },
  },
}
</script>

<style>
.dashboard_app {
  padding: 50px 7px 7px 7px;
  font-family: 'Open Sans', sans-serif;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #898989;
  font-size: 13px !important;
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
}

.dashboard_app a {
  color: #898989 !important;
  text-decoration: none;
}
</style>
