import i18n from '@/locale/i18n'
import { DashboardTypes } from '@/types/DashboardTypes'

export default {
  shortcutColor (shortcut: DashboardTypes.Shortcut) {
    if (!shortcut || !shortcut.details) {
      return 'rgba(153, 153, 153, 0.7)'
    } else if (shortcut.details.page_type === 'index') {
      return '#F57F17'
    } else if (shortcut.details.page_type === 'edit') {
      return 'rgba(38, 163, 94, 0.7)'
    } else if (shortcut.details.page_type === 'show') {
      return 'rgba(153, 153, 153, 0.7)'
    } else if (shortcut.details.link_type === 'external') {
      return '#7986CB'
    }
    return 'rgba(153, 153, 153, 0.7)'
  },

  shortcutIcon (shortcut: DashboardTypes.Shortcut) {
    if (!shortcut.details) {
      return 'list-ul'
    } else if (shortcut.details.page_type === 'index') {
      return 'folder-open'
    } else if (shortcut.details.page_type === 'edit' && parseInt(shortcut.details.item_id) > 0) {
      return 'pencil-alt'
    } else if (shortcut.details.page_type === 'edit') {
      return 'plus'
    } else if (shortcut.details.page_type === 'show') {
      return 'file-alt'
    } else if (shortcut.details.link_type === 'external') {
      return 'external-link-alt'
    }
    return 'list-ul'
  },

  parseShortcutParts (data: string) {
    if (!data) {
      return {}
    }
    const parts = data.split('|')
    const shortcut: DashboardTypes.ShortcutDetails = {
      link_type: parts[0],
      page_type: parts[1],
      object_name: parts[2],
      item_id: parts[3],
      item_summary: parts[4],
      title: parts[5],
      external_link: parts[6],
    }
    return shortcut
  },

  genShortcutLink (data) {
    const shortcut = this.parseShortcutParts(data)
    const itemId = parseInt(shortcut.item_id)

    switch (shortcut.page_type) {
      case 'index':
        return `#${shortcut.object_name}?_lp=${shortcut.item_id}`

      case 'edit':
        return itemId
          ? `#${shortcut.object_name}/${shortcut.item_id}/edit`
          : `#${shortcut.object_name}/new`

      default:
        return itemId
          ? `#${shortcut.object_name}/${shortcut.item_id}`
          : `#${shortcut.object_name}`
    }
  },

  generateShortcutTitle (data) {
    const shortcut = this.parseShortcutParts(data)
    if (shortcut.link_type === 'external') {
      return shortcut.title
    }

    // layout profile
    if (shortcut.page_type === 'index') {
      return shortcut.item_summary + ' (' + i18n.t(shortcut.object_name + '.list.title') + ')'
    }

    // item view
    else if (parseInt(shortcut.item_id) > 0) {
      return shortcut.item_summary + ' (' + i18n.t(shortcut.object_name + '.show.title') + ')'
    }

    // new form
    else if (shortcut.page_type === 'edit') {
      return i18n.t(shortcut.object_name + '.show.title')
    }

    // list view
    else {
      return i18n.t(shortcut.object_name + '.list.title')
    }
  },

  getShortcutKeys (shortcuts) {
    const keys = {}
    shortcuts.forEach((shortcut_data) => {
      const key = this.getShortcutKey(shortcut_data.element_action)
      if (key) {
        keys[key] = shortcut_data.id
      }
    })
    return keys
  },

  getShortcutKey (identifier: string) {
    const shortcut = this.parseShortcutParts(identifier)
    if (shortcut.link_type === 'internal') {
      return shortcut.page_type + '_' + shortcut.object_name + '_' + shortcut.item_id
    }
    return ''
  }
}
