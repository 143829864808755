import axios, { AxiosError, AxiosResponse, RawAxiosRequestHeaders } from 'axios'
import store from './../store'
import Raven from 'raven-js'
import qs from 'qs'

type keyVal = {
  key?: string,
  value?: string,
}

export const networkErrorAxiosResponse = (error: AxiosError): AxiosResponse => {
  return {
    data: null,
    status: 503,
    statusText: 'Service Unavailable (network/timeout)',
    headers: {},
    config: error.config!,
  }
}

// TODO-2023 any type
const getFiltersQuery = (filters: object | keyVal[]) => {
  const filtersQuery = !Array.isArray(filters)
    ? Object.keys(filters).map(key => key + '=' + filters[key])
      .join('&')
    : filters.map(filter => filter.key + '=' + filter.value
    ).join('&')
  return filtersQuery
}
export default {
  sendPostRequest (apiUrl: string, parameters: any): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      const payloadParameters = new URLSearchParams()
      if (Array.isArray(parameters)) {
        parameters.forEach((parameter) => {
          payloadParameters.append(parameter.key, parameter.value)
        })
      } else {
        Object.keys(parameters).forEach(key => {
          payloadParameters.append(key, parameters[key])
        })
      }
      axios.post(apiUrl,
        payloadParameters, this.requestOptions()).then((response) => {
        resolve(response)
      }).catch((error: AxiosError) => {
        resolve(error.response || networkErrorAxiosResponse(error))
      })
    })
  },

  // TODO-2023 any type
  sendPostRequestForMultiEdit (apiUrl: string, data: any): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      const formData = {}
      data.forEach(parameter => {
        formData[parameter.key] = parameter.value
      })
      axios.post(apiUrl,
        formData, this.requestOptions()).then((response) => {
        resolve(response)
      }).catch((error: AxiosError) => {
        resolve(error.response || networkErrorAxiosResponse(error))
      })
    })
  },

  sendPostRequestFormData (apiUrl: string, data: any): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      const formData = qs.stringify(data)
      axios.post(apiUrl,
        formData, this.requestOptions()).then((response) => {
        resolve(response)
      }).catch((error: AxiosError) => {
        resolve(error.response || networkErrorAxiosResponse(error))
      })
    })
  },

  sendRequest (apiUrl: string, filters: object | [], queries: string[], path = ''): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      const filtersQuery = getFiltersQuery(filters)
      const url = apiUrl + '?' + path +
        filtersQuery + '&' +
        queries.map(query => 'q[]=' + query).join('&')

      axios.get(url, this.requestOptions()).then((response: AxiosResponse) => {
        Raven.captureMessage('API no response data', {
          level: 'error',
          extra: {
            apiUrl,
            filters,
            queries,
            response,
          }
        })
        resolve(response)
      }).catch((error: AxiosError) => {
        Raven.captureMessage('API error captured', {
          level: 'error',
          extra: {
            apiUrl,
            filters,
            queries,
            error
          }
        })
        resolve(error.response || networkErrorAxiosResponse(error))
      })
    })
  },

  requestOptions (): { headers: RawAxiosRequestHeaders } {
    const acceptLanguage: string = store.state.locale
    return {
      headers: {
        'Auth-Required': true,
        'Accept-Language': acceptLanguage,
        'X-Requested-With': 'XMLHttpRequest',
        'X-Portal-ID': 'aava',
        'X-Portal-Name': 'vue', // Now for PDF report special handling
      }
    }
  },

  sendSimplePostRequest (apiUrl: string, parameters: any): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      axios.post(apiUrl,
        parameters, this.requestOptions()).then((response) => {
        resolve(response)
      }).catch((error: AxiosError) => {
        resolve(error.response || networkErrorAxiosResponse(error))
      })
    })
  },

  sendSimplePutRequest (apiUrl: string, parameters: any): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      axios.put(apiUrl, parameters, this.requestOptions())
        .then((response) => {
          resolve(response)
        }).catch((error: AxiosError) => {
          resolve(error.response || networkErrorAxiosResponse(error))
        })
    })
  },

  // TODO-2023 any type
  sendPutWithPayloadRequest (apiUrl: string, queries: any, parameters: any): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      let payloadParameters = {}
      if (Array.isArray(parameters)) {
        parameters.forEach((parameter) => {
          payloadParameters[parameter.key] = parameter.value
        })
      } else {
        payloadParameters = parameters
      }
      const url = apiUrl + '?' + queries
        .map(query => typeof query === 'string'
          ? 'q[]=' + query
          : query.key + '=' + query.value
        )
        .join('&')
      axios.put(url, payloadParameters, this.requestOptions())
        .then((response) => {
          resolve(response)
        }).catch((error: AxiosError) => {
          // Need to send error.response?.data
          // Example to handle data.redirect_to when status is 'error'
          // Update 16.02.23 - Still need error.response (without .data),
          // Because error code 406 is needed to show event-aciton-confirmation
          resolve(error.response || networkErrorAxiosResponse(error))
        })
    })
  },

  // TODO-2023 any type
  sendPutRequest (apiUrl: string, queries: any, parameters: any): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      const payloadParameters = new URLSearchParams()
      parameters.forEach((parameter) => {
        payloadParameters.append(parameter.key, parameter.value)
      })
      const url = apiUrl + '?' + queries.map(query => query.key + '=' + query.value).join('&')
      axios.put(url, payloadParameters, this.requestOptions())
        .then((response) => {
          resolve(response)
        }).catch((error: AxiosError) => {
          resolve(error.response || networkErrorAxiosResponse(error))
        })
    })
  },

  // TODO-2023 any type
  sendDeleteRequest (apiUrl: string, parameters: any): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      const url = apiUrl + '?' + parameters.map(parameter => parameter.key + '=' + parameter.value).join('&')
      axios.delete(url)
        .then((response) => {
          resolve(response)
        }).catch((error: AxiosError) => {
          resolve(error.response || networkErrorAxiosResponse(error))
        })
    })
  },
}
