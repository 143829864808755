export default {
  itemLayoutProfilesByModel: {},
  selectedLayoutProfileIdByModel: {},
  waitingItemLayoutProfileChangeByModel: {},
  itemScrolledTop: 0,
  // Item layout containers (attribute and field-set groups) which are collapsed
  // when attributes are grouped dynamically based on attribute_group field (layout-containers have not been created)
  collapsedVirtualContainers: {},
  dfcRequestsComingFromTimer: 0,
  resizeTrigger: 0,
  queueJobKeys: [],
  currentJobKey: null,
  supportedListEditTypes: [
    'quantity',
    'decimal',
    'string',
    'dynamic_list',
    'static_list',
    // 'search_string',
    'text',
    'reference',
    'date',
    'datetime',
    'numeric',
    'price',
    'percentage',
    // 'richtext'
    // 'link'
    // 'state',
    'process',
    // 'address',
    // 'process_events',
    'boolean',
    'polymorphic_autocomplete',
  ],
  focusableFieldTypes: [
    'quantity',
    'string',
    'search_string',
    'text',
    'reference',
    'date',
    'datetime',
    'numeric',
    'price',
    'percentage',
    // 'richtext'
    // 'link'
    // 'boolean'
    // 'polymorphic_autocomplete',
  ],
}
