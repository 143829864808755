// Complete list item data
// For Vue to consider item as reactive, all properties must be set here
const completeListItemData = (item) => {
  item.selected = false
  return item
}

export default {
  completeListItemsData (listItems) {
    return listItems.map(item => {
      return completeListItemData(item)
    })
  },

  completeListItemData,
}
