<template>
  <div
    v-if="showContentFullTextMenu"
    id="content-full-text-menu"
    class="popup-menu content-full-text-menu elevation-5 list-table"
    :style="style"
    v-html="content"
  />
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ContentFullTextMenu',

  data () {
    return {
      content: null,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    style () {
      let maxWidth = 800
      let maxHeight = 600
      const containerEl = document.getElementById('vueList')
      if (maxWidth > containerEl.clientWidth - 20) {
        maxWidth = containerEl.clientWidth - 20
      }
      if (maxHeight > containerEl.clientHeight - 20) {
        maxHeight = containerEl.clientHeight - 20
      }
      return {
        maxWidth: maxWidth + 'px',
        maxHeight: maxHeight + 'px',
      }
    }
  },

  watch: {
    showContentFullTextMenu (cellKey) {
      if (!cellKey) { return }
      const el = document.getElementById(this.hoverCell)
      if (!el) { return }
      this.content = el?.querySelector('.cell-div')?.innerHTML
    }
  },

  created () {
    document.addEventListener('click', this.clickListener)
  },

  destroyed () {
    document.removeEventListener('click', this.clickListener)
  },

  methods: {
    clickListener () {
      this.showContentFullTextMenu = false
    }
  }
}
</script>

<style lang="scss">
.content-full-text-menu {
  display: block !important;
  min-width: 200px;
  overflow: auto;
  font-weight: 500;
  padding: 8px !important;
}
</style>
