<template>
  <div
    v-html="text"
  />
</template>

<script>

export default {
  name: 'CardViewItemRow',

  props: {
    text: {
      type: String,
      default: () => null,
    },
  },
}
</script>
