import { LP } from '../../types/LP.types'

interface LayoutContainersByLayoutProfile {
  [layoutProfileId: number]: LP.Container[],
}
interface FieldSetsByLayoutProfile {
  [layoutProfileId: number]: LP.FieldSet[],
}

export default {
  layoutProfileIdFromUri: null,
  layoutProfiles: [] as LP.LayoutProfile[],
  layoutContainers: {} as LayoutContainersByLayoutProfile, // by Layout Profile id
  layoutContainerFieldSets: {} as FieldSetsByLayoutProfile, // by Layout Profile id
  selectedLayoutProfileId: null,
  selectedLayoutProfile: null as LP.LayoutProfile | null,
  draggingLayoutProfileIndex: null,
  draggingLayoutProfile: {},
  layoutProfileTemplates: [],
  newProfileJustCreated: false,
  newItemProfileJustCreated: false,
  visibleLayoutProfileTabsCount: 100,
  // Trigger to calculate LP tabs count on all forms.
  // There is only one list, but there can be multiple item forms open at the same time,
  // therefore also visibleItemLayoutProfileTabsCount is set inside ItemToolbar component
  triggerToCalculateVisibleItemLayoutProfileTabs: 0,
  focusedField: null,
  focusedFieldBefore: null,
  listFieldsByName: {},
  listColumnMenuField: null,
  draggingFieldName: null,
  resizingFieldName: null,
  resizingFieldWidthBefore: null,
  resizingHasManyField: null,
  showHeaderColumnStatesMenu: false,
  showHeaderColumnUniqueValuesMenu: false,
  visibleColumnsByName: {},
  multiEditColumn: '',
  loadingLayoutProfiles: true,
  lastLayoutProfileSwapTimestamp: null,
  supportsCardView: false,
  cardViews: [],
  viewOptions: {}
}
