<template>
  <div class="nose-notification-editor">
    <div v-if="noSeLoading">
      <v-progress-linear
        :indeterminate="true"
        height="3"
        color="teal"
      />
    </div>

    <template v-else>
      <v-toolbar dense color="grey lighten-4">
        <v-btn
          :small="theme.button.small"
          :dark="theme.button.dark"
          :rounded="theme.button.round"
          :depressed="theme.button.depressed"
          :color="colors.buttonNeutral"
          class="mr-3"
          @click="close"
        >
          <v-icon small>
            fa fa-arrow-left
          </v-icon>
          <span class="no-se-button-text">{{ $i18n.t('aava.actions.cancel') }}</span>
        </v-btn>
        <v-btn
          :small="theme.button.small"
          :dark="!!saveable && theme.button.dark"
          :rounded="theme.button.round"
          :depressed="theme.button.depressed"
          :color="colors.button"
          :disabled="!saveable"
          @click="saveAndClose"
        >
          <v-icon small>
            fa fa-save
          </v-icon>
          <span class="no-se-button-text">{{ $i18n.t('aava.actions.save') }}</span>
        </v-btn>
      </v-toolbar>
      <v-expansion-panels
        v-model="panel"
      >
        <v-expansion-panel>
          <v-expansion-panel-header :class="nameClass">
            <v-icon
              class="no-se-panel-header-icon"
              :color="nameIconColor"
            >
              icon{{ nameIcon }}
            </v-icon>
            <div> {{ nameHeader }} </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            :class="nameClass"
            expand-icon="fa fa-angle-down"
          >
            <v-container class="no-se-panel" fluid>
              <v-row>
                <v-col
                  cols="6"
                  class="pr-4"
                >
                  <v-text-field
                    v-model="item.identifier"
                    :class="nameFieldClass"
                    :disabled="disabled"
                    :label="$i18n.t('aava.no_se.identifier')"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="item.description"
                    :label="$i18n.t('aava.no_se.description')"
                    class="no-se-input"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header :class="nameClass">
            <v-icon
              class="no-se-panel-header-icon "
              :color="typeIconColor"
            >
              {{ typeIcon }}
            </v-icon>
            <div
              class="nose-type-help-tooltip"
            >
              {{ typeHeader }}
              <v-icon
                class="ml-2"
                small
                v-tooltip.bottom="$i18n.t('aava.no_se.tooltips.type')"
              >
                fa-regular fa-question-circle
              </v-icon>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            :class="typeClass"
            expand-icon="fa fa-angle-down"
          >
            <v-container class="no-se-panel" fluid>
              <v-row>
                <v-col cols="6">
                  <v-radio-group
                    v-model="item.type"
                    :class="typeFieldClass"
                    row
                  >
                    <v-radio
                      :label="$i18n.t('aava.no_se.type_event')"
                      on-icon="fa-regular fa-dot-circle"
                      off-icon="fa-regular fa-circle"
                      :disabled="!supportsEvents"
                      value="event"
                    />
                    <v-radio
                      :label="$i18n.t('aava.no_se.type_group')"
                      on-icon="fa-regular fa-dot-circle"
                      off-icon="fa-regular fa-circle"
                      value="group"
                    />
                  </v-radio-group>
                </v-col>
                <v-col
                  v-if="item.type === 'event'"
                  cols="6"
                >
                  <div
                    id="no-se-event-selection"
                    class="no-se-input"
                  >
                    <v-select
                      ref="actionSelect"
                      v-model="item.event"
                      :items="classEvents"
                      :label="$i18n.t('aava.no_se.select_event')"
                      :class="eventFieldClass"
                    />
                  </div>
                </v-col>
                <template v-else-if="item.type === 'group'">
                  <v-flex xs6>
                    <div
                      id="no-se-view-selection"
                      class="no-se-input"
                    >
                      <v-select
                        ref="viewSelect"
                        v-model="item.layoutProfile"
                        :items="classLayoutProfiles"
                        :label="$i18n.t('aava.no_se.select_view')"
                        :class="layoutProfileFieldClass"
                      />
                    </div>
                  </v-flex>
                  <v-flex
                    xs6
                    class="pr-4"
                  >
                    <div
                      class="no-se-input"
                    >
                      <v-text-field
                        v-model="item.checkInterval"
                        :label="$i18n.t('aava.no_se.check_interval')"
                      />
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <div
                      class="no-se-input"
                    >
                      <v-text-field
                        v-model="item.maxNotifications"
                        :label="$i18n.t('aava.no_se.max_notifications')"
                      />
                    </div>
                  </v-flex>
                </template>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header
            :class="rulesClass"
          >
            <v-icon
              class="no-se-panel-header-icon"
              :color="rulesIconColor"
            >
              {{ rulesIcon }}
            </v-icon>
            <div
              class="nose-rules-help-tooltip"
            >
              {{ rulesHeader }}
              <v-icon
                class="ml-2"
                small
                v-tooltip.bottom="$i18n.t('aava.no_se.tooltips.rules')"
              >
                fa-regular fa-question-circle
              </v-icon>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            expand-icon="fa fa-angle-down"
          >
            <v-container class="no-se-panel" fluid>
              <v-row
                v-if="showRules"
                class="___no-se-rules-block"
                wrap
              >
                <v-col cols="6">
                  <div class="no-se-rules-container">
                    <no-se-rule
                      v-for="(rule, index) in noSeEditedRules"
                      :key="index"
                      :ref="rule.key"
                      :data="rule"
                      @removeRuleRow="removeRuleRow"
                      @focusRuleRow="focusRuleRow"
                      @addRuleRow="addRuleRow"
                    />
                  </div>
                </v-col>
                <v-col cols="6">
                  <no-se-attribute-picker
                    :data="{ attributes: attributes, fixedAttributes: fixedAttributes, objectClass: objectClass }"
                    @attributeSelected="addAttributeToRuleCursor"
                  />
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="noSeEditedRuleCombination"
                    :label="ruleCombinationLabel()"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon
              class="no-se-panel-header-icon"
              :color="messagesIconColor"
            >
              {{ messagesIcon }}
            </v-icon>
            <div
              class="nose-message-help-tooltip"
            >
              {{ messageHeader }}
              <v-icon
                class="ml-2"
                small
                v-tooltip.bottom="$i18n.t('aava.no_se.tooltips.message')"
              >
                fa-regular fa-question-circle
              </v-icon>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            :class="messagesClass"
          >
            <v-container class="no-se-panel" fluid>
              <no-se-message
                v-for="(message, index) in messages"
                :key="index"
                :data="message"
                @removeMessageRow="removeMessageRow"
              />
              <v-btn
                v-if="supportMultipleMessages"
                fab
                small
                @click="addMessageRow"
              >
                <v-icon>fa fa-plus</v-icon>
              </v-btn>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import NoSeRule from './NoSeRule'
import NoSeMessage from './NoSeMessage'
import NoSeAttributePicker from './NoSeAttributePicker'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'NoSeNotificationEditor',

  components: {
    NoSeRule,
    NoSeMessage,
    NoSeAttributePicker
  },

  data () {
    return {
      panel: 0,
      focusedRuleRow: null,
      supportMultipleMessages: false, // for now, perhaps later
      ruleAttributesShown: false,
      showRules: true
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    item: {
      get () {
        return this.$store.state.noSeEditedNotification
      },
      set (value) {
        this.$store.commit('noSeEditedNotification', value)
      }
    },

    messages: {
      get () {
        return this.$store.state.noSeEditedMessages
      },
      set (value) {
        this.$store.commit('noSeEditedMessages', value)
      }
    },

    disabled () {
      return !!this.item.id
    },

    attributes () {
      return this.$store.state.noSeAttributes[this.objectClass]
    },

    recipients () {
      return this.$store.state.noSeRecipients[this.objectClass]
    },

    fixedAttributes () {
      return this.$store.state.noSeFixedAttributes
    },

    objectClass () {
      return this.$store.state.objectClass
    },

    supportsEvents () {
      return this.$store.state.noSeActions[this.objectClass] != null
    },

    classEvents () {
      if (!this.$store.state.noSeActions[this.objectClass]) {
        return []
      }
      return this.$store.state.noSeActions[this.objectClass].map((action) => {
        let translation = ''
        if (action.process_name) {
          translation = action.process_name + ' - ' +
            this.$i18n.t(this.objectClass + '.process_events.' + action.process + '.' + action.event)
        } else {
          translation = this.$i18n.t('aava.no_se.action_events.' + action.event)
        }

        return {
          text: translation,
          value: { process_event: action.event, object_process: action.process },
        }
      })
    },

    classLayoutProfiles () {
      return this.$store.state.layoutProfiles.map((lp) => {
        return {
          text: lp.name,
          value: { id: lp.id, name: lp.name }
        }
      })
    },

    nameReady () {
      return this.item && this.item.identifier && this.item.identifier.length > 0
    },

    nameIcon () {
      return 'fa fa-check'
      // return this.nameReady ? "fa fa-check" :  "fa fa-ban"
    },

    nameIconColor () {
      return this.nameReady ? 'green lighten-2' : this.colorForNotCompletedPanel(0)
    },

    typeFieldReady () {
      return this.item && this.item.type
    },

    typeReady () {
      if (!this.typeFieldReady) { return false }
      if (this.item.type === 'event' && !this.item.event) { return false }
      if (this.item.type === 'group' && !this.item.layoutProfile) { return false }
      return true
    },

    typeIcon () {
      return 'fa fa-check'
      // return this.typeReady ? "fa fa-check" :  "fa fa-ban"
    },

    typeIconColor () {
      return this.typeReady ? 'green lighten-2' : this.colorForNotCompletedPanel(1)
    },

    typeClass () {
      return this.typeReady ? 'nose-panel-ready' : 'nose-panel-not-ready'
    },

    typeHeader () {
      if (this.panel === 1) {
        return this.$i18n.t('aava.no_se.type')
      } else {
        let typeString = ''
        if (this.item.type === 'event') {
          typeString = this.$i18n.t('aava.no_se.type_event')
        } else if (this.item.type === 'event') {
          typeString = this.$i18n.t('aava.no_se.type_group')
        }
        return this.$i18n.t('aava.no_se.type') + ': ' + typeString
      }
    },

    typeFieldClass () {
      return (!this.typeFieldReady ? 'no-se-missing-field' : '')
    },

    eventFieldClass () {
      return (this.item && this.item.event ? '' : 'no-se-missing-field')
    },

    layoutProfileFieldClass () {
      return (this.item && this.item.layoutProfile ? '' : 'no-se-missing-field')
    },

    nameFieldClass () {
      return 'no-se-input' + (!this.nameReady ? ' no-se-missing-field' : '')
    },

    saveable () {
      return this.nameReady && this.typeReady && this.propertiesReady && this.messagesReady
    },

    propertiesReady () {
      if (this.item.type === 'event') {
        return !!this.item.event
      } else if (this.item.type === 'group') {
        return !!this.item.layoutProfile
      } else {
        return false
      }
    },

    messagesReady () {
      return this.messages?.length &&
        this.messages[0].recipients?.length &&
        this.messages[0].subject?.length
    },

    rulesHeader () {
      return this.$i18n.t('aava.no_se.rules')
    },

    rulesReady () {
      return this.noSeEditedRules.length > 0
    },

    rulesIcon () {
      return 'fa fa-check'
    },

    rulesIconColor () {
      return this.rulesReady ? 'green lighten-2' : this.colorForNotCompletedPanel(2)
    },

    rulesClass () {
      return 'nose-panel-ready'
    },

    messageHeader () {
      return this.$i18n.t('aava.no_se.messages')
    },

    messagesIcon () {
      return 'fa fa-check'
    },

    messagesIconColor () {
      return this.messagesReady ? 'green lighten-2' : this.colorForNotCompletedPanel(3)
    },

    messagesClass () {
      return this.messagesReady ? 'nose-panel-ready' : 'nose-panel-not-ready'
    },

    nameHeader () {
      if (this.panel === 0) {
        return this.$i18n.t('aava.no_se.identifier')
      } else {
        const identifier = this.item.identifier ? ': ' + this.item.identifier : ''
        return this.$i18n.t('aava.no_se.identifier') + identifier
      }
    },

    nameClass () {
      return this.nameReady ? 'nose-panel-ready' : 'nose-panel-not-ready'
    }
  },

  watch: {
    noSeEditItemId (id) {
      this.$store.dispatch('getNoSeItemForEdit', id)
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      this.$store.dispatch('getNoSeItemForEdit', this.noSeEditItemId).then(() => {
        // this.noSeEditing = true
      })
      if (this.messages.length === 0) {
        this.addMessageRow()
      }
      if (this.noSeEditedRules.length === 0) {
        this.addRuleRow(false)
      }
    },

    colorForNotCompletedPanel (panelIndex) {
      return panelIndex === this.panel === panelIndex ? 'orange lighten-2' : 'grey lighten-1'
    },

    // rule cursor is either in the row that last had the focus, or the
    // last row if none of the rows has had focus
    addAttributeToRuleCursor (attribute) {
      const focusedRow = this.focusedRuleRow || Object.keys(this.$refs).slice(-1)
      const child = this.$refs[focusedRow]
      if (!child) { return }

      child[0].addAttributeToCursor(attribute)
    },

    addFixedAttributeToRuleCursor (attribute) {
      const focusedRow = this.focusedRuleRow || Object.keys(this.$refs).slice(-1)
      const child = this.$refs[focusedRow]
      if (!child) { return }

      child[0].addAttributeToCursor(attribute)
    },

    attributeTranslation (attribute) {
      return this.$i18n.t(this.$store.state.objectClass + '.attributes.' + attribute)
    },

    fixedAttributeTranslation (attribute) {
      return this.$i18n.t('aava.no_se.fixed_attributes.' + attribute)
    },

    saveAndClose () {
      if (!this.item.id) {
        this.$store.dispatch('noSeCreate').then(() => {
          this.close()
        })
      } else {
        this.$store.dispatch('noSeUpdate').then(() => {
          this.close()
        })
      }
    },

    close () {
      this.noSeEditItemId = null
      this.noSeViewComponent = 'my'
    },

    addRuleRow (removable) {
      this.noSeEditedRules.push({ key: Math.random().toString(36).substring(2), value: [], removable: removable })
    },

    removeRuleRow (key) {
      const index = this.noSeEditedRules.findIndex(item => item.key === key)
      this.noSeEditedRules.splice(index, 1)

      // deep magic to get the rule rows to update correctly after one is removed
      this.$nextTick(() => {
        this.showRules = false
        this.$nextTick(() => {
          this.showRules = true
        })
      })
    },

    focusRuleRow (key) {
      this.focusedRuleRow = key
    },

    addMessageRow () {
      this.messages.push({ key: Math.random().toString(36), recipients: ['me'], subject: [], text: [], type: 'email' })
    },

    removeMessageRow (key) {
      const index = this.messages.findIndex(item => item.key === key)
      this.messages.splice(index, 1)
    },

    ruleCombinationLabel () {
      if (this.noSeEditedRuleCombination === true) {
        return this.$i18n.t('aava.no_se.rule_combination_or')
      } else {
        return this.$i18n.t('aava.no_se.rule_combination_and')
      }
    },
  }
}
</script>

<style lang="less">
.nose-notification-editor {
  .v-label {
    font-weight: inherit;
  }
  .v-expansion-panel {
    box-shadow: none;
  }
  .v-expansion-panel-header {
    background: #F5F5F5;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  .v-expansion-panel-container--active {
    .v-expansion-panel-header {
      background: #EEEEEE;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .v-select .dropdown-menu {
    display: block;
  }
  font-size: initial;
  .v-input {
    input, textarea, .v-select-selection {
      font-size: initial;
    }
  }
  .no-se-missing-field {
    color: #EF6C00 !important;
    label {
      color: #EF6C00 !important;
    }
  }
  .no-se-button-text {
    margin-left: 8px;
  }
  .no-se-rules-block {
    display:block
  }
  .no-se-rules-container {
    width: 100%;
    vertical-align: top;
    display:inline-block;
    padding-right: 10px;
  }
  .no-se-rules-attributes-container {
    vertical-align: top;
    max-width: 600px;
    display:inline-block
  }
  .no-se-panel-header-icon {
    max-width: 24px;
    margin-right: 10px;
  }
  .no-se-help-icon {
    position: relative;
    bottom: 0.5em;
    font-size: 0.8em;
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel--active+.v-expansion-panel, .v-expansion-panel--active:not(:first-child) {
    margin-top: 0;
  }
}
.no-se-fix {
  font-family: 'Courier New', Courier, monospace;
  }
</style>
