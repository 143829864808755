<template>
  <v-toolbar
    class="actions-row-container elevation-0"
    height="40"
    width="100%"
    text
    absolute
  >
    <div
      v-if="!loadingList && listTotalCount !== null"
      class="list-count mr-2"
    >
      <div class="list-count-title">
        {{ listTotalCount }} {{ $i18n.t('aava.general.matches', 'en') }}
      </div>
    </div>
    <!-- v-if="showListRefreshButton" -->
    <ListRefreshBtn @reloadListItems="reloadListItems" />
    <!-- SwitchField
      v-model="listEdit.editing"
      :label="$i18n.t('aava.buttons.editing')"
      class="ml-5 mt-1"
    / -->
    <v-spacer />
    <ListHiddenFilters v-if="selectedItems.length === 0" />
    <HeaderActionsSingleSelectActions v-if="selectedItems.length === 1" />
    <HeaderActionsSelectionButtons v-if="selectedItems.length > 0" />
    <v-spacer />
    <ListClearSearchButton v-if="temporaryFiltersSet && selectedItems.length === 0" />
    <ListSuperSearch
      v-if="showSuperSearch"
      @reloadListItems="reloadListItems"
    />
    <ListColumnsSelector v-if="showColumnsSelector" />
    <ListMetaActionsMenu />
    <ListDownloadMenu />
    <ListTextLanesMenu v-if="showTextLanesSelector" />
  </v-toolbar>
</template>

<script>
import ListDownloadMenu from '@/components/ListHeaderActions/ListDownloadMenu'
import ListMetaActionsMenu from '@/components/ListHeaderActions/ListMetaActionsMenu'
import ListTextLanesMenu from '@/components/ListHeaderActions/ListTextLanesMenu'
import ListClearSearchButton from './ListClearSearchButton'
import ListRefreshBtn from './ListRefreshBtn'
import HeaderActionsSelectionButtons from './HeaderActionsSelectionButtons'
import ListSuperSearch from './ListSuperSearch'
import ListHiddenFilters from './ListHiddenFilters'
import ListColumnsSelector from './ListColumnsSelector'
import HeaderActionsSingleSelectActions from './HeaderActionsSingleSelectActions'

import { createHelpers } from 'vuex-map-fields'
import methods from '@/components/methods'
import SwitchField from '@/components/Form/SwitchField'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ListActionsToolbar',

  components: {
    ListColumnsSelector,
    ListTextLanesMenu,
    ListDownloadMenu,
    ListMetaActionsMenu,
    ListClearSearchButton,
    ListRefreshBtn,
    HeaderActionsSelectionButtons,
    ListSuperSearch,
    ListHiddenFilters,
    HeaderActionsSingleSelectActions,
  },

  computed: {
    ...mapFields([
      'loadingList',
      'listTotalCount',
      'selectedItems',
      'splitMode',
      'showListRefreshButton',
      'listEdit',
    ]),

    showColumnsSelector () {
      const locked = this.$store.state.selectedLayoutProfile.locked
      return !locked && !(this.selectedItems.length > 0 && this.splitMode === 'vertical') && !this.$vuetify.breakpoint.xsOnly
    },

    showTextLanesSelector () {
      return !(this.selectedItems.length > 0 && this.splitMode === 'vertical') && !this.$vuetify.breakpoint.xsOnly
    },

    showSuperSearch () {
      return this.selectedItems.length === 0 && this.splitMode !== 'vertical' && !this.$vuetify.breakpoint.xsOnly
    },

    temporaryFiltersSet () {
      return this.$store.getters.temporaryFiltersSet
    },
  },

  methods: {
    ...methods,
  },
}
</script>

<style lang="scss">
.actions-row-container {
  margin-top: 3px;
  max-height: 45px;
  background: var(--list_actions_row_background_color) !important;
  z-index: 2 !important;
  /* overflow: hidden; can't hide as hides also menus */
  .v-btn {
    color: #777 !important;
    font-weight: 400 !important;
  }
}
.list-count {
  line-height: 34px;
}
.list-count-title {
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.icon-right-space {
  padding-right: 10px;
}
.v-popup-menu {
  z-index: 1050;
  width: 100%;
}
</style>
