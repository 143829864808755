import listViewAPI from '@/store/api'
import Confirm from '@/methods/confirm'
import { AxiosResponse } from 'axios'
import { EventResponse, RedirectTo } from '@/types/API.responses'
import { MetaAction } from '@/types/LP.types'

const interpolate = (str, idOrIds) => {
  if (Array.isArray(idOrIds)) {
    const idParams = idOrIds.map(id => 'ids[]=' + id).join('&')
    return str.replace('%ids%', idParams)
  } else {
    return str.replace('%id%', idOrIds)
  }
}

export default {
  confirmSendAction (action) {
    if (action?.query?.type === 'list') { // Skip for sub-menu
      return
    }
    const selectedCount = this.getSelectedIds().length
    if (action.confirmation_limit && selectedCount >= parseInt(action.confirmation_limit)) {
      Confirm.request(this.$i18n.t('aava.actions.confirmation'), () => {
        this.sendItemMetaAction({ action, view: 'index' })
      })
    } else {
      this.sendItemMetaAction({ action, view: 'index' })
    }
  },

  runApiActionWithValue (action, value) {
    this.runApiAction(action, this.getSelectedIds(), value)
  },

  runApiAction (action: MetaAction, ids = [], value: string) {
    if (action?.requires_ids && ids.length < 1) {
      this.$store.dispatch('addFlashMessage', {
        message: this.$i18n.t('aava.index.actions.no_selection'),
        type: 'error',
      }).then()
      return
    }
    const params: any = { ids }
    if (value && action.query) {
      params.value = {}
      params.value[action.query.identifier] = value
    }

    if (action.query?.type === 'string') {
      const query_value = prompt(action.query.label, '')
      if (query_value === null) { return }
      params.value = {}
      params.value[action.query.identifier] = query_value
    }

    listViewAPI.sendApiActionCall(action.resource, action.identifier, params)
      .then((response: AxiosResponse<EventResponse>) => {
        this.runningAction = false
        if (this.closeMenu) { // List menu
          this.closeMenu()
        }
        // Do not reload when a file was downloaded
        if (response.data && !response.data.binary_content) {
          this.redirectFromMetaAction(response.data?.redirect_to)
        }
        this.showApiActionMessages(response)
      })
  },

  showApiActionMessages (response: AxiosResponse<EventResponse>) {
    const messages: { message: string, type: string }[] = []
    if (response.data.items?.length) {
      messages.push({
        message: this.$i18n.t('aava.index.messages.items_executed',
          { items: response.data.items.length }),
        type: 'info',
      })
    }

    if (response.data.messages) {
      const messageType = response.data.status === 'ok' ? 'info' : 'error'
      response.data.messages.forEach(element => {
        messages.push({
          message: element,
          type: messageType,
        })
      })
    }

    this.$store.dispatch('addFlashMessages', messages).then()
  },

  sendItemMetaAction ({
    action,
    view, // index or item
    value,
  }: {
    action: MetaAction,
    view: string,
    value: string,
  }) {
    // Special case to clear cache
    // so user can see the app from another user point of view immediately
    if (action?.identifier === 'act_as_user_action') {
      this.clearCache()
    }
    if (this.closeMenu) {
      this.closeMenu()
    }
    const idOrIds = this.item?.id || this.getSelectedIds()
    if (action.method === 'api') {
      return this.runApiAction(action, idOrIds, value)
    }
    this.runningAction = true
    if (action.event) {
      listViewAPI.sendListEventCall('/api/' + this.objectClass + '/' + action.event, idOrIds)
        .then((response: AxiosResponse) => {
          this.showExecuteMessages(response)
          this.runningAction = false
          this.$store.dispatch('clearSelected')
        })
    } else if (action.action) {
      const code = interpolate(action.action, idOrIds)
      this.runActionCode(code)
      if (view === 'index') {
        this.runningAction = false
      }
    } else if (action.url && action.method?.toLowerCase() === 'post') {
      let url = action.url
      if (action.query) {
        const query = prompt(action.query.title, action.query.default)
        if (query === null) { return }
        url = action.url + '?' + action.query.identifier + '=' + encodeURIComponent(query)
      }
      listViewAPI.sendListActionCall(interpolate(url, idOrIds))
        .then((response: AxiosResponse) => {
        // TODO Should it always run redirectFromMetaAction on index? and if no redirect then refresh?
          this.redirectFromMetaAction(response.data.redirect_to)
          // Show message, if present
          if (view === 'index') {
            this.runningAction = false
          }
          this.showExecuteMessages(response)
        })
    } else if (action.url && action.method?.toLowerCase() === 'get') {
      window.open(interpolate(action.url, idOrIds), '_blank')
      if (view === 'index') {
        this.runningAction = false
      }
    }
  },

  redirectFromMetaAction (redirectTo: RedirectTo) {
    if (!redirectTo?.resource) {
      // If no redirect_to just do a refresh
      setTimeout(() => {
        location.reload()
      }, 1500) // Give some time to display messages
      return
    }
    this.handleRedirectToFromResponse(redirectTo)
  },
}
