<template>
  <div :style="containerStyle">
    <div v-if="type === 'text'">
      <div
        :style="titleStyle"
        class="card-item-title-text"
        v-html="value"
      />
    </div>
    <div v-else-if="type === 'icon' && value != null">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="action"
            :style="titleStyle"
            :action="action"
            small
            v-on="on"
            @click.stop="itemAction()"
          >
            fa-{{ valueComputed }}
          </v-icon>
          <v-icon
            v-else
            small
            :style="titleStyle"
            v-on="on"
          >
            fa-{{ valueComputed }}
          </v-icon>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </div>
    <div
      v-else-if="type === 'image' && value != null"
    >
      <v-spacer />
      <v-img
        :style="titleStyle"
        :src="'/content/attachments/' + value + '/icon.png'"
        class="card-view-item-title-image"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardViewItemTitleItem',

  props: {
    item: {
      type: Object,
      default: () => null,
    },
    titleItem: {
      type: Object,
      default: () => null,
    },
  },

  data () {
    return {
      uid: '',
    }
  },

  computed: {
    type () {
      return Object.keys(this.titleItem)[0]
    },

    valueComputed () {
      return this.value.replace('clock-o', 'clock') // new font awesome version
    },

    action () {
      const item = this.item[Object.values(this.titleItem)[0]]
      if (!item) { return null }
      if (typeof (item) === 'object') {
        return item.action
      }
      return ''
    },

    value () {
      const item = this.item[Object.values(this.titleItem)[0]]
      if (!item) { return null }
      if (typeof (item) === 'object') {
        return item.value
      } else {
        return item
      }
    },

    titleStyle () {
      const item = this.item[Object.values(this.titleItem)[0]]
      if (!item) { return null }
      if (typeof (item) === 'object') {
        return item.style
      } else {
        return ''
      }
    },

    containerStyle () {
      return {
        float: this.type === 'icon' ? '' : 'left',
        paddingRight: this.type === 'icon' ? '' : '5px',
      }
    },

    tooltip () {
      const item = this.item[Object.values(this.titleItem)[0]]
      if (!item) { return null }
      if (typeof (item) === 'object') {
        return item.tooltip
      } else {
        return ''
      }
    },
  },

  created () {
    this.uid = Math.random().toString(36).substring(7)
  },

  methods: {
    itemAction (e) {
      this.$emit('actionClicked', this.action)
    },
  },
}
</script>

<style>
.card-item-title {
  font-weight: 600;
}
.card-view-item-title-image {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-right: 4px;
}

.fa-beat {
  animation:fa-beat 5s ease infinite;
}
@keyframes fa-beat {
  0% {
    transform:scale(1);
  }
  5% {
    transform:scale(1.25);
  }
  20% {
    transform:scale(1);
  }
  30% {
    transform:scale(1);
  }
  35% {
    transform:scale(1.25);
  }
  50% {
    transform:scale(1);
  }
  55% {
    transform:scale(1.25);
  }
  70% {
    transform:scale(1);
  }
}
</style>

<style>
.card-item-title-text {
  font-weight: 700;
  cursor: pointer;
}
</style>
