const topBuffer = 100
const bottomBuffer = 100

// Get top offset items count
// these items should not be in visible items to be rendered
const getOffsetItems = (state) => {
  const oneRowPixels = state.rowHeight + state.rowPadding
  const offsetItems = Math.ceil((state.scrolledTop - topBuffer) / oneRowPixels)
  return offsetItems < 0
    ? 0
    : offsetItems
}

// Get index for last item that should be included in visible items
// it may not be visible for the user (as bottomBuffer is included)
// but still included because user can scroll fast and items should be still shown
// even if scroll event is not triggered
// (there is small timeout-delay for scrolling event for performance purpose)
const getLastItemIndex = (state) => {
  const oneRowPixels = state.rowHeight + state.rowPadding
  const areaHeight = window.innerHeight + topBuffer + bottomBuffer - state.listHeaderOffset
  let lastItemIndex = state.firstItemIndex + Math.ceil(areaHeight / oneRowPixels)
  if (lastItemIndex < state.firstItemIndex + 20) {
    lastItemIndex = state.firstItemIndex + 20
  }
  return lastItemIndex > state.listItems.length
    ? state.listItems.length
    : lastItemIndex
}

// Get visible items that will be rendered into DOM
const getVisibleItems = (state) => {
  const visibleItems = []
  // state.visibleItems = state.listItems.slice(state.firstItemIndex, state.lastItemIndex)
  for (let i = state.firstItemIndex; i < state.lastItemIndex; i++) {
    if (i < state.listItems.length) {
      visibleItems.push(state.listItems[i])
    }
  }
  return visibleItems
}

export default {
  // Updates items in store that should be rendered in DOM
  // and calls DOM update
  setListVisibleItems: ({ state, dispatch }, { forced }) => {
    // Update only if render area has changed
    // don't want to update on every scroll event
    const requestedRenderAreaNumber = Math.ceil(state.scrolledTop / 100)
    if (requestedRenderAreaNumber === state.renderedAreaNumber && !forced) {
      return
    }
    // Get offset, first and last item index
    state.offsetItems = getOffsetItems(state)
    state.firstItemIndex = state.offsetItems
    state.lastItemIndex = getLastItemIndex(state)
    // Update visible items
    state.listVisibleItems = getVisibleItems(state)
    state.renderedAreaNumber = requestedRenderAreaNumber
    // Update render rowPool to trigger actual change in DOM
    dispatch('updateRowPool')
  }
}
