import decimal from '@/methods/decimal'
import currencySymbols from '../../utilities/currencySymbols'

export default {
  name: 'BreakdownViewLineAttribute',

  props: {
    attributeName: {
      type: String,
      default: null,
    },
    showUnit: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    level: {
      type: Number,
      default: 0,
    },
    breakdownAttributes: {
      type: Array,
      default: () => [],
    },
    totalSums: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    layoutProfileItemsByName () {
      return this.$store.getters.layoutProfileItemsByName
    },

    type () {
      return this.layoutProfileItemsByName[this.attributeName].type
    },

    isPriceOrQuantity () {
      return ['quantity', 'price'].includes(this.type)
    },

    style () {
      return {
        width: (99.99 / this.breakdownAttributes.length) + '%',
        textAlign: this.isPriceOrQuantity
          ? 'right'
          : 'left'
      }
    },

    isCountAttribute (): boolean {
      return this.data.counts && this.data.counts[this.attributeName]
    },

    isPercentageAttribute (): boolean {
      return this.data.sums &&
        this.data.sums[this.attributeName] &&
        this.data.sums[this.attributeName].type === 'percentage'
    },

    percentageFromTotal () {
      if (this.isPercentageAttribute) { return null }
      if (!this.totalSums || !this.sumValue) { return null }
      if (!this.totalSums[this.attributeName]) { return null }
      const unitKeys = Object.keys(this.totalSums[this.attributeName]).filter((key) => key !== 'type')
      const totalValue = this.totalSums[this.attributeName][unitKeys[0]].value
      const sumValue = this.sumValue[unitKeys[0]].value
      const percentage = sumValue * 100 / totalValue
      if (isNaN(percentage)) { return null }
      return decimal.format(percentage, { precision: 2 })
    },

    uniqUnitNameExists () {
      if (!this.totalSums?.[this.attributeName]) { return false }
      if (typeof this.totalSums?.[this.attributeName] !== 'object') { return false }
      const unitKeys = Object.keys(this.totalSums?.[this.attributeName]).filter((key) => key !== 'type')
      return unitKeys.length === 1
    },

    unit () {
      let unit = this.data.sums[this.attributeName + '_unit'] ||
        this.data.sums[this.attributeName + '_currency']
      unit = !unit ? '' : unit // Set undefined
      return currencySymbols[unit] || unit
    },

    sumValue () {
      return this.data.sums[this.attributeName]
    },

    sumValueRounded () {
      return decimal.format(this.sumValue, { precision: 2 }) || '0,00'
    },

    countTotal () {
      return (this.data.counts[this.attributeName].true || 0) +
        (this.data.counts[this.attributeName].false || 0)
    },

    countTrue () {
      return (this.data.counts[this.attributeName].true || 0)
    },

    countValue () {
      return this.countTrue + ' / ' + this.countTotal
    },

    percentageAttributeValue () {
      const value = this.data.sums[this.attributeName].p.value / this.data.ids.length
      return decimal.format(value, { precision: 2 })
    },

    value () {
      if (this.isPercentageAttribute) { return this.percentageAttributeValue }
      return this.isCountAttribute ? this.countValue : this.sumValueWithUnit
    },

    sumValueWithUnit () {
      const thisValue = this.data.sums[this.attributeName]
      if (typeof thisValue !== 'object') { return thisValue }

      return Object.keys(thisValue).filter(key => key !== 'type').map(key => {
        const value = decimal.format(thisValue[key].value, { precision: 2 }) || '0,00'
        let unit = this.showUnit ? (thisValue[key].unit || thisValue[key].currency) : ''
        unit = !unit ? '' : unit // Set undefined
        return value + ' ' + (currencySymbols[unit] || unit)
      }).join(', ')
    },
  },
}
