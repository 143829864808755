const numeral = require('numeral')

module.exports = {
  parse (val) {
    let str = String(val || '0.0')
    // Replace all periods and commas except the last one.
    // eslint-disable-next-line no-useless-escape
    str = str.replace(/[\ \.,](?=.*[\.,])/g, '').replace(',', '.')
    // Force ".0" at the end and remove trailing zeros after the period except
    // the last one
    if (!str.match(/\./)) { str = `${str}.` }
    if (str.match(/^\./)) { str = `0${str}` }
    str = str.replace(/([^0])0+$/, '$1')
    if (str.match(/\.$/)) { str = `${str}0` }
    return str
  },

  // numeral.js default rounding uses Math.round, which is towards positive infinity.
  // It is a problem with money values as -1.545 and +1.545 round to -1.54 and +1.55.
  // We want negative and positive values round in consistent way. Thus always rounding
  // away from zero which makes -1.545 and +1.545 round to -1.55 and +1.55.
  awayFromZeroRoundingFunction (val) {
    const value = numeral(val)
    if (value.value() > 0) {
      return Math.round
    } else {
      // This rounding function for negative numbers rounds the number as
      // its positive counterpart and returns the rounded number as negative
      // This ensures that postive and negative numbers are rounded symmetrically
      return val => Math.round(val * -1) * -1
    }
  },

  format (amount, { precision }) {
    if (amount === null) { return '' }

    let format = '0,0.00' // default precision
    if (precision) {
      if (precision === true) { // full precision
        format = '0,0.00[000000000]'
      } else if (precision === '0') { // no decimals at all
        format = '0,0'
      } else { // exact decimal count given in precision
        if (precision) { format = '0,0.0' + Array(parseInt(precision)).join('0') }
      }
    }

    if (typeof amount === 'string') {
      // numeral parses the string with current locale's delimiters whereas Aava
      // stores them always with '.'
      const delimiter = numeral.localeData().delimiters.decimal
      amount = amount.replace('.', delimiter)
    }

    const value = numeral(amount)
    if (value.value() === null) { return 'NaN' }

    // numeral.js bug492, which unfortunately has not been fixed to this date,
    // format returns NaN if the value is so small it is expressed as exponential
    if (this._isExponential(value)) {
      return numeral('0').format(format, this.awayFromZeroRoundingFunction(amount))
    } else {
      return value.format(format, this.awayFromZeroRoundingFunction(amount))
    }
  },

  // solution proposal from the numeral.js bug492 to fix the small exponentials
  // fixing the huge numbers is probably not a practival issue, waiting for the numeral.js fix
  _isExponential (value) {
    return value.value().toString().indexOf('e-') > -1
  }
}
