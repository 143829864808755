<template>
  <div style="width: 80%;">
    <div class="custom-bar">
      <div
        style="background: #aaa;left: 0;width: 33%;"
        class="bar-fill"
      >
        33% +4
      </div>
    </div>
    <div class="custom-bar">
      <div
        style="background: #FF9800;left: 0%;width: 65%;"
        class="bar-fill"
      >
        65% -12
      </div>
    </div>
    <div class="custom-bar">
      <div
        style="background: #607D8B;left: 0%;width: 55%;"
        class="bar-fill"
      >
        55% -8
      </div>
    </div>
    <div class="custom-bar">
      <div
        style="background: #4CAF50;left: 0%;width: 20%;"
        class="bar-fill"
      >
        20% +1
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomBars',
}
</script>

<style lang="scss">
.custom-bar {
  background: #eee;
  width: 100%;
  border-radius: 50px;
  position: relative;
  height: 20px;
  margin: 10px 0;
  .bar-fill {
    position: absolute;
    border-radius: 50px;
    height: 20px;
    font-size: 11px;
    line-height: 19px;
    color: white;
    padding-left: 10px;
  }
}
</style>
