
import Sortable from 'sortablejs'
import methods from '@/components/methods'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import listQueries from '@/store/_listQueries'
import listViewAPI from '@/store/api'
import listFilters from '@/store/_listFilters'
import ColumnsSelectorField from '@/components/ListHeaderActions/ActionMenus/ColumnsSelectorField.vue'
import translateAttribute from '@/utilities/translateAttribute'
import SwitchField from '../../Form/SwitchField.vue'
import listCellEditMethods from '@/methods/listItem/listCellEditMethods'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ListColumnsSelectorDialog',

  components: {
    SwitchField,
    ColumnsSelectorField,
  },

  data () {
    return {
      render: true,
      saving: false,
      sampleItem: null,
      sortable1: null,
      sortable2: null,
      columns: [],
      searchTermForVisibleColumns: null,
      searchTermForAvailableColumns: null,
      sortCondition: 'name',
      reloadListOnReturn: true,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    props () {
      return this.columnsSelectorProps
    },

    layoutProfileItemsComputed () {
      return this.props.layoutProfileItems.map(field => {
        field.translation = translateAttribute(this.props.objectClass, field.name, this.locale, this.$i18n)
        return field
      }).sort((a, b) => a.sort_order < b.sort_order ? -1 : 1)
        .map((field, i) => {
          field.index = i
          return field
        })
    },

    selectedColumns () {
      return this.columns.filter(field => field.visible)
    },

    availableColumns () {
      if (this.sortCondition) {
        return this.columns.filter(field => !field.visible)
          .sort((a, b) => {
            const nameSort = (a.translation < b.translation) ? -1 : (a.translation > b.translation) ? 1 : 0
            if (this.sortCondition === 'name') {
              return nameSort
            }
            return (a.type < b.type) ? -1 : (a.type > b.type) ? 1 : nameSort
          })
      }
      return this.columns.filter(field => !field.visible)
    },

    listFirstItem () {
      return this.listItems[0]
    },
  },

  created () {
    if (!this.props.forHasMany) {
      this.reloadListOnReturn = 'doNotReloadListOnReturn' in this.props.selectedLayoutProfile.timelineConfiguration
        ? !this.props.selectedLayoutProfile.timelineConfiguration.doNotReloadListOnReturn
        : true
    }
    this.columns = JSON.parse(JSON.stringify(this.layoutProfileItemsComputed))
    this.getColumnsSelectorPreviewDataForAllFields()
    this.reSortColumns()
    this.createColumnsSortables()
  },

  methods: {
    ...methods,
    ...listCellEditMethods,

    showAll () {
      this.columns = this.columns.map(field => {
        field.visible = true
        return field
      })
    },

    hideAll () {
      this.columns = this.columns.map(field => {
        field.visible = false
        return field
      })
    },

    createColumnsSortables () {
      this.$nextTick(() => {
        this.sortable1 = null
        this.sortable2 = null
        const el1 = document.getElementById('selected-columns')
        const el2 = document.getElementById('available-columns')
        if (!el1 || !el2) { return }
        this.sortable1 = Sortable.create(el1, {
          group: 'shared',
          onEnd: this.columnDragEnd,
          chosenClass: 'sortable-chosen',
          ghostClass: 'sortable-ghost',
        })
        this.sortable2 = Sortable.create(el2, {
          group: 'shared',
          onEnd: this.columnDragEnd,
          chosenClass: 'sortable-chosen',
          ghostClass: 'sortable-ghost',
        })
      })
    },

    reSortColumns () {
      this.columns = JSON.parse(JSON.stringify(
        this.columns.sort((a, b) => a.sort_order < b.sort_order ? -1 : 1)
          .map((field, i) => {
            field.index = i
            return field
          })
      ))
    },

    columnDragEnd () {
      const elements: HTMLCollection = document.getElementsByClassName('field-chip-container')
      Array.from(elements).forEach((rawEl, newIndex) => {
        const el = rawEl as HTMLElement
        const currentIndex = el.dataset.currentIndex
        if (currentIndex !== undefined) {
          if (el.parentElement!.id !== el.dataset.parentId) {
            this.$set(this.columns[currentIndex], 'visible', !this.columns[currentIndex].visible)
          }
          this.$set(this.columns[currentIndex], 'sort_order', newIndex + 1)
        }
      })
      this.reSortColumns()
      this.reRender()
    },

    reRender () {
      const el = document.getElementById('columns-selector-columns')
      const scrollTop = (el && el.scrollTop) || 0
      this.render = false
      this.$nextTick(() => {
        this.render = true
        this.$nextTick(() => {
          this.createColumnsSortables()
          this.$nextTick(() => {
            // Restore scroll position after re-render
            const el = document.getElementById('columns-selector-columns')
            if (el) {
              el.scrollTop = scrollTop
            }
          })
        })
      })
    },

    getColumnsSelectorPreviewDataForAllFields () {
      const queries = listQueries.get(this.props.layoutProfileItems, this.selectedLayoutProfile, {
        locale: this.locale,
        includeAllFields: true,
      })
      listViewAPI.getListOneItem(
        this.props.objectClass,
        // listFilters.get(1, 0, this.$store.state),
        {},
        queries,
        // When selecting columns for item has-many, provide id of the item which is first in the has-many list
        // Could be empty, then last changed item is used
        this.props.forHasMany ? this.props.previewItemId : this.listFirstItem?.id
      ).then((response: AxiosResponse) => {
        this.sampleItem = response.data.items?.[0] || {}
      })
    },

    hasManyToggleClickHandler (toggleFieldName) {
      let hasManyFieldsWithData = this.selectedLayoutProfile.timelineConfiguration.hasManyFieldsWithData || []
      if (hasManyFieldsWithData.includes(toggleFieldName)) {
        hasManyFieldsWithData = hasManyFieldsWithData.filter(fieldName => fieldName !== toggleFieldName)
      } else {
        hasManyFieldsWithData.push(toggleFieldName)
      }
      this.$set(this.selectedLayoutProfile.timelineConfiguration, 'hasManyFieldsWithData', hasManyFieldsWithData)
    },

    listEditableFieldToggleHandler (field) {
      this.$set(field, 'editable_in_list', !field.editable_in_list)
    },

    closeMenu () {
      this.showListColumnsSelector = false
    },

    callSaveCallback () {
      this.closeMenu()
      // Different actions when opened from the main list, or from item has-many list
      this.props.callback({ columns: this.columns, reloadListOnReturn: this.reloadListOnReturn })
    },

    toggleFieldVisibility (field) {
      if (!field.visible) {
        this.moveAddedColumnToLastPlace(field)
      }
      this.$set(field, 'visible', !field.visible)
      this.reSortColumns()
      this.reRender()
    },

    toggleSortCondition () {
      this.sortCondition = this.sortCondition
        ? this.sortCondition === 'name'
          ? 'type'
          : ''
        : 'name'
    },

    moveAddedColumnToLastPlace (field) {
      // Get current max visible column order number
      const maxSortOrder = this.selectedColumns[this.selectedColumns.length - 1]?.sort_order
      if (!maxSortOrder) { return }
      if (field.sort_order < maxSortOrder) {
        this.columns.forEach(column => {
          if (column.sort_order > field.sort_order && column.sort_order <= maxSortOrder) {
            column.sort_order--
          }
        })
        field.sort_order = maxSortOrder
      }
    },
  },
}
