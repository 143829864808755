
import { PropType } from 'vue'
import dateTimeHelper from '@/methods/date_time_helper'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import LayoutProfileMenu from '@/components/LayoutProfiles/LayoutProfileMenu.vue'
import LayoutProfileForm from '@/components/LayoutProfiles/LayoutProfileForm.vue'
import { LP } from '@/types/LP.types'

const { mapFields }: any = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

const dragOffsetPixels = -6
const dragStartDelay = 700

export default {
  name: 'LayoutProfileTab',

  components: {
    LayoutProfileForm,
    LayoutProfileMenu,
  },

  props: {
    layoutProfile: {
      type: Object as PropType<LP.LayoutProfile>,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    itemEdit: {
      type: Boolean,
      default: false,
    },
    showMenuBtn: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      showMenu: false,
      showForm: false,
      pos: {
        x: 0,
        y: 0,
      },
    }
  },

  created () {
    document.addEventListener('keyup', this.keyUpListener)
  },

  destroyed () {
    document.removeEventListener('keyup', this.keyUpListener)
  },

  computed: {
    ...mapFields(Object.keys(state)),

    isLocked () {
      return !!this.layoutProfile.locked
    },

    isGlobalView () {
      return !!this.layoutProfile.global_view
    },

    isOwnerOfTheGlobalView () {
      return this.isGlobalView && !this.isLocked
    },

    isLockedGlobalView () {
      return this.isGlobalView && this.isLocked
    },

    showCount () {
      return this.layoutProfile?.timelineConfiguration?.showItemCount && this.layoutProfile?.count !== ''
    },

    isSelected () {
      return this.layoutProfile.action === 'index'
        ? this.layoutProfile.id === this.selectedLayoutProfileId
        : this.layoutProfile.id === this.selectedLayoutProfileIdByModel[this.layoutProfile.controller]
    },

    tabIcon () {
      switch (this.layoutProfile?.timelineConfiguration?.startView) {
        case 'breakdown':
          return 'fa-angle-double-right'
        case 'timeline':
          return 'fa-clock'
        case 'pivot_table':
          return 'fa-table'
        case 'card_view':
          return 'fa-columns'
        default:
          return 'fa-bars'
      }
    },

    countStyle () {
      const layoutProfile = this.layoutProfile
      const backgroundColor = layoutProfile.timelineConfiguration &&
        layoutProfile.timelineConfiguration.backgroundColor
        ? layoutProfile.timelineConfiguration.backgroundColor
        : ''
      if (!this.isSelected || !backgroundColor) {
        // return {}
      }
      return {
        background: backgroundColor || '#aaa',
      }
    },

    classes () {
      const classes = ['layout-profile-tab', 'text-none']
      if (this.isSelected) {
        classes.push('selected-layout-profile')
      }
      return classes
    },

    style () {
      const style = {
        color: 'white',
        background: '',
        position: '',
        top: '',
      }
      const layoutProfile: LP.LayoutProfile = this.layoutProfile
      if (layoutProfile.timelineConfiguration?.backgroundColor && !this.isSelected) {
        style.background = layoutProfile.timelineConfiguration.backgroundColor + ' !important'
      }
      if (this.isSelected) {
        style.color = 'black'
      }
      if (this.draggingLayoutProfileIndex === this.index && layoutProfile.action === this.draggingLayoutProfile.action) {
        style.position = 'relative'
        style.top = dragOffsetPixels + 'px'
      }
      if (!style.background) {
        style.background = '#aaa'
      }
      return style
    },
  },

  methods: {
    ...methods,

    keyUpListener (e) {
      if (e.key !== 'Escape') { return }
      this.showMenu = false
    },

    clickHandler () {
      if (this.isLocalDevelopment) {
        console.log('Layout Profile:', this.layoutProfile.id, this.layoutProfile, this.$store.getters.layoutProfileItemsByName)
      }
      if (this.layoutProfile.action === 'index') {
        this.selectLayoutProfile(this.layoutProfile)
      } else {
        this.selectItemLayoutProfileWhenReady(this.layoutProfile)
      }
    },

    mouseDownOnLayoutProfileTab (index) {
      if (index === null) { return }
      this.timer = setTimeout(() => {
        this.draggingLayoutProfileIndex = index
        this.draggingLayoutProfile = JSON.parse(JSON.stringify(this.layoutProfile))
      }, dragStartDelay)
    },

    mouseUpOnLayoutProfileTab () {
      if (!this.draggingLayoutProfileIndex) {
        clearTimeout(this.timer)
      }
    },

    showLayoutProfileMenu (layoutProfile, e) {
      if (this.draggingLayoutProfileIndex) {
        return
      }
      this.showMenu = true
      this.pos = {
        x: e.clientX,
        y: e.clientY,
      }
    },

    mouseEnterHandler (index) {
      const enteredLayoutProfile = this.parentLayoutProfiles?.[index]
      // Do not allow sorting (position exchange) for locked global view
      const isLocked = !!enteredLayoutProfile?.global_view && !!enteredLayoutProfile?.locked
      if (isLocked) { return }
      // TODO - use sortable? May be difficult because some tabs are behind a menu
      // Check that enough time passed, sometimes one swap triggers immediately another
      // Ex: moving smaller tab to right causes a swap, after swapping mouse may again be
      // on target tab, which triggers another 'mouseEnterHandler' call
      // To avoid auto-swap-back we check passed time
      const enoughTimePassed = (dateTimeHelper.getTimestamp() - this.lastLayoutProfileSwapTimestamp) > 0.1
      if (index === null || !enoughTimePassed) { return }
      if (this.draggingLayoutProfileIndex !== null &&
        this.draggingLayoutProfileIndex !== index
      ) {
        this.lastLayoutProfileSwapTimestamp = dateTimeHelper.getTimestamp()
        this.$nextTick(() => {
          this.$store.dispatch('swapLayoutProfilePosition', {
            index1: this.draggingLayoutProfileIndex,
            index2: index,
          })
          this.draggingLayoutProfileIndex = index
        })
      }
    },
  },
}
