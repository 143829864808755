import api from './../../store/api'
import { AxiosResponse } from 'axios'
import { ItemsResponse } from '@/types/API.responses'
import { LP } from '@/types/LP.types'

export default {
  getHasManyItems (objectClass: string, forClass: string, forId: any, forField: string, filters: any, queries: string[]): Promise<AxiosResponse<ItemsResponse>> {
    return api.fetchListItems(objectClass, filters, queries, { forClass, forId, forField })
  },
}
