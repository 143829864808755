import api from '../../store/api'
import Vue from 'vue'
import shortcutData from '@/store/dashboardItems/helpers/shortcutData'
import { DashboardItemsR, DashboardOptionsR, DashboardTypes } from '../../types/DashboardTypes'
import { AxiosResponse } from 'axios'

export default {
  refreshDashboardItems () {
    this.$store.state.dashboardItemsRefreshing = true
    this.$store.dispatch('getAndSetDashboardItemsContent', this.$store.state.dashboardItems)
      .then(() => {
        this.$store.state.dashboardItemsRefreshing = false
      })
  },

  deleteDashboardItem (id: number) {
    return api.deleteDashboardItem(id).then(() => {
      this.$store.state.dashboardItems = this.$store.state.dashboardItems.filter(item => item.id !== id)
      this.refreshShortcuts()
    })
  },

  addShortcut ({ identifier, objectClass, viewId }) {
    return new Promise(resolve => {
      api.getItemSummary(objectClass, viewId).then((summary) => {
        api.gorillaAddDashboardItem({
          type: 'link',
          name: identifier + summary.replace('|', ''),
        }).then(() => {
          // Add shortcut key immediately to give fast user feedback (filled star)
          Vue.set(this.$store.state.shortcutKeys, shortcutData.getShortcutKey(identifier) as any, 1)
          this.refreshShortcuts()
          resolve(true)
        })
      })
    })
  },

  addExternalShortcut (identifier) {
    return new Promise(resolve => {
      api.gorillaAddDashboardItem({
        type: 'link',
        name: identifier,
      } as DashboardTypes.Shortcut).then(() => {
        this.refreshShortcuts().then(() => {
          resolve(true)
        })
      })
    })
  },

  // Send Ajax requests to the service to delete dashboard item
  deleteShortcut (id) {
    this.$store.state.shortcuts = this.shortcuts.filter(shortcut => shortcut.id !== id)
    // Delete directly from the store & then send service request
    api.deleteDashboardItem(id).then(() => {
      this.refreshShortcuts()
    })
  },

  refreshTabs () {
    return api.fetchDashboardItems().then((response: AxiosResponse<DashboardItemsR>) => {
      const items_from_api = response.data.items
      this.$store.dispatch('setDashboardTabsToStore', { dashboardItems: items_from_api })
    })
  },

  refreshShortcuts () {
    return api.fetchDashboardItems().then((response: AxiosResponse<DashboardItemsR>) => {
      const items_from_api = response.data.items
      this.$store.dispatch('setShortcutsToStore', items_from_api)
    })
  },
}
