import clearCache from '@/utilities/clearCache'
import { ExtraFormItem, ExtraFormItemType, FormItem, LP } from '@/types/LP.types'
import { AxiosResponse } from 'axios'

export default {
  addNewFieldSet (container = null) {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    this.dialogItemContainerMenu = false
    return new Promise(resolve => {
      this.saveFieldSet({
        layoutContainer: container || this.itemContainerForEdit,
        content_columns: 0,
        columns: 12,
        bgColor: '',
      }).then((response: AxiosResponse) => {
        if (response) {
          this.updateContainersInDOMAndCreateSortings()
        }
        resolve(response)
      })
    })
  },

  updateLayoutContainerFieldSet (fieldSet: LP.FieldSet) {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    this.$store.dispatch('updateLayoutContainerFieldSet', fieldSet)
  },

  saveFieldSet (fieldSet: LP.FieldSet) {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    return new Promise(resolve => {
      this.$store.dispatch('saveLayoutContainerFieldSet', fieldSet).then(savedFieldSet => {
        resolve(savedFieldSet)
      })
    })
  },

  // Add richtext_info or HR to the field set
  addExtraFormItem (fieldSet: LP.FieldSet, layoutProfile: LP.LayoutProfile, type: ExtraFormItemType): void {
    this.dialogItemFieldSetMenu = false
    const id = layoutProfile.timelineConfiguration.extraFormItems.length
      ? (Math.max(...layoutProfile.timelineConfiguration.extraFormItems.map(o => o.id))) + 1
      : 1
    const item: ExtraFormItem = {
      id,
      type,
      isLPI: false,
      layout_container_field_set: { id: fieldSet.id! },
    }
    if (type === 'richtext_info') {
      // Create a container for html values in x locales
      item.values = {}
    }
    layoutProfile.timelineConfiguration.extraFormItems.push(item)
    this.$store.dispatch('saveLayoutProfileData', layoutProfile)
  },

  deleteLayoutContainerFieldSet (fieldSet: LP.FieldSet): Promise<boolean> {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    this.dialogItemFieldSetMenu = false
    return new Promise(resolve => {
      this.$store.dispatch('deleteLayoutContainerFieldSet', fieldSet).then((result: boolean) => {
        resolve(result)
      })
    })
  },

  getFieldSetClasses () {
    return [
      'field-set-form',
      (this.itemLayoutEditMode ? ' field-set-edit ' : ''),
      // (this.getFieldSetColsInContainer(container, fieldSet) === 12 ? 'field-set-12' : ''),
      // (this.showLoaderComputed ? ' fieldset-loading-bg ' : ''),
      (this.fieldSets.length > 1 || this.containersComputed.length > 1 ? 'multiple-field-sets' : 'single-field-set'),
    ]
  },

  getFieldSetDefaultDataForContainer (container: LP.Container) {
    return [{
      // name: '',
      content_columns: 0,
      columns: 12,
      layout_container: {
        id: container.id,
      },
    }] as LP.FieldSet[]
  },

  getFieldsInContainerFieldSet (containerIndex: number, fieldSetIndex: number, fieldSet: LP.FieldSet) {
    return this.formFields.filter((field: FormItem) => {
      const fieldIsInVirtualContainer = field.isLPI && field.containerIndex === containerIndex &&
        field.fieldSetIndex === fieldSetIndex && this.containers.length === 0
      const fieldIsInFieldSet = field.layout_container_field_set &&
        field.layout_container_field_set.id === fieldSet.id
      return fieldIsInVirtualContainer || fieldIsInFieldSet
    })
  },
}
