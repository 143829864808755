<template>
  <v-row class="py-4">
    <v-col cols="12">
      <v-text-field
        v-model="shortcut_name"
        :label="$i18n.t('aava.shortcuts.link_name')"
        outlined
        dense
        hide-details
        @focus="resetError"
      />
    </v-col>
    <v-col
      cols="12"
      class="pt-0"
    >
      <v-text-field
        v-model="shortcut_link"
        :label="$i18n.t('aava.shortcuts.external_link')"
        placeholder="https://"
        outlined
        dense
        hide-details
        @focus="resetError"
      />
    </v-col>
    <v-col
      v-if="save_error"
      cols="12"
    >
      <div
        class="shortcut_form_error"
      >
        {{ save_error }} {{ shortcut_name }}
      </div>
    </v-col>
    <v-col cols="3" />
    <v-col
      cols="3"
      class="text-center"
    >
      <v-btn
        fab
        small
        :disabled="!saveEnabled"
        class="elevation-0"
        @click="saveLink"
      >
        <v-icon
          small
          color="green"
        >
          fa-check
        </v-icon>
      </v-btn>
    </v-col>
    <v-col
      cols="3"
      class="text-center"
    >
      <v-btn
        fab
        small
        class="elevation-0"
        @click="goBack"
      >
        <v-icon
          small
          color="red"
        >
          fa-times
        </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="3" />
  </v-row>
</template>

<script>
import dashboardMethods from '@/components/Dashboard/dashboardMethods'
import util from '../../utilities/sharedUtilities'

export default {
  name: 'ShortcutAddExternalLink',

  props: {
    // For unknown reason this.$emit('close') does not work in dispatch.then() with arrow function
    // therefore using as prop
    closeForm: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      saving: false,
      shortcut_name: '',
      shortcut_link: '',
      save_error: '',
    }
  },

  computed: {
    saveEnabled () {
      return this.shortcut_link && util.isValidHttpsURL(this.shortcut_link)
    },
  },

  methods: {
    ...dashboardMethods,

    saveLink () {
      if (!this.shortcut_name || !this.shortcut_link) {
        this.save_error = this.$i18n.t('aava.shortcuts.fields_are_mandatory')
      } else {
        const identifier = 'external|||||' + this.shortcut_name.replace('|', '') + '|' + this.shortcut_link
        this.$store.state.loadingShortcuts = true
        this.addExternalShortcut(identifier).then(() => {
          this.goBack()
          this.resetForm()
        })
      }
    },

    resetForm () {
      this.shortcut_name = ''
      this.shortcut_link = ''
    },

    goBack () {
      this.closeForm()
    },

    resetError () {
      this.save_error = ''
    },
  }
}
</script>

<style scoped>
.config_buttons_container {
  width: 100%;
  padding: 10px 0;
}
.config_buttons {
  margin: 0 auto;
  width: 80px;
}
.config_button {
  float: left;
  text-align: center;
  color: #cccccc;
  cursor: pointer;
}
.button_shortcut_save, .button_shortcut_cancel {
  padding: 12px;
  width: 40px;
  height: 40px;
}
.shortcut_add_container {
  width: 100%;
}
.shortcut_form_input {
  width: 280px;
  padding: 5px 10px;
  border: 1px solid #cccccc;
  margin: 5px 10px;
}
.shortcut_form_error {
  padding: 5px 10px;
  color: #e08b8b;
}
</style>
