import axios, { AxiosError, AxiosResponse } from 'axios'
import { networkErrorAxiosResponse } from '@/store/api/apiRequests'

export default {
  deleteItem (objectClass: string, id: number | string): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      axios.delete(
        '/api/' + objectClass + '/' + id, {
          ...this.requestOptions()
        },
      ).then((response) => {
        resolve(response)
      }).catch((error: AxiosError) => {
        resolve(error.response as AxiosResponse || networkErrorAxiosResponse(error))
      })
    })
  },
}
