import { LP, LPI } from '@/types/LP.types'

type ItemFieldMoveCallback = (movedField: LPI, toFieldSetId: number) => void;

export default {
  dialogItemContainerMenu: false,
  dialogItemFieldSetMenu: false,
  dialogItemFieldMenu: false,

  itemContainerForEdit: null as LP.Container | null,
  itemFieldSetForEdit: null as LP.FieldSet | null,
  itemFieldForEdit: null as LPI | null,

  itemFieldMoveCallback: null as ItemFieldMoveCallback | null,
  itemFieldMenuData: {},

  addNewContainerCallback: () => {},
  addNewFieldSetCallback: () => {},
  toggleContainerInfoTextFieldCallback: () => {},

  selectedLayoutEditorFields: [] as string[],
}
