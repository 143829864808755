import { getField } from 'vuex-map-fields'
import util from '../utilities/sharedUtilities'

// Get main object state ids which are set as list filters
// In 'stateFilter' value is stored as is needed for resource api
const getStateFilterStateIds = stateFilter => {
  return stateFilter
    ? stateFilter.split(' ')
      .map(value => parseInt(value.replace(/\D/g, '')))
    : []
}

export default {
  getField,
  snackbar: state => state.snackbar,
  objectClass: state => state.objectClass,
  splitMode: state => state.splitMode,

  inListEditMode: state => {
    if (!state.listEdit.editing) {
      return false
    }
    return !!state.layoutProfileItems.filter(item => {
      return item.editable_in_list && item.visible &&
        state.supportedListEditTypes.includes(item.type) &&
        item.widget !== 'address'
    }).length
  },

  // Easily access in list cell to check if is in save queue
  listEditCellQueueKeys: state => {
    const keys = []
    state.listEdit.queue.forEach(job => {
      keys.push(job.id + '_' + job.field.name)
    })
    return keys
  },

  // TODO why there is state with same name and why
  // gorilla service does not return all user level settings to it?
  userLevelSettings: state => {
    const settings = {};
    (state.userInfo?.user_profiles?.[0]?.settings || []).forEach(setting => {
      settings[setting.key] = setting
    })
    return settings
  },

  availableContentLocalesSorted: state => {
    const locale = state.locale
    // Sort so that current locale is first
    return JSON.parse(JSON.stringify(state.availableContentLocales))
      .sort((a, b) => a === locale ? -1 : b === locale ? 1 : 0)
  },

  isProductionBackend: state => {
    return state.systemInfo.mode === 'production' &&
      !state.isLocalDevelopment &&
      window.location.host !== 'oma.aavaohjelmistot.fi' // Exception as in Oma mode is always production (?)
  },

  menuColor: state => {
    const menuColors = {
      test: 'yellow darken-3',
      development: '#5c7d80',
      production: 'grey darken-3',
    }
    // const matchHardCodedColors = {
    //   '#ffb500': 'test',
    // }
    // const menuColor = matchHardCodedColors[state.systemInfo.menuColor] || menuColors.production
    // Use development color even if using test api through proxy
    if (state.isLocalDevelopment) {
      return '#E64A19'
    }
    const colorCode = state.systemInfo.menuColor
    return menuColors[colorCode] || colorCode || 'grey darken-3'
  },

  isAdminUser: (state) => {
    return state.userInfo?.roleIdentifiers?.includes('aava')
  },

  listAreaWidth: (state) => {
    if (state.splitMode !== 'vertical') {
      return state.innerWidth
    }
    return state.innerWidth - state.innerWidth * (100 - state.splitModeListSize) / 100
  },

  listHeaderColumnTitleMenuTopOffset: (state, getters) => {
    let topOffset = 190
    if (!state.suppressToolbar && getters.showListTabsOnSeparateToolbar || state.splitMode === 'vertical') {
      topOffset += 35
    }
    if (state.suppressToolbar) {
      topOffset -= 100
    }
    return topOffset
  },

  showListTabsOnSeparateToolbar: state => {
    if (state.splitMode) {
      return true
    }
    return state.layoutProfiles && state.layoutProfiles.length > 2
  },

  // Set Layout Profile Items by name
  // for easy access in various methods and actions
  layoutProfileItemsByName: state => {
    const byName = {}
    state.layoutProfileItems.forEach(field => {
      byName[field.name] = field
    })
    return byName
  },

  // Returns true if temporary filters are set
  // Temporary filters are filters applied to the list
  // which are not saved as permanent filters (stored server side)
  temporaryFiltersSet: state => {
    const permanentFilters = {}
    let temporaryFiltersSet = false
    state.layoutProfileItems.forEach(field => {
      if (field.custom_value) {
        permanentFilters[field.name] = field.custom_value
      }
    })
    Object.keys(state.listFilters).forEach(filterName => {
      if (state.listFilters[filterName] && !permanentFilters[filterName]) {
        temporaryFiltersSet = true
      }
    })
    return temporaryFiltersSet
  },

  // Returns list of has_many_reference fields
  // for which has-many items should be shown in the list
  // User can toggle this in the 'select columns' popup
  // This user setting is stored in 'timelineConfiguration'
  hasManyFieldsWithData: state => {
    return state.selectedLayoutProfile?.timelineConfiguration?.hasManyFieldsWithData || []
  },

  // Get applied main_object_state filter ids
  // Also checks if filters were applied in previous list UI,
  // in this case returns empty array
  stateFilterStateIds: state => {
    if (util.isOldUIStateFilterValue(state.listFilters.main_object_state)) {
      return []
    }
    return getStateFilterStateIds(state.listFilters.main_object_state)
  },

  // Get a text value to display selected state filters
  // In new version filtering is done by id (no longer by name)
  // For user we need to show text values
  listFiltersGetStateDisplayValue: state => {
    if (util.isOldUIStateFilterValue(state.listFilters.main_object_state)) {
      return state.listFilters.main_object_state
    }
    let value = ''
    const objectStates = state.objectStatesByClass[state.objectClass]
    if (objectStates) {
      objectStates.forEach(option => {
        if (state.listFilters.main_object_state) {
          if (getStateFilterStateIds(state.listFilters.main_object_state)
            .includes(option.id)) {
            value = value + ' ' + option.name
          }
        }
      })
    }
    return value || state.listFilters.main_object_state
  },

  actionsForList: state => {
    return [...state.metaInfo?.buttons || [], ...state.metaInfo?.actions || []]
      .filter(action => {
        return !action.views || action.views.includes('index')
      })
  },

  selectedDashboardTab: state => {
    return state.dashboardTabs.filter(tab => tab.id === state.selectedDashboardTabId)?.[0]
  },
}
