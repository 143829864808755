import { ItemToolbarButton } from '@/types/ItemToolbarButton'

export default {
  getToolbarButtons (): ItemToolbarButton[] {
    let buttons: ItemToolbarButton[] = []
    buttons = [
      {
        identifier: 'info',
        name: 'Info',
        icon: 'fa-info-circle',
        color: 'grey',
        action: () => {
          this.showInfoDialog = true
        },
        show: (this.modelInfoText || this.fieldsInfoTexts.length > 0) &&
          this.$vuetify.breakpoint.mdAndUp && !this.isMyProfile,
        enabledWhenLoading: true,
      }, {
        identifier: 'move_to_left',
        name: this.$i18n.t('aava.edit.move_to_left'),
        icon: this.splitMode === 'horizontal' ? 'fa-arrow-up' : 'fa-arrow-left',
        color: 'grey',
        action: this.moveToLeftInSplitMode,
        show: !this.forbiddenListModel && !this.modal && !!this.splitMode && (this.itemFormPosition === 'second' || !this.splitProps.secondResource) &&
          !this.isMyProfile,
        enabledWhenLoading: true,
      }, {
        identifier: 'move_to_right',
        name: this.$i18n.t('aava.edit.move_to_right'),
        icon: this.splitMode === 'horizontal' ? 'fa-arrow-down' : 'fa-arrow-right',
        color: 'grey',
        action: this.moveToRightInSplitMode,
        show: !this.forbiddenListModel && !this.modal && !!this.splitMode && (this.itemFormPosition === 'first' && this.splitProps.secondResource) &&
          !this.isMyProfile,
        enabledWhenLoading: true,
      }, {
        identifier: 'save',
        name: this.$i18n.t('aava.edit.save'),
        icon: 'fa-cloud-upload-alt',
        color: 'green',
        buttonColor: 'green',
        action: this.saveFromToolbar,
        actionParameter: {},
        wideButton: true,
        show: this.item && this.mode !== 'show',
        keyboardShortcut: ['ctrl', 's'],
        enabledWhenLoading: true,
        disabled: !this.item['@editable'],
        loading: this.savingFormItem,
      }, {
        identifier: 'save_and_return',
        name: this.$i18n.t('aava.edit.save_and_return'),
        icon: 'fa-cloud',
        color: 'grey',
        buttonColor: '#9E9E9E',
        action: this.saveFormItem,
        actionParameter: { goBackToList: true },
        wideButton: true,
        show: this.item && this.mode !== 'show' && !this.modal && this.itemFormPosition === 'first' &&
          this.$vuetify.breakpoint.mdAndUp && !this.isMyProfile,
        keyboardShortcut: ['ctrl', 'alt', 's'],
        enabledWhenLoading: true,
        disabled: !this.item['@editable'],
      }, {
        identifier: 'edit',
        name: this.$i18n.t('aava.actions.edit'),
        icon: 'fa-edit',
        color: 'orange',
        buttonColor: 'orange',
        action: this.openEditForm,
        wideButton: true,
        show: this.item && this.mode === 'show' && !this.modal && !this.isMyProfile,
        disabled: !this.showLoader && (this.item && this.item['@editable'] !== true),
        keyboardShortcut: ['ctrl', 'e'],
        enabledWhenLoading: true,
      }, {
        identifier: 'cancel_edit',
        name: this.$i18n.t('aava.edit.cancel'),
        icon: 'fa-undo-alt',
        color: 'grey',
        buttonColor: '#9E9E9E',
        wideButton: true,
        action: this.cancelEditOrCloseModal,
        show: this.item && this.mode !== 'show' && !this.modal && !this.isMyProfile,
        keyboardShortcut: ['ctrl', 'esc'],
        enabledWhenLoading: true,
      }, {
        identifier: 'add_new',
        name: this.$i18n.t('aava.general.new'),
        icon: 'fa-plus',
        color: 'green',
        buttonColor: 'grey',
        action: this.openNewForm,
        actionParameter: {
          resource: this.resource,
        },
        disabled: !this.showLoader && !(this.itemMeta?.permissions && this.itemMeta.permissions.includes('create')),
        wideButton: true,
        show: this.mode === 'show' &&
          !this.modal &&
          (this.itemFormPosition === 'first' || this.isMobileBottomToolbar) &&
          !this.isMyProfile,
        keyboardShortcut: ['ctrl', 'n'],
        enabledWhenLoading: true,
      }, {
        identifier: 'toggle_split',
        name: this.$i18n.t('aava.general.split'),
        icon: 'fa-columns',
        color: 'grey',
        action: this.toggleSplitModeFromItemView,
        show: !this.forbiddenListModel && this.showToggleSplitModeBtn && !this.isMyProfile,
        // keyboardShortcut: ['ctrl', 'shift', 'arrowleft'], Conflict with selecting text in edit view. Disabling until ...
        enabledWhenLoading: true,
      }, {
        identifier: 'open_list',
        name: this.$i18n.t('aava.edit.index'),
        icon: 'fa-list',
        color: 'green',
        action: this.openList,
        show: !this.forbiddenListModel && !this.modal,
        // keyboardShortcut: ['ctrl', 'l'], Conflict with browser go to url
        enabledWhenLoading: true,
      }, {
        identifier: 'delete',
        name: this.$i18n.t('aava.actions.remove'),
        icon: 'fa-trash',
        color: 'red darken-1',
        buttonColor: 'red darken-1',
        action: this.deleteItem,
        show: this.item && this.mode !== 'new' && !this.modal && !this.isMyProfile,
        disabled: !this.showLoader &&
          (!(this.itemMeta?.permissions && this.itemMeta.permissions.includes('destroy')) ||
            !this.item['@editable']),
        keyboardShortcut: ['ctrl', 'd'],
        enabledWhenLoading: true,
      }, {
        identifier: 'copy_from',
        name: this.$i18n.t('aava.edit.add_from_template'),
        icon: 'fa-copy',
        color: 'grey',
        action: this.copyFrom,
        show: this.item?.id && this.mode !== 'new' && !this.modal && !this.isMyProfile && this.itemMeta.allow_object_as_template,
        disabled: !this.item['@class'],
      }, {
        identifier: 'previous_item',
        name: this.$i18n.t('aava.actions.previous'),
        icon: 'fa-arrow-left',
        color: 'grey',
        action: this.previousItem,
        show: !this.forbiddenListModel && this.mode === 'show' && this.$vuetify.breakpoint.mdAndUp &&
          this.itemFormPosition === 'first' &&
          ((this.selectedId === this.item.id &&
            this.resource === this.objectClass) || this.showLoader) && !this.modal,
        disabled: !this.showLoader && this.isFirstItemInTheList(),
        keyboardShortcut: ['ctrl', 'arrowleft'],
        enabledWhenLoading: true,
      }, {
        identifier: 'next_item',
        name: this.$i18n.t('aava.actions.next'),
        icon: 'fa-arrow-right',
        color: 'grey',
        action: this.nextItem,
        show: !this.forbiddenListModel && this.mode === 'show' && this.$vuetify.breakpoint.mdAndUp &&
          this.itemFormPosition === 'first' &&
          ((this.selectedId === this.item.id &&
            this.resource === this.objectClass) || this.showLoader) && !this.modal,
        disabled: !this.showLoader && this.isLastItemInTheList(),
        keyboardShortcut: ['ctrl', 'arrowright'],
        enabledWhenLoading: true,
      }, {
        identifier: 'close',
        name: this.$i18n.t('aava.actions.close'),
        icon: 'fa-times',
        color: 'orange',
        buttonColor: '#9E9E9E',
        wideButton: true,
        action: this.cancelEditOrCloseModal,
        show: this.modal,
        keyboardShortcut: ['ctrl', 'esc'],
        enabledWhenLoading: true,
      },
    ]
    return buttons
  }
}
