<template>
  <div
    :class_="splitMode === 'horizontal' ? 'horizontal-split-mode-container' : ''"
  >
    <content-has-many-list-menu />
    <content-full-text-menu />
    <template v-if="selectedLayoutProfileId">
      <NoSeContainer v-if="noSeView" />
      <ListView
        v-else-if="layoutProfileView === 'list' || listBreakdownSearchIds.length > 0"
      />
      <PivotTableView
        v-else-if="layoutProfileView === 'pivot_table'"
      />
      <BreakdownView
        v-else-if="layoutProfileView === 'breakdown'"
      />
      <CardView
        v-else-if="layoutProfileView.startsWith('card_view')"
      />
      <TimelineView
        v-else
      />
    </template>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from './../store/state'
import methods from './methods'
import ContentHasManyListMenu from './ListContent/ContentHasManyListMenu'
import ContentFullTextMenu from './ListContent/ContentFullTextMenu'
import ListView from './ListView'
import PivotTableView from '@/components/PivotTableView/PivotTableView'
import BreakdownView from '@/components/BreakdownView/BreakdownView.vue'
import CardView from '@/components/CardView/CardView'
import TimelineView from '@/components/TimelineView/TimelineView'
import NoSeContainer from './NoSe/NoSeContainer'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

const delayForUpdateOnSplitModeChange = 500

export default {
  name: 'ListViewContainer',

  components: {
    NoSeContainer,
    TimelineView,
    CardView,
    BreakdownView,
    PivotTableView,
    ContentHasManyListMenu,
    ContentFullTextMenu,
    ListView,
  },

  props: {
    model: {
      default () { return {} },
      type: Object
    },
  },

  data () {
    return {
      loadItemsTimer: null,
      resetOverflowingTextTimer: null,
      fixedScrollTimer: null,
      previousHoverField: null,
      resizeTimer: null,
      splitModeChangeTimer: null,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  watch: {
    objectClass (objectClass, objectClassBefore) {
      if (objectClassBefore) { // If wasn't before, then is called on 'created'
        this.initialize()
      }
    },

    userId (value, oldValue) {
      // On user switch load Layout Profiles etc
      if (value && oldValue) {
        this.initialize()
      }
    },

    splitModeAltered (value) {
      if (!value) { return }
      // Must check that List is visible, as "revertSplit" is called in split_screen.js
      // when opening item from the list
      this.$nextTick(() => {
        // Change back on next tick so other components can also watch this
        this.splitModeAltered = false
      })
      if (!this.isListViewVisible()) { return }
      if (value && !this.loadingList) {
        this.setVisibleLayoutProfileTabsCount()
        this.$store.commit('hideMenuPopups')
        clearTimeout(this.splitModeChangeTimer)
        this.splitModeChangeTimer = setTimeout(() => {
          this.adjustCellWidthsAndLineHeight()
        }, delayForUpdateOnSplitModeChange)
      }
    },
  },

  created () {
    this.$store.dispatch('getQueryParameters')
    this.updateObjectClass()
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('click', this.handleClick)
    document.addEventListener('mouseup', this.listViewMouseUpListener)
    document.addEventListener('mousedown', this.listViewMouseDownHandler)
    this.initialize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('click', this.handleClick)
    document.removeEventListener('mouseup', this.listViewMouseUpListener)
    document.removeEventListener('mousedown', this.listViewMouseDownHandler)
  },

  updated () {
    this.$store.dispatch('getQueryParameters')
  },

  methods: {
    ...methods,

    listViewMouseDownHandler (e) {
      this.mouseDownCoords.x = e.pageX
      this.mouseDownCoords.y = e.pageY
    },

    isListViewVisible () {
      return !!(document.getElementById('vueList'))
    },

    initialize () {
      return new Promise(resolve => {
        this.resetList()
        this.loadingLayoutProfiles = true
        this.$store.dispatch('loadMetaAndLayoutProfiles', {
          objectClass: this.objectClass,
          cache: true,
        })
          .then(() => {
            this.loadingLayoutProfiles = false
            this.$nextTick(() => {
              this.setVisibleLayoutProfileTabsCount()
              setTimeout(() => {
                // Load main object states for selected object
                this.$store.dispatch('loadObjectStates', this.objectClass)
                this.$store.dispatch('getLayoutProfilesItemCount').then()
              }, 1000) // Give time for list item load requests to be sent
              resolve()
            })
          })
      })
    },

    handleResize () {
      this.setVisibleLayoutProfileTabsCount()
    },

    handleClick () {
      // Can be removed once everything in in Vue.js and using Routers
      // Currently Marionette menu component blocks events
      this.$store.dispatch('getQueryParameters')
    },

    updateObjectClass () {
      if (this.model && this.model.attributes &&
        this.model.attributes.resourcesName
      ) {
        this.objectClass = this.model.attributes.resourcesName
      }
    }
  }
}
</script>

<style lang="less">
body {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: var(--body-background-image);
  background-position: var(--body-background-position);
  background-color: white;
}
html {
	overflow-y: visible !important;
}
.title {
	letter-spacing: inherit !important;
	font-family: inherit;
	font-size: inherit !important;
	font-weight: inherit !important;
}
.application {
  font-family: 'Ubuntu', "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
.theme--light.application {
	background: inherit !important;
}
.v-btn--floating.v-btn--small {
	height: 30px !important;
	width: 30px !important;
}
.v-input__slot {
	margin-bottom: 0 !important;
}
.right {
	float: none !important;
}
.horizontal-split-mode-container {
  top: 23px;
  position: relative;
}
</style>
