// Pools are used to decide what content should be visible for the user
// and therefore rendered into DOM
// For each rowPool value one HTML node is placed, inside that node is placed
// one HTML node for each cellPool value
// When visible area is changed (user scrolled) pools are updated so that pools reserved
// for the content that is no longer visible are freed and reused for new visible content
// Very important is to keep DOM nodes in exact same position for
// content that was visible before scroll started and still visible after scroll ended
// Then this part of the DOM is not updated during scroll and performance is better

export default {
  // Update pools for rows
  // Each pool value is index from listItems
  // to indicate which list row should be rendered into that node
  updateRowPool: ({ state }) => {
    let rowPool = state.rowPool.slice(0)
    if (rowPool.length > state.lastItemIndex) {
      rowPool = []
    }
    const freePoolSlots = []
    if (rowPool.length > 0) {
      // Remove not visible items from rowPool
      rowPool.forEach((itemIndex, rowPoolIndex) => {
        if (itemIndex < state.firstItemIndex || itemIndex > state.lastItemIndex) {
          freePoolSlots.push(rowPoolIndex)
        }
      })
    }
    // Put visible items into rowPool
    for (let i = state.firstItemIndex; i < state.lastItemIndex; i++) {
      // Check if already in rowPool
      if (!rowPool.includes(i)) {
        if (freePoolSlots.length > 0) {
          rowPool[freePoolSlots.shift()] = i
        } else {
          rowPool.push(i)
        }
      }
    }
    state.rowPool = rowPool
  },

  // Update cell pool
  // cellPool includes names of visible columns
  // Not all columns are shown for the user, only columns that should be visible
  // depending on scroll position
  // This solution guarantees that list view is still fast even if ~100 columns selected
  // as visible in Layout Profile
  updateCellPool: ({ state }, columns) => {
    const cellPool = state.cellPool.slice(0)
    const freePoolSlots = []
    if (state.cellPool.length > 0) {
      // remove not visible items from rowPool
      cellPool.forEach((columnName, rowPoolIndex) => {
        if (!columns.includes(columnName)) {
          freePoolSlots.push(rowPoolIndex)
        }
      })
    }
    // Put visible columns into cellPool
    columns.forEach(columnName => {
      if (!cellPool.includes(columnName)) {
        if (freePoolSlots.length > 0) {
          cellPool[freePoolSlots.shift()] = columnName
        } else {
          cellPool.push(columnName)
        }
      }
    })
    state.cellPool = cellPool
  },
}
