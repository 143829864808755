<template>
  <v-toolbar-items
    :style="style"
    :class="forItem ? 'allow-scrolling-without-scrollbar item-layout-profiles-outer-container' : 'list-layout-profiles-outer-container'"
  >
    <LayoutProfileTab
      v-for="(layoutProfile, index) in layoutProfilesComputed"
      :key="layoutProfile.id + '_' + index"
      :layout-profile="layoutProfile"
      :parent-layout-profiles="layoutProfilesComputed"
      :index="index"
      :modal="modal"
      :item-edit="itemEdit"
    />
    <LayoutProfilesMoreMenu
      :for-item="forItem"
      :item-layout-profiles="itemLayoutProfiles"
      :visible-tabs-count="visibleTabsCount"
      :modal="modal"
    />
    <v-btn
      v-if="layoutProfiles.length > 0 && !modal"
      class="layout-profile-tab add-new-profile text-none px-3"
      :ripple="false"
      style="color: white;"
      tile
      text
      @click.stop="showAddNewLayoutProfilePopup($event)"
    >
      <div>
        <v-icon>
          fa-plus
        </v-icon>
      </div>
    </v-btn>
    <v-menu
      v-model="showAddNewForm"
      :position-x="formPosition.x"
      :position-y="formPosition.y"
      :close-on-content-click="false"
      :z-index="160"
      absolute
    >
      <LayoutProfileForm
        v-if="showAddNewForm"
        :layout-profile="defaultFormData"
        @close="closeLayoutProfileForm"
      />
    </v-menu>
  </v-toolbar-items>
</template>

<script>
import LayoutProfileTab from './LayoutProfileTab'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import LayoutProfilesMoreMenu from '@/components/LayoutProfiles/LayoutProfilesMoreMenu'
import LayoutProfileForm from '@/components/LayoutProfiles/LayoutProfileForm'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'LayoutProfileTabs',

  components: {
    LayoutProfileForm,
    LayoutProfilesMoreMenu,
    LayoutProfileTab,
  },

  props: {
    itemLayoutProfiles: {
      type: Array,
      default: () => { return [] },
    },
    forItem: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    itemEdit: {
      type: Boolean,
      default: false,
    },
    visibleItemLayoutProfileTabsCount: {
      type: Number,
      default: 100,
    },
  },

  data () {
    return {
      showAddNewForm: false,
      formPosition: {
        x: 0,
        y: 0,
      },
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    showTabsOnSeparateToolbar () {
      return this.forItem
        ? this.showItemTabsOnSeparateToolbar(this.itemLayoutProfiles)
        : this.$store.getters.showListTabsOnSeparateToolbar
    },

    layoutProfilesComputed () {
      const items = this.forItem ? this.itemLayoutProfiles : this.layoutProfiles
      return items.filter((layoutProfile, index) => {
        // Fits into tabs bar
        return this.visibleTabsCount === null || index < this.visibleTabsCount
      })
    },

    visibleTabsCount () {
      return this.forItem ? this.visibleItemLayoutProfileTabsCount : this.visibleLayoutProfileTabsCount
    },

    style () {
      return this.showTabsOnSeparateToolbar
        ? {
            paddingTop: '0px',
            width: '100%',
          }
        : {
            paddingTop: '29px',
            marginRight: '20px',
          }
    },

    defaultFormData () {
      return {
        action: this.forItem ? 'show' : 'index',
        controller: this.layoutProfilesComputed[0]?.controller || this.objectClass,
      }
    },
  },

  methods: {
    ...methods,

    closeLayoutProfileForm () {
      this.showAddNewForm = false
    },

    showAddNewLayoutProfilePopup (e) {
      this.showAddNewForm = true
      this.formPosition = {
        x: e.clientX,
        y: e.clientY,
      }
      this.$store.dispatch('loadLayoutProfileTemplates', this.forItem ? 'show' : 'index')
    }
  }
}
</script>

<style lang="scss">
.list-layout-profiles-outer-container::before, .item-layout-profiles-outer-container::before, .split-mode-file-toolbar:before {
  z-index: -1;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.show-layout-profiles-menu {
  cursor: pointer;
  div {
    display: flex;
    i {
      cursor: pointer;
      font-size: 12px;
      padding-left: 7px;
    }
  }
}
.add-new-profile {
  div {
    float: right;
  }
  i {
    font-size: 12px;
  }
}
/* .layout-profiles-inner-container {
  width: 10000px;
}*/
.allow-scrolling-without-scrollbar {
  overflow: auto;
  scrollbar-width: none;  /* Firefox */
}
.allow-scrolling-without-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>

<style>
.v-toolbar__items>div>.v-btn {
  border-radius: 0;
  height: 100%!important;
  max-height: none;
}
.badge {
  display: inline-block;
  min-width: 22px;
  padding: 3px 5px;
  font-size: 11px;
  height: 20px;
  border: 1px solid #e0e0e0;
  position: absolute;
  top: -21px;
  right: -6px;
  font-weight: 400;
  color: #fff;
  line-height: 12px;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  border-radius: 32px;
  text-indent: 0;
}
</style>
