import util from '../../utilities/sharedUtilities'
import api from '@/store/api'
import listQueries from '@/store/_listQueries'
import { ListEditSaveJob } from '@/types/ListEdit'
import { AxiosResponse } from 'axios'
import { BaseItem } from '@/types/AppTypes'
import { LPI } from '@/types/LP.types'

export default {
  addEventActionToQueue ({ eventButton, callback = null }) {
    this.initListRowBeforeEdit()
    // const oldValue = util.cloneValue(this.valueBeforeEdit)
    // const newValue = util.cloneValue(this.listItem[this.field.name])
    const job: ListEditSaveJob = {
      id: this.listItem.id,
      // Will be set later, right before sending the save request from the queue
      // But still setting here in case user moves away from this list view
      itemDataBeforeSave: util.cloneValue(this.listItem),
      // itemDataOnJobCreate: util.cloneValue(this.listItem),
      oldValue: null,
      newValue: null,
      field: this.field,
      eventButton,
      callback,
    }
    this.listEdit.queue.push(job)
    this.listEdit.queueLog.push(job)
  },

  addSaveJobToQueue ({ e, item, field }: {
    e?: MouseEvent | KeyboardEvent
    item: BaseItem
    field: LPI
  }) {
    this.$nextTick(() => {
      if (e) {
        this.moveRowDownOrUp({ e }) // Used only for Enter to move row down
        this.activateNextEditCellFromKey(e) // Used for Tab
      }
    })
    return new Promise(resolve => {
      if (!item.id) {
        return resolve(false)
      }
      const oldValue = this.$store.state.listEdit.serverItems[item.id]?.[field.name]
      // const oldValue = util.cloneValue(this.valueBeforeEdit)
      const newValue = util.cloneValue(item[field.name])
      if (JSON.stringify(oldValue) === JSON.stringify(newValue)) {
        return resolve(false)
      }
      console.log('old-vs-new', oldValue, newValue)
      if (e?.preventDefault) {
        e.preventDefault()
      }
      // this.valueBeforeEdit = util.cloneValue(newValue)
      const job: ListEditSaveJob = {
        id: item.id,
        // Will be set later, right before sending the save request from the queue
        // But still setting here in case user moves away from this list view
        itemDataBeforeSave: util.cloneValue(item),
        // itemDataOnJobCreate: util.cloneValue(item),
        oldValue,
        newValue,
        field,
      }
      console.log('PUSH', job)
      this.$store.state.listEdit.queue.push(job)
      this.$store.state.listEdit.queueLog.push(job)
      resolve(true)
    })
  },

  saveListCellValue (job: ListEditSaveJob) {
    // console.log('_______saveListCellValue', job)
    return new Promise(resolve => {
      const item = JSON.parse(JSON.stringify(job.itemDataBeforeSave))
      const field = job.field
      item[field.name] = job.newValue

      // console.log('Has value changed??')
      // console.log(field.name, JSON.stringify(job.oldValue), 'vs', JSON.stringify(job.newValue),
      //  JSON.stringify(job.oldValue) === JSON.stringify(job.newValue))
      // No changes? Skip API call
      if (JSON.stringify(job.oldValue) === JSON.stringify(job.newValue) && !job.eventButton) {
        console.log('OLD and NEW value are the same', job.newValue)
        return resolve(true)
      }

      // console.log('saving this cell value', this.listEdit.current.item, this.listEdit.current.field, this.listEdit.current.field?.name)
      // console.log(this.listEdit.current.item[this.listEdit.current.field?.name])
      const saveFields = {}
      saveFields[field.name] = field
      const itemPayload = this.getItemSavePayload(item, saveFields)
      const queries = listQueries.get(this.$store.state.layoutProfileItems, this.$store.state.selectedLayoutProfile, {
        locale: this.$store.state.locale,
      })
      const value = item[field.name]?.['@class'] // For reference
        ? {
            '@class': item[field.name]['@class'],
            id: item[field.name].id,
          }
        : item[field.name]
      const payload = {} as any
      if (job.eventButton) {
        const key = job.eventButton.type === 'processAction' ? 'event_action' : 'event'
        payload[key] = job.eventButton.identifier
      } else {
        payload.value = value
      }
      api.saveValueFromList(this.objectClass, itemPayload, queries, field.name, payload)
        .then((response: AxiosResponse) => {
          if (job.callback) { // Release the loader
            job.callback()
          }
          this.$store.dispatch('globalErrorDisplay', { response, context: 'Save list value' }).then(errorsCheck => {
            // Update cell response error
            this.$set(this.$store.state.listEdit.errors, job.id + '_' + field.name, errorsCheck?.displayMessage || '')
            if (response.data.item) {
              this.$store.dispatch('updateListItemDataAfterCellEdit', { item: response.data.item, job })
              resolve(true)
            } else {
              resolve(false)
            }
          })
        })
    })
  },

  // Restore the value on ESC key
  restoreCurrent () {
    const serverItem = this.listEdit.serverItems[this.listItem.id]
    // console.log('___restoreCurrent', util.cloneValue(this.listEdit.current.valueBeforeEdit), util.cloneValue(this.listEdit.current.unitValueBeforeEdit))
    this.listItem[this.field.name] = util.cloneValue(serverItem[this.field.name])
    // Restore unit or price
    // if (['price', 'quantity', 'decimal'].includes(this.field.type)) {
    //  const isPrice = this.field.type === 'price'
    //  this.listItem[this.field.name + (isPrice ? '_currency' : '_unit')] = util.cloneValue(this.listEdit.current.unitValueBeforeEdit)
    // }
    // this.emptyListEditCurrentProps()
    // this.emptyListEditNextProps()
  },

  // Used on Tab key press to activate next (or prev) cell
  activateNextEditCellFromKey (e) {
    if (e?.code === 'Tab' && !e.shiftKey) {
      e.preventDefault()
      this.activateNextEditableCell()
    } else if (e?.code === 'Tab') {
      e.preventDefault()
      this.activatePreviousEditableCell()
    }
  },

  // Find next editable cell on the right and activate it
  activateNextEditableCell () {
    for (let i = 0; i <= 25; i++) {
      const startColIndex = i === 0 ? this.currentIndex + 1 : 0
      const item = this.listItems?.[this.itemIndex + i]
      if (!item?.id) { break }
      const found = this.activateNextEditableCellForItem(item, startColIndex)
      if (found) { break }
    }
  },

  activatePreviousEditableCell () {
    for (let i = 0; i <= 25; i++) {
      const startColIndex = i === 0 ? this.currentIndex - 1 : this.visibleLayoutProfileItems.length
      const item = this.listItems?.[this.itemIndex - i]
      if (!item?.id) { break }
      const found = this.activatePreviousEditableCellForItem(item, startColIndex)
      if (found) { break }
    }
  },

  isItemCellEditableForNavigation (item: BaseItem, field: LPI) {
    const isEventButton = ['event', 'action'].includes(field.type) && item[field.name]?.length
    const isEditableInList = (item['@editable_members']?.includes(field.name) && field.editable_in_list) || isEventButton
    const isRendered = this.cellPool.includes(field?.name)
    if (isEditableInList && isRendered && (this.isSupportedListEditField(field) || isEventButton)) {
      return true
    }
  },

  activateNextEditableCellForItem (item: BaseItem, startColIndex = 0) {
    for (let i = startColIndex; i < this.visibleLayoutProfileItems.length; i++) {
      const field = this.visibleLayoutProfileItems[i] || {}
      if (this.isItemCellEditableForNavigation(item, field)) {
        this.activateEditField(item, this.itemIndex, field)
        return true
      }
    }
    return false
  },

  // Find next editable cell on the left and activate it
  activatePreviousEditableCellForItem (item, startColIndex = 0) {
    for (let i = startColIndex; i >= 0; i--) {
      const field = this.visibleLayoutProfileItems[i] || {}
      if (this.isItemCellEditableForNavigation(item, field)) {
        this.activateEditField(item, this.itemIndex, field)
        return true
      }
    }
  },

  isSupportedListEditField (field: LPI) {
    return this.$store.state.supportedListEditTypes.includes(field.type) &&
      field.widget !== 'address' &&
      !field.dynamic
  },

  // Shared method to activate field on (Shift+) Tab key press
  activateEditField (listItem: BaseItem, itemIndex: number, editableField: LPI, scrollToFocus = true) {
    if (!listItem?.['@editable_members']?.includes(editableField.name) && ['event', 'action'].includes(editableField.type)) {
      // return
    }
    // If new active cell is boolean, have to manually remove focus from any previous input
    if (['boolean', 'event', 'action'].includes(editableField.type)) {
      const focusedElement = document.activeElement as HTMLInputElement
      if (focusedElement) {
        focusedElement.blur()
      }
    }
    // Set item, field and current value
    this.listEdit.current.item = listItem
    this.listEdit.current.field = editableField
    this.listEdit.current.saveCallback = this.saveListCellValue
    if (scrollToFocus) {
      // TODO - not the best feature? jumps
      // Or maybe scroll if is on the very edge or out of the view area
      // this.scrollToEditableField(itemIndex, editableField)
    }
  },

  // Key press handler to select editable row from next or previous row
  moveRowDownOrUp ({ e }) {
    // console.log('moveRowDownOrUp', e?.key)
    if (!['ArrowUp', 'ArrowDown', 'Enter'].includes(e?.key)) {
      return
    }
    if (['ArrowUp', 'ArrowDown'].includes(e?.key) &&
      ![
        'event',
        'action',
        'string',
        'numeric',
        'price',
        'boolean',
        'quantity',
        'percentage',
        'decimal',
      ].includes(this.field.type)) {
      return
    }
    e.preventDefault()
    const editableField = this.listEdit.current.field
    const isEventButton = ['event', 'action'].includes(editableField.type)
    const moveToRowIndex = ['ArrowDown', 'Enter'].includes(e.key)
      ? this.itemIndex + 1
      : this.itemIndex - 1
    const editItem = this.listItems?.[moveToRowIndex]
    // If not editable, skip
    if (!editItem?.['@editable_members'].includes(editableField.name) && !isEventButton) { return }
    if (editItem) {
      this.listEdit.current.item = editItem
      this.listEdit.current.field = editableField
      this.listEdit.current.saveCallback = this.saveListCellValue
    }
  },

  // User has requested to edit specific cell value
  // Checks if previously another cell was being edited
  // and saves its value before activating this new requested cell
  requestEditForSelectedCell () {
    this.initListRowBeforeEdit()
    this.setListEditProps('current')
  },

  initListRowBeforeEdit () {
    // Set list row serve item, after item cell value save used to compare what data has changed
    if (!this.listEdit.serverItems[this.listItem.id]) {
      this.listEdit.serverItems[this.listItem.id] = JSON.parse(JSON.stringify(this.listItem))
    }
  },

  emptyListEditNextProps () {
    this.listEdit.next.item = null
    this.listEdit.next.field = null
    this.listEdit.next.saveCallback = null
  },

  emptyListEditCurrentProps () {
    this.listEdit.current.item = null
    this.listEdit.current.field = null
    this.listEdit.current.saveCallback = null
  },

  setListEditProps (which, from = null) {
    // Set current value which will be used on ESC key - cancelling user changes
    this.listEdit.editing = true
    this.listEdit[which].item = from ? this.listEdit[from].item : this.listItem
    this.listEdit[which].field = from ? this.listEdit[from].field : this.field
    this.listEdit[which].saveCallback = from ? this.listEdit[from].saveCallback : this.saveListCellValue
  },
}
