<template>
  <v-dialog
    v-model="showBulkDeleteDialog"
    width="500"
  >
    <v-card
      v-if="deleting"
      class="multi-delete-loader"
    >
      <v-progress-linear
        :indeterminate="true"
        height="3"
        color="teal"
      />
    </v-card>

    <v-card v-else>
      <v-card-title class="headline">
        {{ $i18n.t('aava.confirmations.dialog_title') }}
      </v-card-title>

      <v-card-text v-if="selectedItems.length <= 100">
        {{ $i18n.t('aava.confirmations.' + (selectedItems.length === 1
          ? 'destroy_object'
          : 'destroy_objects')
        ) }}
      </v-card-text>
      <v-card-text v-else>
        {{ $i18n.t('aava.messages.delete_too_many_items') }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="green darken-1"
          text
          @click="cancel()"
        >
          {{ $i18n.t('aava.actions.cancel') }}
        </v-btn>

        <v-btn
          v-if="selectedItems.length <= 100"
          color="red darken-1"
          text
          @click="confirmBulkDelete()"
        >
          {{ $i18n.t('aava.actions.remove') }} {{ selectedItems.length }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {

  name: 'HeaderActionsConfirmBulkDelete',

  data () {
    return {
      deleting: false
    }
  },

  computed: {
    ...mapFields(Object.keys(state))
  },

  methods: {
    ...methods,

    showDestroyMessages (result) {
      const messages = []
      if (result && result.items && result.items.length > 0) {
        messages.push({
          message: this.$i18n.t('aava.index.messages.items_destroyed',
            { items: result.items.length }),
          type: 'info'
        })
      }
      if (result && result.messages) {
        const errors = result.messages.filter(message => message.type === 'error')
        if (errors.length > 0) {
          messages.push({
            message: this.$i18n.t('aava.index.messages.failed_to_destroy_items',
              { items: errors.length }),
            type: 'error'
          })
        }
      }
      if (messages.length > 0) {
        this.$store.dispatch('addFlashMessages', messages)
      }
    },

    confirmBulkDelete () {
      this.deleting = true
      this.$store.dispatch('deleteBulk', this.selectedItems.map(selectedIndex => {
        return this.listItems[selectedIndex].id
      })).then(result => {
        this.showDestroyMessages(result)
        this.showBulkDeleteDialog = false
        this.deleting = false
        this.$store.dispatch('clearSelected')
        this.reloadListItems()
      })
    },

    cancel () {
      this.showBulkDeleteDialog = false
    }
  }
}
</script>

<style>
.multi-delete-loader {
  padding: 20px;
}
</style>
