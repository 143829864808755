<template>
  <v-menu
    v-model="showMenu"
    :z-index="160"
    offset-y
    close-on-click
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="'#ddd'"
        class="text-none"
        text
        tile
        depressed
        v-bind="attrs"
        v-on="on"
      >
        <i class="fa fa-download icon-right-space" />
        {{ $vuetify.breakpoint.lgAndUp && splitMode !== 'vertical' ? $i18n.t('aava.download.title') : '' }}
      </v-btn>
    </template>
    <div class="popup-items-container list-download-menu">
      <div v-if="generatingReport">
        <v-progress-linear
          :indeterminate="true"
          height="3"
          color="teal"
        />
      </div>
      <template v-else-if="metaInfo && metaInfo.exports">
        <div
          v-for="(exportOption, index) in metaInfo.exports"
          :key="index + '_option'"
          class="popup-menu-el"
          @click="confirmRequestReport(exportOption.format)"
        >
          <span :class="iconByFormat(exportOption.format)" />
          {{ exportOption.label }}
        </div>
      </template>
      <div
        v-if="storedReports && storedReports.length > 0"
        class="pl-3 pt-2 pb-1"
      >
        {{ $i18n.t('aava.index.download.recent_reports') }}
      </div>
      <div v-if="loadingStoredReports">
        <v-progress-linear
          :indeterminate="true"
          height="3"
          color="teal"
        />
      </div>
      <div
        v-for="(report, reportIndex) in storedReports"
        :key="reportIndex"
        class="popup-menu-el"
        @click="openReportById(report.id)"
      >
        <span class="fa fa-download" />
        {{ report.name }}
      </div>
    </div>
  </v-menu>
</template>

<script>
import util from '../../utilities/sharedUtilities'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import listViewAPI from '@/store/api'
import Confirm from '@/methods/confirm'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

const defaultReportItemsLimit = 2500

export default {
  name: 'ListDownloadMenu',

  data () {
    return {
      showMenu: false,
      storedReports: [],
      loadingStoredReports: false,
      generatingReport: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state))
  },

  watch: {
    showMenu (value) {
      if (!value) { return }
      this.storedReports = []
      this.loadingStoredReports = true
      listViewAPI.getStoredReports(util.objectClassSingularName(this.objectClass))
        .then(reports => {
          this.storedReports = reports.data.items
          this.loadingStoredReports = false
        })
    }
  },

  methods: {
    ...methods,

    showDownload (e) {
      this.hideAllListHeaderActionMenus()
      this.showHeaderActionsDownloadMenu = true
      this.$nextTick(() => {
        util.positionPopupMenu(e, 'list-download-menu', 0, 0, {
          attachEl: this.$refs.listDownloadButton.$el
        })
      })
    },

    iconByFormat (format) {
      if (format === 'xls') return 'fa fa-file-excel'
      if (format === 'csvx') return 'fa fa-file-csv'
      return 'fa fa-download'
    },

    confirmRequestReport (reportFormat) {
      const limit = this.systemConfigs?.export_warning_limit || defaultReportItemsLimit
      const itemCount = this.selectedItems.length || this.listTotalCount
      if (itemCount > limit) {
        Confirm.request(this.$i18n.t('aava.confirmations.big_export'), () => {
          this.requestReport(reportFormat)
        })
      } else {
        this.requestReport(reportFormat)
      }
    },

    requestReport (reportFormat) {
      this.generatingReport = true
      this.$store.dispatch('addFlashMessage', {
        message: this.$i18n.t('aava.index.download.start_message'),
        type: 'info',
      })
      this.$store.dispatch('sendReportExportRequest', reportFormat)
        .then(report => {
          this.generatingReport = false
          this.openReportById(report.report_id)
          this.$store.dispatch('addFlashMessage', {
            message: this.$i18n.t('aava.index.download.success'),
            type: 'info',
          })
        }, (reason) => {
          if (reason.statusText === 'timeout') {
            this.$store.dispatch('addFlashMessage', {
              message: this.$i18n.t('aava.index.download.timeout'),
              type: 'warning',
            })
          } else {
            this.$store.dispatch('addFlashMessage', {
              message: this.$i18n.t('aava.index.download.error') + ' ' +
                reason.statusText,
              type: 'error',
            })
          }
        })
    },

    openReportById (id) {
      window.open(this.config.storedReportsDirectory + id)
    },
  },
}
</script>

<style lang="scss">
.list-download-menu {
  max-width: 350px;
  min-width: 200px;
  max-height: 400px;
}
</style>
