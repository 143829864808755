export default {
  listHeight () {
    if (this.$store.state.splitMode === 'horizontal') {
      const padding = 8
      const layoutProfileTabsRowHeight = 35
      let height = (this.$store.state.innerHeight / 100 * this.$store.state.splitModeListSize) -
        this.topOffset + padding + layoutProfileTabsRowHeight
      if (this.$store.state.suppressToolbar) {
        height -= 35
      }
      return height
    }
    return this.$store.state.innerHeight - this.topOffset
  },

  alternativeViewHeight () {
    return this.listHeight + 50
  },

  alternativeViewStyle () {
    return 'height: ' + this.alternativeViewHeight + 'px; overflow: auto;'
  },

  topOffset () {
    return this.$store.state.suppressToolbar
      ? 54
      : this.$store.getters.showListTabsOnSeparateToolbar
        ? 190
        : 155
  },
}
