
import ShortcutAddExternalLink from '@/components/Shortcuts/ShortcutAddExternalLink.vue'
import shortcutData from '@/store/dashboardItems/helpers/shortcutData'
import Sortable from 'sortablejs'
import { DashboardTypes } from '@/types/DashboardTypes'
import dashboardMethods from '@/components/Dashboard/dashboardMethods'
import util from '../../utilities/sharedUtilities'

export default {
  name: 'ShortcutsList',

  components: {
    ShortcutAddExternalLink,
  },

  data () {
    return {
      render: true,
      showShortcuts: false,
      show_form: false,
      edit_mode: false,
      opened_new_tab_ts: 0,
    }
  },

  computed: {
    shortcuts (): DashboardTypes.Shortcut[] {
      return this.$store.state.shortcuts.filter(shortcut => {
        return shortcut.details?.link_type === 'internal' ||
          util.isValidHttpsURL(shortcut.details?.external_link) // Prevent executing JS which this same user added ;)
      })
    },
    loadingShortcuts () {
      return this.$store.state.loadingShortcuts
    },
  },

  created () {
    this.createShortcutsSortables()
  },

  methods: {
    ...dashboardMethods,

    createShortcutsSortables () {
      if (this.$store.state.isMobileOrTablet) { return }
      this.$nextTick(() => {
        this.sortable = null
        const el = document.getElementById('side-shortcuts')
        if (!el) { return }
        this.sortable = Sortable.create(el, {
          onEnd: this.shortcutDragEnd,
          // delay: 300, No need for delay as not supported on mobile
          // because holding the finger for certain time opens mobile browser menu
          // and sorting is not possible anyway
        })
      })
    },

    shortcutDragEnd (move) {
      const temp = this.shortcuts.splice(move.oldIndex, 1)[0]
      this.shortcuts.splice(move.newIndex, 0, temp)
      // Re-render for new order to be shown
      // Without it sortable new order and vuex new order are conflicted
      this.reRender()
      this.$store.commit('updateShortcutsOrder', this.shortcuts)
    },

    reRender () {
      this.render = false
      this.$nextTick(() => {
        this.render = true
        this.$nextTick(() => {
          this.createShortcutsSortables()
        })
      })
    },

    show () {
      this.showShortcuts = true
    },

    shortcutIcon (shortcut: DashboardTypes.Shortcut) {
      return shortcutData.shortcutIcon(shortcut)
    },

    hide () {
      if (!this.show_form && !this.loadingShortcuts) {
        this.showShortcuts = false
        this.show_form = false
        this.edit_mode = false
      }
    },

    closeForm () {
      this.show_form = false
      this.edit_mode = false
      this.createShortcutsSortables()
    },

    openForm () {
      this.show_form = true
    },

    toggleEditMode () {
      this.edit_mode = !this.edit_mode
    },
  }
}
