<template>
  <div>
    <v-btn
      text
      color="warning"
      class="text-none"
      @click.stop="$store.dispatch('clearSelected')"
    >
      <i
        class="fa fa-times icon-right-space"
      />
      {{ $vuetify.breakpoint.lgAndUp && splitMode !== 'vertical'
        ? $i18n.t('aava.actions.clear')
        : '' }} ({{ selectedItems.length }})
    </v-btn>
    <v-btn
      text
      color="error"
      class="text-none"
      @click.stop="confirmDeleteSelected()"
    >
      <i
        class="fa fa-trash-alt icon-right-space"
      />
      {{ $vuetify.breakpoint.lgAndUp && splitMode !== 'vertical'
        ? $i18n.t('aava.actions.remove')
        : '' }} ({{ selectedItems.length }})
    </v-btn>
  </div>
</template>

<script>
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'HeaderActionsSelectionButtons',

  computed: {
    ...mapFields(Object.keys(state))
  },

  methods: {
    ...methods
  }
}
</script>
