<template>
  <table
    class="list-columns"
  >
    <thead>
      <tr class="tr">
        <th
          v-for="(field, cellIndex) in visibleLayoutProfileItems"
          :id="field.name + '_th'"
          :key="cellIndex"
          :ref="'th_' + cellIndex"
          :class="'th0 attr_header_' + field.name"
          :style="style(field, cellIndex)"
        >
          <HeaderColumnInput
            :field="field"
            :cell-index="cellIndex"
            :field-value="field.name === 'main_object_state' ? computedStateFilterValue : listFilters[field.name]"
            class="d-inline"
            @updateFieldValue="updateFieldValue"
          />
          <HeaderColumnResize
            :field="field"
            class="d-inline"
          />
        </th>
        <th v-if="showResetWidthsBtn">
          <v-icon
            class="ml-2"
            color="grey"
            small
            @click="resetColumnWidths"
          >
            fa-arrow-right
          </v-icon>
        </th>
      </tr>
    </thead>
  </table>
</template>

<script>
import HeaderColumnInput from './HeaderColumnInput'
import HeaderColumnResize from './HeaderColumnResize'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ListHeaderColumnsRow',

  components: {
    HeaderColumnInput,
    HeaderColumnResize
  },

  data () {
    return {
      timer: null
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    computedStateFilterValue: {
      get () {
        return this.$store.getters.listFiltersGetStateDisplayValue
      },
      set () {
        // Do nothing as user input is not allowed,
        // can only select from list
      }
    },

    visibleLayoutProfileItems () {
      return this.layoutProfileItems.filter(field => field.visible)
    },

    showResetWidthsBtn () {
      // If at least one column has fixed width, show reset right arrow btn
      return this.layoutProfileItems.filter(layoutProfile => !!layoutProfile.userFixedWidth).length > 0
    },
  },

  methods: {
    ...methods,

    resetColumnWidths () {
      this.$store.dispatch('resetListColumnWidths')
      this.adjustCellWidthsAndLineHeight()
    },

    style (field, cellIndex) {
      const fixedWidth = field.userFixedWidth || field.forcedWidth
      const style = fixedWidth
        ? {
            minWidth: fixedWidth + 'px',
            maxWidth: fixedWidth + 'px'
          }
        : {}
      if (field.name === this.draggingFieldName) {
        style.background = '#fff13d'
      }
      if (cellIndex + 1 === this.visibleLayoutProfileItems.length) {
        style.overflow = 'hidden'
      }
      return style
    },

    updateFieldValue ({ field, value }) {
      if (field.name !== 'main_object_state') {
        this.$store.dispatch('setListFilter', { fieldName: field.name, value: value })
      }
    }
  }
}
</script>

<style langs="scss">
.th0 {

}
</style>

<style lang="less">
.list-columns {
	position: sticky;
	z-index: 2;
	background: var(--list_header_row_background_color);
	border-collapse: collapse;
	left: 0px;
	top: 0px;
	tr {
		th {
			padding: 4px 3px 4px 3px;
			text-overflow: ellipsis;
			line-height: 17px;
      position: relative;
		}
	}
}
.th>.v-input--is-disabled {
	&:not(.v-input--is-readonly) {
		pointer-events: inherit !important;
	}
}
</style>
