export default {
  DASHBOARD_OBJECT_COLUMNS: 3,
  DECIMAL_TYPES: ['quantity', 'price', 'percentage', 'decimal'],

  DASHBOARD_MAX_COLUMNS: 3,
  DASHBOARD_DEFAULT_ROWS: 2,
  ONE_OBJECT_HEIGHT_PX: 38,
  SPACE_BETWEEN_OBJECTS_PX: 16,
  SCROLLBAR_WIDTH_PX: 20,
  DASHBOARD_APP_MARGIN: 7,
  PREVIEW_BOX_MARGIN_PX: 13,
  PREVIEW_BOX_MARGIN_TABLET_PX: 10,
  PREVIEW_BOX_MARGIN_MOBILE_PX: 7,
  PREVIEW_BOX_MIN_WIDTH_PX: 360,
  TOP_MENU_HEIGHT_PX: 50,
  BOX_TITLE_HEIGHT_PX: 65,
  MAIN_REGION_TOP_MARGIN: 105,
  PROGRESS_STATE_CELL_MIN_PX: 100,
  MIN_HEIGHT_FOR_2_ROWS: 400,

  // Did set this high as when adding-removing dashboard items a lot, can request 20, but response has only few.
  REQUEST_DASHBOARD_ITEMS_LIMIT: 100,
  ADD_ITEM_BOX_TITLE_HEIGHT_PX: 40,
  ADD_ITEM_BOX_SUB_TITLE_HEIGHT_PX: 30,
  ADD_ITEM_OPTION_MAX_WIDTH_PX: 200,
  DASHBOARD_SHORTCUTS_HORIZONTAL_LINE_HEIGHT_PX: 33,
  CHART_TITLE_SIZE: 17,
  CHART_TITLE_FONT: '#5e5d5d',
  CHART_PIE_BORDER_COLOR: '#ffffff',
  CHART_LINE_COLORS: [
    'rgba(38, 163, 94, 0.7)', // '#26a35e', // .green
    'rgba(211, 61, 68, 0.7)', // '#d33d44', // .red
    'rgba(153, 153, 153, 0.7)', // '#999999', // .gray
    'rgba(224, 211, 0, 0.7)', // '#e0d300', // .yellow mid
    'rgba(181, 165, 43, 0.7)', // '#b5a52b', // .yellow dark
    'rgba(242, 107, 96, 0.7)', // '#f26b60', // .red light
    'rgba(43, 43, 61, 0.7)', // ''#2b2b3d', //  .blue dark
    'rgba(38, 163, 94, 0.7)', // '#26a35e', // .green
    'rgba(211, 61, 68, 0.7)', // '#d33d44', // .red
    'rgba(153, 153, 153, 0.7)', // '#999999', // .gray
    'rgba(224, 211, 0, 0.7)', // '#e0d300', // .yellow mid
    'rgba(181, 165, 43, 0.7)', // '#b5a52b', // .yellow dark
    'rgba(242, 107, 96, 0.7)', // '#f26b60', // .red light
    'rgba(43, 43, 61, 0.7)' // ''#2b2b3d', //  .blue dark
  ],
  CHART_COLORS: [
    'rgba(38, 163, 94, 0.7)', // '#26a35e', // .green
    'rgba(153, 153, 153, 0.7)', // '#999999', // .gray
    'rgba(255, 255, 0, 0.7)', // '#ffff00', // .yellow
    'rgba(242, 107, 96, 0.7)', // '#f26b60', // .red light
    'rgba(43, 43, 61, 0.7)', // ''#2b2b3d', //  .blue dark
    'rgba(224, 211, 0, 0.7)', // '#e0d300', // .yellow mid
    'rgba(28, 119, 91, 0.7)', // '#1c775b', // .green dark
    'rgba(181, 165, 43, 0.7)', // '#b5a52b', // .yellow dark
    'rgba(211, 61, 68, 0.7)', // '#d33d44', // .red
    'rgba(53, 53, 56, 0.7)', // '#353538', // .gray dark
    'rgba(252, 249, 124, 0.7)', // '#fcf97c', // .yellow light
    'rgba(38, 163, 94, 0.7)', // '#26a35e', // .green
    'rgba(153, 153, 153, 0.7)', // '#999999', // .gray
    'rgba(255, 255, 0, 0.7)', // '#ffff00', // .yellow
    'rgba(242, 107, 96, 0.7)', // '#f26b60', // .red light
    'rgba(43, 43, 61, 0.7)', // ''#2b2b3d', //  .blue dark
    'rgba(224, 211, 0, 0.7)', // '#e0d300', // .yellow mid
    'rgba(28, 119, 91, 0.7)', // '#1c775b', // .green dark
    'rgba(181, 165, 43, 0.7)', // '#b5a52b', // .yellow dark
    'rgba(211, 61, 68, 0.7)', // '#d33d44', // .red
    'rgba(53, 53, 56, 0.7)', // '#353538', // .gray dark
    'rgba(252, 249, 124, 0.7)', // '#fcf97c', // .yellow light
    'rgba(38, 163, 94, 0.7)', // '#26a35e', // .green
    'rgba(153, 153, 153, 0.7)', // '#999999', // .gray
    'rgba(255, 255, 0, 0.7)', // '#ffff00', // .yellow
    'rgba(242, 107, 96, 0.7)', // '#f26b60', // .red light
    'rgba(43, 43, 61, 0.7)', // ''#2b2b3d', //  .blue dark
    'rgba(224, 211, 0, 0.7)', // '#e0d300', // .yellow mid
    'rgba(28, 119, 91, 0.7)', // '#1c775b', // .green dark
    'rgba(181, 165, 43, 0.7)', // '#b5a52b', // .yellow dark
    'rgba(211, 61, 68, 0.7)', // '#d33d44', // .red
    'rgba(53, 53, 56, 0.7)', // '#353538', // .gray dark
    'rgba(252, 249, 124, 0.7)' // '#fcf97c', // .yellow light
  ],
}
