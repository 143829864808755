import i18n from '@/locale/i18n'
import date_comparers from './utils/date_comparers'
import translateAttribute from '@/utilities/translateAttribute'

export default {
  _translateKey (objectClass, key) {
    return translateAttribute(objectClass, key, this.locale, i18n)
  },

  _sorter (name) {
    if (name.includes(' - ' + i18n.t('aava.index.pivottable.monthly'))) {
      return date_comparers.monthlyComparer
    } else if (name.includes(' - ' + i18n.t('aava.index.pivottable.weekday'))) {
      return date_comparers.weekdayComparer
    } else if (name.includes(' - ' + i18n.t('aava.index.pivottable.daily'))) {
      return date_comparers.dailyComparer
    } else if (name.includes(' - ' + i18n.t('aava.index.pivottable.time_of_day'))) {
      return date_comparers.timeOfDayComparer
    } else if (name.includes(' - ' + i18n.t('aava.index.pivottable.hourly'))) {
      return date_comparers.hourlyComparer
    }
  },

  // attribute printing helper, most likely similar exist somewhere already
  _attributeToString (attribute, attributeCache) {
    if (attribute === null) { return attribute }
    if (typeof attribute !== 'object') { return attribute }
    if (attribute['@class'] === 'Unit') {
      return attribute.identifier
    } else {
      return attributeCache && attributeCache.reference_attribute
        ? attribute[attributeCache.reference_attribute]
        : attribute.summary
    }
  },

  pageSize (count) {
    if (count / 10 < 25) {
      return 25
    } else if (count / 10 > 100) {
      return 100
    }
    return Math.floor(count / 10)
  },
}
