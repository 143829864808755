<template>
  <v-text-field
    v-model="$store.state.superSearchTerm"
    class="regular-vinput super-search-input"
    :label="$i18n.t('aava.index.super_search_placeholder')"
    prepend-icon="fa-search"
    spellcheck="false"
    autocomplete="off"
    color="#F57C00"
    single-line
    hide-details
    dense
    @click:prepend="$emit('reloadListItems')"
    @keyup.enter="$emit('reloadListItems')"
  />
</template>

<script>
export default {
  name: 'ListSuperSearch',
}
</script>

<style lang="scss">
.super-search-input {
  position: relative;
  top: 0;
  max-width: 150px !important;
  padding: 0 16px;
  color: #777;
  .v-input__slot:before {
    border-color: white !important;
  }
  input {
    font-size: 16px !important;
    line-height: 20px !important;
    margin-bottom: 2px;
    color: #F57C00 !important;
  }
  .v-input__prepend-outer {
    margin: 2px 5px 0 0 !important;
  }
  .fa-search {
    font-size: 14px !important;
  }
  label {
    font-size: 14px !important;
    line-height: 17px !important;
    margin-bottom: 6px;
    text-transform: capitalize;
  }
}
</style>
