
export default {
  name: 'LayoutEditorToolbar',
  props: {
    resettingLayout: {
      type: Boolean,
      required: true,
    },
    savingItemLayout: {
      type: Boolean,
      required: true,
    },
  },
}
