export default {
  listTargetResources: null,
  listTargetId: null,
  listTargetAssoc: null,
  loadingList: true,
  loadingListItems: true,
  objectClass: null,
  metaInfo: {}, // LP.Meta
  pagesRetrieved: [],
  preloadTimer: null,
  reFetchingItemId: null,
  listHeaderDateFilterField: null,
  listHeaderDateFilterLeft: null,
  sortField: 'created_at',
  sortBy: 'asc',
  secondarySortings: {},
  listFilters: {},
  // Store filter value for focused field
  // Is used to decide if 'pin' icon should be displayed for a field (only if filter value is positive)
  // And when 'pin' clicked then use this value to set listFilters value in store
  focusedFieldFilterValue: null,
  objectStatesByClass: {},
  superSearchTerm: null,
  // When load is started, hash is generated from filters
  // once respose is received, current hash (possibly with updated filters) is checked
  listLoadHash: null
}
