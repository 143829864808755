var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{attrs:{"z-index":210,"max-width":"300","offset-y":"","close-on-click":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({class:_vm.isMobileBottomToolbar ? 'mobile-toolbar-btn grey--text text--darken-1' : 'mx-1',attrs:{"dark":!_vm.compact && !_vm.isMobileBottomToolbar,"icon":_vm.compact,"disabled":_vm.itemActions.length === 0,"block":_vm.isMobileBottomToolbar,"color":_vm.isMobileBottomToolbar ? 'grey lighten-3' : 'grey',"data-cy":"item-actions-btn","depressed":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{class:_vm.isMobileBottomToolbar ? 'pr-2' : _vm.compact ? '' : 'mr-2',attrs:{"small":""}},[_vm._v(" fa-bars ")]),_vm._v(" "+_vm._s(_vm.compact ? '' : _vm.$i18n.t('aava.actions.title'))+" ")],1)]}}])},[_c('div',{staticClass:"popup-items-container"},[_vm._l((_vm.itemActions),function(action,index){return [(action.query && action.query.type === 'list')?_c('v-menu',{key:index,attrs:{"z-index":220,"left":"","content-class":_vm.isMobileBottomToolbar ? 'item-meta-action-options' : '',"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"popup-menu-el"},'div',attrs,false),on),[_c('a',{attrs:{"href":"javascript:;"}},[_c('span',{staticClass:"fa fa-play"}),_vm._v(" "+_vm._s(action.label)+" ")])])]}}],null,true)},[_c('div',{staticClass:"popup-items-container"},_vm._l((action.query.values),function(subButton,index2){return _c('div',{key:index2,staticClass:"popup-menu-el",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('sendItemMetaAction', {
                action,
                view: 'item',
                value: subButton.value,
              })}}},[_vm._v(" "+_vm._s(subButton.label)+" ")])}),0)]):_c('div',{key:index,staticClass:"popup-menu-el",on:{"click":function($event){return _vm.$emit('sendItemMetaAction', {
            action,
            view: 'item',
          })}}},[_c('a',{attrs:{"href":"javascript:;"}},[_c('span',{staticClass:"fa fa-play"}),_vm._v(" "+_vm._s(action.label)+" ")])])]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }