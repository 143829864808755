var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-6 pt-0 pb-8 kpi-dashboard fill-height",style:(_vm.style),attrs:{"fluid":""}},[(_vm.render)?_c('v-row',_vm._l((_vm.blocks),function(block,chart_i){return _c('v-col',{key:chart_i,staticClass:"pa-4",attrs:{"cols":"12","md":"6","lg":"4"},on:{"click":function($event){return _vm.$emit('showModal', chart_i)}}},[_c('v-container',{staticClass:"elevation-5",staticStyle:{"position":"relative","cursor":"pointer","border-radius":"8px","overflow":"hidden","background":"white","height":"280px"}},[(_vm.showLevels)?_c('LevelWithBar',{attrs:{"block":block}}):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"header text-left pl-6 pt-4 pb-2"},[_c('v-icon',{staticClass:"mt-n1 mr-3 d-none",attrs:{"color":"#bbb"}},[_vm._v(" "+_vm._s(block.icon || 'fa-folder')+" ")]),_vm._v(" "+_vm._s(_vm.$t(block.title))+" ")],1),_c('v-col',{staticClass:"text-right pt-5 pr-5 pb-2",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" fa-chart-line ")])],1)],1)],1),(_vm.showChartsCount >= chart_i + 1)?_c('v-row',[(block.graph.type)?_c('v-col',{staticStyle:{"height":"150px","position":"relative"}},[(block.graph.type === 'custom-bars')?_c('CustomBars',{staticClass:"kpi-block-number"}):(block.graph.type === 'multi-gauge')?_c('MultiGauge',{staticClass:"kpi-block-number"}):(block.graph.type === '2-weeks')?_c('WeeksChart',{staticClass:"kpi-block-number"}):(block.graph.type === 'people')?_c('PeopleChart',{staticClass:"kpi-block-number"}):(block.graph.type === 'chart.js')?_c('DashboardChart',{attrs:{"space-allocation":{
                box_height: 100,
                box_width: 400,
              },"chart-data":{
                data: block.graph.chartData,
                options: block.graph.chartOptions,
                type: block.graph.chartType,
              }}}):_c('div',{staticClass:"kpi-block-number",staticStyle:{"font-size":"36px","color":"grey"}},[_c('svg-icon',{style:('opacity: 0.45;transform: rotate(' + (block.graph.direction === 'up' ? 225 : 315) + 'deg);'),attrs:{"type":"mdi","path":_vm.path,"size":48,"color":block.graph.direction === 'up' ? 'green' : 'red'}}),_vm._v(" "+_vm._s(block.graph.value)+" ")],1)],1):_vm._e()],1):_vm._e()],1)],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }