<template>
  <div
    :class="'item-show-field boolean-field no-text-select ' + (!edit ? 'boolean-show' : 'boolean-edit')"
  >
    <div
      :class="(edit ? 'boolean-title item-edit-label' : 'item-show-label') + ' item-show-label '"
    >
      <span>{{ label }}</span>
    </div>
    <div
      class="boolean-toggle-container no-text-select"
      :style="containerStyle"
      @click="toggleValue"
      @keyup.left="setTrue"
      @keyup.right="setFalse"
    >
      <div
        v-if="edit || isTrue"
        ref="toggleTrue"
        class="text-no-wrap boolean-toggle"
      >
        {{ valueLabel(true) }}
      </div>
      <div
        v-if="edit || !isTrue"
        ref="toggleFalse"
        class="text-no-wrap boolean-toggle"
      >
        {{ valueLabel(false) }}
      </div>
      <component
        :is="hasManyItemLink ? 'router-link' : 'div'"
        v-if="itemIsSet && (leftOffsetSet || !edit)"
        :to="hasManyItemLink"
        :class="'text-no-wrap toggle-slider ' + (leftOffsetSet ? 'toggle-slider-transform' : '')"
        :style="toggleStyleSlider"
        tabindex="0"
        @focus="focus"
      >
        {{ valueLabel(isTrue) }}
      </component>
    </div>
  </div>
</template>

<script>
import { isDarkColor } from '@/components/helperMethods'

const Color = require('color')

export default {
  name: 'BooleanField',

  props: {
    value: {
      type: [String, Boolean],
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    isActiveInListEdit: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object,
      default: () => {
        return {}
      },
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    resource: {
      type: String,
      default: null,
    },
    view: {
      type: String,
      default: 'item',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    layoutEditMode: {
      type: Boolean,
      default: false,
    },
    isHasManyFieldAndNotFirstRow: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    hasManyItemLink: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      falseLeftOffset: 0,
      leftOffsetSet: false,
      sliderWidth: 0,
      fieldsNow: [],
      listeningKeyEvents: false,
    }
  },

  computed: {
    isTrue () {
      return !!this.value
    },

    toggleStyleSlider () {
      const style = {
        background: this.colorNicer,
        color: isDarkColor(this.colorNicer) ? 'white !important' : 'black !important',
      }
      if (this.edit) {
        style.width = this.sliderWidth + 'px'
        style.left = this.isTrue ? 0 : (this.falseLeftOffset + 1) + 'px'
      }
      return style
    },

    containerStyle () {
      return {
        background: this.edit && !this.readOnly ? 'white' : 'inherit',
      }
    },

    colorNicer () {
      // Overwrite often used too bright red
      if (this.color === 'red') {
        return Color(this.color).desaturate(0.4).lighten(0.2)
      }
      return Color(this.color)
    },

    color () {
      const defaultYesColor = '#88b58a'
      const defaultNoColor = '#EF5350'
      const defaultValueColor = this.isTrue ? defaultYesColor : defaultNoColor
      const customValueColor = this.field?.items?.[this.value]?.color
      return customValueColor || defaultValueColor
    },

    itemIsSet () {
      return this.field.name in this.item
    },
  },

  watch: {
    value (now, prev) {
      // In case of value being changed outside this component
      // Ex defaults for change in for metal instance > sales_orders/285/edit
      setTimeout(() => {
        this.setFalseLeftOffset()
        this.setSliderWidth()
      }, 10)
    },

    isActiveInListEdit (val) {
      if (val) {
        setTimeout(() => {
          this.listeningKeyEvents = true
        }, 100)
      } else {
        this.listeningKeyEvents = false
      }
    },

    itemIsSet () {
      this.setFalseLeftOffset()
      this.setSliderWidth()
    },

    edit () {
      this.$nextTick(() => {
        this.setFalseLeftOffset()
        this.setSliderWidth()
      })
    },
  },

  created () {
    if (this.view === 'list') {
      if (this.isActiveInListEdit) {
        this.listeningKeyEvents = true
      }
      document.addEventListener('keydown', this.keyListener)
    }
  },

  mounted () {
    this.setFalseLeftOffset()
    this.setSliderWidth()
  },

  beforeDestroy () {
    if (this.view === 'list') {
      document.removeEventListener('keydown', this.keyListener)
    }
  },

  methods: {
    keyListener (e) {
      // Only for list edit
      if (this.view !== 'list') { return }
      if (!this.isActiveInListEdit || !this.listeningKeyEvents || !e.key) { return }
      switch (e.key) {
        case 'ArrowLeft':
          this.setTrue()
          break
        case 'ArrowRight':
          this.setFalse()
          break
        case 'ArrowUp':
        case 'ArrowDown':
        case 'Escape':
        case 'Enter':
        case 'Tab':
          e.preventDefault()
          this.$emit('keyPress', e)
          break
      }
    },

    focus () {
      setTimeout(() => {
        this.$store.dispatch('closeItemPicker')
      }, 100) // Give time for reference field option pick action and destroy
    },

    setSliderWidth () {
      if (!this.edit || !this.$refs.toggleTrue || !this.$refs.toggleFalse) { return }
      this.sliderWidth = this.isTrue
        ? this.$refs.toggleTrue.clientWidth
        : this.$refs.toggleFalse.clientWidth
    },

    setFalseLeftOffset () {
      setTimeout(() => {
        if (!this.edit || !this.$refs.toggleTrue) { return }
        this.falseLeftOffset = this.$refs.toggleTrue.clientWidth
        this.leftOffsetSet = true
      }, 0)
    },

    valueLabel (val) {
      if (val === null) {
        val = false
      }
      const locale = this.$store.state.locale
      const customValue = this.field?.items?.[val]
        ? this.field.items[val]['show_text_' + locale]
          ? this.field.items[val]['show_text_' + locale]
          : this.field.items[val]['text_' + locale]
            ? this.field.items[val]['text_' + locale]
            : ''
        : ''
      return customValue || this.$i18n.t('aava.general.' +
        (val ? 'yes' : 'no'))
    },

    setTrue () {
      if (!this.isTrue) {
        this.toggleValue()
      }
    },

    setFalse () {
      if (this.isTrue) {
        this.toggleValue()
      }
    },

    toggleValue () {
      if (!this.edit || this.readOnly) { return }
      this.$emit('input', !this.isTrue)
      this.$nextTick(() => {
        this.setSliderWidth()
      })
      this.$emit('changeListener', 0)
    },
  },
}
</script>

<style lang="scss">
  .boolean-field {
    /* line-height: 16px; */
    .item-edit-labe {
      background: transparent;
    }
    padding-top: 10px !important;
    .boolean-title {
    }
    .boolean-toggle-container {
      max-width: 100% ;
      display: flex;
      overflow: hidden;
      line-height: 22px;
      color: var(--item-form-value-color);
      position: relative;
      float: left;
      border-radius: 4px;
    }
    .boolean-toggle {
      float: left;
      padding: 0 10px;
    }
    .toggle-slider {
      border-radius: 4px;
      text-align: center;
      position: absolute;
      padding: 0 10px;
      top: 0;
      white-space: nowrap;
      overflow: hidden;
    }
    .toggle-slider-transform {
      text-overflow: unset;
      transition: 0.35s;
      transition-timing-function: ease-out;
    }
  }
  .boolean-edit {
    /* background-color: white !important; */
    border-color: #999 !important;
    .boolean-toggle-container {
      cursor: pointer;
    }
  }
  .boolean-show {
    .toggle-slider {
      position: inherit;
      padding: 0 5px;
    }
    .boolean-toggle {
      display: none;
    }
    .boolean-toggle-container {
      margin: 0 0 0 4px;
      border: none;
    }
  }
  .item-has-many-items {
    .has-many-item:not(:first-child) {
      .boolean-edit {
        .item-edit-label {
          display: none !important;
        }
      }
    }
    .boolean-edit {
      min-height: 34px !important;
      /* padding: 10px !important; Why? It makes boolean higher in has-many than other inputs */
    }
    .boolean-toggle-container {
      line-height: 18px;
      border: none;
    }
  }
  .boolean-placeholder {
    min-height: 20px;
  }
</style>
