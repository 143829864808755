
import CompatibilityViewReports from '@/components/App/CompatibilityViewReports.vue'
import api from '@/store/api'
import { AxiosResponse } from 'axios'
export default {
  name: 'CompatibilityView',

  components: {
    CompatibilityViewReports,
  },

  props: {
    modelName: {
      type: String,
      default: '',
    },
    viewName: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      loading: true,
      content: '',
      reportProps: null,
    }
  },

  computed: {
    viewUrl () {
      return '/compatibility/' + this.modelName + '/' + this.viewName
    },
  },

  created () {
    this.getContent()
  },

  methods: {
    getContent () {
      this.loading = true
      api.sendRequest(this.viewUrl, {}, []).then((response: AxiosResponse) => {
        this.$store.dispatch('globalErrorDisplay', { response, context: 'Compatibility view' }).then(errorsFound => {
          if (!errorsFound) {
            if (response.data.reports) {
              this.reportProps = response.data
            }
          }
          this.loading = false
        })
      })
    },
  },
}
