
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import api from '@/store/api'
import sharedUtilities from '@/utilities/sharedUtilities'
import dateTimeHelper from '@/methods/date_time_helper'
import { AxiosResponse } from 'axios'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ItemHistoryBtn',

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    resource: {
      type: String,
      default: '',
    },
    isMobileBottomToolbar: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      dateTimeHelper,
      historyItems: [],
      auditTrailEnabled: false,
      loading: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    compact () {
      return !this.isMobileBottomToolbar
    },
  },

  methods: {
    getItemHistory () {
      this.loading = true
      this.historyItems = []
      api.fetchItemHistory(sharedUtilities.objectClassSingularName(this.resource), this.item.id).then((response: AxiosResponse) => {
        this.loading = false
        this.historyItems = response.data.items
        this.auditTrailEnabled = !!response.data.audit_trail
      })
    },

    openAuditTrail () {
      window.open('/gorilla/audit_trail/' + this.resource + '/' + this.item.id + '?locale=' + this.locale, '_blank')
    },
  },
}
