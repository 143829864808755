

// For now though it is only used in has-many?
import Color from 'color'
import itemMethods, { getObjectStateBackgroundColor } from '@/methods/item/itemMethods'
import { Types } from '@/types/AppTypes'
import { isDarkColor } from '@/components/helperMethods'

export default {
  name: 'ObjectStateLabel',

  props: {
    item: {
      type: Object as () => Types.Item,
      required: true,
    },
    isHasManyField: {
      type: Boolean,
      default: false,
    },
    hasManyItemLink: {
      type: String,
      default: '',
    },
  },

  computed: {
    style () {
      return {
        width: '100%',
        cursor: this.isHasManyField ? 'pointer' : 'default' // By any other way .v-chip would overwrite it
      }
    },
  },

  methods: {
    ...itemMethods,
    getObjectStateBackgroundColor,
    isDarkColor,
    hexColor (color) {
      if (!color) { return }
      return Color(color).hex()
    },
  },
}
