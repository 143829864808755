import axios, { AxiosError, AxiosResponse } from 'axios'
import { Types } from '@/types/AppTypes'
import { Field } from '@/types/FieldTypes'
import { networkErrorAxiosResponse } from '@/store/api/apiRequests'

const api = {
  saveAttachment ({
    forObjectClass,
    forFieldName,
    forObjectId,
    parentClass,
    parentTokenOrId,
    parentFieldName,
    attachmentType,
    file,
    skipPathQuery = false,
  }): Promise<AxiosResponse> {
    const bodyFormData = new FormData()
    bodyFormData.set('attachments[][media_type]', attachmentType)
    bodyFormData.set('attachments[][attachable_association]', forFieldName)
    bodyFormData.append('attachments[][uploaded_data]', file)

    let pathQuery = ''
    if (parentClass && !skipPathQuery) {
      // Path is needed for the api when requesting options for item reference field
      // opened on parent item form
      // Example: Country for address which is on parent item form
      // Example: Reference field in has-many editable list on parent item edit form
      pathQuery = '&~path=' + parentClass + ':' + parentTokenOrId + '%20' + parentFieldName
    }

    return new Promise(resolve => {
      axios.post('/api/' + forObjectClass + '/' + forObjectId + '/attachment?q=filename' + pathQuery,
        bodyFormData,
        this.requestOptions())
        .then((response) => {
          resolve(response)
        }).catch((err: AxiosError) => {
          resolve(err.response || networkErrorAxiosResponse(err))
        })
    })
  },

  saveItem (
    objectClass: string,
    item: Types.Item,
    queries: string[] = [],
    filters: object = {},
  ): Promise<AxiosResponse | null> {
    const filtersUrl = '&' + Object.keys(filters).map(key => key + '=' + filters[key]).join('&')
    return new Promise((resolve) => {
      if (item.id) { // item.token || - if no id and token, send POST instead
        axios.put(
          '/api/' + objectClass + '/' + (item.token || item.id) + '?' +
          queries.map(query => 'q[]=' + query).join('&') + filtersUrl,
          item,
          this.requestOptions(),
        ).then((response: AxiosResponse) => {
          resolve(response)
        }).catch((err: AxiosError) => {
          resolve(err.response || networkErrorAxiosResponse(err))
        })
      } else {
        this.fetchToken(objectClass, item, []).then(token => {
          if (token) {
            axios.post(
              '/api/' + objectClass + '/' + token + '?' +
              queries.map(query => 'q[]=' + query).join('&') + filtersUrl,
              item,
              this.requestOptions(),
            ).then((response: AxiosResponse) => {
              resolve(response)
            }).catch((err: AxiosError) => {
              resolve(err.response! || networkErrorAxiosResponse(err))
            })
          } else {
            resolve(Promise.resolve(null))
          }
        })
      }
    })
  },

  saveValueFromList (objectClass: string, item: Types.Item, queries: string[] = [], fieldName: string, payload: any): Promise<any> {
    const filtersUrl = ''
    return new Promise((resolve) => {
      axios.put(
        '/gorilla/' + objectClass + '/' + (item.token || item.id) + '/' + fieldName + '?' +
        queries.map(query => 'q[]=' + query).join('&') + filtersUrl,
        payload,
        this.requestOptions(),
      ).then((response: AxiosResponse) => {
        // console.log('PUT-done', response)
        resolve(response)
      }).catch((err: AxiosError) => {
        resolve(err.response || networkErrorAxiosResponse(err))
      })
    })
  },

  // Called by saveItem action
  // API is not updating images order by itself
  // Also when updating images order right after sorting by user (for new images)
  // relation between image and parent item is lost
  // TODO - fix in back-end so that can be called right after sorting
  saveItemImagesOrder (images: Field.Image[]) {
    return new Promise(resolve => {
      if (!images) {
        resolve(true)
        return
      }
      const promises: Promise<any>[] = []
      images.forEach((image, index) => {
        promises.push(this.saveItem('attachments', {
          id: image.id,
          order: index,
        }, [], {}))
      })
      Promise.all(promises).then(responses => {
        resolve(responses)
      })
    })
  },

  changePassword (current_password: string, password: string, password_confirmation: string) {
    return this.sendPostRequestFormData('/api/change_password', {
      current_password,
      password,
      password_confirmation,
    })
  },

  setGlobalFilter (instance: any) {
    return this.sendPutRequest('/gorilla/global_filters', [], [{
      key: 'value',
      value: instance.value,
    }])
  },
}

export default api
