import Vue from 'vue'
import VueI18n from 'vue-i18n'

import enEdit from './en/aava.edit.yaml'
import enIndex from './en/aava.index.yaml'
import enShow from './en/aava.show.yaml'
import enAava from './en/aava.yaml'
import enLogin from './en/aava.login.yaml'
import fiEdit from './fi/aava.edit.yaml'
import fiIndex from './fi/aava.index.yaml'
import fiShow from './fi/aava.show.yaml'
import fiAava from './fi/aava.yaml'
import fiLogin from './fi/aava.login.yaml'

const en = {}
const fi = {}

en.aava = {
  ...enAava,
  edit: enEdit,
  index: enIndex,
  show: enShow,
  login: enLogin,
}

fi.aava = {
  ...fiAava,
  edit: fiEdit,
  index: fiIndex,
  show: fiShow,
  login: fiLogin,
}

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en',
  messages: {
    en, fi
  },
})
