
import { PropType } from 'vue'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import layoutEditMethods from '@/components/Item/LayoutEditor/layoutEditMethods'
import TextField from '@/components/Form/TextField.vue'
import StaticDynamicList from '@/components/Form/StaticDynamicList.vue'
import SwitchField from '@/components/Form/SwitchField.vue'
import ColorPicker from '@/components/App/ColorPicker.vue'
import layoutProfileMethods from '../../methods/listItem/layoutProfileMethods.js'
import { LP } from './../../types/LP.types'

const { mapFields }: any = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'LayoutProfileForm',

  components: {
    ColorPicker,
    SwitchField,
    StaticDynamicList,
    TextField,
  },

  props: {
    layoutProfile: {
      type: Object as PropType<LP.LayoutProfile>,
      default: () => { return {} },
    },
  },

  data () {
    return {
      saving: false,
      selectedHexColor: null,
      formData: {
        selectedColor: null,
        selectedTemplate: null,
        name: null,
        createDefault: false,
        doCopy: false,
        editViewLayoutProfileId: null,
        showViewLayoutProfileId: null,
      },
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    layoutProfileIdForEdit () {
      return this.layoutProfile.id
    },

    // props () {
    //   return this.layoutProfileFormProps
    // },

    layoutProfilesComputed (): LP.LayoutProfile[] {
      return this.isList
        ? this.layoutProfiles
        : this.itemLayoutProfilesByModel[this.layoutProfile.controller]
    },

    isNew () {
      return !this.layoutProfile.id
    },

    isList () {
      return this.layoutProfile.action === 'index'
    },

    selectedItemLayoutProfileId () {
      return this.selectedLayoutProfileIdByModel[this.objectClass]
    },

    isSelectedItemLayoutProfile () {
      return !this.isList && this.layoutProfile?.id === this.selectedItemLayoutProfileId
    },

    itemLayoutContainers () {
      return this.layoutContainers[this.selectedItemLayoutProfileId]
    },

    itemLayoutContainerFieldSets () {
      return this.layoutContainerFieldSets[this.selectedItemLayoutProfileId]
    },

    itemLayoutProfileAsOptions () {
      return (this.itemLayoutProfilesByModel[this.objectClass] || []).map(item => {
        return {
          value: item.id,
          text: item.name,
        }
      })
    }
  },

  watch: {
    layoutProfileIdForEdit (value) {
      // New LP add form opened, reset form data
      if (!value) {
        this.setFormData()
      }
    },
    'formData.createDefault' (value) {
      if (value) {
        this.$set(this.formData, 'selectedTemplate', null)
        this.$set(this.formData, 'doCopy', false)
      }
    },
  },

  created () {
    this.setFormData()
    this.getItemLayoutProfiles()
  },

  methods: {
    ...methods,
    ...layoutEditMethods,
    ...layoutProfileMethods,

    getItemLayoutProfiles () {
      this.$store.dispatch('loadItemLayoutProfiles', this.objectClass)
    },

    setFormData () {
      if (!this.layoutProfile.id) { return }
      this.selectedHexColor = this.layoutProfile.timelineConfiguration.backgroundColor
      this.formData.editViewLayoutProfileId = this.layoutProfile.timelineConfiguration.editViewLayoutProfileId
      this.formData.showViewLayoutProfileId = this.layoutProfile.timelineConfiguration.showViewLayoutProfileId
      this.formData.name = this.layoutProfile.name
    },

    saveForm () {
      if (this.layoutProfile.id) {
        this.updateLayoutProfile()
      } else {
        this.createNewLayoutProfile()
      }
    },

    closeLayoutProfileMenu () {
      this.$emit('close')
    },
  },
}
