import { render, staticRenderFns } from "./ShortcutsTopMenu.vue?vue&type=template&id=bbb60600&"
import script from "./ShortcutsTopMenu.vue?vue&type=script&lang=js&"
export * from "./ShortcutsTopMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports