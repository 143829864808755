<template>
  <v-row class="container-menu pr-0">
    <v-col
      :cols="container.columns < 3 ? 12 : ''"
      class="ma-0 pb-0 px-0 flex-grow-1 flex-shrink-0"
    >
      <v-btn
        style="float: left;"
        class="ml-2 mr-0 mt-1"
        icon
        @click="$emit('toggleContainerCollapse', container)"
      >
        <v-icon
          color="#bbb"
          small
        >
          {{ container.collapsed ? 'fa-chevron-down' : 'fa-chevron-up' }}
        </v-icon>
      </v-btn>
      <v-btn
        style="float: left;"
        class="ml-1 mr-1 mt-1"
        icon
        @click.stop="$emit('showItemContainerMenu', { e: $event, container })"
      >
        <v-icon
          color="#bbb"
          small
        >
          fa-ellipsis-vertical
        </v-icon>
      </v-btn>
      <div
        class="title"
        style="display: contents;"
      >
        <MultiLangTextField
          v-model="container"
          :field="{ name: 'name', type: 'string' }"
          :label="$i18n.t('aava.general.group') + ' ' + (index + 1)"
          :edit="true"
          @changeListener="changeListener"
        />
      </div>
    </v-col>
    <v-col
      :cols="container.columns < 3 ? 12 : ''"
      class="justify-end align-right text-right pt-5 flex-grow-0 flex-shrink-1"
      style="min-width: 140px;"
    >
      <ContainerColumnsSelector
        v-model="container.columns"
        @change="$emit('updateLayoutContainer', container)"
      />
    </v-col>
  </v-row>
</template>

<script>
import ContainerColumnsSelector from '@/components/Item/LayoutEditor/ContainerColumnsSelector'
import MultiLangTextField from '@/components/Form/MultiLangTextField'

export default {
  name: 'ContainerEditorToolbar',

  components: {
    MultiLangTextField,
    ContainerColumnsSelector,
  },

  props: {
    container: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
  },

  data () {
    return {
      changeUpdateTimer: null
    }
  },

  methods: {
    changeListener () {
      clearTimeout(this.changeUpdateTimer)
      this.changeUpdateTimer = setTimeout(() => {
        this.$emit('updateLayoutContainer', this.container)
      }, 1000)
    },
  },
}
</script>
