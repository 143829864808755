<template>
  <v-container
    fluid
    class="pa-8 kpi-dashboard modal-view"
    style="background: #eee;"
  >
    <v-row v-if="render">
      <v-col class="text-left header">
        {{ layouts[i].title }}
      </v-col>
      <v-col class="text-right">
        <v-btn icon @click="showPrevious">
          <v-icon>fa-caret-left</v-icon>
        </v-btn>
        <v-btn icon @click="showNext">
          <v-icon>fa-caret-right</v-icon>
        </v-btn>
        <v-btn icon @click="$emit('close')">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="render">
      <v-col
        cols="12"
        class="pa-0"
      >
        <Layout_4grid
          v-if="i === 0"
          :layout="layouts[i]"
          :charts="layoutCharts[i]"
          :block-height="blockHeight"
        />
        <Layout_2_plus_1_high
          v-else-if="i === 1"
          :layout="layouts[i]"
          :charts="layoutCharts[i]"
          :block-height="blockHeight"
        />
        <Layout_9grid
          v-else-if="i === 2"
          :layout="layouts[i]"
          :charts="layoutCharts[i]"
          :block-height="blockHeight"
        />
        <Layout_1Larger
          v-else-if="i === 3"
          :layout="layouts[i]"
          :charts="layoutCharts[i]"
          :block-height="blockHeight"
        />
        <Layout_4grid
          v-else-if="i === 4"
          :layout="layouts[i]"
          :charts="layoutCharts[i]"
          :block-height="blockHeight"
        />
        <Layout_1LargeWithHorizontalGauges
          v-else-if="i === 5"
          :layout="layouts[i]"
          :charts="layoutCharts[i]"
          :block-height="blockHeight"
        />
        <Layout_4grid
          v-else-if="i === 6"
          :layout="layouts[i]"
          :charts="layoutCharts[i]"
          :block-height="blockHeight"
        />
        <Layout_4grid
          v-else-if="i === 7"
          :layout="layouts[i]"
          :charts="layoutCharts[i]"
          :block-height="blockHeight"
        />
        <Layout_2_plus_1_high
          v-else-if="i === 8"
          :layout="layouts[i]"
          :charts="layoutCharts[i]"
          :block-height="blockHeight"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import KPI_blocks1 from './data/KPI_blocks_modal1'
import KPI_blocks2 from './data/KPI_blocks_modal2'
import KPI_blocks3 from './data/KPI_blocks_modal3'
import KPI_blocks4 from './data/KPI_blocks_modal4'
import KPI_blocks5 from './data/KPI_blocks_modal5'
import KPI_blocks6 from './data/KPI_blocks_modal6'
import KPI_modal_layouts from './data/KPI_modal_layouts'
import Layout_9grid from './Layouts/Layout_9grid'
import Layout_1Larger from './Layouts/Layout_1Larger'
import Layout_1LargeWithHorizontalGauges from './Layouts/Layout_1LargeWithHorizontalGauges'
import Layout_4grid from './Layouts/Layout_4grid'
import Layout_2_plus_1_high from './Layouts/Layout_2_plus_1_high'

export default {
  name: 'KPIModal',

  components: {
    Layout_2_plus_1_high,
    Layout_4grid,
    Layout_1LargeWithHorizontalGauges,
    Layout_1Larger,
    Layout_9grid,
  },

  data () {
    return {
      render: false,
      i: 0,
      layouts: KPI_modal_layouts,
      layoutCharts: [
        KPI_blocks4,
        KPI_blocks5,
        KPI_blocks1,
        KPI_blocks2,
        KPI_blocks6,
        KPI_blocks3,
        KPI_blocks2,
        KPI_blocks4,
        KPI_blocks6,
      ],
      showChartsCount: 0,
      showLevels: true,
    }
  },

  props: {
    openIndex: {
      type: Number,
      default: 0,
    },
  },

  mounted () {
    console.log('abc')
  },

  created () {
    this.i = this.openIndex
    console.log('this.openIndex', this.openIndex)
    setTimeout(() => {
      this.render = true
      // this.showNextChart()
    }, 200)
    setTimeout(() => {
      this.showChartsCount = 9
      // this.showNextChart()
    }, 200)
  },

  computed: {
    blockHeight () {
      const topPartPx = 106
      return (this.$store.state.innerHeight - topPartPx) / 3
    },
  },

  methods: {
    showNext () {
      this.reload()
      console.log(this.i, this.layouts.length - 1)
      if (this.i === this.layouts.length - 1) {
        this.i = 0
        return
      }
      this.i++
    },

    showPrevious () {
      this.reload()
      if (this.i === 0) {
        this.i = this.layouts.length - 1
        return
      }
      this.i--
    },

    reload () {
      this.render = false
      this.$nextTick(() => {
        this.render = true
      })
    },

    showNextChart () {
      this.showChartsCount++
      if (this.showChartsCount < 9) {
        setTimeout(() => {
          this.showNextChart()
        }, 0) // Not so great effect?
      }
    },
  },
}
</script>
