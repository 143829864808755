
import itemMethods, { getObjectStateBackgroundColor } from '@/methods/item/itemMethods'
import { isDarkColor } from '@/components/helperMethods'
import { Types } from '@/types/AppTypes'

export default {

  name: 'ItemStateLabel',

  props: {
    item: {
      type: Object as () => Types.Item,
      required: true,
    },
  },

  methods: {
    ...itemMethods,
    getObjectStateBackgroundColor,
    isDarkColor,
  },
}
