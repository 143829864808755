import { IVueI18n } from 'vue-i18n'

const translateAttribute = (
  objectClass: string,
  fieldName: string,
  locale: string,
  i18n: IVueI18n,
  view: string = 'index' // TODO make literal
) => {
  // Order is important, the last one must be most exact, to be used
  const labelKeys = [
    `aava.attributes.${fieldName}`,
    `${objectClass}.attributes.${fieldName}`,
  ]

  const listKey = `${objectClass}.attributes.${fieldName}_for_list`

  if (view === 'index') {
    labelKeys.push(listKey)
  } else {
    labelKeys.unshift(listKey)
  }

  let label = fieldName

  labelKeys.forEach(labelKey => {
    if (i18n.te(labelKey, locale)) {
      label = i18n.t(labelKey) as string
    }
  })

  return label
}

export default translateAttribute
