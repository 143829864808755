export default {
  getItemLayoutProfileItems ({ id, useCache }) {
    return new Promise(resolve => {
      this.$store.dispatch('getItemLayoutProfileItems', { id, useCache }).then(lpData => {
        if ('containers' in lpData) {
          return resolve(lpData)
        }
        console.error('API error, containers must be in LP items response')
        resolve(false)
      })
    })
  },
}
