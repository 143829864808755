
import { HasManyActionButtonStyle, HasManyActionButtonType } from '@/types/AppTypes'

export default {
  name: 'HasManyItemActionBtn',

  props: {
    buttonKey: {
      type: String as () => HasManyActionButtonType,
      required: true,
    },
    buttonStyle: {
      type: Object as () => HasManyActionButtonStyle,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    readyForEditMode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    disabled () {
      return (this.buttonStyle.disabled) || (this.edit && !this.readyForEditMode) || this.$store.state.itemLayoutEditMode
    },
  },
}
