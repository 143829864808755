<template>
  <div class="item-show-value">
    <v-menu
      v-if="itemsCount > 1 && onSingleLine"
      min-width="300"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          class="has-many-count-round link"
          v-on="on"
        >
          {{ itemsCount }}
        </div>
      </template>
      <v-container class="white">
        <ContentHasManyReferenceItem
          v-for="(hasManyItem, hasManyItemIndex) in hasManyItems"
          :key="'hmi_' + hasManyItemIndex"
          :item="hasManyItem"
          :field="field"
          :last-item="lastItem"
        />
      </v-container>
    </v-menu>
    <ContentHasManyReferenceItem
      v-for="(hasManyItem, hasManyItemIndex) in hasManyItemsComputed"
      :key="'hmi_' + hasManyItemIndex"
      :item="hasManyItem"
      :field="field"
      :last-item="lastItem"
    />
  </div>
</template>

<script>
import util from '../../../utilities/sharedUtilities'
import listItemCellMethods from './../../../methods/listItem/listItemCellMethods'
import listItemMethods from './../../../methods/listItem/listItemMethods'
import ContentHasManyReferenceItem from '@/components/ListContent/DataTypes/ContentHasManyReferenceItem'

export default {
  name: 'ContentHasManyReferenceItems',

  components: {
    ContentHasManyReferenceItem,
  },

  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    onSingleLine: {
      type: Boolean,
      default: false,
    },
    lastItem: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    hasManyItemsComputed () {
      if (this.onSingleLine && this.itemsCount > 1) {
        return [this.hasManyItems[0]]
      }
      return this.hasManyItems
    },

    hasManyItems () {
      return this.item[this.field.name]
    },

    itemsCount () {
      return (this.hasManyItems || []).length
    },

    style () {
      return this.item['@association_style'] || ''
    },

    referenceItemUrl () {
      if (!this.item || !this.item['@class']) { return '' }
      return '#' + util.objectClassUnderscoredName(this.item['@class']) + '/' + this.item.id
    },

    linkName () {
      return this.item[(this.field?.reference_attribute) || 'summary'] || this.item.filename || '-'
    },
  },

  methods: {
    ...listItemMethods,
    ...listItemCellMethods,

    mouseUpHandler (event) {
      if (this.checkAndStopMetaKeyPress(event)) { return }
      event.stopPropagation()
      event.preventDefault()
      // If reference item is an attachment, open attachment, not model item record
      if (this.item['@class'] === 'Attachment') {
        this.openAttachmentFile(this.item)
        return
      }
      this.openReferenceLink(
        this.item.id,
        { className: this.item['@class'], event }
      )
    },
  }
}
</script>
