import util from '../../utilities/sharedUtilities'
import pixelWidth from 'string-pixel-width'
import Vue from 'vue'
import { LP } from '@/types/LP.types'
import { BaseItem, Types } from '@/types/AppTypes'

const doubleClickDelay = 250

const isOpenLinkInSplitModeEvent = (e) => {
  return e && e.shiftKey && e.altKey
}

export default {
  // Check if mouse was pressed without any key press that should open link in new tab or window
  checkAndStopSimpleMouseClick (e) {
    if (this.isLinkToProcessOrState) {
      // Special handling for state as it should not be linked from the list
      // But it (ObjectState) is included in Linked Associations list
      return false
    }
    if (this.isSimpleMouseClick(e)) {
      e.stopPropagation()
      e.preventDefault()
      return true
    }
    return false
  },

  isSimpleMouseClick (e) {
    return !e.ctrlKey && !e.metaKey && !e.shiftKey && e.which === 1
  },

  // Check if mouse was pressed with key press that should open link in new tab or window
  checkAndStopMetaKeyPress (e) {
    if (!this.isSimpleMouseClick(e) && !isOpenLinkInSplitModeEvent(e)) {
      e.stopPropagation()
      e.preventDefault()
      return true
    }
    return false
  },

  referenceLabel (item: Types.Item, field: LP.Item) {
    if (!item) { return '' }
    // Use object stat short name when not enough space
    if (field.name === 'main_object_state' && item.short_name) {
      const fixedWidth: number = field.userFixedWidth || field.forcedWidth || 0
      const pxWidth = pixelWidth(item.summary, { size: 12 })
      if (pxWidth + 20 > fixedWidth) {
        return item.short_name
      }
    }
    const value = item[field.reference_attribute || 'summary']
    if (field.reference_class === 'Address') {
      return value?.replaceAll('<br>', ', ')
    }
    // Disabled by request - Aava-Vue-563
    // if (field.reference_class === 'Any') {
    //   try {
    //     const resource = item['@class']
    //     const resources = util.objectClassUnderscoredName(resource)
    //     const title = this.$i18n.t(resources + '.list.title')
    //     value = title + ': ' + value
    //   } catch (err) {
    //     console.log('Title not found for ' + field.name)
    //   }
    // }
    return value
  },

  openReferenceLinkFromSplitView ({ className, id }) {
    const path = this.generateReferenceLinkInSplitMode({ className, id })
    this.$router.push({ path }).then()
  },

  // TODO refactor rename, as does support also non split mode
  generateReferenceLinkInSplitMode ({ className, id, view = 'show' }) {
    className = util.objectClassUnderscoredName(className)
    let link = ''
    let routeName = this.$route.name
    // Split mode is not always indicated by the route
    // In this case still should use /split/ for the reference link
    if (['List_ItemShow', 'List_index'].includes(routeName) && this.$store.state.splitMode) {
      routeName = 'List_SplitMode'
    }
    switch (routeName) {
      case 'List_forTarget':
      case 'List_forTargetWithForm':
      case 'List_forTargetWithFormForAnotherResource':
        link = '/' + this.$route.params.resource + '/for/' +
          this.$route.params.targetResource + '/' + this.$route.params.targetId + '/' +
          this.$route.params.targetField + '/' + util.objectClassUnderscoredName(className) +
          '/' + id + '/' + view
        break
      // case 'List_SplitMode': - do not use this, has to be default
      case 'List_SplitModeTwoItems':
      case 'List_SplitModeOneItemLeft':
        link = '/' + this.$store.state.objectClass + '/split/' +
          this.$route.params.firstResource + '/' + this.$route.params.firstItemId + '/' + this.$route.params.firstItemView + '/' +
          className + '/' + id + '/' + view
        break
      case 'List_SplitMode':
        link = '/' + this.$store.state.objectClass + '/split/' +
          className + '/' + id + '/' + view
        break
      default:
        link = '/' + className + '/' + id + '/' + view
    }
    return link
  },

  openReferenceLink (id, { className, event, parentId, view = 'list' }) {
    if (this.isLinkedAssociate(className)) {
      if (this.$store.state.splitMode) {
        this.openReferenceLinkFromSplitView({
          className,
          id,
          // view, // For now no difference if reference link was opened from the list or item view
        })
      } else if (view === 'item') {
        this.openReferenceItemFromItemView(className, id)
      } else {
        // Open reference link
        this.listItemOpenById(id, { className: util.objectClassUnderscoredName(className), event })
      }
    } else if (parentId) {
      // Open item link without object class, uses objectClass from state which is the current list model
      this.listItemOpenById(parentId, { event })
    }
  },

  // When two items open side by side
  openAlternateSplitItemView (view) {
    if (!this.$store.state.splitMode || !parseInt(this.id)) { return } // Only if id is present
    // Item is shown on the second split area
    if (this.itemFormPosition === 'second') {
      this.$router.push({
        path: '/' + this.$route.params.resource + '/split/' + this.$route.params.firstResource + '/' + this.$route.params.firstItemId + '/' + this.$route.params.firstItemView +
          '/' + this.$route.params.secondResource + '/' + this.id + '/' + view
      })
      return true
    } else {
      // First
      const secondSplitItemUrlPart = this.splitProps.secondItemId
        ? '/' + this.splitProps.secondResource + '/' + this.splitProps.secondItemId + '/' + this.splitProps.secondItemView
        : '/split'
      this.$router.push({
        path: '/' + this.objectClass + '/split/' + this.resource + '/' + this.id + '/' + view +
          (this.splitProps.secondResource ? secondSplitItemUrlPart : '')
      })
      return true
    }
  },

  listItemMouseEnterHandler (itemIndex: number) {
    this.mouseEnterRowIndex = itemIndex
  },

  // Disabled context menu when user holds Ctrl key down, this is used to select rows
  handleContextMenu (e: MouseEvent) {
    if (e.ctrlKey) {
      e.preventDefault()
    }
  },

  listItemMouseDownHandler (itemIndex: number, e: MouseEvent) {
    // Activate edit mode on click
    // When in edit mode and clicking on an editable cell, do not open row item
    this.initListRowBeforeEdit()
    if (this.isEditable && this.$store.getters.inEditMode) {
      return this.requestEditForSelectedCell()
    }
    if (!e.shiftKey) {
      this.dragStartIndex = itemIndex
    }
  },

  listItemMouseUpHandler (item: BaseItem, itemIndex: number, e: MouseEvent) {
    this.initListRowBeforeEdit()
    if (this.draggingFieldName ||
      this.resizingFieldName ||
      util.clickOrDrag(e, this.mouseDownCoords) === 'drag' ||
      this.edit
    ) {
      return
    }

    if (e.metaKey) {
      this.toggleVerticalSplitMode()
    }
    if (e.ctrlKey) { //  && this.selectedItems.length > 0
      // Toggle item selection
      this.$store.dispatch('toggleSelectByIndex', itemIndex)
    } else if (e.shiftKey && !isOpenLinkInSplitModeEvent(e) && this.lastSelectedIndex !== null) {
      // Expand selection area to index
      this.$store.dispatch('expandSelectAreaTo', itemIndex)
    } else {
      // Second click within delay ms = double click = open for edit
      if (this.itemClickTimer) {
        // Open edit view
        clearTimeout(this.itemClickTimer)
        this.itemClickTimer = null
        this.listItemOpenForEdit(item.id, {})
      } else {
        // Set to open show view in 350ms, can't do instantly because have to listen
        // for possible double click
        this.itemClickTimer = setTimeout(() => {
          this.listItemOpenById(item.id, { event: e })
          clearTimeout(this.itemClickTimer)
          this.itemClickTimer = null
        }, doubleClickDelay)
      }
    }
  },

  listItemOpenSelected () {
    if (this.listItems[this.selectedItems[0]]) {
      this.listItemOpenById(this.listItems[this.selectedItems[0]].id, {})
    }
  },

  // When list LP has configured item LPs for edit or show view
  // switch to this view
  setItemLayoutProfile (view = 'show') {
    const viewId = view === 'edit'
      ? this.selectedLayoutProfile?.config?.editViewLayoutProfileId
      : this.selectedLayoutProfile?.config?.showViewLayoutProfileId
    if (!viewId) { return }

    const isAvailable = !!this.itemLayoutProfilesByModel[this.objectClass]
      ?.filter(item => item.id === viewId).length

    // Check for supported route
    if (!isAvailable || ![
      'List_ItemShow',
      'List_index',
      'List_ItemAction',
    ].includes(this.$route.name)) { return }
    this.$nextTick(() => {
      Vue.set(this.waitingItemLayoutProfileChangeByModel, this.objectClass, viewId)
    })
  },

  listItemOpenById (id, { className, event, skipTargetItem }) {
    // Check if was triggered by mouse up on reference cell
    // and if mouse dragged > return
    if (event && util.clickOrDrag(event, this.$store.state.mouseDownCoords) === 'drag') {
      return
    }
    if (event) {
      event.stopPropagation()
    }
    // Ctrl to open link in new tab
    // Command key (metaKey) to open link in new tab
    // Shift key to open link in new window
    if (event && (event.ctrlKey || event.metaKey || event.shiftKey) && !isOpenLinkInSplitModeEvent(event)) {
      // Do nothing here as item is opened in new tab or new window by <a href=""></a> link
    } else {
      if (isOpenLinkInSplitModeEvent(event)) {
        this.toggleVerticalSplitMode()
      }
      this.setItemLayoutProfile('show')
      this.$store.dispatch('listItemOpenById', {
        className: util.objectClassUnderscoredName(className || this.objectClass),
        id,
        skipTargetItem
      }).then()
    }
  },

  cellClickHandler (e) {
    this.initListRowBeforeEdit()
    if (isOpenLinkInSplitModeEvent(e) || this.edit) {
      e.preventDefault()
    }
  },

  listItemOpenForEdit (id, { className }) {
    this.setItemLayoutProfile('edit')
    const routerProps = this.$route.params
    if (routerProps && routerProps.targetResource && routerProps.targetId) {
      this.$router.push({
        path: '/' + (className || this.objectClass) + '/for/' +
          routerProps.targetResource + '/' +
          routerProps.targetId + '/' +
          routerProps.targetField + '/' +
          id + '/edit'
      })
    } else {
      this.$router.push({ path: '/' + (className || this.objectClass) + '/' + id + '/edit' })
    }
  },

  showItemTabsOnSeparateToolbar (itemLayoutProfiles) {
    return true // Show always on second line, so more space for event action buttons
    // if (!itemLayoutProfiles) { return false }
    // return !!(this.splitMode || itemLayoutProfiles.length > 2) || (itemLayoutProfiles.length > 1 && !this.$vuetify.breakpoint.lgAndUp)
  },
}
