import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import i18n from './../locale/i18n'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    iconfont: 'fa'
  },
  theme: {
    themes: {
      light: {
        anchor: '#4a4a4a',
      },
    },
  },
})
