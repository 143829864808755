<template>
  <div class="layout-profile-menu white elevation-1 text-left">
    <MenuOption
      :label="$i18n.t('aava.actions.edit')"
      icon="fa fa-edit"
      @click="$emit('showTabForm', tab)"
    />

    <MenuOption
      :label="$i18n.t('aava.actions.remove')"
      icon="fa fa-trash"
      :disabled="disableDelete"
      @click="deleteTab"
    />
  </div>
</template>

<script>
import MenuOption from '@/components/App/MenuOption'

export default {
  name: 'DashboardTabMenu',

  components: {
    MenuOption,
  },

  props: {
    tab: {
      type: Object,
      default: () => { return {} },
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
  },

  computed: {},

  methods: {
    deleteTab () {
      this.$emit('deleteTab', this.tab)
    },
  },
}
</script>

<style lang="scss">
.layout-profile-menu {
  padding: 18px 0 12px 0;
  max-width: 220px;
  min-width: 220px;
  .col {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
}
.menu-separator-line {
  width: 100%;
  border-top: 1px solid #ddd;
  margin-top: 10px;
  height: 1px;
  padding-bottom: 10px;
  background: white;
}
</style>
