import axios, { AxiosError, AxiosResponse } from 'axios'
import { networkErrorAxiosResponse } from '@/store/api/apiRequests'

const userQueries = [
  ['name'],
  ['roles identifier'],
  ['language identifier'],
  ['password_change_date'],
  ['time_zone_info'],
  ['user_profiles settings'],
  ['user_profiles settings key,value'],
]

const personQueries = [
  ['token,person_number,name,language'],
  ['language identifier'],
]

export default {
  getUserInfo (userId: number | string): Promise<AxiosResponse> {
    personQueries.forEach(personQuery => {
      userQueries.push(['person ' + personQuery])
    })
    return this.fetchItemInfo('user_accounts', userId, userQueries)
  },

  // TODO-23 test person my form
  // getPersonInfoForEdit (userId: number) {
  //   return this.fetchItemInfoForEdit('people', userId, personQueries)
  // },

  // For developer login only
  sendLoginRequest (credentials: any): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      axios.post('/api/login/', credentials, this.requestOptions())
        .then((response) => {
          resolve(response)
        }).catch((error: AxiosError) => {
          resolve(error.response || networkErrorAxiosResponse(error))
        })
    })
  },

  // For developer login only
  sendLogoutRequest (): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      axios.post('/api/logout/', {}, this.requestOptions())
        .then((response) => {
          resolve(response)
        }).catch((error: AxiosError) => {
          resolve(error.response || networkErrorAxiosResponse(error))
        })
    })
  },
}
