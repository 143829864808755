export default {

  /*
   * Update available items store object and set added = true
   * where item has already been added to the dashboard
   */
  setAvailableItemsIfAdded (dashboardItems, dashboardAvailableItems) {
    const layoutProfilePreviewsAdded = dashboardItems
      .filter(dashboardItem => !!dashboardItem.layout_profile)
      .map(dashboardItem => dashboardItem.layout_profile.id)
    const chartsAdded = dashboardItems
      .filter(dashboardItem => dashboardItem.element_type === 'chart')
      .map(dashboardItem => dashboardItem.element_action)

    // set dashboardItems added
    if (dashboardAvailableItems.preview) {
      dashboardAvailableItems.preview.forEach((item, i) => {
        dashboardAvailableItems.preview[i].added = layoutProfilePreviewsAdded.indexOf(item.id) > -1
      })
    }

    // set charts added
    if (dashboardAvailableItems.chart) {
      dashboardAvailableItems.chart.forEach((item, i) => {
        dashboardAvailableItems.chart[i].added = chartsAdded.indexOf(item.identifier) > -1
      })
    }

    return dashboardAvailableItems
  }
}
