import Vue from 'vue'
import listViewAPI from './../../api'
import util from '../../../utilities/sharedUtilities'
import { AxiosResponse } from 'axios'

export default {
  saveTimelineConfig: ({ state, dispatch }, { configuration, rangeStart, rangeEnd }) => {
    const layoutProfileIndex = util.findLayoutProfileIndexById(state, state.selectedLayoutProfileId)
    Vue.set(state.layoutProfiles[layoutProfileIndex].timelineConfiguration,
      'timeline_configuration', configuration)
    Vue.set(state.layoutProfiles[layoutProfileIndex].timelineConfiguration,
      'timeline_range', { start: rangeStart, end: rangeEnd })
    return dispatch('saveLayoutProfileData', state.layoutProfiles[layoutProfileIndex])
  },

  saveTimelineItem: ({ state, dispatch }, { id, parameters }) => {
    return new Promise(resolve => {
      listViewAPI.updateItem(state.objectClass, id, parameters)
        .then((response: AxiosResponse) => {
          dispatch('globalErrorDisplay', { response })
          resolve(response)
        })
    })
  },
}
