//
// # Derived attributes for price
// #
// # For each price attribute one derived attribute is created. This derived
// # attribute is the rounded value. It is used only in pivottable headers.
// # This is done because using exact values in headers look bad, it is preferred
// # to show rounded value in the header.
// # On the other hand all the calculations must be done with the exact values so
// # the original values and the original attribute must be preserved.
// # User does not need to know anything about this magic, she just sees correct
// # calculation results and nicely rounded headers
// #
// # The name of the derived attribute is only one whitespace different from the
// # original attribute so in user point-of-view it looks like there is only one
// # attribute
// #

export default {
  createTranslations: function (key, translatedKey) {
    const translations = {}
    translations[key] = translatedKey
    return translations
  },
  createPriceDerivedAttributes: function (val, precision) {
    const derived = {}
    derived[val + ' '] = function (record) {
      return parseFloat(record[val]).toFixed(precision)
    }
    return derived
  },
  createHiddenFromAggregatorsAttributes: function (val) {
    return [val + ' ']
  },
  createHiddenFromDragDropAttributes: function (val) {
    return [val]
  }
}
