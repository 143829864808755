<template>
  <v-btn
    :class="'shortcut-button ml-2 ' + ($store.getters.showListTabsOnSeparateToolbar ? '' : 'mr-4') "
    :disabled="$store.state.loadingShortcuts"
    :loading="updating"
    icon
    @click="toggleShortcut"
  >
    <v-icon
      :color="shortcutIsAdded ? 'green' : 'grey lighten-1'"
      :class="shortcutIsAdded ? 'enabled-shortcut' : 'disabled-shortcut'"
      small
    >
      fa-star
    </v-icon>
  </v-btn>
</template>

<script>
import shortcutData from '@/store/dashboardItems/helpers/shortcutData'
import dashboardMethods from '../Dashboard/dashboardMethods'

export default {
  name: 'ShortcutsAddButton',

  props: {
    objectClass: {
      type: String,
      default: '',
    },
    itemId: {
      type: [String, Number],
      default: '',
    },
    viewName: {
      default () { return '' },
      type: String,
    },
  },

  data () {
    return {
      updating: false,
    }
  },

  computed: {
    layoutProfileName () {
      return this.$store.state.selectedLayoutProfile.name
    },

    layoutProfileObjectClassName () {
      return this.$store.state.selectedLayoutProfile.controller
    },

    layoutProfileID () {
      return this.$store.state.selectedLayoutProfile.id
    },

    shortcutId () {
      return this.$store.state.shortcutKeys[shortcutData.getShortcutKey(this.generateShortcutIdentifier())]
    },

    shortcutIsAdded () {
      return !!this.shortcutId
    },
  },

  methods: {
    ...dashboardMethods,

    selectedLayoutProfile () {
      return this.$store.state.selectedLayoutProfile
    },

    toggleShortcut () {
      this.updating = true
      if (this.shortcutIsAdded) {
        this.deleteDashboardItem(this.shortcutId).then(() => {
          this.updating = false
        })
      } else {
        this.addShortcut({
          identifier: this.generateShortcutIdentifier(),
          objectClass: this.objectClass,
          viewId: this.itemId
        }).then(() => {
          this.updating = false
        })
      }
    },

    generateShortcutIdentifier () {
      if (this.viewName === 'index') {
        return 'internal|' + this.viewName + '|' +
          this.layoutProfileObjectClassName + '|' +
          this.layoutProfileID + '|' +
          this.layoutProfileName
      }
      return 'internal|' +
        this.viewName + '|' +
        this.objectClass + '|' +
        (this.itemId || 0) + '|'
    },
  },
}
</script>

<style scoped>
.toolbar_shortcut_add {
  width: 30px;
  height: 45px;
  float: left;
  cursor: pointer;
}
.toolbar_shortcut_add_icon {
  width: 30px;
  height: 45px;
  padding: 13px 2px 16px 0;
}
@media only screen and (max-width: 767px)  {
  .toolbar_shortcut_add {
    height: 53px;
  }
  .toolbar_shortcut_add_icon {
    height: 53px;
    padding: 15px 5px;
  }
}
</style>
