<template>
  <div
    v-if="showHasManyListMenu"
    id="has-many-list-menu"
    class="has-many-list-menu popup-menu elevation-5"
  >
    <v-progress-linear
      v-if="showHasManyListMenu === 'loading'"
      :indeterminate="true"
      height="3"
      color="teal"
    />
    <template v-else>
      <ContentHasManyReferenceItem
        v-for="(hasManyItem, hasManyItemIndex) in hasManyListItems"
        :key="'hmi_' + hasManyItemIndex"
        :item="hasManyItem"
        :last-item="hasManyItemIndex === hasManyListItems.length - 1"
      />
      <template v-if="hasManyListItemsCount > hasManyListItemsFetchCount">
        +{{ hasManyListItemsCount - hasManyListItemsFetchCount }} items
      </template>
    </template>
  </div>
</template>

<script>
import methods from './../methods'
import ContentHasManyReferenceItem from './DataTypes/ContentHasManyReferenceItem'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ContentHasManyListMenu',

  components: {
    ContentHasManyReferenceItem,
  },

  computed: {
    ...mapFields(Object.keys(state))
  },

  created () {
    document.addEventListener('click', this.clickListener)
  },

  destroyed () {
    document.removeEventListener('click', this.clickListener)
  },

  methods: {
    ...methods,

    openHasManyItem (item, event) {
      if (item['@class']) {
        this.listItemOpenById(item.id, { className: item['@class'], event: event })
      }
    },

    clickListener (e) {
      // Only close has-many items popup list for simple click
      // When opening items in new tab or new window, keep popup open
      if (this.isSimpleMouseClick(e)) {
        this.showHasManyListMenu = false
        this.hasManyListItems = []
        this.hasManyListItemsCount = null
      }
    },
  }
}
</script>

<style>
.has-many-list-menu {
  min-width: 150px;
  max-width: 80%;
  max-height: 400px;
  overflow: auto;
  padding: 5px !important;
}
</style>
