<template>
  <v-col
    v-if="columnInfo"
    :class="draggableColumn + ' '"
    :style="outerStyle"
  >
    <v-container
      :style="innerStyle"
      class="pa-0 elevation-3 card-view-column-inner-container"
      fill-height
    >
      <v-row
        class="ma-0 col-title elevation-1"
        style="z-index: 1;"
      >
        <v-col
          v-if="columnWithSelection && selectionData"
          cols="12"
          class="card-view-selection-data"
          :style="selectionDataStyle"
        >
          {{ selectionData }}
        </v-col>
        <v-col
          class="pa-0 card-column-header-text"
          cols="12"
        >
          <v-chip
            :color="styleColor"
            style="width: 100%;"
            dark
            label
          >
            {{ columnInfo.title || column.name }}
          </v-chip>
          <div
            v-for="(columnIcon, index) in columnIcons"
            :key="index"
          >
            <v-icon
              class="card-column-header-icon"
              @click="columnIconClicked(columnIcon)"
            >
              fa-{{ columnIcon.value }}
            </v-icon>
          </div>
        </v-col>
      </v-row>
      <v-expand-transition>
        <div v-show="hasAction">
          <card-view-icon-action
            :action="iconAction"
            :color="columnInfo.color"
            @completed="onActionCompleted"
            @click.stop="itemAction"
          />
        </div>
      </v-expand-transition>

      <draggable
        v-model="orderedColumnItems"
        :disabled="disabledAsDropTarget"
        :style="cardDraggableStyle"
        class="card-draggable row align-content-start no-gutters pa-0"
        delay="200"
        force-fallback="true"
        group="cardGroup"
        animation="200"
        @choose="dragStart"
        @unchoose="dragUnchoose"
      >
        <CardViewItem
          v-for="(item, index) in orderedColumnItems"
          :key="item.id"
          :row-index="index"
          :row-item="item"
          :class="index === dragged_index ? ' dragged_preview ' : ''"
          @card-selected="selectCard"
          @disableFullScreen="$emit('disableFullScreen')"
        />
      </draggable>
      <span
        v-if="hasOverflow"
        class="overflow-text"
      >
        {{ $i18n.t('aava.index.cardview.column_overflow', {count: overflowAmount}) }}
      </span>
    </v-container>
  </v-col>
</template>

<script>
import methods from './../methods'
import util from '../../utilities/sharedUtilities'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import draggable from 'vuedraggable'
import CardViewItem from './CardViewItem'
import CardViewIconAction from '@/components/CardView/CardViewIconAction'
import sharedComputed from '@/sharedComputed'
const Color = require('color')

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'CardViewColumn',

  components: {
    CardViewIconAction,
    CardViewItem,
    draggable,
  },

  props: {
    columnIndex: {
      type: Number,
      default: () => null,
    },
    columnDragged: {
      type: Boolean,
      default: () => false,
    },
    column: {
      type: Object,
      default: null,
    },
    columnInfo: {
      type: Object,
      default: null,
    },
    columnItems: {
      type: Array,
      default: null,
    },
    lumnWidthPercentage: {
      type: Number,
      default: 100,
    },
  },

  data () {
    return {
      dragged_index: -1,
      disabled: false,
      iconAction: null,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    columnWithSelection () {
      return this.column.order === this.$store.state.selectedColumn
    },

    columnIcons () {
      return this.column.icons || []
    },

    hasAction () {
      return this.iconAction && Object.keys(this.iconAction).length > 0
    },

    // dummy rowitem given to the action component
    actionRowItem () {
      return { color: this.columnInfo.color }
    },

    maxItemsPerColumn () {
      return this.cardViewInfos[this.objectClass].max_items_per_column || this.maxCardsOnColumn
    },

    hasOverflow () {
      return this.overflowAmount > 0
    },

    overflowAmount () {
      if (this.columnInfo.total) {
        return this.columnInfo.total - this.maxItemsPerColumn
      } else {
        return this.columnItems.length - this.maxItemsPerColumn
      }
    },

    orderedColumnItems: {
      get () {
        const size = this.maxItemsPerColumn || this.columnItems.length
        return this.columnItems.slice().sort((a, b) => {
          return a.card_order > b.card_order ? 1 : -1
        }).slice(0, size)
      },
      // called on drop events on both source and target columns
      set (param) {
        // sort change inside the column
        if (param.length === this.columnItems.length) {
          return this.updateColumnOrdering(param)
        }
        this.changeValue(param)
      }
    },

    // return true to those columns that need to be disabled during dragging. The
    // not allowed states
    disabledAsDropTarget () {
      // column is enabled if dragging is not ongoing
      if (!this.cardDraggedItemColumn) {
        return false
      }

      if (this.isSourceColumn) {
        // source column is always enabled
        return false
      } else if (this.isValidTargetColumn()) {
        // valid target column is enabled
        return false
      } else {
        // the rest of the columns are disabled, not allowed next states for the dragged object
        return true
      }
    },

    selectionDataStyle () {
      const color = '#FFE4C4' || this.columnInfo.color
      return {
        backgroundColor: color,
      }
    },

    outerStyle () {
      const widthString = 'calc(' + this.columnWidthPercentage + '% - 10px)'
      return {
        width: widthString,
        minWidth: widthString,
        maxWidth: widthString,
      }
    },

    innerStyle () {
      return {
        background: '#eee',
        display: 'block',
      }
    },

    styleColor () {
      return this.columnInfo.color && !['card_column_color', 'work_cards_column_color'].includes(this.columnInfo.color)
        ? this.columnInfo.color // Color(this.columnInfo.color).lightness(50).hex()
        : '#aaa'
    },

    // Something is needed to remind the dragger what was the original state, in case he wants
    // not to change state after all
    draggableColumn () {
      if (!this.cardDraggedItemColumn) {
        return 'card-view-column-container'
      }
      if (this.isSourceColumn) {
        return 'card-view-column-container source-container'
      } else if (this.isValidTargetColumn()) {
        return 'card-view-column-container target-container'
      } else {
        return 'card-view-column-container non-target-container'
      }
    },

    cardDraggableStyle () {
      const heightReservedForColHeader = 60
      return {
        minHeight: 'calc(100% - ' + heightReservedForColHeader + 'px)',
      }
    },

    valueChangeNewValue () {
      return this.column.value
    },

    isSourceColumn () {
      return this.cardDraggedItemColumn.value === this.column.value
    },
  },

  methods: {
    ...methods,

    columnIconClicked (action) {
      this.iconAction = this.hasAction ? {} : action
    },

    onActionCompleted (action, operation) {
      if (operation === 'save') {
        const columnParameters = [{ name: 'column', value: this.column.value }]
        this.$store.dispatch('sendIconAction', {
          item: null,
          name: action.name,
          data: [...action.parameters, ...columnParameters]
        })
          .then(result => {})
      }

      // clear action row values
      action.parameters.forEach(param => {
        param.value = null
      })
      this.iconAction = {}
    },

    selectCard (item) {
      this.$store
        .dispatch('selectCard', {
          column: this.column.order,
          index: this.cardItems.findIndex(scItem => scItem.id === item.id),
          item: item,
          viewName: this.cardViewInfos[this.objectClass].view_name,
        })
        .then()
    },

    // This is called for both source and target column. Only the target column
    // has a new item, which is the dragged item
    changeValue (param) {
      const type = this.cardViewInfos[this.objectClass].type
      const data = this.getChangeData(type, param)
      if (!data) {
        return
      } // source column

      // find out the selected cards
      const actionItems = []
      actionItems.push(data.item)
      if (data.item.selected) {
        this.$store.state.cardItems.forEach(cardItem => {
          if (cardItem.selected && cardItem.id !== data.item.id) {
            actionItems.push(cardItem)
          }
        })
      }

      // param is an array of items in the target column. By default there
      // is only the dragged card. Insert also other selected cards to the array
      // and set the order of items in the columns after that
      const insertionPoint = param.findIndex((i) => i.id === data.item.id)
      param.splice(insertionPoint, 1, ...actionItems)
      this.updateColumnOrdering(param)

      // clear the data showing selectionInfo for the column
      this.$store.state.selectionData = null
      this.$store.state.selectedColumn = null

      // call the action to all selected items
      const newValue = this.getChangeNewValue(type)
      const viewName = this.cardViewInfos[this.objectClass].view_name
      actionItems.forEach(item => {
        this.$store
          .dispatch('sendCardAction', {
            index: this.cardItems.findIndex(
              scItem => scItem.id === item.id
            ),
            data: { ...data, item: item },
            newValue: newValue,
            viewName: viewName
          })
          .then()
      })
    },

    getChangeData (type, param) {
      if (type === 'state') {
        return this.getStateChangeData(param)
      } else if (type === 'reference') {
        return this.getReferenceChangeData(param)
      } else {
        return this.getValueChangeData(param)
      }
    },

    getStateChangeData (param) {
      const changedStateItem = param.find(
        item => item.main_object_state.identifier !== this.column.value
      )
      if (!changedStateItem) {
        return
      } // source column

      return {
        item: changedStateItem,
        action: 'event',
        event: this.eventForStateChange(changedStateItem)
      }
    },

    getReferenceChangeData (param) {
      const attribute = this.cardViewInfos[this.objectClass].card_attribute
      const identifier = this.cardViewInfos[this.objectClass].card_attribute_identifier
      const changedItem = param.find(item => {
        if (item[attribute] === null) {
          return this.column.value !== null
        }
        return item[attribute][identifier] !== this.column.value
      })

      if (!changedItem) {
        return
      } // target column

      return {
        item: changedItem,
        action: 'update_reference',
        attribute: attribute,
        identifier: identifier,
        value: this.column.value
      }
    },

    getValueChangeData (param) {
      const attribute = this.cardViewInfos[this.objectClass].card_attribute
      const changedItem = param.find(
        item => item[attribute] !== this.column.value
      )
      if (!changedItem) {
        return
      } // target column

      return {
        item: changedItem,
        action: 'update',
        attribute: attribute,
        value: this.column.value
      }
    },

    // for state card view the item value is more than just the value
    getChangeNewValue (type) {
      if (type === 'state') {
        return this.getStateChangeNewValue()
      } else if (type === 'reference') {
        return this.getReferenceChangeNewValue()
      } else {
        return this.valueChangeNewValue
      }
    },

    getStateChangeNewValue () {
      return {
        '@class': 'ObjectState',
        id: this.column.id,
        identifier: this.column.value,
        background_color: this.column.color
      }
    },

    getReferenceChangeNewValue () {
      const identifier = this.cardViewInfos[this.objectClass].card_attribute_identifier
      const ret = {}
      ret[identifier] = this.column.value
      return ret
    },

    // find the event corresponding to the move. The code is executed in the
    // drag target column, the event-information is in the list of previous columns
    eventForStateChange (item) {
      const previousColumn = this.column.previous_columns.find(
        state => state.value === item.main_object_state.identifier
      )
      if (!previousColumn) {
        return
      }
      const eventInfo = previousColumn.events.find(event => {
        return event.process === item.main_object_process.identifier
      })
      return eventInfo.event
    },

    // updates the ordering of items within the state. If items is moved to a new state
    // this gets called for both, source and target state
    updateColumnOrdering (params) {
      if (params.length === 0) {
        return
      }
      if (this.cardViewInfos[this.objectClass].ordering === false) {
        return
      }
      params.forEach((item, index) => (item.card_order = index))
      const columnOrdering = params.map(item => {
        return { id: item.id, order: item.card_order }
      })
      this.$store
        .dispatch('updateColumnOrdering', columnOrdering)
        .then()
    },

    // checks if this column is a valid target column for the dragged item
    // returns true if the draggedItem's column is in this column's
    // previous-columns list
    isValidTargetColumn () {
      if (this.column.previous_columns === 'all') {
        return true
      }
      return this.column.previous_columns.some(col => {
        return this.cardDraggedItemColumn && col.value === this.cardDraggedItemColumn.value
      })
    },

    dragUnchoose (e) {
      this.dragged_index = -1
      this.cardDraggedItemColumn = null
    },

    dragStart (e) {
      this.dragged_index = e.oldIndex
      this.cardDraggedItemColumn = {
        ...this.column,
        process: this.cardItems[e.oldIndex].main_object_process
      }
    },
  }
}
</script>

<style lang="scss">
.target-container {
  .card-view-column-inner-container {
    outline: 2px solid #388E3C;
  }
}
</style>

<style>
.card-view-column-container {
  max-width: 300px !important;
}
.card-view-column-container.sortable-chosen {
  cursor: move;
  cursor: -webkit-grabbing;
}
.source-container {
  opacity: 0.9;
}
.non-target-container {
  opacity: 0.5;
}
.overflow-text {
  display: block;
  margin-bottom: 10px;
  margin-left: 20px;
  font-style: italic;
}
.card-view-column-header {
  font-weight: bold;
}
</style>

<style scoped>
.dragged_preview {
  outline-offset: -5px;
  outline: 5px solid #fff159;
  cursor: move;
  cursor: -webkit-grabbing;
}
.col-title {
  position: sticky;
  top: 0;
  background: #eee;
  font-size: 13px;
  font-weight: 700;
  padding: 05px !important;
  color: white;
}
</style>
