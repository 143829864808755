<template>
  <div
    ref="itemActionButtonsOuterContainer"
    class="flex-grow-1 flex-shrink-0 text-center item-action-buttons"
  >
    <div
      v-if="buttonsCountToShow > 0 && (measuringButtonWidths || widthMeasured)"
      ref="itemActionButtonsInnerContainer"
      :class="isMobileBottomToolbar ? '' : 'd-inline-flex'"
    >
      <template v-if="!measuringContainerWidth">
        <v-btn
          v-for="(button, index) in itemActionButtonsComputed"
          :key="index + '_action'"
          ref="itemActionButton"
          :class="'grey--text text--darken-1 ' +
            (isMobileBottomToolbar ? 'mobile-toolbar-btn' : ' mx-2') +
            (index > buttonsCountToShow - 1 && !measuringButtonWidths ? ' d-none' : '') +
            (' ' + button.identifier + '_event')
          "
          :color="isMobileBottomToolbar ? 'grey lighten-3' : 'white'"
          :block="isMobileBottomToolbar"
          small
          light
          depressed
          @click="$emit('sendProcessEventOrAction', { event: button, e: $event })"
        >
          <v-icon
            small
            :color="button.color"
            :class="'pr-2'"
          >
            fa-{{ button.icon }}
          </v-icon>
          {{ button.name }}
        </v-btn>
      </template>
    </div>
    <v-menu
      v-if="showCompactItemActionButtons"
      :z-index="160"
      offset-y
      close-on-click
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          :class="($vuetify.breakpoint.mdAndUp && $store.state.splitMode !== 'vertical' ? ' pr-4' : '') + 'mx-1 mt-n1 more-event-actions-btn'"
          color="grey"
          dark
          small
          depressed
          v-on="on"
        >
          <v-icon
            :class="$store.state.splitMode !== 'vertical' ? 'mr-2' : ''"
            small
          >
            fa-caret-down
          </v-icon>
          {{ $store.state.splitMode !== 'vertical' ? $i18n.t('aava.actions.title') : '' }}
        </v-btn>
      </template>
      <div class="popup-items-container">
        <v-btn
          v-for="(button, index) in itemActionButtonsComputed"
          :key="index + '_action'"
          :class="'grey--text text--darken-1 ' +
            (index > buttonsCountToShow - 1 ? ' ' : ' d-none' )"
          color="white"
          small
          light
          depressed
          block
          @click="$emit('sendProcessEventOrAction', { event: button, e: $event })"
        >
          <v-icon
            small
            :color="button.color"
            class="pr-2"
          >
            fa-{{ button.icon }}
          </v-icon>
          {{ button.name }}
        </v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'ItemActionButtons',

  props: {
    itemActionButtonsComputed: {
      type: Array,
      default: () => {},
    },
    isMobileBottomToolbar: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      resizeTimer: null,
      measuringContainerWidth: false,
      measuringButtonWidths: false,
      itemActionButtonsWidths: [],
      itemActionButtonsInnerContainerWidth: 0,
      widthMeasured: false,
    }
  },

  computed: {
    showCompactItemActionButtons () {
      if (this.isMobileBottomToolbar) {
        return false
      }
      if (this.measuringButtonWidths || this.measuringContainerWidth) {
        return false
      }
      return !this.fitsAllButtons
    },

    combinedWidth () {
      return this.itemActionButtonsWidths.reduce((a, b) => a + b, 0)
    },

    fitsAllButtons () {
      const buffer = 50
      // console.log(this.combinedWidth, '<', this.itemActionButtonsInnerContainerWidth - buffer)
      return !this.widthMeasured || this.combinedWidth < this.itemActionButtonsInnerContainerWidth - buffer
    },

    buttonsCountToShow () {
      if (this.fitsAllButtons || this.measuringButtonWidths || this.isMobileBottomToolbar) {
        return this.itemActionButtonsComputed.length
      }
      let count = 0
      let combined = 0
      const buffer = 50
      const moreBtnWidth = 100
      this.itemActionButtonsComputed.forEach((btn, i) => {
        const isLast = i === this.itemActionButtonsComputed.length - 1
        const reserved = buffer + (isLast ? moreBtnWidth : moreBtnWidth)
        // console.log('::', i, this.itemActionButtonsWidths[i] + combined, '<', (this.itemActionButtonsInnerContainerWidth - reserved), isLast, reserved)
        // Previous btn did already fit
        if (count < i) { return }
        if (this.itemActionButtonsWidths[i] + combined < this.itemActionButtonsInnerContainerWidth - reserved) {
          combined = combined + this.itemActionButtonsWidths[i]
          count++
        }
      })
      return count
    },

    measuringTrigger () {
      return JSON.stringify(this.itemActionButtonsComputed) + this.$store.state.resizeTrigger
    },
  },

  watch: {
    measuringTrigger () {
      this.measureWidths()
    },
  },

  mounted () {
    this.measureWidths()
  },

  methods: {
    measureWidths () {
      // console.log('measureWidths', this.$refs, !this.$refs.itemActionButtonsOuterContainer)
      if (!this.$refs.itemActionButtonsOuterContainer) { return }
      // Use setTimeout, because UI takes time to react on screen size change, example when hiding other toolbar buttons
      setTimeout(() => {
        this.measuringContainerWidth = true
        this.$nextTick(() => {
          this.itemActionButtonsInnerContainerWidth = this.$refs.itemActionButtonsOuterContainer?.clientWidth
          if (!this.itemActionButtonsInnerContainerWidth) {
            return
          }
          this.measuringButtonWidths = true
          this.measuringContainerWidth = false
          this.$nextTick(() => {
            // console.log('_', this.itemActionButtonsInnerContainerWidth, this.$refs.itemActionButton)
            this.itemActionButtonsWidths = (this.$refs.itemActionButton || []).map(item => {
              // console.log(item.$el.clientWidth)
              return item.$el.clientWidth
            })

            // console.log('_combined', this.combinedWidth, 'fits all', this.fitsAllButtons)
            this.$nextTick(() => {
              this.measuringButtonWidths = false
              this.widthMeasured = true
            })
          })
        })
      }, 50)
    },
  },
}
</script>
<style lang="scss">
.popup-items-container {
  .v-btn {
    justify-content: left !important;
    margin-left: 0 !important;
  }
}
</style>
