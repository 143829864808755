import Vue from 'vue'
import api from '@/store/api'
import i18n from '@/locale/i18n'

const itemValueByColumnValue = (state, item) => {
  let itemValue = null
  if (state.cardViewInfos[state.objectClass].type === 'state') {
    itemValue = item.main_object_state.identifier
  } else if (state.cardViewInfos[state.objectClass].type === 'reference') {
    const attribute = state.cardViewInfos[state.objectClass].card_attribute
    const identifier = state.cardViewInfos[state.objectClass].card_attribute_identifier
    if (item[attribute] && item[attribute][identifier]) {
      itemValue = item[attribute][identifier]
    } else {
      itemValue = null
    }
  } else {
    const attribute = state.cardViewInfos[state.objectClass].card_attribute
    itemValue = item[attribute]
  }
  return itemValue
}

export default {
  resetCardView: ({ state }, className) => {
    return new Promise(resolve => {
      state.cardViewInfos[className] = {}
      state.cardColumns = []
      state.cardItems = []
      resolve()
    })
  },

  loadCardViewInfo: ({ state }, { className, viewName }) => {
    return new Promise(resolve => {
      api.loadCardViewInfo(state.selectedLayoutProfileId, viewName)
        .then(response => {
          if (response.data.status === 'ok') {
            state.cardViewInfos[className] = response.data
            resolve(response.data)
          } else resolve(false)
        })
    })
  },

  sendCardAction: ({ state, dispatch }, { index, data, newValue, viewName }) => {
    return new Promise(resolve => {
      const attribute_name = data.action === 'event' ? 'main_object_state' : data.attribute
      const originalValue = data.item[attribute_name]
      const object_id = data.item.id
      const movingItem = { ...data.item }
      movingItem[attribute_name] = newValue

      // cardActionItem is used in preventing websocket notification to run in
      // the same view that sent the action
      state.cardActionItem = object_id
      Vue.set(state.cardItems, index, movingItem)
      api.sendCardAction(state.objectClass, viewName, data.item.id, [{ key: 'data', value: data }])
        .then(response => {
          dispatch('globalErrorDisplay', { response, context: 'Send card action ' + state.objectClass + ' ' + viewName })
          const result = response.data
          const attribute_name = data.action === 'event' ? 'main_object_state' : data.attribute
          if (result.status === 'ok') {
            if (result.columns) { state.cardColumns = result.columns }
            const updatedItem = { ...data.item, ...result.item }
            updatedItem[attribute_name] = newValue
            updatedItem.selected = false
            Vue.set(state.cardItems, index, updatedItem)
          } else {
            const movingItem = { ...data.item }
            movingItem[attribute_name] = originalValue
            Vue.set(state.cardItems, index, movingItem)
          }
          state.cardActionItem = null
          resolve(result)
        })
    })
  },

  sendIconAction: ({ state, dispatch }, { name, data, item }) => {
    return new Promise(resolve => {
      // cardActionItem is used in preventing websocket notification to run in
      // the same view that sent the action
      if (item) { state.cardActionItem = item.id }
      const dataParams = data.map((param) => {
        return { name: param.name, value: param.value }
      })
      const params = { name, id: item ? item.id : null, data: dataParams }
      api.sendIconAction(state.objectClass, [{ key: 'data', value: params }])
        .then(response => {
          const result = response.data
          dispatch('globalErrorDisplay', { response: result, context: 'Send card action ' + state.objectClass })
          state.cardActionItem = null
          if (result.status === 'nok') {
            dispatch('showMessage', {
              message: result.data || i18n.t('aava.index.cardview.action_failed'),
              type: 'error'
            })
          } else if (result.status === 'ok') {
            dispatch('showMessage', {
              message: i18n.t('aava.index.cardview.action_saved'),
              type: 'success',
              expires: 1000,
            })
          }
          // not all actions return an item, but if they do the item must be updated
          if (result.status === 'ok' && item) {
            const itemToUpdate = state.cardItems.find((cardItem) => item.id === cardItem.id)
            itemToUpdate[result.data.name] = result.data.value
          }
          resolve(result)
        })
    })
  },

  selectCard: ({ state }, { column, index, item, viewName }) => {
    item.selected = !item.selected
    Vue.set(state.cardItems, index, item)
    const itemValue = itemValueByColumnValue(state, item)

    if (item.selected) {
      // in case of a selection, all selections from other columns need to be unselected
      state.cardItems.forEach((cardItem) => {
        if (cardItem.id === item.id) { return }
        if (!cardItem.selected) { return }

        const targetItemValue = itemValueByColumnValue(state, cardItem)
        if (targetItemValue === itemValue) { return }

        const itemIndex = state.cardItems.findIndex((scItem) => scItem.id === cardItem.id)
        cardItem.selected = false
        Vue.set(state.cardItems, itemIndex, cardItem)
      })
    }

    // fetch selectionData text from the backend
    const selectedIds = state.cardItems.map((cardItem) => {
      return (cardItem.selected ? cardItem.id : null)
    }).filter((id) => id != null)

    if (selectedIds.length > 0) {
      state.selectedColumn = column

      api.getCardSelectionData(state.objectClass, viewName, selectedIds)
        .then(response => {
          const result = response.data
          if (result.status === 'ok') {
            state.selectionData = result.data
          } else if (result.status === 'nok' && result.reason === 'selection_data_not_supported') {
            state.selectionData = null
            state.selectedColumn = null
          }
        })
    } else {
      state.selectedColumn = null
    }
  },

  updateColumnOrdering: ({ state, dispatch }, data) => {
    return new Promise(resolve => {
      api.updateColumnOrdering(state.objectClass, [{ key: 'data', value: data }])
        .then(response => {
          dispatch('globalErrorDisplay', { response, context: 'Update card view column order ' + state.objectClass })
          resolve(response.data)
        })
    })
  },

  // take the ordering coming as the parameter in use
  assignColumnOrdering: ({ state }, data) => {
    return new Promise(resolve => {
      data.forEach(element => {
        const changedItem = state.cardItems.find((i) => i.id === element.id)
        changedItem.card_order = element.order
      })
      resolve()
    })
  },

  saveCardViewOrder: ({ state, dispatch }, columnOrder) => {
    return new Promise(resolve => {
      const conf = state.selectedLayoutProfile.timelineConfiguration
      conf.cardColumnOrder = columnOrder
      Vue.set(state.selectedLayoutProfile, 'timelineConfiguration', conf)
      api.updateLayoutProfile(state.selectedLayoutProfile)
        .then(response => {
          dispatch('globalErrorDisplay', { response, context: 'Save card view layout profile' })
          resolve(response.data)
        })
    })
  },
}
