import Vue from 'vue'
import clearCache from '@/utilities/clearCache'
import { LP, LPI } from '@/types/LP.types'
import Color from 'color'

export default {
  addNewContainer () {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    this.dialogItemContainerMenu = false
    const sortOrder = this.itemContainerForEdit?.sort_order
    const container = {
      columns: 12,
      sort_order: sortOrder,
    }
    return new Promise(resolve => {
      this.$store.dispatch('saveNewLayoutContainer', container).then((savedContainer: LP.Container) => {
        this.addNewFieldSet(savedContainer).then(() => {
          resolve(savedContainer)
          setTimeout(() => {
            this.changeContainersSortOrder(true)
          }, 1000)
        })
      })
    })
  },

  saveContainer (container: LP.Container) {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    return new Promise(resolve => {
      this.$store.dispatch('saveNewLayoutContainer', container).then((savedContainer: LP.Container) => {
        resolve(savedContainer)
      })
    })
  },

  toggleContainerInfoTextField (container: LP.Container) {
    this.dialogItemContainerMenu = false
    container.showInfoText = !container.showInfoText
    this.$store.dispatch('updateLayoutContainer', container)
  },

  updateLayoutContainer (container: LP.Container) {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    this.$store.dispatch('updateLayoutContainer', container)
  },

  toggleContainerCollapse (container: LP.Container) {
    this.$nextTick(() => {
      this.createSortables()
    })
    if (container.virtual && container.id) {
      this.$set(this.collapsedVirtualContainers, container.id, !this.collapsedVirtualContainers[container.id])
      return
    }
    this.$set(container, 'collapsed', !container.collapsed)
    if (this.itemLayoutEditMode) {
      this.updateLayoutContainer(container)
    }
  },

  saveVirtualContainers () {
    return new Promise<void>(resolve => {
      if (this.containers.length > 0) {
        // Already saved
        resolve()
        return
      }
      const containersWithFieldSets = JSON.parse(JSON.stringify(this.virtualContainers)).map((container, containerIndex) => {
        container.fieldSets = this.getFieldSetDefaultDataForContainer({})
        // Only one field set, set including field names
        container.fieldSets[0].fieldNames = this.fields
          .filter((field: LPI) => {
            return field.containerIndex === containerIndex
          })
          .map((field: LPI) => field.name)
        return container
      }) as LP.Container[]
      this.savingItemLayout = true
      this.createContainersAndFieldSets({ containersWithFieldSets }).then(() => {
        this.savingItemLayout = false
        resolve()
      })
    })
  },

  deleteExistingContainersAndFieldSets () {
    return new Promise<void>(resolve => {
      const promises: Promise<any>[] = []
      this.containers.forEach((container: LP.Container) => {
        promises.push(this.deleteLayoutContainer(container, { createNewWhenNoneLeft: false }))
      })
      Promise.all(promises).then(() => {
        resolve()
      })
    })
  },

  // Create Layout Containers and Field Sets
  // from imported JSON data or from virtual containers
  // Also put fields into the Field Sets
  createContainersAndFieldSets ({
    containersWithFieldSets,
    hasManyLayouts = {},
  }: {
    containersWithFieldSets: LP.Container[],
    hasManyLayouts?: object,
  }) {
    const fieldsByName = {}
    this.fields.forEach(field => {
      fieldsByName[field.name] = field
    })
    this.savingItemLayout = true
    return new Promise<void>(resolve => {
      this.deleteExistingContainersAndFieldSets().then(() => {
        const containerPromises: Promise<any>[] = []
        const fieldSetPromises: Promise<any>[] = []
        const fieldsToUpdate: string[] = []
        const fieldNamesBySavedFieldSetIndex: any[] = [] // To easily access once containers and field sets are saved and have id's
        const allFieldsInOrder: string[] = []
        containersWithFieldSets.forEach(container => {
          containerPromises.push(this.saveContainer(container))
        })
        // Save containers
        Promise.all(containerPromises).then(savedContainers => {
          savedContainers.forEach((savedContainer, containerIndex) => {
            containersWithFieldSets[containerIndex].fieldSets?.forEach((fieldSetForSave: LP.FieldSet, saveIndex: number) => {
              fieldSetForSave.layoutContainer = savedContainer
              fieldSetPromises.push(this.saveFieldSet(fieldSetForSave))
              fieldNamesBySavedFieldSetIndex.push(containersWithFieldSets[containerIndex].fieldSets?.[saveIndex].fieldNames)
              allFieldsInOrder.push(...(containersWithFieldSets[containerIndex].fieldSets?.[saveIndex].fieldNames ?? []))
            })
          })

          const selectedItemLayoutProfileItems = this.layoutProfileItemsById[this.selectedLayoutProfileIdByModel[this.objectClass]] ?? []

          selectedItemLayoutProfileItems.forEach((layoutProfileItem: LPI) => {
            layoutProfileItem.sort_order = allFieldsInOrder.indexOf(layoutProfileItem.name)
          })
          // Now in LP config
          // this.$store.dispatch('saveItemFieldsOrder', { sortBy: 'sortOrderField' }).then()

          // Save field sets and set fields
          Promise.all(fieldSetPromises).then(savedFieldSets => {
            savedFieldSets.forEach((savedFieldSet, fieldSetIndex) => {
              if (!savedFieldSet) { return }
              // For each field, go through in the same order as was exported
              fieldNamesBySavedFieldSetIndex[fieldSetIndex].forEach(fieldName => {
                const field = fieldsByName[fieldName]
                if (field?.id) {
                  field.layoutContainerFieldSet = savedFieldSet // One field-set per container in virtual
                  // Update field field-set in store
                  Vue.set(field, 'layout_container_field_set', field.layoutContainerFieldSet)
                  // Set field as visible in vuex, as field may have been not visible in previous/standard form
                  Vue.set(field, 'visible', true)
                  // Reset field set index -  used to show virtual field sets when no containers and field sets saved
                  Vue.set(field, 'fieldsetIndex', null)
                  if (hasManyLayouts[field.name]) {
                    Vue.set(field, 'has_many_layout', hasManyLayouts[field.name])
                  }
                  // Update filed over API
                  fieldsToUpdate.push(field)
                }
              })
            })

            // Clear LPI cache as these do not have layout_container_field_set.id
            clearCache('lp_data_' + this.selectedItemLayoutProfileId)
            const itemLayoutProfileId = this.selectedLayoutProfileIdByModel[this.objectClass]
            this.$store.dispatch('updateLayoutContainerFieldSetsForFields', {
              itemLayoutProfileId,
              fields: fieldsToUpdate,
            }).then(() => {
              this.getItemLayoutProfileItems({
                id: this.selectedItemLayoutProfileId,
                useCache: false,
              }).then(() => {
                this.savingItemLayout = false
                resolve()
              })
            })
          })
        })
      })
    })
  },

  deleteLayoutContainer (layoutContainer: LP.Container, { createNewWhenNoneLeft = true }) {
    this.dialogItemContainerMenu = false
    return new Promise<void>(resolve => {
      // Delete Layout Container
      this.$store.dispatch('deleteLayoutContainer', layoutContainer).then(deleteSuccessful => {
        const selectedItemLayoutProfileId = this.selectedLayoutProfileIdByModel[this.objectClass]
        // Now delete containing Field Sets
        const promises: Promise<void>[] = []
        if (deleteSuccessful) {
          const fieldSets = this.layoutContainerFieldSets[selectedItemLayoutProfileId]
          fieldSets.filter(fieldSet => fieldSet.layout_container?.id === layoutContainer.id)
            .forEach(fieldSet => {
              promises.push(this.deleteLayoutContainerFieldSet(fieldSet))
            })
        }
        Promise.all(promises).then(() => {
          resolve()
          // When everything deleted
          // check if at least once Layout Container left
          // if not, create one (with field set) to add fields to
          if (createNewWhenNoneLeft) {
            const containers = this.layoutContainers[selectedItemLayoutProfileId]
            if (containers.length === 0) {
              this.addNewContainerCallback()
            }
          }
        })
      })
    })
  },

  getContainerTranslatedName (container: LP.Container): string {
    const translations: Record<string, any> = this.translationsByLocale[this.locale]?.[this.objectClass]
    return ((container.name && translations?.attribute_groups?.[container.name]) || container.name) ?? ''
  },

  getContainerClasses (container: LP.Container) {
    const classes = ['container-form', 'pa-0']
    if (this.itemLayoutEditMode && this.$vuetify.breakpoint.lgAndUp) {
      classes.push('container-edit-' + container.columns)
    }
    if (this.resettingLayout) {
      classes.push('d-none')
    }
    return classes
  },

  getFieldSetColsInContainer (container: LP.Container, fieldSet: LP.FieldSet) {
    const containerWidth = this.getAvailableSpaceForContainer(container)
    // Take original container.columns into account
    const minSpace = 1000 / 12 * (container.columns || 12)
    return containerWidth < minSpace ? 12 : (fieldSet.columns || '')
  },

  fieldSetBgStyle (fieldSet: LP.FieldSet) {
    const defaultColor = this.fieldSets.length > 1 ? '#f6f6f6' : 'white'
    // const defaultColor = this.fieldSets.length > 1 ? '#E3F2FD' : '#E3F2FD' // For testing
    const color = fieldSet.bgColor || defaultColor
    const colorLighter = Color(color)
      .lightness(98)
      .hex()
    const tableEventRowBg = Color(color)
      .lighten(0.03)
      .hex()
    return {
      backgroundColor: color,
      '--label-bg': colorLighter,
      '--show-view-input-grey-bg': color,
      '--show-view-input-white-bg': color,
      '--readonly-input-bg': color,
      '--editable-input-bg': 'white',
      '--show-view-has-many-even-row-bg': tableEventRowBg,
      //     --: #fff;
      //     --custom-styled-input-bg: rgba(255, 255, 255, 0.6);
      //     --item-form-label-color: #888;
      //     --item-form-value-color: #4a4a4a;
      //     --item-form-disabled-value-color: rgba(0,0,0,.38);
      //     --item-form-link-color: #4a4a4a;
    }
  },

  getAvailableSpaceForContainerFieldSets (container, fieldSet) {
    const fieldSetPadding = 30
    const containerWidth = this.getAvailableSpaceForContainer(container)
    const fieldSetCols = this.getFieldSetColsInContainer(container, fieldSet)
    if (!fieldSetCols) {
      return this.getAvailableSpaceForContainerFieldSetsForAutoCols(container, containerWidth)
    }
    return containerWidth / 12 * fieldSetCols - fieldSetPadding
  },

  getAvailableSpaceForContainerFieldSetsForAutoCols (container: LP.Container, containerWidth: number) {
    const fieldSetsWithAutoCols = container.fieldSets?.filter(fieldSet => !fieldSet.columns).length ?? 1
    let fieldSetFixedColsInUse = 0;
    (container.fieldSets || []).forEach(fieldSet => {
      fieldSetFixedColsInUse += fieldSet.columns
    })
    const availableColsForAutoWidthFieldSets = (12 - fieldSetFixedColsInUse) || 1
    let availableColsForOneFieldSet = availableColsForAutoWidthFieldSets / fieldSetsWithAutoCols
    // Can't be less than 1. Use may put total fixed over 12. Or more than 12 cols. Set some limit
    if (availableColsForOneFieldSet < 1) {
      availableColsForOneFieldSet = 1
    }
    return containerWidth / 12 * availableColsForOneFieldSet
  },

  // Two options
  // Using saved containers and field sets
  // Or not, then have to create placeholders
  // But when user switches to edit mode first time
  // saved containers and field sets are created and fields place inside

  getColsForContainer (container: LP.Container) {
    let columns = this.$vuetify.breakpoint.lgAndUp
      ? container.columns
      : 12
    if (this.splitMode === 'vertical' && this.formWidth < 1000) {
      columns = 12 // columns * 2
    }
    return columns > 12 ? 12 : columns
  },

  getColsForContainerFieldSets (container: LP.Container) {
    let columns = this.getColsForContainer(container)
    if (this.$vuetify.breakpoint.mdAndUp) {
      columns = columns / this.getFieldSetsCountForContainer(container)
    }
    return columns
  },

  getAvailableSpaceForContainer (container: LP.Container) {
    const containerPadding = 30
    let splitModeMultiplier = this.splitMode === 'vertical'
      ? (1 - this.splitModeListSize / 100)
      : 1
    if (this.splitMode === 'vertical' && this.isInFirstSplitWindow) {
      splitModeMultiplier = 1 - splitModeMultiplier
    }
    return (
      (this.innerWidth - containerPadding) * splitModeMultiplier / 12 * this.getColsForContainer(container)
    )
  },

  getFieldSetsCountForContainer (container: LP.Container) {
    if (container.virtual) { // Virtual container, just in case, though should not come here
      return 1
    }
    return this.fieldSets
      .filter(fieldSet => fieldSet?.layout_container?.id === container.id).length
  },

}
