<template>
  <v-toolbar
    color="grey lighten-2 elevation-0"
    class="list-toolbar"
    height="64"
    text
  >
    <template v-if="!loadingLayoutProfiles">
      <v-toolbar-title>
        {{ $i18n.t(objectClass + '.list.title') }}
        <br v-if="listForItemId">
        <div
          class="target-item-name"
          @click="listItemOpenById(listForItemId, { className: listForItemClass, skipTargetItem: true })"
        >
          {{ listForItemName }}
        </div>
      </v-toolbar-title>
      <ShortcutsAddButton
        :object-class="objectClass"
        view-name="index"
      />
      <template>
        <LayoutProfileTabs
          v-if="!$store.getters.showListTabsOnSeparateToolbar"
        />
        <v-btn
          v-for="(button, index) in buttons.filter(b => b.show)"
          :key="index"
          v-tooltip.bottom="button.name"
          :icon="showCompactButtons"
          :small="!showCompactButtons"
          :tile="false"
          :color="showCompactButtons ? '' : button.buttonColor || 'white'"
          :class="btnClasses(button)"
          light
          depressed
          @click="button.action(button.actionParameter)"
        >
          <v-icon
            :color="button.buttonColor && !showCompactButtons ? 'white' : button.color"
            :class="showCompactButtons ? '' : 'pr-2'"
          >
            {{ button.icon }}
          </v-icon>
          {{ showCompactButtons ? '' : button.name }}
        </v-btn>
        <v-spacer />
        <GlobalFilterSelect v-if="splitMode !== 'vertical' && $vuetify.breakpoint.mdAndUp" />
      </template>
    </template>
  </v-toolbar>
</template>

<script>
import LayoutProfileTabs from './LayoutProfiles/LayoutProfileTabs'
import moment from 'moment'
import state from './../store/state'
import { createHelpers } from 'vuex-map-fields'
import itemMethods from '@/methods/item/itemMethods'
import listItemMethods from '@/methods/listItem/listItemMethods'
import ShortcutsAddButton from '@/components/Shortcuts/ShortcutsAddButton'
import GlobalFilterSelect from '@/components/App/GlobalFilterSelect'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ListToolbar',

  components: {
    GlobalFilterSelect,
    ShortcutsAddButton,
    LayoutProfileTabs,
  },

  computed: {
    ...mapFields(Object.keys(state)),

    showCompactButtons () {
      return this.$vuetify.breakpoint.mdAndDown || this.splitMode === 'vertical'
    },

    showCompactItemActionButtons () {
      return !!this.$vuetify.breakpoint.smAndDown
    },

    buttons () {
      return [
        {
          name: 'Luo uusi',
          icon: 'fa-plus',
          color: 'green',
          buttonColor: 'green',
          action: this.openNewForm,
          show: true,
          actionParameter: {},
        }, {
          name: 'Näkymä',
          icon: 'fa-columns',
          color: 'grey',
          action: this.toggleSplitModeFromList,
          show: this.$vuetify.breakpoint.mdAndUp,
        },
      ]
    },
  },

  methods: {
    ...itemMethods,
    ...listItemMethods,

    btnClasses (button) {
      const classes = ['list-toolbar-icon'] // 'text-capitalize'
      if (!this.showCompactButtons) {
        classes.push('mx-2')
        classes.push('large-button')
        if (button.buttonColor) {
          classes.push('white--text')
        }
      }
      if (this.showCompactButtons || !button.buttonColor) {
        classes.push('grey--text')
        classes.push('text--darken-1')
      }
      return classes
    },

    toggleSplitModeFromList () {
      if (this.splitMode) {
        this.splitMode = null
        const isForView = [
          'List_forTarget',
          'List_forTargetWithForm',
          'List_forTargetWithFormForAnotherResource'
        ].includes(this.$route.name)
        // When in /for/ view and form is open, go to List_forTarget route
        if (isForView && this.$route.name !== 'List_forTarget') {
          this.$router.push({
            path: '/' + this.$route.params.resource + '/for/' + this.$route.params.targetResource +
              '/' + this.$route.params.targetId + '/' + this.$route.params.targetField
          })
        }
        // Go back to normal (non-split) route
        // If not /for/ view, as /for/ view does not have /split/ in the url
        if (!isForView && this.$route.path !== '/' + this.objectClass) {
          this.$router.push({ path: '/' + this.objectClass })
        }
      } else {
        this.splitMode = 'vertical'
      }
      this.splitModeAltered = true
    },
  },
}
</script>

<style>
.target-item-name {
  font-size: 14px;
  cursor: pointer;
  color: #1976D2;
}
</style>
