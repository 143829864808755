<template>
  <div
    v-if="data"
    :class="rowClass()"
  >
    <div class="input-with-tags-parent-container">
      <NoSeInputWithTags
        ref="textField"
        v-model="data.value"
        class="no-se-input"
        @focus="$emit('focusRuleRow', data.key)"
      />
    </div>
    <v-btn
      v-if="data.removable"
      fab
      small
      text
      class="no-se-action-icon"
      color="red lighten-1"
      @click="$emit('removeRuleRow', data.key)"
    >
      <v-icon>fa fa-trash</v-icon>
    </v-btn>
    <v-btn
      v-else
      fab
      small
      text
      class="no-se-action-icon"
      color="green lighten-1"
      @click="$emit('addRuleRow', true)"
    >
      <v-icon>fa fa-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import NoSeInputWithTags from './NoSeInputWithTags'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'NoSeRule',

  components: {
    NoSeInputWithTags
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  methods: {
    rowClass () {
      if (this.data.removable) {
        return 'no-se-rule-row-removable'
      } else {
        return 'no-se-rule-row'
      }
    },

    addAttributeToCursor (attribute) {
      const editor = this.$refs.textField
      editor.addTag({
        key: attribute.value,
        value: attribute.text,
        color: '#FFA726'
      })
    },
  }
}
</script>

<style>
.input-with-tags-parent-container {
  float: left;
  width: calc(100% - 50px);
}
.no-se-rule-row {
  width: 100%;
  display: block;
  padding-bottom: 10px;
  overflow: auto;
}
.no-se-rule-row-removable {
  width: 100%;
  display: block;
  padding-bottom: 10px;
  overflow: auto;
}
.no-se-action-icon {
  float: left;
  width: 50px;
}
</style>
