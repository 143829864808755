<template>
  <div class="has-many-reference">
    <div
      v-if="hasManyItems && hasManyItems.length > 0"
      :class="hasManyFieldsWithData.includes(field.name)
        ? 'has-many-preloaded'
        : ' has-many-count-round'"
      @mouseup="showHasManyListItems($event, hasManyItems)"
      @click.stop.prevent=""
    >
      {{ hasManyCellCount }}
    </div>
    <template v-if="hasManyFieldsWithData.includes(field.name)">
      <ContentHasManyReferenceItem
        v-for="(hasManyItem, hasManyItemIndex) in hasManyItems"
        :key="'hmi_' + hasManyItemIndex"
        :item="hasManyItem"
        :field="field"
        :last-item="hasManyItemIndex === hasManyItems.length - 1"
      />
    </template>
    <template v-else>
      {{ hasManyCellValue }}
    </template>
  </div>
</template>

<script>
import util from '@/utilities/sharedUtilities'
import ContentHasManyReferenceItem from '@/components/ListContent/DataTypes/ContentHasManyReferenceItem'

export default {
  name: 'ContentHasManyReference',

  components: {
    ContentHasManyReferenceItem,
  },

  props: {
    listItem: {
      type: Object,
      default: () => {}
    },

    field: {
      type: Object,
      default: () => {}
    },

    cellValue: {
      type: Object,
      default: () => {}
    },

    hasManyFieldsWithData: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    hasManyItems () {
      return this.cellValue?.value || []
    },

    hasManyCellCount () {
      return this.hasManyFieldsWithData.includes(this.field.name)
        ? this.hasManyItems
          ? this.hasManyItems.length
          : ''
        : this.hasManyItems?.[0]
          ? this.hasManyItems[0].count
          : ''
    },

    hasManyCellValue () {
      return this.hasManyFieldsWithData.includes(this.field.name)
        ? ''
        : this.hasManyItems?.[0]
          ? this.$i18n.t(
            util.objectClassUnderscoredName(this.field.reference_class) +
              (this.hasManyItems[0].count === 1
                ? '.show.title'
                : '.list.title')
          ).toLowerCase()
          : ''
    }
  },

  methods: {
    showHasManyListItems (e, items) {
      if (e && util.clickOrDrag(e, this.$store.state.mouseDownCoords) === 'drag') {
        return
      }
      e.stopPropagation()
      // Show popup with loader
      this.$store.commit('updateValues', { showHasManyListMenu: 'loading' })
      this.positionPopup(e)
      // Show preloaded items or load items and show in popup
      this.$store.dispatch('showHasManyListItems', {
        items,
        field: this.field,
        listItem: this.listItem
      })
        .then(() => {
          this.positionPopup(e)
        })
    },

    positionPopup (e) {
      const topOffset = 0
      const leftOffset = -10
      this.$nextTick(() => {
        util.positionPopupMenu(e, 'has-many-list-menu', leftOffset, topOffset, {})
      })
    }
  }

}
</script>

<style lang="scss">
.has-many-reference {
  text-overflow: ellipsis;
  overflow: hidden;
}
.has-many-reference-item {
  position: relative;
  .has-many-line-separator {
    position: absolute;
    bottom: -1px;
    left: 0;
    line-height: 1px;
    background: #a5a5a5;
    width: 100%;
    height: 1px;
  }
}
/*
  Show count btn if content does not fit to the cell
  Do not put into has-many-reference - is used also for other components (Files)
*/
// By default hide for files and images */
/* Show only if does not fit */
.type_image, .type_file {
  .has-many-count-round {
    display: none;
  }
}
.item-has-many-items-show {
  .has-many-count-round {
    margin-top: 2px;
  }
}
.overflowing-has-many-list {
  .has-many-preloaded, .has-many-count-round {
    display: inherit;
    position: relative;
    z-index: 1;
  }
}
</style>
