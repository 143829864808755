<template>
  <v-btn
    :label="$i18n.t('aava.actions.refresh')"
    :color="'grey lighten-1'"
    class="ml-1 list-reload-btn"
    small
    icon
    dark
    text
    @click="$emit('reloadListItems')"
  >
    <v-icon small>
      fa-sync-alt
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ListRefreshBtn',
}
</script>
