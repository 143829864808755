import itemSaveQueueMethods from '@/methods/item/itemSaveQueueMethods'
import api from '@/store/api'
import { AxiosResponse } from 'axios'
import { LP } from '@/types/LP.types'
import { ListEditSavePayload } from '@/types/Item'

export default {
  ...itemSaveQueueMethods,
  getHasManyItemDfcChanges (changedField: LP.Item) {
    return new Promise(resolve => {
      if (!changedField?.name) { return resolve(false) }
      const itemBeforeDfcStart = JSON.parse(JSON.stringify(this.item))

      this.startJob().then(key => {
        this.getFormItemOnDefaultsForChange(this.resource, this.id, this.edit, {
          targetResource: this.parentResource,
          targetId: this.parentItem.token || this.parentItem.id,
          field: changedField.name,
          // TODO how to get 'changeable' for has many?
          // amc does not seem to have it
          // because of that shows created_at as changeable field
          // or must combine this.fields and this.amc?
          item: this.getItemSavePayload(this.item, this.amc),
          targetField: this.parentField.name,
          queries: this.listQueries,
        }).then((response: AxiosResponse | false) => {
          const itemAfterDfcEnd = JSON.parse(JSON.stringify(this.item))
          if (response && response.data.item) {
            const item = response.data.item
            // Add changed field to the response as this is not returned by the API
            item[changedField.name] = this.item[changedField.name]
            // Set targetObject for ~path query to work
            item.targetObject = this.parentItemWithoutDynamicFields
            const itemCopy = JSON.parse(JSON.stringify(this.itemCopy))
            this.setItemCopy(item)
            this.restoreItemChangesWhileDfcWasInProgress(item, itemCopy, itemBeforeDfcStart, itemAfterDfcEnd, changedField)
            this.setHasManyItemInList(this.index, item)
          }
          if (changedField.name !== 'updated_at' && !!this.updateHasManyParentItemOnDefaultsForChange) { // Only if not a dummy call with updated_at (for copy row feature)
            this.updateHasManyParentItemOnDefaultsForChange()
          }
          // Can not wait for queue empty, as this is the job running anyway and can't leave this 100 ms window
          // where item save would possibly happen
          // this.whenQueueIsEmpty().then(() => {
          // })
          setTimeout(() => {
            this.endJob(key)
            resolve(response)
          }, 100) // To make sure updating parent does not get fatal from the api, when sending too quickly after updating has-many row
        })
      })
    })
  },

  saveValueFromHasManyShowView ({ field, eventButton, parent, callback }: ListEditSavePayload) {
    if (this.edit || !this.item?.id) { return } // Skip save when id not available (not yet saved as permanent item)
    // Has value changed?
    if (JSON.stringify(this.itemCopy[field.name]) === JSON.stringify(this.item[field.name]) && !eventButton) {
      // console.log('s a m e :', this.itemCopy[field.name], this.item[field.name])
      if (callback) {
        callback(null)
      }
      return
    }
    console.log('_.:::-..:changed to', field.name, this.item[field.name])
    const item = JSON.parse(JSON.stringify(this.item))
    const saveFields = {}
    saveFields[field.name] = field
    const itemPayload = this.getItemSavePayload(this.item, saveFields)

    const value = item[field.name]?.['@class'] // For reference
      ? {
          '@class': item[field.name]['@class'],
          id: item[field.name].id,
        }
      : item[field.name]

    const payload = {} as any
    if (eventButton) {
      const key = eventButton.type === 'processAction' ? 'event_action' : 'event'
      payload[key] = eventButton.identifier
      if (parent?.id) {
        payload['@parent'] = parent
      }
    } else {
      payload.value = value
    }

    const itemBeforeDfcStart = JSON.parse(JSON.stringify(this.item))
    this.startJob().then((key: string) => {
      api.saveValueFromList(this.resource, itemPayload, this.listQueries, field.name, payload)
        .then((response: AxiosResponse) => {
          const itemAfterDfcEnd = JSON.parse(JSON.stringify(this.item))
          this.$store.dispatch('globalErrorDisplay', { response, context: 'Save has-many list value' })
            .then(() => {
            // Update cell response error
            // this.$set(this.$store.state.listEdit.errors, job.id + '_' + field.name, errorsCheck?.displayMessage || '')
              if (response.data.item) {
              // Remove token when original item didn't have it
                if (!('token' in item) && 'token' in response.data.item) {
                  delete response.data.item.token
                }
                const responseItem = response.data.item
                const currentValue = this.item[field.name]
                const valueBeforeSave = itemBeforeDfcStart[field.name]
                // Set item copy so we can detect from future change events if data has actually changed
                const itemCopy = JSON.parse(JSON.stringify(this.itemCopy))
                this.setItemCopy(responseItem)
                this.restoreItemChangesWhileDfcWasInProgress(responseItem, itemCopy, itemBeforeDfcStart, itemAfterDfcEnd, field)
                this.setHasManyItemInList(this.index, responseItem)
                if (JSON.stringify(currentValue) !== JSON.stringify(valueBeforeSave)) {
                // Part below moved inside restoreItemChangesWhileDfcWasInProgress
                // this.$set(this.item, field.name, currentValue)
                // responseItem[field.name] = currentValue
                // console.log('SET height NOW:', responseItem.height)
                // this.setHasManyItemInList(this.index, responseItem)
                }
              }
              console.log(callback, 778)
              if (callback) {
                callback(response)
              }
              this.endJob(key)
            })
        })
    })
  },
}
