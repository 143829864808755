import Vue from 'vue'
import api from '@/store/api'
import sharedUtilities from '@/utilities/sharedUtilities'
import { AxiosResponse } from 'axios'

export default {
  addNewFile: ({ state, dispatch }, {
    file, files, forObjectClass, forFieldName,
    forObjectId, attachmentType,
    parentClass, parentTokenOrId, parentFieldName, // For in has-many list file add
    skipPathQuery,
  }) => {
    state.showLoader = true
    return new Promise(resolve => {
      api.saveAttachment({
        forObjectClass: sharedUtilities.objectClassUnderscoredName(forObjectClass),
        forFieldName,
        forObjectId,
        attachmentType,
        file,
        parentClass,
        parentTokenOrId,
        parentFieldName,
        skipPathQuery,
      }).then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'save attachment' }).then(errorsFound => {
          if (!errorsFound) {
            const newImage = (response.data.items && response.data.items[0]) || {}
            Vue.set(files, files.length, {
              '@class': 'Attachment',
              id: newImage.id,
              token: newImage.token,
              filename: newImage.filename
            })
            resolve(true)
          } else {
            resolve(false)
          }
          state.showLoader = false
        })
      })
    })
  },

  deleteFile: ({ state, dispatch }, { index, files }) => {
    return new Promise(resolve => {
      const id = files[index].id
      state.showLoader = true
      dispatch('deleteItem', { resource: 'attachments', id }).then(() => {
        Vue.delete(files, index)
        state.showLoader = false
        resolve(true)
      })
    })
  },
}
