const portalName = 'Aava Portal'

interface Params {
  action: string
  resetToken?: string
}

export default ({ action, resetToken }: Params) => {
  let url = window.location.origin + '/login/'
  let portalUrl = encodeURIComponent(window.location.href)
  if (resetToken) {
    url += '#reset_password/' + resetToken + '?'
    // Skip query parameters, do not want to re-trigger going back to login portal
    // after password has been changed
    portalUrl = window.location.origin + window.location.pathname
  } else {
    url += '?action=' + action
  }
  url += '&portalName=' + portalName
  url += '&portalUrl=' + portalUrl
  window.location.href = url
}
