const Label = {
  labelize (source, defaultValue) {
    if (!source) {
      if (!defaultValue) {
        return ''
      } else {
        return defaultValue
      }
    }
    const sanitizedSource = source.replace('-', '_')

    // If the source is a translation key it must be translated.
    if (this.is_translation_key(sanitizedSource)) return this.i18n.t(sanitizedSource, { defaultValue })
    return source
  },

  // Translation key is detected by its structure, it start with a lower-case
  // letter, the first_word is followed by ':' or '.' and it contains only lower-case
  // letters, numbers and characters ._:-
  is_translation_key (string) {
    if (string === undefined || string === null) return false
    return string.match(/^[a-z_]+[\.:][a-z\d_\-\.:]+$/); // eslint-disable-line
  }

}

export default Label
