<template>
  <v-menu
    v-if="showMoreButton"
    :z-index="160"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        ref="showMoreButton"
        :ripple="false"
        class="layout-profile-tab show-layout-profiles-menu text-none px-3"
        style="color: white;"
        tile
        text
        v-on="on"
      >
        <div>
          {{ $i18n.t('aava.tabs.more') }}
          <v-icon>
            fa-caret-down
          </v-icon>
        </div>
      </v-btn>
    </template>
    <div class="layout-profiles-menu elevation-2">
      <LayoutProfileTab
        v-for="(layoutProfile, index) in layoutProfilesComputed"
        :key="layoutProfile.id + '_' + index"
        :index="visibleTabsCount + index"
        :layout-profile="layoutProfile"
        :parent-layout-profiles="layoutProfilesComputed"
      />
    </div>
  </v-menu>
</template>

<script>
import LayoutProfileTab from './LayoutProfileTab'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'LayoutProfilesMoreMenu',

  components: {
    LayoutProfileTab,
  },

  props: {
    forItem: {
      type: Boolean,
      default: false,
    },
    itemLayoutProfiles: {
      type: Array,
      default: () => { return [] },
    },
    visibleTabsCount: {
      type: Number,
      default: 100,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    showMoreButton () {
      return !this.modal && this.layoutProfilesComputed.length > 0
    },

    layoutProfilesComputed () {
      const items = this.forItem ? this.itemLayoutProfiles : this.layoutProfiles
      return items.filter((layoutProfile, index) => {
        // Does not fit into tabs bar
        return index >= this.visibleTabsCount
      })
    },
  },

  methods: {
    ...methods,
  },
}
</script>

<style lang="less">
.layout-profiles-menu {
	max-width: 300px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
  .badge {
    position: inherit;
  }
}
</style>
