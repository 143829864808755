<template>
  <v-navigation-drawer
    v-model="showMenuNavigationDrawer"
    :overlay-opacity="0"
    :temporary="true"
    class="side-menu"
    style="z-index: 200; padding-top: 60px;"
    color="grey lighten-2"
    app
    absolute
  >
    <template
      v-for="(menuItem, index) in menuItems"
    >
      <v-list-item
        :key="index"
        :class="'level1 ' + (openedItemsByLevel[1] === index ? 'level1-selected' : '')"
        :dense="!(openedItemsByLevel[1] === index)"
        :ripple="false"
        @click.stop="openMenuItem(menuItem, 1, index)"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ menuItem.label }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon
            v-if="menuItem.children && menuItem.children.length > 0"
            small
          >
            {{ openedItemsByLevel[1] === index ? 'fa-caret-down' : 'fa-caret-down' }}
          </v-icon>
        </v-list-item-icon>
      </v-list-item>

      <template
        v-for="(child, childIndex) in menuItem.children"
      >
        <v-list-item
          v-if="openedItemsByLevel[1] === index"
          :key="index + '-' + childIndex"
          :class="'level2 ' + (openedItemsByLevel[2] === index + '-' + childIndex ? 'level2-selected' : '') + selectedListItemClass(child)"
          :ripple="false"
          :dense="!(openedItemsByLevel[2] === index + '-' + childIndex)"
          @click.stop="openMenuItem(child, 2, index + '-' + childIndex)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ child.label }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon
              v-if="child.children && child.children.length > 0"
              small
            >
              {{ openedItemsByLevel[2] === index + '-' + childIndex ? 'fa-caret-down' : 'fa-caret-down' }}
            </v-icon>
          </v-list-item-icon>
        </v-list-item>

        <template
          v-for="(child2, child2Index) in child.children"
        >
          <v-list-item
            v-if="openedItemsByLevel[2] === index + '-' + childIndex && openedItemsByLevel[1] === index"
            :key="index + '-' + childIndex + '-' + child2Index"
            :ripple="false"
            :class="'level3 ' + selectedListItemClass(child2)"
            dense
            @click.stop="openMenuItem(child2, 3, '')"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ child2.label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </template>
  </v-navigation-drawer>
</template>

<script>
import axios from 'axios'
import { createHelpers } from 'vuex-map-fields'
import state from '../../store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'MenuNavigationDrawer',

  data () {
    return {
      openedItemsByLevel: {
        1: null,
        2: null,
      },
      openedMenuItems: [],
      menuLeftOffset: 0,
      activeSubmenuWidth: 0,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    submenuStyle () {
      return {
        left: this.menuLeftOffset + 'px'
      }
    },

    thirdLevelMenuStyle () {
      const offset = 0
      return {
        left: (this.activeSubmenuWidth + offset) + 'px'
      }
    },
  },

  methods: {
    isActiveMenuElement (item) {
      return '/' + item.url === this.$route.fullPath
    },

    selectedListItemClass (item) {
      return this.isActiveMenuElement(item) ? ' selected-list-item' : ''
    },

    // TODO - refactor. duplicate in MenuToolbar
    openMenuItem (item, level, index) {
      if (item.children) {
        if (this.openedItemsByLevel[level] === index) {
          this.openedItemsByLevel[level] = null
          if (level === 1) {
            this.openedItemsByLevel[2] = null
          }
        } else {
          // Show children menu
          this.openedItemsByLevel[level] = index
          if (level === 1) {
            this.openedItemsByLevel[2] = null
          }
        }
      } else if (item.separate) {
        // For system requests send POST to the server
        if (item.url.includes('system/')) {
          axios.post(window.location.origin + '/gorilla/' + item.url, '_blank')
            .then(() => {
              location.reload()
            })
        } else {
          // Otherwise, is compatibility view and open the url
          this.$router.push({ path: '/compatibility/' + item.url })
        }
      } else if (item.url && item.external_path) {
        window.open(window.location.origin + '/' + item.url)
      } else if (item.url) {
        this.$router.push({ path: '/' + item.url })
      }
    },
  }
}
</script>

<style lang="scss">
.side-menu {
  .v-list-item__title {
    font-weight: 300 !important;
  }
  .v-list-item--dense {
    height: 30px !important;
    min-height: 30px !important;
  }
  z-index: 200 !important; /* TODO test. was 500 but has to be less than 201 which is dialog */
  .level1-selected {
    background: #eee;
    .v-list-item__title {
      font-weight: 300;
    }
  }
  .level2 {
    .v-list-item__content {
      padding-left: 10px;
    }
    background: #eee;
    .menu-item {
      background: white;
    }
  }
  .level2-selected {
    background: #fff;
  }
  .level3 {
    background: #fff;
    .v-list-item__content {
      padding-left: 25px;
    }
  }
  .selected-list-item {
    background: #777;
    div {
      color: white !important;
      font-weight: 500 !important;
    }
  }
}
</style>
