<template>
  <div
    class="chart_container"
    :style="chartContainerStyle"
  >
    <div
      v-if="!refreshing && index !== ''"
      :id="index"
      class="float_right remove_button_container"
      @click="$emit('delete', dashboardItem.id)"
    >
      <i
        class="fa fa-times remove_button"
      />
    </div>
    <canvas
      v-if="!refreshing"
      :id="canvasId"
    />
  </div>
</template>

<script>
import dashboardChartHelper from '@/components/Dashboard/dashboardChartHelper'
import Chart from 'chart.js/auto'
// import { Chart, LineController, LineElement, PointElement, LinearScale, Title } from `chart.js`

// TODO - tree shaking?
// Chart.register(LineController, LineElement, PointElement, LinearScale, Title);

export default {
  name: 'DashboardChart',

  props: {
    dashboardItem: {
      default () { return {} },
      type: Object,
    },
    chartData: {
      default () { return {} },
      type: Object
    },
    index: {
      default () { return '' },
      type: String
    },
    spaceAllocation: {
      default () { return {} },
      type: Object
    }
  },

  data () {
    return {
      chart: null
    }
  },

  watch: {
    refreshing (value) {
      if (value) { return }
      this.$nextTick(() => {
        this.drawChart()
      })
    },
  },

  computed: {
    canvasId () {
      return 'canvas_id_' + Math.random()
    },

    chartInfoCompleted () {
      // For dash
      const info = JSON.parse(JSON.stringify(this.chartData))
      info.options.maintainAspectRatio = false
      // From version 2 to version 4
      if (info.options.scales?.xAxes) {
        info.options.scales.x = info.options.scales?.xAxes?.[0]
        delete info.options.scales.xAxes
      }
      if (info.options.scales?.yAxes) {
        info.options.scales.y = info.options.scales?.yAxes?.[0]
        delete info.options.scales.yAxes
      }
      // Dataset label is set the old way
      if (info.options?.legend) {
        if (!info.options.plugins) {
          info.options.plugins = {}
        }
        info.options.plugins.legend = info.options.legend
      }
      // Dataset label is set the old way
      if (info.options?.title) {
        if (!info.options.plugins) {
          info.options.plugins = {}
        }
        info.options.plugins.title = info.options.title
        // For some reason in version 4, display is set to false by default
        if (!('display' in info.options.plugins.title)) {
          info.options.plugins.title.display = true
        }
      }
      // Globally disable border
      if (info.options.scales?.x && !info.options.scales.x.border) {
        info.options.scales.x.border = { display: false }
      }
      if (info.options.scales?.y && !info.options.scales.y.border) {
        info.options.scales.y.border = { display: false }
      }
      return info
    },

    refreshing () {
      return this.$store.state.dashboardItemsRefreshing
    },

    chartContainerStyle () {
      return {
        width: this.spaceAllocation.box_width + 'px',
        height: this.spaceAllocation.box_height + 'px',
        paddingTop: (this.chartData?.options?.title) ? '' : 10 + 'px'
      }
    },

    chartInfo () {
      if (this.chartData.type && dashboardChartHelper.validateData(this.dashboardItem)) {
        return dashboardChartHelper.completeData(this.chartData.type, this.dashboardItem)
      }
      return {}
    },

    chartType () {
      return this.chartData.type
    },

    chartWidth () {
      return this.spaceAllocation.box_width
    },

    chartHeight () {
      return this.spaceAllocation.box_height
    }
  },

  mounted () {
    this.drawChart()
  },

  methods: {
    drawChart () {
      const ctx = document.getElementById(this.canvasId)
      if (!ctx) { return }
      if (this.chart) {
        this.chart.destroy()
      }
      this.$nextTick(() => {
        this.chart = new Chart(ctx, this.chartInfoCompleted)
      })
    },
  },
}
</script>

<style scoped>
  .chart_container {
    margin: auto auto;
    position: relative;
  }
  .fade-leave-active {
    transition: opacity 3s;
  }
  .fade-leave-to {
    opacity: 0;
  }
</style>
