
import { Field } from '@/types/FieldTypes'

export default {

  name: 'FilesFile',

  props: {
    file: {
      type: Object as () => Field.File,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    editInList: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showCompactOneFile: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    openFileInNewTab () {
      // Use same check as for split mode, where images and PDF are supported
      return this.isImageOrPDF
    },

    isImageOrPDF () {
      return this.file.content_type?.includes('/pdf') || this.file.content_type?.includes('image/')
    },
  },

  methods: {
    openFile (e: MouseEvent) {
      if (this.$store.state.splitMode && this.openFileInSplitMode()) {
        e.preventDefault()
        e.stopPropagation()
      }
      // Otherwise, do nothing, btn href+download props are used to download the file
    },

    openFileInSplitMode () {
      if (!this.isImageOrPDF) { return false }
      this.$store.state.splitModeFile = this.file
      return true
    },

    shortName (filename: string) {
      const maxFileName = 30
      if (filename.length > maxFileName) {
        return filename.substr(0, maxFileName - 10) + '...' + filename.substr(-10)
      }
      return filename
    },
  },
}
