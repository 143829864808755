<template>
  <div
    class="options_column"
    :style="optionsColumnStyle"
  >
    <div class="options_type_title">
      {{ title }}
    </div>
    <div
      class="options_container"
      :style="optionsContainerStyle"
    >
      <div
        class="options_inner_container"
        :style="optionsContainerStyle"
      >
        <DashboardItemAddOption
          v-for="(itemOption, index) in dashboardAvailableItems[groupType]"
          :key="index"
          :option="itemOption"
          :space-allocation="spaceAllocation"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardItemAddOption from './DashboardItemAddOption'
import dashboardConfig from '@/components/Dashboard/dashboardConfig'

export default {
  name: 'DashboardItemAddGroup',

  components: {
    DashboardItemAddOption,
  },

  props: {
    groupType: {
      default () { return '' },
      type: String,
    },
    title: {
      default () { return '' },
      type: String,
    },
    option: {
      default () { return {} },
      type: Object,
    },
    spaceAllocation: {
      default () { return {} },
      type: Object,
    },
  },

  computed: {
    dashboardAvailableItems () {
      return this.$store.state.dashboardAvailableItems
    },

    optionsColumnStyle () {
      return {
        height: this.spaceAllocation.box_height * 2 - dashboardConfig.ADD_ITEM_BOX_TITLE_HEIGHT_PX + 'px'
      }
    },

    optionsContainerStyle () {
      return {
        height: (
          this.spaceAllocation.box_height * 2 -
          (dashboardConfig.ADD_ITEM_BOX_TITLE_HEIGHT_PX + dashboardConfig.ADD_ITEM_BOX_SUB_TITLE_HEIGHT_PX) +
          'px'
        )
      }
    },
  },
}
</script>

<style scoped>
  .options_column {
    width: 100%;
    float: left;
    padding: 0;
    text-align: center;
    overflow: hidden;
  }
  .options_type_title {
    font-size: 14px;
    font-weight: 700;
    padding: 15px 0 5px 0;
  }
  .options_container {
    width: 100%;
    position: relative;
    left: -10px;
    overflow: hidden;
  }
  .options_inner_container {
    margin-right: -20px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 0 0 10px;
  }
</style>
