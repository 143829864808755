import { ListEditSaveJob } from '@/types/ListEdit'
import { BaseItem, Types } from '@/types/AppTypes'

const listSelectionProps = {
  selectedItems: [] as BaseItem[],
  selectedId: null,
  lastSelectedIndex: null,
  dragStartIndex: null as number | null,
  mouseEnterRowIndex: null as number | null,
  itemClickTimer: null,
}

const renderingProps = {
  rowPool: [],
  cellPool: [],
  pagesOnScreen: 2,
  // Random indexes to use list items to measure how much space are given when fixed width is not set
  rowsToMeasureWidthsFrom: [0, 3, 8, 16],
  measuringColumnWidths: false,
  renderedAreaNumber: null,
  listVisibleItems: [],
}

export default {
  ...renderingProps,
  ...listSelectionProps,
  listItems: [] as BaseItem[],
  listTotalCount: null,
  hasManyListItemsFetchCount: 100,
  hasManyListItems: [] as Types.Item[],
  hasManyListItemsCount: null,
  sendingEventActionForIds: [] as (number | string)[],
  showBulkDeleteDialog: false,
  userId: 0,
  listBreakdownSearchIds: [],
  showHasManyListMenu: false,
  showContentFullTextMenu: false,
  showListRefreshButton: false,
  showHeaderActionsActionsMenu: false,
  showHeaderActionsDownloadMenu: false,
  showListColumnsSelector: false,
  listEdit: {
    serverItems: {},
    editing: true,
    queue: [] as ListEditSaveJob[],
    queueLog: [] as ListEditSaveJob[],
    savingJob: null as ListEditSaveJob | null,
    errors: {}, // By keys for easy access id_fieldName
    current: {
      item: null,
      field: null,
      saving: false,
      saveCallback: null,
      valueBeforeEdit: null,
      unitValueBeforeEdit: null, // For decimal, quantity and price
    },
    next: {
      item: null,
      field: null,
    },
  },
  columnsSelectorProps: {
    layoutProfileItems: [],
    objectClass: '',
    forHasMany: false,
    // Only pre-set when selecting for has-many
    previewItemId: null,
    callback: () => {},
  },
  hasManyFixedWidthsSetByItemDesigner: null,

  showListTextLanesMenuMenu: false,
  splitModeAltered: false,
  confirmingSelectedDelete: false,
  mouseDownCoords: {
    x: null,
    y: null
  } as {
    x: null | number,
    y: null | number,
  },
  layoutProfileView: 'list',
  totalLoadRequestsProcessed: 0,
  uniqueValueFilterOptions: [],
  addressModalData: {
    address: null,
    from: null
  },
  showAddressModal: false,
  listForItemId: null,
  listForItemClass: null,
  listForItemName: null,
}
