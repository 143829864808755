export default {
  scrolledLeft: 0,
  scrolledTop: 0,
  rowHeight: 17,
  defaultRowHeight: 17,
  offsetItems: 0,
  itemsOnPage: 30,
  listHeaderOffset: 94,
  firstItemIndex: 0,
  lastItemIndex: 19,
  rowPadding: 12,
  textLanes: 1,
  hoverRowIndex: null,
  fixedScrolledLeft: 0,
  listOffsetLeft: 0,
  scrollingDirection: 'down',
  overflowingCells: [],
  overflowingHasManyCells: [],
  hoverCell: '',
  clientX: 0,
  clientY: 0
}
