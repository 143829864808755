<template>
  <v-btn
    :text="!showListColumnsSelector"
    :color="showListColumnsSelector ? '#ddd' : ''"
    class="text-none"
    tile
    depressed
    @click.stop="showColumnsMenu"
  >
    <i class="fa fa-th-list icon-right-space" />
    {{ $vuetify.breakpoint.lgAndUp && splitMode !== 'vertical'
      ? $i18n.t('aava.index.columns')
      : '' }}
  </v-btn>
</template>

<script>
import methods from '@/components/methods'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import clearCache from '@/utilities/clearCache'
import api from '@/store/api'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ListColumnsSelector',

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    ...methods,

    showColumnsMenu (e) {
      if (this.showListColumnsSelector) {
        this.showListColumnsSelector = false
        return
      }
      this.hideAllListHeaderActionMenus()
      this.columnsSelectorProps = {
        layoutProfileItems: JSON.parse(JSON.stringify(this.layoutProfileItems)),
        objectClass: this.objectClass,
        callback: this.save,
        selectedLayoutProfile: this.selectedLayoutProfile,
      }
      this.showListColumnsSelector = true
    },

    save ({ columns, reloadListOnReturn }) {
      this.$store.dispatch('updateLayoutProfileProp', {
        layoutProfile: this.selectedLayoutProfile,
        propName: 'doNotReloadListOnReturn', // By default it reloads
        propValue: !reloadListOnReturn,
      })
      clearCache('lp_data_' + this.selectedLayoutProfileId)
      const newColumns = JSON.parse(JSON.stringify(columns))
      const columnsBeforeByName = JSON.parse(JSON.stringify(this.$store.getters.layoutProfileItemsByName))
      // 1. Update layout profile items without waiting results from the api
      this.layoutProfileItems = columns
      // 2. Update list items (new columns may have been added and that data must be downloaded for list items)
      this.reloadListItemsWithoutCounts().then(() => {
        this.menu = false
        this.saving = false
        this.$nextTick(() => {
          this.adjustCellWidthsAndLineHeight()
        })
      })
      setTimeout(() => {
        const promises = []
        // 3. Save field visibility field by field, check if visibility has changed
        newColumns.forEach(column => {
          // Visibility changed
          if (column.visible && !columnsBeforeByName[column.name].visible) {
            promises.push(this.$store.dispatch('showListField', column.name))
          } else if (!column.visible && columnsBeforeByName[column.name].visible) {
            promises.push(this.$store.dispatch('hideListField', column.name))
          }
          // Editable-in-list changed
          if (column.editable_in_list !== columnsBeforeByName[column.name].editable_in_list) {
            api.saveListFieldEditableValue(column.id, column.editable_in_list)
          }
        })
        Promise.all(promises).then(() => {
          // 4. Save order of layout profile items. Do this when all field visibility queries have a response, otherwise api sometimes returns fatal
          this.$store.dispatch('sortLayoutProfileItems')
        })
      }, 1500) // Give time for list to reload, then save new settings
    },
  },
}
</script>
