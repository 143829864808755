<template>
  <div>
    <span
      v-if="listItem"
      :class="classNames"
      :style="style"
    >
      {{ customValue }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'ContentBoolean',

  props: {
    listItem: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    cellValue: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    classNames () {
      return this.cellValue.value ? 'label label-success' : 'label label-danger'
    },

    stringValue () {
      return this.cellValue.value ? 'true' : 'false'
    },

    style () {
      const style = {}
      if (this.field.items && this.field.items[this.stringValue] &&
        this.field.items[this.stringValue].color
      ) {
        style.background = this.field.items[this.stringValue].color
      }
      return style
    },

    customValue () {
      const locale = this.$store.state.locale
      const customValue = this.field.items && this.field.items[this.stringValue]
        ? this.field.items[this.stringValue]['show_text_' + locale]
          ? this.field.items[this.stringValue]['show_text_' + locale]
          : this.field.items[this.stringValue]['text_' + locale]
            ? this.field.items[this.stringValue]['text_' + locale]
            : ''
        : ''
      return customValue || this.$i18n.t('aava.general.' +
        (this.cellValue.value ? 'yes' : 'no'))
    }
  }
}
</script>
