<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="grey lighten-2"
        height="26"
        class="elevation-0 ml-2"
        outlined
        dense
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          x-small
          color="grey darken-1"
        >
          {{ value || 'auto' }}
        </v-icon>
        <v-icon
          x-small
          class="ml-3"
          color="grey darken-1"
        >
          fa-caret-down
        </v-icon>
      </v-btn>
    </template>
    <div class="select-menu elevation-5">
      <div class="picker-options">
        <div
          v-for="(contentColumnOption, index) in contentColumnOptions"
          :key="index + '_co'"
          :class="'popup-menu-el ' + (value === contentColumnOption.value ? 'menu-el-disabled' : '')"
          @click="change(contentColumnOption.value)"
        >
          <a href="javascript:;">
            <!-- span class="fa fa-line-columns"></span -->
            {{ contentColumnOption.text }}
          </a>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'FieldSetColumnsPerRowSelector',

  props: {
    value: {
      type: Number,
      default: 3,
    },
  },

  // TODO change icon to fa-line-columns, when fontawesome 6.0.1 will have it?

  data () {
    return {
      // TODO translate
      contentColumnOptions: [
        {
          value: 0,
          text: 'auto',
        }, {
          value: 1,
          text: '1 columns',
        }, {
          value: 2,
          text: '2 columns',
        }, {
          value: 3,
          text: '3 columns',
        }, {
          value: 4,
          text: '4 columns',
        },
      ],
    }
  },

  methods: {
    change (value) {
      this.$emit('input', value)
      this.$emit('change')
    },
  },
}
</script>
