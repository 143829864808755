import colors from './chartColors'

const data = {
  labels: ['Product Category 1', 'Product Category 2', 'Product Category 3', 'Product Category 4'],
  stockLevels: [250, 400, 350, 300, 275, 325, 400, 375, 350, 325, 275, 300, 350, 325, 275, 400, 375, 350, 325, 300],
  minStockLevels: [200, 350, 300, 250, 225, 300, 375, 325, 300, 275, 250, 275, 325, 300, 250, 375, 325, 300, 275, 250],
  daysOfInventory: [25, 20, 22, 24, 27, 23, 19, 26, 21, 28, 25, 24, 22, 21, 23, 26, 20, 28, 24, 22]
}

export default [
  {
    name: 'sales_invoices',
    key: 'sales_invoices.list.title',
    icon: 'fa-file-invoice',
    title: 'Minimum Stock Level Comparison',
    chartInfo: {
      type: 'chart.js',
      chartType: 'bar',
      chartData: {
        labels: data.labels.map((label, index) => `Day ${index + 1}`),
        datasets: [{
          label: 'Days of Inventory',
          data: data.daysOfInventory,
          type: 'line',
          yAxisID: 'days',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: colors.teal_d1,
          borderWidth: 3,
          pointRadius: 0
        }, {
          label: 'Stock Levels',
          data: data.stockLevels,
          backgroundColor: colors.blue,
        }, {
          label: 'Minimum Stock Levels',
          data: data.minStockLevels,
          backgroundColor: colors.green,
        }]
      },
      chartOptions: {
        responsive: true,
        legend: {
          display: true,
          position: 'top',
        },
        title: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const value = tooltipItem.yLabel
              return value < 0 ? -value : value
            }
          }
        },
        scales: {
          x: {
            ticks: {
              display: false
            },
            gridLines: {
              display: false
            }
          },
          y: {
            title: {
              display: true,
              text: 'Stock Levels'
            },
            ticks: {
              beginAtZero: true
            }
          },
          days: {
            position: 'right',
            title: {
              display: true,
              text: 'Days of Inventory'
            },
            ticks: {
              beginAtZero: true
            }
          },
        }
      },
    },
  },
  {
    name: 'sales_invoices',
    key: 'sales_invoices.list.title',
    icon: 'fa-file-invoice',
    title: 'Backorder Rate',
    chartInfo: {
      type: 'chart.js',
      chartType: 'bar',
      chartData: {
        datasets: [
          {
            label: 'Order Volume',
            data: [200, 180, 220, 190, 210],
            backgroundColor: colors.orange,
            fill: true,
          },
          {
            label: 'Backorders',
            data: [20, 40, 30, 25, 45],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: colors.grey_d1,
            fill: true,
            yAxisID: 'backorders',
          },
        ],
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
      },
      chartOptions: {
        scales: {
          y: {
            id: 'volume',
            title: {
              display: true,
              text: 'Order Volume',
              font: {
                size: 16,
              },
            },
            position: 'left',
          },
          y1: {
            id: 'backorders',
            title: {
              display: true,
              text: 'Backorders',
              font: {
                size: 16,
              },
            },
            position: 'right',
          },
          x: {
            title: {
              display: false,
              text: 'Weeks',
              font: {
                size: 16,
              },
            },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              callback: function (value, index, values) {
                return 'Day ' + (index * 5 + 1)
              },
            },
          },
        },
      },
    },
  },
  {
    name: 'sales_invoices',
    key: 'sales_invoices.list.title',
    icon: 'fa-file-invoice',
    title: 'Stock Level Comparison by Day',
    chartInfo: {
      type: 'chart.js',
      chartType: 'bar',
      chartData: {
        labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8', 'Day 9', 'Day 10', 'Day 11', 'Day 12', 'Day 13', 'Day 14', 'Day 15', 'Day 16', 'Day 17', 'Day 18', 'Day 19', 'Day 20', 'Day 21', 'Day 22', 'Day 23', 'Day 24', 'Day 25', 'Day 26', 'Day 27', 'Day 28', 'Day 29', 'Day 30'],
        datasets: [{
          label: 'Minimum Stock Level',
          type: 'line',
          borderColor: 'rgb(255, 99, 132)',
          data: [100, 150, 200, 75, 125, 100, 150, 200, 75, 125, 100, 150, 200, 75, 125, 100, 150, 200, 75, 125, 100, 150, 200, 75, 125, 100, 150, 200, 75, 125],
          fill: false
        }, {
          label: 'Actual Stock Levels',
          type: 'bar',
          backgroundColor: 'rgb(54, 162, 235)',
          data: [90, 120, 180, 60, 80, 90, 120, 180, 60, 80, 90, 120, 180, 60, 80, 90, 120, 180, 60, 80, 90, 120, 180, 60, 80, 90, 120, 180, 60, 80],
          borderWidth: 1
        }, {
          label: 'Demand Forecast',
          type: 'line',
          borderColor: 'rgb(75, 192, 192)',
          data: [80, 100, 150, 50, 90, 80, 100, 150, 50, 90, 80, 100, 150, 50, 90, 80, 100, 150, 50, 90, 80, 100, 150, 50, 90, 80, 100, 150, 50, 90],
          fill: false,
          borderDash: [5, 5]
        }, {
          label: 'Days of Inventory',
          type: 'bar',
          backgroundColor: 'rgb(255, 205, 86)',
          data: [15, 20, 30, 10, 8, 15, 20, 30, 10, 8, 15, 20, 30, 10, 8, 15, 20, 30, 10, 8, 15, 20, 30, 10, 8, 15, 20, 30, 10, 8],
          borderWidth: 1,
          yAxisID: 'y-axis-2'
        }],
      },
      chartOptions: {
        responsive: true,
        legend: {
          display: true,
          position: 'top',
        },
        title: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const value = tooltipItem.yLabel
              return value < 0 ? -value : value
            }
          }
        },
        scales: {
          x: {
            ticks: {
              display: false
            },
            gridLines: {
              display: false
            }
          },
          y: {
            gridLines: {
              display: false
            },
            ticks: {
              display: false,
              beginAtZero: true,
            }
          },
        }
      },
    },
  },
  {
    name: 'sales_invoices',
    key: 'sales_invoices.list.title',
    icon: 'fa-file-invoice',
    title: 'ABC Analysis',
    chartInfo: {
      type: 'chart.js',
      chartType: 'pie',
      chartData: {
        labels: ['A', 'B', 'C'],
        datasets: [{
          label: 'Total Inventory Value (€)',
          backgroundColor: [
            'rgba(54, 162, 235, 0.8)',
            'rgba(255, 206, 86, 0.8)',
            'rgba(255, 99, 132, 0.8)',
          ],
          data: [70, 20, 10],
        }],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
          },
          outlabels: {
            text: '%l %p',
            color: 'white',
            stretch: 45,
            font: {
              resizable: true,
              minSize: 12,
              maxSize: 18
            }
          },
        }
      },
    },
  },
]
