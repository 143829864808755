import Vue from 'vue'

export default {
  // Select rows while user is dragging mouse and holding down mouse main button
  // Can not use row enter event because if moving mouse very fast
  // it skips some rows - issue in previous list view version
  listSelectItemsOnDrag: ({ state, dispatch, getters }, e) => {
    // if (getters.inListEditMode) { return } // Was requested to support it in edit mode
    if (state.dragStartIndex === null || state.mouseEnterRowIndex === null) { return }
    // Only clear selection if Ctrl key is not down
    // With Ctrl can select multiple ranges
    if (!e.ctrlKey) {
      dispatch('clearSelected')
    }
    state.lastSelectedIndex = null
    dispatch('selectItemsByRange', {
      from: state.dragStartIndex,
      to: state.mouseEnterRowIndex
    })
    state.lastSelectedIndex = state.mouseEnterRowIndex
  },

  // Clears selection
  clearSelected: ({ state }) => {
    state.selectedItems.forEach(itemIndex => {
      if (state.listItems[itemIndex]) {
        Vue.set(state.listItems[itemIndex], 'selected', false)
      }
    })
    state.selectedItems = []
    state.lastSelectedIndex = null
  },

  // Selects items by range
  selectItemsByRange: ({ state, getters }, { from, to }) => {
    // if (getters.inListEditMode) { return }
    if (to < from) {
      [to, from] = [from, to]
    }
    for (let i = from; i <= to; i++) {
      if (state.listItems[i] && !state.listItems[i].selected) {
        Vue.set(state.listItems[i], 'selected', true)
        Vue.set(state.selectedItems, state.selectedItems.length, i)
      }
    }
  },

  // Toggles selection for one item
  toggleSelectByIndex: ({ state }, index) => {
    if (state.listItems[index]) {
      if (state.listItems[index].selected) {
        state.selectedItems.splice(state.selectedItems.indexOf(index), 1)
      } else {
        Vue.set(state.selectedItems, state.selectedItems.length, index)
        state.lastSelectedIndex = index
      }
      Vue.set(state.listItems[index], 'selected', !state.listItems[index].selected)
    }
  },

  // Expands selection to item index
  // User has selected something, holds down Shift key and clicks another item
  // this item, and items in between last selected item, are selected
  expandSelectAreaTo: ({ state, dispatch }, index) => {
    if (state.lastSelectedIndex === null) {
      return
    }
    dispatch('selectItemsByRange', {
      from: state.lastSelectedIndex,
      to: index
    })
  },

  // Toggles selection for one item in area
  // User holds down Shift key and presses arrow (up or down) key
  toggleAreaExpandByOne: ({ state, dispatch }, index) => {
    if (!state.selectedItems.includes(state.lastSelectedIndex) ||
      !state.listItems[state.lastSelectedIndex] ||
      !state.listItems[index]
    ) {
      return
    }
    const toggleIndex = state.selectedItems.includes(index)
      ? state.lastSelectedIndex
      : index
    if (toggleIndex !== index) {
      state.lastSelectedIndex = index
    }
    dispatch('toggleSelectByIndex', toggleIndex)
  },

  // Selects first item in the list
  selectFirstItem: ({ dispatch, getters }) => {
    if (getters.inListEditMode) { return }
    dispatch('toggleSelectByIndex', 0)
  },

  // Selects next or previous item
  // nextOrPrevious determines which one
  selectNextOrPreviousItem: ({ state, dispatch, getters }, nextOrPrevious) => {
    if (getters.inListEditMode) { return }
    if (state.selectedItems.length > 1) {
      return
    }
    if (state.selectedItems.length === 0) {
      dispatch('selectFirstItem')
    } else if (state.selectedItems) {
      const targetIndex = state.selectedItems[0] +
        (nextOrPrevious === 'next'
          ? 1
          : -1)
      if (targetIndex < 0 || targetIndex >= state.listItems.length) {
        return
      }
      dispatch('toggleSelectByIndex', targetIndex)
      dispatch('toggleSelectByIndex', state.selectedItems[0])
    }
  }
}
