<template>
  <div style="width: 85%; max-height: 120px;">
    <div
      v-for="(item, i) in Array(4)"
      :key="i"
      style="width: 25%; float: left; padding: 15px; position: relative;"
    >
      <div
        style="position: absolute;
        top: 50%; right: 50%;
        font-size: 20px;
        transform: translate(50%,-50%);"
      >
        {{ options[i].valueText || options[i].value }}
      </div>
      <VueSvgGauge
        :start-angle="-180"
        :end-angle="180"
        :value="options[i].value"
        :separator-step="1"
        :min="0"
        :max="options[i].max"
        :inner-radius="85"
        :separator-thickness="2"
        :gauge-color="[{ offset: 30, color: options[i].color }, { offset: 100, color: options[i].color }]"
        :scale-interval="0"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultiGauge',
  data () {
    return {
      options: [
        {
          color: '#4CAF50',
          value: 8,
          max: 10,
        },
        {
          color: '#FFC107',
          value: 27,
          max: 50,
        },
        {
          color: '#666',
          value: 26,
          valueText: '+7%',
          max: 50,
        },
        {
          color: '#EF5350',
          value: 3,
          valueText: '-2%',
          max: 20,
        },
      ],
    }
  },
}
</script>
