import moment from 'moment-timezone'

export default {
  formatDateAndTime (datetime: string) {
    if (!datetime) { return '' }
    return moment(datetime).tz(this.getUserTimeZone()).format('DD.MM.YYYY HH:mm')
  },

  formatDateAndTimeWithSeconds (datetime: string) {
    if (!datetime) { return '' }
    return moment(datetime).tz(this.getUserTimeZone()).format('DD.MM.YYYY HH:mm:ss')
  },

  formatDate (datetime: string): string {
    if (!datetime) { return '' }
    return moment(datetime).tz(this.getUserTimeZone()).format('DD.MM.YYYY')
  },

  getUserTimeZone () {
    // TODO
    // if (App) {
    //   if (App.session) {
    //     if (App.session.userTimeZone) {
    //       return App.session.userTimeZone
    //     }
    //   }
    // }
    if (Intl.DateTimeFormat().resolvedOptions().timeZone) {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    return 'Europe/Helsinki'
  },

  getTimestamp () {
    return new Date().getTime() / 1000
  }
}
