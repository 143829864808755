const isCellOverflowing = (el) => {
  const childEl = el?.querySelector('.cell-div')
  if (!childEl) { return false }
  const result = childEl.scrollHeight > el.scrollHeight || childEl.scrollWidth > el.scrollWidth
  return result
}

const textFieldTypes = ['text', 'string', 'reference', 'has_many_reference', 'richtext', 'polymorphic_autocomplete']

export default {
  resetListScroll () {
    this.scrolledTop = 0
    const el = document.getElementById('vueList')
    if (el) {
      el.scrollTop = 0
    }
  },

  getAreaWidth () {
    const el = document.getElementById('vueList')
    if (el) {
      return el.clientWidth
    }
    return window.innerWidth - 50
  },

  // Set fixed widths for visible list columns
  // and autodetect how many text lanes should be shown for each list item
  // based on first loaded page of content.
  // Content (only few rows) is rendered for measuring just for few ms, which is not visible
  // for the user. Then we measure how much space is needed for the content
  // and set widths and text lanes.
  // And call 'fillTableHorizontallyFully' when needed.
  // Why not use simple table where all this is automatically working?
  // Because of full table scrolling and data advanced preload support.
  adjustCellWidthsAndLineHeight () {
    return new Promise(resolve => {
      this.measuringColumnWidths = true
      const tdWidths = []
      let totalWidth = 0
      const textFieldIndexes = []
      let cellIndex = 0
      let maxHeight = 0
      this.$store.dispatch('setVisibleColumnsByNameWithLeftOffset')
      this.$nextTick(() => {
        this.layoutProfileItems.forEach((field, columnIndex) => {
          if (field.visible) {
            let defaultWidth = 127
            if (textFieldTypes.includes(field.type)) {
              defaultWidth = 150
              textFieldIndexes.push(columnIndex)
            }

            tdWidths[cellIndex] = defaultWidth

            // Go through each measuring row to get field current width (without forcing width)
            this.rowsToMeasureWidthsFrom.forEach(rowNumber => {
              const el = document.getElementById('td_' + rowNumber + '_' + field.name)
              if (el) {
                if (!tdWidths[cellIndex] || el.clientWidth > tdWidths[cellIndex]) {
                  tdWidths[cellIndex] = el.clientWidth +
                    (field.type === 'has_many_reference' ? 25 : 0) // Add space for has-many count indicator
                }
                if (el.childNodes[0].clientHeight > maxHeight &&
                  ['text', 'string', 'richtext', 'reference'].includes(field.type)
                ) {
                  maxHeight = el.childNodes[0].clientHeight
                }
              }
            })
            // Limit to cellMaxWidth
            if (tdWidths[cellIndex] > this.config.cellMaxWidth) {
              tdWidths[cellIndex] = this.config.cellMaxWidth
            }
            // More space for reference (link icon)
            if (field.type === 'reference') {
              tdWidths[cellIndex] += 40
            }

            // Set forced width
            this.$store.commit('updateColumnWidth', {
              columnIndex: columnIndex,
              width: tdWidths[cellIndex]
            })
            totalWidth += tdWidths[cellIndex]
          }
          cellIndex++
        })

        this.setTextLanes(Math.ceil(maxHeight / 17), {})
        this.$store.dispatch('setVisibleColumnsByNameWithLeftOffset')
        this.$store.dispatch('updateCellPool', this.getColumnsToRender())
        this.measuringColumnWidths = false
        this.$nextTick(() => {
          this.$store.dispatch('setListVisibleItems', {})
          const areaWidth = this.getAreaWidth()
          if (totalWidth < areaWidth) {
            this.fillTableHorizontallyFully(totalWidth, textFieldIndexes, areaWidth)
            this.$store.dispatch('setVisibleColumnsByNameWithLeftOffset')
          }
          resolve(true)
        })
      })
    })
  },

  // Update textLanes which defines how many visible text lanes there is
  // for one list item
  setTextLanes (lanes, { byUser }) {
    const maxTextLanes = 100
    // get lanesPerRow from Layout Profile settings
    if (!byUser && this.selectedLayoutProfile.timelineConfiguration &&
      this.selectedLayoutProfile.timelineConfiguration.lanesPerRow
    ) {
      lanes = this.selectedLayoutProfile.timelineConfiguration.lanesPerRow
    }
    lanes = parseInt(lanes)
    lanes = !lanes || lanes < 1
      ? 1
      : lanes > maxTextLanes
        ? maxTextLanes
        : lanes
    this.textLanes = lanes
    this.rowHeight = lanes * 17
    this.setRowHeightCssProperties()
    if (byUser) {
      this.resetListScroll()
    }
    this.$store.dispatch('setListVisibleItems', {})
    if (byUser) {
      this.$store.dispatch('updateLayoutProfileDefaultSort', {
        id: this.selectedLayoutProfileId,
        lanesPerRow: lanes
      })
    }
  },

  // Fills table horizontally fully in case of few visible columns
  // and empty blank white space on the right side of the list
  fillTableHorizontallyFully (totalWidth, textFieldIndexes, areaWidth) {
    if (textFieldIndexes.length >= 3) {
      // Divide space between text columns
      const extraSpacePerColumn = Math.floor((areaWidth - totalWidth) / textFieldIndexes.length)
      textFieldIndexes.forEach(columnIndex => {
        this.$store.commit('updateColumnWidth', {
          columnIndex: columnIndex,
          width: this.layoutProfileItems[columnIndex].forcedWidth + extraSpacePerColumn
        })
      })
    } else {
      // Divide space between all columns, but text type will get double the space
      const columnsCount = this.layoutProfileItems.filter(
        column => column.visible
      ).length
      const extraSpacePerColumn = Math.floor(
        (areaWidth - totalWidth) / (columnsCount + textFieldIndexes.length)
      )
      this.layoutProfileItems.forEach((field, columnIndex) => {
        if (field.visible) {
          const koef = textFieldTypes.includes(field.type) ? 2 : 1 // Double space for text
          this.$store.commit('updateColumnWidth', {
            columnIndex: columnIndex,
            width: parseInt(this.layoutProfileItems[columnIndex].forcedWidth) + extraSpacePerColumn * koef
          })
        }
      })
    }
  },

  setRowHeightCssProperties () {
    document.documentElement.style.setProperty('--row-height', this.rowHeight + 'px')
  },

  setOverflowingTextTimer () {
    clearTimeout(this.resetOverflowingTextTimer)
    this.resetOverflowingTextTimer = setTimeout(() => {
      this.resetOverflowingTextIndicators()
    }, 500)
  },

  // For each visible cell detect if text is overflowing
  // then set "overflowingCells" with cells that must show overflowing
  // text indicator
  resetOverflowingTextIndicators () {
    const overflowingCells = []
    const overflowingHasManyCells = []
    this.rowPool.forEach(id => {
      this.cellPool.forEach(fieldName => {
        const el = document.getElementById('td_' + id + '_' + fieldName)
        const type = this.visibleColumnsByName[fieldName] && this.visibleColumnsByName[fieldName].type
        if (el) { //  && !['image'].includes(type)
          if (isCellOverflowing(el)) {
            // For has-many and file+pdf include into different array
            // For these types total-child-count btn is shown
            // Instead of overflowing text triangle
            if (type === 'has_many_reference' || ['file', 'pdf', 'image'].includes(type)) {
              overflowingHasManyCells.push('td_' + id + '_' + fieldName)
            } else {
              overflowingCells.push('td_' + id + '_' + fieldName)
            }
          }
        }
      })
    })
    this.overflowingCells = overflowingCells
    this.overflowingHasManyCells = overflowingHasManyCells
  }
}
