import UAParser from 'ua-parser-js'
import { Types } from '@/types/AppTypes'
import { LP } from '@/types/LP.types'
const parser = new UAParser(window.navigator.userAgent)
const isLocalhostDomain = window.location.hostname === 'localhost' || window.location.hostname.includes('192.168.')
const isLocalDevelopment = isLocalhostDomain || window.location.hostname.includes('dev.test')

export default {
  locale: 'en',
  availableContentLocales: ['en', 'fi'], // Default values, will be updated later
  availableAppLocales: ['en', 'fi'],
  showMultiLocaleView: false,
  cable: null,
  cableState: '',
  infoChannel: null,
  infoChannelPollInterval: null,
  userInfo: {
    person: {},
  },
  deviceType: parser.getDevice()?.type, // Has value for mobile or tablet
  isMobileOrTablet: ['mobile', 'tablet'].includes(parser.getDevice()?.type),
  showLoginDialog: false,
  translationsByLocale: {} as Record<string, any>,
  showUserPasswordForm: false,
  showReleaseInfo: false,
  showUserLevelSettingsForm: false,
  splitMode: '',
  splitModeFile: {},
  suppressToolbar: false,
  showUserMenuDrawer: false,
  showMenuNavigationDrawer: false,
  documentationByModel: {},
  amByModel: {} as { [key: string]: LP.Item }, // Attribute Metadata by Model
  loaderShowComplete: false,
  supportedDataLoaded: false,
  authenticated: false,
  snackbar: {
    show: false,
    message: '',
    color: 'success',
  },
  modelsRetrievingAttributeMetadataNow: [],
  forbiddenListModels: [],
  processStyles: {},
  showDatePickerMenu: false,
  datePickerDateOnly: false,
  datePickerSelectedDateTime: {
    date: null,
    time: '00:00',
  },
  flashMessages: [],
  splitModeListSize: 50, // %
  linkedAssociations: [],
  userLevelSettings: {}, // TODO - combine with same name getter? Logic was changed in back-end, is this the old way?. used for technical_documentation
  menuItems: [] as Types.MenuItem[],
  settings: [],
  globalFilters: {},
  systemConfigs: {},
  systemInfo: {},
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  config: {
    attachmentsFolder: '/content/attachments/',
    storedReportsDirectory: '/content/stored_reports/',
    cellMaxWidth: 350
  },
  theme: {
    button: {
      round: false,
      depressed: true,
      small: true,
      dark: true
    }
  },
  colors: {
    switch: 'green lighten-1',
    button: 'green darken-3',
    buttonNeutral: 'blue-grey lighten-1',
    input: 'yellow darken-4',
    progressBar: 'yellow darken-4',
    buttonLight: '#9E9E9E',
    buttonVeryLight: '#ddd',
  },
  draggingItemType: null,
  isLocalhostDomain,
  isLocalDevelopment,
  restartMessage: {
    time: null,
    duration: null
  }
}
