
export default {
  name: 'LayoutEditorNotAvailable',

  computed: {
    layoutEditorNotAvailableMessage () {
      if (this.$store.state.splitMode) {
        return this.$i18n.t('aava.messages.exit_split_mode')
      }
      return this.$i18n.t('aava.messages.layout_editor_use_larger_resolution')
    },
  }
}
