
import methods from './../components/methods'

import ItemToolbar from '@/components/Item/Toolbar/ItemToolbar.vue'
import ItemForm from './../components/Item/ItemForm.vue'
import LayoutProfileTabs from './../components/LayoutProfiles/LayoutProfileTabs.vue'

import { createHelpers } from 'vuex-map-fields'
import state from './../store/state'
import ItemMetaActions from '@/components/Item/Toolbar/ItemMetaActions.vue'
import ItemDownloadActions from '@/components/Item/Toolbar/ItemDownloadActions.vue'
import itemMetaActionMethods from '@/methods/item/itemMetaActionMethods'
import ItemHistoryBtn from '@/components/Item/ItemHistoryBtn.vue'
import MobileItemBottomToolbar from '@/components/Item/Toolbar/MobileItemBottomToolbar.vue'
import itemFormMethods from '@/components/Item/itemFormMethods'
import ItemStateLabel from './ItemStateLabel.vue'
import { Types } from '@/types/AppTypes'
import { ItemMeta } from '@/types/LP.types'
import { SaveFormItemProps } from '@/types/Item'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'Item',

  components: {
    ItemStateLabel,
    MobileItemBottomToolbar,
    ItemHistoryBtn,
    ItemDownloadActions,
    ItemMetaActions,
    ItemToolbar,
    LayoutProfileTabs,
    ItemForm,
  },

  // TODO - are all these props here necessary?
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    targetResource: {
      type: String,
      default: '',
    },
    targetId: {
      type: [String, Number],
      default: '',
    },
    targetField: {
      type: String,
      default: '',
    },
    itemAction: {
      type: String,
      default: '',
    },
    resource: {
      type: String,
      required: true,
    },
    modalProps: {
      type: Object,
      default: () => {},
    },
    itemFormPosition: {
      type: String as () => 'first' | 'second' | 'modal',
      default: 'first',
    },
  },

  data () {
    return {
      item: {} as Types.Item,
      itemMeta: null as ItemMeta | null,
      showLoader: true,
      visibleItemLayoutProfileTabsCount: 100,
      mountItemFormComponent: true,
    }
  },

  watch: {
    resource (value, before) {
      if (value) {
        this.loadItemLayoutProfiles().then()
        if (before) {
          this.mountItemFormComponent = false
          this.$nextTick(() => {
            this.mountItemFormComponent = true
          })
        }
      }
    },

    triggerToCalculateVisibleItemLayoutProfileTabs () {
      this.setVisibleItemLayoutProfileTabsCount()
    },

    isMyProfile () {
      // Special case for using Person class for my_profile when user may not have Person class rights
      this.clearItemCurrentData()
      this.loadItemLayoutProfiles().then()
    },
  },

  created () {
    this.loadItemLayoutProfiles().then(() => {
      this.setVisibleItemLayoutProfileTabsCount()
    })
  },

  destroyed () {
    if (this.itemLayoutEditMode) {
      this.suppressToolbar = false
      this.itemLayoutEditMode = false
      this.highlightedFieldName = ''
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    filteredItemLayoutProfileTabs () {
      return this.itemLayoutProfilesByModel?.[this.resource]?.filter(layoutProfile => {
        // Filter out LPs which are to be used as a dialog + user is not the owner (locked)
        // Aava-Vue-572
        return !(layoutProfile.locked && layoutProfile.global_view && layoutProfile.context === 'dialog')
      }) || []
    },

    showLayoutProfileTabsToolbar () {
      return this.$vuetify.breakpoint.mdAndUp && // Do not show on mobile
        this.item && !this.suppressToolbar && this.showTabsOnSeparateToolbar && !this.loadingLayoutProfiles &&
        this.splitMode !== 'horizontal' &&
        !this.modalProps?.layoutProfileContext // In modal dialog if fixed LP ID is set, do not show
    },

    isMobile () {
      return this.$vuetify.breakpoint.xsOnly
    },

    modal () {
      return this.itemFormPosition === 'modal'
    },

    splitProps () {
      return this.$route.params
    },

    showTabsOnSeparateToolbar () {
      return this.showItemTabsOnSeparateToolbar(this.itemLayoutProfilesByModel[this.resource])
    },

    edit () {
      return this.itemAction === 'edit'
    },

    isMyProfile () {
      return this.$route.params.specialAction === 'myProfile'
    },
  },

  methods: {
    ...methods,
    ...itemMetaActionMethods,
    ...itemFormMethods,

    // Detect how many Layout Profile tabs can fit
    setVisibleItemLayoutProfileTabsCount () {
      // Temporarily show all layout profile tabs
      // Reset value in store
      this.visibleItemLayoutProfileTabsCount = 100
      let visibleTabs = 0
      let cumulatedWidth = 0
      const containerEl = this.$refs.tabsContainer?.$el
      if (!containerEl) { return }
      const offset = 200
      const tabsAreaWidth = containerEl.clientWidth - offset
      this.$nextTick(() => {
        // When is rendered, can measure width
        (this.itemLayoutProfilesByModel[this.resource] || []).forEach(profile => {
          const el = document.getElementById('layout_profile_tab_' + profile.id)
          if (el) {
            cumulatedWidth += el.clientWidth
            if (cumulatedWidth < tabsAreaWidth) {
              visibleTabs++
            }
          }
        })
        this.$nextTick(() => {
          this.visibleItemLayoutProfileTabsCount = visibleTabs || 0
        })
      })
    },

    updateShowLoader (value: boolean) {
      this.showLoader = value
      if (!value) {
        // Now item object state label is also present, re-measure how many item LP's can fit
        this.setVisibleItemLayoutProfileTabsCount()
      }
    },

    saveFormItem (props: SaveFormItemProps) {
      return this.$refs.itemForm.saveFormItem(props)
    },

    openEditForm (props) {
      return this.$refs.itemForm.openEditForm(props)
    },

    refreshItem (props) {
      return this.$refs.itemForm.initializeFormItem(props)
    },

    setItemMeta (meta: ItemMeta) {
      this.itemMeta = meta
    },

    loadItemLayoutProfiles () {
      return new Promise(resolve => {
        this.$store.dispatch('loadItemLayoutProfiles', this.resource)
          .then(result => {
            resolve(result)
          })
      })
    },
  },
}
