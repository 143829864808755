<template>
  <div
    v-tooltip.top="tooltip"
    :style="fieldStyle"
    class="item-show-field"
  >
    <div
      :class="'item-show-label ' + (!!displayValue ? ' item-show-label-filled-todo-to-be-decided' : '')"
    >
      <span>{{ label }}</span>
    </div>
    <component
      :is="hasManyItemLink ? 'router-link' : 'div'"
      :to="hasManyItemLink"
      tabindex="-1"
      :class="field.type + '-type item-show-value'"
      v-html="typeof displayValue === 'string'
        ? (displayValue.trim() || '&nbsp;')
        : displayValue"
    />
  </div>
</template>

<script>
import listItemCellMethods from '@/methods/listItem/listItemCellMethods'

export default {
  name: 'ShowViewText',

  props: {
    displayValue: {
      type: [String, Number, Object, Array, Boolean],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    fieldStyle: {
      type: String,
      default: '',
    },
    rawValue: {
      type: [String, Number, Object, Array, Boolean],
      default: '',
    },
    field: {
      type: Object,
      default: () => {},
    },
    hasManyItemLink: {
      type: String,
      default: '',
    },
  },

  computed: {
    tooltip () {
      if (this.field.type !== 'decimal') { return }
      return this.formatFloatValueForLocale(this.rawValue, null, true)
    },
  },

  methods: {
    ...listItemCellMethods,
  },
}
</script>
