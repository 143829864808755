<template>
  <div>
    <div
      v-for="(field, index) in hiddenFieldFilters"
      :key="index"
      :class="'text-xs-center pb-2 float-left ' + (insideMenu ? 'clear-both' : '')"
    >
      {{ $i18n.t($store.state.objectClass + '.attributes.'+ field.name) + ': ' }}
      <v-chip
        color="#bbb"
        text-color="white"
        class="ml-2"
        small
        @click.stop="$store.state.selectedLayoutProfile.locked ? null : listFiltersRemovePermanent(field.name)"
      >
        {{ showListFilterValue(field.name) }}
        <i
          v-if="!$store.state.selectedLayoutProfile.locked"
          class="remove fa fa-times-circle filter-remove-icon"
        />
      </v-chip>
    </div>
  </div>
</template>

<script>
import methods from '@/components/methods'

export default {
  name: 'ListHiddenFiltersList',

  props: {
    hiddenFieldFilters: {
      type: Array,
      default: () => {},
    },
    insideMenu: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    ...methods,

    showListFilterValue (fieldName) {
      if (fieldName === 'main_object_state') {
        return this.$store.getters.listFiltersGetStateDisplayValue
      }
      return this.$store.state.listFilters[fieldName]
    },
  },
}
</script>

<style>
.clear-both {
  clear: both;
}
</style>
