<template>
  <div
    v-if="!loadingList"
    id="list-header-actions-container"
    class="list-header-actions-outer-container"
    :style="listHeaderStyle"
  >
    <div class="list-header-actions-inner-container">
      <template
        v-for="(field, cellIndex) in layoutProfileItems.filter(field => field.visible)"
      >
        <div
          :key="cellIndex"
          :class="'list-action-container action-container-for-' + field.name"
          :style="actionContainerStyle(field)"
        >
          <div
            v-if="listFilters[field.name] || focusedField === field.name"
            class="list-filter-pin"
          >
            <v-hover
              v-if="listFieldsByName[field.name].custom_value"
              v-slot="{ hover }"
            >
              <div
                :class="!hover || locked ? 'list-action-button-pinned' : 'list-action-button-clear'"
                @click.stop="locked ? null : listFiltersRemovePermanent(field.name)"
              >
                <span
                  :class="!hover || locked ? 'fa fa-thumbtack' : 'fa fa-times'"
                />
              </div>
            </v-hover>
            <template v-else>
              <div
                v-if="!locked && focusedField === field.name && (focusedFieldFilterValue || listFilters[field.name])"
                class="list-action-button-pin-add"
                @mousedown="listFiltersAddPermanent(field.name)"
              >
                <span
                  class="fa fa-thumbtack"
                />
              </div>
              <div
                v-if="listFilters[field.name]"
                class="list-action-button-clear"
                @click.stop="listFiltersRemove(field.name)"
              >
                <span
                  class="fa fa-eraser"
                />
              </div>
            </template>
          </div>
          <div
            v-else
            class="list-filter-empty"
          >
            &nbsp;
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

const topOffsetDefault = 37
const topOffsetForHorizontalSplitMode = 42

export default {
  name: 'HeaderColumnSearchActionButtons',

  computed: {
    ...mapFields(Object.keys(state)),

    locked () {
      return this.$store.state.selectedLayoutProfile.locked
    },

    listHeaderStyle () {
      const topOffset =
        this.suppressToolbar
          ? '3px'
          : this.splitMode === 'horizontal'
            ? topOffsetForHorizontalSplitMode + 'px'
            : topOffsetDefault + 'px'
      return {
        top: topOffset,
        left: '0'
      }
    },
  },

  methods: {
    ...methods,

    actionContainerStyle (field) {
      const style = {}
      const fixedWidth = field.userFixedWidth || field.forcedWidth
      if (fixedWidth) {
        style.minWidth = fixedWidth + 'px'
        style.maxWidth = fixedWidth + 'px'
      }
      return style
    },
  },
}
</script>

<style>
.list-header-actions-outer-container {
  z-index: 5;
  position: absolute;
  left: 0px;
  width: var(--header-actions-width);
  overflow: hidden;
}
.list-header-actions-inner-container {
  width: 100000px;
  position: relative;
  left: 0;
}
.list-action-container {
  float: left;
  text-align: right;
  height: 20px;
}
.list-action-button-clear, .list-action-button-pinned,
  .list-action-button-pin-add {
  font-size: 11px;
  position: absolute;
  right: 8px; /* 35 */
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.list-action-button-pin-add {
  right: 30px; /* 57 */
  background: #43A047;
}
.list-action-button-pinned {
  background: #F57C00;
}
.list-action-button-clear {
  background: orange;
  font-size: 11px;
}
.
.list-filter-icons-container {
  float: left;
}
.list-filter-pin {
  position: relative;
  top: 0;
  right: 0px;
  font-size: 12px;
  color: white;
}
.list-filter-empty {
  position: relative;
  top: 0;
  right: 0px;
  font-size: 12px;
  color: white;
}
</style>
