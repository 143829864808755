var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.chart)?_c('v-container',{ref:"chartBlock",staticClass:"chart-block elevation-0",style:('background: ' + _vm.chart.background),attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{style:('color: ' + _vm.chart.titleColor)},[_vm._v(" "+_vm._s(_vm.title || _vm.chart.title)+" ")])]),(_vm.chart.chartInfo.type)?_c('v-col',{style:(_vm.containerStyle)},[(_vm.chart.chartInfo.type === 'custom-bars')?_c('CustomBars',{staticClass:"kpi-block-number"}):(_vm.chart.chartInfo.type === 'multi-gauge')?_c('MultiGauge',{staticClass:"kpi-block-number"}):(_vm.chart.chartInfo.type === 'multi-gauge-wide')?_c('MultiGaugeWide',{staticClass:"kpi-block-number"}):(_vm.chart.chartInfo.type === '2-weeks')?_c('WeeksChart',{staticClass:"kpi-block-number"}):(_vm.chart.chartInfo.type === 'people')?_c('PeopleChart',{staticClass:"kpi-block-number"}):(_vm.chart.chartInfo.type === 'chart.js')?_c('DashboardChart',{attrs:{"space-allocation":{
          box_height: (_vm.clientHeight - 60),
          box_width: (_vm.clientWidth - 40),
        },"chart-data":{
          data: _vm.chart.chartInfo.chartData,
          options: _vm.chart.chartInfo.chartOptions,
          type: _vm.chart.chartInfo.chartType,
        }}}):_c('div',{staticClass:"kpi-block-number",staticStyle:{"font-size":"30px","color":"grey"}},[_c('svg-icon',{style:('opacity: 0.45;transform: rotate(' + (_vm.chart.chartInfo.direction === 'up' ? 225 : 315) + 'deg);'),attrs:{"type":"mdi","path":_vm.path,"size":48,"color":_vm.chart.chartInfo.direction === 'up' ? 'green' : 'red'}}),_vm._v(" "+_vm._s(_vm.chart.chartInfo.value)+" ")],1)],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }