

/*
* TODO - comment
* */

import api from './../store/api'
import appMethods from '@/methods/list/appMethods'
import infoChannelMethods from '@/methods/list/infoChannelMethods'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'LoginDialog',

  data () {
    return {
      showError: false,
      loading: false,
      dialog: true,
      username: '',
      password: '',
      tfToken: '',
      enteringTFToken: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    ...appMethods,
    ...infoChannelMethods,

    login () {
      this.loading = true
      const credentials: any = {
        name: this.username,
        password: this.password,
      }
      if (this.tfToken) {
        credentials.tfToken = this.tfToken
      }
      api.sendLoginRequest(credentials).then((response: AxiosResponse) => {
        this.loading = false
        if (response.data.status === 'tw_token_needed') {
          // Two Factor Token required
          this.enteringTFToken = true
        } else if (response.data.status === 'ok') {
          // Login ok > reload
          this.$store.state.showLoginDialog = false
          if (this.$route.params.itemAction === 'edit') {
            this.$store.dispatch('addFlashMessage', {
              message: this.$i18n.t('aava.messages.form_can_not_be_saved_after_re_login'),
              type: 'warning',
              expires: 5000,
            })
            setTimeout(() => {
              location.reload()
            }, 3000)
          } else {
            // For now always have to reload
            // Ideally user would be able to save item again, but token is no longer valid, after new session
            location.reload()
          }
        } else {
          this.showError = true
          setTimeout(() => {
            this.showError = false
          }, 1500)
          const message = response.data.status === 'error' ? this.$i18n.t('aava.login.messages.invalid_credentials') : response.data.status
          // this.$store.dispatch('addFlashMessage', {
          //   message,
          //   type: 'error',
          //   expires: 2000,
          // })
        }
      })
    },
  },
}
