
import currencySymbols from './../../utilities/currencySymbols'
import itemFieldMethods from './../../methods/item/itemFieldMethods'
import pixelWidth from 'string-pixel-width'
import listItemCellMethods from '@/methods/listItem/listItemCellMethods'
import listCellEditMethods from '@/methods/listItem/listCellEditMethods'
import { BaseItem, Types } from '@/types/AppTypes'
import { LP, LPI } from '@/types/LP.types'
const inflect = require('i')()

export default {
  name: 'TextField',

  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    view: {
      type: String,
      default: 'item',
    },
    label: {
      type: String,
      default: '',
    },
    field: {
      type: Object as () => LP.Item,
      required: true,
    },
    item: {
      type: Object as () => Types.Item,
      default: () => {},
    },
    resource: {
      type: String,
      default: null,
    },
    layoutEditMode: {
      type: Boolean,
      default: false,
    },
    hasManyParentField: {
      type: Object as () => LP.Item,
      default: () => {},
    },
    isHasManyFieldAndNotFirstRow: {
      type: Boolean,
      default: false,
    },
    isHasManyField: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: Object,
      default: () => {},
    },
    fieldLocale: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    appendIconFunction: {
      type: Function,
      default: () => {},
    },
    showItemPickerFor: {
      type: Function,
      default: () => {},
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      changeTimer: null,
      stringOptionPickerIsOpen: false,
      skipFocusEventListener: false,
      skipKeyUpAfterCutCopyPaste: false,
    }
  },

  computed: {
    text: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        // For dynamic attributes show as show view value
        // Ex price or quantity has many decimal places but should be rounded
        if (this.readOnly && this.field.dynamic) {
          return this.getItemFieldDisplayText({
            inputItem: this.item,
            inputField: this.field,
            withUnit: false,
          })
        }
        return this.value
      },
    },

    classes () {
      const classes = [
        ('data-' + this.field.name),
        (this.field.mandatory ? (this.value ? ' required-filled ' : ' required-missing ') : ''),
      ]
      if (this.unitDisplayName) {
        const pxWidth = pixelWidth(this.unitDisplayName, { size: 14 })
        let pxWidthCeil = Math.ceil(pxWidth / 10) * 10
        if (pxWidthCeil > 60) { pxWidthCeil = 60 }
        classes.push('qty-input-minus-unit-for-' + pxWidthCeil + '-px')
      }
      return classes
    },

    unitIsHidden () {
      // For now only supported in has-many
      return this.field.unit_presentation === 'hidden' ||
        (this.field.unit_presentation === 'fixed' && this.isHasManyField)
    },

    showUnitDisplayName () {
      return ['quantity', 'price', 'percentage'].includes(this.field.type) && !this.unitIsHidden
    },

    unitPickerIsDisabled () {
      return this.field.unit_presentation === 'fixed' || this.readOnly
    },

    unitDisplayName () {
      if (this.field.type === 'percentage') {
        return '%'
      }
      const identifierOrName = this.unit?.name || this.unit?.identifier ||
        (this.showUnitDisplayName ? '-' : '')
      // Replace when is currency
      return currencySymbols[identifierOrName] || identifierOrName
    },
  },

  methods: {
    ...itemFieldMethods,
    ...listItemCellMethods,
    ...listCellEditMethods,

    keydownHandler (e: KeyboardEvent) {
      // Skip for string_search type. Possibly refactor in some other place or with ".stop" like in ReferenceField?
      if (e.key === 'ArrowDown') {
        if (this.showStringOptionPicker(e)) {
          // Arrow down not supported to move to the row down
          return
        }
      } else if (e.key === 'ArrowUp' && this.stringOptionPickerIsOpen) {
        // Disable going to previous row (in has many for example) when picker is open
        return
      } else if (e.key === 'Enter' && this.stringOptionPickerIsOpen) {
        // Do not add new has many row
        return
      }
      // Special case for Tab to pass for the parent
      // Can't capture on @keyup
      if ([
        'Tab', // Move to next editable item in list
        'ArrowDown', // Move down on key down for quicker UI
        'ArrowUp', // Move up on key down for quicker UI
        // For string_search field good to pass on key-down, as can prevent default.
        // On key-up may be too late if item-picker is opened for example
        'Enter',
      ].includes(e.code)) {
        this.$emit('keyPress', e)
      }
    },

    keyUpHandler (e: KeyboardEvent) {
      // String search picker already open, update search term and do not proceed
      if (this.stringOptionPickerIsOpen) {
        this.$store.state.itemPickerSearchTerm = this.text
        return
      }
      if (this.skipKeyUpAfterCutCopyPaste) { return }
      // Send key press to the parent, except for special cases in list edit view
      if (![
        'Tab',
        'ArrowDown',
        'ArrowUp',
        'Enter',
      ].includes(e.code)) { // Do not send double Tab
        this.$emit('keyPress', e)
      }
      // Ignore with Tab key, user moved to another field
      // No need to trigger possible defaults-for-change.
      // If indeed this was needed, then timer for changeListener has previously
      // been started with non-Tab key
      // Emitting keyPress is still needed, as this may change focused has-many row index (for copy_nested feature)
      // Ignore also arrow keys etc
      // console.log(e.which)
      // TODO might have issues still in the future - monitor
      // triggering change event when no actual change was done? Monitor
      // Issue is that it can cause defaults-for-change requests
      // Update JUN-2023: Removed shiftKey check, as this actually does change input value
      if (e.metaKey || e.ctrlKey || e.altKey) { return } // e.shiftKey
      // When keys pressed which do not change input value, ignore
      // TODO which is deprecated. Is it safe to replace with:
      // if ([
      //   'Tab',
      //   'Control',
      //   'Enter',
      //   'Shift',
      //   'Alt',
      //   'Control',
      //   'ArrowLeft',
      //   'ArrowUp',
      //   'ArrowRight',
      //   'ArrowDown',
      //   'Meta',
      //   'Escape'
      // ].includes(e.key)) {
      //   return
      // }
      if ([
        9, // Tab
        12, // Control
        13, // Enter
        16, // Shift
        18, // Alt
        17, // Ctrl
        37, // Arrow Left
        38, // Arrow Up
        39, // Arrow Right
        40, // Arrow Down
        91, // Meta
        27, // Esc
      ].includes(e.which)) { return }
      this.$emit('changeListener')
      // For the string search item picker, change search value
      setTimeout(() => {
        this.showStringOptionPicker(e)
      }, 100) // Give time for reference field option pick action and destroy
    },

    // Handle copy-paste action separately, as changeListener does not take care of it
    // depending on which key to release first, it may or may not trigger change listener to the parent
    onPaste (e: ClipboardEvent) {
      this.skipNextKeyUp()
      this.$emit('changeListener', 0)
      // For the string search item picker, change search value
      setTimeout(() => {
        this.showStringOptionPicker(e)
      }, 100) // Give time for reference field option pick action and destroy
    },

    // When user releases letter key after Ctrl key, no way to detect this in handleKeyUp
    // So temporarily we disable key up listening like this.
    // Tested @input as well, but it does not return $event
    skipNextKeyUp () {
      this.skipKeyUpAfterCutCopyPaste = true
      setTimeout(() => {
        this.skipKeyUpAfterCutCopyPaste = false
      }, 400)
    },

    onCut () {
      this.skipNextKeyUp()
    },

    onCopy () {
      this.skipNextKeyUp()
    },

    focus (e: FocusEvent) {
      // Avoid focus event actions when programmatically focusing
      // unfortunately event information does not give an option to detect this
      if (this.skipFocusEventListener) { return }
      this.autoSelectTextOnFocus(e)
      setTimeout(() => {
        this.$store.dispatch('closeItemPicker')
        this.showStringOptionPicker(e)
      }, 100) // Give time for reference field option pick action and destroy
    },

    showStringOptionPicker (e: KeyboardEvent | ClipboardEvent) {
      const forId = (this.item && (this.item.token || this.item.id)) || null
      if (this.field.type !== 'search_string' ||
        !forId || // // Item not loaded yet
        !this.$refs?.inputRef?.$el // Reference field lost, user moved away from the input?
      ) {
        return
      }
      this.$store.state.itemPickerSearchTerm = this.text
      this.stringOptionPickerIsOpen = true
      this.showItemPickerFor(e, {
        objectClass: this.resource,
        forObjectClass: (this.hasManyParentField && this.hasManyParentField.name) || this.resource,
        forField: this.field,
        forFieldName: this.field.name,
        forId,
        selectColumns: this.field.select_columns || [],
        selectCallback: this.selectStringOption,
        closeCallback: () => {
          this.setOptionPickerPropClosed()
        },
        attachEl: this.$refs.inputRef.$el,
        useAttachElMinWidth: true,
        forItem: this.item,
      })
      return true
    },

    setOptionPickerPropClosed () {
      // Delay needed as some evens happen in keyup, some in keydown
      // Ex in has-many Enter to block adding new row
      setTimeout(() => {
        this.stringOptionPickerIsOpen = false
      }, 500)
    },

    selectStringOption (option: string | BaseItem) {
      this.setOptionPickerPropClosed()
      this.text = typeof option === 'object' ? (option.summary ?? option) : option
      this.$emit('changeListener', 0)
      // Set focus back to the input, when selected with the mouse
      this.skipFocusEventListener = true
      this.$refs.inputRef.focus()
      this.$nextTick(() => {
        this.skipFocusEventListener = false
      })
    },

    unitClickHandler (e) {
      if (this.unitPickerIsDisabled) {
        return
      }
      setTimeout(() => {
        if (!['quantity', 'price'].includes(this.field.type)) {
          return
        }
        const unitPrefix = this.field.type === 'quantity' ? 'unit' : 'currency'
        this.$store.state.itemPickerSearchTerm = ''
        this.showItemPickerFor(e, {
          objectClass: inflect.pluralize(unitPrefix),
          forObjectClass: this.resource,
          forField: this.field,
          forFieldName: this.field.name + '_' + unitPrefix,
          forId: (this.item && this.item.id) || null,
          selectColumns: ['summary', this.field.type === 'quantity' ? 'name' : 'identifier'],
          selectCallback: this.selectUnitOption,
        })
      }, 200) // Give time for focus to close potential previous item picker
    },

    selectUnitOption (unit: BaseItem) {
      const unitFieldName = this.field.name + '_' +
        (this.field.type === 'quantity'
          ? 'unit'
          : 'currency')
      this.$set(this.item, unitFieldName, unit)
      // For list edit unit/currency save
      if (this.view === 'list') {
        this.addSaveJobToQueue({
          item: this.item,
          field: {
            name: unitFieldName,
          } as LPI,
        })
      }
    },
  },
}
