import Vue from 'vue'
import api from './../../store/api'
import listFilters from './../../store/_listFilters'
import util from '../../utilities/sharedUtilities'
import clearCache from '@/utilities/clearCache'

export default {
  updateItemLayoutProfileItem: ({ state }, field) => {
    const payload: any = {
      '@class': 'LayoutProfileItem',
      id: field.id,
      visible: field.visible ? 'true' : 'false',
      has_many_layout: field.has_many_layout,
    }
    if (field.layoutContainerFieldSet?.id) {
      payload.layout_container_field_set = {
        '@class': 'LayoutContainerFieldSet',
        id: field.layoutContainerFieldSet.id,
      }
    }
    return api.saveItem('layout_profile_items', payload, [], {})
  },

  updateLayoutContainerFieldSetsForFields: ({ state }, { itemLayoutProfileId, fields }) => {
    return api.updateLayoutContainerFieldSetsForFields(itemLayoutProfileId, fields)
  },

  saveListPermanentFilter: ({ state, dispatch }, fieldName) => {
    // Set filter value if user clicked pin icon
    // otherwise it's done by enter key press
    if (fieldName !== 'main_object_state' && state.focusedFieldFilterValue) {
      dispatch('setListFilter', { fieldName, value: state.focusedFieldFilterValue })
    }
    if (fieldName && state.listFilters[fieldName]) {
      Vue.set(state.listFieldsByName[fieldName], 'custom_value',
        state.listFilters[fieldName])
      return api.saveListPermanentFilter(
        state.listFieldsByName[fieldName].id,
        state.listFilters[fieldName]
      )
    } else {
      return dispatch('emptyPromise')
    }
  },

  removeListPermanentFilter: ({ state, dispatch }, fieldName) => {
    if (fieldName) {
      Vue.set(state.listFilters, fieldName, '')
      Vue.set(state.listFieldsByName[fieldName], 'custom_value', '')
      listFilters.setLocalStorageFilter(state.selectedLayoutProfileId, fieldName, '')
      return api.saveListPermanentFilter(
        state.listFieldsByName[fieldName].id,
        ''
      )
    } else {
      return dispatch('emptyPromise')
    }
  },

  saveListFieldVisibility: ({ state, dispatch }, payload) => {
    clearCache('lp_data_' + state.selectedLayoutProfileId)
    if (payload.fieldName) {
      Vue.set(state.listFieldsByName[payload.fieldName],
        'visible',
        payload.visible)
      dispatch('updateLayoutProfileItemsByName')
      return api.saveListFieldVisibility(
        state.listFieldsByName[payload.fieldName].id,
        payload.visible
      )
    } else {
      return dispatch('emptyPromise')
    }
  },

  storePermanentFilterValues: ({ state, commit }, layoutProfileItems) => {
    // Reset current filters and get filters from local storage
    state.listFilters = listFilters.getLocalStorageFilters(state.selectedLayoutProfileId)
    // Add permanent filter values
    layoutProfileItems.forEach(field => {
      if (field.custom_value) {
        commit('setFilterField', {
          filterField: field.name,
          filterValue: field.custom_value
        })
      }
    })
  },

  resetListColumnWidths: ({ state, dispatch }) => {
    state.layoutProfileItems.forEach(layoutProfileItem => {
      Vue.set(layoutProfileItem, 'userFixedWidth', null)
    })
    const layoutProfileIndex = util.findLayoutProfileIndexById(state, state.selectedLayoutProfileId)
    Vue.set(state.layoutProfiles[layoutProfileIndex].timelineConfiguration, 'userFixedWidths', {})
    dispatch('saveLayoutProfileData', state.layoutProfiles[layoutProfileIndex])
  },

  setListFieldUserFixedWidth: ({ state }, { fieldName, width }) => {
    const index = state.layoutProfileItems.findIndex(field => field.name === fieldName)
    if (index === -1) { // For some reason attribute (deleted?) may not be found
      return
    }
    Vue.set(state.layoutProfileItems[index], 'userFixedWidth', width)

    // Save also in Layout Profile configuration
    // this is then (when user releases mouse button) saved over api
    // and can be retrieved next time user opens Layout Profile
    const layoutProfileIndex = util.findLayoutProfileIndexById(state, state.selectedLayoutProfileId)
    if (!state.layoutProfiles[layoutProfileIndex].timelineConfiguration.userFixedWidths) {
      Vue.set(state.layoutProfiles[layoutProfileIndex].timelineConfiguration, 'userFixedWidths', {})
    }
    Vue.set(state.layoutProfiles[layoutProfileIndex].timelineConfiguration.userFixedWidths, fieldName, width)
  },

  // Get user fixed column widths from Layout Profile config and apply to Items
  storeUserFixedColumnWidths: ({ state, dispatch }) => {
    const layoutProfileIndex = util.findLayoutProfileIndexById(state, state.selectedLayoutProfileId)
    const layoutProfile = state.layoutProfiles[layoutProfileIndex]
    const userFixedWidths = layoutProfile?.timelineConfiguration?.userFixedWidths || []
    Object.keys(userFixedWidths).forEach(fieldName => {
      dispatch('setListFieldUserFixedWidth', {
        fieldName,
        width: userFixedWidths[fieldName]
      })
    })
  },

  swapListColumnsPosition: ({ state }, { fieldName1, fieldName2 }) => {
    const index1 = state.layoutProfileItems.findIndex(field => field.name === fieldName1)
    const index2 = state.layoutProfileItems.findIndex(field => field.name === fieldName2)
    const tempField = state.layoutProfileItems[index1]
    Vue.set(state.layoutProfileItems, index1, state.layoutProfileItems[index2])
    Vue.set(state.layoutProfileItems, index2, tempField)

    // Swap also cellPool to trigger re-render
    const rowPoolIndex = state.cellPool.indexOf(fieldName2)
    Vue.set(state.cellPool, state.cellPool.indexOf(fieldName1), fieldName2)
    Vue.set(state.cellPool, rowPoolIndex, fieldName1)
  },
}
