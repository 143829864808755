<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size / 2"
    viewBox="0 0 3600 2000"
    preserveAspectRatio="xMidYMid meet"
    class="aava-loader"
  >
    <g
      id="layer102"
      fill="grey"
      stroke="none"
    >
      <!-- path class="logo-svg-lin3e" d="
       M200 974
       c0 -4 93 -178 207 -386
         163 -301 209 -377 218 -366
         9 10 338 608 404 736
       l12 22 -108 0
         -108 0 -95 -176
       c-52 -97 -99 -182 -105 -190
         -7 -10 -36 34 -112 174
       l-101 187 -106 3
       c-58 1 -106 0 -106 -4z"
     />
      <path class="logo-svg-lin3e" d="M1267 872 c220 -409 359 -661 364 -662 6 0 419 752 419 763 0 4 -47 7 -104 7 l-104 0 -105 -191 -105 -191 -70 129 c-39 70 -85 156 -104 190 l-33 63 -108 0 -108 0 58 -108z"/ -->
      <!-- path class="logo-svg-lin3e svg-line-3" d="M407 1662 c-114 -208 -207 -382 -207 -386 0 -3 48 -5 106 -4 l106 3 101 187 c76 140 105 184 112 174 6 -8 53 -93 105 -190 l95 -176 108 0 108 0 -58 108 c-220 409 -359 661 -364 662 -3 0 -98 -170 -212 -378z"/>
     <path class="logo-svg-lin3e svg-line-4" d="M1213 2023 c2 -5 93 -172 202 -373 109 -201 203 -371 210 -378 9 -10 57 72 218 367 114 209 207 382 207 385 0 4 -48 5 -106 4 l-106 -3 -103 -188 -103 -188 -103 190 -104 191 -108 0 c-60 0 -106 -3 -104 -7z"/ -->

      <!-- path
        d="M200 970
              L620 225
            L1040 970
              L1460 225
              L1880 970
              L1680 970
              L1460 605
            L1040 1370
              L620 605
            L400 970
              L200 970
            z
            "
        class="logo-svg-line"
     / -->
      <path
        d="M0 1000
        L500 200
          L1000 1000
        L1500 200
          L2000 1000
            L2300 1480
          L2600 1000
        L3100 200
          L3600 1000
        L3400 1000
      L3100 520
        L2300 1800
      L1500 520
        L1000 1320
      L500 520
      L200 1000z"
        class="logo-svg-line"
      />
    </g>
  </svg>
</template>

<script>
const defaultColor = '#eee' // #ffe600

export default {
  name: 'AavaLoader',

  props: {
    color: {
      type: String,
      default: defaultColor,
    },
    size: {
      type: Number,
      default: 300,
    }
  },

  watch: {
    color () {
      this.setColor()
    }
  },

  created () {
    this.setColor()
  },

  methods: {
    setColor () {
      document.documentElement.style.setProperty('--loader-color', this.color || defaultColor)
    }
  },
}
</script>

<style>
:root {
  --loader-color: #ddd;
}
.logo-svg-line {
  stroke: var(--loader-color) !important;
  stroke-width: 20;
  fill: #FAFAFA;
  stroke-dasharray: 2000;
  stroke-dashoffset: 60000;
  animation: dash 90s linear infinite;
}
.svg-line-2 {
  animation-delay: 2s;
}
.svg-line-3 {
  animation-delay: 4s;
}
.svg-line-4 {
  animation-delay: 6s;
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
    fill: #FAFAFA;
  }
}
</style>
