import util from '../../utilities/sharedUtilities'
import Vue from 'vue'

export default {
  // Select Layout Profile
  selectLayoutProfile (layoutProfile) {
    if (this.selectedLayoutProfileId === layoutProfile.id) { return }
    this.$store.dispatch('setSelectedLayoutProfileData', layoutProfile)
    this.$store.dispatch('getLayoutProfileItemCount', layoutProfile.id)
    // Layout Profile Id is watched and this triggers Layout Profile List load and render
  },

  // Select Item Layout Profile
  selectItemLayoutProfile (layoutProfile) {
    Vue.set(this.selectedLayoutProfileIdByModel, layoutProfile.controller, layoutProfile.id)
  },

  // Select Item Layout Profile
  selectItemLayoutProfileWhenReady (layoutProfile) {
    if (this.selectedLayoutProfileIdByModel[layoutProfile.controller] === layoutProfile.id) {
      return
    }
    Vue.set(this.waitingItemLayoutProfileChangeByModel, layoutProfile.controller, layoutProfile.id)
  },

  // Detect how many Layout Profile tabs can fit
  setVisibleLayoutProfileTabsCount () {
    // Temporarily show all layout profile tabs
    // Reset value in store
    this.triggerToCalculateVisibleItemLayoutProfileTabs++
    this.visibleLayoutProfileTabsCount = 100
    let visibleTabs = null
    let cumulatedWidth = 0
    const containerEl = document.getElementsByClassName('list-layout-profiles-outer-container')?.[0]
    const offset = 200
    if (!containerEl) { return }
    const tabsAreaWidth = containerEl.clientWidth - offset
    this.$nextTick(() => {
      // When is rendered, can measure width
      this.layoutProfiles.forEach(profile => {
        const el = document.getElementById('layout_profile_tab_' + profile.id)
        if (el) {
          cumulatedWidth += el.clientWidth
          if (cumulatedWidth < tabsAreaWidth) {
            visibleTabs++
          }
        }
      })
      // When tabs not on separate toolbar, show all
      if (!this.$store.getters.showListTabsOnSeparateToolbar) {
        visibleTabs = this.layoutProfiles.length
      }
      // Set value in store
      this.$nextTick(() => {
        this.visibleLayoutProfileTabsCount = visibleTabs || 0
      })
    })
  },
}
