import date_time_helper from '@/methods/date_time_helper'
import decimal from '@/methods/decimal'
import shortcutData from './shortcutData'
import currencySymbols from '@/utilities/currencySymbols'
import api from '@/store/api/'
import sharedUtilities from '@/utilities/sharedUtilities'
import dashboardConfig from '@/components/Dashboard/dashboardConfig'
import i18n from '@/locale/i18n'

const getFieldUnitValue = (item, column_type, column_name) => {
  if (column_type === 'price') {
    const currency_identifier = item[column_name + '_currency'] && item[column_name + '_currency'].identifier
    return currencySymbols[currency_identifier] || currency_identifier || ''
  } else if (column_type === 'quantity') {
    return (item[column_name + '_unit'] && item[column_name + '_unit'].name) || ''
  }
  return ''
}

/*
 * Prepares one object field value to be shown to the user
 * Adds formatting per type
 * returns String
 */
const getOneObjectFieldDisplayValue = (item, column_info) => {
  let display_value = ''

  // Check if is boolean (true / false)
  if (typeof item[column_info.name] === 'boolean') {
    if (item[column_info.name]) {
      display_value = i18n.t('aava.general.yes')
    } else {
      display_value = i18n.t('aava.general.no')
    }
  }

  // Check if field is an Object (summary?)
  else if (sharedUtilities.isObject(item[column_info.name])) {
    display_value = item[column_info.name].summary
  }

  // Check if field is DateTime
  else if (column_info.type === 'datetime') {
    if (!sharedUtilities.isEmptyString(item[column_info.name])) {
      display_value = date_time_helper.formatDateAndTime(item[column_info.name])
    } else {
      display_value = ''
    }
  }

  // Check if field is Date
  else if (column_info.type === 'date') {
    if (!sharedUtilities.isEmptyString(item[column_info.name])) {
      display_value = date_time_helper.formatDate(item[column_info.name])
    } else {
      display_value = ''
    }
  }

  // Number
  else if (sharedUtilities.isDecimalType(column_info.type)) {
    if (item[column_info.name] !== '') {
      const decimal_value = decimal.parse(item[column_info.name])
      display_value = (
        decimal.format(decimal_value, { precision: column_info.precision }) +
        ' ' +
        getFieldUnitValue(item, column_info.type, column_info.name)
      )
    } else {
      display_value = ''
    }
  }

  // Regular text field
  else {
    display_value = item[column_info.name]
  }

  return sharedUtilities.removeHTMLTagsFromString(display_value)
}

export default {
  /*
   * Completes DashboardItems with additional information like data types, adds column type
   * and translations
   * returns Promise
   */

  completeShortcutsData (dashboardItems) {
    return dashboardItems
      .filter(item => item.element_type === 'link')
      .map(dashboardItem => {
        if (dashboardItem.element_type === 'link') {
          dashboardItem.details = shortcutData.parseShortcutParts(dashboardItem.element_action)
          dashboardItem.link_url = shortcutData.genShortcutLink(dashboardItem.element_action)
          dashboardItem.link_title = shortcutData.generateShortcutTitle(dashboardItem.element_action)
        }
        return dashboardItem
      })
  },

  /*
   * Collects columns attribute metadata from App.amc
   * returns Promise which includes metadata object for each object type
   */
  getCacheForObjectTypes (dashboardItems) {
    return new Promise(
      (resolve) => {
        const objects_metadata = {}
        const promises = dashboardItems.filter(dashboardItem => dashboardItem.previewData.items).forEach(dashboardItem => {
          const object_name = sharedUtilities.objectClassUnderscoredNameSingular(dashboardItem.object_type)
          this.$store.dispatch('getAttributeMetadata', object_name).then(cache => {
            objects_metadata[dashboardItem.object_type] = cache
          })
        })
        Promise.all(promises).then(() => {
          resolve(objects_metadata)
        })
      })
  },

  /*
   * Prepares one Preview List for showing to the user
   * returns Object
   */
  completeOnePreviewListData (dashboardItem, objects_metadata) {
    const completed_preview = dashboardItem

    // Prepare data for easy use in Vue Dashboard component
    dashboardItem.previewData.items.forEach((item, i) => {
      for (let col_i = 0; col_i < dashboardConfig.DASHBOARD_OBJECT_COLUMNS; col_i++) {
        const column_name = dashboardItem.previewData.column_order[col_i]
        const object_metadata = (objects_metadata && objects_metadata[column_name]) || {}
        const column_info = {
          name: column_name,
          type: object_metadata.attribute_type || 'string',
          precision: object_metadata.precision || null
        }

        // Get one field display value
        completed_preview.previewData.items[i]['col' + col_i + '_show'] = (
          getOneObjectFieldDisplayValue(item, column_info)
        )
        completed_preview.previewData.items[i]['col' + col_i + '_type'] = column_info.type
      }
      completed_preview.previewData.items[i].updated_at_show = (
        sharedUtilities.userFriendlyLastModifiedTime(item.updated_at)
      )
    })
    return completed_preview
  },
}
