import axios, { AxiosError, AxiosResponse } from 'axios'
import { networkErrorAxiosResponse } from '@/store/api/apiRequests'

export default {
  getUniqueValues (objectClass: string, fieldName: string) {
    return this.sendRequest('/api/' + objectClass + '/values/' + fieldName, [], [])
  },

  deleteBulk (objectClass: string, ids: (number | string)[]): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      const formData = new URLSearchParams()
      ids.forEach((id) => {
        formData.append('ids_and_tokens[]', id.toString())
      })
      axios.delete('/api/' + objectClass + '?~confirmations=required',
        {
          params: formData,
          ...this.requestOptions(),
        }
      )
        .then((response) => {
          resolve(response)
        }).catch((err: AxiosError) => {
          resolve(err.response! || networkErrorAxiosResponse(err))
        })
    })
  }
}
