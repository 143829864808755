
import api from './../../store/api'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import TextArea from '../Form/TextArea.vue'
import { AxiosResponse } from 'axios'
import { Types } from '@/types/AppTypes'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'UserLevelSettings',
  components: { TextArea },
  data () {
    return {
      saving: false,
      disabled: false,
      settingsByKey: {
        models_to_skip_reload_list_on_return: '',
      },
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    userProfile () {
      // Assume here that user has a UserProfile already
      return this.userInfo.user_profiles?.[0]
    },
  },

  created () {
    this.setData()
  },

  methods: {
    saveUserLevelSettings () {
      const promises: any = []
      this.saving = true
      Object.keys(this.settingsByKey).forEach(key => {
        // May, or may not be existing setting
        const setting = this.$store.getters.userLevelSettings[key]
        const payload: Types.Item = {
          profile: {
            '@class': 'UserProfile',
            id: this.userProfile.id,
          },
          setting_type: null,
          key,
          value: this.settingsByKey[key],
        }
        if (setting) {
          payload.id = setting.id
        }
        promises.push(this.saveOne(payload))
      })
      let errors = false
      Promise.all(promises).then(responses => {
        responses.forEach(saveSuccessful => {
          if (!saveSuccessful) {
            errors = true
          }
        })
        if (!errors) {
          this.$store.dispatch('showMessage', {
            message: 'OK',
            type: 'success',
            expires: 1000,
          })
          this.showUserLevelSettingsForm = false
          location.reload()
        }
        setTimeout(() => {
          this.saving = false
        }, 500)
      })
    },

    saveOne (payload) {
      return new Promise(resolve => {
        api.saveItem('user_settings', payload).then((response: AxiosResponse | null) => {
          this.$store.dispatch('globalErrorDisplay', { response, context: 'Save user-setting' }).then(errorsFound => {
            resolve(!errorsFound)
          })
        })
      })
    },

    setData () {
      Object.keys(this.settingsByKey).forEach(key => {
        this.settingsByKey[key] = this.$store.getters.userLevelSettings?.[key].value || ''
      })
    },
  },
}
