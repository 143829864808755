
import moment from 'moment'
import CompatibilityViewReportFilter from '@/components/App/CompatibilityViewReportFilter.vue'
import api from '@/store/api'
import { AxiosResponse } from 'axios'

export default {
  name: 'CompatibilityViewReports',

  components: {
    CompatibilityViewReportFilter,
  },

  props: {
    reportProps: {
      type: Object,
      default: () => {},
    },
    modelName: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      loadingReportById: {},
      content: '',
    }
  },

  methods: {
    reportFiltersAreFilled (report) {
      const filledCount = report.availableFilters.filter(availableFilterName => {
        return !!report.filterValues[availableFilterName]
      }).length
      return filledCount === report.availableFilters.length || (report.acceptAnyOneFilter && filledCount > 0)
    },

    openReport (report) {
      this.$set(this.loadingReportById, report.id, true)
      const url = '/compatibility/' + this.modelName
      const payload = {
        [report.filterObject]: {
          id: report.id,
        },
      }
      Object.keys(report.filterValues).forEach(filterName => {
        payload[report.filterObject][filterName] = report.filterValues[filterName]
      })

      api.sendPostRequestFormData(url, payload).then((response: AxiosResponse) => {
        this.$set(this.loadingReportById, report.id, false)
        if (response.data.base64pdf) {
          const filename = report.title + ' ' + moment().format('DD.MM.YYYY HH.mm.ss') + '.pdf'
          const link = document.createElement('a')
          link.setAttribute('href', 'data:application/pdf;base64,' + response.data.base64pdf)
          link.setAttribute('download', filename)
          const event = new MouseEvent('click')
          link.dispatchEvent(event)
        } else {
          this.$store.dispatch('showMessage', {
            message: response.data.error || 'Error',
            type: 'error'
          })
        }
      })
    },
  },
}
