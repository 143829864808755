import decimal from '@/methods/decimal'

const XLSX = require('xlsx')
export default {
  createExcelFile (opts) {
    const className = this.$i18n.t(opts.objectClass + '.list.title')
    const wb = XLSX.utils.book_new()
    wb.Props = {
      Title: this.$i18n.t('aava.index.breakdown.title'),
      Subject: className,
      Comments: opts.comments.join('\n')
    }
    wb.SheetNames.push(className)
    const ws_data = this.createExcelData(opts)
    const ws = XLSX.utils.aoa_to_sheet(ws_data)
    wb.Sheets[className] = ws

    XLSX.writeFileXLSX(wb, this.$i18n.t('aava.index.breakdown.title') + '_' + className + '.xlsx', {})
  },

  createExcelData (opts) {
    const result = []
    opts.percentageFields = []
    opts.breakdownAttributes.forEach(attribute => {
      if (this.uniqUnitNameExists(opts.totalsCollection.items.sums, attribute) &&
          !this.isPercentageAttribute(attribute, opts.totalsCollection.items)) {
        opts.percentageFields.push(attribute)
      }
    })

    this.createHeaderRow(result, opts)
    this.createDataRow(result, opts.totalsCollection.items, opts, 0)
    return result
  },

  createDataRow (result, items, opts, level) {
    result.push([
      ...this.createDataRowTitle(opts, items, level),
      items.ids.length,
      ...opts.breakdownAttributes.map(attribute => this.attributeValue(opts, attribute, items)).flat()
    ])
    this.sortSubgroups(items.subgroups, opts.sortColumn, opts.sortOrder).map(subgroup => {
      return this.createDataRow(result, subgroup, opts, level + 1)
    })
  },

  createDataRowTitle (opts, item, level) {
    const titleColumns = opts.selectedAttributes.length + 1

    if (level === 0) {
      return [this.$i18n.t('aava.index.breakdown.total'), ...Array(titleColumns - 1).fill('')]
    } else {
      const blanksBeforeTitle = level
      const blanksAfterTitle = titleColumns - level - 1
      const ret = []
      for (let i = 0; i < blanksBeforeTitle; i++) { ret.push(' ') }
      ret.push(item.group_title)
      for (let i = 0; i < blanksAfterTitle; i++) { ret.push(' ') }

      return ret
    }
  },

  createHeaderRow (result, opts) {
    const bdAttrs = [...opts.breakdownAttributes].map(element => {
      const commonUnit = this.countAttributeUnit(opts.totalsCollection.items, element) || ''
      const title = this.$i18n.t(opts.objectClass + '.attributes.' + element) + ' ' + commonUnit

      // percentage fields have their own column
      if (opts.percentageFields.indexOf(element) > -1) {
        const percentageTitle = this.$i18n.t(opts.objectClass + '.attributes.' + element) + ' (%)'
        return [title, percentageTitle]
      } else {
        return [title]
      }
    })

    result.push([
      '', // totals column, no title,
      ...opts.selectedAttributes,
      this.$i18n.t('aava.index.breakdown.object_count'), // number of items-column
      ...bdAttrs.flat()
    ])
  },

  sumValue (opts, attribute, group) {
    return opts.percentageFields.indexOf(attribute) > -1
      ? this.sumValueWithoutUnit(attribute, group)
      : this.sumValueWithUnit(attribute, group)
  },

  isPercentageAttribute (attribute, group) {
    return group.sums && group.sums[attribute] && group.sums[attribute].type === 'percentage'
  },

  percentageAttributeValue (attribute, group) {
    const value = group.sums[attribute].p.value / group.ids.length
    return decimal.format(value, { precision: 2 })
  },

  attributeValue (opts, attribute, group) {
    if (this.isPercentageAttribute(attribute, group)) {
      return this.percentageAttributeValue(attribute, group)
    }

    let resultValue = this.isCountAttribute(attribute, group)
      ? this.countValue(attribute, group)
      : this.sumValue(opts, attribute, group)
    let showPercentage = false
    let percentageValue = 0

    if (opts.percentageFields.indexOf(attribute) > -1) {
      showPercentage = true
      if (isNaN(resultValue)) {
        resultValue = 0
      } else {
        percentageValue = parseFloat((this.percentageFromTotal(opts.totalsCollection.items.sums, group, attribute)).replace(',', '.'))
      }
    }

    // percentage fields have their own column
    return showPercentage
      ? [resultValue, percentageValue]
      : [resultValue]
  }
}
