<template>
  <div>
    <div
      v-html="cellValue.value"
    />
  </div>
</template>

<script>
export default {
  name: 'ContentRichtext',

  props: {
    cellValue: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
