import Vue from 'vue'
import listViewAPI from './../../api'
import listFilters from './../../_listFilters'
import listQueries from '@/store/_listQueries'
import storeShared from './../../_storeShared'
import router from './../../../router'
import { AxiosResponse } from 'axios'
import { InfoText, ItemsResponse } from '@/types/API.responses'

// Generate hash for loading list items
// For advanced load it is checked that has not changed
// If hash is changed, it means that user has changed filters
// and advanced preload should be stopped
const getListLoadHash = (state) => {
  const hash = state.objectClass + '_' +
    state.selectedLayoutProfileId + '_' +
    JSON.stringify(listFilters.getSortFilters(state)) + '_' +
    JSON.stringify(listFilters.getSuperSearchFilters(state.superSearchTerm)) + '_' +
    JSON.stringify(listFilters.getSearchFilters(state))
  return hash
}

const isActiveLoadHash = (state, listLoadHash) => {
  return !(state.listLoadHash && state.listLoadHash !== listLoadHash)
}

// Do not load while Layout Profile items are not set
// user may have switched Layout Profile
const layoutProfileItemsAreLoaded = (state) => {
  return state.layoutProfileItems.length === 0
}

const pageIsAlreadyLoaded = (state, pageNumber) => {
  return state.pagesRetrieved.includes(pageNumber) || !pageNumber || pageNumber < 1
}

const listLoadForPropsFromRouter = () => {
  return router.currentRoute
    ? {
        forField: router.currentRoute.params.targetField,
        forId: router.currentRoute.params.targetId,
        forClass: router.currentRoute.params.targetResource,
      }
    : {}
}

const getFinalizedListFilters = (state, offset) => {
  let filters = listFilters.get(state.itemsOnPage, offset, state)
  // For break-down view can't use all filters
  // Use only main-filters and add unique_ids
  if (state.listBreakdownSearchIds.length > 0) {
    filters = [...listFilters.getMainFiltres(state.itemsOnPage, offset),
      ...[{ key: '_unique_ids', value: state.listBreakdownSearchIds.join(',') }]]
  }
  return filters
}

// List table is divided into logical areas (size = itemsOnPage), eash area has a "position  number"
// User can scroll to any position, then "position number" is used to items from api
const getCurrentPositionPageNumber = (state) => {
  if (state.rowHeight > 0) {
    const pageNumber = Math.ceil(
      state.scrolledTop /
      (state.itemsOnPage * (state.rowHeight + state.rowPadding))
    )
    return (pageNumber > 0) ? pageNumber : 1
  }
  return 1
}

export default {
  setNewListLoadHash: ({ state }) => {
    state.listLoadHash = getListLoadHash(state)
  },

  resetListLoadHash: ({ state }) => {
    state.listLoadHash = 'null'
  },

  reloadListItems: ({ state, dispatch, commit }, { updateCounts }) => {
    return new Promise(resolve => {
      dispatch('clearSelected')
      state.loadingListItems = true
      commit('resetListPageItemsBeforeFirstPageLoad')
      dispatch('setNewListLoadHash')
      dispatch('listLoadItemsInAdvance', state.listLoadHash).then(() => {
        resolve(true)
      })
      if (updateCounts) {
        setTimeout(() => {
          dispatch('getLayoutProfilesItemCount')
        }, 1000)
      }
    })
  },

  triggerItemDeleteEvents: ({ state, dispatch }) => {
    // Call list refresh when item is deleted in split mode
    // and item show/edit view is opened in primary split region
    if (state.splitMode) {
      dispatch('reloadListItems', { updateCounts: true })
    }
  },

  sortByField: ({ state, dispatch, commit }, {
    fieldName,
    secondary,
    direction,
    updateRemote = true,
  }) => {
    commit('updateSortField', {
      fieldName,
      secondary,
      direction,
    })
    dispatch('reloadListItems', { updateCounts: false })
      .then(() => {
        if (updateRemote) {
          dispatch('updateLayoutProfileDefaultSort', {
            id: state.selectedLayoutProfileId,
            defaultSort: {
              sortField: state.sortField,
              sortBy: state.sortBy,
              secondarySortings: state.secondarySortings
            }
          })
        }
      })
  },

  listLoadItemsInAdvance: ({ state, dispatch }, listLoadHash) => {
    return new Promise(resolve => {
      let pageNumber = getCurrentPositionPageNumber(state)
      // Set triggeringScrollPosition which is checked
      // to stop preloading if scrolled away from this position
      const triggeringScrollPosition = state.scrolledTop
      // If scroll direction is up, increase pageNumber
      // because page number is calculated from top of the page
      // and screen can fit more than 1 (loaded items) page
      if (state.scrollingDirection === 'up') {
        pageNumber++
      }

      // First page has been loaded
      // Load for page: 2

      dispatch('loadItemsForMultiplePages', {
        // When scrolling up, start loading from the bottom
        pageNumber: pageNumber + (state.scrollingDirection === 'down' ? 0 : (state.pagesOnScreen - 1)),
        triggeringScrollPosition: state.scrolledTop,
        loadForPages: 1,
        listLoadHash,
      }).then(moreItemsToLoad => {
        resolve(null)
        // Proceed only if previous response got items
        // and conditions (listLoadHash) have not changed
        if (moreItemsToLoad && listLoadHash === state.listLoadHash) {
          state.loadingListItems = false
          dispatch('loadItemsForMultiplePages', {
            // In case of scroll direction is up, start loading from pageNumber + state.pagesOnScreen
            // solves issue with large or vertically positioned monitors where items at the bottoms
            // are not loaded otherwise
            pageNumber: (pageNumber + (state.scrollingDirection === 'down' ? 1 : (state.pagesOnScreen - 2))),
            triggeringScrollPosition,
            // How many pages to load in advance, beside pages visible on screen + 1 already loaded above
            loadForPages: state.pagesOnScreen + 2,
            listLoadHash,
          })
        }
      })
    })
  },

  loadLayoutProfileWithItems: ({ dispatch, state, commit }) => {
    return new Promise(resolve => {
      if (!state.selectedLayoutProfileId) {
        resolve(false)
        return
      }
      state.loadingList = true
      state.loaderShowComplete = false
      state.loadingListItems = true
      const layoutProfileId = state.selectedLayoutProfileId
      commit('resetLayoutProfile')
      commit('resetListPageItemsBeforeFirstPageLoad')
      dispatch('resetListLoadHash')

      // GET Layout Profile Items

      dispatch('loadListLayoutProfileItems').then(r => {
        dispatch('adjustListSortAfterLPItemsLoad', r?.items)
        // Ensure Layout Profile has not been changed
        if (layoutProfileId === state.selectedLayoutProfileId) {
          // Set loadingListItems again as true
          // because when switching Layout Profile tabs quite fast it is possible
          // to show previous tab total count in new tab view
          state.loadingListItems = true
          commit('resetListPageItemsBeforeFirstPageLoad')
          dispatch('setNewListLoadHash')
          const listLoadHash = state.listLoadHash

          // GET List Items (one page first)

          // Loading first page items here is important
          // because before calling advanced items load
          // list should be rendered with few items to calculate line-height and cell widths
          dispatch('loadItemsForMultiplePages', {
            listLoadHash,
            pageNumber: 1, // For one page first
            offset: 0,
            forced: true,
          }).then(moreItemsToLoad => {
            // START advanced load
            if (listLoadHash === state.listLoadHash) {
              state.loaderShowComplete = true
              setTimeout(() => {
                state.loadingList = false
                state.loadingListItems = false
                resolve(moreItemsToLoad)
                dispatch('listLoadItemsInAdvance', listLoadHash)
              }, 50)
            }
          })
        }
      })
    })
  },

  // Refactor, split check and actual function
  fetchListItemsForOnePage: ({ state }, { offset, pageNumber, forced, listLoadHash }): Promise<AxiosResponse<ItemsResponse> | boolean> => {
    return new Promise(resolve => {
      // May happen that objectClass matches with loadHash, but actually layout profile has not been set yet
      // Happens when moving very quickly between different model lists as this:
      // open model 1 list, move to model 2 list, move back to model 1 list
      const objectClassMatchesLayoutProfile = state.objectClass === state.selectedLayoutProfile.controller
      if (!isActiveLoadHash(state, listLoadHash) || layoutProfileItemsAreLoaded(state) || !objectClassMatchesLayoutProfile) {
        // Cancel if old list load hash
        // or layout profile items not yet loaded
        resolve(false)
      } else if (pageIsAlreadyLoaded(state, pageNumber) && !forced) {
        // Page already loaded
        resolve(false)
      } else {
        Vue.set(state.pagesRetrieved, state.pagesRetrieved.length, pageNumber)
        // Get items from the API
        listViewAPI.fetchListItems(
          state.objectClass,
          getFinalizedListFilters(state, offset),
          listQueries.get(state.layoutProfileItems, state.selectedLayoutProfile, {
            locale: state.locale,
          }), listLoadForPropsFromRouter())
          .then((response: AxiosResponse) => {
            resolve(response)
          })
      }
    })
  },

  getFlashMessagesFromResponse: ({ dispatch }, response: AxiosResponse) => {
    if (!response.data.flash_messages) { return }
    response.data.flash_messages.forEach(message => {
      message.message = message.text
      dispatch('addFlashMessage', message)
    })
  },

  getInfoTextFromResponse: ({ dispatch }, response: AxiosResponse) => {
    if (!response.data.info_text) { return }
    const infoTexts: InfoText[] = response.data.info_text?.filter(text => !!text) ?? [] // sometimes there is a text with null value
    if (infoTexts.length === 0) { return }
    dispatch('showMessage', {
      message: infoTexts
        // Back-end can already have <br>, but just in case let's add, if not. 0: "Asiakkas puuttuu<br>"
        .map(text => text.toString().includes('<br>') ? text : text + '<br>')
        .join(''),
      type: 'info',
    })
  },

  storeListPageItems: ({ commit, dispatch }, { state, response, listLoadHash, offset }) => {
    if (response.data?.items) {
      // Check that listLoadHash is same
      if (listLoadHash === state.listLoadHash) {
        commit('updateListPageItems', {
          items: storeShared.completeListItemsData(response.data.items),
          offset,
          total: response.data.total,
          listLoadHash,
        })
        commit('updateListTotalCount', response.data.total)
        dispatch('setListVisibleItems', { forced: true })
        return true
      }
    }
    return false
  },

  // Load list items for one page
  // Returns true if more items to load
  loadItemsForMultiplePages: ({ state, dispatch },
    { pageNumber, forced, loadForPages, triggeringScrollPosition, listLoadHash }) => {
    return new Promise(resolve => {
      const offset = (state.itemsOnPage * pageNumber) - state.itemsOnPage
      // Cancel further loads if triggering scroll position is not close (within 1000 px)
      // to current scroll position
      // Or requesting non-existing items (over listTotalCount)
      if (Math.abs(triggeringScrollPosition - state.scrolledTop) > 1000 ||
        (state.listTotalCount !== null && offset >= state.listTotalCount) && pageNumber > 1) {
        return resolve(false)
      }
      dispatch('fetchListItemsForOnePage', { offset, pageNumber, forced, listLoadHash, })
        .then((response: AxiosResponse<ItemsResponse> | boolean) => {
          if (response) { // No need to check errors if skipped
            dispatch('globalErrorDisplay', { response, context: 'Load more list items ' + pageNumber })
          }
          if (dispatch('storeListPageItems', { state, response, listLoadHash, offset })) {
            resolve(true)
            dispatch('loadNextPage', { state, loadForPages, offset, pageNumber, listLoadHash, triggeringScrollPosition })
          }

          resolve(false) // Did not get items or old load hash
        })
    })
  },

  loadNextPage: ({ dispatch }, { state, loadForPages, offset, pageNumber, listLoadHash, triggeringScrollPosition }) => {
    // Call load for next page
    if (loadForPages - 1 > 0 &&
      // For pages over 3 load only if started scrolling
      // can be bad to load over 3 if loading takes a long time and
      // user switches between many layout profiles quickly
      // Alternatively could check page load time and then decide...
      (state.scrolledTop > 0 || (offset / state.itemsOnPage) < 2)
    ) {
      dispatch('loadItemsForMultiplePages', {
        pageNumber: (pageNumber + (state.scrollingDirection === 'down' ? 1 : -1)),
        triggeringScrollPosition,
        loadForPages: loadForPages - 1,
        listLoadHash,
      })
    }
  },

  setListFilter: ({ state }, { fieldName, value }) => {
    if (fieldName) {
      Vue.set(state.listFilters, fieldName, value)
      listFilters.setLocalStorageFilter(state.selectedLayoutProfileId, fieldName, value)
    }
  }
}
