
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'NoSeMyNotifications',

  data () {
    return {
      tab: null,
      headers: [
        {
          text: this.$i18n.t('aava.no_se.status'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.name'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.type'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.description'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.actions_btn'),
          align: 'left',
          sortable: false
        },
      ]
    }
  },

  computed: {
    ...mapFields(Object.keys(state))
  },

  methods: {
    activate (item, active) {
      this.$store.dispatch('noSeActivate', { item, active }).then((success: boolean) => {
        if (success) {
          item.active = active
        }
      })
    },

    openForEditing (item) {
      this.noSeEditItemId = item.id
    },

    remove (item) {
      if (confirm(this.$i18n.t('confirmations.destroy_object'))) {
        this.$store.dispatch('noSeRemove', item).then(() => {
        })
      }
    },

    share (item, shared) {
      this.$store.dispatch('noSeShare', { item, shared }).then((success: boolean) => {
        if (success) {
          item.shared = shared
        }
      })
    },
  },
}
