<template>
  <v-container
    class="column preview_table"
    :style="addItemContainerStyle"
  >
    <v-row dense>
      <v-col
        v-if="!show_add_form"
        cols="12"
        class="text-center"
      >
        <div
          v-if="$store.state.dashboardItems.length === 0"
          class="no_dashboardItems"
        >
          {{ $i18n.t('aava.general.no_objects') }}
        </div>
        <v-btn
          :loading="$store.state.dashboardAddingItemNow"
          :color="'grey lighten-1'"
          large
          icon
          @click="showForm"
        >
          <v-icon>
            fa-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        v-if="show_add_form && !$store.state.dashboardAddingItemNow"
        cols="12"
      >
        <v-container
          class="pa-0"
          fluid
        >
          <v-row no-gutters>
            <v-col cols="10">
              <div class="form_title">
                {{ $i18n.t('aava.dashboard.add_title') }}
              </div>
            </v-col>
            <v-col cols="2">
              <v-icon
                small
                @click="hideForm"
              >
                fa-times
              </v-icon>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <DashboardItemAddGroup
                :space-allocation="spaceAllocation"
                :group-type="'preview'"
                :title="$i18n.t('aava.dashboard.add_preview_title')"
              />
            </v-col>
            <v-col cols="6">
              <DashboardItemAddGroup
                :space-allocation="spaceAllocation"
                :group-type="'chart'"
                :title="$i18n.t('aava.dashboard.add_chart_title')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardItemAddGroup from './DashboardItemAddGroup'

export default {
  name: 'DashboardItemAdd',

  components: {
    DashboardItemAddGroup,
  },

  props: {
    spaceAllocation: {
      default () { return {} },
      type: Object
    }
  },

  data () {
    return {
      show_add_form: false,
    }
  },

  computed: {
    addItemContainerStyle () {
      const height_rows = 2
      return {
        height: this.spaceAllocation.box_height * (this.show_add_form ? height_rows : 1) + 'px',
        width: this.spaceAllocation.box_width + 'px',
        margin: this.spaceAllocation.margin_px + 'px'
      }
    },
  },

  methods: {
    showForm () {
      this.show_add_form = true
    },

    hideForm () {
      this.show_add_form = false
    },
  },
}
</script>

<style scoped>
  .close_button_container {
    position: relative;
    top: 0;
    right: 0;
  }

  .close_button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 8px 8px 8px;
    width: 30px;
    height: 24px;
    color: #cccccc;
    margin: 5px 5px 0 0;
    cursor: pointer;
    font-size: 7px;
  }

  .form_title {
    font-size: 18px;
  }

  .no_dashboardItems {
    width: 100%;
    padding: 30px 0 15px 0;
    font-size: 24px;
    color: #d8d8d8;
  }
</style>
