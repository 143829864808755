import { Types } from '@/types/AppTypes'
import { ItemPicker } from '@/types/ItemPicker'

export default {
  showItemPicker: false,
  showItemPickerTimestamp: 0,
  itemPickerSelectedOption: {} as null | Types.Item,
  itemPickerSearchTerm: '',
  itemPickerSelectCallback: () => {},
  itemPickerUnselectCallback: () => {},
  itemPickerShowTimer: null as null | number,
  itemPickerResetTimer: null as null | number,
  hasManyComponentForItemPicker: null,
  itemPickerAutoSelectFirstWhenOptionsLoaded: false,
  itemPickerProps: null as null | ItemPicker.Props
}
