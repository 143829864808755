export default {
  buttonBg: [
    '#E57373',
    '#EF5350',
    '#F06292',
    '#EC407A',
    '#BA68C8',
    '#AB47BC',
    '#9575CD',
    '#7E57C2',
    '#7986CB',
    '#5C6BC0',
    '#42A5F5',
    '#1E88E5',
    '#29B6F6',
    '#039BE5',
    '#26C6DA',
    '#00ACC1',
    '#4DB6AC',
    '#26A69A',
    '#66BB6A',
    '#43A047',
    '#9CCC65',
    '#7CB342',
    '#D4E157',
    '#C0CA33',
    '#FDD835',
    '#FBC02D',
    '#FFCA28',
    '#FFB300',
    '#FFA726',
    '#FB8C00',
    '#FF7043',
    '#F4511E',
    '#8D6E63',
    '#78909C',
    '#546E7A',
    '#AAAAAA',
  ],
  formBg: [
    '#fcf0f2',
    '#f9f2fa',
    '#faeae8',
    '#f2f4fc',
    '#E3F2FD',
    '#E0F2F1',
    '#E8F5E9',
    '#ECEFF1',
    '#F1F8E9',
    '#F9FBE7',
    '#FFFDE7',
    '#faf7d7',
    '#FFF8E1',
    '#FFF3E0',
    '#FFFFFF',
    '#FAFAFA',
    '#f2f2f2',
    '#e8e8e8',
  ],
}
