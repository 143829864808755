
import { DashboardTypes } from '../../types/DashboardTypes'
import DashboardTabForm from './DashboardTabForm.vue'
import DashboardTabMenu from './DashboardTabMenu.vue'
import Sortable from 'sortablejs'
import api from '../../store/api'
import dashboardMethods from '@/components/Dashboard/dashboardMethods'
import DashboardSettings from '@/components/Dashboard/DashboardSettings.vue'
import DashboardShortcuts from '@/components/Dashboard/DashboardShortcuts.vue'
import { AxiosResponse } from 'axios'

// TODO - on refresh chart is not showing

export default {
  name: 'DashboardTabs',

  props: {
    showShortcuts: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DashboardShortcuts,
    DashboardSettings,
    DashboardTabForm,
    DashboardTabMenu,
  },

  data () {
    return {
      renderTabForm: false,
      renderTabMenu: false,
      tabForEdit: null,
      sortable: null,
      formPosition: {
        x: 0,
        y: 0,
      },
      showTabsMenu: false,
    }
  },

  computed: {
    tabsComputed () {
      return this.tabs
    },

    tabs (): DashboardTypes.Tab[] {
      return this.$store.state.dashboardTabs
    },

    KPIDemoEnabled () {
      const isOmaInstance = window.location.host.includes('oma.aava')
      const isMetalDemo = window.location.host.includes('metal.aavaohjelmistot')
      return isOmaInstance || isMetalDemo || window.location.host.includes('localhost')
      // return true
    },
  },

  created () {
    this.createTabsSortables()
  },

  methods: {
    ...dashboardMethods,

    openTab (tab) {
      this.$store.state.selectedDashboardTabId = tab.id
    },

    openKPI () {
      this.$store.state.selectedDashboardTabId = 'kpi'
      this.$emit('showKPI')
    },

    saveTab (formData: DashboardTypes.TabFormData) {
      const tab: DashboardTypes.Tab = {
        name: formData.name,
        type: 'tab',
      }
      if (formData.id) {
        tab.id = formData.id
        const payload = {
          element_action: formData.name,
          config: ''
        }
        api.gorillaUpdateDashboardItem(formData.id, payload).then((response: AxiosResponse) => {
          this.$store.dispatch('globalErrorDisplay', { response, context: 'Update dashboard tab' })
          const i = this.getTabIndex(formData.id)
          this.tabs[i].name = formData.name
        })
      } else {
        this.saveNewTab(tab)
      }
    },

    saveNewTab (tab: DashboardTypes.Tab) {
      api.gorillaAddDashboardItem({
        type: 'tab',
        name: tab.name,
      }).then((response: AxiosResponse) => {
        this.$store.dispatch('globalErrorDisplay', { response, context: 'Add dashboard tab' })
        // As gorilla service does not return the id > have to reload all dashboard items
        // Then get the last Tab (hopefully this is the last one) and set it active
        this.refreshTabs().then(() => {
          this.setLastTabActive()
        })
      })
    },

    setFirstTabActive () {
      this.$store.state.selectedDashboardTabId = this.tabs[0]?.id
    },

    setLastTabActive () {
      this.$store.state.selectedDashboardTabId = this.tabs[this.tabs.length - 1].id
    },

    showTabForm (tab) {
      this.renderTabMenu = false
      this.tabForEdit = tab
      this.$nextTick(() => {
        this.renderTabForm = true
      })
    },

    deleteTab (tab) {
      const sameTabIsOpen = this.$store.state.selectedDashboardTabId === tab.id
      api.deleteItem('dashboard_items', tab.id).then((response: AxiosResponse) => {
        this.$store.dispatch('globalErrorDisplay', { response, context: 'Dashboard tab delete ' })
        if (!response) { return }
        this.closeMenu()
        this.$store.state.dashboardItems.filter(item => item.tabId === tab.id).forEach(item => {
          api.deleteItem('dashboard_items', item.id)
        })
        this.refreshTabs().then(() => {
          if (sameTabIsOpen) {
            this.setFirstTabActive()
          }
        })
      })
    },

    createTabsSortables () {
      this.$nextTick(() => {
        this.sortable = null
        const el = document.getElementById('dashboard-tabs')
        if (!el) { return }
        this.sortable = Sortable.create(el, {
          onEnd: this.tabDragEnd,
          delay: 50,
          animation: 200,
          draggable: '.draggable-tab'
        })
      })
    },

    tabDragEnd (move) {
      // When this.KPIDemoEnabled, decrease index by one, as KPI tab is not in the this.tabs
      // It is not draggable for Sortable, but still included in newIndex and oldIndex
      const temp = this.tabs.splice(move.oldIndex - (this.KPIDemoEnabled ? 1 : 0), 1)[0]
      this.tabs.splice(move.newIndex - (this.KPIDemoEnabled ? 1 : 0), 0, temp)
      api.saveTabsOrder(this.tabs)
    },

    getTabIndex (id) {
      return this.tabs.findIndex(t => t.id === id)
    },

    showTabMenu (tab, e) {
      this.tabForEdit = tab
      this.renderTabMenu = true
      this.formPosition = {
        x: e.clientX,
        y: e.clientY,
      }
    },

    classes (tab) {
      const classes = ['toolbar-tab', 'text-none']
      if (this.$store.state.selectedDashboardTabId === tab?.id) {
        classes.push('selected-layout-profile')
      }
      if (tab && tab.id !== 'kpi') {
        classes.push('draggable-tab')
      }
      return classes
    },

    style (tab) {
      const style: any = {
        color: 'white',
      }
      if (this.$store.state.selectedDashboardTabId === tab?.id) {
        style.color = 'black'
      }
      if (!style.background) {
        style.background = '#aaa'
      }
      return style
    },

    closeForm () {
      this.renderTabForm = false
    },

    closeMenu () {
      this.renderTabMenu = false
    },

    showAddNewPopup (e) {
      this.tabForEdit = null
      this.renderTabForm = true
      this.formPosition = {
        x: e.clientX,
        y: e.clientY,
      }
    }
  }
}
