export default {
  set (variable, value, ttlMs) {
    const data = {
      value,
      expiresAt: new Date().getTime() + ttlMs / 1,
    }
    try {
      localStorage.setItem(variable.toString(), JSON.stringify(data))
    } catch (e) {
      console.error('local storage over limit')
      console.log(e, e.message)
      // With single instance it should never exceed over 10 MB limit
      // But if it does happen, it must not break the app
      // Clear everything
      localStorage.clear()
    }
  },
  get (variable) {
    const data = JSON.parse(localStorage.getItem(variable.toString()))
    // const data = null
    if (data !== null) {
      if (data.expiresAt !== null && data.expiresAt < new Date().getTime()) {
        localStorage.removeItem(variable.toString())
      } else {
        return data.value
      }
    }
    return null
  },
}
