export default {
  loadCardViewInfo (lp_id, viewName) {
    return this.sendRequest('/gorilla/card_view/info/' + lp_id + '/' + viewName, [], [])
  },

  updateColumnOrdering (objectClass, data) {
    return this.sendPutWithPayloadRequest('/gorilla/card_view/ordering/' + objectClass, [], data)
  },

  sendCardAction (objectClass, viewName, id, data) {
    return this.sendPutWithPayloadRequest('/gorilla/card_view/execute/' + objectClass + '/' + id + '/' + viewName, [], data)
  },

  sendIconAction (objectClass, data) {
    return this.sendPutWithPayloadRequest('/gorilla/card_view/action/' + objectClass, [], data)
  },

  getCardItems (objectClass, viewName, filters, queries) {
    return this.sendRequest('/gorilla/card_view/index/' + objectClass + '/' + viewName, filters, queries)
  },

  getCardSelectionData (objectClass, viewName, ids) {
    const filters = ids.map(id => {
      return {
        key: 'ids[]',
        value: id
      }
    })
    return this.sendRequest('/gorilla/card_view/selection/' + objectClass + '/' + viewName, filters, [])
  },
}
