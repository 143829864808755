
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import api from '@/store/api'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ChangePassword',

  data () {
    return {
      saving: false,
      current_password: '',
      password: '',
      password_confirmation: '',
      message: null,
      textFieldAttributes: {
        outlined: true,
        color: '#666',
        rounded: true,
        hideDetails: true,
        dense: false,
        clearable: false,
      },
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    disabled () {
      return !(this.current_password && this.password && this.password_confirmation)
    },
  },

  methods: {
    changePassword () {
      this.saving = true
      api.changePassword(this.current_password, this.password, this.password_confirmation).then((response: AxiosResponse) => {
        if (response.data.status === 'ok' && response.data.message) {
          this.message = {
            message: this.$i18n.t(response.data.message.replace('aava:', 'aava.')),
            type: 'success',
          }
        } else {
          this.message = {
            message: this.$i18n.t(response.data?.messages?.[0].key.replace('aava:', 'aava.')) || 'Error',
            type: 'error',
          }
        }
        this.saving = false
      })
    },
  },
}
