export default {
  createNewLayoutProfile () {
    this.formData.selectedColor = this.selectedHexColor
    this.formData.timelineConfiguration = {
      backgroundColor: this.selectedHexColor || ''
    }
    this.saving = true
    // const view = this.layoutProfile.action
    this.$store.dispatch('createNewLayoutProfile', {
      data: this.formData,
      layoutProfile: this.layoutProfile,
    })
      .then(() => {
        this.closeLayoutProfileMenu()
        this.updateLayoutProfilesAfterCreatingNew()
      })
  },

  updateLayoutProfilesAfterCreatingNew () {
    this.setVisibleLayoutProfileTabsCount()
    if (this.isList) {
      this.newProfileJustCreated = true
      this.$store.dispatch('loadMetaAndLayoutProfiles', {
        objectClass: this.objectClass,
        cache: false,
      })
        .then(() => {
          this.$store.dispatch('getLayoutProfilesItemCount')
            .then(() => {
              this.openLayoutProfileList()
              this.$nextTick(() => {
                this.setVisibleLayoutProfileTabsCount()
                this.newProfileJustCreated = false
              })
            })
        })
    } else {
      this.newItemProfileJustCreated = true
      this.$store.dispatch('loadItemLayoutProfiles', this.layoutProfile.controller)
    }
    this.closeLayoutProfileMenu()
  },

  updateLayoutProfile () {
    this.saving = true
    this.formData.selectedColor = this.selectedHexColor
    this.formData.id = this.layoutProfile.id
    const layoutProfile = this.layoutProfile
    let timelineConfiguration = layoutProfile.timelineConfiguration
    if (!timelineConfiguration) {
      timelineConfiguration = {}
    }
    timelineConfiguration.backgroundColor = this.formData.selectedColor
    timelineConfiguration.editViewLayoutProfileId = this.formData.editViewLayoutProfileId
    timelineConfiguration.showViewLayoutProfileId = this.formData.showViewLayoutProfileId
    this.formData.timelineConfiguration = timelineConfiguration
    this.$store.dispatch('updateLayoutProfile', {
      formData: this.formData,
      layoutProfile: this.layoutProfile,
    })
      .then(() => {
        this.closeLayoutProfileMenu()
        this.setVisibleLayoutProfileTabsCount()
      })
  },
}
