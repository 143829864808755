<template>
  <v-row class="container-menu pr-0">
    <v-col
      :cols="availableSpaceInFieldSet < 500 ? 12 : ''"
      class="ma-0 pb-0 px-0 flex-grow-1 flex-shrink-0"
    >
      <v-btn
        style="float: left;"
        class="ml-2 mr-0 mt-0"
        icon
        @click.stop="$emit('showItemFieldsetMenu', { e: $event, fieldSet })"
      >
        <v-icon
          color="#bbb"
          small
        >
          fa-ellipsis-vertical
        </v-icon>
      </v-btn>
      <div
        class="title"
        style="display: contents;"
      >
        <MultiLangTextField
          v-model="fieldSet"
          :field="{ name: 'name', type: 'string' }"
          :label="$i18n.t('aava.general.subgroup') + ' ' + (index + 1)"
          :edit="true"
          @changeListener="changeListener"
        />
      </div>
    </v-col>
    <v-col
      :cols="availableSpaceInFieldSet < 500 ? 12 : ''"
      class="justify-end align-right text-right pt-5 flex-grow-0 flex-shrink-1"
      style="min-width: 250px;"
    >
      <ContainerColumnsSelector
        v-model="fieldSet.columns"
        :allow-empty-option="true"
        @change="$emit('updateLayoutContainerFieldSet', fieldSet)"
      />
      <FieldSetColumnsPerRowSelector
        v-model="fieldSet.content_columns"
        @change="$emit('updateLayoutContainerFieldSet', fieldSet)"
      />
      <ColorPicker
        v-model="fieldSet.bgColor"
        button-style="fab"
        color-preset="formBg"
        @change="$emit('updateLayoutContainerFieldSet', fieldSet)"
      />
    </v-col>
  </v-row>
</template>

<script>
import MultiLangTextField from '@/components/Form/MultiLangTextField'
import FieldSetColumnsPerRowSelector from '@/components/Item/LayoutEditor/FieldSetColumnsPerRowSelector'
import LCMethods from '@/models/LayoutContainer/LC.methods'
import ContainerColumnsSelector from '@/components/Item/LayoutEditor/ContainerColumnsSelector'
import ColorPicker from '@/components/App/ColorPicker'

export default {
  name: 'FieldSetEditorToolbar',

  components: {
    ColorPicker,
    ContainerColumnsSelector,
    FieldSetColumnsPerRowSelector,
    MultiLangTextField,
  },

  props: {
    container: {
      type: Object,
      default: () => {},
    },
    fieldSet: {
      type: Object,
      default: () => {},
    },
    fieldSets: {
      type: Array,
      default: () => { return [] },
    },
    index: {
      type: Number,
      default: null,
    },
    availableSpaceInFieldSet: {
      type: Number,
      default: null,
    },
  },

  data () {
    return {
      changeUpdateTimer: null
    }
  },

  methods: {
    ...LCMethods,

    changeListener () {
      clearTimeout(this.changeUpdateTimer)
      this.changeUpdateTimer = setTimeout(() => {
        this.$emit('updateLayoutContainerFieldSet', this.fieldSet)
      }, 1000)
    },
  },
}
</script>
