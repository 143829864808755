export default [{
  title: 'Tarjoukset',
  chartNames: [
    'Hävityt/voitetut',
    'Läpimeno-%',
    'Tarjouskanta',
    'Uusien tuotteiden määrä',
  ],
}, {
  title: 'Myynti',
  chartNames: [
    'Tilauskanta',
    'Tilauksen keskihinta',
    'Tilauksen kateprosentti',
    'Tilauksen kate-eurot',
  ],
}, {
  title: 'Osto',
  chartNames: [
    'Ostot euroina',
    'Ostohintojen muutos',
    'Raaka-aine ostot',
    'Keskiarvo kilohinta',
    'Toimitusvarmuus (materiaali)',
    'Toimitusvarmuus (alihankinta)',
    'Ostot euroina',
    'Ostohintojen muutos',
    'Raaka-aine ostot',
  ],
}, {
  title: 'Tuotanto',
  chartNames: [
    'Virheet',
    'Kapasiteetin käyttöaste',
    'Keskeneräinen tuotanto',
    'Tuotanto kiloina',
    'Tuotanto kpl',
    'Kapasiteetin käyttöaste',
  ],
}, {
  title: 'Logistiikka',
  chartNames: [
    'Ajallaan toimitetut tilaukset',
    'Aika tilauksesta toimitukseen',
    'Jälkitoimitukset',
    'Toimitusvarmuus',
    'Toimitetut kilot',
  ],
}, {
  title: 'Varasto',
  chartNames: [
    'Varastoarvot',
    'Kiertonopeus',
    'Hävikki',
  ],
}, {
  title: 'Laatu',
  chartNames: [
    'Asiakasreklamaatiot',
    'Sisäiset reklamaatiot',
    'Toimittajareklamaatiot',
    'Reklamaatioiden kustannukset',
  ],
}, {
  title: 'Henkilöstö',
  chartNames: [
    'Työtunnit toiminnoittain',
    'Sairaspoissaolot',
    'Ylityötunnit',
    'Osa-aikaisia/kokoaikaisia',
    'Tuottavuus',
  ],
}, {
  title: 'Hallinto',
  chartNames: [
    'Laskutusaika tuotteen valmistumisesta',
    'Myyntilaskujen euromäärä',
    'Hyvityslaskut',
    'Kannattavuus',
  ],
}]
