
import util from '../../utilities/sharedUtilities'
import itemMethods from '@/methods/item/itemMethods'
import itemFieldMethods from '@/methods/item/itemFieldMethods'
import listItemCellMethods from '@/methods/listItem/listItemCellMethods'
import { AxiosResponse } from 'axios'

export default {
  name: 'ReferenceField',

  props: {
    // TODO - props builder?
    value: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: null,
    },
    view: {
      type: String,
      default: 'item',
    },
    field: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    resource: {
      type: String,
      default: null,
    },
    showItemPickerFor: {
      type: Function,
      default: () => {},
    },
    editInList: {
      type: Boolean,
      default: false,
    },
    layoutEditMode: {
      type: Boolean,
      default: false,
    },
    openPickerOnFocus: {
      type: Boolean,
      default: false,
    },
    showItemFieldMenu: {
      type: Function,
      default: () => {},
    },
    hasManyParentField: {
      type: Object,
      default: () => {},
    },
    isHasManyFieldAndNotFirstRow: {
      type: Boolean,
      default: false,
    },
    showItemModal: {
      type: Function,
      default: () => {},
    },
    modal: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      inputSearchString: '',
      itemPickerIsOpen: false,
      clearedFromMenu: false,
      autoSelectFirstWhenOptionsLoaded: false,
    }
  },

  computed: {
    showMenuButton () {
      return !this.modal && !this.readOnly // && !this.isHasManyField
    },

    pickerAutoOpenOff () {
      return !!this.field.amc_options?.picker_auto_open_off || !!this.field.picker_auto_open_off
    },

    isHasManyField () {
      return !!(this.hasManyParentField && this.hasManyParentField.name)
    },

    addNewOptions () {
      const options: { referenceClass: string, title?: string }[] = []
      if (this.field.reference_class === 'Any') {
        (this.field.association_classes || []).forEach(referenceClass => {
          options.push({
            referenceClass,
            title: this.$i18n.t(util.objectClassUnderscoredName(referenceClass) + '.show.title'),
          })
        })
      } else {
        options.push({ referenceClass: this.field.reference_class })
      }
      return options
    },

    inputValue: {
      set (value) {
        if (typeof value === 'object') {
          this.$emit('input', value)
        } else {
          // Clear value if search term cleared
          if (!value) {
            this.$emit('input', null)
          }
          this.$store.state.itemPickerSearchTerm = value
          if (!this.clearedFromMenu && !this.pickerAutoOpenOff) {
            this.showItemPicker()
          }
        }
      },
      get () {
        return (this.value?.[this.summaryField] || this.value?.id) || ''
      },
    },

    summaryField () {
      return this.field?.reference_attribute || 'summary'
    },
  },

  watch: {
    // On user input as search string, update inputValue
    // which triggers showing the item picker
    inputSearchString (value) {
      if (this.inputValue === value) { return }
      this.inputValue = value
    },

    // Show selected option text value for the user,
    // however the selection was actually made (by resetting, user selection, dfc-request etc)
    inputValue (value) {
      if (this.inputSearchString === value) { return }
      this.inputSearchString = value
    },
  },

  created () {
    this.inputSearchString = this.inputValue
  },

  methods: {
    ...itemMethods,
    ...itemFieldMethods,
    ...listItemCellMethods,

    clearValue () {
      this.clearedFromMenu = true // To avoid item picker popup while changing input value
      this.inputValue = ''
      this.$emit('changeListener', 0)
      this.$emit('selectedInList')
      setTimeout(() => {
        this.clearedFromMenu = false
      }, 30)
    },

    openNewReferenceItemModal (referenceClass) {
      if (this.readOnly) { return }
      this.showItemModal({
        show: true,
        id: 'new',
        edit: true,
        resource: util.objectClassUnderscoredName(referenceClass),
        showLoader: false,
        saveCallback: this.changeModalReferenceItemCompleted,
        closeCallback: this.closeReferenceModal,
      })
    },

    openReferenceItemModal (edit) {
      if (!this.value?.id) { return }
      this.showItemModal({
        show: true,
        id: this.value.id,
        edit,
        resource: util.objectClassUnderscoredName(this.value['@class']),
        showLoader: false,
        saveCallback: this.changeModalReferenceItemCompleted,
        closeCallback: this.closeReferenceModal,
      })
    },

    // Control how reference item is opened in case of
    // a - regular edit form field
    // b - has-many item on edit mode, in split mode
    // c - has many item on edit mode, without split mode
    showReferenceItem (event) {
      if (!this.value?.id) { return }
      if (!this.isHasManyField) {
        // Open reference item on modal
        this.openReferenceItemModal(false)
      } else {
        if (this.$store.state.splitMode) {
          // In case of has-many item, open in split mode or new tab
          const payload = {
            className: this.value['@class'],
            // parentId: this.hasManyParentField?.id,
            event,
            view: 'item',
          }
          this.openReferenceLink(this.value.id, payload)
        } else {
          // Open in new tab, easiest to support
          // Can't use same modal as for the main item, not just available easily enough
          window.open('#' + util.objectClassUnderscoredName(this.value['@class']) + '/' + this.value.id)
        }
      }
    },

    changeModalReferenceItemCompleted (item) {
      if (!item?.['@class']) { return }
      this.closeReferenceModal()
      // TODO - test this case if should use summaryField
      this.getItemInfo(util.objectClassUnderscoredName(item['@class']), item.id, [this.summaryField])
        .then((response: AxiosResponse) => {
          this.selectOption(response.data.item)
        })
    },

    closeReferenceModal () {
      this.showItemModal({
        show: false,
      })
    },

    keydownHandler (e) {
      // For date field show only with down key
      if (e.key === 'ArrowDown') {
        this.showItemPicker()
      }
      // On special cases pass keypress to the <FormField> component
      if ([
        'Escape', // Restore the value in list view
      ].includes(e.key)) {
        this.$emit('keyPress', e)
      }
      // Special behaviour for picker_auto_open_off
      // Tab opens item picker, if only one result, auto-select and move to next input
      if (e.key === 'Tab' && this.pickerAutoOpenOff && !this.$store.state.showItemPicker) {
        this.showItemPicker()
        this.$store.state.itemPickerAutoSelectFirstWhenOptionsLoaded = true
        e.stopPropagation()
        e.preventDefault()
      } else if (e.key === 'Tab' && this.pickerAutoOpenOff) {
        this.$store.state.itemPickerAutoSelectFirstWhenOptionsLoaded = true
      } else if (e.key === 'Tab') {
        // Pass Tab for the parent - used in list view to move to the next editable cell
        this.$emit('keyPress', e)
      }
    },

    focus (e) {
      if (this.readOnly) { return }
      this.autoSelectTextOnFocus(e)
      if (this.openPickerOnFocus) {
        this.showItemPicker()
      }
    },

    blur (e) {
      // After blur let's set input search string to reflect if and which value has been set
      // Case - user selects one option, then types new search string and leaves the input,
      // this will still keep the old selection, which should be shown for the user
      setTimeout(() => {
        this.inputSearchString = this.value?.id ? this.inputValue : ''
      }, 300)
    },

    toggleItemPicker (e) {
      if (this.itemPickerIsOpen) {
        this.$store.dispatch('closeItemPicker')
        this.itemPickerIsOpen = false
      } else {
        this.showItemPicker(e)
      }
    },

    showItemPicker (e) {
      this.itemPickerIsOpen = true
      this.showItemPickerFor(e, {
        objectClass: util.objectClassUnderscoredName(this.field.reference_class),
        // TODO comment
        forObjectClass: (this.hasManyParentField && this.hasManyParentField.name) || this.resource,
        forField: this.field,
        forFieldName: this.field.name,
        forId: (this.item && (this.item.token || this.item.id)) || null,
        selectColumns: this.field.select_columns || [],
        // For country select in address form for ~path part in url
        // Normally forObjectClass is fine but country api call expects different value
        pathField: this.field.pathField,
        selectCallback: this.selectOption,
        closeCallback: () => { this.itemPickerIsOpen = false },
        attachEl: this.$refs.inputRef.$el,
        useAttachElMinWidth: true,
        forItem: this.item,
      })
    },

    selectOption (option) {
      this.itemPickerIsOpen = false
      this.inputValue = {
        '@class': option['@class'],
        id: option.id,
        [this.summaryField]: option[this.summaryField],
      }
      this.$emit('changeListener', 0)
      this.$emit('selectedInList')
      // Set focus back to the input, when selected with the mouse
      this.$refs.inputRef.focus()
    },
  }

}
