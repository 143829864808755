<template>
  <div
    class="resize-start-line-container"
    :style="style"
    @mousedown="mouseDownHandler(field.name, $event)"
    @click.stop=""
  >
    <div
      :style="lineStyle"
      class="resize-start-line"
    />
  </div>
</template>

<script>
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

const columnDragStartDelay = 700

export default {
  name: 'HeaderColumnResize',

  props: {
    field: {
      type: Object,
      default: () => Object,
    },
    forHasMany: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    // TODO - does not need all state
    ...mapFields(Object.keys(state)),

    resizeEnabled () {
      return this.forHasMany || !this.selectedLayoutProfile.locked
    },

    style () {
      const style = {}
      style.cursor = this.resizeEnabled ? 'col-resize' : 'not-allowed'
      return style
    },

    lineStyle () {
      const style = {}
      if (!this.resizeEnabled) {
        style.background = '#ddd'
      }
      return style
    }
  },

  methods: {
    ...methods,

    mouseDownHandler (fieldName, e) {
      if (!this.resizeEnabled) {
        return
      }
      if (this.forHasMany) {
        this.hasManyColumnResizeStart(e)
      } else {
        this.resizingFieldName = fieldName
        this.resizingFieldWidthBefore = this.field.userFixedWidth || this.field.forcedWidth
        this.resizingFieldWidthBefore = this.field.userFixedWidth || this.field.forcedWidth
      }
    }
  }
}
</script>

<style>
.resize-start-line-container {
  position: absolute;
  right: -3px;
  top: 16px;
  width: 1px;
  padding: 4px;
  height: 25px;
  z-index: 1;
}
.resize-start-line {
  background: #E0E0E0;
  width: 2px;
  height: 10px;
}
</style>
