import sharedUtilities from '@/utilities/sharedUtilities'
import { EventBus } from '@/eventBus'
import { EventAction } from '@/types/EventAction'
import { Types } from '@/types/AppTypes'
import { AxiosResponse } from 'axios'
import { EventResponseMessage } from '@/types/API.responses'

export default {

  // 1. Used in EventButton (item show, list edit, has-many show)
  // 2. Used in list view ContentProcessEvents < ContentCell
  // 3. Used in ITEM view when field.type === 'process_events' (?)
  sendProcessEventForContentItem (event: EventAction, parent: Types.Item, parentField: Types.Item, e: MouseEvent) {
    return new Promise(resolve => {
      e.stopPropagation()

      this.showListRefreshButton = true
      this.$set(this.sendingEventActionForIds, this.sendingEventActionForIds.length, this.item.id)
      this.startJob().then(key => {
        this.$store.dispatch('dispatchProcessEventOrAction', {
          event,
          item: this.item,
          view: this.view,
          index: this.itemIndex,
          objectClass: this.objectClass,
          parent,
        }).then((response: AxiosResponse) => {
          resolve(response) // Do not need more
          this.endJob(key)
          // Redirect in any case, status = ok or error
          if (response.data.redirect_to || response.data.redirect_to) { // .data is used when coming from error
            this.$emit('redirectToAfterProcessEventAction', response)
          }
          if (response.data.status === 'ok') {
            const messages: EventResponseMessage[] = response.data.messages ?? []
            messages.forEach(message => this.$store.dispatch('showMessage', {
              type: message.type,
              message: message.text,
            }))
            if (this.stateChangeCallback) {
              // In case of has-many item event action click, refresh has-many items
              this.stateChangeCallback()
            }
            if (this.isHasManyField) {
              EventBus.$emit('callUpdateMainItemOnHasManyChildItemEventAction', {
                parentField,
                parent,
              })
              return
            }
            const selectedId = this.$store.state.selectedId
            if (!response.data.redirect_to && selectedId && this.splitMode) {
              // If split mode, re-load item show view
              // If edit form was open, still load show view
              this.$parent.listItemOpenById(selectedId, {})
            } else if (response.data.reload) {
              // TODO - wrong place?
              this.$store.dispatch('reloadListItems', { updateCounts: true })
            }
          }
        })
      })
    })
  },
}
