<template>
  <v-dialog
    v-model="showAddressModal"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar color="grey lighten-2">
        <v-toolbar-title>{{ addressTitle }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="showAddressModal = false"
        >
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <div
        class="google-maps-container"
        :style="containerStyle"
      >
        <div
          id="map-area"
          :style="mapAreaStyle"
        />
        <div
          v-if="addressModalData.from"
          id="direction-details"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from './../store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

const API_URL = 'https://maps.googleapis.com/maps/api/js'
const CALLBACK_NAME = '__googleMapsApiOnLoadCallback'

const loadGoogleMapsApi = (key) => {
  return new Promise(resolve => {
    if (window.google && window.google.maps) {
      resolve(window.google.maps)
      return
    }

    window[CALLBACK_NAME] = function () {
      resolve(window.google.maps)
      delete window[CALLBACK_NAME]
    }

    const scriptElement = document.createElement('script')
    const params = ['callback=' + CALLBACK_NAME]
    params.push('key=' + key)

    scriptElement.src = API_URL + '?' + params.join('&')
    document.body.appendChild(scriptElement)
  })
}

export default {
  name: 'AddressModal',

  data () {
    return {
      height: window.innerHeight - 166
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    apiKey () {
      return this.$store.state.systemConfigs.google_maps_api_key
    },

    mapAreaStyle () {
      return {
        width: (this.addressModalData.from ? 60 : 100) + '%'
      }
    },

    addressTitle () {
      return this.addressModalData.address && this.addressModalData.address.replace(/<br>/g, ', ')
    },

    containerStyle () {
      return { height: this.height + 'px' }
    }
  },

  watch: {
    showAddressModal (value) {
      if (value) {
        this.showAddress()
      }
    }
  },

  methods: {
    clearDirectionDetailsDiv () {
      const el = document.getElementById('direction-details')
      if (el) {
        el.innerHTML = ''
      }
    },

    showAddress () {
      this.clearDirectionDetailsDiv()
      loadGoogleMapsApi(this.apiKey)
        .then(googleMaps => {
          if (this.addressModalData.from) {
            this.routeToAddress(googleMaps, this.addressModalData.from, this.addressModalData.address)
          } else {
            this.geoCodeAndPlotAddress(googleMaps, this.addressModalData.address)
          }
        })
    },

    routeToAddress (googleMaps, from, to) {
      const directionsService = new googleMaps.DirectionsService()
      const directionsDisplay = new googleMaps.DirectionsRenderer()
      const map = new googleMaps.Map(document.getElementById('map-area'), { zoom: 8 })
      const request = {
        origin: from.replace(/<br>/g, ' '), // remove possible oldUI <br> tags instances may still have
        destination: to.replace(/<br>/g, ' '),
        travelMode: 'DRIVING'
      }
      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          directionsDisplay.setDirections(result)
          directionsDisplay.setPanel(document.getElementById('direction-details'))
        } else {
          this.mapError(status)
        }
        directionsDisplay.setMap(map)
      })
    },

    mapError (status) {
      if (status === 'ZERO_RESULTS' || status === 'NOT_FOUND') {
        this.$store.dispatch('showMsesage', {
          message: this.$i18n.t('aava.messages.map_not_found'),
          type: 'error'
        })
      } else {
        this.$store.dispatch('showMessage', {
          message: this.$i18n.t('aava.messages.map_generic_error'),
          type: 'error'
        })
      }
    },

    geoCodeAndPlotAddress (googleMaps, address) {
      const map = new googleMaps.Map(document.getElementById('map-area'), { zoom: 15 })
      const geocoder = new googleMaps.Geocoder()
      geocoder.geocode({ address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (status !== window.google.maps.GeocoderStatus.ZERO_RESULTS) {
            map.setCenter(results[0].geometry.location)
            const marker = new googleMaps.Marker({ position: results[0].geometry.location, map: map })
            const infowindow = new googleMaps.InfoWindow()
            infowindow.setContent(results[0].formatted_address)
            infowindow.open(map, marker)
          }
        } else {
          this.mapError(status)
        }
      })
    }
  }
}
</script>

<style>
.google-maps-container {
  width: 100%;
  display: flex;
}
#map-area {
  float: left;
  height: 100%;
  min-height: 400px;
}
#direction-details {
  float: left;
  width: 40%;
  height: 100%;
  min-height: 400px;
  padding: 10px;
  overflow: auto;
}
</style>
