import util from '../../utilities/sharedUtilities'
import apiRequests from './apiRequests'
import apiMultiEdit from './apiMultiEdit'
import apiListItem from './apiListItem'
import apiListItems from './apiListItems'
import apiBreakdownItems from './apiBreakdownItems'
import apiCardView from './apiCardView'
import apiNoSe from './apiNoSe'
import getApi from './getApi'
import saveApi from './saveApi'
import deleteApi from './deleteApi'
import sessionApi from './sessionApi'
import LPApi from './../../models/LayoutProfile/LP.api'
import LPIApi from './../../models/LayoutProfileItem/LPI.api'
import LCApi from './../../models/LayoutContainer/LC.api'
import LCFSApi from './../../models/LayoutContainerFieldSet/LCFS.api'
import hasManyApi from './../../components/Item/hasMany.api'
import dashboardItemAPI from '@/store/dashboardItems/api/dashboardItemAPI'
import { AxiosResponse } from 'axios'
import { Types } from '@/types/AppTypes'
import { LP } from '@/types/LP.types'
import { EventResponse } from '@/types/API.responses'

export default {
  ...apiRequests,
  ...apiMultiEdit,
  ...apiListItem,
  ...apiListItems,
  ...apiBreakdownItems,
  ...apiCardView,
  ...apiNoSe,
  ...getApi,
  ...saveApi,
  ...deleteApi,
  ...sessionApi,
  ...LPApi,
  ...LPIApi,
  ...LCApi,
  ...LCFSApi,
  ...hasManyApi,
  ...dashboardItemAPI,

  sendFlashMessageReceipt (message) {
    this.sendPostRequest('/gorilla/flash_message/read_receipt', {
      key: message.key,
    })
  },

  getHasManyListQueryColumns (modelName: string, parentId: string | number, hasManyFieldName: string): Promise<AxiosResponse> {
    return new Promise(resolve => {
      this.sendRequest('/gorilla/widget_columns/' + modelName + '/' + parentId + '/' + hasManyFieldName, [], [])
        .then((response: AxiosResponse) => {
          resolve(response)
        })
    })
  },

  sendApiActionCall (resource: string, identifier: string, params: Record<string, any>, parentItem: Types.Item | null = null): Promise<AxiosResponse> {
    if (parentItem) {
      params['@parent'] = parentItem
    }
    const url = '/api/' + resource + '/action/' + identifier
    return new Promise(resolve => {
      apiRequests.sendSimplePutRequest(url + '?q[]=created_at', params).then((response: AxiosResponse<EventResponse>) => {
        if (response.data.binary_content) {
          const pdf_newTab = window.open('')
          pdf_newTab?.document.write('<html><head><title>' + response.data.title +
            '</title></head><body><iframe style="border:none;"' +
            'width="100%" height="100%" src="data:' + response.data.mime_type + ', ' +
            response.data.binary_content +
            '"></iframe></body></html>')
        }
        resolve(response)
      })
    })
  },

  sendListActionCall (url: string): Promise<AxiosResponse> {
    return apiRequests.sendPostRequest(url, [])
  },

  sendListEventCall (url: string, ids: any[]): Promise<AxiosResponse> {
    return apiRequests.sendPutRequest(url, ids.map(id => {
      return {
        key: 'ids[]',
        value: id,
      }
    }), [])
  },

  getStoredReports (objectClass: string): Promise<AxiosResponse> {
    return this.sendRequest('/content/stored_reports/my/' + objectClass, [], [])
  },

  sendReportExportRequest (objectClass, reportFormat, filters, queries) {
    return this.sendRequest('/gorilla/export/' + objectClass + '.' + reportFormat, filters, queries)
  },

  updateLayoutContainerFieldSetsForFields (itemLayoutProfileId: number, fields: LP.Item[]): Promise<AxiosResponse> {
    const payload = { data: {} }
    fields.forEach(field => {
      if (field.layout_container_field_set?.id) {
        payload.data[field.id] = field.layout_container_field_set.id
      }
    })
    return this.sendPostRequestFormData('/gorilla/layout_profiles/' + itemLayoutProfileId + '/update_lcfs', payload)
  },

  loadHasManyListFor (className: string, fieldName: string, forClass: string, forId: string | number, queries: any, fetchCount: number): Promise<AxiosResponse> {
    const filters = [{
      key: 'existing',
      value: 'true'
    }, {
      key: 'limit',
      value: fetchCount
    }]
    return this.sendRequest('/api/' + util
      .objectClassUnderscoredName(className) + '/for/' + forClass + '/' +
        forId + '/' + fieldName, filters, queries
    )
  },

  getMetaInfo (objectClass: string): Promise<AxiosResponse> {
    let view = 'index'
    if (window.location.hash.includes('myProfile')) {
      view = 'my_profile'
    }
    return this.sendRequest('/gorilla/' + objectClass + '/meta/' + view, [], [])
  },
}
