<template>
  <v-container
    v-if="chart"
    ref="chartBlock"
    class="chart-block elevation-0"
    :style="'background: ' + chart.background"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <h2 :style="'color: ' + chart.titleColor">
          {{ title || chart.title }}
        </h2>
      </v-col>
      <v-col
        v-if="chart.chartInfo.type"
        :style="containerStyle"
      >
        <CustomBars
          v-if="chart.chartInfo.type === 'custom-bars'"
          class="kpi-block-number"
        />
        <MultiGauge
          v-else-if="chart.chartInfo.type === 'multi-gauge'"
          class="kpi-block-number"
        />
        <MultiGaugeWide
          v-else-if="chart.chartInfo.type === 'multi-gauge-wide'"
          class="kpi-block-number"
        />
        <WeeksChart
          v-else-if="chart.chartInfo.type === '2-weeks'"
          class="kpi-block-number"
        />
        <PeopleChart
          v-else-if="chart.chartInfo.type === 'people'"
          class="kpi-block-number"
        />
        <DashboardChart
          v-else-if="chart.chartInfo.type === 'chart.js'"
          :space-allocation="{
            box_height: (clientHeight - 60),
            box_width: (clientWidth - 40),
          }"
          :chart-data="{
            data: chart.chartInfo.chartData,
            options: chart.chartInfo.chartOptions,
            type: chart.chartInfo.chartType,
          }"
        />
        <div
          v-else
          class="kpi-block-number"
          style="font-size: 30px; color: grey;"
        >
          <svg-icon
            type="mdi"
            :path="path"
            :size="48"
            :style="'opacity: 0.45;transform: rotate(' + (chart.chartInfo.direction === 'up' ? 225 : 315) + 'deg);'"
            :color="chart.chartInfo.direction === 'up' ? 'green' : 'red'"
          />
          {{ chart.chartInfo.value }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiArrowDownBold } from '@mdi/js'
import CustomBars from './CustomBars'
import DashboardChart from '../DashboardChart'
import MultiGauge from './MultiGauge'
import PeopleChart from './PeopleChart'
import WeeksChart from './WeeksChart'
import MultiGaugeWide from './MultiGaugeWide'

export default {
  name: 'KPI_ModalChart',

  components: {
    MultiGaugeWide,
    CustomBars,
    DashboardChart,
    MultiGauge,
    PeopleChart,
    WeeksChart,
    SvgIcon,
  },

  data () {
    return {
      path: mdiArrowDownBold,
      clientHeight: 0,
      clientWidth: 0,
    }
  },

  props: {
    chart: {
      type: Object,
      default: () => {},
    },
    blockHeight: {
      type: Number,
      default: 180,
    },
    blockWidth: {
      type: Number,
      default: 300,
    },
    title: {
      type: String,
      default: '',
    },
  },

  mounted () {
    // Measure div to get dimensions for the chart
    this.clientHeight = this.$refs.chartBlock?.clientHeight
    this.clientWidth = this.$refs.chartBlock?.clientWidth
  },

  computed: {
    containerStyle () {
      const titleHeight = 30
      return {
        // position: 'relative',
        // height: (this.blockHeight - titleHeight) + 'px',
        // width: this.blockWidth + 'px',
      }
    },
  },
}
</script>

<style lang="scss">
.modal-view {
  .chart-block {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    border: 4px solid white;
    .chart_container {
      top: 50px;
      left: 20px;
      transform: inherit;
    }
  }
}
</style>
