<template>
  <div
    v-if="listHeaderDateFilterField"
    id="date-filter-menu"
    ref="list-date-filter"
    class="list-date-filter dialog-region popup-menu elevation-5"
    pa-2
  >
    <div
      v-for="(filterPeriod, index) in periodFilters"
      :key="index"
      :class="'period-filter-line' + (index % 2 ? '' : ' period-filter-line-odd')"
    >
      <div class="date-filter-cell-title">
        <v-toolbar-title class="list-date-filter-title">
          {{ $i18n.t('aava.index.relative_time.' + filterPeriod.periodName) }}
        </v-toolbar-title>
      </div>
      <div
        class="date-filter-number-container"
        @click="periodAgoClickHandler(index, $event)"
      >
        <div class="date-filter-number cell-right">
          <v-menu
            :id="'period-ago-' + filterPeriod.options[0]"
            :nudge-width="100"
            :z-index="160"
            attach
          >
            <template v-slot:activator="{ on }">
              <v-toolbar-title
                v-on="on"
                @click="hideDatePicker()"
              >
                <span>{{ selects[filterPeriod.options[0]] }}</span>
                <v-icon>fa-caret-down</v-icon>
              </v-toolbar-title>
            </template>
            <div class="popup-items-container">
              <div
                v-for="item in periodDropdownOptions"
                :key="item"
                class="popup-menu-el"
                @click="changePeriodAgoValue(index, item)"
              >
                <div v-text="item" />
              </div>
            </div>
          </v-menu>
        </div>
        <div class="date-filter-number-title">
          {{ $i18n.t('aava.index.relative_time.' + filterPeriod.options[0], { amount: '' }) }}
        </div>
      </div>
      <div
        v-for="key in [1,2,3]"
        :key="key"
        class="date-filter-cell"
        @click="setDateFilter(filterPeriod.options[key])"
      >
        {{ $i18n.t('aava.index.relative_time.' + filterPeriod.options[key]) }}
      </div>
      <div
        class="date-filter-number-container"
        @click="periodFutureClickHandler(index, $event)"
      >
        <div class="date-filter-number cell-right">
          <v-menu
            :id="'period-future-' + filterPeriod.options[4]"
            :nudge-width="100"
            :z-index="160"
            attach
          >
            <template v-slot:activator="{ on }">
              <v-toolbar-title
                v-on="on"
                @click="hideDatePicker()"
              >
                <span>{{ selects[filterPeriod.options[4]] }}</span>
                <v-icon>fa-caret-down</v-icon>
              </v-toolbar-title>
            </template>
            <div class="popup-items-container">
              <div
                v-for="item in periodDropdownOptions"
                :key="item"
                class="popup-menu-el"
                @click="changePeriodFutureValue(index, item)"
              >
                <div v-text="item" />
              </div>
            </div>
          </v-menu>
        </div>
        <div class="date-filter-number-title">
          {{ $i18n.t('aava.index.relative_time.' + filterPeriod.options[4], { amount: '' }) }}
        </div>
      </div>
    </div>

    <v-layout
      px-3
      py-3
    >
      <DateTime
        v-model="rangeStartDateTime"
        :data="{ blurFieldName: 'rangeStartDateTime' }"
        default-time="00:00"
        :date-only="dateOnly"
      />
      <div class="start-end-date-separator">
        -
      </div>
      <DateTime
        v-model="rangeEndDateTime"
        :data="{ blurFieldName: 'rangeEndDateTime' }"
        default-time="23:59"
        :date-only="dateOnly"
      />
      <div class="search-button-container">
        <v-btn
          fab
          small
          dark
          color="green"
          class="elevation-0"
          @click="filterByDateRange()"
        >
          <v-icon small>
            fa-search
          </v-icon>
        </v-btn>
      </div>
    </v-layout>
  </div>
</template>

<script>
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import DateTime from '../Form/DateTime'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'HeaderColumnDateFilterMenu',

  components: {
    DateTime,
  },

  data () {
    return {
      select: '?',
      rangeStartDateTime: null,
      rangeEndDateTime: null,
      periodDropdownOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      justNowBlurredField: null,
      selects: {
        days_ago: 2,
        weeks_ago: 2,
        months_ago: 2,
        years_ago: 2,
        in_days: 2,
        in_weeks: 2,
        in_months: 2,
        in_years: 2
      },
      periodFilters: [
        {
          periodName: 'day',
          options: ['days_ago', 'yesterday', 'today', 'tomorrow', 'in_days']
        },
        {
          periodName: 'week',
          options: ['weeks_ago', 'last_week', 'this_week', 'next_week', 'in_weeks']

        },
        {
          periodName: 'month',
          options: ['months_ago', 'last_month', 'this_month', 'next_month', 'in_months']

        },
        {
          periodName: 'year',
          options: ['years_ago', 'last_year', 'this_year', 'next_year', 'in_years']
        }
      ]
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    dateOnly () {
      const field = this.layoutProfileItems
        .find(field => field.name === this.listHeaderDateFilterField)
      return !!(field && field.type === 'date')
    }
  },

  created () {
    // Take start+end dates if range was already defined
    if (this.listFilters[this.listHeaderDateFilterField] &&
      this.listFilters[this.listHeaderDateFilterField].includes('..')) {
      [this.rangeStartDateTime, this.rangeEndDateTime] = this.listFilters[
        this.listHeaderDateFilterField
      ].split('..')
    }
    document.addEventListener('click', this.clickListener)
  },

  destroyed () {
    document.removeEventListener('click', this.clickListener)
  },

  methods: {
    ...methods,

    blurHandler (data) {
      this.justNowBlurredField = data.blurFieldName
    },

    filterByDateRange () {
      if (!this.rangeStartDateTime && !this.rangeEndDateTime) {
        // Empty filter
        this.$store.dispatch('setDateFilter', {
          filterField: this.listHeaderDateFilterField,
          filterValue: '',
        })
      } else {
        this.$store.dispatch('setDateFilter', {
          filterField: this.listHeaderDateFilterField,
          filterValue: (this.rangeStartDateTime || '') + '..' +
            (this.rangeEndDateTime || '')
        })
        // Put focus back so that user can pin filter
        this.focusedField = this.listHeaderDateFilterField
      }
      this.listHeaderDateFilterField = null
      this.$nextTick(() => {
        this.reloadListItems()
      })
    },

    hideDatePicker () {
      this.showDatePickerMenu = false
    },

    periodAgoClickHandler (index, e) {
      const period = this.periodFilters[index]
      const el = document.getElementById('period-ago-' + period.options[0])
      if (!el || (el && !el.contains(e.target))) {
        this.showPeriodAgoItems(period)
      }
    },

    showPeriodAgoItems (period) {
      const translatedValue = this.selects[period.options[0]] +
        this.$i18n.t('aava.index.relative_time.' + period.options[0], { amount: '' })
      if (this.justNowBlurredField) {
        this[this.justNowBlurredField] = translatedValue
        return
      }
      this.$store.dispatch('setDateFilter', {
        filterField: this.listHeaderDateFilterField,
        filterValue: translatedValue
      })
      // Put focus back so that user can pin filter
      this.focusedField = this.listHeaderDateFilterField
      this.listHeaderDateFilterField = null
      this.$nextTick(() => {
        this.reloadListItems()
      })
    },

    changePeriodAgoValue (index, value) {
      const period = this.periodFilters[index]
      this.selects[period.options[0]] = value
      this.showPeriodAgoItems(period)
    },

    periodFutureClickHandler (index, e) {
      const period = this.periodFilters[index]
      const el = document.getElementById('period-future-' + period.options[4])
      if (!el || (el && !el.contains(e.target))) {
        this.showPeriodFutureItems(period)
      }
    },

    showPeriodFutureItems (period) {
      const translatedValue = this.selects[period.options[4]] +
        this.$i18n.t('aava.index.relative_time.' + period.options[4], { amount: '' })
      if (this.justNowBlurredField) {
        this[this.justNowBlurredField] = translatedValue
        return
      }
      this.$store.dispatch('setDateFilter', {
        filterField: this.listHeaderDateFilterField,
        filterValue: translatedValue
      })
      // Put focus back so that user can pin filter
      this.focusedField = this.listHeaderDateFilterField
      this.listHeaderDateFilterField = null
      this.$nextTick(() => {
        this.reloadListItems()
      })
    },

    changePeriodFutureValue (index, value) {
      const period = this.periodFilters[index]
      this.selects[period.options[4]] = value
      this.showPeriodFutureItems(period)
    },

    setDateFilter (filterValue) {
      const translatedValue = this.$i18n.t('aava.index.relative_time.' + filterValue)
      if (this.justNowBlurredField) {
        this[this.justNowBlurredField] = translatedValue
        return
      }
      this.$store.dispatch('setDateFilter', {
        filterField: this.listHeaderDateFilterField,
        filterValue: translatedValue
      })
      // Put focus back so that user can pin filter
      this.focusedField = this.listHeaderDateFilterField
      this.listHeaderDateFilterField = null
      this.$nextTick(() => {
        this.reloadListItems()
      })
    },

    clickListener (e) {
      if (this.showDatePickerMenu) { return }
      if (this.listHeaderDateFilterField) {
        const el = this.$refs['list-date-filter']
        const el2 = document.getElementById(this.listHeaderDateFilterField + '_th')
        if (el && el2 && !el.contains(e.target) && !el2.contains(e.target)) {
          this.listHeaderDateFilterField = null
        }
      }
    }
  }
}
</script>

<style lang="less">
.period-filter-line {
  line-height: 40px;
  height: 40px;
  background: #f5f5f5;
  .v-menu__content {
    z-index: 1090 !important; /* Ensure menu is on top of date picker */
  }
}
.period-filter-line-odd {
  background: white;
}
.cell-left {
	text-align: left;
}
.cell-right {
	text-align: right;
}
.date-filter-cell-title {
	width: 12%;
  float: left;
  padding-left: 15px;
	.v-toolbar__title {
		font-weight: bold;
	}
}
.date-filter-number-container, .date-filter-cell {
  &:hover {
    background: #eee;
    cursor: pointer;
    border-radius: 6px;
    -webkit-box-shadow:inset 0px 0px 0px 3px #FF9800;
    -moz-box-shadow:inset 0px 0px 0px 3px #FF9800;
    box-shadow:inset 0px 0px 0px 3px #FF9800;
  }
}

.date-filter-number-container {
	width: 20%;
  float: left;
}
.date-filter-cell {
  float: left;
	width: 16%;
	text-align: center;
	height: 40px;
	line-height: 40px;
}
.date-filter-number {
	width: 20%;
	float: left;
  height: 40px;
	line-height: 40px;
}
.date-filter-number-title {
	width: 80%;
	float: left;
	text-align: left;
  height: 40px;
	line-height: 40px;
	padding: 0 0 0 10px;
}
.list-date-filter {
	position: absolute;
	z-index: 1050;
	width: 750px;
	background: white;
	.filter-cell {
		display: table-cell;
		vertical-align: middle;
		padding: 0px 5px;
		height: 31px;
		line-height: 34px;
		text-align: center;
	}
	input {
		padding: 5px;
		height: 28px;
	}
	.input-group-btn {
		width: 100%;
	}
	.v-toolbar__content {
		padding: 0 5px 0 15px !important;
		height: 40px !important;
	}
	.v-toolbar__title {
		.v-icon {
			font-size: 12px !important;
      padding-left: 3px;
		}
    line-height: inherit !important;
		font-size: 12px !important;
	}
	.v-btn__content {
		font-size: 11px !important;
	}
	.v-toolbar__items {
		.v-btn {
			padding: 0 4px !important;
		}
	}
}
.range-cell {
	display: table-cell;
	vertical-align: middle;
	padding: 0px 5px;
}
.date-filter-input {
	width: 100%;
	float: left;
}
.filter-right {
	float: right;
}
.filter-center-buttons {
	text-align: center;
}
.filter-button {
	margin: 5px;
}
.filter-days-input {
	.v-input__control {
		height: 31px !important;
		min-height: 31px !important;
	}
}
.list-date-filter-title {
	font-size: 14px;
}
.v-toolbar__extension {
	padding: 0 5px 0 15px !important;
}
.start-end-date-separator {
	padding: 0 10px;
	height: 31px;
	line-height: 31px;
}
.search-button-container {
	padding-left: 10px;
	height: 31px;
}
</style>
