import axios, { AxiosError, AxiosResponse } from 'axios'
import { DashboardItem, DashboardItemsR, DashboardOptionsR, DashboardTypes } from '../../../types/DashboardTypes'
import { networkErrorAxiosResponse } from '@/store/api/apiRequests'

export default {
  fetchDashboardItems (): Promise<AxiosResponse<DashboardItemsR>> {
    return this.sendRequest('/gorilla/dashboard_items/my', {}, [])
  },

  getDashboardItemsContent (dashboardItems: DashboardItem[]) {
    return new Promise(resolve => {
      const promises = dashboardItems.map(dashboardItem => {
        if (['preview', 'chart'].includes(dashboardItem.element_type!)) {
          return this.getDashboardItemContent(dashboardItem)
        }
        return dashboardItem
      })
      Promise.all(promises).then(function (results) {
        resolve(results)
      })
    })
  },

  // Get preview objects for one Layout Profile
  getDashboardItemContent (dashboardItem: DashboardItem): Promise<DashboardItem> {
    return new Promise(resolve => {
      this.sendRequest('/gorilla/dashboard_items/' + dashboardItem.id, {}, [])
        .then((response: AxiosResponse) => {
          dashboardItem.previewData = response?.data?.data || {}
          resolve(dashboardItem)
        })
    })
  },

  // Save order of Dashboard Items
  saveNewOrder (dashboardItems: DashboardItem[], shortcuts: DashboardTypes.Shortcut[]) {
    const params = new URLSearchParams()
    dashboardItems.forEach((dashboardItem) => {
      if (dashboardItem) {
        params.append('order[]', dashboardItem.id!?.toString())
      }
    })
    shortcuts.forEach((shortcut) => {
      if (shortcut) {
        params.append('order[]', shortcut.id!?.toString())
      }
    })
    return axios.post('/gorilla/dashboard_items/sort', params, this.requestOptions())
  },

  saveTabsOrder (tabs: DashboardTypes.Tab[]) {
    const params = new URLSearchParams()
    tabs.forEach(tab => {
      params.append('order[]', tab.id!?.toString())
    })
    return axios.post('/gorilla/dashboard_items/sort', params, this.requestOptions())
  },

  // Deletes Dashboard Item
  deleteDashboardItem (itemId: number) {
    return this.deleteItem('dashboard_items', itemId)
  },

  gorillaUpdateDashboardItem (id: number, payload: DashboardTypes.PUTPayload): Promise<AxiosResponse> {
    return new Promise(resolve => {
      axios.put('/gorilla/dashboard_items/' + id, payload).then((response) => {
        resolve(response)
      }).catch((err: AxiosError) => { // error
        resolve(err.response || networkErrorAxiosResponse(err))
      })
    })
  },

  gorillaAddDashboardItem ({ type, name, layoutProfileId, key, value, config }: DashboardTypes.OneOf): Promise<AxiosResponse> {
    return new Promise(resolve => {
      let payload = {}
      if (type === 'option') {
        payload = payload = { type: 'option', key, value }
      } else if (type === 'preview') {
        payload = { type: 'preview', layout_profile_id: layoutProfileId, config }
      } else if (type) {
        payload = { type, element_action: name, config }
      }
      axios.post('/gorilla/dashboard_items', payload).then((response) => {
        resolve(response)
      }).catch((err: AxiosError) => { // error
        resolve(err.response || networkErrorAxiosResponse(err))
      })
    })
  },

  getAvailableItems (): Promise<AxiosResponse<DashboardOptionsR>> {
    return this.sendRequest('/gorilla/dashboard_items/', {}, [])
  },

  getItemSummary (object_class: string, id: number): Promise<string> {
    return new Promise((resolve) => {
      if (!object_class || !id) {
        resolve('')
      } else {
        axios.get('/api/' + object_class + '/' + id + '?q[]=summary', this.requestOptions()).then((response) => {
          let summary = ''
          if (response.data && response.data.item && response.data.item.summary) {
            summary = response.data.item.summary
          }
          resolve(summary)
        }).catch(() => { // error
          resolve('')
        })
      }
    })
  }
}
