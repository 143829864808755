
import Vue from 'vue'
import util from '../../utilities/sharedUtilities'
import methods from './../methods'
import listQueries from '@/store/_listQueries'
import api from './../../store/api'

import HasManyItem from './HasManyItem.vue'

import state from './../../store/state'
import { createHelpers } from 'vuex-map-fields'
import Confirm from '@/methods/confirm'
import itemSaveQueueMethods from '@/methods/item/itemSaveQueueMethods'
import { HasManyExtendedField, LP } from '@/types/LP.types'
import { HasManyBlockButton, Types } from '@/types/AppTypes'
import { AxiosResponse } from 'axios'
import { ItemsResponse } from '@/types/API.responses'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'HasManyItems',

  components: {
    HasManyItem,
  },

  props: {
    total: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
    field: {
      type: Object as () => LP.Item,
      required: true,
    },
    parentItem: {
      type: Object as () => Types.Item,
      required: true,
    },
    parentResource: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    layoutEditMode: {
      type: Boolean,
      default: false,
    },
    availableSpaceInFieldSet: {
      type: Number,
      default: 23,
    },
    showItemModal: {
      type: Function,
      default: () => {},
    },
    modal: {
      type: Boolean,
      default: false,
    },
    updateItemAfterSelectingNewHasManyChild: {
      type: Function,
      default: () => {},
    },
    updateItemOnDefaultsForChange: {
      type: Function,
      default: () => {},
    },
    unfinishedDfcCount: {
      type: Number,
      default: 0,
    },
  },

  data () {
    return {
      items: [] as Types.Item[],
      amc: {} as LP.AMC,
      columns: [],
      loadingItems: true,
      loadingAllItems: false,
      loadingNewFor: null,
      listQueries: [],
      autofocusLastItemFirstTextField: false,
      focusedRowIndex: 0,
      addQueue: [],
      reloadWhenItemSaveQueueIsEmpty: false,
      fieldsPickedByItemDesigner: [],
      fixedWithsByItemDesigner: {},
      indeterminateProgress: false,
      layoutChangeTimer: null,
      // Temporary hold the field name to use when auto-filling reference field value
      // for new has-many item which was picked from the item picker (feature add_multi_by_reference)
      referenceFieldNameToAutofillFromItemPicker: '',
      intervalId: null, // To execute button click when DFCs are done
      debug: false,
      buttonIcons: {
        add: 'link',
        add_new: 'external-link-alt',
        add_nested: 'plus',
        add_multi_by_reference: 'square-check',
        add_modal: 'plus-square',
        copy_nested: 'copy',
      },
    }
  },

  watch: {
    parentId () {
      this.initialize()
    },

    reloadWhenItemSaveQueueIsEmpty (val) {
      if (!val) {
        console.log('-s-e-t-:::::::', false)
        return
      }
      this.whenQueueIsEmpty().then(() => {
        console.log('_______reloadWhenItemSaveQueueIsEmpty', val, this.field.name)
        this.initialize()
      })
    },

    layoutChangeTrigger () {
      clearTimeout(this.layoutChangeTimer)
      this.layoutChangeTimer = setTimeout(() => {
        this.saveHasManyLayout()
      }, 2000)
    },

    loadingItems (val) {
      if (val) {
        // When small form and loading very fast
        // No need to flash the loader in this case
        setTimeout(() => {
          if (this.loadingItems) {
            this.indeterminateProgress = true
          }
        }, 1000)
        return
      }
      this.indeterminateProgress = false
    },
  },

  created () {
    this.$store.dispatch('getAttributeMetadata', this.resource).then(amc => {
      this.amc = amc
      this.initHasManyLayoutProps()
      this.initialize()
    })
  },

  computed: {
    // TODO refactoring. When e2e tests are ready, start filtering which state arrays to use here. does not need all
    ...mapFields(Object.keys(state)),

    layoutChangeTrigger () {
      return JSON.stringify(this.fieldsPickedByItemDesigner) + '_' + JSON.stringify(this.fixedWithsByItemDesigner)
    },

    count () {
      const arrayCountFromParent = (Array.isArray(this.parentItem[this.field.name]) ? this.parentItem[this.field.name].length : 0)
      return this.edit
        ? this.items.length
        : this.parentItem[this.field.name]?.[0]?.count || // When items are not returned within the parent item request
        arrayCountFromParent // Items already present, ex. for 'files'
    },

    isPreventWriteMember () {
      if (!this.parentItem['@prevent_write_members']) {
        return
      }
      return this.parentItem['@prevent_write_members'].includes(this.field.name)
    },

    totalCount () {
      return this.parentItem[this.field.name] && this.parentItem[this.field.name][0] && this.parentItem[this.field.name][0].count || 0
    },

    editComputed () {
      return this.edit
      // return this.edit && !this.field.dynamic
    },

    buttonsComputed (): HasManyBlockButton[] {
      if (!this.field.buttons) { return [] }
      return this.field.buttons
        .filter((button: HasManyBlockButton) => {
          // In modal disabled nested option as can't open modal on top of another modal
          if (this.modal && button.button === 'add_modal') {
            return false
          }
          // Disable adding new child in new window when parent item is not saved yet (has no id)
          // Just does not work. Didn't work in old ui as well - logical error
          if (!this.parentItem.id && button.button === 'add_new') {
            return false
          }
          if (!this.edit && ['none', 'edit'].includes(button.hide_on)) {
            return true
          } else if (this.edit && ['none', 'show', null].includes(button.hide_on)) {
            return true
          }
          return false
        })
    },

    columnsByName () {
      // To add column data to field easily, like static list options
      const byName = {}
      this.columns.forEach(column => {
        byName[column.name] = column
      })
      return byName
    },

    itemsComputed () {
      if (this.layoutEditMode) {
        if (this.items?.[0]) { // First item for layout editor
          return [this.items[0]]
        }
        return [{}] // empty object for layout editor
      }
      return this.items
    },

    fieldsComputed (): HasManyExtendedField[] {
      const columns = this.fieldsPickedByItemDesigner?.length
        // Columns have been selected by using the item designer - the new way
        ? this.fieldsPickedByItemDesigner
        // Fields set by attribute metadata by object modelling in the old/default way
        : this.columns
      return this.completeFieldsWithAttributeMetadataInfo(this.filteredFieldsByHideOn(columns))
    },

    // Parent item without dynamic fields
    // used for has-many row reference item-picker query
    // back-end requires full parent item as payload
    parentItemWithoutDynamicFields () {
      const item = {}
      const amc = this.amByModel[util.objectClassUnderscoredName(this.parentItem['@class'])]
      Object.keys(this.parentItem).filter(fieldName => {
        return !amc?.[fieldName]?.dynamic
      }).forEach(fieldName => {
        item[fieldName] = this.parentItem[fieldName]
      })
      return item
    },

    filters () {
      // Set order, if not, set default order
      // Preferred would be 'created_at desc', but api does not like that,
      // for edit view have to send 'existing=temp', which prevents any sorting.
      // Therefore, has to be asc for now, until api is changed.
      const order = this.field.order || 'created_at asc'
      if (this.edit) {
        return {
          existing: 'temp',
          order,
        }
      }
      return {
        existing: 'true',
        order,
      }
    },

    resource () {
      return util.objectClassUnderscoredName(this.field.reference_class)
    },

    parentId () {
      return this.parentItem && (this.parentItem.token || this.parentItem.id) || null
    },
  },

  methods: {
    ...methods,
    ...itemSaveQueueMethods,

    requestReloadWhenItemSaveQueueIsEmpty () {
      this.reloadWhenItemSaveQueueIsEmpty = true
    },

    // Filter fields by hide_on for specific views (show/edit)
    // Gorilla service widget_columns gives information when some fields are hidden
    filteredFieldsByHideOn (fields) {
      return fields.slice().filter(field => {
        return !field.hide_on || this.layoutEditMode || !(field.hide_on === (this.edit ? 'edit' : 'show'))
      })
    },

    // Use fields from has_many_layout and improve with attribute metadata
    // So we have columns which are usable for columns selector
    completeFieldsWithAttributeMetadataInfo (fields: LP.Item[]): HasManyExtendedField[] {
      return fields.map((field: LP.Item) => {
        const fieldInfo = this.amc[field.name] || field as LP.Item
        fieldInfo.name = fieldInfo.name || fieldInfo.attribute_name
        fieldInfo.type = fieldInfo.type || fieldInfo.attribute_type
        fieldInfo.select_columns = field.select_columns
        fieldInfo.inputOptions = field.input_options || {}
        if (this.columnsByName[field.name] && this.columnsByName[field.name].items) {
          fieldInfo.items = this.columnsByName[field.name].items
        }
        // Use editable_in_list from field data got from JSON (has_many_layout)
        fieldInfo.editable_in_list = field.editable_in_list
        return fieldInfo
      })
    },

    showColumnsMenu () {
      if (this.showListColumnsSelector || !this.field.reference_class) {
        this.showListColumnsSelector = false
        return
      }
      const objectClass = util.objectClassUnderscoredName(this.field.reference_class)
      this.$store.dispatch('loadLayoutProfiles', { objectClass }).then((layoutProfiles: LP.LayoutProfile[]) => {
        // Does not matter which LP id to use, using first one.
        // All return the same items, only visibility etc is different, which does not matter here.
        const firstId = layoutProfiles[0]?.id
        if (!firstId) { return }
        api.getLayoutProfileItems(firstId)
          .then((itemsResponse: AxiosResponse) => {
            const fields = itemsResponse.data.items || []
            const itemsPreparedForColumnsSelector = [] as HasManyExtendedField[]
            fields.forEach((field: HasManyExtendedField) => {
              const indexInDefault = this.fieldsComputed.map(f => f.name).indexOf(field.name)
              // Get index in the default list, to sort by it
              field.visible = indexInDefault > -1
              // Set field props from has_many_layout as in has-many list we do not use layout profile items
              // and have this field to store layout settings in JSON
              if (indexInDefault > -1) {
                field.editable_in_list = this.fieldsComputed[indexInDefault].editable_in_list
                field.sort_order = indexInDefault
              }
              itemsPreparedForColumnsSelector.push(field)
            })
            itemsPreparedForColumnsSelector.sort((a, b) => a.index < b.index ? -1 : 1)
            this.columnsSelectorProps = {
              layoutProfileItems: JSON.parse(JSON.stringify(itemsPreparedForColumnsSelector)),
              objectClass,
              previewItemId: this.items?.[0]?.id,
              forHasMany: true,
              callback: this.saveColumns,
            }
            this.showListColumnsSelector = true
          })
      })
      // Get first LP for that model and including LP items
    },

    saveColumns ({ columns }) {
      this.fieldsPickedByItemDesigner = columns.filter(column => column.visible)
      this.initialize()
    },

    updateHasManyParentItemOnDefaultsForChange () {
      // Force update always, as currently @observed_members does not include has-many list field
      // Would be good if it had, then don't have to always update parent item when something changes in has-many row
      // that triggers DFC request
      this.updateItemOnDefaultsForChange(this.field, true)
    },

    moveFocusTo ({ rowIndex, colIndex }) {
      const targetEl = this.$refs.hasManyItem?.[rowIndex]?.$refs?.hasManyItemField?.[colIndex]?.$el
      if (!targetEl) { return }
      const inputEl = targetEl.querySelector('input')
      if (inputEl) {
        inputEl.focus()
        this.setFocusedRowIndex(rowIndex)
      }
    },

    setFocusedRowIndex (index: number) {
      this.focusedRowIndex = index
    },

    openHasManyItemsInNewWindow () {
      window.open(window.location.origin + window.location.pathname +
        '#/' + this.resource + '/for/' + this.parentResource + '/' +
        this.parentItem.id + '/' + this.field.name, '_blank')
    },

    openAttributeMetadata (metadataId) {
      window.open(window.location.origin + window.location.pathname + '#/attribute_metadatas/' + metadataId + '/edit', '_blank')
    },

    deleteHasManyItemById (itemId: number | string) {
      const index = this.items.map(item => item && item.id).indexOf(itemId)
      this.deleteHasManyItem(index)
    },

    deleteHasManyItem (index: number) {
      Vue.delete(this.items, index)
      const currentCount = (this.parentItem[this.field.name][0]?.count) || 0
      Vue.set(this.parentItem, this.field.name, [{ count: currentCount - 1 }])
    },

    deleteHasManyItemWithWarning (index: number) {
      Confirm.request(this.$i18n.t('aava.confirmations.destroy_objects'), () => {
        this.deleteHasManyItem(index)
        this.whenQueueIsEmpty().then(() => {
          this.updateHasManyParentItemOnDefaultsForChange()
        })
      })
    },

    setHasManyItemInList (index: number, item: Types.Item) {
      Vue.set(this.items, index, item)
    },

    initialize ({ loadAll } = { loadAll: false }) {
      return new Promise(resolve => {
        if (!this.parentId) { // this.layoutEditMode || - Now also need example data for layout editor
          this.items = []
          this.loadingItems = false
          return resolve(null)
        }
        if (!loadAll) {
          this.loadingItems = true
        }
        // Get parent item attribute metadata (needed for item picker as targetObject, to filter out dynamic attributes)
        // Parent item has not retrieved it's am itself, as it gets all needed info from LP-items
        // Actually LP-items should also have this info, but easier to use attribute-metadata in various places
        // rather than passing LP-items to children and it's children
        this.$store.dispatch('getAttributeMetadata', this.parentItem['@class']).then(() => { // Will be used later
          // Get model attribute metadata first
          this.queryColumns().then(() => {
            // Then complete queries for every field by its type
            listQueries.getQueriesForHasManyItems(this.columns, {
              amc: this.amc,
              edit: this.edit,
              locale: this.locale,
              view: 'hasManyItemEdit',
            }).then((listQueries: string[]) => {
              const filters = this.filters
              filters.limit = this.layoutEditMode ? 1 : loadAll ? 10000 : 20
              this.listQueries = listQueries
              this.getHasManyItems(this.resource, this.parentResource,
                this.parentId, this.field.name,
                filters,
                listQueries
              ).then((response: AxiosResponse<ItemsResponse>) => {
                this.reloadWhenItemSaveQueueIsEmpty = false
                this.$store.dispatch('globalErrorDisplay', { response, context: 'Has-many list loading for ' + this.resource })
                if (response.data) {
                  this.items = response.data.items?.map(item => {
                    // Set parent item as target object to be used for has-many row item picker query
                    if (this.edit) { // For actual row edit only, otherwise in has-many show mode list edit reference dropdowns do not work
                      item.targetObject = this.parentItemWithoutDynamicFields
                    }
                    return item
                  }) || []
                  this.loadingItems = false
                  this.loadingAllItems = false
                  this.focusedRowIndex = this.itemsComputed.length - 1
                }
                resolve(null)
              })
            })
          })
        })
      })
    },

    loadAllHasManyItems () {
      this.loadingAllItems = true
      this.initialize({ loadAll: true })
    },

    queryColumns () {
      return new Promise(resolve => {
        // A) Use widget gorilla service to get columns info
        if (this.field.columns === 'query_columns') {
          api.getHasManyListQueryColumns(this.parentItem['@class'], this.parentItem.id, this.field.name)
            .then((response: AxiosResponse) => {
              this.$store.dispatch('globalErrorDisplay', { response, context: 'Get widget columns' }).then()
              const widgetColumns = response.data.columns || []
              // A.1) When items were picked by layout designer, use these columns
              // But still need to get information from gorilla service which columns to hide in which fields
              if (this.fieldsPickedByItemDesigner?.length > 0) {
                const hideOnByName = {}
                widgetColumns.filter(col => !!col.hide_on).forEach(col => {
                  hideOnByName[col.name] = col.hide_on
                })
                this.columns = this.fieldsPickedByItemDesigner.map(col => {
                  col.hide_on = hideOnByName[col.name]
                  return col
                })
                resolve(true)
              } else {
              // A.2) Use widget columns as they are
                this.columns = widgetColumns
                resolve(true)
              }
            })
        } else if (this.fieldsPickedByItemDesigner?.length > 0) {
          // B) Use columns from layout designer
          this.columns = this.fieldsPickedByItemDesigner
          resolve(true)
        } else if (Array.isArray(this.field.columns)) {
          // C) API has returned array of columns to show, from attribute metadata, no widths are set
          this.columns = this.field.columns
          if (this.field.columns.length === 0) {
            this.columns = [{
              name: 'summary',
              type: 'string',
            }]
          }
          resolve(true)
        } else {
          this.columns = []
          resolve(true)
        }
      })
    },

    // Handle user button click
    // Call executeButtonClick when DFCs are completed
    buttonClick (button, e) {
      clearInterval(this.intervalId)

      // Show loading btn immediately, even if executing code later
      this.loadingNewFor = button.button

      // Add important delay here, as there is a delay on user input change to trigger DFC
      // Do not want to execute code below if there may be change event triggered
      setTimeout(() => {
        this.intervalId = setInterval(() => {
          this.executeButtonClick(button, e)
        }, 100)
      }, 200) // Text field has 1000 ms, but not likely to be issue here with text fields
    },

    executeButtonClick (button, e = null) {
      // console.log('try...', this.unfinishedDfcCount)
      if (this.loadingItems) { // To be sure
        clearInterval(this.intervalId)
        return
      }

      // Cancel this run when DFCs in progress
      if (this.unfinishedDfcCount > 0) {
        return
      }

      clearInterval(this.intervalId)
      this.referenceFieldNameToAutofillFromItemPicker = ''
      switch (button.button) {
        case 'add_new':
          this.addNewRow()
          break
        case 'add_nested':
          this.addNewNestedRow({})
          break
        case 'copy_nested':
          this.copyNestedRowAsNew()
          break
        case 'add_modal':
          this.addModalRow()
          break
        case 'add_multi_by_reference':
          // Set which field will be autofilled when user picks new item from the item picker
          this.referenceFieldNameToAutofillFromItemPicker = button.reference
          this.addMultiByReferenceField(e)
          break
        default:
          this.addRow(e)
      }
    },

    // Add new in new window
    addNewRow () {
      if (!this.parentItem.id) { return }
      const path = '/' + this.resource + '/new/for/' +
        this.parentResource + '/' +
        this.parentItem.id + '/' + this.field.name
      this.$router.push({ path })
      // Also cancel the loader
      // Because when adding item in new window, user will lose existing form changes and has to confirm that
      // If user cancels route push, then is important to stop loader
      this.loadingNewFor = null
    },

    addNewNestedRow ({
      // Used when user has focus in existing has-many row and wants to copy it
      copyFromIdOrToken = false,
      // When selecting multiple items from the popup of one field possible values, don't need to focus
      setFocusAfter = true,
      // When picking from the popup of one field possible values, also need to fill that field value for newly added has-many item
      // Aava-Vue-521 feature
      autoFillData = {},
    }): Promise<Types.Item | null> {
      return new Promise(resolve => {
        if (!this.parentId) { return resolve(null) }
        api.fetchTokenFor({
          objectClass: this.parentResource,
          referenceClass: this.resource,
          objectToken: this.parentId,
          referenceField: this.field.name,
          queries: this.listQueries,
          copyFromIdOrToken,
        }).then((tokenResponse: AxiosResponse) => {
          this.$store.dispatch('globalErrorDisplay', { response: tokenResponse, context: 'Token for new has-many nested' })
          if (tokenResponse.data.item) {
            let item = tokenResponse.data.item
            Object.keys(autoFillData).forEach(key => {
              item[key] = autoFillData[key]
            })
            // TODO refactor
            let isObservedMember = false
            const changedFieldName = Object.keys(autoFillData)[0]
            if (Object.keys(autoFillData).length) {
              isObservedMember = item['@observed_members'].includes(changedFieldName)
            }
            if (Object.keys(autoFillData).length && isObservedMember) {
              // ---- //
              this.getFormItemOnDefaultsForChange(util.objectClassUnderscoredName(this.field.reference_class),
                item.token,
                true,
                {
                  targetResource: this.parentResource,
                  targetId: this.parentItem.token || this.parentItem.id,
                  field: changedFieldName,
                  item: this.getItemSavePayload(item, this.amc),
                  targetField: this.field.name,
                  queries: this.listQueries,
                })
                .then((response: AxiosResponse | false) => {
                  if (response && response.data?.item) {
                    item = response.data.item
                    // Add changed field to the response as this is not returned by the API
                    // item[changedFieldName] = this.item[changedFieldName]
                    // Set targetObject for ~path query to work
                    if (this.edit) {
                      item.targetObject = this.parentItemWithoutDynamicFields
                    }
                  }
                  this.addNewItemToStore(item).then(() => {
                    resolve(item)
                  })
                })
              // ---- //
            } else {
              this.addNewItemToStore(item).then(() => {
                resolve(item)
              })
              if (setFocusAfter) {
                this.setFocusToNewlyAddedRow()
              }
            }
          } else {
            // Release add-new-nested btn
            this.loadingNewFor = null
            resolve(null)
          }
          // this.focusedRowIndex = this.itemsComputed.length - 1
        })
      })
    },

    // Get last has-many row
    // Get focusable fields
    // Use focus_column_name if set, if not, then first (not read only)
    setFocusToNewlyAddedRow () {
      const focusColumnName = this.field.focus_column_name
      // For not yet discovered reason, when adding new has-many row into an empty list,
      // it takes 4 ticks (this.$nextTick) before <FormField> is created
      // Even though all the data is present to create that component at the same tick
      // When deleting first row and adding a new row, it's created instantly
      // Using setTimeout here for just visual preference
      setTimeout(() => {
        const lastRow = this.$refs.hasManyItem?.pop()
        const fieldComponents = lastRow?.$refs?.hasManyItemField || []
        const focusableFields = fieldComponents
          .filter(fieldComponent => {
            if (fieldComponent.readOnlyComputed) { // Can't focus when disabled
              return false
            }
            return focusColumnName === fieldComponent.field.name || // Set focus to specified column (focus_column_name)
              (!focusColumnName && this.focusableFieldTypes.includes(fieldComponent.field?.type))
          })
        const firstFocusableInput = focusableFields?.[0]?.$children?.[0]?.$refs?.inputRef
        if (!firstFocusableInput) { return }
        firstFocusableInput.focus()
      }, 5)
    },

    copyNestedRowAsNew () {
      if (this.unfinishedDfcCount > 0) {
        this.loadingNewFor = null
        return this.showUnfinishedDfcRequestsError()
      }
      // Get recently focused has-many item ref
      const childRef = this.$children.filter(hasMany => {
        return hasMany.index === this.focusedRowIndex && hasMany.getHasManyItemDfcChanges
      })?.[0]
      if (!childRef) {
        this.loadingNewFor = null
        return
      }
      // Do a dummy update with defaults-for-change for focused has-many item, for field 'updated_at'
      // This will update that row data server side, which is then used for copying
      // and creating new row with same data
      childRef.getHasManyItemDfcChanges({ name: 'updated_at' }).then(() => {
        this.addNewNestedRow({ copyFromIdOrToken: childRef.item.token || childRef.item.id })
      })
    },

    // Add new has-many item picked from item picker
    addNewSelectItemToStore (item: Types.Item | null = null) {
      if (item) {
        // Always add to the queue, so item-picker can show the loader
        // Until all requests related to adding new item are completed
        // and is ok to add the next item selected with multi-select item picker
        this.addQueue.push(item)
      } else {
        // Take the next item from the queue, if any left
        item = this.addQueue?.[0]
        if (!item) { return }
      }
      // Only continue when processing first item from the queue
      if (this.addQueue?.[0]?.id !== item.id) { return }
      this.processNewItemFromQueue(item).then(() => {
        // Now have to update has-many items for the parent
        this.updateItemAfterSelectingNewHasManyChild(this.field.name).then(() => {
          // Reload all has-many items with changeable fields and tokens
          this.initialize().then(() => {
            this.addQueue.shift()
            // If user has selected next item(s) with multi-select item picker, add the next one
            if (this.addQueue[0]) {
              this.addNewSelectItemToStore()
            }
          })
        })
      })
    },

    // Add new has-many item picked form item picker
    // Use can pick multiple items quickly - therefore queue is used
    // NOTE - theoretically may have issues with ongoing DFCs
    processNewItemFromQueue (item) {
      return new Promise(resolve => {
        if (this.referenceFieldNameToAutofillFromItemPicker) {
          // A) Popup of one configured reference field values was shown
          // User can select multiple form the popup, new nested items must be created,
          // then value set for the reference field
          const autoFillData = {}
          autoFillData[this.referenceFieldNameToAutofillFromItemPicker] = item
          this.addNewNestedRow({
            setFocusAfter: false, // We want to keep the popup open, user can select next one
            autoFillData,
          }).then(() => {
            resolve(null)
          })
        } else {
          // B) Popup of existing items was shown and user picked existing items from there, just need to create a link
          this.addNewItemToStore(item).then(() => {
            resolve(null)
          })
        }
      })
    },

    addNewItemToStore (item: Types.Item) {
      return new Promise(resolve => {
        const itemIndexWithSameId = this.items.findIndex((i: Types.Item) => i.id === item.id)
        // If item with same id already added, skip
        if (!item || (!item.token && itemIndexWithSameId > -1)) { return resolve(null) }
        // Set target object for reference field queries with parent props
        if (this.edit) {
          item.targetObject = this.parentItemWithoutDynamicFields
        }
        const currentCount = (this.parentItem[this.field.name][0]?.count) || 0
        this.autofocusLastItemFirstTextField = true
        Vue.set(this.items, this.items.length, item)
        // Update count
        Vue.set(this.parentItem, this.field.name, [{ count: currentCount + 1 }])
        this.loadingNewFor = null
        resolve(null)
      })
    },

    addModalRow () {
      if (!this.parentItem.id) { return }
      this.showItemModal({
        show: true,
        id: 'new',
        edit: true,
        targetResource: this.parentResource,
        targetId: this.parentItem.id,
        targetField: this.field.name,
        resource: this.resource,
        showLoader: false,
        saveCallback: this.closeModalAndReloadList,
        closeCallback: this.closeModalRow,
      })
      this.loadingNewFor = null
    },

    closeModalAndReloadList () {
      this.closeModalRow()
      this.initialize()
    },

    closeModalRow () {
      this.showItemModal({
        show: false,
      })
    },

    addRow (e) {
      this.hasManyComponentForItemPicker = this
      this.$store.state.itemPickerSearchTerm = ''
      this.showItemPickerFor(e, {
        objectClass: util.objectClassUnderscoredName(this.field.reference_class),
        forObjectClass: util.objectClassUnderscoredName(this.parentItem['@class']),
        forItem: this.parentItem,
        selectColumns: ['summary'],
        selectCallback: this.addNewSelectItemToStore,
        unselectCallback: this.deleteHasManyItemById,
        forField: this.field,
        forFieldName: this.field.name,
        forHasMany: true,
        multiSelect: this.field.multi_select === true || this.field.multi_select === 'true',
      })
      this.loadingNewFor = null
    },

    // Create a dropdown of one reference field possible values
    addMultiByReferenceField (e) {
      this.hasManyComponentForItemPicker = this
      this.$store.state.itemPickerSearchTerm = ''
      // Instead of picking has-many item from the same list which model has-many items are
      // use a list from specified reference field model
      const objectClass = this.amc[this.referenceFieldNameToAutofillFromItemPicker].reference_class || this.amc[this.referenceFieldNameToAutofillFromItemPicker].association_types[0]
      this.showItemPickerFor(e, {
        objectClass,
        selectColumns: ['summary'],
        selectCallback: this.addNewSelectItemToStore,
        unselectCallback: () => {},
        forField: this.field,
        forFieldName: this.field.name,
        forHasMany: true,
        multiSelect: true,
        hideToggleAll: true,
        disableDeselect: true,
      })
      this.loadingNewFor = null
    },
  },
}
