
import methods from './../components/methods'

import Item from './Item.vue'

import HeaderColumnStatesMenu from './../components/ListHeaderColumns/HeaderColumnStatesMenu.vue'
import HeaderColumnUniqueValuesMenu from './../components/ListHeaderColumns/HeaderColumnUniqueValuesMenu.vue'
import HeaderColumnMenu from './../components/ListHeaderColumns/HeaderColumnMenu.vue'
import HeaderColumnDateFilterMenu from './../components/ListHeaderColumns/HeaderColumnDateFilterMenu.vue'

import ListColumnsSelectorDialog from './../components/ListHeaderActions/ActionMenus/ListColumnsSelectorDialog.vue'
import HeaderActionsConfirmBulkDelete from './../components/ListHeaderActions/HeaderActionsConfirmBulkDelete.vue'

import ListToolbar from './../components/ListToolbar.vue'
import LayoutProfileTabs from './../components/LayoutProfiles/LayoutProfileTabs.vue'
import ListViewContainer from './../components/ListViewContainer.vue'

import { createHelpers } from 'vuex-map-fields'
import state from './../store/state'
import api from '@/store/api'
import SplitModeFile from '@/views/SplitModeFile.vue'
import listEditMethods from '@/methods/list/listEditMethods'
import listCellEditMethods from '@/methods/listItem/listCellEditMethods'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'List',

  components: {
    SplitModeFile,
    Item,
    ListToolbar,
    ListViewContainer,
    LayoutProfileTabs,
    ListColumnsSelectorDialog,
    HeaderActionsConfirmBulkDelete,
    HeaderColumnStatesMenu,
    HeaderColumnUniqueValuesMenu,
    HeaderColumnMenu,
    HeaderColumnDateFilterMenu,
  },

  beforeRouteLeave (to, from, next) {
    this.handleRouteChange(to, from, next)
  },

  beforeRouteUpdate (to, from, next) {
    this.handleRouteChange(to, from, next)
  },

  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    targetResource: {
      type: String,
      default: '',
    },
    targetId: {
      type: [String, Number],
      default: '',
    },
    targetField: {
      type: String,
      default: '',
    },
    itemAction: {
      type: String,
      default: '',
    },
    resource: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      draggingSplitLine: '',
    }
  },

  watch: {
    splitMode () {
      this.saveSplitModeSettings()
    },

    translationsLoaded (value) {
      if (!value || !this.$route.name.includes('List_')) {
        return
      }
      this.setListPageTitle()
    },

    targetId () {
      this.setTargetItemInfo()
    },

    resource (value) {
      if (!this.resource || !value) {
        return
      }
      this.objectClass = value
      this.setListPageTitle()
    },

    id (value, before) {
      this.selectedId = parseInt(value)
      // User returning from item view back to the list view
      if (before && !value) {
        // Set More tabs content when user came from item view to list view
        this.$nextTick(() => {
          this.setVisibleLayoutProfileTabsCount()
        })
        // When closing item and showing list again, update page title
        this.setListPageTitle()
        // Reload list when configured so
        const reloadList = 'doNotReloadListOnReturn' in this.selectedLayoutProfile.timelineConfiguration
          ? !this.selectedLayoutProfile.timelineConfiguration.doNotReloadListOnReturn
          : true
        if (reloadList) {
          this.$nextTick(() => {
            this.resetListScroll()
            return this.$store.dispatch('reloadListItems', { updateCounts: false })
          })
        }
      }
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),

    passTargetToItemForm () {
      // Have to pass target resource for the item form when adding new item in new window with this special route
      // But can not pass it when just having an item view next to the list view - breaks queries etc
      // Aava-Vue-524
      return !this.splitProps.firstItemId && this.$route.name === 'List_ItemForTarget'
    },

    splitProps () {
      return this.$route.params
    },

    translationsLoaded () {
      // Safe to assume that there is always 'en'
      return !!this.$store.state.translationsByLocale?.en
    },

    verticalSplitLineStyle () {
      return {
        left: 'calc(' + this.splitModeListSize + '% - 3px)',
      }
    },

    horizontalSplitLineStyle () {
      return {
        top: (this.innerHeight / 100 * this.splitModeListSize) + 'px',
      }
    },

    listIsVisible () {
      return !this.id && !this.splitProps.secondResource
    },

    listStyle () {
      // TODO refactor
      if (this.splitMode === 'vertical' && !this.splitProps.secondResource) {
        return {
          width: 'calc(' + this.splitModeListSize + '% - 3px)',
          float: 'left',
        }
      } else if (this.splitMode && !this.splitProps.secondResource) {
        const topMenuSize = 39
        return {
          height: ((this.innerHeight / 100 * this.splitModeListSize) - topMenuSize) + 'px',
          width: '100%',
          float: 'left',
        }
      } else if (!this.listIsVisible) {
        return {
          display: 'none',
          position: 'absolute',
          left: '10000px',
          top: 0,
        }
      }
      return {}
    },

    itemStyle () {
      if (this.splitMode === 'horizontal') {
        return {
          float: 'left',
          width: '100%',
        }
      } else if (this.splitMode) {
        const width = !this.splitProps.secondResource
          ? (100 - this.splitModeListSize)
          : this.splitModeListSize
        return {
          width: width + '%',
          float: 'left',
        }
      } else if (!this.id) {
        return {
          display: 'none',
        }
      }
      return {}
    },

    secondSplitWindowStyle () {
      if (this.splitMode === 'horizontal') {
        return {
          float: 'left',
          width: '100%',
        }
      }
      return {
        width: (100 - this.splitModeListSize) + '%',
        float: 'left',
      }
    },
  },

  created () {
    this.getSplitModeSettings()
    this.setTargetItemInfo()
    this.setListPageTitle()
    this.processListEditQueueJobs() // Start the timer
    if (this.id) {
      this.selectedId = parseInt(this.id)
    }
    // TODO - move to util?
    if (window.location.hash.split('/')[2] === 'split') {
      // TODO - use local storage first?
      this.splitMode = 'vertical'
    }
    // TODO - delete if below not needed
    // objectClass was set before only when it was not already set
    // caused issue when coming form dashboard did not update object class
    // if (!this.objectClass && this.resource) {  }
    this.objectClass = this.resource
    document.addEventListener('mousemove', this.mouseMoveListener)
    document.addEventListener('mouseup', this.mouseUpListener)
  },

  destroyed () {
    document.removeEventListener('mousemove', this.mouseMoveListener)
    document.removeEventListener('mouseup', this.mouseUpListener)
    this.endListEditTimer()
  },

  methods: {
    ...methods,
    ...listEditMethods,
    ...listCellEditMethods,

    // Toggle split mode with Ctrl+Shift+ArrowKey
    toggleSplitModeFromShortcut (event) {
      if (['down', 'up'].includes(event.srcKey)) {
        // TODO Temp disable
        // this.toggleHorizontalSplitMode()
      } else { // 'left' or 'right'
        // this.toggleVerticalSplitMode()
      }
    },

    handleRouteChange (to, from, next) {
      // Use only 'next', and explicitly ignore 'from' with an underscore
      const mainItemEditIsPendingSave = this.$refs?.main_item?.$refs?.itemForm?.itemIsPendingSave
      const mainItemModalItemEditIsPendingSave = this.$refs?.main_item?.$refs?.itemForm?.$refs?.modal_item?.$refs?.itemForm?.itemIsPendingSave
      const splitViewItemEditIsPendingSave = this.$refs?.split_view_item?.$refs?.itemForm?.itemIsPendingSave
      const splitViewItemModalItemEditIsPendingSave = this.$refs?.split_view_item?.$refs?.itemForm?.$refs?.modal_item?.$refs?.itemForm?.itemIsPendingSave
      if (mainItemEditIsPendingSave || splitViewItemEditIsPendingSave || mainItemModalItemEditIsPendingSave || splitViewItemModalItemEditIsPendingSave) {
        const answer = window.confirm(this.$i18n.t('aava.confirmations.dirty_object'))
        if (answer) {
          next()
        } else {
          next(false)
        }
      } else {
        next()
      }
    },

    // Request item summary for target item. User is viewing a list for one a parent item.
    setTargetItemInfo () {
      if (this.targetId) {
        this.listForItemId = this.targetId
        this.listForItemClass = this.targetResource
        api.fetchItemInfo(this.targetResource, this.targetId, ['!summary'])
          .then((response: AxiosResponse) => {
            this.listForItemName = response.data.item?.summary
          })
        this.listForItemName = null
      } else {
        this.listForItemId = null
        this.listForItemClass = null
        this.listForItemName = null
      }
    },

    // TODO - comment
    mouseMoveListener (e) {
      const minSplitWindowSizePercentage = 30
      const maxSize = this.splitMode === 'vertical' ? window.innerWidth : window.innerHeight
      const position = this.splitMode === 'vertical' ? e.clientX : e.clientY
      if (this.draggingSplitLine) {
        this.splitModeListSize = position * 100 / (maxSize - 8)
        this.splitModeListSize = this.splitModeListSize > (100 - minSplitWindowSizePercentage)
          ? (100 - minSplitWindowSizePercentage)
          : this.splitModeListSize < minSplitWindowSizePercentage
            ? minSplitWindowSizePercentage
            : this.splitModeListSize
      }
    },

    mouseUpListener () {
      if (this.draggingSplitLine) {
        window.dispatchEvent(new Event('resize'))
        this.saveSplitModeSettings()
      }
      this.draggingSplitLine = ''
    },

    startSplitLineDrag (type) {
      this.draggingSplitLine = type
    },

    getSplitModeSettings () {
      const splitMode = window.localStorage.getItem('splitMode') ?? ''
      if (['vertical', 'horizontal'].includes(splitMode)) {
        this.splitMode = splitMode
      }
      const splitModeListSize = window.localStorage.getItem('splitModeListSize') ?? ''
      if (parseInt(splitModeListSize) > 15 && parseInt(splitModeListSize) < 85) {
        this.splitModeListSize = parseInt(splitModeListSize)
      }
    },

    saveSplitModeSettings () {
      window.localStorage.setItem('splitMode', this.splitMode)
      window.localStorage.setItem('splitModeListSize', this.splitModeListSize)
    },
  },
}
