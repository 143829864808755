import api from './../api'
import Vue from 'vue'
import Raven from 'raven-js'
import goToLoginApp from '@/utilities/goToLoginApp'
import { AxiosResponse } from 'axios'

export default {
  /**
   * Called on reload, check if user is logged in and set user data
   */
  // getPersonInfoWithTokens: ({ dispatch, state }) => {
  //   return new Promise(resolve => {
  //     api.getPersonInfoForEdit(state.userInfo.person.id).then((response: AxiosResponse) => {
  //       if (response.data.item) {
  //         // Update user info with tokens
  //         dispatch('updatePersonInfo', response.data.item)
  //         resolve(true)
  //       }
  //     })
  //   })
  // },

  trySessionRestore: ({ dispatch, state }) => {
    return new Promise(resolve => {
      api.getUserInfo('my').then((response: AxiosResponse) => {
        Raven.captureMessage('MY-beta', {
          level: 'error',
          extra: {
            response,
          }
        })
        // Skip error check here. With login dialog error is put to the array
        // and after successful login it's shown with the delay. Do not need here at all
        // dispatch('globalErrorDisplay', { response, context: 'Get user info /my' })
        if (response.data.item) {
          resolve(response)
          // Set user info after two ticks, then locale can be set
          // before sending any following API calls
          Vue.nextTick(() => {
            Vue.nextTick(() => {
              dispatch('setUserInfo', response.data.item)
            })
          })
        } else {
          // TODO send different result depending on
          // user not logged in
          // or request /connection etc error
          resolve(false)
        }
        state.sessionRestoreTryCompleted = true
      })
    })
  },

  logout ({ state }) {
    if (state.isLocalhostDomain) {
      api.sendLogoutRequest().then(() => {
        location.reload()
      })
      return
    }
    goToLoginApp({ action: 'logout' })
  },

  login () {
    goToLoginApp({ action: 'login' })
  },

  // updatePersonInfo ({ state }, personInfo) {
  //   Vue.set(state.userInfo, 'person', personInfo)
  // },

  setUserInfo ({ state }, userInfo) {
    if (!userInfo.working_region) {
      userInfo.working_region = []
    }
    if (!userInfo.street_address) {
      userInfo.street_address = {}
    }
    userInfo.roleIdentifiers = userInfo?.roles?.map(role => role.identifier) || []
    state.authenticated = true
    state.userInfo = userInfo
  }
}
