import Sortable, { SortableEvent } from 'sortablejs'
import Vue from 'vue'
import clearCache from '@/utilities/clearCache'
import { ExtraFormItem, LP, LPI } from '@/types/LP.types'
import { getSelectedItemLP } from '@/methods/item/itemMethods'

export default {
  createSortables () {
    if (!this.itemLayoutEditMode) {
      return
    }
    this.$nextTick(() => {
      this.containerSortable = null
      const el = document.getElementById('level-1-sort')
      if (!el) { return }
      this.containerSortable = Sortable.create(el, {
        onEnd: this.onDragEndForContainer,
        chosenClass: 'container-chosen',
        dragClass: 'container-drag',
        handle: '.fa-ellipsis-vertical',
      })
      this.createFieldSetSortables()
      this.createFieldSortables()
      this.createFieldSelectorSortables()
    })
  },

  // destroySortables () {
  //   if (this.containerSortable) {
  //     this.containerSortable.destroy()
  //   }
  //   if (this.fieldSetSortables) {
  //     this.fieldSetSortables.forEach(sortable => {
  //       sortable.destroy()
  //     })
  //   }
  //   if (this.fieldSortables) {
  //     this.fieldSortables.forEach(sortable => {
  //       sortable.destroy()
  //     })
  //   }
  //   if (this.fieldSelectorSortables) {
  //     this.fieldSelectorSortables.forEach(sortable => {
  //       if (!sortable) { return }
  //       sortable.destroy()
  //     })
  //   }
  // },

  createFieldSetSortables () {
    this.fieldSetSortables = []
    this.containers.forEach((container: LP.Container) => {
      const el = document.getElementById('level-2-sort-' + container.id)
      if (el) {
        this.fieldSetSortables.push(Sortable.create(el, {
          group: 'container',
          onEnd: this.onDragEndForFieldSet,
          chosenClass: 'field-set-chosen',
          dragClass: 'field-set-drag',
          handle: '.fa-ellipsis-vertical',
        }))
      }
    })
  },

  createFieldSortables () {
    this.fieldSortables = []
    this.containers.forEach(container => {
      this.fieldSets.filter(fieldSet => (fieldSet.layout_container && fieldSet.layout_container.id) === container.id)
        .forEach(fieldSet => {
          const el = document.getElementById('level-3-sort-' + fieldSet.id)
          if (el) {
            this.fieldSortables.push(Sortable.create(el, {
              group: 'fieldSet',
              onEnd: this.onDragEndForField,
              onStart: this.onDragStartForField,
              ghostClass: 'field-ghost',
              chosenClass: 'field-chosen',
              dragClass: 'field-drag',
              handle: '.field-drag-handle',
            }))
          }
        })
    })
  },

  createFieldSelectorSortables () {
    const el = document.getElementById('item-field-selector-inner')
    this.fieldSelectorSortables = []
    if (el) {
      this.fieldSelectorSortables.push(Sortable.create(el, {
        group: {
          name: 'fieldSet',
          pull: 'clone',
        },
        onStart: this.onDragStartForField,
        onEnd: this.onDragEndForNewField,
        chosenClass: 'field-chosen',
        dragClass: 'field-drag',
      }))
    }
  },

  onDragEndForContainer () {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    this.$nextTick(() => {
      this.changeContainersSortOrder()
      this.updateContainersInDOMAndCreateSortings()
    })
  },

  onDragEndForFieldSet (dropInfo: SortableEvent) {
    clearCache('lp_data_' + this.selectedItemLayoutProfileId)
    this.$nextTick(() => {
      this.changeFieldSetsSortOrder(dropInfo)
      this.updateContainersInDOMAndCreateSortings()
    })
  },

  onDragEndForField (dropInfo: SortableEvent) {
    this.$nextTick(() => {
      this.moveFieldAndUpdateSortOrder(dropInfo)
      this.updateContainersInDOMAndCreateSortings()
      this.draggingItemType = null
    })
  },

  onDragEndForNewField (dropInfo: SortableEvent) {
    this.$nextTick(() => {
      this.moveFieldAndUpdateSortOrder(dropInfo)
      this.updateContainersInDOMAndCreateSortings()
      this.draggingItemType = null
    })
  },

  onDragStartForField () {
    // So we can change css for potential drop areas
    this.draggingItemType = 'form-field'
  },

  updateContainersInDOMAndCreateSortings () {
    this.sortContainersAndFieldSets()
    this.reRender()
    this.$nextTick(() => {
      this.createSortables()
    })
  },

  dragEnd () {
    // this.changeContainersSortOrder()
  },

  changeContainersSortOrder (forced = false) {
    let sortingChanged = false
    const newOrderArr: number[] = []
    let prevIndex = -1
    document.querySelectorAll<HTMLElement>('.container-form').forEach(el => {
      const index = parseInt(el.dataset.index!)
      if (index < prevIndex) {
        sortingChanged = true
      }
      newOrderArr.push(index)
      prevIndex = index
    })
    if (sortingChanged || forced) {
      newOrderArr.forEach((containerIndex, newOrder) => {
        const containers = this.layoutContainers[this.selectedItemLayoutProfileId]
        if (containers[containerIndex] && 'sort_order' in containers[containerIndex]) {
          Vue.set(containers[containerIndex], 'sort_order', newOrder)
        }
      })
      this.$store.dispatch('saveLayoutContainersOrder')
    }
  },

  changeFieldSetsSortOrder (dropInfo: SortableEvent) {
    const toContainerId: number = parseInt((dropInfo.to.parentNode?.parentNode as HTMLElement).dataset.containerId!)
    const fromContainerId: number = parseInt((dropInfo.from.parentNode?.parentNode as HTMLElement).dataset.containerId!)
    const fieldSets = this.layoutContainerFieldSets[this.selectedItemLayoutProfileId]
    const newOrderArr: number[] = []
    document.querySelectorAll<HTMLElement>('.field-set-form').forEach(el => {
      newOrderArr.push(parseInt(el.dataset.fieldSetId!))
    })
    // Update field sets sort order in store
    newOrderArr.forEach((fieldSetId, newOrder) => {
      const fieldSet = fieldSets.find(fieldSet => fieldSet.id === fieldSetId)
      if (fieldSet && 'sort_order' in fieldSet) {
        Vue.set(fieldSet, 'sort_order', newOrder)
      }
    })
    // Update field sets in store
    if (toContainerId !== fromContainerId) {
      const fieldSet = fieldSets.find(fieldSet => {
        return fieldSet.id === parseInt(dropInfo.item.dataset.fieldSetId!)
      })
      if (fieldSet) {
        Vue.set(fieldSet, 'layout_container', {
          id: toContainerId
        })
      }
    }
    // Update sort order over API
    this.$store.dispatch('saveLayoutContainerFieldSetsOrder').then(() => {
      if (toContainerId !== fromContainerId) {
        // Update field sets over API
        this.$store.dispatch('updateLayoutContainerFieldSetContainer', {
          id: dropInfo.item.dataset.fieldSetId,
          layoutContainer: {
            id: toContainerId
          }
        })
      }
    })
  },

  updateLPItemsOrderInLPConfig (lp: LP.LayoutProfile, newOrder: (string | number)[]) {
    if (!lp) { return }
    this.$set(lp.timelineConfiguration, 'itemsSortOrder', newOrder)
    this.$store.dispatch('saveLayoutProfileData', lp)
  },

  // Sort fields in a field-set
  // Also:
  // - move field between field-set
  // - remove field from the field-set
  // - move field from field selector to field-set
  moveFieldAndUpdateSortOrder (dropInfo: SortableEvent) {
    const toFieldSetId: number = parseInt((dropInfo.to.parentNode?.parentNode as HTMLElement).dataset.fieldSetId!) || 0
    const fromFieldSetId: number = parseInt((dropInfo.from.parentNode?.parentNode as HTMLElement).dataset.fieldSetId!) || 0
    const fields = this.layoutProfileItemsById[this.selectedItemLayoutProfileId]
    const newOrder: (string | number)[] = []
    const lp = getSelectedItemLP(this, this.resource, this.selectedItemLayoutProfileId)
    document.querySelectorAll<HTMLElement>('.field-set-field').forEach(el => {
      if (!el) { return }
      const isInSelectorList = (el.parentNode as HTMLElement).id === 'item-field-selector-inner'
      if (isInSelectorList) { return }
      // If field was already in field set
      // include field added from selector
      // and not to include replaced field into new order array
      const wasAlreadyInFieldSet = el.dataset.inFieldSet
      const isFieldFromSelector = el.className.includes('form-field-selectable')
      const isReplacedFieldFromSelector = !fromFieldSetId && wasAlreadyInFieldSet && el.dataset.fieldId === dropInfo.item.dataset.fieldId
      if (isFieldFromSelector || (wasAlreadyInFieldSet && !isReplacedFieldFromSelector)) {
        if (!el.dataset.isHasManyField) {
          const prefix = el.dataset.isLpi ? '' : '_'
          newOrder.push(prefix + parseInt(el.dataset.fieldId!))
        }
      }
    })
    // Update field field-set in store
    if (!toFieldSetId || !fromFieldSetId || toFieldSetId !== fromFieldSetId) {
      if (dropInfo.item.dataset.isLpi || !fromFieldSetId) {
        const field = fields[dropInfo.item.dataset.fieldIndex!]
        Vue.set(field, 'layout_container_field_set', { id: toFieldSetId })
        Vue.set(field, 'visible', true)
      } else {
        const extraItem = lp?.timelineConfiguration.extraFormItems
          .find((i: ExtraFormItem) => i.id === parseInt(dropInfo.item.dataset.fieldId!))
        if (extraItem) {
          Vue.set(extraItem, 'layout_container_field_set', { id: toFieldSetId })
        }
        // Extra form item, update field-set in the config
      }
    }
    // Update visibility and field-set over the API
    if (toFieldSetId !== fromFieldSetId) {
      // Update field field-set and visibility over API
      this.$store.dispatch('updateItemLayoutProfileItem', {
        id: dropInfo.item.dataset.fieldId,
        visible: !!toFieldSetId,
        layoutContainerFieldSet: {
          id: toFieldSetId
        }
      })
    }
    this.updateLPItemsOrderInLPConfig(lp, newOrder)
    // Update sort order over API
    // Deprecated - now in LP config
    // this.$store.dispatch('saveItemFieldsOrder', { sortBy: 'sortOrderField' }).then(() => {})
  },

  // Case 1 - user selected from drop-down where to move the field
  itemFieldMove (movedField: LPI, toFieldSetId: number) {
    const fields = this.layoutProfileItemsById[this.selectedItemLayoutProfileId]
    const newOrder: (string | number)[] = []
    const lp = getSelectedItemLP(this, this.resource, this.selectedItemLayoutProfileId)
    // Go through all fields, in case multiple were selected
    // For LPI change field-set directly in LPI data
    if (movedField.isLPI) {
      fields.filter((field: LPI) => {
        // movedField can also be empty, when opened menu from the selected items total count btn
        return this.selectedLayoutEditorFields.includes(field.name) || field.name === movedField?.name
      }).forEach((field: LPI) => {
        Vue.set(field, 'layout_container_field_set', { id: toFieldSetId })
        Vue.set(field, 'visible', true)
        // Update field field-set and visibility over API
        this.$store.dispatch('updateItemLayoutProfileItem', {
          id: field.id,
          visible: true,
          layoutContainerFieldSet: {
            id: toFieldSetId
          }
        })
      })
    } else {
      // For Form Extra Item, update in LP config
      const extraItem = lp?.timelineConfiguration.extraFormItems
        .find((i: ExtraFormItem) => i.id === movedField.id)
      if (extraItem) {
        Vue.set(extraItem, 'layout_container_field_set', { id: toFieldSetId })
      }
    }
    // Get new order from DOM and update in LP config
    document.querySelectorAll<HTMLElement>('.field-set-field').forEach(el => {
      if (!el) { return }
      const isInSelectorList = (el.parentNode as HTMLElement).id === 'item-field-selector-inner'
      if (isInSelectorList) { return }
      const prefix = el.dataset.isLpi ? '' : '_'
      newOrder.push(prefix + parseInt(el.dataset.fieldId!))
    })
    this.updateLPItemsOrderInLPConfig(lp, newOrder)
    this.closeLayoutEditorMenus()
    this.selectedLayoutEditorFields = []
  },
}
