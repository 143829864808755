var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"mousedown":function($event){_vm.dragMoveCount = 0}}},[_c('div',{staticClass:"item-show-field"},[(_vm.fieldLabel)?_c('div',{staticClass:"item-show-label"},[_c('span',[_vm._v(_vm._s(_vm.fieldLabel))])]):_vm._e(),(_vm.item && !_vm.isLinkedAssociate(_vm.item['@class']))?_c('div',{class:!_vm.isListView ? 'item-show-value' : ''},[_vm._v(" "+_vm._s(_vm.referenceLabel(_vm.item, _vm.field))+" ")]):[_c(_vm.hasManyItemLink ? 'router-link' : 'div',{tag:"component",class:'string-type ' + (!_vm.isListView ? 'item-show-value' : ''),style:({
          display: _vm.isListView ? 'inline-flex' : '',
          cursor: _vm.isListView ? 'pointer' : '',
        }),attrs:{"to":_vm.hasManyItemLink}},[(_vm.item)?_c('a',{class:' reference-label-inline assoc_link ' +
            (_vm.isLinkedAssociate(_vm.field.reference_class) ? '' : 'no-link-decoration'),style:(_vm.style),attrs:{"href":_vm.referenceItemUrl,"tabindex":"-1"},on:{"click":function($event){_vm.disableLink ? null : _vm.checkAndStopSimpleMouseClick($event)},"mouseup":function($event){_vm.item ? _vm.mouseUpHandler($event) : null},"mousemove":function($event){_vm.dragMoveCount++}}},[(_vm.field.widget === 'address' && _vm.isGeoCodable)?_c('i',{staticClass:"fa reference-icon fa-map-marker-alt",on:{"click":function($event){return _vm.showAddressOnMap($event)},"mouseup":function($event){$event.stopPropagation();$event.preventDefault();}}}):_vm._e(),(_vm.field.widget === 'address' && _vm.isRoutable('office'))?_c('i',{staticClass:"fa reference-icon fa-car",on:{"click":function($event){return _vm.showRouteOnMap($event)},"mouseup":function($event){$event.stopPropagation();$event.preventDefault();}}}):_vm._e(),(_vm.field.widget === 'address' && _vm.isRoutable('home'))?_c('i',{staticClass:"fa reference-icon fa-home",on:{"click":function($event){return _vm.showRouteFromHomeOnMap($event)},"mouseup":function($event){$event.stopPropagation();$event.preventDefault();}}}):_vm._e(),((_vm.field.widget !== 'address' ) && // || isGeocodable()
              !['main_object_process', 'main_object_state'].includes(_vm.field.name))?_c('svg-icon',{style:({
              color: '#1976D2',
              margin: _vm.isListView ? '-6px 0 -6px -4px !important' : '-6px -3px -6px -5px !important',
              padding: _vm.isListView ? '5px 0 3px 0 !important' : '4px 0 4px 0 !important',
            }),attrs:{"path":_vm.iconPath,"size":23,"type":"mdi"}}):_vm._e()],1):_vm._e(),_vm._v(" "+_vm._s(_vm.value || ' ')+" "),(_vm.item && _vm.item.main_object_state)?_c('button',{staticClass:"reference-state-button",style:(_vm.referenceButtonStyle(_vm.item.main_object_state)),attrs:{"type":"button","tabindex":"-1"}},[_vm._v(" "+_vm._s(_vm.item.main_object_state.name)+" ")]):_vm._e()])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }