
import sharedUtilities from '@/utilities/sharedUtilities'
import appMethods from '@/methods/list/appMethods'
import itemMethods, { getCombinedItemProcessEventsAndActions } from '@/methods/item/itemMethods'
import itemEventActionMethods from '@/methods/item/itemEventActionMethods'
import itemSaveQueueMethods from '@/methods/item/itemSaveQueueMethods'
import { Types } from '@/types/AppTypes'
import { EventAction } from '@/types/EventAction'

export default {
  name: 'ContentProcessEvents',

  props: {
    item: {
      type: Object as () => Types.Item,
      required: true,
    },
    itemIndex: {
      type: Number,
      default: null
    },
    view: {
      type: String,
      default: 'list',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    stateChangeCallback: {
      type: Function,
      default: null,
    },
    isHasManyField: {
      type: Boolean,
      default: false,
    },
    hasManyParentId: {
      type: [String, Number],
      default: '',
    },
    hasManyParentClass: {
      type: String,
      default: '',
    },
    hasManyParentField: {
      type: Object as () => Types.Item,
      default: () => {},
    },
  },

  computed: {
    objectClass (): string {
      return sharedUtilities.objectClassUnderscoredName(this.item['@class'])
    },

    sendingEventActionForIds (): number[] {
      return this.$store.state.sendingEventActionForIds
    },

    eventActions () {
      return getCombinedItemProcessEventsAndActions(this.item)
    }
  },

  methods: {
    ...appMethods, // TODO - why need all?
    ...itemMethods,
    ...itemEventActionMethods,
    ...itemSaveQueueMethods,

    clickHandler (event: EventAction, e: MouseEvent) {
      const parent = {
        '@class': this.hasManyParentClass,
        id: this.hasManyParentId,
      } as Types.Item
      this.sendProcessEventForContentItem(event, parent, this.hasManyParentField, e)
    },
  },
}
