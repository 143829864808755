
import methods from './../../components/methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import { LP } from '@/types/LP.types'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ItemFieldSetMenu',

  data () {
    return {
      saving: false,
    }
  },

  props: {
    layoutProfile: {
      type: Object as () => LP.LayoutProfile,
      required: true,
    },
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  created () {
    document.addEventListener('click', this.clickListener)
  },

  destroyed () {
    document.removeEventListener('click', this.clickListener)
  },

  methods: {
    ...methods,

    clickListener (e) {
      if (this.dialogItemFieldSetMenu) {
        const el = document.getElementById('item-field-set-menu')
        if (el && !el.contains(e.target)) {
          this.dialogItemFieldSetMenu = false
        }
      }
    },
  }
}
