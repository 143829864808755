<template>
  <div class="edit-field-textarea">
    <v-textarea
      v-model="text"
      :label="label"
      :placeholder="isHasManyField ? label : ''"
      :autofocus="autofocus"
      :rows="rows"
      :class="field.mandatory ? (text ? ' required-filled ' : ' required-missing ') : ''"
      :single-line="isHasManyFieldAndNotFirstRow"
      :persistent-placeholder="true"
      :disabled="readOnly"
      :auto-grow="autoGrow"
      :height="isHasManyField && rows === 1 && !autoGrow ? 28 : ''"
      outlined
      dense
      hide-details
      @change="textChanged"
      @focus="focus"
      @input="$emit('changeListener')"
      @keydown="keydownHandler"
    />
    <div
      v-if="appendIcon"
      class="item-field-action-buttons"
    >
      <v-btn
        class="mx-1"
        color="grey"
        tabindex="-1"
        icon
        @click.stop="appendIconFunction()"
        @mousedown.stop=""
      >
        <v-icon small>
          {{ appendIcon }}
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextArea',

  props: {
    value: {
      type: String,
      default: () => {}
    },
    label: {
      type: String,
      default: null
    },
    field: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    },
    isHasManyField: {
      type: Boolean,
      default: false,
    },
    isHasManyFieldAndNotFirstRow: {
      type: Boolean,
      default: false,
    },
    updateItemOnDefaultsForChange: {
      type: Function,
      default: () => {},
    },
    appendIcon: {
      type: String,
      default: '',
    },
    appendIconFunction: {
      type: Function,
      default: () => {},
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      changeTimer: null,
    }
  },

  computed: {
    text: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      },
    },

    rows () {
      return this.isHasManyField ? this.autoGrow ? 1 : 3 : 5
    },

    autoGrow () {
      const macCharacters = 120
      return this.isHasManyField && (this.value?.length < macCharacters || !this.value)
    },
  },

  methods: {
    focus () {
      setTimeout(() => {
        this.$store.dispatch('closeItemPicker')
      }, 100) // Give time for reference field option pick action and destroy
    },

    textChanged () {
      clearTimeout(this.changeTimer)
      this.changeTimer = setTimeout(() => {
        this.updateItemOnDefaultsForChange(this.field)
      }, 10) // called on blur
    },

    keydownHandler (e) {
      // In list-edit only Tab is supported, as text may be on multiple lines
      if ([
        'Tab', // Move to next editable item in list
      ].includes(e.code)) {
        this.$emit('keyPress', e)
      }
    },
  },

}
</script>

<style>
.v-textarea textarea {
  line-height: 1.2rem !important;
}
</style>
