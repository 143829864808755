<template>
  <div
    v-if="hiddenFieldFilters.length > 0"
    :class="'hidden-field-filters ' + (displayMode === 'wide' ? 'pt-1' : '')"
  >
    <v-menu
      v-model="showMenu"
      :z-index="160"
      :disabled="displayMode === 'wide'"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :text="!showMenu"
          :disabled="displayMode === 'wide'"
          :color="showMenu ? '#ddd' : ''"
          class="text-none"
          small
          tile
          depressed
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            class="mr-2"
            small
          >
            fa-search
          </v-icon>
          <div
            v-if="displayMode !== 'wide'"
            class="d-inline-flex mt-n4"
          >
            {{ hiddenFieldFilters.length }}
          </div>
          <v-icon
            v-if="displayMode !== 'wide'"
            class="ml-n3"
            x-small
          >
            fa-chevron-down
          </v-icon>
          {{ displayMode === 'compact' ? $i18n.t('aava.index.list.filters') : '' }}
        </v-btn>
      </template>
      <v-container class="white">
        <v-row class="pt-2">
          <v-col>
            <ListHiddenFiltersList
              :hidden-field-filters="hiddenFieldFilters"
              :inside-menu="true"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-menu>
    <ListHiddenFiltersList
      v-if="displayMode === 'wide'"
      :hidden-field-filters="hiddenFieldFilters"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import ListHiddenFiltersList
  from '@/components/ListHeaderActions/ActionMenus/ListHiddenFiltersList'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ListHiddenFilters',

  components: {
    ListHiddenFiltersList,
  },

  data () {
    return {
      showMenu: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    layoutProfileItemsByName () {
      return this.$store.getters.layoutProfileItemsByName
    },

    hiddenFieldFilters () {
      return this.layoutProfileItems.filter(field => {
        return this.listFilters[field.name] && (!field.visible || this.layoutProfileView !== 'list')
      })
    },

    displayMode () {
      if (this.$vuetify.breakpoint.mdAndDown) { return 'minimal' }
      // Decide how to show hidden filters based on area width and filters count
      const spaceNeededForOneFilter = 150
      const spaceNeeded = this.hiddenFieldFilters.length * spaceNeededForOneFilter
      const spaceAvailable = this.$store.getters.listAreaWidth - 500 // 500 is for other components
      if (spaceAvailable > spaceNeeded) {
        return 'wide'
      } else if (spaceAvailable > 200) {
        return 'compact'
      }
      return 'minimal'
    },
  },
}
</script>

<style lang="less">
.hidden-field-filters {
  .fa {
    font-size: 16px;
    position: relative;
    right: -8px;
    margin-left: -2px !important;
  }
}
.hidden-field-filters {
  line-height: 34px;
  position: relative;
  overflow: hidden;
  .fa-search {
    float: left;
    line-height: 26px;
  }
  div, button {
    float: left;
    padding-right: 8px
  }
}
.filter-remove-icon {
  margin-left: 6px;
  padding-top: 0 !important;
}
</style>
