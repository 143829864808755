<template>
  <v-col
    cols="12"
    class="ma-0 overflow-hidden"
  >
    <v-container class="pa-0">
      <v-row
        class="elevation-1 mt-1 white"
        no-gutters
      >
        <v-col
          :style="style"
          class="card-item-color-line flex-grow-0 flex-shrink-1"
        />
        <v-col
          class="flex-grow-1 flex-shrink-0 pl-2 my-1 pr-2"
        >
          <div style="display: flex;">
            <div
              v-if="rowItem.title_html"
              class="pt-1 mb-1"
              @click="handleClick($event)"
              v-html="rowItem.title_html"
            />
            <template v-else>
              <div
                @click="handleClick($event)"
              >
                <CardViewItemTitleItem
                  v-for="(titleItem, index) in titleItems.filter(item => !item.icon)"
                  :key="index"
                  :item="rowItem"
                  :title-item="titleItem"
                  @actionClicked="onActionClicked"
                />
              </div>
              <v-spacer />
              <div
                class="pt-1"
              >
                <CardViewItemTitleItem
                  v-for="(titleItem, index) in titleItems.filter(item => !!item.icon)"
                  :key="index"
                  :item="rowItem"
                  :title-item="titleItem"
                  @actionClicked="onActionClicked"
                />
              </div>
            </template>
          </div>
          <div
            v-if="!rowItem.title_html"
            class="pt-1"
            :title="rowItem.subtitle"
            v-html="subtitle"
          />
          <CardViewIconAction
            v-if="hasAction"
            :action="iconAction"
            :color="itemColor"
            @completed="onActionCompleted"
          />
          <v-expand-transition>
            <div
              v-show="showExpanded"
              class="pt-2"
            >
              <CardViewItemRow
                :text="rowItem.extended_text"
              />
              <div class="card-view-extended justify-end">
                <v-btn
                  color="grey lighten-2"
                  height="25"
                  class="my-2"
                  block
                  dense
                  small
                  depressed
                  :to="'/' + objectClass + '/' + rowItem.id"
                  @click="$emit('disableFullScreen')"
                >
                  {{ $i18n.t('aava.index.cardview.open_item') }}
                </v-btn>
              </div>
            </div>
          </v-expand-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import CardViewItemRow from './CardViewItemRow'
import CardViewItemTitleItem from './CardViewItemTitleItem'
import CardViewIconAction from './CardViewIconAction'

const Color = require('color')

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'CardViewItem',

  components: {
    CardViewItemRow,
    CardViewItemTitleItem,
    CardViewIconAction
  },

  props: {
    rowIndex: {
      type: Number,
      default: () => null,
    },
    rowItem: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      showExpanded: false,
      iconAction: null,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    titleItems () {
      return this.cardViewInfos[this.objectClass].card_title
    },

    subtitle () {
      return this.rowItem.subtitle
    },

    hasAction () {
      return this.iconAction && Object.keys(this.iconAction).length > 0
    },

    style () {
      return {
        backgroundColor: this.itemColor,
      }
    },

    rowColor () {
      return this.rowItem.color || '#ffb6c1'
    },

    itemColor () {
      // Only allow light colors for ui to look good
      return Color(this.rowColor)
        .lightness(this.rowItem.selected ? 50 : 80)
        .hex()
    },
  },

  methods: {
    ...methods,

    openItem () {
      this.listItemOpenById(this.rowItem.id, { className: this.resource })
    },

    onActionClicked (action) {
      this.iconAction = this.hasAction ? {} : action
    },

    onActionCompleted (action, operation) {
      if (operation === 'save') {
        this.$store.dispatch('sendIconAction', {
          item: this.rowItem,
          name: action.name,
          data: action.parameters
        })
          .then(result => {})
      }
      action.parameters.forEach(param => {
        param.value = null
      })
      this.iconAction = {}
    },

    handleClick (event) {
      if (event.ctrlKey) {
        this.$emit('card-selected', this.rowItem)
      } else {
        if (this.splitMode) {
          this.openItem()
          return
        }
        this.showExpanded = !this.showExpanded
      }
    },
  }
}
</script>

<style>
.card-view-selected {
  border-width: 3px;
}
.card-item-color-line {
  float: left;
  min-width: 10px;
  max-width: 10px;
}
</style>
