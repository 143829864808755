import api from './../api'
import i18n from './../../locale/i18n'
import Vue from 'vue'
import localStorageTtl from '@/utilities/localStorageTtl'
import moment from 'moment'
import Raven from 'raven-js'
import { AxiosResponse } from 'axios'
import { TranslateResult } from 'vue-i18n'
import { AppMessage, FlashMessage } from '@/types/API.responses'
const inflect = require('i')()
// const errorDisplayTimeBeforeShowingLogin = 10

// TODO - use some package?
// const clientErrorCodes = ['error', 400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410,
//   411, 412, 413, 414, 415, 416, 417, 418, 421, 422, 423, 424, 425, 426, 428, 429, 431, 451]
const serverErrorCodes = ['fatal', 500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511]
const successCodes = ['ok', 200, 201, 202, 203, 204, 205, 206, 207, 208, 226]

const isObject = item => {
  return item && typeof item === 'object' && !Array.isArray(item)
}

const setLocaleMessages = (responseData: any) => {
  // Set translations in i18n
  Object.keys(responseData).forEach(lang => {
    // Not using locales array itself here as this was recently added and takes a while when it's deployed everywhere
    if (!['locales', 'status'].includes(lang)) { // Make sure it's a lang
      const combinedMessages = mergeDeep(responseData[lang], i18n.messages[lang])
      i18n.setLocaleMessage(lang, combinedMessages)
    }
  })
}

const mergeDeep = (target, source) => {
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }
  return target
}

const getErrorMessageForCode = (code, i18n) => {
  if (!code || parseInt(code) < 400) { return } // For 201 for example, when new item created
  switch (code) {
    // case 403:
    //  return i18n.t('aava.messages.action_not_permitted')
    // case 403:
    //  return i18n.t('aava.messages.action_not_permitted')
    case 404:
      return i18n.t('aava.messages.object_not_found')
    case 422:
      return i18n.t('aava.messages.invalid_condition')
    case 500:
      return i18n.t('aava.messages.fatal_error')
    default:
      return i18n.t('aava.messages.fatal_error')
  }
}
const checkErrorMessagesIfSessionHasExpired = errors => {
  let loggedOut = false
  if (errors) {
    errors.forEach((message) => {
      if (message.key === 'app.access.not_authenticated') {
        loggedOut = true
      }
    })
  }
  return loggedOut
}

const getMessageRandomKey = () => {
  return Math.random().toString(36)
}

const messageKeyExists = (key, messages) => {
  return messages.filter(message => message.key === key).length > 0
}

export default {
  deleteItem: ({ dispatch }, { id, resource }): Promise<boolean> => {
    return new Promise(resolve => {
      api.deleteItem(resource, id).then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'delete ' + resource + '/' + id })
        resolve(response.data?.status === 'ok')
      })
    })
  },

  addFlashMessage: ({ state, dispatch }, message: FlashMessage) => {
    const defaultExpireTime = 3000
    const defaultErrorExpireTime = 15000
    if (!message.key) { message.key = getMessageRandomKey() }
    if (messageKeyExists(message.key, state.flashMessages)) { return }
    if (!message.expires && !message.sticky && ['error', 'warning'].includes(message.type)) {
      message.expires = defaultErrorExpireTime
    } else if (!message.expires && !message.sticky) {
      message.expires = defaultExpireTime
    }
    // Set expire timer
    if (message.expires) {
      setTimeout(() => {
        dispatch('clearFlashMessageByKey', message.key)
      }, message.expires)
    }
    // Skip message if exact same messageBody is already showing
    // To avoid duplicate similar (even with different context)
    const messagesWithSameBody = state.flashMessages
      .filter(item => JSON.stringify(item.messageBody) === JSON.stringify(message.messageBody))
    if (messagesWithSameBody.length > 0 && message.messageBody) {
      return
    }
    // Add message to store
    Vue.set(state.flashMessages, state.flashMessages.length, message)
  },

  addFlashMessages: ({ dispatch }, messages) => {
    if (!messages) { return }
    messages.forEach(message => {
      dispatch('addFlashMessage', message)
    })
  },

  clearFlashMessageByKey: ({ state }, key) => {
    const index = state.flashMessages.map(message => message.key).indexOf(key)
    if (index === -1) { return }
    Vue.delete(state.flashMessages, index)
  },

  showMessage: ({ dispatch }, { message, type, expires }: AppMessage) => {
    dispatch('addFlashMessage', {
      message,
      expires,
      type: type || 'info',
    } as FlashMessage)
  },

  emptyPromise: () => {
    return new Promise(resolve => resolve(false))
  },

  setUserId: ({ state }, userId) => {
    state.userId = userId
  },

  showAddressModal: ({ state }, data) => {
    state.addressModalData = data
    state.showAddressModal = true
  },

  // Generic action to handle all (or most) API errors in a single place
  // Returns true if errors found and shown
  globalErrorDisplay: ({ state, dispatch },
    { response, context = '', ignoreErrorCodes = [] }: {
      response: AxiosResponse | null,
      context: string,
      ignoreErrorCodes: (number | string)[],
    }): Promise<boolean> => {
    return new Promise(resolve => {
      // console.log('___', context, response)
      if (!response) { return resolve(false) }
      if (!response.data) {
        console.warn('Request response should have "data"', context, response)
        return resolve(false)
      }
      dispatch('getFlashMessagesFromResponse', response)
      dispatch('getInfoTextFromResponse', response)
      const responseCode: number = response.status
      // To avoid double error codes, sometimes showing error for the user is disabled
      // Like item and list have LP request and meta request, which likely will get the same error
      if (ignoreErrorCodes.includes(responseCode) || !responseCode) {
        return resolve(false)
      }
      // For login to confirm that request went through, if error with request
      // then don't send back to login portal to cause loop
      if (checkErrorMessagesIfSessionHasExpired(response.data.messages)) {
        setTimeout(() => {
          // dispatch('logout', { root: true })
          dispatch('showLoginDialogIfSessionHasExpired', {
            name: 'globalErrorDisplay: ' + context,
            context,
            responseCode,
            response,
          })
          // Also return, as when login dialog is shown, no need to show the error
        }, 400)
        return resolve(true)
      }
      const statusOk = successCodes.includes(responseCode) && (
        response.data.status === 'ok' ||
        !response.data.status // Ex gorilla service for excel export does not have
      )
      if (!responseCode || statusOk) {
        // Everything OK with the response, return
        return resolve(false)
      }

      let collectedMessages = []
      if (response.data.messages?.length) {
        collectedMessages = response.data.messages.map(m => {
          return (m.text ? m.text : m.key) || m
        })
      } else if (serverErrorCodes.includes(response.status)) {
        // In debug mode (from server side) may be additional messages
        collectedMessages = (response.data.debug ?? []).map(m => m.text)
      }

      // if (!response) {
      //   // Unknown error
      //   dispatch('addFlashMessage', {
      //     message: 'Unknown API ERROR for <b>' + context + '</b><br>',
      //     type: 'error',
      //   })
      // } else {
      // }
      const httpErrorMessageOverwritesBodyMessage: number[] = [
        // UPDATE 15.12.22
        // Looks like can't use this at all, it breaks other cases (invalid data for item save)
        // For now let's try prioritize messages always first and keep translating missing messages
        // 422, // Example app.system.search.invalid_condition, which key does not exist in locale
        // 403, // Used to but removed 10.12.22. Have to show error messages also for 403. Stale object etc
      ]
      let displayMessage = ''
      let messageBody = ''
      const finalMessages: TranslateResult[] = []
      if (collectedMessages.length > 0 && !httpErrorMessageOverwritesBodyMessage.includes(responseCode)) {
        // API returns messages, use these and try to translate
        collectedMessages.forEach(message => {
          if (i18n.te('aava.' + message)) {
            // Some keys are without "aava.", so try this first
            // Ex app.system.stale_object
            finalMessages.push(i18n.t('aava.' + message))
          } else {
            finalMessages.push(i18n.t(message))
          }
        })
        messageBody = finalMessages.join('<br>')
        displayMessage = '<b>' + messageBody + '</b><br><i>Error code ' + responseCode + ' for ' + context + '</i>'
      } else {
        // Use error code and appropriate message for this
        messageBody = getErrorMessageForCode(responseCode, i18n)
        displayMessage = '<b>' + messageBody + '</b><br>' + context + ' (' + responseCode + ')'
      }
      dispatch('addFlashMessage', {
        message: displayMessage,
        type: 'error',
        messageBody,
      })

      // Log all errors shown for the user also to Sentry
      Raven.captureMessage('All errors and warnings for ' + state.userInfo?.name, {
        level: 'error',
        extra: {
          context,
          response,
          errorMessages: collectedMessages,
          responseCode,
          userInfo: state.userInfo,
          time: moment().format(),
        }
      })
      resolve(false)
    })
  },

  loadSupportingData: ({ state, dispatch }) => {
    return new Promise(resolve => {
      const promises: Promise<void>[] = [];
      [
        'getMenuItems',
        'getSettings',
        'getGlobalFilters',
        'getSystemInfo',
        'getSystemConfigs',
        'getProcessStyles',
      ].forEach(requestName => {
        promises.push(dispatch('getSupportingDataFromCache', requestName))
      })
      // Now linked_associations is part of user_level_settings, better to not use cache for this
      dispatch('getUserLevelSettings')

      promises.push(dispatch('getTranslationsFromCache'))
      Promise.all(promises).then(() => {
        state.supportedDataLoaded = true
        dispatch('setCustomColors')
        resolve(null)
      })
    })
  },

  setCustomColors: ({ state }) => {
    if (!state.settings) { return }
    const styleProps = [
      'list_row_odd_background_color',
      'list_row_even_background_color',
      'list_actions_row_background_color',
      'list_header_row_background_color',
      'list_tab_default_background_color',
      'list_selected_tab_background_color',
      'list_columns_separator_line_color',
      'body-background-image',
      'body-background-position',
      'item-form-label-color',
      'item-form-value-color',
      'item-form-disabled-value-color',
      'item-form-link-color',
    ]
    styleProps.forEach(styleProp => {
      if (state.settings[styleProp]) {
        document.documentElement.style.setProperty('--' + styleProp, state.settings[styleProp])
      }
    })
  },

  // On app load provide supporting data from cache, when available
  // And after some delay update cache and supporting data in vuex store
  // All this for faster app load
  getSupportingDataFromCache: ({ dispatch, state }, requestName: string) => {
    const propName = inflect.camelize(requestName.substr(3), false)
    return new Promise(resolve => {
      const fromCache = localStorageTtl.get(requestName + '_cache')
      if (fromCache) {
        Vue.set(state, propName, fromCache)
        // state[propName] = fromCache // is not reactive this way?
        resolve(fromCache)
        setTimeout(() => {
          dispatch('loadSupportingDataAndSetCache', { requestName, propName })
        }, 7000) // In worst case with unstable connection app should be showing list or show view in 7 seconds
      } else {
        resolve(dispatch('loadSupportingDataAndSetCache', { requestName, propName }))
      }
    })
  },

  // TODO-23
  loadSupportingDataAndSetCache: ({ dispatch }, { requestName }) => {
    // Cache time 15 min
    const cacheTime = 1000 * 60 * 15
    return new Promise(resolve => {
      dispatch(requestName).then(data => {
        localStorageTtl.set(requestName + '_cache', data, cacheTime)
        resolve(data)
      })
    })
  },

  getMenuItems: ({ state, dispatch }) => {
    return new Promise(resolve => {
      api.fetchMenuItems().then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'Get menu items' })
        state.menuItems = response?.data?.items ?? []
        resolve(state.menuItems)
      })
    })
  },

  getTranslations: ({ state, dispatch }) => {
    return new Promise(resolve => {
      api.fetchTranslations().then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'Get translations' })
        // Set translations to store
        if (response.data) {
          state.translationsByLocale = response.data
          setLocaleMessages(response.data)
          dispatch('setAppLocales', response.data.locales)
          // Set to local storage cache for 4 hours
          localStorageTtl.set('getTranslations_cache', response.data, 1000 * 60 * 60 * 4)
          // Set locales, if returned
        }
        resolve(response.data)
      })
    })
  },

  getTranslationsFromCache: ({ state, dispatch }) => {
    const formCache = localStorageTtl.get('getTranslations_cache')
    if (formCache?.locales) {
      setLocaleMessages(formCache)
      state.translationsByLocale = formCache
      dispatch('setAppLocales', formCache.locales)
      // Got translations from cache, update with delay in the background
      setTimeout(() => {
        dispatch('getTranslations').then()
      }, 4500)
    } else {
      dispatch('getTranslations')
    }
  },

  setAppLocales: ({ state }, locales = []) => {
    if (locales && locales.length > 0) {
      // For now app and content locales are the same
      state.availableAppLocales = state.availableContentLocales = locales
    }
  },

  getProcessStyles: ({ state, dispatch }) => {
    return new Promise(resolve => {
      let processStyles = {}
      api.fetchProcessStyles().then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'Get process styles' })
        if (response.data.object_states) {
          processStyles = {
            object_states: response.data.object_states,
          }
          state.processStyles = processStyles
        }
        resolve(processStyles)
      })
    })
  },

  getSettings: ({ state, dispatch }) => {
    return new Promise(resolve => {
      api.fetchSettings().then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'Get settings' })
        if (response.data.systemConfig) {
          state.settings = response.data.systemConfig
        }
        dispatch('setCustomColors')
        resolve(response.data.systemConfig)
      })
    })
  },

  getGlobalFilters: ({ state, dispatch }) => {
    return new Promise(resolve => {
      api.fetchGlobalFilters().then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'Get global filters' })
        state.globalFilters = response?.data?.data ?? {}
        resolve(state.globalFilters)
      })
    })
  },

  getSystemInfo: ({ state, dispatch }) => {
    return new Promise(resolve => {
      api.fetchSystemInfo().then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'Get system info' })
        state.systemInfo = response?.data?.data ?? {}
        resolve(state.systemInfo)
      })
    })
  },

  getUserLevelSettings: ({ state, dispatch }) => {
    return new Promise(resolve => {
      api.fetchUserLevelSettings().then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'Get linked associations' })
        if (response.data.data?.linked_associations) { // New way
          state.linkedAssociations = response.data.data?.linked_associations
          dispatch('setUserLevelSettings', response.data.data)
          resolve(response.data.data?.linked_associations)
        } else if (response.data) {
          // The old way, if instance on R3
          state.linkedAssociations = response.data
          resolve(response.data)
        }
        resolve(false)
      })
    })
  },

  setUserLevelSettings: ({ state }, data) => {
    const settings = {}
    // Skip linked_associations as this is a separate thing
    Object.keys(data).filter(key => key !== 'linked_associations').forEach(key => {
      settings[key] = data[key]
    })
    state.userLevelSettings = settings
  },

  getSystemConfigs: ({ state, dispatch }) => {
    const systemConfigs = {}
    return new Promise(resolve => {
      api.fetchSystemConfigs().then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'Get system configs' })
        if (response.data.items) {
          response.data.items.forEach(config => {
            systemConfigs[config.parameter] = config.value
          })
          state.systemConfigs = systemConfigs
        }
        resolve(systemConfigs)
      })
    })
  },

  // Note that msg.json could in theory have other types of messages as well
  // and we should handle them here. In practice there are no other types.
  getRestartMessage: ({ state }) => {
    return api.fetchMsgJson()
      .then((response: AxiosResponse) => {
        const { time = null, duration = null } = response.data?.find(msg => msg.type === 'restart') || {}
        state.restartMessage = { time, duration }
      })
  },

  hideRestartMessage: ({ state }) => {
    return new Promise(resolve => {
      state.restartMessage = { time: null, duration: null }
      resolve(null)
    })
  },

  // Show login dialog, but first confirm that session has expired
  // Should come here only if got not-auth from the api
  // or cable ws disconnected. But that may not be reliable enough
  showLoginDialogIfSessionHasExpired: ({ state }, reason) => {
    return new Promise(resolve => {
      api.getUserInfo('my').then((response: AxiosResponse) => {
        if (response.data.status === 'not_authenticated' || !response.data.item?.id) {
          state.showLoginDialog = true
        } else {
          Raven.captureMessage('TEMP-log. Trying to show login dialog without reason: ' + reason?.name, {
            level: 'error',
            extra: { reason },
          })
        }
        resolve(null)
      })
    })
  },
}
