<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8" class="pa-0">
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(item, i) in [...Array(2)]"
              :key="i"
              cols="12"
              :style="'height: ' + (blockHeight * 1.5) + 'px;'"
            >
              <KPI_ModalChart
                :layout="layout"
                :title="layout.chartNames[i+1]"
                :chart="charts[i+1]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="4" class="pa-0">
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              :style="'height: ' + (blockHeight * 3) + 'px;'"
            >
              <KPI_ModalChart
                :layout="layout"
                :title="layout.chartNames[0]"
                :chart="charts[0]"
              />
            </v-col>

          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import KPI_ModalChart from '../KPI_ModalChart'
export default {
  name: 'Layout_2_plus_1_high',

  components: {
    KPI_ModalChart,
  },

  props: {
    layout: {
      type: Object,
      default: () => {},
    },
    charts: {
      type: Array,
      default: () => {},
    },
    blockHeight: {
      type: Number,
      default: 300,
    }
  },
}
</script>
