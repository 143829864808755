export default {
  autoSelectTextOnFocus (e) {
    const autoSelectAllFieldTypes = [
      'string',
      'reference',
      'date',
      'datetime',
      'decimal',
      'quantity',
      'percentage',
      'price',
      'numeric',
      'main_object_process',
    ]
    // Auto-select all text
    if (autoSelectAllFieldTypes.includes(this.field.type)) {
      e.target.select()
    }
  },
}
