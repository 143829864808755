<template>
  <v-container
    class="pa-2"
    fluid
    :style="style"
  >
    <v-dialog
      v-model="deleteDialog"
      width="500"
    >
      <v-card>
        <v-card-text class="pt-3">
          <h3>{{ $i18n.t('aava.confirmations.dialog_title') }}</h3>
          {{ $i18n.t('aava.confirmations.destroy_object') }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="deleteDialog = false"
          >
            {{ $i18n.t('aava.general.no') }}
          </v-btn>
          <v-btn
            :color="'error'"
            text
            @click="deleteHandler"
          >
            {{ $i18n.t('aava.actions.remove') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row no-gutters>
      <v-col
        style="min-width: 100px; max-width: 100%;"
        cols="10"
        class="shortcut_bar_container flex-grow-1 flex-shrink-0"
      >
        <DashboardShortcuts v-if="showShortcuts && firstTabIsSelected" />
      </v-col>

      <v-col
        cols="12"
        class="ui text dashboardItems_all"
      >
        <v-container
          v-if="!dashboardItemsLoaded"
          class="no_objects"
        >
          <v-row>
            <v-col class="text-center">
              <v-icon class="fa-spin">
                fa-spinner
              </v-icon>
            </v-col>
          </v-row>
        </v-container>
        <v-container fluid>
          <v-row id="dashboard-items">
            <v-col
              v-for="(dashboardItem, index) in dashboardItems"
              :key="dashboardItem.id"
              :cols="12 / spaceAllocation.cols"
              :class="'preview_table_container'"
            >
              <DashboardPreviewList
                v-if="dashboardItem.previewData && dashboardItem.previewData.items"
                :dashboard-item="dashboardItem"
                :space-allocation="spaceAllocation"
                :space-for-columns="spaceForColumns[index]"
                @delete="confirmDashboardItemDeletion"
              />
              <DashboardChart
                v-else-if="dashboardItem && dashboardItem.element_type === 'chart'"
                :dashboard-item="dashboardItem"
                :chart-data="dashboardItem.previewData"
                :index="(index + '-' + dashboardItem.id)"
                :space-allocation="spaceAllocation"
                :space-for-columns="spaceForColumns[index]"
                @delete="confirmDashboardItemDeletion"
              />
            </v-col>
            <v-col
              v-if="dashboardItemsLoaded"
              :cols="12 / spaceAllocation.cols"
              class="add_box_container"
            >
              <DashboardItemAdd :space-allocation="spaceAllocation" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardPreviewList from './DashboardPreviewList'
import DashboardChart from './DashboardChart'
import DashboardItemAdd from './DashboardItemAdd'
import DashboardShortcuts from './DashboardShortcuts'
import sharedUtilities from '@/utilities/sharedUtilities'
import dashboardSpaceAllocation from '@/components/Dashboard/dashboardSpaceAllocation'
import Sortable from 'sortablejs'
import api from '@/store/api'
import dashboardMethods from './dashboardMethods'

export default {
  name: 'DashboardItems',

  components: {
    DashboardPreviewList,
    DashboardChart,
    DashboardItemAdd,
    DashboardShortcuts,
  },

  props: {
    showShortcuts: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      currentlyDragging: null,
      loggedEvent: '',
      spaceForColumns: [],
      spaceAllocation: dashboardSpaceAllocation.getInitParams(),
      dashboardItems_before: [],
      deleteDialog: false,
      deleteItemId: null,
      sortable: null,
    }
  },

  computed: {
    style () {
      return {
        height: (this.$store.state.innerHeight - 100) + 'px',
        overflow: 'auto',
      }
    },

    firstTabIsSelected () {
      const selectedTabIndex = this.$store.state.dashboardTabs.map(tab => tab.id)
        .indexOf(this.$store.state.selectedDashboardTabId)
      return selectedTabIndex === 0
    },

    dashboardRefreshTimeout () {
      return this.$store.state.dashboardRefreshTimeout
    },

    dashboardItemsLoaded () {
      return this.$store.state.dashboardItemsLoaded
    },
    dashboardItems: {
      get () {
        // Filter items which belong to the selected tab
        // And sort by the sort_order
        return this.$store.state.dashboardItems.slice().filter(item => {
          const tabIsMissing = !item.tabId &&
            // And is first tab
            this.$store.state.dashboardTabs[0]?.id === this.$store.state.selectedDashboardTabId
          const belongsToTheTab = item.tabId === this.$store.state.selectedDashboardTabId
          return tabIsMissing || belongsToTheTab
        }).sort((a, b) => a.sort_order < b.sort_order ? -1 : 1)
      },
      set (value) {
        // Do nothing as method is called on drag end
        // this.$store.commit('updateDashboardItemsOrder', value)
      },
    },
  },

  watch: {
    dashboardRefreshTimeout (value) {
      const stop_refresh = value === '0' || value === false
      if (stop_refresh && this.refresh_interval) {
        // stop refreshing if it is on
        clearInterval(this.refresh_interval)
        this.refresh_interval = null
      } else if (parseInt(value) > 0) {
        // Start refreshing if refresh-timer is set
        if (this.refresh_interval) { clearInterval(this.refresh_interval) }
        const refresh_interval = value * 1000 * 60
        this.refresh_interval = setInterval(this.refresh, refresh_interval)
        this.$store.state.dashboardRefreshTime = new Date()
      }
    }
  },

  beforeDestroy () {
    clearInterval(this.refresh_interval)
  },

  updated () {
    if (JSON.stringify(this.dashboardItems) !== JSON.stringify(this.dashboardItems_before)) {
      this.resizeContainers()
      this.dashboardItems_before = this.dashboardItems.slice()
    }
  },

  created () {
    this.resizeContainers()
    this.createDashboardSortables()
    window.addEventListener('resize', this.resizeHandler)
  },

  destroyed () {
    window.removeEventListener('resize', this.resizeHandler)
  },

  methods: {
    ...dashboardMethods,

    createDashboardSortables () {
      this.$nextTick(() => {
        this.sortable = null
        const el = document.getElementById('dashboard-items')
        if (!el) { return }
        this.sortable = Sortable.create(el, {
          onEnd: this.dashboardItemDragEnd,
          chosenClass: 'dragged_preview',
          delay: 150,
          animation: 200,
          // dragClass: '',
        })
      })
    },

    dashboardItemDragEnd (move) {
      const temp = this.dashboardItems.splice(move.oldIndex, 1)[0]
      this.dashboardItems.splice(move.newIndex, 0, temp)
      this.$store.commit('updateDashboardItemsOrder', this.dashboardItems)
    },

    resizeHandler () {
      this.resizeContainers()
    },

    resizeContainers () {
      const sa = dashboardSpaceAllocation.calculateParams(this.dashboardItems)
      this.spaceAllocation = sa

      // dynamic css for preview item table
      this.dashboardItemStyle = {
        height: sa.box_height + 'px',
        width: sa.box_width + 'px',
        margin: sa.margin_px + 'px'
      }
      this.dashboardItemsStyle = {
        width: sa.width + 'px',
        height: sa.area_height + 'px'
      }

      // Calculate MAX items
      // adding + SPACE_BETWEEN_OBJECTS_PX because first object does now have space above
      this.spaceAllocation.max_items = dashboardSpaceAllocation.calcMaxItemsInTable(this.spaceAllocation)

      // Calculate space for columns
      this.spaceForColumns = dashboardSpaceAllocation.calcSpaceForColumns(this.dashboardItems, sa)
    },

    confirmDashboardItemDeletion (id) {
      this.deleteItemId = id
      this.deleteDialog = true
    },

    deleteHandler () {
      this.deleteDashboardItem(this.deleteItemId)
      this.deleteDialog = false
      this.deleteItemId = null
    },

    refresh () {
      this.refreshDashboardItems()
      this.$store.state.dashboardRefreshTime = new Date()
      // TODO - must it be here?
      this.createDashboardSortables()
    }
  }
}
</script>

<style>
.remove_button_container {
  position: absolute;
  top: -5px;
  right: -5px;
}

.remove_button {
  padding: 4px 8px;
  width:30px !important;
  height:22px;
  color: #cccccc;
  margin: 5px 5px 0 0;
  cursor: pointer;
  border-radius: 50px;
}
</style>

<style scoped>
  .dashboard-table td {
    font-size: 12px;
  }

  .dashboard-table th {
    font-size: 12px;
  }

  .preview_table {
    width: 300px;
    height: 300px;
    float: left;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.75);
  }

  .dashboardItems_all {
    width: 100%;
  }

  .preview-row {
    clear: both;
    overflow: auto;
  }

  .dragged_preview {
    outline-offset: -5px;
    outline: 5px solid #fff159;
    cursor: move;
    cursor: -webkit-grabbing;
  }
</style>
