import dashboardConfig from '@/components/Dashboard/dashboardConfig'

const dashboardSpaceAllocation = {
  getInitParams () {
    return {
      rows: dashboardConfig.DASHBOARD_DEFAULT_ROWS,
      width: 0,
      height: 0,
      cols: 0,
      max_items: 1,
      box_width: 0,
      box_height: 0,
      area_height: 0
    }
  },

  // Calculate one preview box measures
  // Based on window width+height and configuration parameters
  calculateParams (dashboardItems) {
    dashboardItems = dashboardItems || []
    const sa = dashboardSpaceAllocation.getInitParams()
    sa.width = window.innerWidth
    sa.height = window.innerHeight
    sa.scrollbar_px = dashboardConfig.SCROLLBAR_WIDTH_PX
    sa.margin_px = dashboardSpaceAllocation.getMarginPX(sa.width)

    // switch to one row when not enough height
    if (sa.height < dashboardConfig.MIN_HEIGHT_FOR_2_ROWS) {
      sa.rows = 1
    }

    // set no scrollbar space when scrollbar is hidden
    // TODO ? modernizr.hiddenscroll
    // if (modernizr.hiddenscroll) {
    //  sa.scrollbar_px = 0
    // }

    // how many columns can fit?
    sa.cols = Math.floor(sa.width / dashboardConfig.PREVIEW_BOX_MIN_WIDTH_PX)
    if (sa.cols > 3) sa.cols = dashboardConfig.DASHBOARD_MAX_COLUMNS
    else if (sa.cols < 1) sa.cols = 1

    // Not over two rows of dashboard items > no scrollbar needed
    if (dashboardItems.length <= 2 * sa.cols) {
      sa.scrollbar_px = 0
    }

    // calculate whitespace required
    const width_spacing = sa.cols * sa.margin_px * 2 + // margin both side
      2 * sa.margin_px // margin on page sides
    const height_spacing = sa.margin_px * 2 * sa.rows + // box margins on top and bottom * rows
      dashboardConfig.TOP_MENU_HEIGHT_PX + // general top menu px
      dashboardConfig.DASHBOARD_SHORTCUTS_HORIZONTAL_LINE_HEIGHT_PX + // horizontal shortcuts
      2 * dashboardConfig.DASHBOARD_APP_MARGIN // margin on page top and bottom of page

    // calculate box measures
    sa.box_width = Math.floor((sa.width - width_spacing - sa.scrollbar_px) / sa.cols)
    sa.box_height = Math.floor((sa.height - height_spacing) / sa.rows)

    // dashboard area height, to fill exactly 100%, if goes 1px over, scrollbar appears
    sa.area_height = sa.height -
      dashboardConfig.TOP_MENU_HEIGHT_PX -
      2 * dashboardConfig.DASHBOARD_APP_MARGIN -
      dashboardConfig.DASHBOARD_SHORTCUTS_HORIZONTAL_LINE_HEIGHT_PX -
      1 // because of rounding it can be >= 100.1% and causes a scrollbar
    return sa
  },

  // Calculate max items in one Layout Profile preview box
  calcMaxItemsInTable (spaceAllocation) {
    return Math.floor(
      (spaceAllocation.box_height -
        dashboardConfig.BOX_TITLE_HEIGHT_PX -
        dashboardConfig.PREVIEW_BOX_MARGIN_PX +
        dashboardConfig.SPACE_BETWEEN_OBJECTS_PX
      ) / (dashboardConfig.ONE_OBJECT_HEIGHT_PX + dashboardConfig.SPACE_BETWEEN_OBJECTS_PX))
  },

  // Calculate width in pixels for each column in the preview
  // For all dashboardItems
  // This needed because we want to use text-overflow: ellipsis which expects width in pixels for best result
  calcSpaceForColumns (dashboardItems, spaceAllocation) {
    const divide_pixels = spaceAllocation.box_width - dashboardConfig.PROGRESS_STATE_CELL_MIN_PX
    const min_length = 6
    const max_length = 30
    const spaceForColumns = []
    let max_lengths

    dashboardItems.forEach((preview, preview_i) => {
      max_lengths = []

      if (preview.previewData.items) {
        preview.previewData.items.forEach((item) => {
          preview.previewData.column_order.forEach((column_name, column_i) => {
            if (!max_lengths[column_name]) max_lengths[column_name] = 0
            const value = item['col' + column_i + '_show']
            if (value) {
              if (value.length > max_lengths[column_name]) {
                max_lengths[column_name] = value.length > max_length ? max_length : value.length
              }
            }
          })
        })

        // total lengths
        max_lengths.total_length = 0
        preview.previewData.column_order.forEach((column_name) => {
          // Additional space for smaller fields
          if (max_lengths[column_name] < 6) {
            max_lengths[column_name] = max_lengths[column_name] * 2
          } else if (max_lengths[column_name] < 12) {
            max_lengths[column_name] = Math.ceil(max_lengths[column_name] * 1.5)
          }
          if (max_lengths[column_name] >= 0 && max_lengths[column_name] < min_length) {
            max_lengths[column_name] = min_length
          }
          max_lengths.total_length += max_lengths[column_name]
        })

        // calc pixels
        preview.previewData.column_order.forEach((column_name) => {
          max_lengths[column_name + '_px'] = Math.ceil(divide_pixels / 100 *
            (max_lengths[column_name] * 100 / max_lengths.total_length))
        })
      }
      spaceForColumns[preview_i] = max_lengths
    })
    return spaceForColumns
  },

  getMarginPX (width) {
    let margin_px = dashboardConfig.PREVIEW_BOX_MARGIN_PX
    // apply mobile or tablet margin
    if (width <= 767) {
      margin_px = dashboardConfig.PREVIEW_BOX_MARGIN_MOBILE_PX
    } else if (width <= 1024) {
      margin_px = dashboardConfig.PREVIEW_BOX_MARGIN_TABLET_PX
    }
    return margin_px
  },
}

export default dashboardSpaceAllocation
