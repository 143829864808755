
import api from '../../store/api'
import { DashboardTypes } from '@/types/DashboardTypes'

export default {
  name: 'DashboardItemAddOption',

  props: {
    option: {
      default () { return {} },
      type: Object,
    },
    spaceAllocation: {
      default () { return {} },
      type: Object,
    },
  },

  computed: {
    optionContainerStyle () {
      return {
        width: (this.spaceAllocation.box_width / 2) - 40 + 'px' // 20px - padding
      }
    },
  },

  methods: {
    addDashboardItem (option) {
      if (option.added) {
        return
      }
      const type = option.id ? 'preview' : 'chart'
      this.$parent.$parent.hideForm()
      this.$store.state.dashboardAddingItemNow = true
      api.gorillaAddDashboardItem({
        type,
        name: option.identifier, // For chart
        layoutProfileId: option.id, // For preview list
        config: JSON.stringify({ tabId: this.$store.state.selectedDashboardTabId })
      } as DashboardTypes.PreviewOrChart).then(() => {
        this.$store.dispatch('loadAndPrepareDashboardContent')
      })
    },
  }
}
