<template>
  <v-container
    class="item-form-container pa-0 form-visual-demo"
    fill-height
    fluid
  >
    <div class="containers-outer">
      <div class="container-form">
        <v-container
          class="field-set-form pa-0"
          fluid
        >
          <table
            class="field-set-form-inner-container"
            style="width: 100%;"
          >
            <tr
              v-for="(field, i) in fields"
              :key="i"
            >
              <td class="single-field-set">
                <FormField
                  :edit="false"
                  :field="{
                    ...field,
                    label: field.title + ' show view',
                  }"
                  v-bind="bindProps"
                />
              </td>
              <td class="single-field-set">
                <FormField
                  :edit="true"
                  :field="{
                    ...field,
                    label: field.title + ' edit readonly',
                    writable: false,
                  }"
                  v-bind="bindProps"
                />
              </td>
              <td class="single-field-set">
                <FormField
                  :edit="true"
                  :field="{
                    ...field,
                    label: field.title + ' edit view',
                    writable: true,
                  }"
                  v-bind="bindProps"
                />
              </td>
              <td class="multiple-field-sets">
                <FormField
                  :edit="false"
                  :field="{
                    ...field,
                    label: field.title + ' show view',
                  }"
                  v-bind="bindProps"
                />
              </td>
              <td class="multiple-field-sets">
                <FormField
                  :edit="true"
                  :field="{
                    ...field,
                    label: field.title + ' edit readonly',
                    writable: false,
                  }"
                  v-bind="bindProps"
                />
              </td>
              <td class="multiple-field-sets">
                <FormField
                  :edit="true"
                  :field="{
                    ...field,
                    label: field.title + ' edit view',
                    writable: true,
                  }"
                  v-bind="bindProps"
                />
              </td>
            </tr>
          </table>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
import FormField from '@/components/Item/FormField'
import itemPickerMethods from '@/components/ItemPicker/itemPicker.methods'
import methods from '@/components/methods'
import { createHelpers } from 'vuex-map-fields'

import state from './../../store/state'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'FormVisual',

  components: {
    FormField,
  },

  data () {
    return {
      resource: 'Work',
      fields: [
        {
          name: 'boolean',
          title: 'Boolean',
          type: 'boolean',
        },
        {
          name: 'styled_boolean',
          title: 'Styled boolean',
          type: 'boolean',
        },
        {
          name: 'custom_boolean',
          title: 'Custom boolean',
          type: 'boolean',
          items: {
            true: {
              show_text_fi: 'Jeppis ja jepulis',
              color: '#1E88E5',
            },
            false: {
              show_text_fi: 'Nääh',
              text_fi: 'Ei hotsita',
              color: '#D32F2F',
            },
          },
        },
        {
          name: 'string',
          title: 'String',
          type: 'string',
        },
        {
          name: 'styled_string',
          title: 'Styled',
          type: 'string',
        },
        {
          name: 'multilang_string',
          title: 'Multilang string',
          type: 'string',
          multi_language: true,
        },
        {
          name: 'empty_string',
          title: 'Empty string',
          type: 'string',
        },
        {
          name: 'price',
          title: 'Price',
          type: 'price',
        },
        {
          name: 'quantity',
          title: 'Quantity',
          type: 'quantity',
        },
        {
          name: 'date',
          title: 'Date',
          type: 'date',
        },
        {
          name: 'datetime',
          title: 'Datetime',
          type: 'datetime',
        },
        {
          name: 'responsible_person',
          title: 'Reference',
          type: 'reference',
          reference_class: 'people',
        },
        {
          name: 'address',
          widget: 'address',
          title: 'Address',
          type: 'address',
        },
        {
          name: 'richtext',
          title: 'Richtext',
          type: 'richtext',
        },
        {
          name: 'static_list',
          title: 'Static list',
          type: 'static_list',
          items: ['new', 'old'],
        },
        {
          name: 'text',
          title: 'Text',
          type: 'text',
          multi_language: true,
        },
        {
          name: 'files',
          title: 'Files',
          type: 'files',
          writable: true,
        },
        {
          name: 'images',
          title: 'Images',
          type: 'image',
          writable: true,
        },
      ],
      item: {
        id: 1,
        '@member_styles': {
          styled_string: 'background: red; color: white;',
          datetime: 'background-color: rgba(33, 150, 243, 0.48);',
          styled_boolean: 'background-color: rgba(33, 150, 100, 0.3); font-weight: 500;',
          quantity: 'background-image: linear-gradient(#f2f2f2,#f2f2f2), linear-gradient(to left,  #438600 , #8fd747 ); background-size:var(--p, 0.0%) 100%,100% 100%; background-position:right,left; background-repeat:no-repeat;',
        },
        '@prevent_write_members': [],
        '@editable': true,
        '@class': 'Work',
        boolean: false,
        styled_boolean: true,
        custom_boolean: true,
        string: 'String with empty value',
        multilang_string_fi: 'In Finnish',
        multilang_string_en: 'In English',
        empty_string: '',
        date: '2022-02-15',
        datetime: '2022-02-19T11:33:09.000+02:00',
        responsible_person: null,
        richtext: 'Some text <p> in multiple<br> <b>lines..</b>',
        address: {
          street_1: 'Korona katu 13',
          postal_code_string: 12345,
          postal_office_string: 'Harju',
          country: {
            id: 1,
            summary: 'Finland',
          },
          summary: 'Korona katu 13, Harju 12345, Finland',
        },
        static_list: 'new',
        images: [],
        files: [],
        text_fi: 'Textbox',
        styled_string: 'Styled string content',
        quantity: '1759',
        quantity_unit: {
          '@class': 'Unit',
          identifier: 'm2',
        },
        price: '17923.50',
        price_currency: {
          '@class': 'Currency',
          identifier: 'eur',
        },
      },
    }
  },

  created () {
    this.objectClass = 'works'
  },

  computed: {
    ...mapFields(Object.keys(state)),

    bindProps () {
      return {
        item: this.item,
        resource: this.resource,
        showItemPickerFor: this.showItemPickerFor,
      }
    }
  },

  methods: {
    ...methods,
    ...itemPickerMethods,
  },
}
</script>

<style lang="scss" scoped>
.field-set-form-inner-container {
  td {
    width: 16.66%;
  }
}
.form-visual-demo {
  .single-field-set, .multiple-field-sets {
    padding: 10px 5px;
  }
  .multiple-field-sets {
    background: #f6f6f6;
  }
}
</style>
