var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.resource)?_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({
    up: ['ctrl', 'shift', 'arrowup'],
    down: ['ctrl', 'shift', 'arrowdown'],
    left: ['ctrl', 'shift', 'arrowleft'],
    right: ['ctrl', 'shift', 'arrowright'],
  }),expression:"{\n    up: ['ctrl', 'shift', 'arrowup'],\n    down: ['ctrl', 'shift', 'arrowdown'],\n    left: ['ctrl', 'shift', 'arrowleft'],\n    right: ['ctrl', 'shift', 'arrowright'],\n  }"}],class:_vm.draggingSplitLine ? 'no-text-select' : '',staticStyle:{"margin-top":"0","height":"100%"},on:{"shortkey":_vm.toggleSplitModeFromShortcut}},[_c('div',{style:(_vm.listStyle)},[(!_vm.suppressToolbar)?_c('ListToolbar'):_vm._e(),(_vm.showListColumnsSelector)?_c('ListColumnsSelectorDialog'):_vm._e(),_c('header-actions-confirm-bulk-delete'),_c('header-column-menu'),_c('header-column-states-menu'),_c('header-column-unique-values-menu'),(_vm.listHeaderDateFilterField)?_c('header-column-date-filter-menu'):_vm._e(),(!_vm.suppressToolbar && _vm.splitMode !== 'horizontal' && _vm.$store.getters.showListTabsOnSeparateToolbar && !_vm.loadingLayoutProfiles)?_c('v-toolbar',{staticClass:"list-toolbar elevation-0",attrs:{"height":"35","color":"grey lighten-2","text":""}},[_c('LayoutProfileTabs')],1):_vm._e(),_c('ListViewContainer')],1),_c('div',{style:(_vm.itemStyle)},[(!_vm.splitProps.secondResource && _vm.splitModeFile.filename)?_c('SplitModeFile'):_vm._e(),(_vm.id || _vm.splitProps.firstItemId)?_c('Item',{ref:"main_item",class:!_vm.splitProps.secondResource && _vm.splitModeFile.filename ? 'd-none' : '',attrs:{"id":_vm.splitProps.firstItemId || _vm.id,"target-resource":_vm.passTargetToItemForm ? _vm.targetResource : '',"target-id":_vm.passTargetToItemForm ? _vm.targetId : '',"target-field":_vm.passTargetToItemForm ? _vm.targetField : '',"item-action":_vm.splitProps.firstItemId
        ? _vm.splitProps.firstItemId === 'new'
          ? 'edit'
          : _vm.splitProps.firstItemView
        : _vm.id === 'new'
          ? 'edit'
          : _vm.itemAction,"resource":_vm.splitProps.firstItemId ? _vm.splitProps.firstResource : _vm.resource,"item-form-position-delete":_vm.splitProps.firstItemId ? 'first' : '',"item-form-position":"first"}}):_vm._e()],1),(_vm.splitProps.secondResource)?_c('div',{staticClass:"second-split-mode-container",style:(_vm.secondSplitWindowStyle)},[(_vm.splitModeFile.filename)?_c('SplitModeFile'):_vm._e(),(_vm.splitProps.secondItemId)?_c('Item',{ref:"split_view_item",class:_vm.splitModeFile.filename ? 'd-none' : '',attrs:{"id":_vm.splitProps.secondItemId,"item-action":_vm.splitProps.secondItemId === 'new' ? 'edit' : _vm.splitProps.secondItemView,"resource":_vm.splitProps.secondResource,"item-form-position":"second"}}):_vm._e()],1):_vm._e(),(_vm.splitMode === 'vertical')?_c('div',{staticClass:"vertical-split-line",style:(_vm.verticalSplitLineStyle),on:{"mousedown":function($event){return _vm.startSplitLineDrag('vertical')}}}):(_vm.splitMode === 'horizontal')?_c('div',{staticClass:"horizontal-split-line",style:(_vm.horizontalSplitLineStyle),on:{"mousedown":function($event){return _vm.startSplitLineDrag('horizontal')}}}):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }