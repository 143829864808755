import dateTimeHelper from './../../methods/date_time_helper'
import currencySymbols from './../../methods/symbol'
import Label from './../../methods/label'
import sharedUtilities from '@/utilities/sharedUtilities'
import { ListEditSavePayload } from '@/types/Item'
import { BaseItem } from '@/types/AppTypes'
import { LP, LPI } from '@/types/LP.types'
import { Field } from '@/types/FieldTypes'

const getStaticListValue = (cellValue: string, field: LPI, translation: string) => {
  return Label.is_translation_key(field.items[cellValue])
    ? translation
    : field.items[cellValue]
}

export default {
  isLinkedAssociate (className: string) {
    // Note ObjectState is in linked associations? But should not be linked from the list
    return this.$store.state.linkedAssociations.includes(className)
  },

  saveValueInListHandler ({ e, field, eventButton, callback }: ListEditSavePayload) {
    if (eventButton) {
      return this.addEventActionToQueue({ eventButton, callback })
    }
    return this.addSaveJobToQueue({
      e,
      item: this.listItem,
      field: field || this.field,
    })
  },

  searchSimilar (e) {
    const text = e.target.parentElement?.parentElement

    // If state name div is included, remove it
    const stateNameDiv = text.querySelector('.reference-state-button')
    if (stateNameDiv) {
      stateNameDiv.parentNode.removeChild(stateNameDiv)
    }

    // Get text content
    const innerText = text.textContent.trim()
    if (!innerText) { return }
    const searchVal = ['static_list'].includes(this.field.type) ? '"' + innerText + '"' : '="' + innerText + '"'
    this.$set(this.listFilters, this.field.name, searchVal)
    this.reloadListItems()
  },

  getItemFieldDisplayText ({ inputItem, inputField, withUnit = true }: {
    inputItem?: BaseItem,
    inputField?: LP.Item,
    withUnit?: boolean
  } = {}): string {
    const item = inputItem || this.listItem || this.item // List or Item view
    const field = inputField || this.field
    const fieldName = field.multi_language
      ? field.name + '_' + this.$store.state.locale
      : field.name
    // TODO - changed this.item here to item, test that it does not cause issues in list or item view
    let rawValue = this.cellValue || (item && field && item[fieldName]) // List or Item view
    // In case of multi lang AND has-many value, for some reason locale is not used in field name
    // So take value from 'name' if 'name_en' is empty
    // TODO. Investigate. Case: Oma work has children works
    if (!rawValue && field.multi_language && (item && field && item[field.name])) {
      rawValue = item[field.name]
    }
    if (['price', 'quantity'].includes(field.type)) {
      if ([null, undefined].includes(rawValue)) {
        return ' '
        // return 'unset-value-placeholder' - deprecated
      }
    } else if (!field || !field.name || !item || !rawValue) {
      return ' '
      // return field.dynamic ? 'empty-value-placeholder' : ' ' - deprecated
    }
    switch (field.type) {
      case 'datetime':
        return dateTimeHelper.formatDateAndTime(this.cellValue || rawValue)
      case 'price':
        return this.getPriceDisplayTextWithCurrency(rawValue, item, field, withUnit)
      case 'date':
        return dateTimeHelper.formatDate(rawValue)
      case 'link':
        if (rawValue.includes('<a ')) { // API returns link already with <a> tag
          return (rawValue.includes('_blank')
            ? rawValue
            : rawValue.replace('<a ', '<a target="_blank" ')).toString() // Open in new tab
        }
        // eslint-disable-next-line no-case-declarations
        const link = rawValue.includes('://') ? rawValue : 'https://' + rawValue
        return '<a href="' + link + '" target="_blank">' + link + '</a>'
      case 'decimal':
        return this.getDecimalDisplayTextWithUnit(rawValue, field, item)
      case 'quantity':
        return this.getQuantityDisplayTextWithUnit(rawValue, field, item, withUnit)
      // TODO Localize decimals /exchange_rates
      // Issue now is that they do not (always?) have precision set and are as string texts
      // In order to localize in a good way need to convert it to float and keep decimal places
      // There are hacks to use, but can it have precision?
      // case 'decimal':
      //  return this.formatDecimalValueForLocale(rawValue)
      case 'percentage':
        return this.formatFloatValueForLocale(rawValue) + ' %'
      case 'text': // In show view support line breaks
        return rawValue?.replaceAll('\n', '<br>')
      case 'has_many_reference':
        return rawValue
      case 'polymorphic_autocomplete':
      case 'reference': // For column selector
        return rawValue[field.reference_attribute || 'summary']
      case 'dynamic_list':
      case 'static_list':
        // Set field items for getStaticListValue to not crash
        // In case of item picker column type = boolean we don't have static_list items
        // in attribute metadata response
        field.items = field.items || {}
        return getStaticListValue(rawValue, field,
          this.$i18n.t(field.items[rawValue])) || this.$i18n.t(rawValue)
      default:
        return rawValue
    }
  },

  getPriceDisplayTextWithCurrency (cellValue, item, field, withUnit) {
    const defaultPrecisionForPrice = 2
    const identifierText = item[field.name + '_currency'] &&
    item[field.name + '_currency'].identifier
      ? item[field.name + '_currency'].identifier
      : ''
    const priceDecimalsDisplay = item?.[field.name + '_currency']?.decimals_display
    let precision = field.precision
    // Use currency precision only if field precision not set
    const precisionNotSet = precision === undefined || precision === null
    if (precisionNotSet && (priceDecimalsDisplay || priceDecimalsDisplay === 0)) {
      precision = priceDecimalsDisplay
    }
    const symbol = currencySymbols[identifierText] || identifierText
    if (!precision && precision !== 0) {
      precision = defaultPrecisionForPrice
    }
    return this.formatFloatValueForLocale(cellValue, precision) +
      (withUnit ? (' ' + symbol) : '')
  },

  getQuantityDisplayTextWithUnit (cellValue, field, item, withUnit) {
    const unitDisplay = item?.[field.name + '_unit']?.name || ''
    const unitDecimalsDisplay = item?.[field.name + '_unit']?.decimals_display
    let precision = field.precision
    // Use unit precision only if field precision not set
    const precisionNotSet = precision === undefined || precision === null
    if (precisionNotSet && (unitDecimalsDisplay || unitDecimalsDisplay === 0)) {
      precision = unitDecimalsDisplay
    }
    const formattedForLocale = cellValue !== null ? this.formatFloatValueForLocale(cellValue, precision) : ''
    return formattedForLocale +
      (withUnit
        ? (' ' + (unitDisplay !== 'undefined' ? unitDisplay : ''))
        : '')
  },

  getDecimalDisplayTextWithUnit (cellValue, field, item) {
    const unitDisplay = item?.[field.name + '_unit']?.name || ''
    // TODO - this solution now loses decimal places for currency_rates
    const defaultPrecisionForDecimal = 2
    const formattedForLocale = this.formatFloatValueForLocale(cellValue, field.precision || defaultPrecisionForDecimal)
    return formattedForLocale + ' ' + unitDisplay
  },

  formatFloatValueForLocale (value: any, precision = 2, skipPrecision = false) {
    precision = precision || precision === 0 ? precision : 0 // Just in case null is passed
    let options: any = { minimumFractionDigits: precision, maximumFractionDigits: precision }
    if (skipPrecision) { options = {} }
    value = value && parseFloat(value.toString().match(/-?(?:\d+(?:\.\d*)?|\.\d+)/)?.[0])
    const locale = this.$store.state.locale
    return value?.toLocaleString(locale, options)
      // Use actual space for a thousand separator. Otherwise, Cypress tests do not work
      ?.replace(/&nbsp;|\u00A0|\u200B/g, ' ')
  },

  // TODO - move, wrong place (not from list)
  openReferenceItemFromItemView (className: string, id: number) {
    className = sharedUtilities.objectClassUnderscoredName(className)
    // No split mode? Open simple one item show view
    if (!this.$store.state.splitMode) {
      this.$store.dispatch('listItemOpenById', {
        className,
        id,
        // TODO - is there a case where it should be false by default?
        // 26.07.22 case: open smoke things
        // add new smoke_top_parts in new window
        // go back through reference link to parent object
        // no need for x/for/x in the url, which caused parent item save issues.
        skipTargetItem: true,
      })
      const formContainer = document.getElementById('item-form-container')
      if (formContainer) {
        formContainer.scrollTop = 0
      }
    } else {
      // All split mode link open is now handled in openAlternateModelItemInSplitMode
      // Get current split model+id's from url
      const hashParts = window.location.hash.split('/')
      // If second item split window is active
      if (hashParts[6]) {
        this.$router.push({
          path: '/' + this.$store.state.objectClass + '/split/' +
            hashParts[3] + '/' + hashParts[4] + '/' + hashParts[5] + '/' +
            className + '/' + id + '/show'
        })
      } else {
        this.$router.push({
          path: '/' + this.$store.state.objectClass + '/split/' +
            className + '/' + id + '/show'
        })
      }
    }
  },

  openAttachmentFile (file: Field.File) {
    window.open('/content/attachments/' + file.id + '/' + file.filename)
  },
}
