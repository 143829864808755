<template>
  <v-row
    v-if="data"
    class="no-se-message-row"
    fluid
  >
    <v-col
      v-if="supportMultipleMessages"
      cols="12"
      class="no-se-message-buttons"
    >
      <v-btn
        fab
        small
        class="no-se-message-row-remove"
        @click="$emit('removeMessageRow', data.key)"
      >
        <v-icon>fa fa-trash</v-icon>
      </v-btn>
      <v-btn
        fab
        small
        class="no-se-message-show-attributes"
        @click="attributesVisible = !attributesVisible"
      >
        <v-icon>fa fa-th-list</v-icon>
      </v-btn>
    </v-col>
    <v-col
      cols="6"
      class="no-se-message-data"
    >
      <NoSeInputWithTags
        ref="subject"
        v-model="data.subject"
        :class="subjectFieldClass()"
        :placeholder-text="$i18n.t('aava.no_se.message_subject')"
        @focus="focusElement = 'subject'"
      />
      <v-select
        ref="actionSelect"
        v-model="data.recipients"
        :items="possibleRecipients"
        :label="$i18n.t('aava.no_se.message_recipients')"
        class="no-se-message-recipients"
        multiple
        outline
      />
      <NoSeInputWithTags
        ref="text"
        v-model="data.text"
        class="no-se-message-text no-se-input"
        box
        :placeholder-text="$i18n.t('aava.no_se.message_content')"
        auto-grow
        @focus="focusElement = 'text'"
      />
    </v-col>
    <v-col
      cols="6"
      class="no-se-message-data"
    >
      <no-se-attribute-picker
        :data="{ attributes: attributes, fixedAttributes: fixedAttributes, objectClass: objectClass }"
        @attributeSelected="addAttributeToCursor"
      />
    </v-col>
  </v-row>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import NoSeAttributePicker from './NoSeAttributePicker'
import NoSeInputWithTags from './NoSeInputWithTags'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'NoSeMessage',

  components: {
    NoSeAttributePicker,
    NoSeInputWithTags
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data () {
    return {
      focusElement: null,
      attributesShown: false,
      supportMultipleMessages: false // for now, perhaps later
    }
  },

  computed: {
    attributes () {
      return this.$store.state.noSeAttributes[this.$store.state.objectClass]
    },

    fixedAttributes () {
      // action attributes are shown only for process events
      if (this.$store.state.noSeEditedNotification.type === 'event' &&
        this.$store.state.noSeEditedNotification.event &&
        this.$store.state.noSeEditedNotification.event.object_process) {
        return this.$store.state.noSeFixedAttributes.concat(this.$store.state.noSeActionAttributes)
      } else {
        return this.$store.state.noSeFixedAttributes
      }
    },

    possibleRecipients () {
      const fromClass = this.$store.state.noSeRecipients[this.$store.state.objectClass].map((attr) => {
        return { text: this.attributeTranslation(attr.name), value: attr.name }
      })
      const all = [{ text: this.$i18n.t('aava.no_se.me'), value: 'me' }, ...fromClass]
      return all
    },

    objectClass () {
      return this.$store.state.objectClass
    }
  },

  methods: {
    addAttributeToCursor (attribute) {
      if (this.focusElement === 'text') {
        const editor = this.$refs.text
        editor.addTag({
          key: attribute.value,
          value: attribute.text,
          color: '#FFA726'
        })
      } else { // subject row is the default
        const editor = this.$refs.subject
        editor.addTag({
          key: attribute.value,
          value: attribute.text,
          color: '#FFA726'
        })
      }
    },

    attributeTranslation (attribute) {
      return this.$i18n.t(this.$store.state.objectClass + '.attributes.' + attribute)
    },

    fixedAttributeTranslation (attribute) {
      return this.$i18n.t('aava.no_se.fixed_attributes.' + attribute)
    },

    subjectFieldClass () {
      const hasSubject = this.data.subject && this.data.subject.length > 0
      return 'no-se-message-subject no-se-input' + (hasSubject ? '' : ' no-se-missing-field')
    },
  }
}
</script>

<style lang="less">
.no-se-message-row {
  /* display: block; */
}
.no-se-message-data {
  display: inline-block;
  vertical-align: top;
  padding-right: 10px;
}
.no-se-message-buttons {
  display: inline-block;
  vertical-align: top;
  padding-top: 20px;
  padding-right: 10px;
}
.no-se-message-attributes-container {
  display: inline-block;
  vertical-align: top;
  max-width: 600px;
  padding-right: 10px;
}
.no-se-message-row-remove {
  /* display: inline-block; */
}
.no-se-message-show-attributes {
  display: block;
}
.no-se-message-subject {
  display: inline-block;
  min-width: 400px;
  margin-bottom: 5px;
}
.no-se-message-text {
  display: block;
  min-width: 400px;
  margin-bottom: 5px;
}
.no-se-message-recipients {
  .v-input--selection-controls__input {
    input {
      min-width: 28px;
      opacity: 1;
    }
    i {
      opacity: 0;
    }
  }
}
</style>
