import Vue from 'vue'
import dashboardAvailableItems from './helpers/dashboardAvailableItems'
import sharedUtilities from '@/utilities/sharedUtilities'
import api from '@/store/api/'

export default {
  // Update dashboard items order
  updateDashboardItemsOrder: (state, dashboardItems) => {
    const newOrders = {}
    // Update order in VUEX
    dashboardItems.forEach((item, index) => {
      newOrders[item.id] = index
    })
    state.dashboardItems.forEach(item => {
      if (item.id in newOrders) {
        item.sort_order = newOrders[item.id]
      }
    })
    // Change order in service
    api.saveNewOrder(dashboardItems, state.shortcuts)
  },

  // Update order of shortcuts
  updateShortcutsOrder: (state, shortcuts) => {
    state.shortcuts = shortcuts
    // change order in service
    api.saveNewOrder(state.dashboardItems, shortcuts)
  },

  // Set or refresh dashboard dashboardItems
  setDashboardContent: (state, dashboardItems) => {
    state.dashboardItems = dashboardItems
    state.dashboardItemsLoaded = true
    state.dashboardAddingItemNow = false
  },

  // Set store status as loaded
  setUserId: (state, user_id) => {
    state.user_id = user_id
  },

  // Clear dashboardItems store (called on user switch)
  clearDashboardItems: (state) => {
    state.dashboardItems = []
    state.shortcuts = []
    state.dashboardItemsLoaded = false
  },
}
