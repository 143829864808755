import moment from 'moment'
import i18n from '@/locale/i18n'
import date_formatters from '@/components/PivotTableView/utils/date_formatters'

export default {
  createDateTranslations: function (key, translatedKey) {
    const translations = {}
    translations[key + ' - yearly'] = translatedKey + ' - ' + i18n.t('aava.index.pivottable.yearly')
    translations[key + ' - quarterly'] = translatedKey + ' - ' + i18n.t('aava.index.pivottable.quarterly')
    translations[key + ' - monthly'] = translatedKey + ' - ' + i18n.t('aava.index.pivottable.monthly')
    translations[key + ' - weekly'] = translatedKey + ' - ' + i18n.t('aava.index.pivottable.weekly')
    translations[key + ' - daily'] = translatedKey + ' - ' + i18n.t('aava.index.pivottable.daily')
    translations[key + ' - weekday'] = translatedKey + ' - ' + i18n.t('aava.index.pivottable.weekday')
    return translations
  },

  createDateTimeTranslations: function (key, translatedKey) {
    const translations = this.createDateTranslations(key, translatedKey)
    translations[key + ' - time_of_day'] = translatedKey + ' - ' + i18n.t('aava.index.pivottable.time_of_day')
    translations[key + ' - hourly'] = translatedKey + ' - ' + i18n.t('aava.index.pivottable.hourly')
    return translations
  },

  createDateTimeDerivedAttributes: function (val) {
    const derived = this.createDateDerivedAttributes(val)
    const valByTimeOfDay = val + ' - ' + i18n.t('aava.index.pivottable.time_of_day')
    derived[valByTimeOfDay] = (function (_this) {
      return function (record) {
        return _this._formatByTimeOfDay(record, val)
      }
    })(this)
    const valHourly = val + ' - ' + i18n.t('aava.index.pivottable.hourly')
    const hourlyFormat = moment.localeData().longDateFormat(date_formatters.dateFormat()) + ' HH:00'
    derived[valHourly] = (function (_this) {
      return function (record) {
        return _this._formatDatetimeAttribute(record, val, hourlyFormat)
      }
    })(this)
    return derived
  },

  createDateDerivedAttributes: function (val) {
    const derived = {}
    const valYearly = val + ' - ' + i18n.t('aava.index.pivottable.yearly')
    derived[valYearly] = (function (_this) {
      return function (record) {
        return _this._formatDatetimeAttribute(record, val, 'Y')
      }
    })(this)
    const valQuarterly = val + ' - ' + i18n.t('aava.index.pivottable.quarterly')
    derived[valQuarterly] = (function (_this) {
      return function (record) {
        return _this._formatQuarterly(record, val)
      }
    })(this)
    const valMonthly = val + ' - ' + i18n.t('aava.index.pivottable.monthly')
    derived[valMonthly] = (function (_this) {
      return function (record) {
        return _this._formatDatetimeAttribute(record, val, 'Y MMMM')
      }
    })(this)
    const valWeekly = val + ' - ' + i18n.t('aava.index.pivottable.weekly')
    derived[valWeekly] = (function (_this) {
      return function (record) {
        return _this._formatDatetimeAttribute(record, val, 'Y ww')
      }
    })(this)
    const valDaily = val + ' - ' + i18n.t('aava.index.pivottable.daily')
    derived[valDaily] = (function (_this) {
      return function (record) {
        return _this._formatDatetimeAttribute(record, val, date_formatters.dateFormat())
      }
    })(this)
    const valWeekday = val + ' - ' + i18n.t('aava.index.pivottable.weekday')
    derived[valWeekday] = (function (_this) {
      return function (record) {
        return _this._formatDatetimeAttribute(record, val, 'dddd')
      }
    })(this)
    return derived
  },

  _formatByTimeOfDay: function (record, attribute) {
    const datetime = moment(record[attribute], date_formatters.dateTimeFormat())
    if (!datetime.isValid()) {
      return i18n.t('aava.index.pivottable.no_value')
    }
    const hour = datetime.hour()
    if (hour < 6) {
      return i18n.t('aava.index.pivottable.night')
    } else if (hour < 12) {
      return i18n.t('aava.index.pivottable.morning')
    } else if (hour < 18) {
      return i18n.t('aava.index.pivottable.day')
    } else {
      return i18n.t('aava.index.pivottable.evening')
    }
  },

  _formatQuarterly: function (record, attribute) {
    const datetime = moment(record[attribute], date_formatters.dateTimeFormat())
    if (!datetime.isValid()) {
      return i18n.t('aava.index.pivottable.no_value')
    }
    const formattedYear = this._formatDatetimeAttribute(record, attribute, 'Y')
    const month = datetime.month()
    if (month < 3) {
      return formattedYear + ' Q1'
    } else if (month < 6) {
      return formattedYear + ' Q2'
    } else if (month < 9) {
      return formattedYear + ' Q3'
    } else {
      return formattedYear + ' Q4'
    }
  },

  _formatDatetimeAttribute: function (record, attribute, format) {
    const datetime = moment(record[attribute], date_formatters.dateTimeFormat())
    if (!datetime.isValid()) {
      return i18n.t('aava.index.pivottable.no_value')
    }
    return datetime.format(format)
  },
}
