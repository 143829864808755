<template>
  <div class="item-container">
    <v-toolbar
      class="list-toolbar item-tabs-toolbar elevation-0"
      color="grey lighten-2"
      text
    >
      <v-toolbar-title>{{ file.filename }}</v-toolbar-title>
      <v-spacer />
      <v-btn
        small
        icon
        :color="$store.state.defaultBtnBgColor"
        light
        class="mr-2"
        depressed
        @click="close"
      >
        <v-icon color="grey">
          fa-close
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-toolbar
      height="35"
      class="split-mode-file-toolbar list-toolbar item-tabs-toolbar elevation-0"
      color="grey lighten-2"
      text
    >
      <v-spacer />
      <v-btn
        :href="src"
        class="item-toolbar-icon text-capitalize white--text mt-n3 mr-2 no-link-decoration"
        color="grey"
        small
        light
        depressed
        download
      >
        <v-icon
          color="white"
          class="mr-2"
          small
        >
          fa-download
        </v-icon>
        {{ $i18n.t('aava.download.title') }}
      </v-btn>
    </v-toolbar>
    <v-container class="pb-0">
      <v-row>
        <v-col>
          <v-img
            v-if="isImage"
            :src="src"
            :max-height="maxHeight"
            contain
          />
          <embed
            v-else-if="isPDF"
            :src="src"
            :height="maxHeight"
            width="100%"
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'SplitModeFile',

  computed: {
    file () {
      return this.$store.state.splitModeFile
    },

    isImage () {
      return this.file.content_type?.includes('image/')
    },

    isPDF () {
      return this.file.content_type?.includes('/pdf')
    },

    src () {
      return '/content/attachments/' + this.file.id + '/' + this.file.filename
    },

    maxHeight () {
      const toolbarsHeight = 164
      return this.$store.state.innerHeight - toolbarsHeight
    },
  },

  methods: {
    close () {
      this.$store.state.splitModeFile = {}
    },
  },
}
</script>
