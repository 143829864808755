<template>
  <v-navigation-drawer
    v-model="$store.state.showUserMenuDrawer"
    :overlay-opacity="0"
    :temporary="true"
    style="z-index: 200; padding-top: 40px; border: 0;"
    width="350"
    class="elevation-0"
    color="grey lighten-2"
    :mobile-breakpoint="1"
    fixed
    right
  >
    <ChangePassword v-if="$store.state.showUserPasswordForm" />
    <v-sheet
      v-else
      class="menu-drawer-list py-3 elevation-0"
      color="transparent"
      nav
      block
      dense
    >
      <v-container class="text-center">
        <v-chip
          v-if="!$vuetify.breakpoint.lgAndUp"
          color="grey lighten-1"
        >
          <v-icon
            class="mr-2"
            x-small
          >
            fa-user
          </v-icon>
          {{ userNameComputed }}
        </v-chip>
      </v-container>
      <v-btn
        v-for="( menuEl, index ) in menuElements.filter(menuEl => menuEl.show)"
        :key="index"
        :to="menuEl.to"
        :data-cy="'um-' + menuEl.identifier"
        class="sheet-item text-left text-left -align-left"
        dense
        tile
        text
        block
        exact
        @click="menuEl.action "
      >
        <v-icon
          color="grey darken-2"
          small
        >
          {{ menuEl.icon }} {{ menuEl.rotate ? 'fa-rotate-270' : '' }}
        </v-icon>
        {{ menuEl.title }}
      </v-btn>
    </v-sheet>

    <v-container
      class="text-center grey--text py-5"
    >
      <div v-html="versionInfo" />
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import appMethods from '@/methods/list/appMethods'
import methods from '@/components/methods'
import ChangePassword from '@/components/App/ChangePassword'
import moment from 'moment'

export default {
  name: 'UserMenuDrawer',

  components: {
    ChangePassword,
  },

  computed: {
    showUserMenuDrawer () {
      return this.$store.state.showUserMenuDrawer
    },

    userNameComputed () {
      return this.$store.state.userInfo?.person?.name?.split(' ')[0] || this.$store.state.userInfo.name
    },

    menuElements () {
      return [
        {
          title: this.$i18n.t('aava.menu_items.logout'),
          identifier: 'logout',
          icon: 'fa-sign-out-alt',
          action: this.logout,
          show: true,
        },
        {
          title: this.$i18n.t('aava.menu_items.suppress'),
          identifier: 'suppress',
          icon: 'fa-minus',
          action: () => {
            this.$store.state.suppressToolbar = !this.$store.state.suppressToolbar
          },
          show: this.$route.path !== '/' && this.$vuetify.breakpoint.mdAndUp,
        },
        {
          title: this.$i18n.t('aava.menu_items.split_mode') +
            ' (' + this.$i18n.t('aava.general.split-vertical').toLocaleLowerCase() + ')',
          identifier: 'split_mode-v',
          icon: 'fa-columns',
          action: this.toggleVerticalSplitMode,
          show: this.$route.path !== '/' && this.$vuetify.breakpoint.mdAndUp,
        },
        {
          title: this.$i18n.t('aava.menu_items.split_mode') +
            ' (' + this.$i18n.t('aava.general.split-horizontal').toLocaleLowerCase() + ')',
          identifier: 'split_mode-h',
          icon: 'fa-columns',
          rotate: true,
          action: this.toggleHorizontalSplitMode,
          show: this.$route.path !== '/' && this.$vuetify.breakpoint.mdAndUp,
        },
        {
          title: this.$i18n.t('aava.menu_items.my_profile'),
          icon: 'fa-id-card',
          identifier: 'my_profile',
          to: '/people/' + (this.$store.state.userInfo.person && this.$store.state.userInfo.person.id) + '/edit/myProfile',
          action: () => {},
          show: this.$store.state.userInfo.person?.id,
        },
        {
          title: this.$i18n.t('aava.menu_items.my_settings'),
          icon: 'fa-gear',
          identifier: 'my_settings',
          action: () => {
            this.$store.state.showUserLevelSettingsForm = true
          },
          // Disabled. But may need in the future.
          show: false && this.$store.state.userInfo.person?.id,
        },
        {
          title: this.$i18n.t('aava.menu_items.change_password'),
          identifier: 'change_password',
          icon: 'fa-key',
          action: () => {
            this.$store.state.showUserPasswordForm = true
          },
          show: true,
        },
        {
          title: this.$i18n.t('aava.menu_items.clear_cache'),
          identifier: 'clear_cache',
          icon: 'fa-trash',
          action: this.clearCacheAndReload,
          show: true,
        },
        {
          title: this.$i18n.t('aava.menu_items.release_info'),
          icon: 'fa-info-circle',
          identifier: 'release_info',
          action: () => {
            this.$store.state.showReleaseInfo = true
            this.$store.state.showUserMenuDrawer = false
          },
          show: this.$vuetify.breakpoint.mdAndUp,
        },
        {
          title: this.$i18n.t('aava.menu_items.technical_documentation'),
          identifier: 'docs',
          icon: 'fa-file',
          action: () => {
            window.open('/gorilla/documentation/technical')
          },
          show: this.$store.state.userLevelSettings?.technical_documentation,
        },
      ]
    },

    versionInfo () {
      const systemInfo = this.$store.state.systemInfo
      return 'Aava v.' + moment(systemInfo.instance_timestamp).format('DDMMYY') +
        '<br>' + moment(systemInfo.update_timestamp).format('DD.MM.YY') +
        '<br>' + 'ver. ' + this.appVersionText
    },

    appVersionText () {
      return process.env.VUE_APP_VERSION || '-'
    },
  },

  watch: {
    // Hide password form when menu is closed
    showUserMenuDrawer () {
      if (!this.showUserMenuDrawer) {
        this.$store.state.showUserPasswordForm = false
      }
    },
  },

  methods: {
    ...appMethods,

    logout () {
      this.clearCache()
      this.$store.dispatch('logout', {})
    },
  },
}
</script>

<style lang="scss">
.menu-drawer-list {
  a {
    text-decoration: none;
  }
  .v-btn {
    justify-content: left !important;
    height: 28px !important;
    font-weight: 400;
    .v-btn__content {
      max-width: 330px;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .sheet-item {
    .v-icon {
      width: 40px;
    }
  }
  .theme--light.v-icon:focus:after {
    opacity: 0 !important;
  }
}
</style>
