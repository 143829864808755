
import axios from 'axios'
import ShortcutsTopMenu from '@/components/Shortcuts/ShortcutsTopMenu.vue'
import { Types } from '@/types/AppTypes'

export default {
  name: 'MenuToolbar',

  components: {
    ShortcutsTopMenu,
  },

  data () {
    return {
      showMenu: false,
      hoverIndex: null as number | null,
      hoverIndex2: null as number | null,
      menuLeftOffset: 0,
      activeSubmenuWidth: 0,
      showThirdLevelOnRight: false,
    }
  },

  created () {
    document.addEventListener('click', this.closeMenu)
    document.addEventListener('keyup', this.keyUpListener)
  },

  destroyed () {
    document.removeEventListener('click', this.closeMenu)
    document.removeEventListener('keyup', this.keyUpListener)
  },

  computed: {
    showTopMenu () {
      const enoughSpaceForOneElement = 75
      return this.$vuetify.breakpoint.mdAndUp || this.maxMenuElementWidth > enoughSpaceForOneElement
    },

    maxMenuElementWidth () {
      const reservedSpace = this.$vuetify.breakpoint.lgAndUp
        ? 270 // 50 px for person first name
        : 200
      const maxWidth = (this.$store.state.innerWidth - reservedSpace) / (this.$store.state.menuItems.length || 1)
      return maxWidth > 100
        ? 300 // Likely many elements are less than 100 px width and there is plenty of space to show larger titles
        : maxWidth < 60
          ? 60 // Minimum to show 2-3 characters. With this size mobile navigation is also shown
          : maxWidth
    },

    level1Style () {
      return {
        maxWidth: this.maxMenuElementWidth + 'px',
      }
    },

    level1BtnContentStyle () {
      return {
        maxWidth: (this.maxMenuElementWidth - 35) + 'px',
      }
    },

    submenuStyle () {
      return {
        left: this.menuLeftOffset + 'px'
      }
    },

    thirdLevelMenuStyle () {
      const offset = 0
      const left = (this.activeSubmenuWidth + offset) + 'px'
      if (this.showThirdLevelOnRight) {
        return {
          right: left,
        }
      }
      return {
        left,
      }
    },

    userNameComputed () {
      return this.$store.state.userInfo?.person?.name?.split(' ')[0] || this.$store.state.userInfo.name
    },
  },

  methods: {
    keyUpListener (e: KeyboardEvent) {
      if (e.key !== 'Escape') { return }
      this.closeMenu()
    },

    toggleShowMenu (e: MouseEvent, index: number) {
      this.showMenu = !this.showMenu
      if (this.showMenu) {
        this.mouseEnter(e, index)
      }
    },

    openMenuItem (e: MouseEvent, item: Types.MenuItem) {
      if (item.separate) {
        this.closeMenu()
        if (item.url?.includes('system/')) {
          axios.post(window.location.origin + '/gorilla/' + item.url, '_blank')
            .then(() => {
              location.reload()
            })
        } else {
          // Otherwise, is compatibility view and open the url
          this.$router.push({ path: '/compatibility/' + item.url })
        }
      } else if (item.url && item.external_path) {
        this.closeMenu()
        window.open(window.location.origin + '/' + item.url)
      } else if (item.url) {
        this.closeMenu() // Close menu only if url exists
        // Use href link instead, to support opening in new tab/window Shift or Ctrl
        return
        // this.$router.push({ path: '/' + item.url })
      }
      e.preventDefault()
      e.stopPropagation()
    },

    mouseEnter (e: MouseEvent, index: number) {
      this.$nextTick(() => {
        const targetElement = e.target as HTMLElement
        this.menuLeftOffset = targetElement?.offsetLeft
        this.hoverIndex = index
        this.hoverIndex2 = null
      })
    },

    mouseEnterSubmenu (e: MouseEvent, index: number) {
      this.showThirdLevelOnRight = this.$store.state.innerWidth - e.pageX < 420
      this.$nextTick(() => {
        const targetElement = e.target as HTMLElement
        this.activeSubmenuWidth = targetElement?.clientWidth
        this.hoverIndex2 = index
      })
    },

    closeMenu () {
      this.hoverIndex = null
      this.hoverIndex2 = null
      this.showMenu = false
    },

    internalUrl (menuItem: Types.MenuItem) {
      if (menuItem.separate || menuItem.external_path) { return '' }
      return menuItem.url ? ('/' + menuItem.url) : ''
    },
  },
}
