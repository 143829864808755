<template>
  <v-menu
    v-if="$store.state.globalFilters.available_values && $store.state.globalFilters.available_values.length > 0"
    :disabled="readOnly"
    :offset-y="true"
    :z-index="160"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-bind="attrs"
        :style="readOnly ? 'cursor: default;' : ''"
        color="white"
        class="grey--text"
        small
        v-on="on"
      >
        {{ $store.state.globalFilters.title }}
        {{ $store.state.globalFilters.current_value || $i18n.t('aava.general.all') }}
        <v-icon
          v-if="!readOnly"
          small
          class="ml-1"
        >
          fa-caret-down
        </v-icon>
      </v-chip>
    </template>
    <div class="select-menu elevation-5">
      <div
        v-for="(instance, index) in $store.state.globalFilters.available_values"
        :key="index"
        class="popup-menu-el"
        @click="selectInstance(instance)"
      >
        {{ instance.text }}
      </div>
    </div>
  </v-menu>
</template>

<script>
import api from '@/store/api/'

export default {
  name: 'GlobalFilterSelect',

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    selectInstance (instance) {
      this.$set(this.$store.state.globalFilters, 'current_value', instance.value)
      api.setGlobalFilter(instance).then(() => {
        location.reload()
      })
    },
  },
}
</script>
