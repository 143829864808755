<template>
  <div>
    <v-menu
      :z-index="210"
      max-width="300"
      offset-y
      close-on-click
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          :dark="!compact && !isMobileBottomToolbar"
          :icon="compact"
          :disabled="itemActions.length === 0"
          :block="isMobileBottomToolbar"
          :class="isMobileBottomToolbar ? 'mobile-toolbar-btn grey--text text--darken-1' : 'mx-1'"
          :color="isMobileBottomToolbar ? 'grey lighten-3' : 'grey'"
          data-cy="item-actions-btn"
          depressed
          small
          v-on="on"
        >
          <v-icon
            :class="isMobileBottomToolbar ? 'pr-2' : compact ? '' : 'mr-2'"
            small
          >
            fa-bars
          </v-icon>
          {{ compact ? '' : $i18n.t('aava.actions.title') }}
        </v-btn>
      </template>
      <div class="popup-items-container">
        <template
          v-for="(action, index) in itemActions"
        >
          <v-menu
            v-if="action.query && action.query.type === 'list'"
            :key="index"
            :z-index="220"
            left
            :content-class="isMobileBottomToolbar ? 'item-meta-action-options' : ''"
            offset-x
            open-on-hover
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="popup-menu-el"
                v-on="on"
              >
                <a
                  href="javascript:;"
                >
                  <span class="fa fa-play" />
                  {{ action.label }}
                </a>
              </div>
            </template>
            <div
              class="popup-items-container"
            >
              <div
                v-for="(subButton, index2) in action.query.values"
                :key="index2"
                class="popup-menu-el"
                @click.stop="$emit('sendItemMetaAction', {
                  action,
                  view: 'item',
                  value: subButton.value,
                })"
              >
                {{ subButton.label }}
              </div>
            </div>
          </v-menu>
          <div
            v-else
            :key="index"
            class="popup-menu-el"
            @click="$emit('sendItemMetaAction', {
              action,
              view: 'item',
            })"
          >
            <a href="javascript:;">
              <span class="fa fa-play" />
              {{ action.label }}
            </a>
          </div>
        </template>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'ItemMetaActions',

  props: {
    itemMeta: {
      type: Object,
      default: () => {},
    },
    isMobileBottomToolbar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    compact () {
      return !this.isMobileBottomToolbar && (!this.$vuetify.breakpoint.xlOnly || this.$store.state.splitMode === 'vertical')
    },

    itemActions () {
      return [...this.itemMeta?.buttons || [], ...this.itemMeta?.actions || []]
        .filter(action => {
          return !action.views || action.views.includes('show')
        })
    },
  },
}
</script>

<style>
.item-meta-action-options {
  /* Let's make almost full width on mobile, then user can't click on the side
  as it would be a click on the background button,
  which would trigger unwanted action */
  min-width: calc(100% - 30px) !important;
}
</style>
