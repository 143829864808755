import tinyColor from 'tinycolor2'
import listViewAPI from '@/store/api'
import { Timeline } from 'vis-timeline'
import moment from 'moment'
import { getObjectStateBackgroundColor } from '@/methods/item/itemMethods'
import { AxiosResponse } from 'axios'

// TODO use library
const stringToColor = str => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF
    color += ('00' + value.toString(16)).substr(-2)
  }
  return color
}

export default {
  layoutProfileItemExists (value) {
    return !!(value && this.$store.getters.layoutProfileItemsByName[value])
  },

  itemStyle (model) {
    const groupAttribute = this.configuration.color_group_attribute
    if (!model || (model && !model[groupAttribute])) { return }
    if (groupAttribute === 'main_object_state') {
      return {
        backgroundColor: getObjectStateBackgroundColor(model.main_object_state),
        color: model.main_object_state.text_color,
      }
    }
    else {
      const colorKey = this.getColorKey(model, groupAttribute)
      const bgColor = this.configuration && this.colorFromString(colorKey + groupAttribute)
      return `background-color: ${bgColor};` +
        `color: ${this.getReadableColor(bgColor)};`
    }
  },

  getColorKey (model, groupAttribute) {
    return this.configuration && groupAttribute &&
    typeof model[groupAttribute] === 'object'
      ? model[groupAttribute].id
      : model[groupAttribute]
  },

  getReadableColor (backgroundColor) {
    if (!this.textColorMap) this.textColorMap = {}
    if (this.textColorMap[backgroundColor]) { return this.textColorMap[backgroundColor] }
    this.textColorMap[backgroundColor] =
      tinyColor.mostReadable(backgroundColor, this.timelineColors, { includeFallbackColors: true })
        .toHexString()
    return this.textColorMap[backgroundColor]
  },

  groupKeyForField (prefix, nestedIdentifier) {
    let key = this.configuration[`${prefix}_attribute`]
    if (['reference', 'state'].includes(this.configuration[`${prefix}_attribute_type`])) {
      key += `.${nestedIdentifier}`
    }
    return key
  },

  colorFromString (identifier) {
    if (!this.colorMap) this.colorMap = {}
    if (this.colorMap[identifier]) { return this.colorMap[identifier] }
    this.colorMap[identifier] = stringToColor(identifier)
    return stringToColor(identifier)
  },

  initializeColorList () {
    return Object.keys(tinyColor.names).map(value => tinyColor.names[value])
  },

  // Prepares items got from resrouce api to be shown in timeline
  // groups by timeline
  updateDataItems () {
    const items = []
    this.timelineItemsByTimelineGroup = []
    this.dataGroupsByTimelineGroup = []
    this.timelineItems
      .filter(item => item[this.startTimeAttribute] && item[this.endTimeAttribute])
      .forEach(item => {
        const [groupIndex, dataGroupIndex] = this.createDataGroupsAndGetIndexes(item)
        const newItem = this.createNewItem(item, groupIndex, dataGroupIndex)
        this.timelineItemsByTimelineGroup[groupIndex].push(newItem)
      })
    this.dataItems.update(items)
  },

  createDataGroupsAndGetIndexes (item) {
    // Get unique timeline groups
    const groupValue = this.configuration.timeline_group_attribute &&
      item[this.configuration.timeline_group_attribute]
    const groupName = groupValue && typeof groupValue === 'object'
      ? groupValue.summary || groupValue.name || groupValue.id
      : groupValue
    if (!this.timelineGroups.includes(groupName)) {
      this.timelineGroups.push(groupName)
    }
    const groupIndex = this.timelineGroups.indexOf(groupName)
    const dataGroupInfo = this.configuration.group_attribute &&
    item && item[this.configuration.group_attribute]
      ? item[this.configuration.group_attribute]
      : '-'

    // Create containers if do not exist
    if (!this.timelineItemsByTimelineGroup[groupIndex]) {
      this.timelineItemsByTimelineGroup[groupIndex] = []
    }
    if (!this.dataGroupsByTimelineGroup[groupIndex]) {
      this.dataGroupsByTimelineGroup[groupIndex] = []
    }
    // Get data group index
    let dataGroupIndex: number | null = null
    if (this.groupIsReference) {
      // Reference
      dataGroupIndex = this.dataGroupsByTimelineGroup[groupIndex].map(i => i.summary)
        .indexOf(dataGroupInfo.summary)
      if (dataGroupIndex === -1) {
        this.dataGroupsByTimelineGroup[groupIndex].push(dataGroupInfo)
        dataGroupIndex = this.dataGroupsByTimelineGroup[groupIndex].length - 1
      }
    } else {
      // Text
      if (!this.dataGroupsByTimelineGroup[groupIndex].includes(dataGroupInfo)) {
        this.dataGroupsByTimelineGroup[groupIndex].push(dataGroupInfo)
      }
      dataGroupIndex = this.dataGroupsByTimelineGroup[groupIndex].indexOf(dataGroupInfo)
    }
    return [groupIndex, dataGroupIndex]
  },

  createNewItem (item, groupIndex, dataGroupIndex) {
    return {
      id: item.id,
      content: item.summary,
      start: item[this.startTimeAttribute],
      end: item[this.endTimeAttribute],
      group: dataGroupIndex + 1,
      title: item.summary,
      style: this.itemStyle(item),
      model: item,
      timelineId: groupIndex
    }
  },

  getTimelineItems () {
    if (!this.startTimeAttribute || !this.endTimeAttribute) {
      this.loading = false
      this.refreshing = false
      return
    }
    this.timelineGroups = []
    this.timelineItemsByTimelineGroup = []
    this.dataGroupsByTimelineGroup = []
    this.refreshing = true
    this.$nextTick(() => {
      listViewAPI.fetchListItems(this.objectClass, this.filters, this.queries, {})
        .then((response: AxiosResponse) => {
          this.timelineItems = response.data.items
          this.$nextTick(() => {
            this.updateDataItems()
            this.drawTimeline()
            this.refreshing = false
          })
        })
    })
  },

  saveConfig () {
    this.saving = true
    this.$store.dispatch('saveTimelineConfig', {
      configuration: this.configuration,
      rangeStart: this.rangeStart,
      rangeEnd: this.rangeEnd
    }).then(() => {
      this.saving = false
    })
  },

  initialize () {
    if (!this.timelineColors) {
      this.timelineColors = this.initializeColorList()
    }
    this.loading = true
    this.refreshing = true
    this.$store.dispatch('loadListLayoutProfileItems').then(() => {
      this.loading = false
      this.setDataFromConfig()
      this.setMomentDates(null, null)
      this.getTimelineItems()
    })
  },

  setDataFromConfig () {
    const config = this.selectedLayoutProfile.timelineConfiguration
    if (config && config.timeline_configuration) {
      this.configuration = config.timeline_configuration
      // Set timeline start and end from config
      if (config.timeline_range && config.timeline_range.start && config.timeline_range.end) {
        this.rangeStart = config.timeline_range.start
        this.rangeEnd = config.timeline_range.end
      }
    }
  },

  reloadItems () {
    this.refreshing = true
    listViewAPI.fetchListItems(this.objectClass, this.filters, this.queries, {})
      .then((response: AxiosResponse) => {
        this.timelineItems = response.data.items
        this.$nextTick(() => {
          this.updateDataItems()
          this.updateItemsOnTimeline()
          this.refreshing = false
        })
      })
  },

  setMomentDates (startDate, endDate) {
    this.startMoment = startDate || this.rangeStart
      ? moment(startDate || this.rangeStart)
      : moment().startOf('week')
    this.endMoment = endDate || this.rangeEnd
      ? moment(endDate || this.rangeEnd)
      : moment().endOf('week')
    if (startDate && endDate) {
      this.rangeStart = startDate
      this.rangeEnd = endDate
    }
  },

  onRangeChanged (options) {
    if (this.rangeStart && this.rangeEnd &&
      this.rangeStart.toString() === options.start.toString() &&
      this.rangeEnd.toString() === options.end.toString()) {
      // Range is unchanged
      return
    }
    this.setMomentDates(options.start, options.end)
    this.saveConfig()
    this.updateOptionsOnTimeline()
    this.reloadItems()
  },

  drawRuler () {
    if (this.ruler) { return }
    const el = document.getElementById('timeline-div-ruler')
    if (el) {
      this.ruler = new Timeline(el, [], [], this.rulerOptions)
      this.ruler.on('rangechanged', this.setOnRangeChangeTimer)
    }
  },

  setOnRangeChangeTimer (e) {
    clearTimeout(this.rangeChangeTimer)
    this.rangeChangeTimer = setTimeout(() => {
      this.onRangeChanged(e)
    }, 400)
  },

  updateOptionsOnTimeline () {
    this.ruler.setOptions(this.rulerOptions)
    // this.timeline.setOptions(this.options)
    this.$nextTick(() => {
      this.timelineGroups.forEach((group, index) => {
        if (this.timelines[index]) {
          this.timelines[index].setOptions(this.options)
        }
      })
    })
  },

  saveItem (item) {
    this.$store.dispatch('saveTimelineItem', {
      id: item.id,
      parameters: this.getItemSaveParameters(item)
    }).then((response: AxiosResponse) => {
      if (response.data.status === 'ok') {
        this.showMessage({
          message: 'Update OK - ' + item.content,
          color: 'success'
        })
      }
    })
  },

  getItemSaveParameters (item) {
    const parameters: any[] = []
    // Period
    parameters.push({
      key: this.configuration.start_time_attribute,
      value: moment(item.start).format('YYYY-MM-DD HH:mm')
    })
    parameters.push({
      key: this.configuration.end_time_attribute,
      value: moment(item.end).format('YYYY-MM-DD HH:mm')
    })
    // Group
    let newGroupFieldValue = this
      .dataGroupsByTimelineGroup[item.timelineId][parseInt(item.group) - 1]
    if (this.groupIsReference && newGroupFieldValue.id) {
      newGroupFieldValue = {
        '@class': this.$store.getters
          .layoutProfileItemsByName[this.groupAttribute].reference_class,
        id: newGroupFieldValue.id
      }
    }
    if (!this.groupIsReference || newGroupFieldValue.id) {
      parameters.push({
        key: this.groupAttribute,
        value: newGroupFieldValue
      })
    }
    return parameters
  },

  updateItemsOnTimeline () {
    this.$nextTick(() => {
      this.timelineGroups.forEach((group, index) => {
        const items = this.timelineItemsByTimelineGroup[index]
        const groups = this.dataGroupsByTimelineGroupComputed[index]
        if (!this.timelines[index]) {
          this.drawTimelineForGroup(index)
        } else {
          this.timelines[index].setData({
            items,
            groups
          })
        }
      })
    })
  },

  drawTimelineForGroup (index) {
    const el = document.getElementById('timeline-group-div-' + index)
    if (el) {
      this.timelines[index] = new Timeline(
        el,
        this.timelineItemsByTimelineGroup[index],
        this.dataGroupsByTimelineGroupComputed[index],
        this.options
      )
      this.timelines[index].on('rangechanged', this.setOnRangeChangeTimer)
    }
  },

  drawTimeline () {
    this.drawRuler()
    this.$nextTick(() => {
      this.timelineItemsByTimelineGroup.forEach((items, index) => {
        this.drawTimelineForGroup(index)
      })
    })
  },

  updateHandler () {
    this.saveConfig()
    this.getTimelineItems()
  }
}
