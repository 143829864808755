<template>
  <div
    v-if="showHeaderColumnStatesMenu"
    id="state-options-menu"
    class="state-options-menu popup-menu elevation-5"
  >
    <div
      v-for="(objectState, index) in objectStatesByClass[objectClass]"
      :key="index"
      class="popup-menu-el"
      @click="toggleStateOptionFilter($event, objectState)"
    >
      <a href="javascript:;">
        <i
          :class="'fa ' + (stateFilter &&
            stateFilterStateIds.includes(objectState.id)
            ? 'fa-check'
            : 'icon-spaceholder')"
        />
        {{ objectState.name }}
      </a>
    </div>
  </div>
</template>

<script>
import listFilters from './../../store/_listFilters'
import util from '../../utilities/sharedUtilities'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'HeaderColumnStatesMenu',

  computed: {
    ...mapFields(Object.keys(state)),

    stateFilter () {
      return this.listFilters.main_object_state
    },

    stateFilterStateIds () {
      return this.$store.getters.stateFilterStateIds
    }
  },

  created () {
    document.addEventListener('click', this.clickListener)
  },

  destroyed () {
    document.removeEventListener('click', this.clickListener)
  },

  methods: {
    ...methods,

    selectAllStateOptions () {
      this.objectStatesByClass[this.objectClass].forEach(option => {
        if (!this.stateFilterStateIds.includes(parseInt(option.id))) {
          this.stateFilterStateIds.push(parseInt(option.id))
        }
      })
    },

    toggleStateOptionFilter (e, option) {
      if (e.ctrlKey) {
        this.selectAllStateOptions()
      }
      const stateFilterStateIds = this.stateFilterStateIds
      const stateSearchTerm = parseInt(option.id)
      if (stateFilterStateIds.includes(stateSearchTerm)) {
        // Remove filter
        stateFilterStateIds.splice(stateFilterStateIds.indexOf(stateSearchTerm), 1)
      } else {
        // Add filter
        stateFilterStateIds.push(stateSearchTerm)
      }
      const stateFilterValue = stateFilterStateIds.length > 0
        ? '(' + stateFilterStateIds.join(' ') + ')'
        : ''
      this.$store.commit('setFilterField', {
        filterField: 'main_object_state',
        filterValue: stateFilterValue
      })
      listFilters.setLocalStorageFilter(this.selectedLayoutProfileId, 'main_object_state', stateFilterValue)
      this.reloadListItems()
    },

    clickListener (e) {
      if (this.showHeaderColumnStatesMenu) {
        const el = document.getElementById('state-options-menu')
        const el2 = document.getElementById('main_object_state_th')
        if (el && !el.contains(e.target) && el2 && !el2.contains(e.target)) {
          this.showHeaderColumnStatesMenu = null
        }
      }
    }
  }
}
</script>

<style lang="less">
@color_gray: #333;

.state-options-menu {
	min-width: 150px;
	min-height: 100px;
  a {
		color: @color_gray !important;
		text-decoration: none !important;
	}
}
.state-options-menu-el {
	position: relative;
	width: 100%;
	padding: 3px 10px;
	cursor: pointer;
	&:hover {
		background: #f2f2f2;
	}
}
</style>
