<template>
  <div class="input-with-tags-container">
    <div
      v-if="showPlaceholder && placeholderText"
      class="input-placeholder"
      @click="clickOnPlaceholder()"
    >
      {{ placeholderText }}
    </div>
    <div
      :id="containerId"
      class="input-with-tags"
      contenteditable="true"
      @blur="blur($event)"
      @focus="focus($event)"
      @keyup="keyup($event)"
      @keydown="keydown($event)"
      @click="click($event)"
      v-html="inputHtmlValue"
    />
    <!-- TEMP for debugging>
    <pre>
      {{ parts.length }}
      {{ inputHtmlValue }}
      {{ parts }}
    </pre -->
  </div>
</template>

<script>
const defaultTagColor = '#FFA726'

export default {
  name: 'NoSeInputWithTags',

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    placeholderText: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      cursorPositionInFocusedNode: 0,
      focusedNodeIndex: 0,
      inputHtmlValue: null,
      containerId: Math.random().toString(36).substring(7),
      showPlaceholder: true
    }
  },

  computed: {
    parts: {
      get () {
        return this.value || []
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  created () {
    this.setInputHtmlValue()
    const onlyOneEmptyTextPart = this.parts.length === 1 &&
      this.parts[0].type === 'text' && !this.parts[0].value
    this.showPlaceholder = this.parts.length === 0 || onlyOneEmptyTextPart
  },

  methods: {
    setInputHtmlValue () {
      // Add text in front for Firefox
      if (this.parts && this.parts[0] && this.parts[0].type !== 'text') {
        this.parts.unshift({
          type: 'text',
          value: '&nbsp;'
        })
      }
      this.$nextTick(() => {
        const inputHtmlValue = this.parts.map((part, index) => {
          const stringValue = part.value && part.value.trim()
          const value = part.type === 'text'
            ? part.value === '&nbsp;' || part.value === ' ' || !stringValue
              ? '&nbsp;'
              : ' ' + stringValue + ' '
            : part.type === 'linebreak'
              ? '<br>'
              : this.tagHtml(part, index)
          return value
        }).join(' ')
        this.inputHtmlValue = inputHtmlValue
      })
    },

    tagHtml (tag, index) {
      return '<div class="input-tag" contenteditable="false" ' +
        'data-key="' + tag.key + '" ' +
        'data-color="' + tag.color + '" ' +
        ' style="background: ' + (tag.color || defaultTagColor) + ';" ' +
        '>' + tag.value +
        ' <i class="fa fa-times-circle" data-delete-index="' +
        index + '"></i></div>'
    },

    setCursorPositionData (trigger) {
      const selection = document.getSelection()
      if (selection) {
        this.cursorPositionInFocusedNode = selection.anchorOffset
        const focusedNode = selection.anchorNode
        if (!focusedNode) { return }
        this.focusedNodeIndex = this.getFocusedNodeIndex(selection, focusedNode)
      }
    },

    getFocusedNodeIndex (selection, node) {
      if (node.className === 'input-with-tags') {
        return selection.anchorOffset
      }
      let index = 0
      let sibling = node && node.previousSibling
      while (sibling && index < 100) {
        index++
        sibling = sibling && sibling.previousSibling
      }
      return index
    },

    updateInputPartsFromHtmlNodes (trigger) {
      const node = document.getElementById(this.containerId)
      const childNodes = Array.from(node.childNodes)
      // In case of Firefox select-all+delete replace single linebreak with text
      if (childNodes && childNodes.length === 1 && childNodes[0].nodeName === 'BR') {
        this.parts = [{
          type: 'text',
          value: '&nbsp;'
        }]
        this.$nextTick(() => {
          this.setInputHtmlValue()
        })
      } else {
        this.parts = childNodes.map((childNode, index) => {
          return this.getInputPartFromNode(index, childNode)
        })
      }
    },

    getInputPartFromNode (index, node) {
      if (node?.tagName === 'DIV' && node?.dataset.key) {
        return {
          type: 'tag',
          key: node.dataset.key,
          color: node.dataset.color,
          value: node.textContent
        }
      } else if (node.tagName === 'BR' || (node.tagName === 'DIV' && !node.textContent)) { // Firefox linebreak can be also a DIV
        return { type: 'linebreak' }
      } else {
        let stringValue = node && (node.nodeValue || node.textContent)
        stringValue = stringValue.replace(/(\r\n|\n|\r)/gm, '')
        return {
          type: 'text',
          value: stringValue
        }
      }
    },

    clearInputPartsAndSetHtml () {
      this.clearInputParts()
      // Has to be on next tick
      // because computed settler can't handle value change
      // more than once on one tick
      this.$nextTick(() => {
        this.setInputHtmlValue()
      })
    },

    clearInputParts () {
      const newParts = []
      const count = this.parts.length
      let combinedText = ''
      this.parts.forEach((part, index) => {
        if (part.type === 'text') {
          // Collect side by side text nodes into single string
          combinedText += part.value.trim() + ' '
          if (index === count - 1) {
            newParts.push({
              type: 'text',
              value: combinedText
            })
          }
        } else {
          // Add combined text from previous text parts
          if (combinedText !== '') {
            newParts.push({
              type: 'text',
              value: combinedText
            })
            combinedText = ''
          }

          // Add non-text part
          newParts.push(part)

          // If this and next part are both tags, add text in between
          if (part.type === 'tag' && index < (count - 1) && this.parts[index + 1].type === 'tag') {
            newParts.push({
              type: 'text',
              value: ' '
            })
          }
        }
        // If last part is tag, add text to the end (for Firefox)
        if (index === count - 1 && part.type === 'tag') {
          newParts.push({
            type: 'text',
            value: '&nbsp;'
          })
        }
      })
      // Fix empty space in the end for Firefox
      const lastPart = newParts[newParts.length - 1]
      if (lastPart.type === 'text' &&
        (!lastPart.value || lastPart.value.trim() === '')
      ) {
        lastPart.value = '&nbsp;'
      }
      this.parts = JSON.parse(JSON.stringify(newParts))
    },

    addTagToEmptyLine (tagData) {
      this.parts.splice(this.focusedNodeIndex, 0, {
        type: 'text',
        value: '&nbsp;'
      })
      this.parts.splice(this.focusedNodeIndex + 1, 0, tagData)
      this.focusedNodeIndex = 0
      this.cursorPositionInFocusedNode = 0
    },

    addTagToTheEnd (tagData) {
      // remove double linebreaks in the end
      const partsCount = this.parts.length
      if (partsCount >= 2 && this.parts[partsCount - 1].type === 'linebreak' && this.parts[partsCount - 2].type === 'linebreak') {
        this.parts.pop()
      }
      this.parts.push({
        type: 'text',
        value: '&nbsp;'
      })
      this.parts.push(tagData)
    },

    addTag ({ key, value, color }) {
      this.showPlaceholder = false
      const tagData = {
        type: 'tag',
        key,
        value,
        color
      }
      const selectedTextPart = (this.parts[this.focusedNodeIndex] &&
        this.parts[this.focusedNodeIndex].type === 'text' &&
        this.parts[this.focusedNodeIndex].value) || null
      if (selectedTextPart === null || (this.focusedNodeIndex === 0 && this.cursorPositionInFocusedNode === 0)) {
        // Add tag to the end
        if (this.focusedNodeIndex === 0) {
          this.addTagToTheEnd(tagData)
        } else {
          this.addTagToEmptyLine(tagData)
        }
        this.clearInputPartsAndSetHtml()
        return
      }
      const selectedNodeTextParts = [
        selectedTextPart.substring(0, this.cursorPositionInFocusedNode),
        selectedTextPart.substring(this.cursorPositionInFocusedNode)
      ]
      // Split text node into two parts
      this.parts[this.focusedNodeIndex].value = selectedNodeTextParts[0]
      this.parts.splice(this.focusedNodeIndex + 1, 0, {
        type: 'text',
        value: selectedNodeTextParts[1]
      })
      // Now add tag in between
      this.parts.splice(this.focusedNodeIndex + 1, 0, tagData)
      this.cursorPositionInFocusedNode = 0
      this.focusedNodeIndex = 0
      this.clearInputPartsAndSetHtml()
    },

    deleteTagByIndex (index) {
      this.updateInputPartsFromHtmlNodes('blur')
      this.$nextTick(() => {
        this.parts.splice(index, 1)
        this.$nextTick(() => {
          this.clearInputPartsAndSetHtml()
        })
      })
    },

    blur (e) {
      this.updateInputPartsFromHtmlNodes('blur')
      const el = document.getElementById(this.containerId)
      this.showPlaceholder = !el.textContent || el.textContent.trim() === ''
    },

    keyup (e) {
      this.setCursorPositionData('keyup')
    },

    keydown (e) {
      if (e.which === 13 && !e.shiftKey) {
        e.preventDefault()
        document.execCommand('insertHTML', false, '<br>&nbsp;')
      }
    },

    focus (e) {
      this.showPlaceholder = false
      this.$emit('focus')
      setTimeout(() => {
        this.setCursorPositionData('focus')
      }, 50)
    },

    click (e) {
      this.showPlaceholder = false
      const deleteIndex = (e.target && e.target.dataset && e.target.dataset.deleteIndex) || null
      if (deleteIndex !== null) {
        this.deleteTagByIndex(deleteIndex)
      }
      setTimeout(() => {
        this.setCursorPositionData('click')
      }, 50)
    },

    clickOnPlaceholder () {
      this.showPlaceholder = false
      const el = document.getElementById(this.containerId)
      el.focus()
    }
  }
}
</script>

<style>
.input-with-tags-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.input-placeholder {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  color: #aaa;
  padding: 9px 15px;
}
.input-with-tags div {
  display: -webkit-inline-box;
}
.input-with-tags {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 5px 10px;
  line-height: 30px;
  font-size: 14px;
  background: white;
  min-height: 41px;
}
.input-tag {
  position: relative;
  top: -1px;
  margin: 0 3px;
  cursor: default;
  font-size: 12px;
  line-height: 26px;
  height: 26px;
  font-weight: 700;
  padding: 0 27px 0 7px;
  background: #bbb;
  border-radius: 50px;
  color: white;
}
.input-tag i {
  position: absolute;
  top: 3px;
  right: 5px;
  cursor: pointer;
  font-size: 20px;
}
</style>
