<template>
  <div
    class="no-se-attributes-container"
  >
    <v-chip
      @click="attributesShown = !attributesShown"
      class="mr-2 mb-2"
    >
      {{ attributesShown ? $i18n.t('aava.no_se.hide_attributes') : $i18n.t('aava.no_se.show_attributes') }}
    </v-chip>
    <v-expand-transition>
      <div
        v-if="attributesShown"
        class="no-se-attribute-chips"
      >
        <v-chip
          v-for="(attribute, index) in sortedAttributes"
          :key="index"
          color="#eee"
          @click="$emit('attributeSelected', attribute)"
        >
          {{ attribute.text }}
          <v-icon small>fa-plus-circle</v-icon>
        </v-chip>
        <v-divider class="my-3" />
        <v-chip
          v-for="(fixedAttribute, index) in sortedFixedAttributes"
          :key="1000 + index"
          color="#eee"
          @click="$emit('attributeSelected', fixedAttribute)"
        >
          {{ fixedAttribute.text }}
          <v-icon small>fa-plus-circle</v-icon>
        </v-chip>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'NoSeAttributePicker',

  components: {
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data () {
    return {
      attributesShown: false,
    }
  },

  computed: {
    sortedAttributes () {
      if (!this.data.attributes) {
        return []
      }
      return this.data.attributes.map((attr) => {
        return { value: attr.name, text: this.attributeTranslation(attr.name, this.data.objectClass) }
      }).sort((a, b) => {
        if (a.text > b.text) { return 1 }
        if (a.text < b.text) { return -1 }
        return 0
      })
    },

    sortedFixedAttributes () {
      return this.data.fixedAttributes.map((attr) => {
        return { value: attr, text: this.fixedAttributeTranslation(attr) }
      })
    }
  },

  methods: {
    attributeTranslation (attribute) {
      return this.$i18n.t(this.data.objectClass + '.attributes.' + attribute)
    },

    fixedAttributeTranslation (attribute) {
      return this.$i18n.t('aava.no_se.fixed_attributes.' + attribute)
    },
  }
}
</script>

<style lang="less">
.no-se-attribute-chips {
  .v-chip {
    color: #444;
    font-weight: 400;
    height: 28px !important;
    margin: 0 5px 5px 0;
    .v-chip__content {
      cursor: pointer;
    }
  }
  i {
    color: #81C784 !important;
    position: relative;
    right: -6px;
    font-size: 20px;
  }
}
.no-se-attributes-container {
  display: inline-block;
  vertical-align: top;
  /*max-width: 600px; */
  /* padding-right: 10px;*/
}
</style>
