
import util from '../../utilities/sharedUtilities'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import { LP } from '@/types/LP.types'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'HeaderColumnMenu',

  computed: {
    ...mapFields(Object.keys(state)),

    fieldInfo (): LP.Item | null {
      if (!this.listColumnMenuField) { return null }
      return this.layoutProfileItems
        .find((column: LP.Item) => column.name === this.listColumnMenuField)
    },

    showMultiEditButton () {
      const field = this.fieldInfo
      if (!field) { return }
      return field.name === 'main_object_process_events' || field.name === 'owner'
        ? true
        : !(
            !field.allow_multi_edit ||
            !field.writable ||
            field.type === 'richtext' ||
            field.dynamic ||
            field.widget ||
            // TODO widgetClass deprecated?
            (field.widgetClass && field.widgetClass.disallowMultiedit)
          )
    }
  },

  methods: {
    ...methods,

    hideField (field) {
      if (this.selectedLayoutProfile.locked) { return }
      this.$store.dispatch('hideListField', field)
      this.$nextTick(() => {
        this.adjustCellWidthsAndLineHeight()
      })
    },

    addFilter () {
      if (!this.listFilters[this.listColumnMenuField]) { return }
      this.listFiltersAddPermanent(this.listColumnMenuField)
    },

    openMultiEditMenu (e) {
      if (this.selectedItems.length === 0) { return }
      const leftOffset = -170
      const topOffset = -70
      this.multiEditColumn = this.listColumnMenuField
      this.listColumnMenuField = ''
      this.$nextTick(() => {
        util.positionPopupMenu(e, 'multi-edit-menu', leftOffset, topOffset, { })
      })
    },

    emptySearch () {
      if (this.listFieldsByName[this.listColumnMenuField].custom_value) {
        this.listFiltersRemovePermanent(this.listColumnMenuField)
      } else if (this.listFilters[this.listColumnMenuField]) {
        this.listFiltersRemove(this.listColumnMenuField)
      }
    }
  }
}
