import Vue from 'vue'
import api from './../../store/api'
import { ContainerSavePayload, LP } from '@/types/LP.types'
import { AxiosResponse } from 'axios'

const getLayoutContainerQueries = (availableContentLocales) => {
  const queries = [
    'columns',
    'layout_profile',
    'sort_order',
    'created_at',
    'created_by',
    'updated_at',
    'updated_by',
  ]
  availableContentLocales.forEach(locale => {
    queries.push('name_' + locale)
  })
  return queries
}

export default {
  saveNewLayoutContainer: ({ state, dispatch }, layoutContainer: LP.Container) => {
    const payload: ContainerSavePayload = {
      '@class': 'LayoutContainer',
      columns: layoutContainer.columns,
      // Use index if saving virtual containers
      sort_order: layoutContainer.sort_order !== null ? layoutContainer.sort_order : layoutContainer.index,
      layout_profile: {
        '@class': 'LayoutProfile',
        id: state.selectedLayoutProfileIdByModel[state.objectClass],
      },
    }
    state.availableContentLocales.forEach(locale => {
      payload['name_' + locale] = layoutContainer['name_' + locale]
    })
    return new Promise(resolve => {
      const queries = getLayoutContainerQueries(state.availableContentLocales)
      api.saveLayoutContainer(payload, queries).then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'Save Layout Container' })
        // If new container saved, update in store
        const savedContainer: LP.Container = response?.data?.item
        savedContainer.showInfoText = false
        savedContainer.infoTextValues = {}
        if (!layoutContainer.id) {
          const selectedLayoutProfileId = state.selectedLayoutProfileIdByModel[state.objectClass]
          Vue.set(
            state.layoutContainers[selectedLayoutProfileId],
            state.layoutContainers[selectedLayoutProfileId].length,
            savedContainer,
          )
        }
        resolve(savedContainer)
      })
    })
  },

  updateLayoutContainer: ({ state, dispatch }, layoutContainer: LP.Container) => {
    return new Promise(resolve => {
      const payload: ContainerSavePayload = {
        '@class': 'LayoutContainer',
        id: layoutContainer.id,
        columns: layoutContainer.columns,
        collapsed: layoutContainer.collapsed,
        config: JSON.stringify({
          showInfoText: layoutContainer.showInfoText,
          infoTextValues: layoutContainer.infoTextValues,
        }),
      }
      state.availableContentLocales.forEach(locale => {
        payload['name_' + locale] = layoutContainer['name_' + locale]
      })
      const queries = getLayoutContainerQueries(state.availableContentLocales)
      api.saveLayoutContainer(payload, queries).then((response: AxiosResponse) => {
        dispatch('globalErrorDisplay', { response, context: 'updateLayoutContainer' })
        resolve(response)
      })
    })
  },

  saveLayoutContainersOrder: ({ state }) => {
    return new Promise(resolve => {
      const itemLayoutProfileId = state.selectedLayoutProfileIdByModel[state.objectClass]
      const itemLayoutContainers = state.layoutContainers[itemLayoutProfileId]
      api.sortLayoutProfileChildren(
        itemLayoutProfileId,
        itemLayoutContainers,
        'containers'
      ).then(result => {
        resolve(result)
      })
    })
  },

  deleteLayoutContainer: ({ state, dispatch }, layoutContainer: LP.Container) => {
    return new Promise(resolve => {
      dispatch('deleteItem', { resource: 'layout_containers', id: layoutContainer.id }).then(result => {
        // Delete from store
        if (result) {
          const itemLayoutProfileId = state.selectedLayoutProfileIdByModel[state.objectClass]
          const itemLayoutContainers = state.layoutContainers[itemLayoutProfileId]
          const deleteIndex = itemLayoutContainers.map(c => c.id).indexOf(layoutContainer.id)
          Vue.delete(state.layoutContainers[itemLayoutProfileId], deleteIndex)
        }
        resolve(result)
      })
    })
  },
}
