<template>
  <div class="item-show-field edit-field-richtext">
    <div class="item-show-label item-edit-label">
      <span>{{ label }}</span>
    </div>
    <div
      v-if="appendIcon"
      class="item-field-action-buttons"
    >
      <v-btn
        class="mx-1"
        color="grey"
        tabindex="-1"
        icon
        @click.stop="appendIconFunction()"
      >
        <v-icon small>
          {{ appendIcon }}
        </v-icon>
      </v-btn>
    </div>
    <div class="item-show-value">
      <div
        v-if="render && readOnly"
        class="pa-2"
        v-html="htmlComputed"
      />
      <quill-editor
        v-else-if="render"
        ref="myQuillEditor"
        v-model="htmlComputed"
        :options="editorOptions"
        :class="'editor-content ' + (focused && !readOnly ? '' : 'hidden-toolbar')"
        :disabled="readOnly"
        spellcheck="false"
        @focus="focus"
        @blur="blur"
      />
    </div>
  </div>
</template>

<script>
const compactToolbar = [
  [{ header: [1, 2, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['image', 'video', 'code-block'],
]

export default {
  name: 'RichtextEditor',

  props: {
    value: {
      type: String,
      default: ''
    },
    field: {
      type: Object,
      default: () => {},
    },
    compact: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    updateItemOnDefaultsForChange: {
      type: Function,
      default: () => {},
    },
    label: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: '',
    },
    appendIconFunction: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      html: null,
      changeTimer: null,
      focused: false,
      render: true,
      pauseValueWatcher: false,
      editorOptions: {
        placeholder: '',
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ size: ['12px', '16px', '18px'] }],
            [{ color: [] }, { background: [] }],
            ['code', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['image', 'video', 'code-block']
          ]
        }
      },
    }
  },

  computed: {
    htmlComputed: {
      get () {
        return this.html
      },
      set (value) {
        // Change in the component
        this.html = value
        // Change for the item (to save with new value, to use for dfc)
        this.$emit('input', value)
        // Start timer for possible dfc change
        this.$emit('changeListener')
        // Pause change listener for value coming from parent, which actually is changed from this component
        this.pauseValueWatcher = true
        this.$nextTick(() => {
          // Allow value watcher again from parent item dfc (from other fields)
          this.pauseValueWatcher = false
        })
      }
    }
  },

  watch: {
    value (value) {
      if (this.pauseValueWatcher) { return }
      // Only continue if value is changed from the parent (not triggered from this component)
      // Ex Oma smoke_data_types/1/edit change boolean, updates richtext
      // Have to remove quill editor while updating value
      // by default quill editor on value change also focuses the input - this we don't want
      this.render = false
      // Update html value from item possible dfc change
      this.html = value
      this.$nextTick(() => {
        this.render = true
      })
    },
  },

  created () {
    this.html = this.value
    this.initializeToolbar()
  },

  methods: {
    initializeToolbar () {
      if (this.compact) {
        this.editorOptions.modules.toolbar = compactToolbar
      }
      setTimeout(() => {
        this.removeTabIndexFromToolbar()
        this.disableToolbarHeaderClick()
      }, 1000)
    },

    // Disable tabbing through quill editor toolbar
    // Unfortunately not possible to do with options
    removeTabIndexFromToolbar () {
      const quillEditors = document.querySelectorAll('.quill-editor')
      quillEditors.forEach(editor => {
        const elements = editor.querySelectorAll('button, .ql-picker-label')
        elements.forEach(element => element.setAttribute('tabindex', '-1'))
      })
    },

    // Disable toolbar header mouseup to leave focus where it is
    // Otherwise selecting font header loses focus and hides the toolbar
    disableToolbarHeaderClick () {
      document.querySelectorAll('.ql-toolbar').forEach(el => {
        el.addEventListener('mouseup', function (e) {
          e.stopPropagation()
          e.preventDefault()
        })
      })
    },

    blur () {
      this.focused = false
    },

    focus (e) {
      this.focused = true
      setTimeout(() => {
        this.$store.dispatch('closeItemPicker')
      }, 100) // Allow destroyed to be called for another field before this focus is triggered
    },
  },
}
</script>

<style lang="less">
.hidden-toolbar {
  .ql-toolbar {
    display: none !important;
  }
}
.ql-toolbar {
  position: absolute;
  top: -25px;
  background: white;
  left: 10px;
  border-radius: 6px;
  border: 1px solid #ddd !important;
  padding: 4px 10px 3px 10px !important;
  min-width: 510px;
}
.ql-toolbar.ql-snow {
	/* border: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 4px 4px 0 0;
  border-bottom: none !important; */
  /* border: none !important; */
  z-index: 1;
	&+.ql-container.ql-snow {
		border: inherit;
	}
}
.editor-content {
  /* background: white; */
	.ql-container {
		padding: 3px;
		/* height: 250px; */
		/* border: 1px solid rgba(0, 0, 0, 0.42) !important; */
    font-family: inherit;
		border-radius: 0 0 4px 4px;
    border-top: none !important;
	}
}
</style>
