<template>
  <v-container
    :style="outerStyle"
    class="pa-0 ma-0"
    fluid
    fill-height
  >
    <fullscreen
      ref="fullscreen"
      class="card-view-container___"
      @change="fullscreenChange"
    >
      <v-container
        :style="innerStyle"
        class="px-2 pt-1"
        fluid
        fill-height
      >
        <v-progress-linear
          v-if="!initialized || loading"
          :indeterminate="true"
          height="3"
          color="teal"
          class="ma-5"
        />
        <draggable
          v-else
          v-model="visibleColumns"
          :disabled="isPreSorted"
          class="card-column-draggable row fill-height row--dense"
          delay="200"
          force-fallback="true"
          group="cardColumnGroup"
          animation="200"
          handle=".card-column-header-text"
          @choose="dragStart"
          @unchoose="dragUnchoose"
        >
          <CardViewColumn
            v-for="(column, cellIndex) in visibleColumns"
            :key="column.identifier"
            :column-index="cellIndex"
            :column-count="visibleColumns.length"
            :column="column"
            :column-info="columnInfo(column)"
            :column-items="itemsByColumn(column)"
            :column-width-percentage="columnWidthPercentage"
            @disableFullScreen="disableFullScreen"
          />
        </draggable>
      </v-container>
    </fullscreen>
  </v-container>
</template>

<script>
import methods from './../methods'
import listFilters from './../../store/_listFilters'
import listViewAPI from '@/store/api'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import CardViewColumn from './CardViewColumn'
import draggable from 'vuedraggable'
import sharedComputed from '@/sharedComputed'
const Color = require('color')

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'CardView',

  components: {
    CardViewColumn,
    draggable,
  },

  data () {
    return {
      loading: false,
      initialized: false,
      visibleColumns: [],
      fullScreen: false,
    }
  },

  computed: {
    ...sharedComputed,
    ...mapFields(Object.keys(state)),

    isPreSorted () {
      return this.visibleColumns.some(col => col.order)
    },

    sortedVisibleColumns () {
      const visibleColumns = JSON.parse(JSON.stringify(this.cardViewInfos[this.objectClass]?.columns || []))
      if (this.isPreSorted) {
        // Anything that has column order from the backend. For example state card views
        return visibleColumns.sort((a, b) => {
          return (a.order > b.order) ? 1 : -1
        })
      } else {
        // read the possible user-given sort-order from the LP configuration
        const storedOrder = this.$store.state.selectedLayoutProfile.timelineConfiguration.cardColumnOrder
        if (storedOrder) {
          // sort the columns to be in the same order as the given order. New columns
          // may exist, they go to the end
          return visibleColumns.sort((a, b) => {
            const orderA = storedOrder.findIndex(item => item === a.value)
            const orderB = storedOrder.findIndex(item => item === b.value)
            return (orderA > orderB) ? 1 : -1
          })
        } else {
          return visibleColumns
        }
      }
    },

    filters () {
      return [...listFilters.getSearchFilters(this.$store.state)]
    },

    innerStyle () {
      const listToolbarHeight = 50
      const width = this.visibleColumns.length > 0
        ? this.visibleColumns.length * (this.areaWidth / 100 * this.columnWidthPercentage)
        : this.areaWidth
      const scrollBarPx = width - 5 > this.areaWidth ? 10 : 0
      let height = (this.listHeight + listToolbarHeight - scrollBarPx)
      if (this.fullScreen) {
        height = height + 135
      }
      return {
        height: height + 'px',
        overflow: 'auto',
        width: width + 'px',
        minWidth: width + 'px',
        maxWidth: width + 'px',
      }
    },

    outerStyle () {
      return {
        overflowY: 'auto',
        overflowX: 'hidden',
      }
    },

    areaWidth () {
      return this.$store.getters.listAreaWidth
    },

    columnWidthPercentage () {
      const minWidthPx = 200
      const minWidthPercentage = minWidthPx * 100 / this.areaWidth
      const widthPercentage = (100 / this.visibleColumns.length).toFixed(2)
      return widthPercentage < minWidthPercentage ? minWidthPercentage : widthPercentage
    },

    columnsOrdersMissing () {
      return this.cardItems && this.cardItems.some((item) => {
        return item.card_order === undefined || item.card_order === null
      })
    },

    queries () {
      if (this.cardViewInfos[this.objectClass].type === 'state') {
        return ['main_object_state summary,identifier,text_color,background_color',
          'main_object_process summary,identifier',
          'card_order',
          'summary'
        ]
      } else if (this.cardViewInfos[this.objectClass].type === 'reference') {
        return [this.cardViewInfos[this.objectClass].card_attribute + ' ' + this.cardViewInfos[this.objectClass].card_attribute_identifier,
          'card_order',
          'summary'
        ]
      } else {
        return [this.cardViewInfos[this.objectClass].card_attribute,
          'card_order',
          'summary'
        ]
      }
    },
  },

  watch: {
    'viewOptions.cardView' (newValue, oldValue) {
      if (!newValue || oldValue === newValue) { return }
      this.$store.dispatch('resetCardView').then(() => {
        this.initialized = false
        this.initialize()
      })
    },
    selectedLayoutProfileId (value) {
      if (!value) { return }

      this.$store.dispatch('resetCardView').then(() => {
        this.initialized = false
        this.initialize()
      })
    },
  },

  created () {
    this.initialize()
  },

  mounted () {
    document.addEventListener('keydown', this.keyListener)
  },

  beforeDestroy () {
    document.removeEventListener('keydown', this.keyListener)
  },

  methods: {
    ...methods,

    keyListener (e) {
      if (e.key === 'F11' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        this.toggleFullscreen()
      }
    },

    disableFullScreen () {
      if (this.fullScreen) {
        this.toggleFullscreen()
      }
    },

    toggleFullscreen () {
      this.$refs.fullscreen.toggle()
    },

    fullscreenChange (fullscreen) {
      this.fullScreen = fullscreen
    },

    initialize () {
      this.$store.dispatch('loadListLayoutProfileItems').then(() => {
        let viewName = this.$store.state.selectedLayoutProfile.timelineConfiguration.cardView
        if (!viewName) {
          viewName = this.$store.state.cardViews[0].name
        }
        this.$store.dispatch('loadCardViewInfo',
          { className: this.objectClass, viewName }).then(() => {
          this.visibleColumns = JSON.parse(JSON.stringify(this.sortedVisibleColumns))
          this.loadData()
          this.initWsChannel()
        })
      })
    },

    // delayed channel subscription notificationChannel.
    initWsChannel () {
      const notificationChannel = this.cardViewInfos[this.objectClass].notification_channel
      if (notificationChannel) {
        if (this.systemConfigs) {
          this.createWsChannel(notificationChannel)
        } else {
          window.setTimeout(this.initWsChannel, 500)
        }
      }
    },

    createWsChannel (channelName) {
      if (!this.$store.state.cable) { return }
      this.$store.state.cable.subscriptions.create(channelName, {
        connected: () => {},
        disconnected: () => {},
        rejected: () => {},
        received: data => {
          if (data.body.message === 'order') {
            // update order
            this.$store.dispatch('assignColumnOrdering', data.body.order).then(() => {})
          } else {
            // everything else than order-change is a reload
            if (data.body.id === this.cardActionItem) { return }

            // update after a small timeout to give solr the time to update itself
            window.setTimeout(this.loadData, 500)
          }
        }
      })
    },

    dragUnchoose () {
      const order = this.visibleColumns.map((item) => item.value)
      this.$store.dispatch('saveCardViewOrder', order).then(() => { })
    },

    dragStart (e) {},

    // when used as a column filter the value is either searching for
    // empty values '-' or exact strings with ="value"
    columnFilterValue (value) {
      if (value) {
        return '="' + value + '"'
      } else {
        return '-'
      }
    },

    loadColumnItems (column, index) {
      return new Promise(resolve => {
        const filterKey = '_' + this.cardViewInfos[this.objectClass].card_attribute
        const filter = this.filters.find((item) => item.key === filterKey)
        let filterValue
        if (filter) {
          filterValue = filter.value.split(' ').filter((item) => item.startsWith('-')).join(' ')
          filterValue = filterValue + ' ' + (this.columnFilterValue(column.summary || column.value))
        } else {
          filterValue = this.columnFilterValue(column.summary || column.value)
        }
        const filters = [...this.filters,
          { key: 'offset', value: 0 },
          { key: 'limit', value: this.cardViewInfos[this.objectClass].max_items_per_column || this.maxCardsOnColumn },
          { key: 'order', value: this.sortField + ' ' + this.sortBy },
          { key: 'columns', value: index === 0 },
          { key: 'column_key', value: column.value }
        ]
        // main_object_state has complex filtering, not going down that rabbit hole
        if (filterKey !== '_main_object_state') {
          filters.push({ key: filterKey, value: filterValue })
        }

        listViewAPI.getCardItems(this.objectClass,
          this.cardViewInfos[this.objectClass].view_name,
          filters,
          this.queries
        ).then(result => resolve(result))
      })
    },

    loadData () {
      if (this.cardViewInfos[this.objectClass].type === 'state') {
        this.loadInOneQuery()
      } else if (this.cardViewInfos[this.objectClass].type === 'static_list') {
        this.loadInOneQuery()
      } else {
        this.loadByColumn()
      }
    },

    loadInOneQuery () {
      const filters = [...this.filters,
        { key: 'offset', value: 0 },
        { key: 'limit', value: 1000 },
        { key: 'columns', value: true },
      ]
      listViewAPI.getCardItems(this.objectClass, this.cardViewInfos[this.objectClass].view_name, filters, this.queries)
        .then(response => {
          const result = response.data
          this.cardItems = result.items || []
          this.cardColumns = result.columns || this.cardViewInfos[this.objectClass].columns
          if (this.columnsOrdersMissing || this.invalidColumnOrders()) { this.createColumnOrdering() }
          this.initialized = true
        })
    },

    dragEnd (move) {
      console.log(5, move)
    },

    loadByColumn () {
      const promises = this.visibleColumns.map((column, index) => {
        return this.loadColumnItems(column, index)
      })
      Promise.all(promises).then(results => {
        this.cardItems = results.map((res) => res.items).flat() || []
        this.cardColumns = (results.length > 0 && results.find(res => res.columns).columns) || []
        this.cardColumns.forEach((col) => {
          const res = results.find((res) => {
            return res.column_key === col.value || (res.column_key === 'null' && col.value === null)
          })
          col.total = res.total
        })
        if (this.columnsOrdersMissing || this.invalidColumnOrders()) { this.createColumnOrdering() }
        this.initialized = true
      })
    },

    columnInfo (column) {
      if (!this.cardColumns) { return {} }
      return this.cardColumns.find(item => item.value === column.value)
    },

    itemsByColumn (state) {
      if (this.cardViewInfos[this.objectClass].type === 'state') {
        return this.cardItems.filter((item) => item.main_object_state.identifier === state.value)
      } else if (this.cardViewInfos[this.objectClass].type === 'reference') {
        const attribute = this.cardViewInfos[this.objectClass].card_attribute
        const identifier = this.cardViewInfos[this.objectClass].card_attribute_identifier
        return this.cardItems.filter((item) => {
          if (item[attribute] === null) { return state.value === null }
          return item[attribute][identifier] === state.value
        })
      } else {
        const attribute = this.cardViewInfos[this.objectClass].card_attribute
        return this.cardItems.filter((item) => item[attribute] === state.value)
      }
    },

    // new object targeted to the top get -1 as the card_order. If such an item is found,
    // redo the ordering to start from 0
    invalidColumnOrders () {
      return this.cardItems && this.cardItems.some(item => item.card_order < 0)
    },

    createColumnOrdering () {
      this.visibleColumns.forEach((column) => {
        const sortedItems = this.itemsByColumn(column).sort((a, b) => {
          return (a.card_order > b.card_order) ? 1 : -1
        })
        sortedItems.forEach((item, index) => {
          item.card_order = index
        })
      })
      const columnOrdering = this.cardItems.map(item => {
        return {
          id: item.id,
          order: item.card_order,
        }
      })
      this.$store.dispatch('updateColumnOrdering', columnOrdering).then(() => {})
    }
  }
}
</script>

<style lang="scss">
.card-column-draggable {
  justify-content: center;
  border-radius: 10px !important;
  height: inherit;
}
</style>
