<template>
  <div
    style="position: absolute;
    right: 0;
    bottom: 0;
    width: 100px;
    height: 100px;
    z-index: 10;"
    cols="6"
  >
    <div
      style="position: absolute;
      right: -52px;
      bottom: -28px;
      width: 120px;
      height: 120px;"
    >
      <VueSvgGauge
        :start-angle="-100"
        :end-angle="10"
        :value="value"
        :separator-step="1"
        :min="0"
        :max="block.levels"
        :inner-radius="85"
        :separator-thickness="2"
        :gauge-color="[{ offset: 0, color: color }, { offset: 100, color: color }]"
        :scale-interval="0"
      />
    </div>
    <div
      style="max-width: 110px;
                  z-index:2;
                  bottom: 0;
                  right: 0;
                  position: absolute;"
    />
    <div
      style="position: absolute;
                  bottom: 0;
                  right: 0;
                  text-align: center;
                  line-height: 44px;
                  font-weight: 700;
                  font-size: 22px;
                  z-index: 1;
                  color: #777;
                  width: 47px;
                  height: 47px;
                  border-radius: 50px;
                  background_: #eee;
                  "
    >
      {{ block.level }}
    </div>
    <LevelBar
      :block="block"
      class="d-none"
    />
  </div>
</template>
<script>
import LevelBar from './LevelBar'

export default {
  name: 'LevelWithBar',

  components: {
    LevelBar,
  },

  props: {
    block: {},
  },

  data () {
    return {
      value: 0,
    }
  },

  computed: {
    color () {
      return this.block.level > 3
        ? '#43A047'
        : this.block.level < 2
          ? '#FFC107'
          : '#FF9800'
    },
  },

  created () {
    setTimeout(() => {
      this.value = this.block.level
    }, 1000)
  },
}
</script>
