import BreakdownViewLineAttribute from '@/components/BreakdownView/BreakdownViewLineAttribute.vue'
import breakdownMethods from '@/components/BreakdownView/breakdownMethods'
import util from '../../utilities/sharedUtilities'

export default {
  name: 'BreakdownViewLine',

  components: {
    BreakdownViewLineAttribute,
    BreakdownViewLine: () => import('@/components/BreakdownView/BreakdownViewLine.vue'), // For Item > ItemForm > Item to be possible
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
    level: {
      type: Number,
      default: 0,
    },
    breakdownAttributes: {
      type: Array,
      default: () => [],
    },
    totalSums: {
      type: Object,
      default: () => {},
    },
    sortColumn: {
      type: String,
      default: () => null,
    },
    sortOrder: {
      type: String,
      default: () => null,
    },
    singleUnitAttributes: {
      type: Array,
      default: () => { return [] },
    },
  },

  data () {
    return {
      showChildren: false
    }
  },

  computed: {
    sortedSubgroups () {
      return this.sortSubgroups([...this.data.subgroups], this.sortColumn, this.sortOrder)
    },

    hasChildren () {
      return this.data.subgroups && this.data.subgroups.length > 0
    },

    objectClass () {
      return this.$store.getters.objectClass
    },

    titleStyle () {
      return {
        paddingLeft: (40 * this.level - 30) + 'px !important'
      }
    }
  },

  created () {
    if (this.level === 1) {
      this.showChildren = true
    }
  },

  methods: {
    ...breakdownMethods,

    toggleShowChildren () {
      this.showChildren = !this.showChildren
    },

    openIds () {
      const url = '/#' + util.objectClassUnderscoredName(this.objectClass) +
        '?_unique_ids=' + this.data.ids.join(',')
      window.open(url, '_blank')
      // window.location = url
    }
  }
}
