<template>
  <v-menu
    :z-index="160"
    :close-on-content-click="true"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        :ripple="false"
        class="text-none"
        tile
        text
        icon
        small
        v-on="on"
      >
        <div>
          <v-icon small>
            fa-ellipsis-v
          </v-icon>
        </div>
      </v-btn>
    </template>
    <div class="layout-profiles-menu elevation-2">
      <LayoutProfileTab
        v-for="(layoutProfile, index) in itemLayoutProfiles"
        :key="layoutProfile.id + '_' + index"
        :index="index"
        :layout-profile="layoutProfile"
        :show-menu-btn="false"
      />
    </div>
  </v-menu>
</template>

<script>
import LayoutProfileTab from '../../LayoutProfiles/LayoutProfileTab'

export default {
  name: 'MobileItemLayoutProfilesMenu',

  components: {
    LayoutProfileTab,
  },

  props: {
    itemLayoutProfiles: {
      type: Array,
      default: () => {},
    },
  },

  data () {
    return {}
  },
}
</script>
