export default {
  // merge the data coming with the new chunk to the totals
  // in totalsCollection
  _mergeCollection (collection, totalsCollection) {
    if (!collection) { return totalsCollection }
    return this._mergeLevel(totalsCollection.items, collection.items)
  },

  _mergeLevel (totals_level, newData) {
    if (!newData || !newData.ids || newData.ids.length === 0) { return }
    totals_level.ids.push.apply(totals_level.ids, newData.ids)
    if (newData.sums) {
      this._mergeSums(totals_level.sums, newData.sums)
    }
    if (newData.counts) {
      this._mergeCounts(totals_level.counts, newData.counts)
    }
    newData.subgroups.forEach(subgroup => {
      const subgroup_in_totals = totals_level.subgroups.find(item => item.group_attribute === subgroup.group_attribute &&
        item.group_title === subgroup.group_title)
      if (subgroup_in_totals) {
        // if subgroup is found both in the totals and the new chunk those
        // are merged
        this._mergeLevel(subgroup_in_totals, subgroup)
      } else {
        // if subgroup is NOT found both in the totals the subgroup coming
        // with this path is put to the totals
        totals_level.subgroups.push(subgroup)
      }
    })
  },

  // merging the sums means setting the different kind of sum fields to
  // correct new values depending on the field type
  _mergeSums (totalSums, newSums) {
    Object.keys(newSums).forEach(key => {
      if (totalSums[key]) {
        if (typeof (totalSums[key]) === 'object') {
          this._mergeObjects(totalSums[key], newSums[key])
        } else {
          // numeric sum, need to be added up. Some values come as strings, hence the parseFloat
          totalSums[key] = parseFloat(totalSums[key]) + parseFloat(newSums[key])
        }
      } else {
        // totals does not have these sums yet, use the new ones as such
        totalSums[key] = newSums[key]
      }
    })
  },

  _mergeObjects (totalSum, newSum) {
    Object.keys(newSum).filter((key) => key !== 'type').forEach(subKey => {
      if (totalSum[subKey]) {
        totalSum[subKey].value = parseFloat(totalSum[subKey].value) + parseFloat(newSum[subKey].value)
      } else {
        totalSum[subKey] = newSum[subKey]
      }
    })
  },

  // merging the counts. Counts is a hash with attribute names as the keys. The values
  // are hashes of all enumerable attribute values with their integer counts. For example
  //  {is_manager: {false: 22, true: 3}} for boolean and
  //  {priority: {'1': 4, '2': 12, '3': 1}} for a static list type
  _mergeCounts (totalCounts, newCounts) {
    Object.keys(newCounts).forEach(key => {
      if (totalCounts[key]) {
        // totals have already these counts, add all subvalues
        Object.keys(newCounts[key]).forEach(subKey => {
          totalCounts[key][subKey] = (totalCounts[key][subKey] || 0) + newCounts[key][subKey]
        })
      } else {
        // totals does not have these counts yet, use the new ones as such
        totalCounts[key] = newCounts[key]
      }
    })
  },
}
