import Vue from 'vue'
import util from '../../../utilities/sharedUtilities'

export default {
  savePivotTableConfig: ({ state, dispatch }, { config }) => {
    const layoutProfileIndex = util.findLayoutProfileIndexById(state, state.selectedLayoutProfileId)
    Vue.set(state.layoutProfiles[layoutProfileIndex].timelineConfiguration,
      'pivot_configuration', config)
    return dispatch('saveLayoutProfileData', state.selectedLayoutProfile)
  }
}
