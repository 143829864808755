import { AxiosResponse } from 'axios'

export default {
  fetchNoSeItems (className: string): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/nose/index/' + className, [], [])
  },

  loadNoSeActions (className: string): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/nose/actions/' + className, [], [])
  },

  loadNoSeAttributes (className: string): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/nose/attributes/' + className, [], [])
  },

  loadNoSeRecipients (className: string): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/nose/recipients/' + className, [], [])
  },

  noSeActivate (id: string | number, value: string): Promise<AxiosResponse> {
    return this.sendPutRequest('/gorilla/nose/activate/' + id + '/' + value, [], [])
  },

  noSeShare (id: string | number, value: string): Promise<AxiosResponse> {
    return this.sendPutRequest('/gorilla/nose/share/' + id + '/' + value, [], [])
  },

  noSeSubscribe (id: string | number, value: string): Promise<AxiosResponse> {
    return this.sendPutRequest('/gorilla/nose/subscribe/' + id + '/' + value, [], [])
  },

  noSeRemove (parameters: any): Promise<AxiosResponse> {
    return this.sendDeleteRequest('/gorilla/nose', parameters)
  },

  noSeCreate (parameters: any): Promise<AxiosResponse> {
    return this.sendSimplePostRequest('/gorilla/nose', parameters)
  },

  noSeUpdate (parameters: any): Promise<AxiosResponse> {
    return this.sendSimplePutRequest('/gorilla/nose', parameters)
  },

  fetchNoSeItem (id: number | string): Promise<AxiosResponse> {
    return this.sendRequest('/gorilla/nose/show/' + id, [], [])
  }
}
