import { ListEditSaveJob } from '@/types/ListEdit'
import util from '@/utilities/sharedUtilities'

export default {
  endListEditTimer () {
    clearTimeout(this.listEdit.queueTimer)
    this.listEdit.queueTimer = null
  },

  processListEditQueueJobs () {
    if (this.listEdit.queue.length) {
      const job = this.listEdit.savingJob = this.listEdit.queue.shift()
      // console.log('processing.job', this.listEdit.savingJob)
      this.updateSaveJobItemDataBefore(this.listEdit.savingJob)
      this.saveListCellValue(this.listEdit.savingJob).then(() => {
        if (!this.listEdit.queue.length) {
          this.listEdit.savingJob = null
        }
        this.proceedTimer(10)
      })
    } else {
      this.proceedTimer()
    }
  },

  // Right before processing save request from the queue
  // update item data before save, to keep user changes
  updateSaveJobItemDataBefore (job: ListEditSaveJob) {
    const objectClass = job.itemDataBeforeSave['@class']
    // Cancel when user has opened another model index view
    if (util.objectClassUnderscoredName(objectClass) !== this.objectClass) { return }
    // Find item index from the list
    const index = this.listItems.findIndex(listItem => listItem && listItem.id === job.id)
    // When still present in the list, update itemDataBeforeSave, so that when request comes back, can not overwrite user changes
    if (index > -1) {
      job.itemDataBeforeSave = JSON.parse(JSON.stringify(this.listItems[index]))
    }
  },

  proceedTimer (timeout = 100) {
    this.listEdit.queueTimer = setTimeout(() => {
      clearTimeout(this.listEdit.queueTimer)
      this.listEdit.queueTimer = null
      this.processListEditQueueJobs()
    }, timeout)
  },

  // saveListEditJob (job: ListEditSaveJob) {
  //   return new Promise(resolve => {
  //     console.log('____PROCESS:', job)
  //     resolve(true)
  //   })
  // },
}
