<template>
  <v-container
    fluid
    class="px-6 pt-0 pb-8 kpi-dashboard fill-height"
    :style="style"
  >
    <v-row v-if="render">
      <v-col
        v-for="(block, chart_i) in blocks"
        :key="chart_i"
        cols="12"
        md="6"
        lg="4"
        class="pa-4"
        @click="$emit('showModal', chart_i)"
      >
        <v-container
          class="elevation-5"
          style="position: relative;
          cursor: pointer;
          border-radius: 8px;
          overflow: hidden;
          background: white;
          height: 280px;"
        >
          <LevelWithBar
            v-if="showLevels"
            :block="block"
          />
          <v-row>
            <v-col class="header text-left pl-6 pt-4 pb-2">
              <v-icon
                class="mt-n1 mr-3 d-none"
                color="#bbb"
              >
                {{ block.icon || 'fa-folder' }}
              </v-icon>
              {{ $t(block.title) }}
            </v-col>
            <v-col
              class="text-right pt-5 pr-5 pb-2"
              cols="3"
            >
              <v-btn icon>
                <v-icon>
                  fa-chart-line
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="showChartsCount >= chart_i + 1">
            <v-col
              v-if="block.graph.type"
              style="height: 150px;
              position: relative;"
            >
              <CustomBars
                v-if="block.graph.type === 'custom-bars'"
                class="kpi-block-number"
              />
              <MultiGauge
                v-else-if="block.graph.type === 'multi-gauge'"
                class="kpi-block-number"
              />
              <WeeksChart
                v-else-if="block.graph.type === '2-weeks'"
                class="kpi-block-number"
              />
              <PeopleChart
                v-else-if="block.graph.type === 'people'"
                class="kpi-block-number"
              />
              <DashboardChart
                v-else-if="block.graph.type === 'chart.js'"
                :space-allocation="{
                  box_height: 100,
                  box_width: 400,
                }"
                :chart-data="{
                  data: block.graph.chartData,
                  options: block.graph.chartOptions,
                  type: block.graph.chartType,
                }"
              />
              <div
                v-else
                class="kpi-block-number"
                style="font-size: 36px; color: grey;"
              >
                <svg-icon
                  type="mdi"
                  :path="path"
                  :size="48"
                  :style="'opacity: 0.45;transform: rotate(' + (block.graph.direction === 'up' ? 225 : 315) + 'deg);'"
                  :color="block.graph.direction === 'up' ? 'green' : 'red'"
                />
                {{ block.graph.value }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import KPI_blocks from './data/KPI_blocks'
import { mdiArrowDownBold } from '@mdi/js'
import DashboardChart from '../DashboardChart'
import LevelWithBar from './LevelWithBar'
import PeopleChart from './PeopleChart'
import WeeksChart from './WeeksChart'
import CustomBars from './CustomBars'
import MultiGauge from './MultiGauge'

export default {
  name: 'KPI',

  components: {
    MultiGauge,
    CustomBars,
    WeeksChart,
    PeopleChart,
    LevelWithBar,
    DashboardChart,
    SvgIcon,
  },

  data () {
    return {
      render: true,
      blocks: KPI_blocks,
      path: mdiArrowDownBold,
      showChartsCount: 0,
      showLevels: true,
    }
  },

  computed: {
    style () {
      return {
        background: '#eee',
        height: (this.$store.state.innerHeight - 100) + 'px',
        overflow: 'auto',
      }
    },
  },

  created () {
    setTimeout(() => {
      this.showChartsCount = 9
      // this.showNextChart()
    }, 200)
  },

  methods: {
    reload () {
      this.render = false
      this.$nextTick(() => {
        this.render = true
      })
    },

    showNextChart () {
      this.showChartsCount++
      if (this.showChartsCount < 9) {
        setTimeout(() => {
          this.showNextChart()
        }, 0) // Not so great effect?
      }
    },
  },
}
</script>

<style lang="scss">
.kpi-dashboard {
  .chart_container, .kpi-block-number {
    position: absolute;
    top: 50%; right: 50%;
    transform: translate(50%,-50%);
  }
  .header {
    font-size: 28px;
    font-weight: 300;
    font-family: 'Raleway', sans-serif;
  }
}
.font-lato {
  font-family: 'Lato', sans-serif;
}
</style>
