<template>
  <v-container fluid class="container-form">
    <div class="field-set-form multiple-field-sets">
      <v-container v-if="loaded" fluid class="field-set-form-inner-container">
        <CustomerComponent
          v-if="item && item.id"
          :item="item"
          :edit="edit"
        />
      </v-container>
    </div>
  </v-container>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'CustomAppComponent',

  props: {
    appFile: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      loaded: false,
    }
  },

  created () {
    this.loadCustomerComponent()
  },

  methods: {
    async loadCustomerComponent () {
      const customerComponent = await import(`@/customer-apps/${this.appFile}`)
      Vue.component('CustomerComponent', customerComponent.default)
      this.loaded = true
    },
  },
}
</script>
