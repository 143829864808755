export default {
  // Initializes Vue list view for object class
  // Called when user changes module or Layout Profile
  openLayoutProfileList () {
    return new Promise(resolve => {
      this.emptyList()
      this.$store.dispatch('loadLayoutProfileWithItems')
        .then(() => {
          this.renderLayoutProfileList().then(() => {
            if (document.getElementById('vueList')) {
              document.getElementById('vueList').addEventListener('scroll', this.handleListScroll)
            }
          })
          resolve()
        })
    })
  },

  getColumnsToRender () {
    let leftOffset = 0
    const buffer = 100
    return this.layoutProfileItems
      .filter(field => field.visible)
      .filter(field => {
        const fixedWidth = field.userFixedWidth || field.forcedWidth
        const render = !!(fixedWidth > 0 && leftOffset >= (this.fixedScrolledLeft - fixedWidth - buffer) &&
          leftOffset < (window.innerWidth + buffer) + this.fixedScrolledLeft)
        leftOffset += fixedWidth
        return render
      })
      .map(field => field.name)
  },

  reloadListItems () {
    this.resetListScroll()
    return this.$store.dispatch('reloadListItems', { updateCounts: true })
  },

  // TODO - when item is changed, update list and keep scroll position
  // Disabled for now as scrolledTop is set to 0 and does not render as should
  // Also when in the middle of big list, should not start loading items from the top
  // reloadListItemsAndKeepScrollPosition () {
  //   return this.$store.dispatch('reloadListItems', { updateCounts: true })
  // },

  reloadListItemsWithoutCounts () {
    this.resetListScroll()
    return this.$store.dispatch('reloadListItems', { updateCounts: false })
  },

  renderLayoutProfileList () {
    return new Promise(resolve => {
      this.$store.dispatch('setListVisibleItems', {})
      this.$nextTick(() => {
        this.adjustCellWidthsAndLineHeight()
        resolve(true)
      })
    })
  },

  showMessage (payload) {
    this.$store.dispatch('addFlashMessage', {
      message: payload.message,
      type: 'info',
    })
  },

  listViewMouseUpListener () {
    if (this.draggingFieldName) {
      this.draggingFieldName = null
      // Update actual sort_order values
      // Otherwise columns selector popup does not have columns in updated order
      this.layoutProfileItems.forEach((lpi, index) => {
        lpi.sort_order = index
      })
      this.$store.dispatch('sortLayoutProfileItems')
    } else if (this.resizingHasManyField) {
      this.hasManyColumnResizeEnd()
    } else if (this.resizingFieldName) {
      this.$store.dispatch('saveLayoutProfileData', this.selectedLayoutProfile)
      this.resizingFieldName = null
      setTimeout(() => {
        this.adjustCellWidthsAndLineHeight()
        this.setOverflowingTextTimer()
      }, 1000)
    } else if (this.draggingLayoutProfileIndex !== null) {
      this.draggingLayoutProfileIndex = null
      this.$store.dispatch('sortLayoutProfiles')
    }
    // Reset drag start index
    // Must be here (not in listItemMouseUpHandler) to guarantee triggering
    this.dragStartIndex = null
  },

  resetList () {
    this.$store.commit('resetListMeta')
    this.$store.commit('resetListCount')
    this.$store.commit('resetListData')
    this.rowHeight = this.defaultRowHeight
  },

  emptyList () {
    this.$store.commit('resetListCount')
    this.$store.commit('resetListData')
    this.rowHeight = this.defaultRowHeight
  },

  handleListScroll (e) {
    if (e.target) {
      this.scrolledLeft = e.target.scrollLeft
      const actionsContainer = document.getElementById('list-header-actions-container')
      // Actions container is outside of list container
      // so this has to be scrolled separately
      if (actionsContainer) {
        actionsContainer.scrollLeft = e.target.scrollLeft
      }
      this.scrolledTop = e.target.scrollTop
    }
  },

  confirmDeleteSelected () {
    this.showBulkDeleteDialog = true
  },

  getSelectedIds () {
    return this.selectedItems
      .filter(index => this.listItems[index] && this.listItems[index].id)
      .map(index => this.listItems[index].id)
  },

  hideAllListHeaderActionMenus () {
    this.showHeaderActionsActionsMenu = false
    this.showHeaderActionsDownloadMenu = false
    this.showListTextLanesMenuMenu = false
    this.showListColumnsSelector = false
  },

  goToListNextItem () {
    if (!this.selectedId) { return }
    const selectedIndex = this.listItems.findIndex(item => item && item.id === this.selectedId)
    if (selectedIndex >= this.listItems.length - 1) { return }
    const nextItem = this.listItems[selectedIndex + 1]
    this.listItemOpenById(nextItem.id, { className: this.objectClass })
    const el = document.getElementById('vueList')
    if (el) {
      el.scrollTo({
        top: this.scrolledTop + this.rowHeight + 12, // + padding
        behavior: 'smooth',
      })
    }
  },

  goToListPreviousItem () {
    if (!this.selectedId) { return }
    const selectedIndex = this.listItems.findIndex(item => item && item.id === this.selectedId)
    if (selectedIndex === 0) { return }
    const previousItem = this.listItems[selectedIndex - 1]
    if (!previousItem) { return }
    this.listItemOpenById(previousItem.id, { className: this.objectClass })
    const el = document.getElementById('vueList')
    if (el) {
      el.scrollTo({
        top: this.scrolledTop - this.rowHeight - 12, // - padding
        behavior: 'smooth',
      })
    }
  },

  scrollToEditableField (rowIndex, field) {
    const el = document.getElementById('vueList')
    const scrollPx = 100
    if (el) {
      // Place to the middle of the screen
      const left = field.leftOffset - (this.$store.state.innerWidth / 2) + ((field.userFixedWidth || field.forcedWidth || 100) / 2)
      el.scrollTo({
        left,
        behavior: 'smooth',
      })
    }
  },

  setListPageTitle () {
    window.document.title = this.translationsLoaded ? this.$i18n.t(this.resource + '.list.title') : 'Aava Portal'
  },
}
