<template>
  <div
    v-if="dialogItemContainerMenu"
    id="item-container-menu"
    class="item-container-menu popup-menu elevation-5"
  >
    <div
      class="popup-menu-el"
      @click.stop="addNewContainerCallback"
    >
      <a href="javascript:;">
        <span class="fa fa-plus" />
        {{ $i18n.t('aava.buttons.add_new') }}: {{ $i18n.t('aava.general.group') }}
      </a>
    </div>
    <div
      class="popup-menu-el"
      @click.stop="addNewFieldSetCallback()"
    >
      <a href="javascript:;">
        <span class="fa fa-plus-square" />
        {{ $i18n.t('aava.buttons.add_new') }}: {{ $i18n.t('aava.general.subgroup') }}
      </a>
    </div>
    <div
      class="popup-menu-el"
      @click.stop="toggleContainerInfoTextFieldCallback(itemContainerForEdit)"
    >
      <a href="javascript:;">
        <span
          :class="itemContainerForEdit.showInfoText ? 'fa fa-minus': 'fa fa-plus'"
        />
        {{ itemContainerForEdit.showInfoText ? 'Hide' : 'Show' }} info text
      </a>
    </div>
    <div class="menu-separator-line" />
    <div
      class="popup-menu-el"
      @click.stop="deleteHandler"
    >
      <a href="javascript:;">
        <span class="fa fa-trash" />
        {{ $i18n.t('aava.actions.remove') }}
      </a>
    </div>
  </div>
</template>

<script>
import methods from './../../components/methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ItemContainerMenu',

  data () {
    return {
      saving: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    containers () {
      return this.layoutContainers[this.selectedItemLayoutProfileId] || []
    },
  },

  watch: {
  },

  created () {
    document.addEventListener('click', this.clickListener)
  },

  destroyed () {
    document.removeEventListener('click', this.clickListener)
  },

  methods: {
    ...methods,

    deleteHandler () {
      this.deleteLayoutContainer(this.itemContainerForEdit, {})
    },

    clickListener (e) {
      if (this.dialogItemContainerMenu) {
        const el = document.getElementById('item-container-menu')
        if (el && !el.contains(e.target)) {
          this.dialogItemContainerMenu = false
        }
      }
    },
  }
}
</script>
