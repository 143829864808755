import api from '@/store/api/'
import previewListData from './helpers/previewListData'
import shortcutData from './helpers/shortcutData'
import dashboardAvailableItems from './helpers/dashboardAvailableItems'
import router from '@/router'
import i18n from '@/locale/i18n'
import { AxiosResponse } from 'axios'
import { DashboardItemsR, DashboardOptionsR, DashboardTypes } from '@/types/DashboardTypes'

export default {
  loadAndPrepareDashboardContent: ({ state, dispatch }) => {
    // Set delay to get dashboard items and dashboard items content
    // For list items and item show/edit view requests to be sent before
    // Unless user is on dashboard page
    const isDashboardPage = router.app.$route.name?.includes('DashboardContainer')
    const getDashboardItemsDelay = isDashboardPage ? 0 : 1000
    setTimeout(() => {
      api.fetchDashboardItems().then((response: AxiosResponse<DashboardItemsR>) => {
        const items_from_api = response.data.items
        // Set options from dashboardItems
        state.dashboardRefreshTimeout = response.data.options?.refresh_timeout
        // Set shortcuts from dashboardItems
        dispatch('setShortcutsToStore', items_from_api)
        // Set dashboardTabs from dashboardItems
        dispatch('setDashboardTabsToStore', { dashboardItems: items_from_api })
        setTimeout(() => {
          dispatch('getAndSetDashboardItemsContent', items_from_api).then(completed_dashboardItems => {
            dispatch('loadAvailableDashboardItems', completed_dashboardItems)
          })
        }, getDashboardItemsDelay)
      })
    }, getDashboardItemsDelay)
  },

  getAndSetDashboardItemsContent: ({ commit, dispatch }, items_from_api) => {
    return new Promise(resolve => {
      // Get dashboard items content
      api.getDashboardItemsContent(items_from_api).then(items_with_content => {
        dispatch('completeDashboardItemsData', items_with_content)
          .then(completed_dashboardItems => {
            commit('setDashboardContent', completed_dashboardItems.filter((item) => item.element_type !== 'link'))
            resolve(completed_dashboardItems)
          })
      })
    })
  },

  completeDashboardItemsDataForOne: ({ dispatch }, dashboardItem) => {
    return new Promise(resolve => {
      if (dashboardItem) {
        if (dashboardItem.previewData?.items || dashboardItem.element_type === 'chart') {
          let completed_preview = dashboardItem
          // Set to tab
          try {
            if (dashboardItem.config) {
              const config = JSON.parse(dashboardItem.config)
              dashboardItem.tabId = config?.tabId
            }
          } catch (e) {
            alert('Not valid JSON')
          }
          if (dashboardItem.element_type !== 'chart') {
            dispatch('getAttributeMetadata', dashboardItem.object_type).then(objectsMetadata => {
              completed_preview = previewListData.completeOnePreviewListData(dashboardItem, objectsMetadata)
              resolve(completed_preview)
            })
          } else {
            resolve(completed_preview)
          }
        } else {
          resolve(null)
        }
      } else {
        resolve(null)
      }
    })
  },

  completeDashboardItemsData: ({ dispatch }, dashboardItems) => {
    const promises: any[] = []
    return new Promise(resolve => {
      const completed_dashboardItems: any = []
      dashboardItems.forEach(preview => {
        promises.push(dispatch('completeDashboardItemsDataForOne', preview))
      })
      Promise.all(promises).then(responses => {
        responses.forEach((completedItem: any, index) => {
          if (!completedItem) { return }
          completedItem.sort_order = index
          completed_dashboardItems.push(completedItem)
        })
        resolve(completed_dashboardItems)
      })
    })
  },

  loadAvailableDashboardItems: ({ state, dispatch }, completed_dashboardItems) => {
    // Get available items & mark which have been already added
    api.getAvailableItems().then((response: AxiosResponse<DashboardOptionsR>) => {
      dispatch('globalErrorDisplay', { response, context: 'Get dashboard items' })
      if (!response.data.data) { return }
      state.dashboardAvailableItems = dashboardAvailableItems.setAvailableItemsIfAdded(completed_dashboardItems, response.data.data)
    })
  },

  setShortcutsToStore: ({ state }, dashboardItems) => {
    const completed_shortcuts = previewListData.completeShortcutsData(dashboardItems)
    state.shortcutKeys = shortcutData.getShortcutKeys(completed_shortcuts)
    state.shortcuts = completed_shortcuts
    state.loadingShortcuts = false
  },

  createDefaultTab: ({ dispatch, state }) => {
    // Create new tab
    api.gorillaAddDashboardItem({
      type: 'tab',
      name: i18n.t('aava.general.dashboard') as string,
    }).then((response: AxiosResponse) => {
      dispatch('globalErrorDisplay', { response, context: 'Add new default dashboard tab' })
      api.fetchDashboardItems().then((response: AxiosResponse) => {
        dispatch('setDashboardTabsToStore', {
          dashboardItems: response?.data.items,
          createDefaultTabIfNonePresent: false,
        })
        state.selectedDashboardTabId = state.dashboardTabs[0]?.id
      })
    })
  },

  setDashboardTabsToStore: ({ state, dispatch }, {
    dashboardItems,
    // To avoid loop when for some reason actually default tab was not created
    createDefaultTabIfNonePresent = true,
  }: {
    dashboardItems: DashboardTypes.Item[],
    createDefaultTabIfNonePresent: boolean,
  }) => {
    state.dashboardTabs = dashboardItems
      .filter(item => item.element_type === 'tab') // Only 'tab' type elements
      .map(item => {
        item.name = item.element_action
        return item
      })
    // If no tabs, create new default one
    if (!state.dashboardTabs.length && createDefaultTabIfNonePresent) {
      dispatch('createDefaultTab')
    }
    // Activate first tab
    else if (!state.selectedDashboardTabId && state.dashboardTabs[0]?.id) {
      state.selectedDashboardTabId = state.dashboardTabs[0]?.id
    }
  },
}
