
import { LP } from '@/types/LP.types'

export default {
  name: 'ContainerName',
  props: {
    container: {
      type: Object as () => LP.Container,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
}
