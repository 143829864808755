import Vue from 'vue'
import VueRouter from 'vue-router'
import List from '../views/List.vue'
import DashboardContainer from '@/components/Dashboard/DashboardContainer'
import FormVisual from '@/components/App/FormVisual'
import CompatibilityView from '@/components/App/CompatibilityView'
import goToLoginApp from '../utilities/goToLoginApp'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(error => {
    if (error.name !== 'NavigationDuplicated' && !error.message.includes('redundant navigation')) {
      throw error
    }
  })
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    redirect: '/',
  },
  {
    path: '/reset_password/:reset_token',
    props: true,
    beforeEnter (to, from, next) {
      goToLoginApp({ action: 'reset_password', resetToken: to?.params?.reset_token })
    }
  },
  {
    path: '/',
    name: 'DashboardContainer',
    component: DashboardContainer,
  },
  {
    path: '/dashboard',
    name: 'DashboardContainerDirect',
    component: DashboardContainer,
  },
  {
    path: '/form/visual',
    name: 'FormVisual',
    component: FormVisual,
  },
  {
    path: '/compatibility/:modelName/:viewName',
    name: 'CompatibilityViewWithName',
    component: CompatibilityView,
    props: true,
  },
  {
    path: '/compatibility/:modelName',
    name: 'CompatibilityView',
    component: CompatibilityView,
    props: true,
  },
  {
    path: '/:resource',
    name: 'List_index',
    component: List,
    props: true,
  },
  {
    path: '/:resource/:id',
    name: 'List_ItemShow',
    component: List,
    props: true,
  },

  // Split mode routes
  {
    path: '/:resource/split/:firstResource/:firstItemId/:firstItemView',
    name: 'List_SplitMode',
    component: List,
    props: true,
  },
  {
    path: '/:resource/split/:firstResource/:firstItemId/:firstItemView/:secondResource',
    name: 'List_SplitModeOneItemLeft',
    component: List,
    props: true,
  },
  {
    path: '/:resource/split/:firstResource/:firstItemId/:firstItemView/:secondResource/:secondItemId/:secondItemView',
    name: 'List_SplitModeTwoItems',
    component: List,
    props: true,
  },

  // For routes
  // when has-many list was opened from the parent item (count icon)
  {
    path: '/:resource/for/:targetResource/:targetId/:targetField',
    name: 'List_forTarget',
    component: List,
    props: true,
  },
  {
    path: '/:resource/for/:targetResource/:targetId/:targetField/:id/:itemAction',
    name: 'List_forTargetWithForm',
    component: List,
    props: true,
  },
  {
    path: '/:resource/for/:targetResource/:targetId/:targetField/:firstResource/:firstItemId/:firstItemView',
    name: 'List_forTargetWithFormForAnotherResource',
    component: List,
    props: true,
  },

  // Add new for has-many
  // New has-many item form from parent has-many "add new" btn
  {
    path: '/:resource/:id/for/:targetResource/:targetId/:targetField',
    name: 'List_ItemForTarget',
    component: List,
    props: true,
  },
  {
    path: '/:resource/:id/:itemAction',
    name: 'List_ItemAction',
    component: List,
    props: true,
  },
  {
    path: '/:resource/:id/:itemAction/:specialAction',
    name: 'List_ItemActionWithSpecialAction',
    component: List,
    props: true,
  },
]

const router = new VueRouter({
  routes,
})

export default router
