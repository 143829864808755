<template>
  <div>
    <v-navigation-drawer
      v-model="showMobileItemMenu"
      style="z-index: 200; border: 0; height: auto !important;max-height: 100%;"
      class="elevation-0 mobile-item-navigation"
      color="white"
      clipped
      absolute
      bottom
    >
      <v-container
        fluid
      >
        <v-row>
          <v-col class="pb-0 px-0">
            <ItemHistoryBtn
              :item="item"
              :is-mobile-bottom-toolbar="true"
              :resource="resource"
            />
            <ItemDownloadActions
              :is-mobile-bottom-toolbar="true"
              :download-links="item['@displayable_documents']"
            />
            <ItemMetaActions
              :item-meta="itemMeta"
              :is-mobile-bottom-toolbar="true"
              @sendItemMetaAction="sendItemMetaAction"
            />
            <ItemToolbar
              v-if="item"
              :id="id"
              ref="itemToolbar"
              :edit="edit"
              :item="item"
              :item-meta="itemMeta"
              :resource="resource"
              :show-loader="showLoader"
              :save-form-item="saveFormItem"
              :is-mobile-bottom-toolbar="true"
              @updateShowLoader="updateShowLoader"
              @refreshItem="$emit('refreshItem', { reRenderAfterItemIsLoaded: true })"
            />
          </v-col>
          <v-col
            cols="12"
            class="text-center py-3"
          >
            <v-btn
              color="#bbb"
              text
              @click="showMobileItemMenu = false"
            >
              <v-icon>fa-times</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
    <v-bottom-navigation
      absolute
    >
      <v-btn
        v-if="edit"
        :disabled="!item['@editable']"
        :loading="saving"
        @click="save"
      >
        <!-- span>{{ this.$i18n.t('aava.edit.save') }}</span -->
        <v-icon color="green">
          fa-save
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        :disabled="item['@editable'] !== true"
        @click="openEditForm"
      >
        <!-- span>{{ this.$i18n.t('aava.edit.save') }}</span -->
        <v-icon color="orange">
          fa-edit
        </v-icon>
      </v-btn>

      <v-btn @click="openList">
        <!-- span>{{ this.$i18n.t('aava.edit.index') }}</span -->

        <v-icon color="green">
          fa-list
        </v-icon>
      </v-btn>

      <v-btn @click="showMobileItemMenu = !showMobileItemMenu">
        <!-- span>More</span -->
        <v-icon>fa-ellipsis</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
import ItemToolbar from '@/components/Item/Toolbar/ItemToolbar.vue'
import ItemMetaActions from '@/components/Item/Toolbar/ItemMetaActions'
import ItemDownloadActions from '@/components/Item/Toolbar/ItemDownloadActions'
import ItemHistoryBtn from '@/components/Item/ItemHistoryBtn'
import methods from '@/components/methods'
import state from '@/store/state'

import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {

  name: 'MobileItemBottomToolbar',

  components: {
    ItemHistoryBtn,
    ItemDownloadActions,
    ItemMetaActions,
    ItemToolbar,
  },

  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    resource: {
      type: String,
      default: '',
    },
    saveFormItem: {
      type: Function,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    itemMeta: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    updateShowLoader: {
      type: Function,
      default: () => {},
    },
    sendItemMetaAction: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      showMobileItemMenu: false,
      saving: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    ...methods,

    save (button) {
      this.saving = true
      this.saveFormItem({}).then(() => {
        this.saving = false
      })
    },
  },
}
</script>
