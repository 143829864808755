import Raven from 'raven-js'
import { AxiosResponse } from 'axios'
import { ContainerSavePayload } from '@/types/LP.types'

export default {
  saveLayoutContainer (layoutContainer: ContainerSavePayload, queries): Promise<AxiosResponse> {
    return new Promise(resolve => {
      this.saveItem('layout_containers', layoutContainer, queries).then((response: AxiosResponse) => {
        resolve(response)
        if (!response.data.item) {
          Raven.captureMessage('Layout container not created (API)', {
            level: 'error',
            extra: {
              layoutContainer,
              queries,
              response,
            }
          })
        }
      })
    })
  },
}
