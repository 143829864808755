import { AxiosResponse } from 'axios'
import { BaseItem } from '@/types/AppTypes'
import { LP } from '@/types/LP.types'

export default {
  createNewLayoutProfile (payload) {
    const formData: any = {}
    if (payload.selectedTemplate) {
      // Send doCopy only if template has been selected
      if (payload.doCopy) {
        formData['createOptions[doCopy]'] = payload.doCopy
      }
      formData['createOptions[clonedFrom]'] = payload.selectedTemplate
    }
    formData.name = payload.name || ''
    if (payload.timelineConfiguration) {
      formData.timeline_configuration = JSON.stringify(payload.timelineConfiguration)
    }
    if (payload.createDefault) {
      return this.sendPostRequest('/gorilla/layout_profiles/' +
        payload.cloneFromId +
       '/create_default_view', formData)
    } else {
      return this.sendPostRequest('/gorilla/layout_profiles/' +
        payload.cloneFromId +
       '/clone', formData)
    }
  },

  toggleLayoutProfileInDashboard (id, tab) {
    const payload: any = {}
    if (tab?.id) {
      payload.tabId = tab.id
    }
    return this.sendPostRequest('/gorilla/layout_profiles/' + id + '/dashboard', payload)
  },

  sendLayoutProfileGlobalViewOperation (id: number, operation: string, content = '') {
    return this.sendPutWithPayloadRequest('/gorilla/layout_profiles/' + id + '/global_view', [], {
      data: { operation, content },
    })
  },

  updateLayoutProfile (layoutProfile: LP.LayoutProfile) {
    const payload: any = {}
    if (layoutProfile.timelineConfiguration) {
      payload.timeline_configuration = JSON.stringify(layoutProfile.timelineConfiguration)
    }
    if ('publish' in layoutProfile) {
      payload.publish = !!layoutProfile.publish
    }
    if ('owner_id' in layoutProfile) {
      // payload.owner_id = layoutProfile.owner_id
    }
    ['last_used_at', 'name'].forEach(fieldName => {
      if (layoutProfile[fieldName]) {
        payload[fieldName] = layoutProfile[fieldName]
      }
    })
    return this.sendPutWithPayloadRequest('/api/layout_profiles/' + layoutProfile.id, [{
      key: 'q',
      value: '!id'
    }], payload)
  },

  loadLayoutProfileTemplates (objectClass, view) {
    return this.sendRequest('/gorilla/layout_profiles/my_for/' + objectClass + '/' + view, [{
      key: 'public',
      value: true
    }], [])
  },

  sortLayoutProfileChildren (layoutProfileId: number, items: BaseItem[], sortModel: string, sortBy = 'sortOrderField') {
    // Duplicate items for not to update items in vuex store
    let orders = JSON.parse(JSON.stringify(items))
    // Note - for layout profile items don't need to sort by sort_order as
    // items are already sorted in the array
    if (sortBy === 'sortOrderField') { // For layout containers and field sets user sort_order field to sort
      orders = orders.sort((a, b) => a.sort_order < b.sort_order ? -1 : 1)
    }
    orders = orders.map(item => {
      return {
        key: 'order[]',
        value: item.id
      }
    })
    return this.sendPostRequest('/gorilla/layout_profiles/' +
      layoutProfileId + '/sort_' + sortModel, orders)
  },

  sortLayoutProfiles (layoutProfiles: LP.LayoutProfile[]) {
    const orders = layoutProfiles.map(item => {
      return {
        key: 'order[]',
        value: item.id
      }
    })
    return this.sendPostRequest('/gorilla/layout_profiles/sort', orders)
  },

  getLayoutProfileItemCount (id: number) {
    return this.sendRequest('/gorilla/layout_profiles/' + id + '/count', [], [])
  },

  showAllFields (layoutProfileId: number) {
    return this.sendPostRequest('/gorilla/layout_profiles/' +
      layoutProfileId + '/show_all', [])
  },

  hideAllFields (layoutProfileId: number) {
    return this.sendPostRequest('/gorilla/layout_profiles/' +
      layoutProfileId + '/hide_all', [])
  },

  getLayoutProfiles (objectClass: string, view: string): Promise<AxiosResponse> {
    if (window.location.hash.includes('myProfile')) {
      view = 'my_profile'
    }
    return new Promise(resolve => {
      this.sendRequest('/gorilla/layout_profiles/my_for/' + objectClass + '/' + view, [], []).then((response: AxiosResponse) => {
        // Sort Layout Profiles so that locked global views are first
        if (response.data.items) {
          response.data.items = response.data.items.sort((a, b) => {
            // Put users views behind global views
            if (a.global_view && a.locked && !b.global_view) {
              return -1
            }
            return (a.order > b.order) ? 1 : -1
          }).map((item, index) => {
            item.order = index
            return item
          })
        }
        resolve(response)
      })
    })
  },
}
