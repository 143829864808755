
import { LP } from '@/types/LP.types'
import MultiLangTextField from '@/components/Form/MultiLangTextField.vue'

export default {
  name: 'ContainerInfoText',

  components: {
    MultiLangTextField
  },

  data () {
    return {
      containerInfoTextChangeTimer: null,
    }
  },

  props: {
    container: {
      type: Object as () => LP.Container,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    changeHandler () {
      console.log(this.container.infoTextValues)
      if (this.containerInfoTextChangeTimer) {
        clearTimeout(this.containerInfoTextChangeTimer)
        this.containerInfoTextChangeTimer = null
      }
      this.containerInfoTextChangeTimer = setTimeout(() => {
        this.containerInfoTextChangeTimer = null
        this.$emit('updateLayoutContainer', this.container)
      }, 3000)
    }
  }
}
