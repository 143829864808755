// Fontawesome icon name changes from version 4 to 6
/* eslint quote-props: 0 */

const iconNameChanges = {
  'address-book-o': 'address-book',
  'address-card-o': 'address-card',
  'area-chart': 'chart-area',
  'arrow-circle-o-down': 'circle-down',
  'arrow-circle-o-left': 'circle-left',
  'arrow-circle-o-right': 'circle-right',
  'arrow-circle-o-up': 'circle-up',
  'arrows': 'up-down-left-right',
  'arrows-alt': 'maximize',
  'arrows-h': 'left-right',
  'arrows-v': 'up-down',
  'asl-interpreting': 'hands-asl-interpreting',
  'automobile': 'car',
  'bar-chart': 'chart-bar',
  'bar-chart-o': 'chart-bar',
  'bathtub': 'bath',
  'battery': 'battery-full',
  'battery-0': 'battery-empty',
  'battery-1': 'battery-quarter',
  'battery-2': 'battery-half',
  'battery-3': 'battery-three-quarters',
  'battery-4': 'battery-full',
  'bell-o': 'bell',
  'bell-slash-o': 'bell-slash',
  'bitbucket-square': 'bitbucket',
  'bitcoin': 'btc',
  'bookmark-o': 'bookmark',
  'building-o': 'building',
  'cab': 'taxi',
  'calendar': 'calendar-days',
  'calendar-check-o': 'calendar-check',
  'calendar-minus-o': 'calendar-minus',
  'calendar-o': 'calendar',
  'calendar-plus-o': 'calendar-plus',
  'calendar-times-o': 'calendar-xmark',
  'caret-square-o-down': 'square-caret-down',
  'caret-square-o-left': 'square-caret-left',
  'caret-square-o-right': 'square-caret-right',
  'caret-square-o-up': 'square-caret-up',
  'cc': 'closed-captioning',
  'chain': 'link',
  'chain-broken': 'link-slash',
  'check-circle-o': 'circle-check',
  'check-square-o': 'square-check',
  'circle-o': 'circle',
  'circle-o-notch': 'circle-notch',
  'circle-thin': 'circle',
  'clock-o': 'clock',
  'close': 'xmark',
  'cloud-download': 'cloud-arrow-down',
  'cloud-upload': 'cloud-arrow-up',
  'cny': 'yen-sign',
  'code-fork': 'code-branch',
  'comment-o': 'comment',
  'commenting': 'comment-dots',
  'commenting-o': 'comment-dots',
  'comments-o': 'comments',
  'compress': 'down-left-and-up-right-to-center',
  'credit-card-alt': 'credit-card',
  'cutlery': 'utensils',
  'dashboard': 'gauge',
  'deafness': 'ear-deaf',
  'dedent': 'outdent',
  'diamond': 'gem',
  'dollar': 'dollar-sign',
  'dot-circle-o': 'circle-dot',
  'drivers-license': 'id-card',
  'drivers-license-o': 'id-card',
  'eercast': 'sellcast',
  'envelope-o': 'envelope',
  'envelope-open-o': 'envelope-open',
  'eur': 'euro-sign',
  'euro': 'euro-sign',
  'exchange': 'right-left',
  'expand': 'up-right-and-down-left-from-center',
  'external-link': 'up-right-from-square',
  'external-link-square': 'square-up-right',
  'eyedropper': 'eye-dropper',
  'fa': 'font-awesome',
  'facebook': 'facebook-f',
  'facebook-official': 'facebook',
  'feed': 'rss',
  'file-archive-o': 'file-zipper',
  'file-audio-o': 'file-audio',
  'file-code-o': 'file-code',
  'file-excel-o': 'file-excel',
  'file-image-o': 'file-image',
  'file-movie-o': 'file-video',
  'file-o': 'file',
  'file-pdf-o': 'file-pdf',
  'file-photo-o': 'file-image',
  'file-picture-o': 'file-image',
  'file-powerpoint-o': 'file-powerpoint',
  'file-sound-o': 'file-audio',
  'file-text': 'file-lines',
  'file-text-o': 'file-lines',
  'file-video-o': 'file-video',
  'file-word-o': 'file-word',
  'file-zip-o': 'file-zipper',
  'files-o': 'copy',
  'flag-o': 'flag',
  'flash': 'bolt',
  'floppy-o': 'floppy-disk',
  'folder-o': 'folder',
  'folder-open-o': 'folder-open',
  'frown-o': 'face-frown',
  'futbol-o': 'futbol',
  'gbp': 'sterling-sign',
  'ge': 'empire',
  'gittip': 'gratipay',
  'glass': 'martini-glass-empty',
  'google-plus': 'google-plus-g',
  'google-plus-circle': 'google-plus',
  'google-plus-official': 'google-plus',
  'group': 'users',
  'hand-grab-o': 'hand-back-fist',
  'hand-lizard-o': 'hand-lizard',
  'hand-o-down': 'hand-point-down',
  'hand-o-left': 'hand-point-left',
  'hand-o-right': 'hand-point-right',
  'hand-o-up': 'hand-point-up',
  'hand-paper-o': 'hand',
  'hand-peace-o': 'hand-peace',
  'hand-pointer-o': 'hand-pointer',
  'hand-rock-o': 'hand-back-fist',
  'hand-scissors-o': 'hand-scissors',
  'hand-spock-o': 'hand-spock',
  'hand-stop-o': 'hand',
  'handshake-o': 'handshake',
  'hard-of-hearing': 'ear-deaf',
  'hdd-o': 'hard-drive',
  'header': 'heading',
  'heart-o': 'heart',
  'hospital-o': 'hospital',
  'hotel': 'bed',
  'hourglass-1': 'hourglass-start',
  'hourglass-2': 'hourglass',
  'hourglass-3': 'hourglass-end',
  'hourglass-o': 'hourglass',
  'id-card-o': 'id-card',
  'ils': 'shekel-sign',
  'inr': 'rupee-sign',
  'institution': 'building-columns',
  'intersex': 'transgender',
  'jpy': 'yen-sign',
  'keyboard-o': 'keyboard',
  'krw': 'won-sign',
  'legal': 'gavel',
  'lemon-o': 'lemon',
  'level-down': 'turn-down',
  'level-up': 'turn-up',
  'life-bouy': 'life-ring',
  'life-buoy': 'life-ring',
  'life-saver': 'life-ring',
  'lightbulb-o': 'lightbulb',
  'line-chart': 'chart-line',
  'linkedin': 'linkedin-in',
  'linkedin-square': 'linkedin',
  'list-alt': 'rectangle-list',
  'long-arrow-down': 'down-long',
  'long-arrow-left': 'left-long',
  'long-arrow-right': 'right-long',
  'long-arrow-up': 'up-long',
  'mail-forward': 'share',
  'mail-reply': 'reply',
  'mail-reply-all': 'reply-all',
  'map-marker': 'location-dot',
  'map-o': 'map',
  'meanpath': 'font-awesome',
  'meh-o': 'face-meh',
  'minus-square-o': 'square-minus',
  'mobile': 'mobile-screen-button',
  'mobile-phone': 'mobile-screen-button',
  'money': 'money-bill-1',
  'moon-o': 'moon',
  'mortar-board': 'graduation-cap',
  'navicon': 'bars',
  'newspaper-o': 'newspaper',
  'paper-plane-o': 'paper-plane',
  'paste': 'clipboard',
  'pause-circle-o': 'circle-pause',
  'pencil-square': 'square-pen',
  'pencil-square-o': 'pen-to-square',
  'photo': 'image',
  'picture-o': 'image',
  'pie-chart': 'chart-pie',
  'play-circle-o': 'circle-play',
  'plus-square-o': 'square-plus',
  'question-circle-o': 'circle-question',
  'ra': 'rebel',
  'refresh': 'arrows-rotate',
  'remove': 'xmark',
  'reorder': 'bars',
  'repeat': 'arrow-rotate-right',
  'resistance': 'rebel',
  'rmb': 'yen-sign',
  'rotate-left': 'arrow-rotate-left',
  'rotate-right': 'arrow-rotate-right',
  'rouble': 'ruble-sign',
  'rub': 'ruble-sign',
  'ruble': 'ruble-sign',
  'rupee': 'rupee-sign',
  's15': 'bath',
  'send': 'paper-plane',
  'send-o': 'paper-plane',
  'share-square-o': 'share-from-square',
  'shekel': 'shekel-sign',
  'sheqel': 'shekel-sign',
  'shield': 'shield-blank',
  'sign-in': 'right-to-bracket',
  'sign-out': 'right-from-bracket',
  'signing': 'hands',
  'smile-o': 'face-smile',
  'snapchat-ghost': 'snapchat',
  'snowflake-o': 'snowflake',
  'soccer-ball-o': 'futbol',
  'sort-alpha-asc': 'arrow-down-a-z',
  'sort-alpha-desc': 'arrow-down-z-a',
  'sort-amount-asc': 'arrow-down-wide-short',
  'sort-amount-desc': 'arrow-down-short-wide',
  'sort-asc': 'sort-up',
  'sort-desc': 'sort-down',
  'sort-numeric-asc': 'arrow-down-1-9',
  'sort-numeric-desc': 'arrow-down-9-1',
  'square-o': 'square',
  'star-half-empty': 'star-half',
  'star-half-full': 'star-half',
  'star-half-o': 'star-half',
  'star-o': 'star',
  'sticky-note-o': 'note-sticky',
  'stop-circle-o': 'circle-stop',
  'sun-o': 'sun',
  'support': 'life-ring',
  'tablet': 'tablet-screen-button',
  'tachometer': 'gauge',
  'television': 'tv',
  'thermometer': 'temperature-full',
  'thermometer-0': 'temperature-empty',
  'thermometer-1': 'temperature-quarter',
  'thermometer-2': 'temperature-half',
  'thermometer-3': 'temperature-three-quarters',
  'thermometer-4': 'temperature-full',
  'thumb-tack': 'thumbtack',
  'thumbs-o-down': 'thumbs-down',
  'thumbs-o-up': 'thumbs-up',
  'ticket': 'ticket-simple',
  'times-circle-o': 'circle-xmark',
  'times-rectangle': 'rectangle-xmark',
  'times-rectangle-o': 'rectangle-xmark',
  'toggle-down': 'square-caret-down',
  'toggle-left': 'square-caret-left',
  'toggle-right': 'square-caret-right',
  'toggle-up': 'square-caret-up',
  'trash': 'trash-can',
  'trash-o': 'trash-can',
  'try': 'lira-sign',
  'turkish-lira': 'lira-sign',
  'unsorted': 'sort',
  'usd': 'dollar-sign',
  'user-circle-o': 'circle-user',
  'user-o': 'user',
  'vcard': 'address-card',
  'vcard-o': 'address-card',
  'video-camera': 'video',
  'vimeo': 'vimeo-v',
  'volume-control-phone': 'phone-volume',
  'warning': 'triangle-exclamation',
  'wechat': 'weixin',
  'wheelchair-alt': 'accessible-icon',
  'window-close-o': 'rectangle-xmark',
  'won': 'won-sign',
  'y-combinator-square': 'hacker-news',
  'yc': 'y-combinator',
  'yc-square': 'hacker-news',
  'yen': 'yen-sign',
  'youtube-play': 'youtube',
}
export default iconNameChanges
