import pivotTableHelpers from './pivotTableHelpers'
import price_derivers from '@/components/PivotTableView/utils/price_derivers'
import date_derivers from '@/components/PivotTableView/utils/date_derivers'
import date_formatters from '@/components/PivotTableView/utils/date_formatters'
import util from '../../utilities/sharedUtilities'
import moment from 'moment'

export default {
  // Prepares data got from resource api to be ready for pivottable component
  pivotData (objectClass, items, columns, cache) {
    return new Promise(resolve => {
      const classSingular = util.objectClassSingularName(objectClass)
      const data = []
      items.forEach(item => {
        const itemData = {}
        columns.forEach(col => {
          if (!col.visible) { return }
          let value = pivotTableHelpers._attributeToString(item[col.name], cache && cache[col.name])
          if (col.type === 'datetime') {
            value = moment(value).parseZone(value).format(date_formatters.dateTimeFormat())
          } else if (col.type === 'date') {
            value = moment(value).format(date_formatters.dateFormat())
          }
          const translatedKey = pivotTableHelpers._translateKey(objectClass, col.name)
          itemData[translatedKey] = value
        })
        data.push(itemData)
      })
      resolve(data)
    })
  },

  _translationTables (objectClass, columns) {
    const localizations = {}
    const generalizations = {}
    columns.forEach(col => {
      if (!col.visible) { return }

      const key = col.name
      let translations = []
      let translatedKey = ''
      switch (col.type) {
        case 'price':
          translations = price_derivers.createTranslations(key, pivotTableHelpers._translateKey(objectClass, key))
          Object.keys(translations).forEach(key => {
            translatedKey = translations[key]
            localizations[key] = translatedKey
            generalizations[translatedKey] = key
          })
          break
        case 'date':
          translations = date_derivers.createDateTranslations(key, pivotTableHelpers._translateKey(objectClass, key))
          Object.keys(translations).forEach(key => {
            translatedKey = translations[key]
            localizations[key] = translatedKey
            generalizations[translatedKey] = key
          })
          break
        case 'datetime':
          translations = date_derivers.createDateTimeTranslations(key, pivotTableHelpers._translateKey(objectClass, key))
          Object.keys(translations).forEach(key => {
            translatedKey = translations[key]
            localizations[key] = translatedKey
            generalizations[translatedKey] = key
          })
          break
        default:
          translatedKey = pivotTableHelpers._translateKey(objectClass, col.name)
          localizations[key] = translatedKey
          generalizations[translatedKey] = key
      }
    })
    return { localizations: localizations, generalizations: generalizations }
  },

  _derivedAttributes (objectClass, columns) {
    let derived = {}
    let val
    columns.forEach(col => {
      if (!col.visible) { return }
      switch (col.type) {
        case 'price':
          val = pivotTableHelpers._translateKey(objectClass, col.name)
          derived = {
            ...derived,
            ...price_derivers.createPriceDerivedAttributes(val, col.persision || 2),
          }
          break
        case 'date':
          val = pivotTableHelpers._translateKey(objectClass, col.name)
          derived = {
            ...derived,
            ...date_derivers.createDateDerivedAttributes(val),
          }
          break
        case 'datetime':
          val = pivotTableHelpers._translateKey(objectClass, col.name)
          derived = {
            ...derived,
            ...date_derivers.createDateTimeDerivedAttributes(val),
          }
          break
        case 'default':
          return null
      }
    })
    return derived
  },

  _hiddenFromDragDropAttributes (objectClass, columns) {
    return columns.filter(col => col.visible && col.type === 'price')
      .map(col => {
        const val = pivotTableHelpers._translateKey(objectClass, col.name)
        return price_derivers.createHiddenFromDragDropAttributes(val)
      })
  },

  _hiddenFromAggregatorsAttributes (objectClass, columns) {
    return columns.filter(col => col.visible && col.type === 'price')
      .map(col => {
        const val = pivotTableHelpers._translateKey(objectClass, col.name)
        return price_derivers.createHiddenFromAggregatorsAttributes(val)
      })
  },
}
