
import Sortable from 'sortablejs'
import sharedUtilities from '@/utilities/sharedUtilities'
import Confirm from '@/methods/confirm'
import api from '@/store/api'
import { Field } from '@/types/FieldTypes'
import { AxiosResponse } from 'axios'

export default {
  name: 'Images',

  props: {
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    imagesReady: {
      type: Boolean,
      default: true,
    },
    fieldLabel: {
      type: String,
      default: '',
    },
    field: {
      type: Object,
      default: () => {}
    },
    forItem: {
      type: Object,
      default: () => {},
    },
    hasManyParentField: {
      type: Object,
      default: () => {},
    },
    hasManyParent: {
      type: Object,
      default: () => {},
    },
    isHasManyFieldAndNotFirstRow: {
      type: Boolean,
      default: false,
    },
    availableWidthPxForAField: {
      type: Number,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      render: true,
      uploadingImages: [],
      deletingImageIds: [],
      sortable: null,
      imageDialog: false,
      carouselIndex: 0,
      showMenu: false,
      imageLinkForDownload: null,
      isDragOver: false,
      pos: {
        x: 0,
        y: 0,
      },
    }
  },

  computed: {
    carouselHeight () {
      return this.$store.state.innerHeight - 24
    },

    imageWidth () {
      return this.isListView ? '70px' : '100%'
    },

    loading () {
      return this.uploadingImages.length > 0 || this.deletingImageIds.length > 0
    },

    cols () {
      const spaceForOneImage = 120
      if (this.isListView) { return }
      const imagesPerRow = Math.floor(this.availableWidthPxForAField / spaceForOneImage)
      return imagesPerRow > 10
        ? 1
        : imagesPerRow > 4
          ? 2
          : imagesPerRow === 4
            ? 3
            : imagesPerRow === 3
              ? 4
              : imagesPerRow === 2
                ? 6
                : 12
    },

    images: {
      get (): Field.Image[] {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    ready: {
      get () {
        return this.imagesReady
      },
      set (value) {
        this.$emit('ready', value)
      }
    },

    parentId (): number {
      return this.forItem && (this.forItem.id || this.forItem.token)
    },

    isListView () {
      return !this.parentId || this.isHasManyFieldAndNotFirstRow
    },
  },

  watch: {
    deletingImageIds () {
      this.ready = this.deletingImageIds.length === 0
    },
    parentId () {
      this.initialize()
    },
    imageDialog (display) {
      if (display) {
        document.addEventListener('keydown', this.keyDownListener)
      } else {
        this.removeKeyDownListener()
      }
    }
  },

  created () {
    this.initialize()
  },

  destroyed () {
    // Needed to keep things in check when using webpack dev server locally.
    this.removeKeyDownListener()
  },

  methods: {
    handleDragEnter (e: MouseEvent) {
      e.preventDefault()
      this.isDragOver = true
    },
    handleDragLeave (e: MouseEvent) {
      if (!this.$refs.imagesContainer.contains(e.relatedTarget)) {
        e.preventDefault()
        this.isDragOver = false
      }
    },

    initialize () {
      this.sortItemImages()
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.createImageSortables()
        })
      })
    },

    showImage (imageIndex) {
      this.carouselIndex = imageIndex
      this.imageDialog = true
    },

    // Sort images with order or with id if not sorted before
    // Order is new attribute and also not present for existing images
    // Function needed as API is only keeping a field 'order' but not doing the sorting of result
    sortItemImages () {
      this.images = this.images.sort((a, b) => {
        // Sort with order?
        if (a.order !== null && b.order !== null) {
          return a.order > b.order ? 1 : -1
        }
        // Sort with id
        return a.id > b.id ? 1 : -1
      })
    },

    createImageSortables () {
      this.$nextTick(() => {
        this.sortable = null
        const el = document.getElementById('images-gallery')
        if (!el) { return }
        this.sortable = Sortable.create(el, {
          onEnd: this.updateImagesOrderLocal,
          chosenClass: 'container-chosen',
          dragClass: 'container-drag',
          // handle: '.fa-bars',
        })
      })
    },

    updateImagesOrderLocal (move) {
      const temp = this.images.splice(move.oldIndex, 1)[0]
      this.images.splice(move.newIndex, 0, temp)
      this.render = false
      this.$nextTick(() => {
        this.render = true
        this.createImageSortables()
        api.saveItemImagesOrder(this.images)
      })
    },

    selectFile () {
      if (this.uploadingImages.length > 0) { return }
      const el = document.getElementById('upload_image')
      if (!el) { return }
      el.click()
    },

    deleteImage (id) {
      Confirm.request(this.$i18n.t('aava.edit.confirm_destroy_image'), () => {
        this.$set(this.deletingImageIds, this.deletingImageIds.length, id)
        this.$store.dispatch('deleteItem', {
          id,
          resource: 'attachments',
        }).then(success => {
          // Delete from deletingImageIds always, even if error
          const idIndex = this.deletingImageIds.indexOf(id)
          this.$delete(this.deletingImageIds, idIndex)
          // Delete from images array only if positive response
          if (success) {
            const imageIndex = this.images.map(image => image.id).indexOf(id)
            this.$delete(this.images, imageIndex)
          }
        })
      })
    },

    getDataURLs (e) {
      const files = e.target?.files || e.dataTransfer?.files // Select or drag n drop
      return Promise.all([...files].map(fileToDataURL => fileToDataURL))
    },

    // Upload all images and get tokens/ids
    // this must be done synchronous in order for api to return valid tokens
    // with async way tokens are returned, but can't be used on item save or when selecting country
    readImages (e) {
      this.ready = false
      this.isDragOver = false
      this.getDataURLs(e).then(selectedImages => {
        this.setTempUploadingImages(selectedImages)
        if (!selectedImages || !selectedImages[0]) { return }
        this.setTempUploadingImages(selectedImages)
        selectedImages
          .reduce((previousPromise, image) => {
            return previousPromise.then(() => {
              return this.readImage(image, e)
            })
          }, Promise.resolve()).then(() => {
            this.ready = true
          })
      })
    },

    setTempUploadingImages (dataURLs) {
      this.uploadingImages = dataURLs.map(dataURL => {
        return URL.createObjectURL(dataURL)
      })
    },

    readImage (image, e) {
      return new Promise(resolve => {
        if (!image) {
          resolve(false)
          return
        }
        // this.uploadImage = URL.createObjectURL(image)
        const reader = new FileReader()
        reader.onload = e => {
          // this.previewImage = e.target.result
          this.$store.dispatch('addNewFile', {
            file: image,
            files: this.images,
            forObjectClass: sharedUtilities.objectClassUnderscoredName(this.forItem['@class']),
            forFieldName: this.field.name,
            forObjectId: this.forItem.token || this.forItem.id,
            attachmentType: 'image',
            parentClass: this.hasManyParent?.['@class'],
            parentTokenOrId: this.hasManyParent?.token || this.hasManyParent?.id,
            parentFieldName: this.hasManyParentField?.name,
          }).then((response: AxiosResponse) => {
            this.uploadingImages.shift()
            resolve(response)
          })
        }
        reader.readAsText(image)
      })
    },

    showImageDownloadMenu (e, imageLink) {
      this.imageLinkForDownload = imageLink
      e.preventDefault()
      this.showMenu = false
      this.pos = {
        x: e.clientX,
        y: e.clientY,
      }
      this.$nextTick(() => {
        this.showMenu = true
      })
    },

    keyDownListener (e) {
      const current = this.carouselIndex
      const max = this.value.length - 1

      if (['Esc', 'Escape'].includes(e.key)) { // v-dialog does not always catch it1
        this.imageDialog = false
      } else if (e.key === 'ArrowRight') {
        this.carouselIndex = current < max ? current + 1 : 0
      } else if (e.key === 'ArrowLeft') {
        this.carouselIndex = current > 0 ? current - 1 : max
      }
    },

    removeKeyDownListener () {
      document.removeEventListener('keydown', this.keyDownListener)
    }
  }
}
