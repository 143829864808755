<template>
  <div
    v-if="field.name"
    :id="refId"
    :ref="refId"
    v-tooltip.top="{ content: cellError, delay: { show: 100 } }"
    :class="cellClass"
    :style="cellStyle"
    @mousedown="listItemMouseDownHandler(itemIndex, $event)"
    @mouseup="listItemMouseUpHandler(listItem, itemIndex, $event)"
    @mouseenter="mouseEnterHandler"
    @click="cellClickHandler"
    @contextmenu="handleContextMenu"
  >
    <div
      v-if="!edit && !!computedValue && ['string', 'search_string', 'reference', 'process', 'state', 'address', 'polymorphic_autocomplete', 'static_list', 'dynamic_list'].includes(field.type)"
      class="search-similar"
    >
      <v-icon
        x-small
        color="grey"
        @click.stop=""
        @mousedown.stop="searchSimilar"
        @mouseup.stop=""
      >
        fa-search
      </v-icon>
    </div>
    <div
      v-if="savingListEditField"
      class="cell-saving"
    >
      <v-progress-circular
        indeterminate
        size="14"
        width="1"
        color="green"
      />
    </div>
    <FormField
      v-if="renderInput && (edit || isEventButton) && listItem"
      :id="listItem.id"
      :ref="field.name"
      :edit="true"
      :edit-in-list="true"
      :field="field"
      :field-index="field.fieldIndex"
      :resource="objectClass"
      :item="listItem"
      :locale____WHY="null"
      :autofocus="autofocus"
      :show-item-picker-for="showItemPickerFor"
      :read-only="savingListEditField"
      view="list"
      @saveValueInList="saveValueInListHandler"
      @restoreValueInList="restoreCurrent"
      @moveRowDownOrUp="moveRowDownOrUp"
      @mousedown="activateEditField(listItem, itemIndex, field, false)"
    />
    <div
      v-else-if="listItem"
      ref="div-outer"
      :class="'cell-div '"
      :style="style"
    >
      <ContentProcessEvents
        v-if="field.type === 'process_events'"
        :item="listItem"
        :item-index="itemIndex"
        @redirectToAfterProcessEventAction="redirectToAfterProcessEventAction"
      />
      <!-- EventButton
        v-else-if="isEventButton"
        :item="listItem"
        :field="field"
        :is-saving="isSaving"
        view="list"
        @click="addEventActionToQueue"
        @mousedown="activateEditField(listItem, itemIndex, field, false)"
      / -->
      <ContentImages
        v-else-if="field.type === 'image' || field.widget === 'image'"
        :cell-value="{ value: cellValue }"
        :field="field"
      />
      <ContentFiles
        v-else-if="['file', 'pdf'].includes(field.type) || field.widget === 'file'"
        :cell-value="{ value: cellValue }"
        :field="field"
        @openAttachmentFile="openAttachmentFile"
      />
      <ContentReference
        v-else-if="['reference', 'process', 'state', 'address', 'polymorphic_autocomplete'].includes(field.type)"
        :list-item="listItem"
        :item-index="itemIndex"
        :field="field"
      />
      <ContentHasManyReference
        v-else-if="field.type === 'has_many_reference'"
        :cell-value="{ value: cellValue }"
        :list-item="listItem"
        :field="field"
        :has-many-fields-with-data="hasManyFieldsWithData"
      />
      <content-boolean
        v-else-if="field.type === 'boolean'"
        :cell-value="{ value: cellValue }"
        :list-item="listItem"
        :field="field"
      />
      <div
        v-else-if="['raw', 'link', 'raw_full'].includes(field.type)"
        v-html="listItem[field.name]"
      />
      <a
        v-else
        :href="itemUrl"
        class="no-text-select no-link-decoration"
        draggable="false"
        ondragstart="event.preventDefault()"
        @click="checkAndStopSimpleMouseClick($event)"
        @mouseup="checkAndStopMetaKeyPress($event)"
      >
        <content-richtext
          v-if="field.type === 'richtext'"
          :cell-value="{ value: cellValue }"
        />

        <!-- eslint-disable-next-line -->
        <div
          v-else
          v-tooltip.top="{ content: tooltip, delay: { show: 1000 } }"
          :style="['quantity', 'price', 'decimal', 'numeric']
            .includes(field.type) ? 'float:right;' : ''"
          :class="'simple-text '"
        >
          {{ computedValue }}
        </div><!-- eslint-disable-line -->
      </a>
    </div>
  </div>
</template>

<script>
import methods from './../methods'
import util from '../../utilities/sharedUtilities'
import ContentFiles from './DataTypes/ContentFiles'
import ContentImages from './DataTypes/ContentImages'
import ContentBoolean from './DataTypes/ContentBoolean'
import ContentRichtext from './DataTypes/ContentRichtext'
import ContentReference from './DataTypes/ContentReference'
import ContentProcessEvents from './DataTypes/ContentProcessEvents'
import ContentHasManyReference from './DataTypes/ContentHasManyReference'
import listItemMethods from '../../methods/listItem/listItemMethods'
import listItemCellMethods from '../../methods/listItem/listItemCellMethods'
import listCellEditMethods from '@/methods/listItem/listCellEditMethods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import FormField from '@/components/Item/FormField'
import { isDarkColor } from '@/components/helperMethods'
import { getObjectStateBackgroundColor } from '@/methods/item/itemMethods'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ContentCell',

  components: {
    FormField,
    ContentFiles,
    ContentImages,
    ContentBoolean,
    ContentRichtext,
    ContentReference,
    ContentProcessEvents,
    ContentHasManyReference,
  },

  props: {
    field: {
      default () { return {} },
      type: Object,
    },
    listItem: {
      default () { return null },
      type: Object,
    },
    cellIndex: {
      default: null,
      type: Number,
    },
    itemIndex: {
      default: null,
      type: Number,
    },
    backgroundAltered: {
      default: false,
      type: Boolean,
    },
  },

  data: () => {
    return {
      showingFullText: true,
      justSaved: false,
      renderInput: true,
    }
  },

  watch: {
    autofocus (val) {
      if (!val) {
        return
      }
      this.initListRowBeforeEdit()
      if ([
        'boolean',
        'process',
        'event',
        'action',
        // 'reference',
      ].includes(this.field.type)) {
        return
      }
      this.renderInput = false
      this.$nextTick(() => {
        this.renderInput = true
      })
    },

    isSaving (val) {
      if (val) {
        return
      }
      this.justSaved = true
      setTimeout(() => {
        this.justSaved = false
      }, 2500)
    }
  },

  computed: {
    // TODO - specify here which props are used!
    ...mapFields(Object.keys(state)),

    isEventButton () {
      return this.field.type === 'event' || this.field.type === 'action'
    },

    hasEventButtons () {
      return this.isEventButton && this.listItem[this.field.name]?.length
    },

    isInQueue () {
      return this.$store.getters.listEditCellQueueKeys.includes(this.listItem.id + '_' + this.field.name)
    },

    cellState () {
      if (this.isSaving) {
        return 'saving'
      } else if (this.isInQueue) {
        return 'changed'
      } else if (this.justSaved) {
        return 'saved'
      }
      return ''
    },

    style () {
      const style = {}
      if (this.field.forcedWidth || this.field.userFixedWidth) {
        style.maxHeight = 'var(--row-height)'
      }
      if (this.isEventButton) {
        style.maxHeight = 'calc(var(--row-height) + 10px)'
        style.height = 'calc(var(--row-height) + 10px)'
      }
      if (this.field.type === 'image') {
        style.maxHeight = 'calc(var(--row-height) + 13px)'
        style.height = 'calc(var(--row-height) + 13px)'
      }
      if (this.field.type === 'has_many_reference' && !this.hasManyFieldsWithData
        .includes(this.field.name)) {
        style.whiteSpace = 'nowrap'
      }
      return style
    },

    currentIndex () {
      return this.visibleLayoutProfileItems.map(item => item.name).indexOf(this.field.name)
    },

    isEditable () {
      if (this.hasEventButtons) {
        return true // Still use EventButton component
        // return this.listItem[this.field.name]?.length
      }
      return this.isSupportedListEditField(this.field) &&
        this.listItem['@editable'] &&
        this.field.editable_in_list &&
        this.listItem['@editable_members']?.includes(this.field.name)
    },

    inEditMode () {
      return this.$store.getters.inListEditMode
    },

    edit () {
      return this.inEditMode && this.isEditable
    },

    isEditing () {
      return this.listEdit.current.field?.name === this.field?.name && // Same field
        this.listEdit.current.item.id === this.listItem.id // Same item
    },

    isSaving () {
      return this.listEdit.savingJob?.field?.name === this.field?.name && // Same field
        this.listEdit.savingJob?.id === this.listItem.id // Same item
    },

    autofocus () {
      return this.listEdit.current?.field?.name === this.field?.name && // Same field
        this.listEdit.current?.item?.id === this.listItem?.id // Same item
    },

    savingListEditField () {
      // return true
      return this.edit && this.listEdit.current.saving
    },

    tooltip () {
      if (this.field.type !== 'decimal') { return }
      return this.formatFloatValueForLocale(this.listItem[this.field.name], null, true)
    },

    itemUrl () {
      return '#' + util.objectClassUnderscoredName(this.objectClass) + '/' + this.listItem.id
    },

    refId () {
      return 'td_' + this.itemIndex + '_' + this.field.name
    },

    hasManyFieldsWithData () {
      return this.$store.getters.hasManyFieldsWithData
    },

    isHoverRow () {
      return this.hoverRowIndex && this.hoverRowIndex === (this.itemIndex + this.offsetItems)
    },

    cellValue () {
      const fieldName = this.field.name
      // Summer 2023 changed in the list not to use locale specific field like (_fi or _en)
      // Had some problems getting fatal from API in certain models
      // + (this.field.multi_language ? '_' + this.$store.state.locale : '')
      return this.listItem ? this.listItem[fieldName] : ''
    },

    computedValue () {
      return this.getItemFieldDisplayText()
    },

    cellError () {
      return this.$store.state.listEdit.errors[this.listItem.id + '_' + this.field.name]
    },

    cellClass () {
      const classes = [
        'content-cell',
        'td0 attr_' + this.field.name,
        'type_' + this.field.type,
        ((this.edit || this.isEventButton) && 'cell-edit'),
        (this.cellError && 'cell-error'),
        (this.isEditable ? 'cell-editable' : 'cell-readonly'),
        (this.isEditing && 'cell-editing'),
        (this.cellState && ('cell-' + this.cellState))
      ]
      if (['quantity', 'price', 'decimal', 'numeric'].includes(this.field.type)) {
        classes.push('right-text')
      }
      if (this.overflowingCells.includes(this.refId)) {
        classes.push('overflowing-cell-text')
      } else if (this.overflowingHasManyCells.includes(this.refId)) {
        classes.push('overflowing-has-many-list')
      }
      return classes.join(' ')
    },

    cellDivStyle () {
      const style = {}
      style.position = 'relative'
      return style
    },

    cellStyle () {
      let border = false
      let cellCustomStyle = ''
      const style = {
        position: 'absolute',
        left: '0',
        transform: 'translateX(' + (!this.measuringColumnWidths
          ? this.field.leftOffset
          : this.cellIndex * 100) + 'px'
      }
      // Custom style if in @member_styles
      if (this.listItem && this.listItem['@member_styles'] && this.listItem['@member_styles'][this.field.name]) {
        cellCustomStyle = this.listItem['@member_styles'][this.field.name]
        border = true
      }
      // Width
      const fixedWidth = this.field.userFixedWidth || this.field.forcedWidth
      if (fixedWidth && !this.measuringColumnWidths) {
        style.width = (fixedWidth + 1) + 'px'
        style.minWidth = (fixedWidth + 1) + 'px'
        style.maxWidth = (fixedWidth + 1) + 'px'
      } else {
        style['max-width'] = this.config.cellMaxWidth + 'px'
      }

      // Set as being dragged
      if (this.draggingFieldName === this.field.name && !cellCustomStyle) {
        style.background = '#fff13d'
        border = true
      } else if (this.resizingFieldName === this.field.name) {
        style.borderRight = '2px solid grey'
      }
      // Main object state
      if (this.mainObjectStateStyle.background) {
        border = true
        style.background = this.mainObjectStateStyle.background
      }
      if (this.mainObjectStateStyle.color) {
        style.color = this.mainObjectStateStyle.color
      }
      // Set border if background set
      if (border) {
        // Disabled because now have horizontal line borders
        // style.border = '1px solid white'
      }
      // We have to return CSS string because we don't know what to expect
      // from custom @member_styles
      return Object.keys(style)
        .map(ruleName => ruleName + ':' + style[ruleName] + ';').join('') + cellCustomStyle
    },

    mainObjectStateStyle () {
      const style = {}
      if (this.listItem &&
        ['main_object_state', 'main_object_process'].includes(this.field.name) &&
        this.listItem.main_object_state
      ) {
        style.background = getObjectStateBackgroundColor(this.listItem.main_object_state)
        if (this.listItem.main_object_state.text_color) {
          style.color = this.listItem.main_object_state.text_color
        }
        if (!style.color && isDarkColor(style.background)) {
          style.color = 'white'
        }
      }
      return style
    },

    visibleLayoutProfileItems () {
      return this.layoutProfileItems.filter(field => field.visible)
    },
  },

  methods: {
    ...methods,
    ...listItemMethods,
    ...listItemCellMethods,
    ...listCellEditMethods,

    mouseEnterHandler () {
      this.hoverCell = this.refId
      this.showContentFullTextMenu = false
      this.listItemMouseEnterHandler(this.itemIndex)
    },
  },
}
</script>

<style lang="scss">
.has-many-preloaded, .has-many-count-round {
  background: #afafaf;
  min-width: 17px;
  height: 17px;
  line-height: 17px;
  color: white !important;
  white-space: normal;
  font-size: 10px;
  text-align: center !important;
  cursor: pointer;
  float: left;
  padding: 0 2px;
  border-radius: 15px;
  margin-right: 6px;
}
.has-many-count-round {
  padding: 0 5px;
}
.has-many-preloaded {
  background-color: #81C784;
  display: none; /* By default has-many-count number is hidden,
  only showing if list does not fit to the cell */
}
.right-text div, .right-text div div {
  text-align: right;
}
.overflowing-cell-text:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 100%;
  height: 100%;
  /* background: radial-gradient(farthest-side at 100% 100%, #90CAF9, transparent) var(--overflow-bullet-position) var(--overflow-bullet-position); */
  background: linear-gradient(to bottom right, transparent 50%, #666 55%) var(--overflow-bullet-position) var(--overflow-bullet-position);;
  background-size: 7px 7px;
  background-repeat: no-repeat;
}
.overflow-show {
  overflow: visible;
}
.visible-full-text {
  position: relative;
  background: white;
  z-index: 1;
  padding: 0 20px 20px 0;
}
.image-icon {
  width: 28px;
  border: 2px solid white;
  margin: 2px 2px 0 2px;
  outline: 1px solid #BDBDBD;
}
.no_objects {
  color: #aaa;
  font-weight: normal;
}
.simple-text {
  display: inline;
}
.no-link-decoration {
  text-decoration: none !important;
}
.attr_main_object_process_events {
  .v-btn {
    height: 17px !important;
    i {
      padding-top: 2px;
    }
  }
}
.search-similar, .cell-edit-btn { // , .cell-saving
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
  opacity: 0;
  /* transition-delay: 150ms;
  transition-property: opacity; */
}
.content-cell:hover {
  .search-similar, .cell-edit-btn {
    opacity: 1;
  }
}
</style>
