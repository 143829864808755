function getRandomKey () {
  const timestamp = Date.now()
  const random = Math.random().toString(36).substring(2, 7)
  return timestamp + '-' + random
}

export default {
  startJob () {
    const key = this.createNewJob()
    // console.log('+++ NEW job', key, this.$store.state.queueJobKeys, key)
    // If no other jobs, start immediately
    if (!this.$store.state.currentJobKey && this.$store.state.queueJobKeys?.[0] === key) {
      this.$store.state.currentJobKey = this.$store.state.queueJobKeys.shift()
      // console.log('START immediately', this.$store.state.currentJobKey)
    }
    return new Promise(resolve => {
      const checkForTurn = () => {
        if (key === this.$store.state.currentJobKey) {
          setTimeout(() => {
            if (key === this.$store.state.currentJobKey) {
              console.log('Job taking longer than expected?', key)
            }
          }, 5000)
          return resolve(key)
        } else {
          setTimeout(checkForTurn, 100)
        }
      }
      checkForTurn()
    })
  },

  whenQueueIsEmpty () {
    return new Promise(resolve => {
      const checkForJobs = () => {
        if (this.openJobsCount() === 0 && !this.$store.state.currentJobKey) {
          return resolve(true)
        } else {
          setTimeout(checkForJobs, 100)
        }
      }
      checkForJobs()
    })
  },

  openJobsCount () {
    console.log(this.$store.state.queueJobKeys.length)
    return this.$store.state.queueJobKeys.length
  },

  createNewJob () {
    const key = getRandomKey()
    this.$store.state.queueJobKeys.push(key)
    return key
  },

  endJob (key) {
    // console.log('--- end job', key, this.$store.state.queueJobKeys)
    if (this.$store.state.currentJobKey !== key) {
      console.error('Job key missing? Stuck?', key)
      return
    }
    this.$store.state.currentJobKey = this.$store.state.queueJobKeys.length
      ? this.$store.state.queueJobKeys.shift()
      : null
    // console.log('Started next: ', this.$store.state.currentJobKey, this.$store.state.queueJobKeys)
  },
}
