<template>
  <div>
    <v-btn
      slot="activator"
      text
      class="text-none"
      @click.stop="openEditViewForSelected()"
    >
      <i
        class="fa fa-edit icon-right-space"
      />
      {{ $vuetify.breakpoint.lgAndUp && splitMode !== 'vertical'
        ? $i18n.t('aava.index.edit_selected')
        : '' }}
    </v-btn>
    <v-btn
      slot="activator"
      text
      class="text-none"
      @click.stop="openShowViewForSelected()"
    >
      <i
        class="fa fa-newspaper icon-right-space"
      />
      {{ $vuetify.breakpoint.lgAndUp && splitMode !== 'vertical'
        ? $i18n.t('aava.index.show_selected')
        : '' }}
    </v-btn>
  </div>
</template>

<script>
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'HeaderActionsSingleSelectActions',

  computed: {
    ...mapFields(Object.keys(state)),

    singleSelectId () {
      if (this.selectedItems.length !== 1) { return false }
      return this.listItems && this.listItems[this.selectedItems[0]]
        ? this.listItems[this.selectedItems[0]].id
        : false
    }
  },

  methods: {
    ...methods,

    openShowViewForSelected () {
      if (!this.singleSelectId) { return false }
      this.listItemOpenById(this.singleSelectId, {})
    },

    openEditViewForSelected () {
      if (!this.singleSelectId) { return false }
      this.listItemOpenForEdit(this.singleSelectId, {})
    }
  }
}
</script>
