
import Color from 'color'
import Sortable from 'sortablejs'
import shortcutData from '@/store/dashboardItems/helpers/shortcutData'
import { DashboardTypes } from '@/types/DashboardTypes'

export default {
  name: 'DashboardShortcuts',

  props: {
    fullWidth: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      sortable: null,
      render: true,
      expanded: false,
      showExpandBtn: false,
    }
  },

  computed: {
    resizeTrigger () {
      return this.$store.state.resizeTrigger
    },

    shortcuts: {
      get (): DashboardTypes.Shortcut[] {
        return this.$store.state.shortcuts
      },
      set (value) {
        // this.$store.commit('updateShortcutsOrder', value)
      },
    },
  },

  created () {
    this.$nextTick(() => {
      this.createShortcutsSortables()
      this.showExpandBtnIfNeeded()
    })
  },

  watch: {
    resizeTrigger (val) {
      this.showExpandBtnIfNeeded()
    },
  },

  methods: {
    createShortcutsSortables () {
      this.$nextTick(() => {
        this.sortable = null
        const el = document.getElementById('shortcut-items')
        if (!el) { return }
        this.sortable = Sortable.create(el, {
          onEnd: this.shortcutDragEnd,
          delay: 150,
        })
      })
    },

    showExpandBtnIfNeeded () {
      const el = document.getElementById('shortcut-items')
      if (!el) { return }
      this.showExpandBtn = el.scrollHeight > el.clientHeight + 10
    },

    openExternalLink (shortcut: DashboardTypes.Shortcut) {
      if (shortcut.details?.link_type !== 'external') { return }
      window.open(shortcut.details?.external_link.replace('#', '/'))
    },

    shortcutDragEnd (move) {
      const temp = this.shortcuts.splice(move.oldIndex, 1)[0]
      this.shortcuts.splice(move.newIndex, 0, temp)
      // Re-render for new order to be shown
      // Without it sortable new order and vuex new order are conflicted
      this.reRender()
      this.$store.commit('updateShortcutsOrder', this.shortcuts)
    },

    reRender () {
      this.render = false
      this.$nextTick(() => {
        this.render = true
        this.$nextTick(() => {
          this.createShortcutsSortables()
          this.showExpandBtnIfNeeded()
        })
      })
    },

    shortcutColor (shortcut: DashboardTypes.Shortcut) {
      return Color(shortcutData.shortcutColor(shortcut)).hex()
    },

    shortcutIcon (shortcut: DashboardTypes.Shortcut) {
      return shortcutData.shortcutIcon(shortcut)
    },
  }
}
