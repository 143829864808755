<template>
  <v-btn
    :color="$store.state.showShortcuts ? 'grey lighten-2' : $store.getters.menuColor"
    :light="$store.state.showShortcuts"
    :class="'elevation-0 toolbar-btn ' + ($store.state.showShortcuts ? 'grey--text' : '')"
    height="40"
    tile
    @click="toggle()"
  >
    <v-icon small>
      fa-star
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ShortcutsTopMenu',

  methods: {
    toggle () {
      this.$store.state.showShortcuts = !this.$store.state.showShortcuts
    },
  }
}
</script>
