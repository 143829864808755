
import date_time_helper from '@/methods/date_time_helper'
import api from '@/store/api/'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import { AxiosResponse } from 'axios'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ReleaseInfo',

  data () {
    return {
      loading: true,
      currentPage: 0,
      pages: 0,
      releaseNotes: [],
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  created () {
    this.showNextReleaseNote()
  },

  methods: {
    formatDate (date: string) {
      return date_time_helper.formatDate(date)
    },

    showNextReleaseNote () {
      this.loading = true
      if (this.currentPage > 0 && this.currentPage > this.pages) {
        this.loading = false
        return
      }
      this.currentPage++
      api.fetchReleaseInfo(this.currentPage).then((response: AxiosResponse) => {
        this.pages = (response.data.last_page) || 0
        if (response.data.release_notes && Object.keys(response.data.release_notes).length > 0) {
          Object.keys(response.data.release_notes).forEach(instance => {
            this.releaseNotes.push({
              instance,
              release_date: response.data.release_date,
              release_notes: response.data.release_notes[instance],
            })
          })
          this.loading = false
        } else {
          this.showNextReleaseNote()
        }
      })
    },
  },
}
