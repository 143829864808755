<template>
  <div>
    <v-menu
      :z-index="210"
      offset-y
      close-on-click
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          :dark="!compact && !disabled && !isMobileBottomToolbar"
          :icon="compact"
          :disabled="disabled"
          :block="isMobileBottomToolbar"
          :class="isMobileBottomToolbar ? 'mobile-toolbar-btn grey--text text--darken-1' : 'mx-1'"
          :color="isMobileBottomToolbar ? 'grey lighten-3' : 'grey'"
          depressed
          small
          v-on="on"
        >
          <v-icon
            small
            :class="isMobileBottomToolbar ? 'pr-2' : compact ? '' : 'mr-2'"
          >
            fa-download
          </v-icon>
          {{ compact ? '' : $i18n.t('aava.download.title') }}
        </v-btn>
      </template>
      <div class="popup-items-container">
        <div
          v-for="( downloadLink, index ) in downloadLinks"
          :key="index"
          class="popup-menu-el"
          @click="download(downloadLink)"
        >
          <a href="javascript:;">
            <span class="fa fa-download" />
            {{ downloadLink.name }}
          </a>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'ItemDownloadActions',

  props: {
    downloadLinks: {
      type: Array,
      default: () => {
        return []
      },
    },
    isMobileBottomToolbar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    compact () {
      return !this.isMobileBottomToolbar && (!this.$vuetify.breakpoint.xlOnly || this.$store.state.splitMode === 'vertical')
    },

    disabled () {
      return !(this.downloadLinks && this.downloadLinks.length > 0)
    },
  },

  methods: {
    download (link) {
      window.open(link.path)
    },
  },
}
</script>
