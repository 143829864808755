<template>
  <div>
    <!-- Dummy inputs to avoid Firefox
    auto-filling username field and causing Item Picker to open in some cases
    Aava-Vue-558 -->
    <input
      name="DummyUsername"
      type="text"
      style="display:none;"
    >
    <input
      name="DummyPassword"
      type="password"
      style="display:none;"
    >
    <v-text-field
      ref="multi-edit-value"
      v-model="password"
      :label="$i18n.t('aava.change_password.new_password')"
      :placeholder="''"
      :persistent-placeholder="true"
      :disabled="readOnly"
      autocomplete="off"
      type="password"
      spellcheck="false"
      color="orange"
      outlined
      dense
      hide-details
    />
    <v-text-field
      ref="multi-edit-value"
      v-model="passwordConfirmation"
      :label="$i18n.t('aava.change_password.new_password_again')"
      :placeholder="''"
      :persistent-placeholder="true"
      :disabled="readOnly"
      autocomplete="off"
      type="password"
      class="mt-2"
      spellcheck="false"
      color="orange"
      outlined
      dense
      hide-details
    />
  </div>
</template>

<script>
const inflect = require('i')()

export default {
  name: 'Password',

  props: {
    value: {
      type: String,
      default: '',
    },
    field: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {}
  },

  computed: {
    password: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      },
    },
    passwordConfirmation: {
      set (value) {
        this.$set(this.item, this.field.name + '_confirmation', value)
      },
      get () {
        return this.item[this.field.name + '_confirmation']
      },
    },

  },

  methods: {},
}
</script>
