
import listItemCellMethods from './../../methods/listItem/listItemCellMethods'
import dateTimeHelper from './../../methods/date_time_helper'
import RichtextEditor from './../Form/RichtextEditor.vue'
import DateTime from '../Form/DateTime.vue'
import ReferenceField from './../Form/ReferenceField.vue'
import TextArea from './../Form/TextArea.vue'
import BooleanField from './../Form/Boolean.vue'
import HasManyItems from './HasManyItems.vue'
import ContentReference from '@/components/ListContent/DataTypes/ContentReference.vue'
import AddressField from '@/components/Form/AddressField.vue'
import Images from '@/components/Form/Images.vue'
import Files from '@/components/Form/Files.vue'
import TextField from '@/components/Form/TextField.vue'
import MultiLangTextField from '@/components/Form/MultiLangTextField.vue'
import Password from '@/components/Form/Password.vue'
import translateAttribute from '@/utilities/translateAttribute'
import StaticDynamicList from '@/components/Form/StaticDynamicList.vue'
import sharedUtilities from '@/utilities/sharedUtilities'
import ContentProcessEvents from '@/components/ListContent/DataTypes/ContentProcessEvents.vue'
import itemMethods from '@/methods/item/itemMethods'
import ObjectStateLabel from '@/components/Form/ObjectStateLabel.vue'
import ShowViewText from '@/components/Form/ShowViewText.vue'
import ContentHasManyReferenceItems from '@/components/ListContent/DataTypes/ContentHasManyReferenceItems.vue'
import HeaderColumnResize from '../ListHeaderColumns/HeaderColumnResize.vue'
import HTMLWithPreview from '@/components/Form/HTMLWithPreview.vue'
import EventButton from '@/components/ListContent/DataTypes/EventButton.vue'
import itemEventActionMethods from '@/methods/item/itemEventActionMethods'
import { FormItem, LP } from '@/types/LP.types'
import { Types } from '@/types/AppTypes'
import { AxiosResponse } from 'axios'
import { ListEditSavePayload } from '@/types/Item'

export default {
  name: 'FormField',

  components: {
    EventButton,
    HTMLWithPreview,
    HeaderColumnResize,
    ContentHasManyReferenceItems,
    ShowViewText,
    ObjectStateLabel,
    ContentProcessEvents,
    StaticDynamicList,
    Password,
    MultiLangTextField,
    TextField,
    Files,
    Images,
    AddressField,
    ContentReference,
    RichtextEditor,
    DateTime,
    ReferenceField,
    TextArea,
    BooleanField,
    HasManyItems,
  },

  props: {
    resource: {
      type: String,
      default: '',
    },
    view: {
      type: String,
      default: 'item',
    },
    item: {
      type: Object as () => Types.Item,
      required: true,
    },
    field: {
      type: Object as () => FormItem,
      required: true,
    },
    hasManyParent: {
      type: Object as () => Types.Item,
      default: () => {},
    },
    hasManyParentField: {
      type: Object as () => LP.Item,
      default: () => {},
    },
    hasManyItemLink: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    editInList: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    fieldIndex: {
      type: Number,
      default: null,
    },
    compactField: {
      type: Boolean,
      default: false,
    },
    hasManyRowIndex: { // To show field label only for first row
      type: Number,
      default: null,
    },
    colIndex: {
      type: Number,
      default: null,
    },
    getColsForContainerFieldSets: {
      type: Number,
      default: null,
    },
    availableSpaceInFieldSet: {
      type: Number,
      default: null,
    },
    showItemPickerFor: {
      type: Function,
      default: () => {},
    },
    showItemFieldMenu: {
      type: Function,
      default: () => {},
    },
    layoutEditMode: {
      type: Boolean,
      default: false,
    },
    showOverflowingTextTooltip: {
      type: Boolean,
      default: false,
    },
    contentCols: {
      type: [Number, String],
      default: 1,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    updateItemOnDefaultsForChange: {
      type: Function,
      default: () => {},
    },
    updateHasManyParentItemOnDefaultsForChange: {
      type: Function,
      default: () => {},
    },
    showItemModal: {
      type: Function,
      default: () => {},
    },
    updateItemAfterSelectingNewHasManyChild: {
      type: Function,
      default: () => {},
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    stateChangeCallback: {
      type: Function,
      default: null,
    },
    unfinishedDfcCount: {
      type: Number,
      default: 0,
    },
  },

  data () {
    return {
      changeTimer: null,
      extraItemChangeTimer: null,
      autoSaveTimer: null, // For list edit, not to trigger twice for same value on different events
      renderField: true,
      showingTooltipFor: '',
      showTooltipTimer: null,
      skipBlur: false,
    }
  },

  computed: {
    isActiveInListEdit () {
      if (this.view !== 'list') { return false }
      const listEdit = this.$store.state.listEdit
      return listEdit.current?.item?.id === this.item.id && listEdit.current?.field?.name === this.field.name
    },

    showShowViewText () {
      return (!this.edit ||
          this.field.name === 'summary' ||
          this.field.type === 'raw' ||
          this.field.type === 'raw_full'
      ) &&
        (!this.field.multi_language || this.isHasManyField)
    },

    locale () {
      return this.$store.state.locale
    },

    displayValue () {
      return this.getItemFieldDisplayText()
    },

    isHasManyField () {
      return !!(this.hasManyParentField?.name)
    },

    isHasManyFieldAndNotFirstRow () {
      return (this.isHasManyField || this.compactField) && this.hasManyRowIndex !== 0
    },

    cols () {
      if (this.isHasManyField || ['richtext_info', 'hr'].includes(this.field.type)) {
        return 12
      }
      if (['text', 'richtext', 'has_many_reference', 'raw_full'].includes(this.field.type)) {
        return 12
      }
      return this.dynamicContentCols
    },

    availableWidthPxForAField () {
      return this.availableSpaceInFieldSet / 12 * this.cols
    },

    fieldStyleString () {
      return this.item?.['@member_styles']?.[this.field.name] || ''
    },

    fieldStyleIncludesBackgroundColor () {
      // Sometimes CSS string can include 'background' without actual value check for the value
      const parts = this.fieldStyleString?.split(':') ?? []
      return this.fieldStyleString?.includes('background') &&
        parts.length > 1 && parts[1].trim().length > 3 // Check value length
    },

    classes () {
      const classes = [
        ('form-field-' + this.field.name),
        // Do not set show-view and edit-view for has-many, to better set styles
        !this.edit && !this.isHasManyField && 'show-view',
        this.edit && !this.isHasManyField && 'edit-view',
        !this.isHasManyField && 'not-has-many', // For e2e tests
        this.editComputed && !this.readOnlyComputed && 'editable',
        this.readOnlyComputed && 'readonly',
        this.fieldStyleIncludesBackgroundColor && 'custom-styled',
        !this.fieldStyleIncludesBackgroundColor && 'not-custom-styled',
        this.layoutEditMode && 'field-set-field-edit',
        !this.isHasManyField && 'field-set-field',
        ...(this.isHasManyField && this.edit ? ['py-0', 'px-0'] : []),
        ...(this.isHasManyField && !this.edit ? ['my-0', 'py-0', 'px-0'] : []),
        this.layoutEditMode &&
          this.$store.state.selectedLayoutEditorFields.includes(this.field.name) && 'selected-layout-editor-field',
        this.layoutEditMode && this.$store.state.highlightedFieldName === this.field.name && 'highlighted-field',
      ]
      classes.push(this.field.type + '-form-field')
      return classes.filter(c => c)
    },

    unit () {
      return this.field.type === 'price'
        ? this.item[this.field.name + '_currency']
        : this.item[this.field.name + '_unit']
    },

    label () {
      const translateForView = this.isHasManyField ? 'index' : 'show'
      return this.field.label ||
        translateAttribute(this.resource, this.field.name, this.locale, this.$i18n, translateForView)
    },

    dynamicContentCols () {
      const userFixedContentCols = this.contentCols !== 'auto' && this.contentCols
      const minContentCols = userFixedContentCols
        ? 12 / this.contentCols
        : 3
      const suggestedCols = this.availableSpaceInFieldSet < 400
        ? 12
        : this.availableSpaceInFieldSet < 700
          ? 6
          : this.availableSpaceInFieldSet < 1300
            ? 4
            : this.availableSpaceInFieldSet < 1920 ? 3 : 2
      if (userFixedContentCols) {
        return suggestedCols < minContentCols ? minContentCols : suggestedCols
      }
      return suggestedCols
    },

    datetimeDisplay () {
      return this.field.type === 'datetime'
        ? dateTimeHelper.formatDateAndTime(this.item[this.field.name])
        : dateTimeHelper.formatDate(this.item[this.field.name])
    },

    reRenderFieldTrigger () {
      if (['richtext'].includes(this.field.type)) {
        return this.item.id + this.item.token + this.edit
      }
      return ''
    },

    infoText () {
      const documentation = this.$store.state.documentationByModel[this.resource]
      return documentation && documentation.attributes && documentation.attributes[this.field.name]
    },

    editComputed () {
      return this.edit && !this.field.dynamic
    },

    readOnlyComputed () {
      const isEventType = ['process_events', 'event', 'action'].includes(this.field.type)
      // Aava-Vue-527
      // Hack fix to allow item-picker columns search when opened from modal view
      if (this.$store.state.itemPickerProps?.objectClass && this.modal && this.$store.state.itemPickerProps?.selectColumns?.length) {
        // this also does not help
        // && this.$store.state.itemPickerProps.forField?.name !== this.field.name
        return true
      }
      if (this.item['@prevent_write_members']?.includes(this.field.name)) {
        return true
      }
      // Include also dynamic - edit component is still used but with readonly state
      const dynamicCondition = this.field.dynamic && !isEventType
      // Hack - skip writable check if editing my_profile
      const isWritable = this.field.writable ||
        this.$route.params.specialAction === 'myProfile' ||
        isEventType // 27 Nov 2023 had a case where writable was false in has-many item for lower level user. Decided to skip that change
      return this.readOnly || !isWritable || dynamicCondition
    },

    showFilesComponent () {
      return sharedUtilities.isAttachment(this.field)
    },

    showImagesComponent () {
      return sharedUtilities.isImageType(this.field)
    },
  },

  watch: {
    reRenderFieldTrigger () {
      this.reRender()
    },
  },

  methods: {
    ...listItemCellMethods,
    ...itemMethods,
    ...itemEventActionMethods,

    openTooltipFor (target, delay) {
      clearTimeout(this.showTooltipTimer)
      this.showTooltipTimer = setTimeout(() => {
        this.showingTooltipFor = target
      }, delay)
    },

    closeTooltip () {
      clearTimeout(this.showTooltipTimer)
      this.showingTooltipFor = ''
    },

    updateItemBeforeEventAction () {
      // if (this.isHasManyField) {
      return new Promise(resolve => {
        if (!this.edit) { return resolve(true) }
        this.updateItemOnDefaultsForChange({ name: 'updated_at' }).then((response: AxiosResponse) => {
          resolve(response)
        })
      })
      // }
    },

    logFieldInfo () {
      // Log field info to console
      // if is localhost or username includes 'aava' or is Oma instance
      const isAavaUser = this.$store.state.userInfo?.name.includes('aava')
      const isOmaInstance = window.location.host.includes('oma.aava')
      if (isAavaUser || isOmaInstance || this.$store.state.isLocalDevelopment) {
        console.log('click', this.field.name, this.field.type, this.field, this.item[this.field.name], this.item)
      }
    },

    reRender () {
      this.renderField = false
      this.$nextTick(() => {
        this.renderField = true
      })
    },

    // For list edit to quickly detect changes to trigger save value without delay
    blurHandler () {
      if (!this.editInList) {
        return
      }
      // For list view OR has-many edit AND if editInList mode
      clearTimeout(this.autoSaveTimer)
      this.emitSaveValueInList('blur', { field: this.field })
    },

    // Tracks item form extra item changes in Layout Editor
    // do not change too often
    formExtraItemChangeListener () {
      if (this.extraItemChangeTimer) {
        clearTimeout(this.extraItemChangeTimer)
        this.extraItemChangeTimer = null
      }
      this.extraItemChangeTimer = setTimeout(() => {
        this.extraItemChangeTimer = null
        this.$emit('saveLayoutEditorOnExtraItemChange')
      }, 3000)
    },

    // Timeout has to be reasonable, can't type number with multiple digits
    changeListener (timeout = 1000) {
      // Do not block form save for non dfc fields
      // Can no longer skip HERE as needed in list-edit for every attribute
      // if (!this.item?.['@observed_members']?.includes(this.field.name)) {
      //   return
      // }
      const skipDfc = !this.item?.['@observed_members']?.includes(this.field.name)

      // While timeout being triggered, increase dfcRequestsComingFromTimer
      // So form can not be saved before dfc request is put to the queue and/or executed
      if (!skipDfc) {
        this.$store.state.dfcRequestsComingFromTimer++
      }
      // If timer already, clear everything and decrease dfcRequestsComingFromTimer
      if (this.changeTimer) {
        if (!skipDfc) {
          this.$store.state.dfcRequestsComingFromTimer--
        }
        clearTimeout(this.changeTimer)
        this.changeTimer = null // To check here if has cleared. clearTimeout does not make it null
      }
      if (this.autoSaveTimer) {
        clearTimeout(this.autoSaveTimer)
      }
      this.autoSaveTimer = setTimeout(() => {
        // For list view OR has-many edit
        this.autoSaveTimer = null
        if (this.editInList) {
          this.emitSaveValueInList('change-listener', { field: this.field })
        }
      }, timeout)
      this.changeTimer = setTimeout(() => {
        clearTimeout(this.changeTimer) // TODO - this can be removed?
        this.changeTimer = null
        if (!skipDfc) {
          this.$store.state.dfcRequestsComingFromTimer--
          // Send the actual dfc request through a queue
          this.updateItemOnDefaultsForChange(this.field)
          // Call for list edit change
        }
      }, timeout)
    },

    eventButtonClickHandler (payload) {
      return this.$emit('saveValueInList', payload)
    },

    keyPressHandler (e: KeyboardEvent) {
      this.$emit('keyPress', e)
      if ([
        'ArrowDown', // Move 1 row down
        'ArrowUp', // Move 1 row up
      ].includes(e.key)) {
        this.$emit('moveRowDownOrUp', { e, colIndex: this.colIndex })
      } else if ([
        'Enter',
        'Tab',
      ].includes(e.key)) {
        clearTimeout(this.autoSaveTimer)
        this.emitSaveValueInList('keypress', { e, field: this.field })
      } else if (e.key === 'Escape') {
        this.$emit('restoreValueInList', e)
      }
    },

    // Through one place to avoid double, like key press and focus at the same time
    emitSaveValueInList (trigger: 'blur' | 'keypress' | 'change-listener', payload: ListEditSavePayload) {
      // console.log(trigger, this.item[this.field.name])
      if (trigger === 'keypress') {
        // Temporarily disable blur event when just before triggered change handler anyway
        // Avoiding double change trigger in some cases
        this.skipBlur = true
        setTimeout(() => {
          this.skipBlur = false
        }, 150)
      }
      // If trigger and keypress come at the same time, send blur a little later, so it can be skipped when needed
      const timeout = trigger === 'blur' ? 50 : 0
      setTimeout(() => {
        if (trigger === 'blur' && this.skipBlur) {
          console.warn('skip blur, change was in 150 ms', this.field.name)
          return
        }
        this.$emit('saveValueInList', payload)
      }, timeout)
    }
  },
}
