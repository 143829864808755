import Vue from 'vue'
import moment from 'moment'
import api from './../../store/api'
import localStorageTtl from '@/utilities/localStorageTtl'
import router from '@/router'
import { LP, LPItemType } from '@/types/LP.types'
import { AxiosResponse } from 'axios'

const getLastUsedLayoutProfile = profiles => {
  if (!profiles || profiles.length === 0) { return {} }
  const profilesWithTimestamp = profiles?.filter(lp => moment(lp.last_used_at).isValid())
  if (profilesWithTimestamp.length === 0) { // Can't reduce an empty array
    return profiles[0]
  }
  return profilesWithTimestamp.reduce((previous, current) => moment(previous.last_used_at)
    .isAfter(moment(current.last_used_at))
    ? previous
    : current)
}

const getLayoutProfileToSelect = (newProfileJustCreated, layoutProfiles, layoutProfileIdFromUri) => {
  let layoutProfile = getLastUsedLayoutProfile(layoutProfiles)
  if (newProfileJustCreated) {
    layoutProfile = layoutProfiles[layoutProfiles.length - 1]
  }
  if (layoutProfileIdFromUri) {
    const match = layoutProfiles.find(layoutProfile => layoutProfile.id === parseInt(layoutProfileIdFromUri))
    return match || layoutProfile
  }
  return layoutProfile
}

const completeLayoutProfilesData = (layoutProfiles: LP.LayoutProfile[], controller: string, action: LPItemType) => {
  const defaultConfig = {
    backgroundColor: '',
    showItemCount: false,
    hasManyFieldsWithData: [],
    extraFormItems: [],
    itemsSortOrder: [],
  }
  return layoutProfiles.map((item, index) => {
    item.count = item.count || null
    if (item.timelineConfiguration) {
      // Do nothing, LP has been already completed, do not want to overwrite with original API data - Aava-Vue-302
    } else if (!item.timeline_configuration || item.timeline_configuration === 'null') {
      item.timelineConfiguration = JSON.parse(JSON.stringify(defaultConfig))
    } else {
      try {
        item.timelineConfiguration = JSON.parse(item.timeline_configuration)
      } catch (e) {
        console.error('Not valid JSON', item.timeline_configuration)
      }
      if (!item.timelineConfiguration) {
        item.timelineConfiguration = JSON.parse(JSON.stringify(defaultConfig))
      }
      if (!item.timelineConfiguration.showItemCount) {
        item.timelineConfiguration.showItemCount = false
      }
      ['hasManyFieldsWithData', 'extraFormItems', 'itemsSortOrder'].forEach((propName) => {
        if (!item.timelineConfiguration[propName]) {
          item.timelineConfiguration[propName] = []
        }
      })
    }
    item.config = item.timelineConfiguration
    // Make sure all required reactive properties are set
    Object.keys(defaultConfig).forEach(key => {
      if (!item.timelineConfiguration[key]) {
        item.timelineConfiguration[key] = defaultConfig[key]
      }
    })
    item.controller = controller
    item.action = action
    item.index = index
    return item
  })
}

export default {
  updateLayoutProfileIndexes: ({ state }, layoutProfiles: LP.LayoutProfile[]) => {
    if (!layoutProfiles || !layoutProfiles[0]) { return }
    if (layoutProfiles[0].action === 'index') {
      // Update indexes for list view Layout Profiles
      Vue.set(state, 'layoutProfiles', completeLayoutProfilesData(
        state.layoutProfiles, state.objectClass, 'index'
      ))
    } else {
      // Update indexes for show view Layout Profiles
      const resource = layoutProfiles[0].controller
      Vue.set(state.itemLayoutProfilesByModel, resource, completeLayoutProfilesData(
        state.itemLayoutProfilesByModel[resource], resource, 'show'
      ))
    }
  },

  loadMetaInfo: ({ dispatch }, { objectClass, cache }) => {
    return new Promise(resolve => {
      const fromCache = localStorageTtl.get('mi_' + objectClass)
      if (cache && fromCache?.status === 'ok') {
        return resolve(fromCache)
      }
      api.getMetaInfo(objectClass).then((response: AxiosResponse) => {
        // Set to local storage cache for four hours
        dispatch('globalErrorDisplay', { response, context: 'Get meta info for ' + objectClass, ignoreErrorCodes: [403] })
        if (response.data.status === 'ok') {
          localStorageTtl.set('mi_' + objectClass, response.data, 1000 * 60 * 60 * 4)
        }
        resolve(response.data)
      })
    })
  },

  loadMetaAndLayoutProfiles: ({ dispatch, state }, { objectClass, cache }) => {
    return new Promise(resolve => {
      state.loadingList = true
      // Get Meta Info
      dispatch('loadMetaInfo', { objectClass, cache }).then((apiMetaInfo: AxiosResponse) => {
        if (apiMetaInfo) {
          state.metaInfo = apiMetaInfo
          // Get Layout Profiles
          dispatch('loadLayoutProfiles', { objectClass })
            .then((layoutProfiles: LP.LayoutProfile[]) => {
              dispatch('setSelectedLayoutProfileData',
                getLayoutProfileToSelect(state.newProfileJustCreated, layoutProfiles, state.layoutProfileIdFromUri))
              resolve(layoutProfiles)
            })
        } else resolve(false)
      })
    })
  },

  loadLayoutProfiles: ({ state, dispatch }, { objectClass }): Promise<LP.LayoutProfile[] | null> => {
    return new Promise(resolve => {
      api.getLayoutProfiles(objectClass, 'index')
        .then((response: AxiosResponse) => {
          const ignoreErrorCodes: number[] = []
          // On item view, ignore list error codes
          const isItemView = router?.currentRoute?.params?.id
          if (isItemView) {
            ignoreErrorCodes.push(403)
          }
          dispatch('globalErrorDisplay', { response, context: 'Get index Layout Profiles ' + objectClass, ignoreErrorCodes })
          if (response.status === 403) {
            state.forbiddenListModels.push(objectClass)
          }
          if (response.data.items?.[0]?.id) {
            dispatch('setLayoutProfilesDataInStoreAfterLoad', {
              objectClass,
              response,
            })
            resolve(response.data.items)
          } else resolve(null)
        })
    })
  },

  // Set layout profiles and card view related data form the response
  setLayoutProfilesDataInStoreAfterLoad: ({ state }, {
    objectClass,
    response,
  }) => {
    state.layoutProfiles = completeLayoutProfilesData(response.data.items, objectClass, 'index')
    state.supportsCardView = response.data.supports_card_view
    if (response.data.card_views) {
      state.cardViews = response.data.card_views
    }
  },

  loadItemLayoutProfiles: ({ state, dispatch }, objectClass: string) => {
    return new Promise(resolve => {
      api.getLayoutProfiles(objectClass, 'show')
        .then((response: AxiosResponse) => {
          dispatch('globalErrorDisplay', { response, context: 'Get item layout profiles ' + objectClass })
          if (response.data.items?.[0]?.id) {
            Vue.set(state.itemLayoutProfilesByModel,
              objectClass,
              completeLayoutProfilesData(response.data.items, objectClass, 'show')
            )
            state.triggerToCalculateVisibleItemLayoutProfileTabs++
            dispatch('selectItemLayoutProfile', objectClass)
            resolve(response.data.items)
          } else {
            resolve(false)
          }
        })
    })
  },

  selectItemLayoutProfile: ({ state }, objectClass: string) => {
    // If already selected, do not proceed
    // Helps to select default show/edit profile when item selected from list view
    // Except when new LP was just created
    if (state.selectedLayoutProfileIdByModel[objectClass] && !state.newItemProfileJustCreated) {
      return
    }
    const selectLayoutProfile = getLayoutProfileToSelect(
      state.newItemProfileJustCreated,
      state.itemLayoutProfilesByModel[objectClass],
      state.layoutProfileIdFromUri
    )
    Vue.set(state.selectedLayoutProfileIdByModel, objectClass, selectLayoutProfile.id)
    Vue.nextTick(() => {
      state.newItemProfileJustCreated = false
    })
  },
}
