<template>
  <v-container
    id="card-view-icon-action"
    :style="style"
  >
    <v-row dense>
      <v-col
        class="subtitle-2"
        cols="12"
      >
        <b>{{ action && action.label }}</b>
      </v-col>
      <v-col
        v-for="(parameter, index) in (action && action.parameters) || []"
        :key="index"
        cols="12"
      >
        <v-text-field
          v-if="parameter.type === 'String'"
          v-model="parameter.value"
          :label="parameter.label"
          :autofocus="index === 0"
          class="card-view-icon-action-input-string"
          dense
          outlined
          hide-details
          @keyup.enter="save"
        />
        <v-select
          v-else-if="parameter.type === 'Selection'"
          v-model="parameter.value"
          :items="parameter.values"
          :class="'card-view-icon-action-input-selection icon-action-select-' + index"
          :label="parameter.label"
          :attach="'#icon-action-select-' + index"
          dense
          solo
          tile
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-btn
          color="green darken-2"
          dark
          block
          dense
          small
          depressed
          @click.stop="save"
        >
          {{ $i18n.t('aava.actions.save') }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn
          color="blue-grey lighten-1"
          dark
          block
          dense
          small
          depressed
          @click.stop="cancel"
        >
          {{ $i18n.t('aava.actions.cancel') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const Color = require('color')

export default {
  name: 'CardViewIconAction',

  props: {
    action: {
      type: Object,
      default: () => {
        return {}
      },
    },
    color: {
      type: String,
      default: () => null,
    },
  },

  computed: {
    style () {
      let color = (this.action && this.action.color) || this.color || '#e3e3e3'
      const borderColor = '#cfcfcf'
      color = Color(color)
        .lightness(95)
        .hex()
      return {
        background: color,
        borderTop: '1px solid ' + borderColor,
        borderBottom: '1px solid ' + borderColor,
      }
    },
  },

  methods: {
    cancel () {
      this.$emit('completed', this.action)
    },

    save () {
      this.$emit('completed', this.action, 'save')
    },
  },
}
</script>

<style scoped>
input {
  background: transparent !important;
}
#card-view-icon-action {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: -18px !important;
  padding: 4px 10px 8px 10px;
  width: calc(100% + 26px);
}
</style>
