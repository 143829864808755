import moment from 'moment'
import i18n from '@/locale/i18n'
import date_formatters from '@/components/PivotTableView/utils/date_formatters'

export default {
  monthlyComparer: (a, b) => {
    const a_val = a.split(' ')
    const b_val = b.split(' ')
    if (isNaN(a_val[0])) {
      return 1
    }
    if (isNaN(b_val[0])) {
      return -1
    }
    const years_sign = Math.sign(a_val[0] - b_val[0])
    if (years_sign !== 0) {
      return years_sign
    }
    const months = moment.months()
    return Math.sign(months.indexOf(a_val[1]) - months.indexOf(b_val[1]))
  },

  weekdayComparer: (a, b) => {
    const weekdays = moment.weekdays()
    if (weekdays.indexOf(a) === -1) {
      return 1
    }
    if (weekdays.indexOf(b) === -1) {
      return -1
    }
    return Math.sign(weekdays.indexOf(a) - weekdays.indexOf(b))
  },

  dailyComparer: (a, b) => {
    const a_date = moment(a, date_formatters.dateFormat())
    const b_date = moment(b, date_formatters.dateFormat())
    if (!a_date.isValid()) {
      return 1
    }
    if (!b_date.isValid()) {
      return -1
    }
    return Math.sign(a_date - b_date)
  },

  hourlyComparer: (a, b) => {
    const a_datetime = moment(a, date_formatters.dateTimeFormat())
    const b_datetime = moment(b, date_formatters.dateTimeFormat())
    if (!a_datetime.isValid()) {
      return 1
    }
    if (!b_datetime.isValid()) {
      return -1
    }
    return Math.sign(a_datetime - b_datetime)
  },

  timeOfDayComparer: (a, b) => {
    const values = [
      i18n.t('aava.index.pivottable.night'),
      i18n.t('aava.index.pivottable.morning'),
      i18n.t('aava.index.pivottable.day'),
      i18n.t('aava.index.pivottable.evening'),
      i18n.t('aava.index.pivottable.no_value'),
    ]
    return Math.sign(values.indexOf(a) - values.indexOf(b))
  },
}
