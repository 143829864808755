import Vue from 'vue'
import listViewAPI from './../../api'
import util from '../../../utilities/sharedUtilities'
import { AxiosResponse } from 'axios'

export default {
  saveBreakdownConfig: ({ state, dispatch }, { orderColumns }) => {
    const layoutProfileIndex = util.findLayoutProfileIndexById(state, state.selectedLayoutProfileId)
    Vue.set(state.layoutProfiles[layoutProfileIndex].timelineConfiguration,
      'breakdown_configuration', {
        orderColumns
      })
    return dispatch('saveLayoutProfileData', state.selectedLayoutProfile)
  },

  loadBreakdownItems: ({ dispatch, state }, { filters, queries }) => {
    return new Promise(resolve => {
      dispatch('loadObjectStates', state.objectClass)
      listViewAPI.loadBreakdownItems(state.objectClass, filters, queries)
        .then((response: AxiosResponse) => {
          dispatch('globalErrorDisplay', { response, context: 'Load breakdown view items ' + state.objectClass })
          resolve(response)
        })
    })
  }
}
