import decimal from '@/methods/decimal'
import currencySymbols from '../../utilities/currencySymbols'

export default {
  isCountAttribute (attribute, group): boolean {
    return group.counts && group.counts[attribute]
  },

  countValue (attribute, group) {
    return this.countTrue(attribute, group) + ' / ' + this.countTotal(attribute, group)
  },

  countTotal (attribute, group): number {
    return (group.counts[attribute].true || 0) + (group.counts[attribute].false || 0)
  },

  countTrue (attribute, group) {
    return (group.counts[attribute].true || 0)
  },

  sumValueWithUnit (attribute, group) {
    const thisValue = group.sums[attribute]
    if (typeof thisValue !== 'object') { return thisValue }

    const ret = Object.keys(thisValue).filter((key) => key !== 'type').map((key) => {
      const value = decimal.format(thisValue[key].value, { precision: 2 }) || '0,00'
      let unit = thisValue[key].unit || thisValue[key].currency
      unit = !unit ? '' : unit // Set undefined
      return value + ' ' + (currencySymbols[unit] || unit)
    }).join(', ')
    return ret
  },

  // returning unitless if there is only one unit/currency
  sumValueWithoutUnit (attribute, group) {
    const thisValue = group.sums[attribute]
    if (typeof thisValue !== 'object') { return parseFloat(thisValue) }

    if (Object.keys(thisValue).length <= 2) {
      const realkey = Object.keys(thisValue).filter((key) => key !== 'type')[0]
      return parseFloat(thisValue[realkey].value)
    }

    return this.sumValueWithUnit(attribute, group)
  },

  countAttributeUnit (fieldName) {
    if (!fieldName || !this.totalsCollection.items.sums) { return }
    const thisFieldSums = this.totalsCollection.items.sums[fieldName]
    if (typeof thisFieldSums !== 'object') { return }
    if (thisFieldSums.type === 'percentage') { return this.$i18n.t('aava.index.breakdown.avg') }
    const unitKeys = Object.keys(thisFieldSums).filter((key) => key !== 'type')
    if (unitKeys.length !== 1) { return }
    const unitKey = unitKeys[0]
    const unit = thisFieldSums[unitKey].unit || thisFieldSums[unitKey].currency

    return unit && unit !== 'invalid'
      ? (currencySymbols[unit] || unit)
      : ''
  },

  percentageFromTotal (totalSums, items, attributeName) {
    const sumValue = items.sums[attributeName]

    if (!totalSums || !sumValue) { return null }
    if (!totalSums[attributeName]) { return null }
    const unitKeys = Object.keys(totalSums[attributeName]).filter((key) => key !== 'type')
    const totalValue = totalSums[attributeName][unitKeys[0]].value
    const val = sumValue[unitKeys[0]].value
    const percentage = val * 100 / totalValue
    if (isNaN(percentage)) { return null }

    return decimal.format(percentage, { precision: 2 })
  },

  uniqUnitNameExists (totalSums, attributeName) {
    if (!totalSums[attributeName]) { return false }
    if (typeof totalSums[attributeName] !== 'object') { return false }
    const unitKeys = Object.keys(totalSums[attributeName]).filter((key) => key !== 'type')
    return unitKeys.length === 1
  },

  sortSubgroups (data, sortColumn, sortOrder) {
    const gtValue = sortOrder === 'desc' ? -1 : 1
    const ltValue = gtValue * -1

    return data.sort((a, b) => {
      if (sortColumn === null) {
        return this.spaceship(a.group_sort_value, b.group_sort_value, gtValue, ltValue)
      } else if ((a.sums[sortColumn] && a.sums[sortColumn].type) || (b.sums[sortColumn] && b.sums[sortColumn].type)) {
        const arrayA = Object.keys(a.sums[sortColumn]).filter((key) => key !== 'type')
        const arrayB = Object.keys(b.sums[sortColumn]).filter((key) => key !== 'type')
        const keys = arrayA.filter(x => arrayB.includes(x)) // intersection
        // keys is the intersection of currencies/units of the sorted items.
        if (keys.length === 0) { return 0 } // cannot compare unless there are common currencies/units
        return this.spaceship(
          parseFloat(a.sums[sortColumn][keys[0]].value),
          parseFloat(b.sums[sortColumn][keys[0]].value),
          gtValue, ltValue
        )
      } else if (a.sums[sortColumn] || b.sums[sortColumn]) {
        return this.spaceship(
          parseFloat(a.sums[sortColumn]),
          parseFloat(b.sums[sortColumn]),
          gtValue,
          ltValue
        )
      } else if ((a.counts && a.counts[sortColumn]) || (b.counts && b.counts[sortColumn])) {
        return this.spaceship(
          parseFloat(a.counts[sortColumn].true || 0),
          parseFloat(b.counts[sortColumn].true || 0),
          gtValue,
          ltValue
        )
      } else {
        return this.spaceship(a.group_sort_value, b.group_sort_value, gtValue, ltValue)
      }
    })
  },

  // ruby <=>-operator functionality is missing from javascript
  spaceship (a, b, gtValue, ltValue) {
    if (a === null && b != null) { return ltValue }
    if (a != null && b === null) { return gtValue }
    if (a > b) { return gtValue }
    else if (a < b) { return ltValue }
    return 0
  },
}
