<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="grey lighten-2"
        class="elevation-0 ml-2"
        height="26"
        dense
        outlined
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          x-small
          color="grey darken-1"
          class="pl-2"
        >
          {{ value ? value + ' / 12' : 'auto' }}
        </v-icon>
        <v-icon
          x-small
          class="ml-3"
          color="grey darken-1"
        >
          fa-caret-down
        </v-icon>
      </v-btn>
    </template>
    <div class="select-menu elevation-5">
      <div class="picker-options">
        <div
          v-for="(columnOption, index) in columnOptions"
          :key="index + '_co'"
          :class="'popup-menu-el ' + (value === columnOption ? 'menu-el-disabled' : '')"
          @click="change(columnOption)"
        >
          <a href="javascript:;">
            <span class="fa fa-arrows-h" />
            {{ columnOption ? columnOption + ' / 12' : 'auto' }}
          </a>
        </div>
      </div>
    </div>
  </v-menu>
</template>
<script>
export default {
  name: 'ContainerColumnsSelector',

  props: {
    value: {
      type: Number,
      default: 12,
    },
    allowEmptyOption: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      columnOptions: [2, 3, 4, 6, 8, 9, 10, 12],
    }
  },

  created () {
    if (this.allowEmptyOption) {
      this.columnOptions.unshift(0)
    }
  },

  methods: {
    change (value) {
      this.$emit('input', value)
      this.$emit('change')
    },
  },
}
</script>
