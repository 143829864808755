<template>
  <div>
    <template
      v-for="(fieldLocale, index) in $store.getters.availableContentLocalesSorted"
    >
      <div
        v-if="!edit && ($store.state.showMultiLocaleView || index === 0)"
        :key="index"
      >
        <div
          :class="'item-show-field ' + (index !== 0 ? 'mt-2' : '')"
        >
          <div
            :class="'item-show-label ' + (!!fieldValue(fieldLocale) ? ' item-show-label-filled-todo-to-be-decided' : '')"
          >
            <span>
              {{ label }}
              <v-btn
                class="__float-right locale-btn ml-2"
                color="grey"
                tabindex="-1"
                text
                x-small
                @click="toggleMultiLocaleView()"
              >
                <v-icon
                  x-small
                  class="pr-1"
                >fa-globe-americas</v-icon>
                {{ fieldLocale }}
              </v-btn>
            </span>
          </div>
          <div
            :class="field.type + '-type item-show-value'"
            v-html="typeof fieldValue(fieldLocale) === 'string'
              ? (fieldValue(fieldLocale).trim() || '&nbsp;')
              : fieldValue(fieldLocale) || '&nbsp;'"
          />
        </div>
      </div>
      <template v-else-if="$store.state.showMultiLocaleView || index === 0">
        <RichtextEditor
          v-if="field.type === 'richtext'"
          :key="index"
          v-model="item[field.name + '_' + fieldLocale]"
          :compact="true"
          :field="field"
          :label="labelWithLocale(fieldLocale)"
          :class="$store.state.showMultiLocaleView ? 'mb-2' : ''"
          :append-icon="index === 0 ? 'fa-globe-americas' : ''"
          :append-icon-function="toggleMultiLocaleView"
          :read-only="readOnly"
          @changeListener="changeListener"
        />
        <TextArea
          v-else-if="['text', 'richtext'].includes(field.type)"
          :key="index"
          v-model="item[field.name + '_' + fieldLocale]"
          :item="item"
          :field="field"
          :label="labelWithLocale(fieldLocale)"
          :is-has-many-field-and-not-first-row="isHasManyFieldAndNotFirstRow"
          :is-has-many-field="isHasManyField"
          :append-icon="index === 0 ? 'fa-globe-americas' : ''"
          :append-icon-function="toggleMultiLocaleView"
          :autofocus="autofocus && index === 0"
          spellcheck="false"
          :class="$store.state.showMultiLocaleView ? 'mb-2' : ''"
          :read-only="readOnly"
          @changeListener="changeListener"
        />
        <TextField
          v-else
          :key="index"
          v-model="item[field.name + '_' + fieldLocale]"
          :field-locale="fieldLocale"
          :item="item"
          :field="field"
          :label="labelWithLocale(fieldLocale)"
          :append-icon="index === 0 ? 'fa-globe-americas' : ''"
          :append-icon-function="toggleMultiLocaleView"
          :autofocus="autofocus && index === 0"
          :class="$store.state.showMultiLocaleView ? 'mb-2' : ''"
          :read-only="readOnly"
          :is-has-many-field-and-not-first-row="isHasManyFieldAndNotFirstRow"
          @changeListener="changeListener"
        />
      </template>
    </template>
  </div>
</template>

<script>
import TextField from '@/components/Form/TextField'
import RichtextEditor from '@/components/Form/RichtextEditor'
import TextArea from '@/components/Form/TextArea'

export default {
  name: 'MultiLangTextField',

  components: { TextArea, RichtextEditor, TextField },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isHasManyField: {
      type: Boolean,
      default: false,
    },
    isHasManyFieldAndNotFirstRow: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    item: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      },
    },
  },

  methods: {
    fieldValue (fieldLocale) {
      const val = this.value[this.field.name + '_' + fieldLocale]
      if (['richtext'].includes(this.field.type)) {
        return this.createActiveLinks(val)
      }
      return val
    },

    // Create active links user can click on
    createActiveLinks (text) {
      if (!text || text.includes('<a')) { // Already has active links, skip
        return text
      }
      const urlRegex = /(?<!<a.*>)(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])(?!<\/a>)/gi

      const replacedText = text.replace(urlRegex, url => {
        if (url.length > 150) {
          const truncatedUrl = url.slice(0, 147) + '...'
          return '<a href="' + url + '" target="_blank">' + truncatedUrl + '</a>'
        } else {
          return '<a href="' + url + '" target="_blank">' + url + '</a>'
        }
      })
      return replacedText
    },

    toggleMultiLocaleView () {
      this.$store.state.showMultiLocaleView = !this.$store.state.showMultiLocaleView
    },

    labelWithLocale (fieldLocale) {
      return this.label + ' (' + fieldLocale + ')'
    },

    changeListener () {
      this.$emit('changeListener')
    },
  },
}
</script>
