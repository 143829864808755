<template>
  <v-alert
    v-if="minutesToBreak !== null"
    id="restart-message-alert"
    type="warning"
    prominent
    @click="hideWarning"
  >
    <div id="restart-message-content">
      <v-row align="center">
        <v-col class="grow">
          <span>{{ $i18n.t('aava.messages.restart.expected', { minutes: minutesToBreak }) }}</span>
          <span v-if="breakDuration">
            {{ $i18n.t('aava.messages.restart.downtime_duration', { minutes: breakDuration }) }}
          </span>
        </v-col>
        <v-col class="shrink">
          <v-btn
            class="elevation-0"
            color="warning"
            x-small
          >
            <v-icon>fa-times</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-alert>
</template>

<script>
import state from '../../store/state'
import moment from 'moment'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'RestartMessage',

  data () {
    return {
      interval: null,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    minutesToBreak () {
      const time = moment(this.restartMessage.time)
      return time.isValid() ? Math.max(0, time.diff(moment(), 'minutes')) : null
    },

    breakDuration () {
      return this.restartMessage.duration
    },
  },

  mounted () {
    if (this.interval) return

    this.$store.dispatch('getRestartMessage')
    this.interval = window.setInterval(() => {
      this.$store.dispatch('getRestartMessage')
    }, 60 * 1000)
  },

  destroyed () {
    window.clearInterval(this.interval)
  },

  methods: {
    hideWarning () {
      this.$store.dispatch('hideRestartMessage')
    }
  },
}
</script>

<style scoped>
#restart-message-alert {
  cursor: pointer;
  position: absolute;
  top: 50px;
  padding-right: 8px;
  min-width: 400px;
  max-width: 600px;
  right: 20px;
  z-index: 1010;
}
#restart-message-content {
  padding: 2px 8px;
}
</style>
