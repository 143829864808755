<template>
  <v-dialog
    v-if="noSeView"
    v-model="noSeView"
    :style="containerStyle"
    content-class="notification-container"
  >
    <v-toolbar
      color="grey darken-1"
      dark
      dense
      text
      extended
    >
      <template v-slot:extension>
        <v-toolbar-items>
          <v-btn
            text
            @click="noSeView = false"
          >
            <v-icon
              color="#fee600"
              class="mr-3"
            >
              fa-chevron-circle-left
            </v-icon>
            {{ $i18n.t('aava.no_se.close') }}
          </v-btn>
          <v-btn
            v-for="(menuItem, index) in noSeMenu"
            :key="index"
            :color="isMenuItemActive(menuItem.key) ? '#fee600' : ''"
            :light="isMenuItemActive(menuItem.key)"
            :text="!isMenuItemActive(menuItem.key)"
            :class="isMenuItemActive(menuItem.key)
              ? 'grey--text text--darken-2'
              : 'grey--text text--lighten-2'"
            depressed
            @click="selectMenuItem(menuItem.key)"
          >
            <v-icon
              :color="menuItem.iconColor"
              class="pr-3"
            >
              {{ menuItem.icon }}
            </v-icon>
            {{ $i18n.t(menuItem.translationKey) }}
          </v-btn>
        </v-toolbar-items>
        <v-spacer />
      </template>
      <v-toolbar-title
        class="pr-3 mr-4"
        color="#fee600"
      >
        {{ $i18n.t('aava.no_se.title') }} |
        {{ $i18n.t(objectClass + '.list.title') }}
      </v-toolbar-title>

      <v-spacer />
      <v-btn
        class="hidden-xs-only"
        icon
        @click="noSeView = false"
      >
        <v-icon color="#fee600">
          fa-times-circle
        </v-icon>
      </v-btn>
    </v-toolbar>
    <div
      class="no-se-container"
    >
      <div>
        <NoSeMyNotifications v-if="noSeViewComponent === 'my' && !noSeEditItemId" />
        <NoSeSharedNotifications v-else-if="noSeViewComponent === 'shared'" />
        <NoSeNotificationEditor v-else-if="noSeEditItemId || noSeViewComponent === 'newForm'" />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import NoSeMyNotifications from './NoSeMyNotifications'
import NoSeSharedNotifications from './NoSeSharedNotifications'
import NoSeNotificationEditor from './NoSeNotificationEditor'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'NoSeContainer',

  components: {
    NoSeMyNotifications,
    NoSeSharedNotifications,
    NoSeNotificationEditor,
  },

  data () {
    return {
      noSeMenu: [{
        key: 'my',
        translationKey: 'aava.no_se.my_notifications',
        icon: 'fa-user',
        iconColor: ''
      }, {
        key: 'shared',
        translationKey: 'aava.no_se.shared_notifications',
        icon: 'fa-share-alt',
        iconColor: ''
      }, {
        key: 'newForm',
        translationKey: 'aava.no_se.add_notification',
        icon: 'fa-plus-circle',
        iconColor: 'green lighten-2'
      }]
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    containerStyle () {
      return {
        height: this.innerHeight + 'px',
        width: this.innerWidth + 'px'
      }
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    selectMenuItem (key) {
      this.noSeViewComponent = key
      if (key === 'newForm') {
        this.noSeEditItemId = null
      }
    },

    isMenuItemActive (key) {
      return this.noSeViewComponent === key
    },

    initialize () {
      this.$store.dispatch('getNoSeItems', this.objectClass).then(() => {
      })

      this.$store.dispatch('loadNoSeActions', this.objectClass).then(resp => {
      })

      this.$store.dispatch('loadNoSeAttributes', this.objectClass).then(resp => {
      })

      this.$store.dispatch('loadNoSeRecipients', this.objectClass).then(resp => {
      })
    },

    returnToList () {
      this.noSeView = false // close nose and return to list
    }
  }
}
</script>

<style>
.notification-container {
  background: white;
  min-height: 90%;
}
.notification-container .v-toolbar__items .v-btn--depressed {
  border-radius: 0 !important;
}
.no-se-container {
  /* padding: 10px; */
}
.no-se-button-text {
  margin-left: 8px;
}
</style>
