import dashboardConfig from '@/components/Dashboard/dashboardConfig'
import sharedUtilities from '@/utilities/sharedUtilities'

export default {
  validateData (chart_info) {
    return !(!chart_info || !chart_info.options || !chart_info.type || !chart_info.data)
  },

  /*
   * Completes chart options element
   * adds title style and
   * sets some parameters for similar visual
   */
  completeOptions (options) {
    options.responsive = true
    options.maintainAspectRatio = false
    if (options.title && options.title.text) {
      // TODO - test for dashboard
      // options.title.display = true
      // options.title.fontSize = dashboardConfig.CHART_TITLE_SIZE
      // options.title.fontColor = dashboardConfig.CHART_TITLE_COLOR
      /**
       * "align = left" is not yet supported
       * milestone is chart.js version 2.8, then can use:
       * options.title.align = 'start';
       */
    }
    return options
  },

  /*
   * Completes chart data element
   * Adds default background and border colors
   * if not defined before
   */
  completeData (type, chart_info) {
    if (type === 'line' && chart_info.data.datasets[0].fill) {
      chart_info = this.fixAreaChartOpacity(chart_info)
    }
    let colors = dashboardConfig.CHART_COLORS
    if (type === 'line') {
      colors = dashboardConfig.CHART_LINE_COLORS
    }
    const data = sharedUtilities.cloneObjectOrArray(chart_info.data)
    if (data.datasets) {
      // Multi dataset colors
      if ((data.datasets.length > 1 || type === 'line') && type !== 'doughnut') {
        data.datasets.forEach((dataset, i) => {
          if (!data.datasets[i].borderColor) {
            data.datasets[i].borderColor = colors[i]
          }
          if (!data.datasets[i].backgroundColor) {
            data.datasets[i].backgroundColor = colors[i]
          }
        })
      }

      // One dataset colors
      else if (data.datasets.length === 1) {
        if (!data.datasets[0].backgroundColor) {
          data.datasets[0].backgroundColor = []
        }
        if (!data.datasets[0].borderColor) {
          data.datasets[0].borderColor = []
        }
        data.datasets[0].data.forEach((dataval, i) => {
          if (!data.datasets[0].backgroundColor[i]) {
            data.datasets[0].backgroundColor[i] = colors[i]
          }
          if (!data.datasets[0].borderColor[i]) {
            data.datasets[0].borderColor[i] =
              ['doughnut', 'pie'].includes(type) ? dashboardConfig.CHART_PIE_BORDER_COLOR : colors[i]
          }
        })
      }

      // Multi dataset colors for Doughnut
      else {
        data.datasets.forEach((dataset, i) => {
          if (!data.datasets[i].backgroundColor) {
            data.datasets[i].backgroundColor = []
          }
          if (!data.datasets[i].borderColor) {
            data.datasets[i].borderColor = []
          }
          dataset.data.forEach((dataval, j) => {
            if (!data.datasets[i].backgroundColor[j]) {
              data.datasets[i].backgroundColor[j] = colors[j]
            }
            if (!data.datasets[i].borderColor[j]) {
              data.datasets[i].borderColor[j] = dashboardConfig.CHART_PIE_BORDER_COLOR
            }
          })
        })
      }
    }

    return data
  },

  fixAreaChartOpacity (chart_info) {
    const data = chart_info.data
    data.datasets.forEach((dataset, i) => {
      if (i === 0) {
        data.datasets[i].fill = 'origin' // for first dataset this is only allowed value
      } else {
        if (dataset.fill) {
          delete (data.datasets[i].fill) // can only be set for first dataset
        }
      }
    })
    if (!chart_info.options) {
      chart_info.options = {}
    }
    if (!chart_info.options.elements) {
      chart_info.options.elements = {}
    }
    if (!chart_info.options.elements.line) {
      chart_info.options.elements.line = {}
    }
    chart_info.options.elements.line.fill = '-1'
    chart_info.data = data
    return chart_info
  }
}
