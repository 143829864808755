<template>
  <v-container
    fluid
    class="pa-0"
  >
    <transition name="fade">
      <v-row
        v-if="!refreshing"
        class="preview_container"
        no-gutters
      >
        <v-col
          v-if="dashboardItem.object_type"
          cols="12"
          class="preview_header"
        >
          <router-link :to="layoutProfileLink">
            <div class="float_left">
              <div class="preview_model_name">
                {{ $i18n.t(modelName + '.list.title') }}
              </div>
              <div class="preview_title">
                {{ dashboardItem.layout_profile.name }}
              </div>
              <div class="preview_count">
                {{ dashboardItem.previewData.count }}
              </div>
            </div>
          </router-link>
          <div class="float_right remove_button_container">
            <i
              class="fa fa-times remove_button"
              @click="$emit('delete', dashboardItem.id)"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
        >
          <table class="ui unstackable compact table dashboard_table">
            <tbody
              v-for="(item, index) in filterMaxVisibleItems(dashboardItem.previewData.items)"
              :key="index"
              class="one_preview"
            >
              <tr v-if="index > 0">
                <td colspan="4">
                  <div class="spacer">
                    <div class="spacer_line" />
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  v-tooltip.top="item.summary"
                  :colspan="item.main_object_state ? 3 : 4"
                  :style="styleSummary(!!item.main_object_state)"
                  class="one_summary"
                >
                  <router-link
                    :to="itemLink(item.id, dashboardItem.object_type)"
                    class="one_summary"
                    v-html="item.summary"
                  />
                </td>
                <td
                  v-if="item.main_object_state"
                  :style="styleObjectState()"
                  class="one_td"
                >
                  <router-link :to="itemLink(item.id)">
                    <div
                      :style="$store.state.processStyles.object_states && $store.state.processStyles.object_states[item.main_object_state.id]"
                      class="one_status"
                    >
                      {{ showItemValue(item.main_object_state.name) }}
                    </div>
                  </router-link>
                </td>
              </tr>
              <tr>
                <td
                  v-for="line_i in $store.state.dashboardObjectColumns"
                  :key="line_i"
                  v-tooltip.top="composeObjectTooltip(dashboardItem, line_i, item)"
                  :class="'one_td' + itemCellStyle(item, line_i)"
                  :style="styleObjectColumn(dashboardItem, line_i)"
                  nowrap
                >
                  <router-link :to="itemLink(item.id)">
                    {{ item['col' + (line_i-1) + '_show'] }}
                  </router-link>
                </td>
                <td class="one_td">
                  <a :href="itemLink(item.id)">
                    <div
                      class="one_date"
                      nowrap
                    >
                      <div class="float_right">{{ item.updated_at_show }}</div>
                      <div class="float_right">
                        <v-icon
                          class="one_date_icon"
                          color="grey lighten-1"
                          x-small
                        >
                          fa-clock
                        </v-icon>
                      </div>
                    </div>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
        <v-col
          v-if="dashboardItem.previewData && dashboardItem.previewData.items.length === 0"
          cols="12"
          class="no_objects"
        >
          {{ $i18n.t('aava.general.no_objects') }}
        </v-col>
      </v-row>
    </transition>
  </v-container>
</template>

<script>
import sharedUtilities from '@/utilities/sharedUtilities'
import previewListData from '@/store/dashboardItems/helpers/previewListData'
import dashboardConfig from '@/components/Dashboard/dashboardConfig'

export default {
  name: 'DashboardPreviewList',

  props: {
    dashboardItem: {
      default () { return {} },
      type: Object,
    },
    spaceAllocation: {
      default () { return {} },
      type: Object,
    },
    spaceForColumns: {
      default () { return [] },
      type: Array,
    },
  },

  computed: {
    refreshing () {
      return this.$store.state.dashboardItemsRefreshing
    },

    modelName () {
      return sharedUtilities.objectClassUnderscoredName(this.dashboardItem.object_type)
    },

    layoutProfileId () {
      return this.dashboardItem.layout_profile.id
    },

    layoutProfileLink () {
      return this.modelName + '?_lp=' + this.layoutProfileId
    },
  },

  methods: {
    filterMaxVisibleItems (items) {
      return items.filter((item, i) => {
        if (i < this.spaceAllocation.max_items) {
          return item
        }
        return false
      })
    },

    itemLink (itemId) {
      return this.modelName + '/' + itemId
    },

    showItemValue (value) {
      return sharedUtilities.removeHTMLTagsFromString(value)
    },

    composeObjectTooltip (dashboardItem, line_i, item) {
      const field = this.$i18n.t(
        this.modelName + '.attributes.' + dashboardItem.previewData.column_order[line_i - 1]
      )
      const value = item['col' + (line_i - 1) + '_show']
      return field + ': ' + value
    },

    styleObjectColumn (dashboardItem, line_i) {
      if (this.spaceForColumns) {
        return {
          maxWidth: this.spaceForColumns[dashboardItem.previewData.column_order[line_i - 1] + '_px'] + 'px',
          width: this.spaceForColumns[dashboardItem.previewData.column_order[line_i - 1] + '_px'] + 'px'
        }
      } else {
        return { width: '10px' }
      }
    },

    styleSummary (progress_state_visible) {
      if (progress_state_visible) {
        return {
          maxWidth: (this.spaceAllocation.box_width - dashboardConfig.PROGRESS_STATE_CELL_MIN_PX) + 'px'
        }
      }
      return {
        maxWidth: this.spaceAllocation.box_width + 'px'
      }
    },

    styleObjectState () {
      return { minWidth: dashboardConfig.PROGRESS_STATE_CELL_MIN_PX + 'px' }
    },

    itemCellStyle (item, line_i) {
      return (sharedUtilities.isDecimalType(item['col' + (line_i - 1) + '_type']) ? ' text_right' : '')
    }
  }
}
</script>

<style scoped>
  .dashboard_table {
    width: 100%;
    empty-cells: show;
    border-collapse: collapse;
  }
  .dashboard_table tr td {
    padding: 0 7px 0 0;
    border-top: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .spacer {
    position: relative;
    height: 16px;
    width: 100%;
    overflow: hidden;
  }

  .spacer_line {
    position: relative;
    top: 8px;
    left: 0;
    height: 11px;
    width: 100%;
    border-top: 1px solid rgb(220, 220, 220);
  }

  .one_preview {
    margin: 0;
    border-top: none;
  }

  .preview_container {
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .preview_header {
    margin: 0 0 15px 0;
    width: 100%;
    position: relative;
    height: 50px;
    overflow: hidden;
    border-bottom:2px solid rgb(220, 220, 220);
  }

  .preview_title {
    font-weight: 600;
    float: left;
    line-height: 20px;
    padding: 0;
    font-size: 17px;
    color: #5e5d5d;
  }

  .preview_model_name {
    font-size: 12px;
    padding: 0;
    line-height: 20px;
    color: #adadad;
  }

  .one_summary {
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
  }

  .one_date {
    float: right;
  }

  .one_date_icon {
    margin: 0 3px;
    padding: 1px;
  }

  .one_status {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 96px;
    float: right;
    border-radius: 10px;
    color: green;
    padding:1px 8px;
  }

  .no_objects {
    font-size: 20px;
    text-align: center;
    padding: 40px 0 0 0;
    margin: 0;
    color: gray;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .preview_count {
    position: relative;
    left: 0;
    top: -2px;
    float: left;
    background-color: #969696;
    border-radius: 15px;
    margin:0 10px 0 10px;
    padding: 2px 7px;
    color:white;
    font-weight:700;
    font-size:13px;
  }

  .float_left {
    float:left;
  }

  .float_right {
    float:right;
  }

  .is_link {
    cursor: pointer;
  }

  .text_right {
    text-align: right;
  }
</style>
