
import itemMethods from '@/methods/item/itemMethods'
import sharedUtilities from '@/utilities/sharedUtilities'
import listItemMethods from '@/methods/listItem/listItemMethods'
import itemFormMethods from '@/components/Item/itemFormMethods'
import { createHelpers } from 'vuex-map-fields'
import listCellEditMethods from '@/methods/listItem/listCellEditMethods'
import hasManyItemMethods from '@/components/Item/hasManyItemMethods'
import { HasManyActionButtonStyle, HasManyActionButtonType, Types } from '@/types/AppTypes'
import { HasManyExtendedField, LP } from '@/types/LP.types'
import HasManyItemActionBtn from '@/components/Item/HasManyItemActionBtn.vue'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'HasManyItem',

  components: {
    HasManyItemActionBtn,
    FormField: () => import('./FormField.vue'),
  },

  props: {
    parentField: {
      type: Object as () => LP.Item,
      required: true,
    },
    parentItem: {
      type: Object as () => Types.Item,
      required: true,
    },
    parentResource: {
      type: String,
      default: '',
    },
    fields: {
      type: Array as () => HasManyExtendedField[],
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {},
    },
    amc: {
      type: Object as () => LP.AMC,
      required: true,
    },
    parentItemWithoutDynamicFields: { // for item picker targetObject
      type: Object,
      default: () => {},
    },
    listQueries: {
      type: Array,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    resource: {
      type: String,
      default: '',
    },
    layoutEditMode: {
      type: Boolean,
      default: false,
    },
    updateHasManyParentItemOnDefaultsForChange: {
      type: Function,
      default: () => {},
    },
    showItemPickerFor: {
      type: Function,
      default: () => {},
    },
    setHasManyItemInList: {
      type: Function,
      default: () => {},
    },
    deleteCallback: {
      type: Function,
      default: () => {},
    },
    stateChangeCallback: {
      type: Function,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    fixedWithsByItemDesigner: {
      type: Object,
      default: () => {},
    },
    showItemModal: {
      type: Function,
      default: () => {},
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      itemCopy: {},
      debug: false,
      showOverflowingTextOnMouseover: [], // Array - true/false for each item field
      itemPickerJustClosed: false, // Do not add new row on enter when item picker was just closed
    }
  },

  computed: {
    ...mapFields(['showItemPicker']),

    actionButtons (): HasManyActionButtonType[] {
      if (this.edit) { return [this.parentField?.remove_type === 'unlink' ? 'unlink' : 'delete'] }
      return [
        ...this.parentField.action_buttons,
      ].filter(val => !!val)
    },

    readyForEditMode () {
      // When switching from show view, has-many items are already present
      // List is not emptied, to avoid form jumping/flashing
      // But have to wait for has-many list request to complete and set targetObject.token
      // Example issue otherwise is using item-picker with parent item id, when token is not yet available
      return !!this.item.targetObject?.token
    },

    buttonStyles (): Record<HasManyActionButtonType, HasManyActionButtonStyle> {
      const pathForEdit = this.generateReferenceLinkInSplitMode({
        className: this.item['@class'],
        id: this.item.id,
        view: 'edit',
      })
      const pathForShow = this.generateReferenceLinkInSplitMode({
        className: this.item['@class'],
        id: this.item.id,
        view: 'show',
      })
      return {
        show: {
          icon: 'fa-newspaper test-show-btn',
          color: 'green',
          path: pathForShow,
        },
        edit: {
          icon: 'fa-edit test-edit-btn',
          color: 'orange',
          disabled: !this.item['@editable'],
          path: pathForEdit,
        },
        delete: {
          icon: 'fa-times test-delete-btn',
          color: 'red',
          disabled: !this.item['@editable'],
        },
        unlink: {
          icon: 'fa-unlink test-unlink-btn',
          color: 'red',
          disabled: false,
        },
        edit_in_modal: {
          icon: 'fa-regular fa-file-lines',
          color: 'grey',
        },
      }
    },

    actionButtonsStyle () {
      const width = 12 + this.actionButtons.length * 30
      return {
        width: width + 'px',
        maxWidth: width + 'px',
      }
    },

    splitProps () {
      return this.$route.params
    },

    hasManyItemLink () {
      return this.generateReferenceLinkInSplitMode({
        className: this.resource,
        id: this.item.id,
      })
    },

    itemDataHasChange () {
      return JSON.stringify(this.item) !== JSON.stringify(this.itemCopy)
    },
  },

  watch: {
    showItemPicker (value) {
      if (!value) {
        this.itemPickerJustClosed = true
        setTimeout(() => {
          this.itemPickerJustClosed = false
        }, 300)
      }
    },
  },

  created () {
    this.setItemCopy(this.item)
  },

  methods: {
    ...itemMethods,
    ...listItemMethods,
    ...itemFormMethods,
    ...listCellEditMethods,
    ...hasManyItemMethods,

    setItemCopy (item: Types.Item) {
      this.itemCopy = JSON.parse(JSON.stringify(item || {})) as Types.Item
    },

    updateDFCFunction () {
      return this.edit
        ? this.getHasManyItemDfcChanges
        : () => {
            // console.log('Skip DFC on has-many edit in show mode')
          }
    },

    editComputed (field: HasManyExtendedField) {
      return this.edit || this.isEditableInShowView(field)
    },

    isEditableInShowView (field: HasManyExtendedField) {
      return this.$store.state.listEdit.editing &&
        this.isSupportedListEditField(field) &&
        this.item['@editable'] &&
        field.editable_in_list &&
        this.item['@editable_members']?.includes(field.name)
    },

    readOnlyChecked (field: HasManyExtendedField) {
      // Allow using event buttons even if has-many row is read only
      // There are other ways how this can be disabled
      if (field.type === 'process_events') {
        return false
      }
      return this.readOnly
    },

    // For regular text field create a link for the displayed text
    // Used to be createLinkToRowItem and did make a link of the parent div (was router-link instead)
    // Now same logic is inside ShowViewText, so user can copy-paste the value
    // Aava-Vue-615
    createLinkToShowViewText (field: HasManyExtendedField) {
      const noLinkFields = [
        'file',
        'image',
        'process_events',
        // 'reference',
        'polymorphic_autocomplete',
        'process',
        'has_many_reference',
      ]
      return field.type && !this.edit && !noLinkFields.includes(field.type) && !this.layoutEditMode
    },

    changeRowInHasMany ({ e, colIndex }) {
      if (!['ArrowDown', 'ArrowUp'].includes(e.key)) { return }
      this.$emit('moveFocusTo', {
        rowIndex: e.key === 'ArrowUp' ? this.index - 1 : this.index + 1,
        colIndex,
      })
    },

    keyPressHandler (e: KeyboardEvent) {
      // Also focus row, just in case when user moved to another row somehow without using the mouse
      this.$emit('setFocusedRowIndex', this.index)
      // When enter was pressed in any of the text-fields for existing has-many item > add new row
      // Ignore if item picker was just closed (in case of dynamic_list inside has-many). No other good way to trak this as item picker is closed on key-down event
      // and focus is still on the TextField input.
      if (e.key === 'Enter' && !this.itemPickerJustClosed && this.edit) {
        // Actually calls buttonClick, two benefits:
        // - shows loading button, as user would have clicked the button
        // - does wait for DFC requests to finish
        this.$emit('addNewNestedRow', {})
      }
    },

    // Detect if text does not fit, then allow overflowing text tooltip
    measureWidth (i: number) {
      const el = this.$refs['td_' + i]?.[0]
      const valueEl = el?.querySelector('.item-show-value')
      if (!el || !valueEl) { return }
      this.$set(this.showOverflowingTextOnMouseover, i, valueEl.offsetWidth < valueEl.scrollWidth)
    },

    hideTooltip (i: number) {
      this.$set(this.showOverflowingTextOnMouseover, i, false)
    },

    classesForTD (field: HasManyExtendedField) {
      const hasFixedWidth = field.inputOptions?.width || this.fixedWithsByItemDesigner[field.name]
      return [
        'has-many-item-column',
        hasFixedWidth && 'has-fixed-width',
      ]
    },

    colStyle (field: HasManyExtendedField) {
      const style = {} as CSSStyleDeclaration
      const minWidthByFieldType = {
        string: 100,
        search_string: 100,
        text: 120,
        quantity: 90,
        reference: 100,
        polymorphic_autocomplete: 100,
        numeric: 80,
        decimal: 80,
        price: 120,
        percentage: 80,
        static_list: 110,
        dynamic_list: 110,
        boolean: 80,
        action: 80,
        event: 80,
        process_events: 80,
        state: 80,
        file: 80,
        image: 80,
        date: 110,
        datetime: 140,
        has_many_reference: 140,
      }
      if (!minWidthByFieldType[field.type] && field.type) {
        console.error('SET minWidthByFieldType for ' + field.name, field.type, field)
      }
      let minWidth = minWidthByFieldType[field.type] || 150
      if (this.edit) {
        // A little extra space in edit mode, for icons etc
        minWidth += 20
      }
      // In show view event actions are hidden
      if (field.type === 'process_events' && this.edit) {
        return {}
      }
      const extraPadding = this.$store.state.systemConfigs.disable_extra_padding_for_has_many_column_fixed_widths_in_vue_portal
        ? this.edit ? 30 : 20 // Still need some space which is used in edit+show view padding
        : this.edit ? 50 : 35
      let minWidthPx = field.inputOptions?.width
        ? (parseInt(field.inputOptions?.width) + extraPadding) + 'px' // Extra space compared to old UI
        : (minWidth + 'px')

      if (this.fixedWithsByItemDesigner[field.name]) {
        minWidthPx = this.fixedWithsByItemDesigner[field.name] + 'px'
      }
      style.minWidth = minWidthPx
      // When attribute input options width set, use also as max-width and width to force it
      if (field.inputOptions?.width || this.fixedWithsByItemDesigner[field.name]) {
        style.maxWidth = minWidthPx
        style.width = minWidthPx
      }
      return style
    },

    callHasManyRowButtonAction (button: HasManyActionButtonType, event: MouseEvent) {
      // 1. Stop simple click propagation (!) to not use href
      // because need to handle programmatically
      // in case have to open in split mode
      if (!this.modal) { // Not in modal, there show/edit opens url in new tab, using regular link
        this.checkAndStopSimpleMouseClick(event)
      }
      // 2. Do not continue if not a simple click
      // because user is opening in new tab or new window
      if (!this.isSimpleMouseClick(event)) {
        return
      }
      if (this.openHasManyItemInSplitView(button)) {
        event.stopPropagation()
        event.preventDefault()
        return
      }
      switch (button) {
        case 'edit_in_modal':
          this.openHasManyEditInDialog()
          break
        case 'show':
        case 'edit':
          if (!this.modal) {
            // In modal view regular link is used to open in new tab
            this.$router.push({ path: this.buttonStyles[button]?.path })
          }
          break
        case 'unlink':
        case 'delete':
          this.deleteCallback(this.index)
          break
      }
    },

    rowClickHandler () {
      this.$emit('setFocusedRowIndex', this.index)
    },

    openHasManyEditInDialog () {
      this.showItemModal({
        show: true,
        id: this.item.id,
        edit: true,
        resource: sharedUtilities.objectClassUnderscoredName(this.item['@class']),
        showLoader: true,
        saveCallback: this.updateHasManyList,
        closeCallback: this.closeModalRow,
        layoutProfileContext: 'dialog',
      })
    },

    updateHasManyList () {
      this.$emit('closeModalAndReloadList')
    },

    closeModalRow () {
      this.showItemModal({
        show: false,
      })
    },

    openHasManyItemInSplitView (view) {
      if (!this.$store.state.splitMode || !['show', 'edit'].includes(view)) {
        return false
      }
      const path = this.generateReferenceLinkInSplitMode({
        className: this.item['@class'],
        id: this.item.id,
        view,
      })
      this.$router.push({ path })
    },

    // TODO - delete when tested
    openHasManyItemInSplitViewOLD (button) {
      // TODO. use splitProps? hashParts bad practice
      // console.warn('TODO refactor openHasManyItemInSplitView', button)
      if (!this.$store.state.splitMode || !['show', 'edit'].includes(button)) {
        return false
      }
      // Alternate split mode (where list and item models are different)?
      const hashParts = window.location.hash.split('/')
      const isAlternateSplitView = hashParts[2] === 'split'
      const secondResource = hashParts[6]
      if (isAlternateSplitView && secondResource) {
        if (secondResource) {
          this.$router.push({
            path: '/' + this.$store.state.objectClass + '/split/' +
              hashParts[3] + '/' +
              hashParts[4] + '/' +
              hashParts[5] + '/' +
              sharedUtilities.objectClassUnderscoredName(this.item['@class']) + '/' +
              this.item.id + '/' + button
          })
        }
      } else {
        this.$router.push({
          path: '/' + this.$store.state.objectClass + '/split/' +
            sharedUtilities.objectClassUnderscoredName(this.item['@class']) + '/' +
            this.item.id + '/' + button
        })
      }
      return true
    },
  }
}
