
import util from '../../utilities/sharedUtilities'
import pivotTableDataProcessor from './pivotTableDataProcessor'
import pivotTableHelpers from './pivotTableHelpers'
import Confirm from '@/methods/confirm'
import listFilters from './../../store/_listFilters'
import listQueries from '@/store/_listQueries'
import listViewAPI from '@/store/api'
import ListHiddenFilters from './../ListHeaderActions/ListHiddenFilters.vue'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import sharedComputed from '@/sharedComputed'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'PivotTableView',

  components: {
    ListHiddenFilters
  },

  data () {
    return {
      pivotData: [],
      render: false,
      items: [],
      count: null,
      loading: false,
      saving: false,
      refreshing: false,
      pagesLoaded: 0,
      loadingData: false,
      loadProgress: 0,
      cancelling: false,
      cols: [],
      rows: [],
      vals: [],
      aggregator: null,
      initialized: false,
      translationTable: {},
      generationTime: ''

    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
    ...sharedComputed,

    hasFilters () {
      return this.layoutProfileItems.filter(field => this.listFilters[field.name]).length > 0
    },

    buttonLoading () {
      return this.saving || this.refreshing || this.cancelling || this.loadingData
    },

    buttonDisabled () {
      return this.saving || this.refreshing || this.cancelling ||
        this.loadingData || this.count === 0
    },

    layoutProfileItemsByName () {
      return this.$store.getters.layoutProfileItemsByName
    },

    pageSize () {
      return pivotTableHelpers.pageSize(this.count) || 7
    },

    pages () {
      return Math.ceil(this.count / this.pageSize)
    },

    queries () {
      return [...listQueries.get(this.layoutProfileItems, this.selectedLayoutProfile, {
        pivotTableFields: [...this.cols, this.rows],
      })]
    },

    filters () {
      let filters = [...listFilters.getSearchFilters(this.$store.state)]
      if (this.listTargetAssoc) {
        filters = [...filters,
          { key: '_target_resources', value: this.listTargetResources },
          { key: '_target_id_or_token', value: this.listTargetId },
          { key: '_target_member', value: this.listTargetAssoc }
        ]
      }
      return filters
    },

    derivedAttributes () {
      return pivotTableDataProcessor
        ._derivedAttributes(this.objectClass, this.layoutProfileItems)
    },

    translationTables () {
      return pivotTableDataProcessor
        ._translationTables(this.objectClass, this.layoutProfileItems)
    },
    hiddenFields () {
      return this.layoutProfileItems.filter(field => {
        return this.listFilters[field.name] && (!field.visible || this.layoutProfileView !== 'list')
      })
    },

    colsComputed () {
      return this.localizeColumnNames(this.cols || [])
    },

    rowsComputed () {
      return this.localizeColumnNames(this.rows || [])
    },

    valsComputed () {
      return this.localizeColumnNames(this.vals || [])
    },

    aggregatorName () {
      return (this.aggregator && this.aggregator.trim()) || 'Count'
    },

    sorters () {
      return pivotTableHelpers._sorter
    },

    hiddenFromDragDrop () {
      return pivotTableDataProcessor._hiddenFromDragDropAttributes(
        this.objectClass, this.layoutProfileItems
      )
    },
    hiddenFromAggregators () {
      return pivotTableDataProcessor._hiddenFromAggregatorsAttributes(
        this.objectClass, this.layoutProfileItems
      )
    },
  },

  watch: {
    selectedLayoutProfileId (value) {
      if (!value) { return }
      this.initialize()
    },
  },

  created () {
    this.initialize()
  },

  mounted () {
    window.addEventListener('beforeprint', this.beforePrintFilter)
    window.addEventListener('afterprint', this.afterPrintFilter)
  },

  beforeDestroy () {
    window.removeEventListener('beforeprint', this.beforePrintFilter)
    window.removeEventListener('afterprint', this.afterPrintFilter)
  },

  methods: {
    ...methods,

    beforePrintFilter () {
      console.log('beforeprint on')
      document.querySelector('body')!.style.visibility = 'hidden'
    },

    afterPrintFilter () {
      console.log('afterprint on')
      document.querySelector('body')!.style.visibility = 'visible'
    },

    printContent () {
      document.querySelector('body')!.style.visibility = 'hidden'
      window.print()
      document.querySelector('body')!.style.visibility = 'visible'
    },

    hiddenFieldValue (fieldName) {
      if (fieldName === 'main_object_state') {
        return this.$store.getters.listFiltersGetStateDisplayValue
      }
      return this.listFilters[fieldName]
    },

    resetData () {
      this.items = []
      this.count = null
    },

    // pivotTable uses only localized column names. For language
    // independent configuration in timeline_configuration we need
    // generic names. Column name can be an attribute or a name derived
    // from attribute. Same configuration can be used by user's with
    // different locale, in case of shared views. Gets difficult...
    // To enable all this we maintain a two-way mapping between generic
    // and localized names. Names are localized when options are read
    // from the configuration and generalized when they are written back
    localizeColumnNames (names) {
      return names.map(name => this.localizeColumnName(name))
    },

    localizeColumnName (name) {
      return this.translationTable.localizations[name] || name
    },

    generalizeColumnNames (names) {
      return names.map(name => this.generalizeColumnName(name))
    },

    generalizeColumnName (name) {
      return this.translationTable.generalizations[name] || name
    },

    configChanged (config) {
      this.saveConfig(config)
    },

    initPivot () {
      // const renderers = $.extend($.pivotUtilities.renderers,
      //   $.pivotUtilities.d3_renderers,
      //   $.pivotUtilities.c3_regnderers,
      //   $.pivotUtilities.plotly_renderers,
      //   $.pivotUtilities.export_renderers)

      // See https://pivottable.js.org/examples/onrefresh.html on how to set
      // the options
      this.$nextTick(() => {
        this.$store.dispatch('getAttributeMetadata', this.objectClass).then(amc => {
          pivotTableDataProcessor.pivotData(this.objectClass, this.items, this.layoutProfileItems, amc)
            .then(pivotData => {
              this.pivotData = pivotData
              this.$nextTick(() => {
                this.render = true
              })
              // $('.pivottable-output').pivot(pivotData, options)
            })
        })
      })
    },

    initialize () {
      this.initialized = false
      this.resetPivotTableData()
      this.$store.dispatch('loadListLayoutProfileItems').then(() => {
        if (this.selectedLayoutProfile.timelineConfiguration?.pivot_configuration) {
          this.setDataFromConfig()
        }
        this.translationTable = this.translationTables
        this.loadPivotTableCount().then(() => { this.initialized = true })
      })
    },

    setDataFromConfig () {
      const config = this.selectedLayoutProfile.timelineConfiguration.pivot_configuration
      if (!config) { return }
      this.cols = config.cols || []
      this.rows = config.rows || []
      this.vals = config.vals || []
      this.aggregator = config.aggregator && typeof config.aggregator === 'string'
        ? config.aggregator
        : ''
    },

    loadPivotTableCount () {
      return new Promise(resolve => {
        listViewAPI.fetchListItems(this.objectClass, this.filters, [], { count: true }).then((result: AxiosResponse) => {
          this.count = result.data.count
          resolve(true)
        })
      })
    },

    loadPivotTableItems () {
      const maxItems = (this.systemConfigs?.breakdownWarnLimit) || 10000
      if (this.count > maxItems) {
        Confirm.request(this.$i18n.t('aava.confirmations.big_pivot'), () => {
          this.startPivotTableItemsLoad()
        })
      } else {
        this.startPivotTableItemsLoad()
      }
    },

    startPivotTableItemsLoad () {
      this.generationTime = util.currentDateTime(this.locale)
      this.loadingData = true
      this.loadProgress = 1
      this.loadPivotTableItemsOnPages({ pageNo: 1 })
    },

    resetPivotTableData () {
      this.cols = []
      this.rows = []
      this.vals = []
      this.aggregator = null
      this.loadProgress = 0
    },

    cancelPivotProgress () {
      this.loadProgress = 0
    },

    loadPivotTableItemsOnPages ({ pageNo }) {
      if (this.cancelling) {
        this.cancelling = false
        this.cancelPivotProgress()
        return
      }
      // User filters set in list view and add offset + limit
      const filters = [...this.filters,
        { key: 'offset', value: (pageNo * this.pageSize) - (this.pageSize) },
        { key: 'limit', value: this.pageSize }
      ]

      const targetParameters: any = {}
      if (this.listTargetAssoc) {
        targetParameters.forClass = this.listTargetResources
        targetParameters.forId = this.listTargetId
        targetParameters.forField = this.listTargetAssoc
      }
      listViewAPI.fetchListItems(this.objectClass, filters, this.queries, targetParameters)
        .then((result: AxiosResponse) => {
          this.loadProgress = 1 + (99 / this.pages) * pageNo
          // Merge page data
          this.items = [...this.items, ...result.data.items]
          if (pageNo < this.pages) {
            // Load  next page
            this.loadPivotTableItemsOnPages({ pageNo: pageNo + 1 })
          } else {
            // Set all loaded (load progress percent = 100)
            this.loadProgress = 100
            setTimeout(() => {
              // Give user slight glance to see loader bar 100%
              this.loadingData = false
              this.initPivot()
            }, 300)
          }
        })
    },

    saveConfig (config) {
      this.saving = true
      this.$store.dispatch('savePivotTableConfig', {
        config: {
          cols: this.generalizeColumnNames(config.cols),
          rows: this.generalizeColumnNames(config.rows),
          vals: this.generalizeColumnNames(config.vals),
          aggregator: config.aggregatorName
        }
      }).then(() => {
        this.saving = false
      })
    },

    requestCancel () {
      this.loadingData = false
      this.cancelling = true
      this.cancelPivotProgress()
    },

    loadPivotTable () {
      this.saveConfig({
        cols: this.cols,
        rows: this.rows,
        vals: this.vals,
        aggregatorName: this.aggregator,
      })
      this.resetData()
      this.loadPivotTableCount().then(() => {
        this.loadPivotTableItems()
      })
    },
  },
}
