<template>
  <div class="list-view">
    <header-column-search-action-buttons />
    <MultiEditMenu v-if="multiEditColumn" />

    <template v-if="!loadingList">
      <ListActionsToolbar v-if="!suppressToolbar" />
      <div
        id="vueList"
        ref="vueList"
        class="vue-list-table-region"
        :style="vueListStyle"
        @mouseleave="hoverCell = ''"
      >
        <div class="list-table-container no-text-select">
          <ListHeaderColumnsRow />
          <ListContent />
        </div>
      </div>
    </template>
    <div
      v-else
      :style="loaderContainerStyle"
      class="content-center"
    >
      <div class="full-height-content-center">
        <AavaLoader
          :color="loaderShowComplete ? '#C8E6C9' : '#FFCC80'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import methods from './methods'
import util from '../utilities/sharedUtilities'
import AavaLoader from './AavaLoader'
import ListContent from './ListContent/_ListContent'
import MultiEditMenu from './MultiEdit/MultiEditMenu'
import ListHeaderColumnsRow from './ListHeaderColumns/_ListHeaderColumnsRow'
import ListActionsToolbar from './ListHeaderActions/ListActionsToolbar'
import HeaderColumnSearchActionButtons from './ListHeaderColumns/HeaderColumnSearchActionButtons'
import { createHelpers } from 'vuex-map-fields'
import state from './../store/state'
import sharedComputed from '@/sharedComputed'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

const delayForUpdateOnResize = 300
const delayForColumnResizeAction = 300
const scrollDelayForLoadingMoreItems = 200
const horizontalScrollDelayForRendering = 20
const overflowFullTextShowDelay = 800
const delayForUpdateOnSplitModeChange = 500

export default {
  name: 'ListView',

  components: {
    ListContent,
    ListHeaderColumnsRow,
    ListActionsToolbar,
    HeaderColumnSearchActionButtons,
    MultiEditMenu,
    AavaLoader,
  },

  props: {
    model: {
      default () { return {} },
      type: Object,
    },
  },

  data () {
    return {
      loadItemsTimer: null,
      resetOverflowingTextTimer: null,
      fixedScrollTimer: null,
      previousHoverField: null,
      resizeTimer: null,
      resizeColumnTimer: null,
      splitModeChangeTimer: null,
      scrollEventsCount: null,
    }
  },

  computed: {
    ...sharedComputed,
    ...mapFields(Object.keys(state)),

    columnsToRender () {
      return this.getColumnsToRender()
    },

    vueListStyle () {
      return {
        marginTop: this.suppressToolbar ? '5px' : '42px',
        height: this.listHeight + 'px',
      }
    },

    loaderContainerStyle () {
      return {
        height: this.listHeight + 'px',
      }
    },

    // computedLeftScroll () {
    //   const el = document.getElementById('vueList')
    //   return el && el.scrollLeft || 0
    // },

    // listAreaHeightPx () {
    //   return this.listTotalCount * (this.rowHeight + this.rowPadding)
    // },

    initializeTrigger () {
      return this.selectedLayoutProfileId + ',' + this.listForItemId
    },

    backToListViewTrigger () {
      // Return false when list is hidden for the below routes
      if ([
        'List_ItemShow',
        'List_SplitModeTwoItems',
        'List_SplitModeOneItemLeft',
        'List_forTargetWithForm',
        'List_forTargetWithFormForAnotherResource',
        'List_ItemForTarget',
        'List_ItemAction',
      ].includes(this.$route.name)) {
        return false
      }
      return this.$route.name.includes('List_')
    },
  },

  watch: {
    focusedField (value) {
      if (value) {
        this.focusedFieldFilterValue = this.listFilters[value]
      }
    },

    initializeTrigger (value, before) {
      // initializeTrigger is created because two properties must be watched together
      // not to trigger initialize twice
      const selectedLayoutProfileId = value.split(',')[0]
      if (selectedLayoutProfileId === 'null') {
        return
      }
      this.initialize()
    },

    backToListViewTrigger () {
      // Update cell widths when user returning from item view
      // Because on item view list-view is in DOM but with display: none
      // therefore can't read table cell content to adjust widths on resize event

      // Note 5-JUN-23 Must still re-calculate in split mode as view area may have been changed
      // if (this.splitMode) { return } // Skip for split mode as list is already visible
      this.adjustCellWidthsAndLineHeight()
    },

    // listForItemId () {
    // this.initialize()
    // },

    // selectedLayoutProfileId (value) {
    //  if (!value) { return }
    // this.initialize()
    // },

    // Set overflowing text indicators when new load progress is processed
    totalLoadRequestsProcessed () {
      this.$nextTick(() => {
        this.setOverflowingTextTimer()
      })
    },

    hoverCell (value) {
      setTimeout(() => {
        if (value && value === this.hoverCell && this.overflowingCells.includes(this.hoverCell)) {
          this.showContentFullTextMenu = true
          const topOffset = 0
          const leftOffset = 15
          this.$nextTick(() => {
            util.positionPopupMenu({
              clientX: this.clientX,
              clientY: this.clientY
            }, 'content-full-text-menu', leftOffset, topOffset, {})
          })
        }
      }, overflowFullTextShowDelay)
    },

    scrolledTop (scrolledTop, prevScrollTop) {
      this.scrollingDirection = prevScrollTop < scrolledTop || scrolledTop === 0
        ? 'down'
        : 'up'
      this.$store.commit('hideMenuPopups')
      this.loadListItemsOnScroll()
    },

    // Watch scrolledLeft to update which columns should be rendered to the list
    scrolledLeft () {
      this.$store.commit('hideMenuPopups')
      clearTimeout(this.fixedScrollTimer)
      // Set fixed scrolled left
      this.fixedScrollTimer = setTimeout(() => {
        // We use fixedScrolledLeft which is updated by timer
        // Not using scrolledLeft as this is updated on every scroll event trigger
        // and we don't want to update render data so often
        this.fixedScrolledLeft = this.scrolledLeft

        this.listOffsetLeft = Object.keys(this.visibleColumnsByName)
          .filter(
            fieldName => !this.columnsToRender.includes(fieldName) &&
              this.visibleColumnsByName[fieldName].leftOffset < this.fixedScrolledLeft
          )
          .slice(-1)
          .map(
            fieldName => {
              const fixedWidth = this.visibleColumnsByName[fieldName].userFixedWidth || this.visibleColumnsByName[fieldName].forcedWidth
              return this.visibleColumnsByName[fieldName].leftOffset + fixedWidth
            })[0] || 0
        this.$store.dispatch('updateCellPool', this.getColumnsToRender())
        this.setOverflowingTextTimer()
      }, horizontalScrollDelayForRendering)
    },

    splitModeAltered (value) {
      if (!value) { return }
      clearTimeout(this.splitModeChangeTimer)
      this.splitModeChangeTimer = setTimeout(() => {
        this.callScreenChangeMethods()
        this.$store.state.resizeTrigger++
      }, delayForUpdateOnSplitModeChange)
    }
  },

  created () {
    document.addEventListener('click', this.hideHeaderMenu)
    document.addEventListener('keyup', this.listenKeyUp)
    window.addEventListener('resize', this.handleResize)
    document.addEventListener('mousemove', this.mouseMoveListener)
    this.$store.dispatch('getLayoutProfileFromUri')
    // Set delay to load item data before list items
    // when user opened the app to item show/edit view
    const initializeDelay = this.$route.name.includes('List_Item') ? 1000 : 0
    setTimeout(() => {
      this.initialize()
    }, initializeDelay)
  },

  destroyed () {
    document.removeEventListener('click', this.hideHeaderMenu)
    document.removeEventListener('keyup', this.listenKeyUp)
    window.removeEventListener('resize', this.handleResize)
    document.removeEventListener('mousemove', this.mouseMoveListener)
  },

  methods: {
    ...methods,

    // Set the delay for triggering list load for following "virtual" pages
    // Problem: user can scroll very fast (almost coninuously) so that there
    // is no 100ms delay between scroll events.
    // Solution: we count how many scroll events have passed, if quite many
    // then set delay to be zero, which triggers advanced load immediatelly
    loadListItemsOnScroll () {
      const listLoadDelay = this.scrollEventsCount > 50
        ? 0
        : scrollDelayForLoadingMoreItems
      clearTimeout(this.loadItemsTimer)
      this.loadItemsTimer = setTimeout(() => {
        this.scrollEventsCount = 0
        if (this.scrolledTop !== 0) {
          this.$store.dispatch('listLoadItemsInAdvance', state.listLoadHash).then(() => {
            this.setOverflowingTextTimer()
          })
        } else {
          // No items to load but overflowing text indicators must still be set
          this.setOverflowingTextTimer()
        }
      }, listLoadDelay)
      this.$store.dispatch('setListVisibleItems', {})
      this.scrollEventsCount++
    },

    initialize () {
      if (!this.selectedLayoutProfileId) { return }
      this.openLayoutProfileList().then(() => {
        this.setOverflowingTextTimer()
        // Scroll back to the top, previous model may have been open and vueList remains it's scroll position
        // as component is not destroyed.
        const el = document.getElementById('vueList')
        if (el) {
          el.scrollTop = 0
          el.scrollLeft = 0
        }
      })
    },

    listenKeyUp (e) {
      if (e.target.localName !== 'body') {
        return
      }
      switch (e.key) {
        case 'Escape':
          if (!this.multiEditColumn) {
            this.escapeKeyHandler()
          }
          break
        case 'Enter':
          if (this.selectedItems.length === 1) {
            this.listItemOpenSelected()
          }
          break
        case 'ArrowDown':
          this.listSelectNext(e)
          break
        case 'ArrowUp':
          this.listSelectPrevious(e)
          break
      }
    },

    handleResize () {
      clearTimeout(this.resizeTimer)
      this.resizeTimer = setTimeout(() => {
        this.adjustCellWidthsAndLineHeight()
        this.callScreenChangeMethods()
      }, delayForUpdateOnResize)
    },

    listSelectNext (e) {
      if (e.shiftKey) {
        this.$store.dispatch('toggleAreaExpandByOne', this.lastSelectedIndex + 1)
      } else {
        this.$store.dispatch('selectNextOrPreviousItem', 'next')
      }
    },

    listSelectPrevious (e) {
      if (e.shiftKey) {
        this.$store.dispatch('toggleAreaExpandByOne', this.lastSelectedIndex - 1)
      } else {
        this.$store.dispatch('selectNextOrPreviousItem', 'previous')
      }
    },

    callScreenChangeMethods () {
      this.updatePagesOnScreen()
    },

    updatePagesOnScreen () {
      const itemMinHeight = 29
      const maxPagesOnScreen = 7
      const pagesOnScreen = Math.ceil(this.listHeight / (itemMinHeight * this.itemsOnPage))
      this.pagesOnScreen = pagesOnScreen > 1 && pagesOnScreen < maxPagesOnScreen
        ? pagesOnScreen
        : this.pagesOnScreen
    },

    setNewColumnWidthOnResize (e) {
      const minWidth = 58
      let newWidth = e.clientX - this.mouseDownCoords.x + this.resizingFieldWidthBefore
      if (newWidth < minWidth) {
        newWidth = minWidth
      }
      this.$store.dispatch('setListFieldUserFixedWidth', {
        fieldName: this.resizingFieldName,
        width: newWidth
      })

      clearTimeout(this.resizeColumnTimer)
      this.resizeColumnTimer = setTimeout(() => {
        this.adjustCellWidthsAndLineHeight()
        // this.callScreenChangeMethods()
      }, delayForColumnResizeAction)
    },

    // Listen mouse movement to detect dragging
    // to start selecting items. Or to re-sort list columns.
    // TODO - refactor. Ex readable conditions as constants
    mouseMoveListener (e) {
      this.clientX = e.clientX
      this.clientY = e.clientY
      if (this.draggingFieldName) {
        const hoverField = this.layoutProfileItems
          .filter(field => field.visible)
          .find(field => {
            const fixedWidth = field.userFixedWidth || field.forcedWidth
            return field.leftOffset <= (e.clientX + this.scrolledLeft) &&
              (field.leftOffset + fixedWidth) >= (e.clientX + this.scrolledLeft)
          })
        if (hoverField?.name) {
          if (hoverField.name !== this.previousHoverField &&
            hoverField.name !== this.draggingFieldName) {
            this.$store.dispatch('swapListColumnsPosition', {
              fieldName1: this.draggingFieldName,
              fieldName2: hoverField.name
            })
            this.$store.dispatch('setVisibleColumnsByNameWithLeftOffset')
          }
          this.previousHoverField = hoverField.name
        }
      } else if (this.resizingHasManyField) {
        this.adjustHasManyFieldWidth(e)
      } else if (this.resizingFieldName) {
        this.setNewColumnWidthOnResize(e)
      } else if (this.dragStartIndex !== null &&
        (e.buttons === undefined ? e.which : e.buttons) && // e.which for Safari
        util.clickOrDrag(e, this.mouseDownCoords) === 'drag' &&
        (this.splitMode || !this.selectedId) // Item is not selected (item is shown on top of list view) or is in split mode
      ) {
        // If not dragging field and has mouse button down, then select rows
        this.$store.dispatch('listSelectItemsOnDrag', e)
      }
    },

    hideHeaderMenu () {
      this.listColumnMenuField = null
      // this.listHeaderDateFilterField = null
    }
  }
}
</script>

<style lang="scss">
.list-view {
  i {
    font-size: 10px;
    padding-top: 3px;
  }
  .fa {
    /* Must use font-weight 900 for fa icons, otherwise breaks */
    font-weight: 900;
  }
  font-size: 12px;
  font-weight: 500;
  position: relative;
  padding: 0;
  background: transparent;
}
.icon-spaceholder {
	width: 12px;
}
.cell-div {
	position: relative;
	white-space: pre-line;
	overflow: hidden;
	line-height: 17px;
	top: 50%;
	transform: translateY(-50%);
	div {
		max-width: 500px;
		max-height: 400px;
	}
}
.row-number {
	position: absolute;
	left: 0px;
	z-index: 1;
	color: #c2c3c4;
	padding: 2px;
	font-size: 8px;
}
.dragging-layout-profile {
	cursor: move !important;
	div {
		cursor: move !important;
		i {
			cursor: move !important;
		}
	}
}
.no-text-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
  -webkit-user-drag: none;
	-moz-user-select: none;
	user-select: none;
  user-drag: none;
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.list-table {
	.content-item {
		&:hover {
			background-color: #fff8a3;
		}
	}
	.v-progress-linear {
		margin: 0 !important;
	}
}
.content-item {
	position: absolute;
	top: 0;
}
.content-item-selected {
	background: #FFF59D;
	border-bottom: 1px solid white;
}
.content-item-selected-one {
	background: #B3E5FC;
}
.tr-even {
	background-color: #f2f2f2;
}
.list-with-column-border .content-cell {
  transition: background 500ms;
  /* border: 1px solid var(--list_columns_separator_line_color) !important; */
  border: 1px solid var(--list_columns_separator_line_color) !important;
  /* border-right: 1px solid var(--list_columns_separator_line_color) !important;
  border-top: 1px solid var(--list_columns_separator_line_color) !important; */
}
.list-with-column-border .content-cell.cell-edit {
  /* border-right: 1px solid #FB8C00 !important;
  border-top: 1px solid #FB8C00 !important; */
  /* outline: 1px solid #FB8C00;
  outline-offset: 0;
  border-radius: 0;
  margin-left: -1px !important;
  margin-right: 1px !important;
  margin-top: -1px !important; */
}
.content-cell {
	overflow: hidden;
	float: left;
	line-height: 17px;
  min-height: 17px;
	padding: 6px;
  /* -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%); */
}
.content-cell.type_event {
  padding: 0;
  /* -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%); */
}
.fixed_headers {
	table-layout: fixed;
	border-collapse: collapse;
}
.vue-list-table-region {
  width: 100%;
	background: transparent;
	overflow: auto;
	position: relative;
	display: inline-block;
	max-width: 100%;
}
.list-header-menu {
	a {
		color: #333 !important;
		text-decoration: none !important;
	}
}
.select-menu {
  padding: 10px 0;
  background: white;
  max-height: 300px;
  overflow-y: auto;
}
.popup-menu {
	position: absolute;
	z-index: 300;
	padding: 10px 0;
	background: white;
  border-radius: 6px;
	div {
		a {
			color: #333333;
		}
	}
}
.multi-column {
  .fa {
    position: inherit !important;
  }
}

.popup-menu-el {
	position: relative;
	width: 100%;
	padding: 3px 25px 3px 35px !important;
  line-height: 20px;
  font-size: 13px;
  font-weight: 500;
  height: 28px;
  color: #4a4a4a;
	cursor: pointer;
	&:hover {
		background: #f2f2f2;
	}
  .fa, .far, .fa-regular {
    position: absolute;
    left: 13px;
    top: 7px;
    color: #888;
    width: 13px;
    text-align: center;
  }
  a {
		color: #333 !important;
		text-decoration: none !important;
    white-space: nowrap;
	}
}
.menu-el-disabled {
  cursor: not-allowed !important;
  color: #aaa !important;
  span, a {
    cursor: not-allowed !important;
    color: #aaa !important;
  }
}

.disabled-link {
	a {
		color: #777777 !important;
	}
}
.header-input {
  .v-label {
    font-size: 14px !important;
    font-weight: 400;
  }
}
.disabled-cursor {
  cursor: not-allowed !important;
}
</style>
