import Color from 'color'

export const isDarkColor = (color: string | null) => {
  if (!color) { return false }
  let isDark = false
  try {
    isDark = Color(color).luminosity() < 0.45
  } catch (e) {
    console.warn('Bad color for Color()', color)
    isDark = false
  }
  return isDark
}
