import util from '../utilities/sharedUtilities'

const localStorageFiltersPrefix = 'localforage/LP:'

export default {
  get: function (onPage, offset, state) {
    return [
      ...this.getMainFiltres(onPage, offset),
      ...this.getSortFilters(state),
      ...this.getSearchFilters(state),
      ...this.getSuperSearchFilters(state.superSearchTerm)
    ]
  },

  clearTemporarySearchFilters: (layoutProfileId) => {
    window.localStorage.setItem(localStorageFiltersPrefix + layoutProfileId, '')
  },

  getLocalStorageFilters: (layoutProfileId) => {
    let filters = {}
    const filtersString = window.localStorage.getItem(localStorageFiltersPrefix + layoutProfileId)
    if (filtersString) {
      filters = JSON.parse(filtersString)
    }
    return filters
  },

  setLocalStorageFilter: (layoutProfileId, filterName, filterValue) => {
    let filters = {}
    const filtersString = window.localStorage.getItem(localStorageFiltersPrefix + layoutProfileId)
    if (filtersString) {
      filters = JSON.parse(filtersString)
    }
    // Update current field filter
    filters[filterName] = filterValue
    // Save back filters to local storage
    window.localStorage.setItem(localStorageFiltersPrefix + layoutProfileId, JSON.stringify(filters))
  },

  getMainFiltres: (onPage, offset) => {
    const filters = []
    if (onPage) {
      filters.push({
        key: 'limit',
        value: onPage
      })
    }
    if (offset) {
      filters.push({
        key: 'offset',
        value: offset
      })
    }
    return filters
  },

  getSortFilters: (state) => {
    if (!state.sortField || !state.sortBy) { return [] }
    return [{
      key: 'order',
      value: state.sortField + ' ' + state.sortBy +
      Object.keys(state.secondarySortings).map(
        sortField => {
          return ',' + sortField + ' ' + state.secondarySortings[sortField].sortBy
        }
      ).join('')
    }]
  },

  getSuperSearchFilters: (searchTerm) => {
    if (!searchTerm) { return [] }
    return [{
      key: '_text',
      value: searchTerm
    }]
  },

  getSearchFilters: function (state) {
    const filters = []
    const fieldInfoByName = {}
    state.layoutProfileItems.forEach(field => {
      fieldInfoByName[field.name] = field
    })
    Object.keys(state.listFilters).forEach(
      filterName => {
        if (state.listFilters[filterName]) {
          filters.push(this.getFieldSearchValue(state, fieldInfoByName[filterName], filterName))
        }
      }
    )
    return filters
  },

  getFieldSearchValue: function (state, fieldInfo, filterName) {
    let filterValue = state.listFilters[filterName].replace('+', '%2B')
    if (filterName === 'main_object_state') {
      filterValue = state.listFilters[filterName]
      filterName = util.isOldUIStateFilterValue(filterValue)
        ? 'main_object_state'
        : 'main_object_state_id'
    }
    return {
      key: '_' + filterName + (fieldInfo && fieldInfo.multi_language ? '_' + state.locale : ''),
      value: filterValue
    }
  }
}
