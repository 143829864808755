export default [
  {
    name: 'sales_invoices',
    key: 'sales_invoices.list.title',
    icon: 'fa-file-invoice',
    title: 'Sales Growth Report',
    chartInfo: {
      type: 'chart.js',
      chartType: 'bar',
      chartData: {
        datasets: [{
          label: 'Data 1',
          data: [...Array(30)].map((item, i) => {
            let number = Math.floor(Math.random() * (100))
            if ([3, 4, 5, 8, 12, 17, 20].includes(i)) { number = number * -1 }
            else {
              number = Math.floor(number * 3)
            }
            return number
          }),
          backgroundColor: [...Array(30)].map((item, i) => {
            return [3, 4, 5, 8, 12, 17, 20].includes(i) ? '#FBC02D' : '#B0BEC5'
          }),
        }, {
          label: 'Data 2',
          data: [...Array(30)].map((item, i) => {
            let number = Math.floor(Math.random() * (100))
            number = Math.floor(number * 3)
            return number
          }),
          type: 'line',
          backgroundColor: [...Array(30)].map((item, i) => {
            return [3, 4, 5, 8, 12, 17, 20].includes(i) ? '#FBC02D' : '#B0BEC5'
          }),
        }],
        labels: [...Array(30)].map(i => ''),
      },
      chartOptions: {
        responsive: true,
        legend: {
          display: true,
          position: 'top',
        },
        title: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const value = tooltipItem.yLabel
              return value < 0 ? -value : value
            }
          }
        },
        scales: {
          x: {
            ticks: {
              display: false
            },
            gridLines: {
              display: false
            }
          },
          y: {
            gridLines: {
              display: false
            },
            ticks: {
              display: false,
              beginAtZero: true,
            }
          },
        }
      },
    },
    levels: 7,
    level: 2,
    points: 265,
    color: 'green',
    enabled: false,
  },
  {
    name: 'products',
    key: 'products.list.title',
    icon: '',
    title: 'Total Revenue',
    chartInfo: {
      type: 'number',
      value: '258,253 €',
      direction: 'up',
    },
    levels: 5,
    level: 3,
    points: 326,
    color: 'green',
    enabled: true,
  },
  {
    name: 'products',
    key: 'products.list.title',
    icon: '',
    title: 'Sales Growth Rate',
    chartInfo: {
      type: 'number',
      value: '12 %',
      direction: 'up',
    },
    levels: 5,
    level: 3,
    points: 326,
    color: 'green',
    enabled: true,
  },
  {
    name: 'products',
    key: 'products.list.title',
    icon: '',
    title: 'Sales Pipeline Value',
    chartInfo: {
      type: 'number',
      value: '6850 €',
      direction: 'down',
    },
    levels: 5,
    level: 3,
    points: 326,
    color: 'green',
    enabled: true,
  },
  {
    name: 'products',
    key: 'products.list.title',
    icon: '',
    title: 'Average Order Value',
    chartInfo: {
      type: 'number',
      value: '30 535 €',
      direction: 'up',
    },
    levels: 5,
    level: 3,
    points: 326,
    color: 'green',
    enabled: true,
  },
  {
    name: 'products',
    key: 'products.list.title',
    icon: '',
    title: 'Sales Forecast Accuracy',
    chartInfo: {
      type: 'number',
      value: '85 %',
      direction: 'down',
    },
    levels: 5,
    level: 3,
    points: 326,
    color: 'green',
    enabled: true,
  },
  {
    name: 'products',
    key: 'products.list.title',
    icon: '',
    title: 'Average Order Value',
    chartInfo: {
      type: 'number',
      value: '30 535 €',
      direction: 'down',
    },
    levels: 5,
    level: 3,
    points: 326,
    color: 'green',
    enabled: true,
  },
  {
    name: 'orders',
    key: 'sales_orders.list.title',
    icon: 'fa-file-import',
    chartInfo: {
      type: 'number',
      value: '124.74',
      direction: 'down',
    },
    levels: 7,
    level: 5,
    points: 494,
    color: 'blue',
    enabled: true,
  },
  {
    name: 'projects',
    key: 'projects.list.title',
    icon: 'fa-folder-open',
    chartInfo: {
      type: 'chart.js',
      chartType: 'line',
      chartData: {
        datasets:
          [{
            data: [16, 35, -17, 35, 22, 82, 32, 66, 23, 17, 35, 42],
            backgroundColor: 'white',
          }],
        labels: ['', '', '', '', '', '', '', '', '', '', '', '']
      },
      chartOptions: {
        title: { text: '', display: false },
        legend: { display: false },
        scales: {
          x: {
            ticks: {
              display: false
            },
            gridLines: {
              display: false
            }
          },
          y: {
            gridLines: {
              display: false
            },
            ticks: {
              display: false,
              beginAtZero: true,
            }
          },
        },
      },
    },
    levels: 7,
    level: 5,
    points: 494,
    color: 'green',
    enabled: true,
  },
]
