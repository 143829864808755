<template>
  <div
    v-if="showHeaderColumnUniqueValuesMenu && uniqueValues.length > 0"
    id="unique-value-options-menu"
    class="state-options-menu popup-menu elevation-5"
  >
    <div
      v-for="(uniqueValue, index) in uniqueValues"
      :key="index"
      class="list-header-menu-el"
      @click="selectUniqueValue(uniqueValue)"
    >
      <a href="javascript:;">
        {{ uniqueValue }}
      </a>
    </div>
  </div>
</template>

<script>
import Label from './../../methods/label'
import listFilters from './../../store/_listFilters'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'HeaderColumnUniqueValuesMenu',

  computed: {
    ...mapFields(Object.keys(state)),

    uniqueValues () {
      const fieldName = this.focusedField || this.focusedFieldBefore
      if (!fieldName) { return [] }
      const fieldInfo = this.$store.getters.layoutProfileItemsByName[fieldName]
      return this.uniqueValueFilterOptions.map(uniqueValue => {
        if (['static_list', 'dynamic_list'].includes(fieldInfo.type)) {
          uniqueValue = Label.is_translation_key(fieldInfo.items[uniqueValue])
            ? this.$i18n.t(fieldInfo.items[uniqueValue])
            : fieldInfo.items[uniqueValue]
        }
        return uniqueValue
      })
    }
  },

  created () {
    document.addEventListener('click', this.clickListener)
  },

  destroyed () {
    document.removeEventListener('click', this.clickListener)
  },

  methods: {
    ...methods,

    selectUniqueValue (uniqueValue) {
      const fieldName = this.focusedFieldBefore
      this.showHeaderColumnUniqueValuesMenu = false
      this.$store.commit('setFilterField', {
        filterField: fieldName,
        filterValue: uniqueValue
      })
      listFilters.setLocalStorageFilter(this.selectedLayoutProfileId, fieldName, uniqueValue)
      this.reloadListItems()
    },

    clickListener (e) {
      if (this.showHeaderColumnUniqueValuesMenu) {
        const el = document.getElementById('unique-value-options-menu')
        const el2 = document.getElementById(this.focusedField || this.focusedFieldBefore + '_th')
        if (el && !el.contains(e.target) && el2 && !el2.contains(e.target)) {
          this.showHeaderColumnUniqueValuesMenu = null
        }
      }
    }
  }
}
</script>

<style>
#unique-value-options-menu {
  min-height: 1px;
}
</style>
