
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import itemMetaActionMethods from '@/methods/item/itemMetaActionMethods'
import { MetaAction } from '@/types/LP.types'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ListMetaActionsMenu',

  data () {
    return {
      runningAction: false,
      showMenu: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  methods: {
    ...methods,
    ...itemMetaActionMethods,

    isEnabled (actionButton: MetaAction) {
      return !actionButton.requires_ids || this.selectedItems.length
    },

    clickHandler (actionButton: MetaAction) {
      if (!this.isEnabled(actionButton)) {
        return
      }
      this.showMenu = false
      this.confirmSendAction(actionButton)
    },
  },
}
