<template>
  <v-dialog
    v-model="showDialog"
  >
    <v-container
      class="white"
      fluid
    >
      <v-row>
        <v-col class="text-right">
          <v-btn
            text
            @click="showDialog = false"
          >
            <v-icon color="grey">
              fa-times
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <h3>Export JSON</h3>
          <v-text-field
            v-model="exportJSON"
            solo
            readonly
          />
        </v-col>
        <v-col
          cols="12"
          class="text-center"
        >
          <v-btn
            @click="copyToClipboard"
          >
            Copy
            <v-icon
              class="ml-3"
              color="grey"
              small
            >
              fa-copy
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <h3>Import JSON</h3>
          <v-textarea
            v-model="importJSON"
            outlined
          />
        </v-col>
        <v-col class="text-center">
          <v-btn
            @click="startImport"
          >
            Import
            <v-icon
              class="ml-3"
              color="grey"
              small
            >
              fa-file-import
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>

import layoutEditMethods from '@/components/Item/LayoutEditor/layoutEditMethods'

export default {
  name: 'LayoutEditorImportExport',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    containers: {
      type: Array,
      default: () => {},
    },
    fieldSets: {
      type: Array,
      default: () => {},
    },
    fields: {
      type: Array,
      default: () => {},
    },
    layoutProfile: {
      type: Object,
      default: () => {},
    },
  },

  data () {
    return {
      importJSON: '',
    }
  },

  computed: {
    showDialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    exportJSON () {
      return this.getItemLayoutExportJSON(this.layoutProfile, this.containers, this.fieldSets, this.fields)
    },
  },

  methods: {
    ...layoutEditMethods,

    copyToClipboard () {
      navigator.clipboard.writeText(this.exportJSON)
    },

    startImport () {
      try {
        const importData = JSON.parse(this.importJSON)
        this.$emit('import', importData)
        this.showDialog = false
      } catch (e) {
        alert('Not valid JSON')
      }
    },
  },
}
</script>
