<template>
  <div style="width: 95%;">
    <div
      v-for="(item, i) in Array(showCount)"
      :key="i"
      class="gauge-outer"
      style="width: 10%; padding: 20px; margin-top: 30px;"
    >
      <div
        :style="'color:' + options[i].valueColor"
        class="gauge-text"
      >
        <b>{{ options[i].valueText || options[i].value }}</b>
      </div>
      <VueSvgGauge
        :start-angle="-180"
        :end-angle="180"
        :value="options[i].value"
        :separator-step="1"
        :min="0"
        :max="options[i].max"
        :inner-radius="85"
        :separator-thickness="2"
        :gauge-color="[{ offset: 30, color: options[i].color }, { offset: 100, color: options[i].color }]"
        :scale-interval="0"
      />
      <div class="text-center pt-2 subtitle-1">
        Project {{ i + 1 }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultiGaugeWide',

  props: {
    showCount: {
      type: Number,
      default: 10,
    },
  },

  data () {
    return {
      options: [
        {
          color: '#4CAF50',
          value: 8,
          max: 10,
          valueText: '+1,7k',
          valueColor: 'green',
        },
        {
          color: '#4CAF50',
          value: 9,
          max: 10,
          valueText: '-0,3k',
          valueColor: '#EF6C00',
        },
        {
          color: '#EF5350',
          value: 2,
          max: 10,
          valueText: '-1,6k',
          valueColor: '#EF6C00',
        },
        {
          color: '#FFC107',
          value: 6,
          max: 10,
          valueText: '+4,5k',
          valueColor: 'green',
        },
        {
          color: '#FFC107',
          value: 5,
          max: 10,
          valueText: '+1,7k',
          valueColor: 'green',
        },
        {
          color: '#4CAF50',
          value: 9,
          max: 10,
          valueText: '+6,5k',
          valueColor: 'green',
        },
        {
          color: '#EF5350',
          value: 1,
          max: 10,
          valueText: '+2,5k',
          valueColor: 'green',
        },
        {
          color: '#FFC107',
          value: 6,
          max: 10,
          valueText: '-7,2k',
          valueColor: '#EF6C00',
        },
        {
          color: '#4CAF50',
          value: 7,
          max: 10,
          valueText: '-1,6k',
          valueColor: '#EF6C00',
        },
        {
          color: '#FFC107',
          value: 6,
          max: 10,
          valueText: '+0,2k',
          valueColor: '#EF6C00',
        },
      ],
    }
  },
}
</script>

<style>
.gauge-outer {
  float: left;
  padding: 40px 15px 15px 15px;
  position: relative;
}
.gauge-text {
  position: absolute;
  top: 38%; right: 50%;
  font-size: 18px;
  transform: translate(50%,-50%);
}
</style>
