import axios, { AxiosError, AxiosResponse } from 'axios'
import { networkErrorAxiosResponse } from '@/store/api/apiRequests'

export default {
  updateItem (objectClass, id, parameters): Promise<AxiosResponse> {
    const queries = [{
      key: '~confirmations',
      value: 'required'
    }, {
      key: 'q',
      value: '!id'
    }]
    return this.sendPutWithPayloadRequest('/api/' + objectClass + '/' + id, queries, parameters)
  },

  updateItemForMultiEdit (objectClass: string, id: number, parameters: any, updatedField: string): Promise<AxiosResponse> {
    return this.sendPostRequestForMultiEdit('/api/' + objectClass +
      '/save_change_for_multiedit/' + id + '/' + updatedField +
      '?~confirmations=required&q=!id', parameters)
  },

  sendProcessEvent (objectClass: string, id: string | number, action: string, queries: any, confirmations: string, actionParameters: any, parent: any): Promise<AxiosResponse> {
    // Send with PUT payload, to properly send @eventParams data
    return this.sendPutWithPayloadRequest('/api/' + objectClass + '/' + id + '/' + action,
      queries, [
        { key: '@confirmations', value: confirmations },
        { key: '@eventParams', value: actionParameters },
        { key: '@parent', value: parent },
      ].filter(query => query.value !== undefined) // Optional values stringify to "undefined"
    )
  },

  getListOneItem (objectClass: string, filters: any, queries: any, id: number | null = null) {
    filters.limit = 1
    filters.order = 'updated_at desc' // Get the last changed one for has-many list
    // Set id, when used in the list, first row item is also the preview item
    if (id) {
      filters._unique_ids = id
    }
    return this.sendRequest('/api/' + objectClass, filters, queries)
  },

  deleteItem (objectClass: string, id: number | string): Promise<AxiosResponse> {
    return new Promise((resolve) => {
      axios.delete('/api/' + objectClass + '/' + id, {
        id,
        ...this.requestOptions()
      }).then((response) => {
        resolve(response.data)
      }).catch((error: AxiosError) => {
        resolve(error.response || networkErrorAxiosResponse(error))
      })
    })
  },
}
