import methods from '../../methods'
import LayoutProfileTabs from '@/components/LayoutProfiles/LayoutProfileTabs.vue'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import Confirm from '@/methods/confirm'
import ShortcutsAddButton from '@/components/Shortcuts/ShortcutsAddButton.vue'
import GlobalFilterSelect from '@/components/App/GlobalFilterSelect.vue'
import ItemActionButtons from '@/components/Item/Toolbar/ItemActionButtons.vue'
import MobileItemLayoutProfilesMenu from './MobileItemLayoutProfilesMenu.vue'
import ItemStateLabel from '@/views/ItemStateLabel.vue'
import getToolbarButtons from '@/components/Item/Toolbar/getToolbarButtons'
import { ItemToolbarButton } from '@/types/ItemToolbarButton'
import VueRouter from 'vue-router'
import { LP } from '@/types/LP.types'
import { EventAction } from '@/types/EventAction'
import { EventBus } from '@/eventBus'
import { sortItemActions } from '@/methods/item/itemMethods'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ItemToolbar',

  components: {
    ItemStateLabel,
    MobileItemLayoutProfilesMenu,
    ItemActionButtons,
    GlobalFilterSelect,
    ShortcutsAddButton,
    LayoutProfileTabs,
  },

  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    resource: {
      type: String,
      default: '',
    },
    saveFormItem: {
      type: Function,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    itemMeta: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: false,
    },
    isMobileBottomToolbar: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    modalProps: {
      type: Object,
      default: () => {},
    },
    itemFormPosition: { // First or second
      type: String,
      default: '',
    },
    filteredItemLayoutProfiles: {
      type: Array,
      default: () => {},
    },
  },

  mounted () {
    EventBus.$on?.('callEventFromItemToolbar', this.callEventFromItemToolbar)
  },

  beforeDestroy () {
    EventBus.$off?.('callEventFromItemToolbar', this.callEventFromItemToolbar)
  },

  data () {
    return {
      showInfoDialog: false,
      itemActionButtonsCached: [] as ItemToolbarButton[], // For previously opened item, to avoid toolbar items "jumping"
      savingFormItem: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    // Aava-Vue-527
    // Hack fix to allow item-picker columns search when opened from modal view
    disableInModal () {
      return !!this.itemPickerProps?.objectClass && this.modal
    },

    forbiddenListModel () {
      return this.forbiddenListModels.includes(this.resource)
    },

    showOnMobile () {
      return !this.$vuetify.breakpoint.xsOnly || this.isMobileBottomToolbar
    },

    defaultBtnBgColor (): string {
      return this.isMobileBottomToolbar ? 'grey lighten-3' : 'white'
    },

    modal () {
      return this.itemFormPosition === 'modal'
    },

    splitProps (): VueRouter {
      return this.$route.params
    },

    showTabsOnSeparateToolbar () {
      return this.showItemTabsOnSeparateToolbar(this.itemLayoutProfilesByModel[this.resource])
    },

    itemLayoutProfiles (): LP.LayoutProfile[] {
      return this.itemLayoutProfilesByModel && this.itemLayoutProfilesByModel[this.resource]
    },

    mode () {
      return !this.edit
        ? 'show'
        : this.id === 'new'
          ? 'new'
          : 'edit'
    },

    itemActionButtons () {
      const addType = type => item => ({ ...item, type })
      const localizedName = action => action.key ? this.$i18n.t(action.key.replace(':', '.')) : action.label
      const localize = action => ({ ...action, name: localizedName(action), showWide: true })

      return [
        ...(this.item?.displayable_process_events || []).map(addType('processEvent')),
        ...(this.item?.displayable_process_actions || []).map(addType('processAction'))
      ].map(localize)
    },

    isMyProfile () {
      return this.$route.params.specialAction === 'myProfile'
    },

    buttons () {
      return this.getToolbarButtons()
    },

    showToggleSplitModeBtn () {
      if (this.modal || !this.$vuetify.breakpoint.mdAndUp) { return false }
      if (!this.$store.state.splitMode) { return true }
      // Only show for item if shown on first split window
      return this.itemFormPosition === 'first'
    },

    modelDocumentation () {
      return this.$store.state.documentationByModel[this.resource]
    },

    modelInfoText () {
      return this.modelDocumentation?.[this.edit ? 'edit' : 'show']
    },

    fieldsInfoTexts () {
      return Object.keys(this.modelDocumentation?.attributes || {}).map(fieldName => {
        return {
          fieldLabel: this.$i18n.t(this.resource + '.attributes.' + fieldName),
          infoText: this.modelDocumentation?.attributes[fieldName],
        }
      })
    },

    itemActionButtonsComputed () {
      return sortItemActions(this.showLoader ? this.itemActionButtonsCached : this.itemActionButtons)
    },
  },

  watch: {
    showLoader (value) {
      if (!value) {
        // Cache item action buttons to display something before next item is loaded
        // this is to avoid ui flash/jumping - hiding action buttons when moving to next item, showing action buttons again
        // when next item is loaded. Likely next item has same action buttons anyway
        this.itemActionButtonsCached = JSON.parse(JSON.stringify(this.itemActionButtons))
      }
    },
  },

  methods: {
    ...methods,
    ...getToolbarButtons,

    callEventFromItemToolbar ({ eventButton, e, callback }) {
      this.sendProcessEventOrAction({ event: eventButton, e }).then(() => {
        callback()
      })
    },

    classes (button) {
      return [
        (!this.isMobileBottomToolbar ? 'item-toolbar-icon' : 'mobile-toolbar-btn'),
        'item-btn-' + button.identifier,
        (!this.showCompactButton(button) && button.buttonColor && !this.isMobileBottomToolbar ? ' large-button white--text' : 'grey--text text--darken-1'),
        (!this.showCompactButton(button) && !this.isMobileBottomToolbar && ' mx-2 '),
      ]
    },

    saveFromToolbar (props) {
      // Check for focused form input
      // If there is ant > blur > triggers DFC PUt before save PUT/POST is sent
      const focusedElement = document.activeElement as HTMLInputElement
      if (focusedElement) {
        // Should trigger DFC immediately ant any focused field
        focusedElement.blur()
      }
      setTimeout(() => { // A very short timer to guarantee that DFC is sent first
        // To show loading save button
        this.savingFormItem = true
        this.saveFormItem(props).then(() => {
          this.savingFormItem = false
        })
      }, 10)
    },

    clickHandler (button) {
      button.action(button.actionParameter)
    },

    showCompactButton (button: ItemToolbarButton) {
      if (this.isMobileBottomToolbar) {
        return false
      }
      if (this.splitMode === 'vertical' || !button.wideButton) {
        return true
      }

      if (this.$vuetify.breakpoint.lgAndUp) {
        return false
      }
      return !this.$vuetify.breakpoint.xlOnly
    },

    isLastItemInTheList () {
      const index = this.listItems.length - 1
      return this.listItems && this.listItems[index] && this.listItems[index].id === parseInt(this.id)
    },

    isFirstItemInTheList () {
      return this.listItems && this.listItems[0] && this.listItems[0].id === parseInt(this.id)
    },

    moveToLeftInSplitMode () {
      this.$router.push({
        path: '/' + this.objectClass + '/split/' + this.resource + '/' + this.id + '/' +
          (this.edit ? 'edit' : 'show') + '/split'
      })
    },

    moveToRightInSplitMode () {
      this.$router.push({
        path: '/' + this.objectClass + '/split/' + this.resource + '/' + this.id + '/' +
          (this.edit ? 'edit' : 'show')
      })
    },

    deleteItem () {
      Confirm.request(this.$i18n.t('aava.confirmations.destroy_object'), () => {
        this.$store.dispatch('deleteItem', {
          resource: this.resource,
          id: this.id,
        }).then(success => {
          if (success) {
            this.$router.push({ path: '/' + this.objectClass })
            this.reloadListItems()
          }
        })
      })
    },

    previousItem () {
      this.goToListPreviousItem()
    },

    nextItem () {
      this.goToListNextItem()
    },

    cancelEditOrCloseModal () {
      if (this.modal) {
        this.modalProps.closeCallback()
        return
      }
      if (!this.openAlternateSplitItemView('show')) {
        if (this.id === 'new') { // New form is open
          this.openList()
          return
        }
        this.listItemOpenById(this.id, { className: this.resource })
      }
    },

    // Special case (not supported in the old ui because of complexity)
    // User is in edit view and clicks on processAction type event
    // Item data should be saved and then processAction sent
    // After which we hand it over to saveFormItem() to open show view
    sendProcessActionAfterEditSave ({ event, id } = { event: null, id: null }) {
      return new Promise(resolve => {
        const item = { id, objectClass: this.resource }
        this.$store.dispatch('dispatchProcessEventOrAction', {
          event,
          item,
          view: 'item',
          objectClass: this.resource,
        }).then((response: AxiosResponse) => {
          this.handleProcessEventActionResponse({ response, id, skipRefresh: true }).then(() => {
            resolve(null)
          })
        })
      })
    },

    // Show event start note for the user if start_note is defined
    showEventStartNote (event: EventAction) {
      if (!event.start_note) { return }
      this.$store.dispatch('addFlashMessage', {
        message: event.start_note,
        type: 'info',
      })
    },

    // From ITEM view (toolbar)
    // 1. Edit with processEvent
    //    call saveFormItem and add ~execute_event
    // 2. Edit with processAction (HACK)
    //    can't execute with item save. First save item and set callbackBeforeShowViewOpen
    //    then before item is opened again, processAction is executed before
    // 3. Show with processEvent
    //    Send dispatchProcessEventOrAction
    // 4. Show with processAction
    //    Send dispatchProcessEventOrAction
    sendProcessEventOrAction ({ event, e }: {
      event: EventAction,
      e: MouseEvent,
    }) {
      return new Promise(resolve => {
        const id = this.item.id
        this.showEventStartNote(event)
        // For edit view save form with changes and send event action
        if (this.edit) {
          this.savingFormItem = true
          // Only send ~execute_event when type is processEvent
          // for processAction can't send it with edit, have to save and then send sendProcessActionAfterEditSave
          // when save is completed
          const executeEventName = event.type === 'processEvent' ? event.identifier : null
          const callbackBeforeShowViewOpen = event.type === 'processAction'
            ? this.sendProcessActionAfterEditSave
            : () => { return new Promise(resolve => resolve(null)) }
          this.saveFormItem({ executeEventName, event, callbackBeforeShowViewOpen }).then(() => {
            this.savingFormItem = false
            resolve(true)
          })
          return
        }

        // For show view
        this.$emit('updateShowLoader', true)
        const item = { id, objectClass: this.resource }
        this.$store.dispatch('dispatchProcessEventOrAction', {
          event,
          item,
          view: 'item',
          objectClass: this.resource,
        }).then((response: AxiosResponse) => {
          this.handleProcessEventActionResponse({ response, id })
          resolve(true)
        })
      })
    },

    handleProcessEventActionResponse ({
      response,
      id,
      // Skip when sending processAction after save.
      // No need to refresh because show view is opened by saveFormItem() anyway
      skipRefresh = false,
    }) {
      return new Promise(resolve => {
        if (!response) { // User cancelled Prompt
          this.$emit('updateShowLoader', false) // Release toolbar
          return resolve(null)
        }
        // Redirect in any case, status = ok or error
        if (!this.modal && (response.data.redirect_to)) { // .data is used when coming from error
          this.$nextTick(() => {
            this.redirectToAfterProcessEventAction(response)
          })
        }
        // TODO - redirect also if status not ok
        if (response.data.status !== 'ok') {
          this.$emit('updateShowLoader', false)
          resolve(null)
        } else if (response) {
          this.showExecuteMessages(response)
          // If split mode, re-load item show view
          // If edit form was open, still load show view
          if (!this.modal && response.data.redirect_to) {
            this.$emit('updateShowLoader', false) // Otherwise item form does not trigger load
          } else {
            // Refresh item with reloading has-many lists
            if (!skipRefresh) {
              this.$emit('refreshItem', { reRenderAfterItemIsLoaded: true })
            }
            this.updateListAfterItemStateChange(id)
          }
          resolve(null)
        }
      })
    },

    updateListAfterItemStateChange (itemId) {
      if (this.modal || this.objectClass !== this.resource) { return }
      // If list has state filer, reload full list
      if (this.listFilters.main_object_state) {
        this.reloadListItems()
      } else {
        this.$store.dispatch('updateItemInTheListById', itemId)
      }
    },
  },
}
