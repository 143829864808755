<template>
  <div
    style="width: 100%;"
    class="text-center"
  >
    <v-icon
      v-for="(user, i) in Array(10)"
      :key="i"
      large
      class="px-1"
      :color="i < 8 ? 'grey lighten-1' : '#FF9800'"
    >
      fa-person
    </v-icon>
  </div>
</template>
<script>
export default {
  name: 'PeopleChart',
}
</script>
