<template>
  <div
    v-if="dialogItemFieldMenu"
    id="item-field-menu"
    class="item-field-menu popup-menu elevation-5"
  >
    <div
      v-if="itemFieldMenuData.field"
      class="popup-menu-el"
      @click.stop="toggleLayoutEditorSelectedField(itemFieldMenuData.field.name)"
    >
      <a href="javascript:;">
        <span
          :class="'fa-regular ' + (selectedLayoutEditorFields.includes(itemFieldMenuData.field.name) ? 'fa-check-square' : 'fa-square')"
        />
        Select
      </a>
    </div>
    <template v-for="(container, containerIndex) in itemFieldMenuData.containers">
      <template
        v-for="(fieldSet, fieldsetIndex) in itemFieldMenuData.fieldSets.slice().sort((a, b) => a.sort_order < b.sort_order ? -1 : 1)"
      >
        <div
          v-if="(fieldSet.layout_container && fieldSet.layout_container.id) === container.id"
          :key="containerIndex + '_' + fieldsetIndex"
          class="popup-menu-el"
          @click.stop="itemFieldMoveCallback(itemFieldForEdit, fieldSet.id)"
        >
          <a href="javascript:;">
            <span class="fa fa-arrow-right" />
            {{ container['name_' + locale] || ($i18n.t('aava.general.group') + ' ' + (containerIndex + 1)) }} -
            {{ fieldSet['name_' + locale] || ($i18n.t('aava.general.subgroup') + ' ' + (fieldsetIndex + 1)) }}
          </a>
        </div>
      </template>
    </template>
    <div class="menu-separator-line" />
    <div
      v-if="!itemFieldMenuData.field"
      class="popup-menu-el"
      @click.stop="clearSelection"
    >
      <a href="javascript:;">
        <span
          class="fa fa-solid fa-times"
        />
        {{ $t('aava.layout.clear_selection') }}
      </a>
    </div>
    <div
      class="popup-menu-el"
      @click.stop="itemFieldDelete(itemFieldForEdit)"
    >
      <a href="javascript:;">
        <span class="fa fa-trash" />
        {{ $i18n.t('aava.actions.remove') }}
      </a>
    </div>
  </div>
</template>

<script>
import methods from './../../components/methods'

import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import layoutEditMethods from '@/components/Item/LayoutEditor/layoutEditMethods'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'ItemFieldMenu',

  data () {
    return {
      saving: false,
      justCreated: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
  },

  created () {
    this.justCreated = true
    setTimeout(() => {
      this.justCreated = false
    }, 100)
    document.addEventListener('click', this.clickListener)
  },

  destroyed () {
    document.removeEventListener('click', this.clickListener)
  },

  methods: {
    ...methods,
    ...layoutEditMethods,

    clickListener (e) {
      if (this.justCreated) { // Not to immediately close when just now opened
        return
      }
      // Note - opener div must have @click.stop to prevent immediate close after open
      if (this.dialogItemFieldMenu) {
        const el = document.getElementById('item-field-menu')
        if (el && !el.contains(e.target)) {
          this.dialogItemFieldMenu = false
        }
      }
    },
  },
}
</script>
