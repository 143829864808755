var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-text-field',{ref:"inputRef",class:_vm.classes,attrs:{"label":_vm.label,"placeholder":_vm.isHasManyField ? _vm.label : '',"autofocus":_vm.autofocus,"single-line":_vm.isHasManyFieldAndNotFirstRow,"persistent-placeholder":true,"disabled":_vm.readOnly,"data-test":_vm.field.name,"spellcheck":"false","autocomplete":"off","color":"orange","outlined":"","dense":"","hide-details":""},on:{"keyup":[_vm.keyUpHandler,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter')}],"focus":_vm.focus,"paste":_vm.onPaste,"copy":_vm.onCopy,"cut":_vm.onCut,"blur":function($event){return _vm.$emit('blur')},"keydown":_vm.keydownHandler},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}},[(_vm.showUnitDisplayName)?_c('div',{class:'multi-edit-unit ' + (_vm.unitPickerIsDisabled ? '' : 'link'),attrs:{"slot":"append"},on:{"click":function($event){return _vm.unitClickHandler($event)}},slot:"append"},[(_vm.unitDisplayName === '-')?_c('v-btn',{staticClass:"elevation-0",style:({
          top: '-5px',
          right: '2px',
          width: '24px',
          height: '24px',
          backgroundColor: _vm.unitPickerIsDisabled ? 'transparent !important' : '',
        }),attrs:{"fab":"","color":_vm.value ? 'orange lighten-2' : 'grey lighten-3',"disabled":_vm.unitPickerIsDisabled,"x-small":""}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fa-question ")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.unitDisplayName !== '-' ? _vm.unitDisplayName : '')+" ")],1):(_vm.appendIcon && !_vm.unitIsHidden)?_c('div',{attrs:{"slot":"append"},slot:"append"},[_c('v-icon',{staticClass:"mt-1",attrs:{"small":"","color":"grey","tabindex":"-1"},on:{"click":function($event){$event.stopPropagation();return _vm.appendIconFunction.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.appendIcon)+" ")])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }