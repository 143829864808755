
import FilesFile from '@/components/Form/FilesFile.vue'
import state from './../../store/state'
import { createHelpers } from 'vuex-map-fields'
import { Types } from '@/types/AppTypes'
import { LP } from '@/types/LP.types'
import { Field } from '@/types/FieldTypes'
import Confirm from '@/methods/confirm'
import { AxiosResponse } from 'axios'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'Files',

  components: {
    FilesFile,
  },

  props: {
    files: {
      type: Array as () => Field.File[],
      default: () => [],
    },
    fieldLabel: {
      type: String,
      default: '',
    },
    field: {
      type: Object as () => LP.Item,
      required: true,
    },
    forItem: {
      type: Object as () => Types.Item,
      required: true,
    },
    hasManyParentField: {
      type: Object as () => LP.Item,
      default: () => {},
    },
    hasManyParent: {
      type: Object as () => Types.Item,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: false,
    },
    editInList: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      uploadFiles: [],
      uploading: false,
      isDragOver: false,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    showCompactOneFile () {
      return (!!this.hasManyParent?.['@class'] || this.editInList) && this.files.length > 0
    },

    fileInputId () {
      return 'upload_file' + Math.random()
    },
  },

  methods: {
    deleteFile (index: number) {
      Confirm.request(this.$i18n.t('aava.edit.confirm_destroy_file'), () => {
        this.$store.dispatch('deleteFile', { index, files: this.files })
      })
    },

    handleDragEnter (e: DragEvent) {
      e.preventDefault()
      this.isDragOver = true
    },

    handleDragLeave (e: MouseEvent) {
      if (!this.$refs.filesContainer.contains(e.relatedTarget)) {
        e.preventDefault()
        this.isDragOver = false
      }
    },

    selectFile () {
      const el = document.getElementById(this.fileInputId)
      if (!el) { return }
      el.click()
    },

    getDataURLs (e: DragEvent | MouseEvent) {
      const files: FileList | null = (e.target as HTMLInputElement)?.files || ((e as DragEvent).dataTransfer?.files || null) // Select or drag n drop
      return files
        ? Promise.all(Array.from(files)
          .map(fileToDataURL => fileToDataURL))
        : null
    },

    // Upload all files and get tokens/ids
    // this must be done synchronous in order for api to return valid tokens
    // with async way tokens are returned, but can't be used on item save or when selecting country
    readFiles (e: DragEvent | MouseEvent) {
      this.ready = false
      this.isDragOver = false
      this.getDataURLs(e).then(selectedFiles => {
        // this.setTempUploadingImages(selectedFiles)
        if (!selectedFiles || !selectedFiles[0]) { return }
        // this.setTempUploadingImages(selectedFiles)
        selectedFiles
          .reduce((previousPromise, file) => {
            return previousPromise.then(() => {
              return this.readFile(file, e)
            })
          }, Promise.resolve()).then(() => {
            this.ready = true
          })
      })
    },

    readFile (file, e) {
      return new Promise(resolve => {
        if (!file) {
          resolve(false)
          return
        }
        // this.uploadFile = URL.createObjectURL(file)
        const reader = new FileReader()
        reader.onload = e => {
          this.uploading = true
          this.$store.dispatch('addNewFile', {
            file,
            files: this.files,
            forObjectClass: this.forItem['@class'],
            forFieldName: this.field.name,
            forObjectId: this.forItem.token || this.forItem.id,
            attachmentType: this.field.type === 'pdf' ? 'pdf' : 'file',
            parentClass: this.hasManyParent?.['@class'],
            parentTokenOrId: this.hasManyParent?.token || this.hasManyParent?.id,
            parentFieldName: this.hasManyParentField?.name,
            skipPathQuery: this.hasManyParent && !this.edit, // When in has-many and show view, do not send path query
          }).then((response: AxiosResponse) => {
            this.uploading = false
            resolve(response)
          })
        }
        reader.readAsText(file)
      })
    }
  }
}
