<template>
  <div :class="'item-show-field switch-field d-inline-block'">
    <v-switch
      v-model="valueComputed"
      :label="label"
      color="green darken-2"
      :disabled="readOnly"
      hide-details
      dense
    />
  </div>
</template>

<script>
export default {
  name: 'SwitchField',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    field: {
      type: Object,
      default: () => {
        return {}
      },
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    resource: {
      type: String,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    valueComputed: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      },
    },
  },
}
</script>

<style lang="scss">
</style>
