export default {
  noSeView: false,
  noSeViewComponent: 'my',
  noSeEditItemId: null,
  noSeLoading: false,
  noSeEditing: false,
  noSeMyNotifications: [],
  noSeSharedNotifications: [],
  noSeEditedNotification: { type: 'event' },
  noSeEditedRuleCombination: false,
  noSeEditedRules: [],
  noSeEditedMessages: [],
  noSeActions: {},
  noSeAttributes: {},
  noSeFixedAttributes: [],
  noSeRecipients: {}
}
