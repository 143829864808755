import dashboardConfig from './../../components/Dashboard/dashboardConfig'
import { DashboardTypes } from '@/types/DashboardTypes'

export default {
  dashboardItems: [],
  dashboardItemsLoaded: false,
  loadingShortcuts: false,
  showShortcuts: false,
  dashboardAddingItemNow: false,
  dashboardAvailableItems: {},
  shortcuts: [] as DashboardTypes.Shortcut[],
  shortcutKeys: {},
  dashboardObjectColumns: dashboardConfig.DASHBOARD_OBJECT_COLUMNS,
  dashboardItemsRefreshing: false,
  dashboardRefreshTime: null,
  dashboardRefreshTimer: null,
  dashboardRefreshTimeout: null,
  selectedDashboardTabId: 0 as number,
  dashboardTabs: [] as DashboardTypes.Tab[],
}
