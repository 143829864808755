export default {
  yellow_d2: '#FBC02D',
  orange_d2: '#F57C00',
  lime_d2: '#C0CA33',
  green_d2: '#689F38',
  teal_d1: '#00897B',
  blue_d2: '#0288D1',
  pink_d2: '#C2185B',
  purple_d1: '#8E24AA',
  grey_d1: '#546E7A',
  blue: '#64B5F6',
  green: '#9CCC65',
  orange: '#FFA000',
  orange2: '#FF8A65',
}
