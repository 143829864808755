<template>
  <v-text-field
    ref="inputRef"
    v-model="valueComputed"
    :label="label"
    :placeholder="isHasManyField ? label : ''"
    :single-line="isHasManyFieldAndNotFirstRow"
    :persistent-placeholder="true"
    :disabled="readOnly"
    :autofocus="autofocus"
    spellcheck="false"
    autocomplete="off"
    outlined
    hide-details
    dense
    color="orange"
    @blur="blurHandler"
    @focus="focus"
    @keydown="keydownHandler"
    @click="userInputValue = ''"
    @keyup.enter="showDatePickerMenu = false"
  >
    <template v-slot:append>
      <v-icon
        tabindex="-1"
        class="py-1 pl-2"
        text
        x-small
        :disabled="readOnly"
        @click="showPickerMenu"
      >
        fa-calendar
      </v-icon>
    </template>
  </v-text-field>
</template>

<script>
import moment from 'moment'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import util from '@/utilities/sharedUtilities'
import itemFieldMethods from '@/methods/item/itemFieldMethods'

// .theme--light.v-input, .theme--light.v-input input, .theme--light.v-input textarea

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

const dateQuickInputFormats = [
  // Moved some formats to be detected on blur()
  // 'DD.MM', // Date for current year
  // 'D.MM', // Date for current year, with single digits day
  'DDMMYY', // With a year
  // 'DMMYY', // With a year, with single digits day
]

export default {
  name: 'DateTime',

  props: {
    parentId: { // For watches
      type: [String, Number],
      default: null
    },
    value: {
      type: String,
      default: null
    },
    defaultTime: {
      type: String,
      default: null
    },
    dateOnly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      default: () => {}
    },
    field: {
      type: Object,
      default: () => {}
    },
    layoutEditMode: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isHasManyFieldAndNotFirstRow: {
      type: Boolean,
      default: false,
    },
    isHasManyField: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      selectedDateTime: {
        date: '',
        time: '',
      },
      startWatching: false, // Not to trigger watchers before defaultTime is used
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    selectedDate () {
      return this.selectedDateTime.date
    },

    selectedTime () {
      return this.selectedDateTime.time
    },

    dateFormat () {
      return this.dateOnly
        ? 'YYYY-MM-DD'
        : 'YYYY-MM-DD HH:mm'
    },

    dateDisplayFormat () {
      return this.dateOnly
        ? 'DD.MM.YYYY'
        : 'DD.MM.YYYY HH.mm'
    },

    valueComputed: {
      get () {
        // Special case - if date with time and only date is set, still display date
        if (this.value && moment(this.value.trim(), ['YYYY-MM-DD'], true).isValid()) {
          return moment(this.value).format(this.dateDisplayFormat)
        }
        return moment(this.value, this.dateFormat, true).isValid() // Date is valid in expected format
          ? moment(this.value).format(this.dateDisplayFormat)
          : moment(this.value).isValid() // In case of date comes in other format than expected
            ? moment(this.value).format(this.dateDisplayFormat)
            // Used in list date filter menu, has to support "today", "yesterday" etc strings
            : this.data && this.data.blurFieldName
              ? this.value
              : ''
      },
      set (value) {
        this.userInputValue = value
        const formattedDate = this.detectDateInputFromMultipleFormats(value)
        if (!value) {
          // If cleared from input or through picker, clear also for item object
          this.$emit('input', value)
        } else if (moment(value, this.dateDisplayFormat, true).isValid()) {
          // Date is updated through input in display format, convert it to save format
          this.$emit('input', moment(value, this.dateDisplayFormat, true).format(this.dateFormat))
        } else if (formattedDate && this.dateOnly) {
          // Use input was detected by one of the input formats, now show in display format
          this.$emit('input', formattedDate)
        } else if (this.dateOnly) {
          // For date only check if user manually did input date in quick input format
          dateQuickInputFormats.forEach(inputFormat => {
            if (moment(value, inputFormat, true).isValid()) {
              this.$emit('input', moment(value, inputFormat, true).format(this.dateFormat))
            } else {
              // this.$emit('input', value)
            }
          })
        } else {
          return
        }
        this.$nextTick(() => {
          this.updateSelectedDateTime(this.value)
          // Update specific form field datetime with date picker object
          this.datePickerSelectedDateTime = this.selectedDateTime
        })
      }
    }
  },

  watch: {
    selectedDate (value) {
      if (this.startWatching) {
        // TODO - default-time="23:59" should maybe be used also in regular form date+time fields?
        // So user does not have to select time always

        // Set default time if no time selected by user
        // Used in list view period filter when user only selects dates
        if (this.defaultTime && !this.selectedDateTime.time) {
          this.selectedDateTime.time = this.defaultTime
        }
        // Date is cleared, clear also time
        if (!value) {
          this.selectedDateTime.time = ''
        }
        this.$emit('input',
          value + (this.dateOnly
            ? ''
            : ' ' + this.selectedTime)
        )
        this.$emit('changeListener', 500)
      }
    },

    selectedTime (value) {
      if (this.startWatching) {
        this.$emit('input', this.selectedDate + ' ' + value)
        this.$emit('changeListener', 500)
      }
    },

    parentId () {
      this.updateSelectedDateTime(this.value)
    },
  },

  created () {
    this.$nextTick(() => {
      this.updateSelectedDateTime(this.value)
      // Set this after defaultTime is used
      // Has to be with some delay
      // otherwise selectedDateTime will get value in updateSelectedDateTime AFTER startWatching is set to true
      // Don't want to trigger 'changeListener' because there is an actual change in the value
      setTimeout(() => {
        this.startWatching = true
      }, 200)
    })
  },

  methods: {
    ...itemFieldMethods,

    detectDateInputFromMultipleFormats (userInput) {
      console.log(userInput)
      let formattedDate = ''
      if (!userInput) { return false }
      const formats = [
        'DD.MM.YYYY',
        'D.M.YYYY',
        'D.MM.YYYY',
        // 'MM/DD',
        // 'M/DD',
        // 'M/D/YYYY',
      ]
      formats.forEach(format => {
        if (moment(userInput, format, true).isValid()) {
          console.log('valid', format, moment(userInput, format, true).format(this.dateFormat))
          // Date is updated through input in display format, convert it to save format
          formattedDate = moment(userInput, format, true).format(this.dateFormat)
        }
      })
      return formattedDate
    },

    detectDateWithTimeInputOnBlur () {
      const formats = [
        'DD.MM.YYYY H.m',
        'DD.MM.YYYY H:m',
        'DD.MM.YYYY HH.mm',
        'DD.MM.YY H.m',
        'DD.MM.YY H:m',

        'D.M.YYYY H.m',
        'D.M.YYYY H:m',
        'D.M.YYYY HH.mm',
        'D.M.YY H.m',
        'D.M.YY H:m',

        'D.MM.YYYY H.m',
        'D.MM.YYYY H:m',
        'D.MM.YYYY HH.mm',
        'D.MM.YY H.m',
        'D.MM.YY H:m',

        'DD.MM.YYYY',
        'D.M.YYYY',
        'D.MM.YYYY',
        'D.M.YY',
        'D.M',
      ]
      formats.forEach(format => {
        if (moment(this.userInputValue, format, true).isValid()) {
          const value = moment(this.userInputValue, format, true).format(this.dateFormat)
          this.$emit('input', value)
          this.updateSelectedDateTime(value)
          this.userInputValue = ''
          return
        }
      })
    },

    // Date is also detected while typing, but in some formats it can be done only after blur
    detectDateInputOnBlur () {
      const formats = [
        'D.M.YY',
        'DD.MM',
        'D.M.YY',
        'D.MM',
        'D.M',
      ]
      formats.forEach(format => {
        if (moment(this.userInputValue, format, true).isValid()) {
          const value = moment(this.userInputValue, format, true).format(this.dateFormat)
          this.$emit('input', value)
          this.updateSelectedDateTime(value)
          this.userInputValue = ''
          return
        }
      })
    },

    focus (e) {
      this.autoSelectTextOnFocus(e)
    },

    showPickerMenu (e) {
      // Set small timeout for possible previously focused field blur actions can finish
      // Ex: list view period dates filter
      setTimeout(() => {
        return new Promise(resolve => {
          this.$store.dispatch('closeItemPicker')
          this.datePickerSelectedDateTime = this.selectedDateTime
          this.datePickerDateOnly = this.dateOnly
          this.showDatePickerMenu = true
          this.$nextTick(() => {
            util.positionPopupMenu(e, 'date-time-picker-container', 0, 0, {
              attachEl: this.$refs.inputRef.$el,
            })
          })
          resolve()
        })
      }, 200)
    },

    updateSelectedDateTime (value) {
      let time = '' // '00:00'
      let date = '' // mull
      // Set date and time from component property
      if (value && moment(value, this.dateFormat, true).isValid()) {
        [date, time] = value.split(' ')
        if (this.dateOnly) { time = '' }
      } else if (moment(value).isValid()) {
        date = moment(value).format('YYYY-MM-DD')
        time = moment(value).format('HH:mm')
      }
      this.selectedDateTime = {
        date,
        time,
      }
    },

    keydownHandler (e) {
      // For date field show only with down key
      if (e.key === 'ArrowDown') {
        this.showPickerMenu()
      } else if (e.key === 'Tab') {
        this.showDatePickerMenu = false
      }
      // Add timeout for date quick edit format to work, then send Tab for list edit to focus next editable field
      setTimeout(() => {
        this.$emit('keyPress', e)
      }, 200)
    },

    blurHandler () {
      if (!this.dateOnly && this.userInputValue) {
        this.detectDateWithTimeInputOnBlur()
      } else if (this.userInputValue) {
        this.detectDateInputOnBlur()
      }
      // Call blur handler of parent component
      // if there is one
      if (this.$parent && this.$parent.blurHandler) {
        this.$parent.blurHandler(this.data)
      }
    },
  },
}
</script>
