<template>
  <v-text-field
    :ref="field.name + '_input'"
    v-model="fieldComputedValue"
    v-tooltip.top="{
      delay: { show: 500, hide: 0 },
      content: tooltipText,
    }"
    :disabled="inputDisabled"
    :label="label"
    :placeholder="placeholder"
    color="#F57C00"
    background-color="white"
    class="header-input"
    autocomplete="off"
    spellcheck="false"
    outlined
    hide-details
    dense
    @keyup.enter="enterHandler"
    @keyup.esc="escapeKeyHandler"
    @focus="filterInputClick(field.name, $event)"
    @blur="blurField"
  >
    <template v-slot:append>
      <div
        slot="append"
        :class="isWideColumn ? 'header-input-icons' : 'header-input-icon'"
        @mousedown.stop="mouseDownOnHeaderIcons($event)"
      >
        <template v-if="isIndexed && isWideColumn">
          <i
            :class="sortIconClasses('fa fa-sort-up', field, 'asc')"
            @click.stop="sortByHeaderField(field.name, $event)"
          />
          <i
            :class="sortIconClasses('fa fa-sort-down', field, 'desc')"
            @click.stop="sortByHeaderField(field.name, $event)"
          />
        </template>
        <i
          class="fa fa-bars"
          @mousedown="mouseDownOnMenu(field.name)"
          @mouseup="mouseUpOnMenu($event)"
          @click.stop="showHeaderMenu(field.name, $event)"
        />
      </div>
    </template>
  </v-text-field>
</template>

<script>
import util from '@/utilities/sharedUtilities'
import methods from '@/components/methods'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
import translateAttribute from '@/utilities/translateAttribute'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

const columnDragStartDelay = 700
const showColumnNameTooltipWidthPx = 150
const narrowColumnPx = 80

export default {
  name: 'HeaderColumnInput',

  props: {
    field: {
      type: Object,
      default: () => Object,
    },
    fieldValue: {
      type: String,
      default: '',
    },
    cellIndex: {
      type: Number,
      default: null,
    },
  },

  data () {
    return {
      fieldChangedValue: null,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    layoutProfileIsLocked () {
      return this.selectedLayoutProfile.locked
    },

    fixedWidth () {
      return this.field.userFixedWidth || this.field.forcedWidth
    },

    isWideColumn () {
      return !!(this.fixedWidth && this.fixedWidth > narrowColumnPx)
    },

    showColumnNameTooltip () {
      return !(this.fixedWidth && this.fixedWidth > showColumnNameTooltipWidthPx)
    },

    inputDisabled () {
      return !!(this.listFieldsByName[this.field.name].custom_value ||
        !this.isIndexed)
    },

    isIndexed () {
      return this.listFieldsByName[this.field.name].indexed
    },

    placeholder () {
      return !this.isIndexed
        ? this.$i18n.t('aava.general.not_searchable')
        : ''
    },

    tooltipText () {
      return !this.listFieldsByName[this.field.name].indexed
        ? this.$i18n.t('aava.general.not_searchable_tooltip')
        : this.showColumnNameTooltip
          ? this.label
          : ''
    },

    label () {
      return translateAttribute(this.objectClass, this.field.name, this.locale, this.$i18n)
    },

    fieldComputedValue: {
      get () {
        return this.fieldValue
      },
      set (value) {
        this.fieldChangedValue = value
      },
    },
  },

  watch: {
    fieldChangedValue (value) {
      this.focusedFieldFilterValue = value
    },
  },

  created () {
    this.fieldChangedValue = this.fieldValue
  },

  methods: {
    ...methods,

    enterHandler () {
      this.$emit('updateFieldValue', { field: this.field, value: this.fieldChangedValue })
      this.reloadListItems()
    },

    sortIconClasses (iconClass, field, direction) {
      const classes = [iconClass]
      const sortByIsApplied = this.sortField === field.name && this.sortBy === direction
      const secondarySortByIsApplied = this.secondarySortings[field.name]?.sortBy === direction
      if (sortByIsApplied) {
        classes.push('sort-active')
        classes.push('sort-active-' + direction)
      } else if (secondarySortByIsApplied) {
        classes.push('sort-active-secondary')
        classes.push('sort-active-secondary-' + direction)
      }
      if (this.layoutProfileIsLocked) {
        // Now user can sort locked lists Aava-Vue-602
        // classes.push('disabled-cursor')
      }
      return classes
    },

    showHeaderMenu (fieldName, e) {
      if (!this.draggingFieldName) {
        this.showHeaderFieldMenu(fieldName, e)
      }
    },

    blurField () {
      // Update filter value without reloading list
      // this is how previous list-view version worked.
      // User can enter search term for 'first name' then use TAB to go to next
      // field 'last name', enter search term and press enter
      this.$emit('updateFieldValue', { field: this.field, value: this.fieldChangedValue })
      this.focusedFieldBefore = this.focusedField
      // Set focused field to null
      // unless 'main_object_state', as this field is auto-blurred on click
      // Otherwise pin icon is not shown
      if (this.focusedField !== 'main_object_state') {
        this.focusedField = null
      }
    },

    filterInputClick (fieldName, e) {
      const refEl = document.getElementById(fieldName + '_th')
      this.focusedField = fieldName
      this.showStateOptions(fieldName, refEl, e)
      this.showUniqueValueFilterOptions(fieldName, refEl, e)
      // Show date select popup
      this.showDateSelectPopup(fieldName, refEl, e)
    },

    showStateOptions (fieldName, refEl, e) {
      const topOffset = this.$store.getters.listHeaderColumnTitleMenuTopOffset
      if (fieldName !== 'main_object_state') { return }
      // Deny manual input
      this.blurHeaderSearchInput(fieldName)
      // Show state options popup
      const stateOptionsMenuLeft = refEl.offsetLeft - this.scrolledLeft + 2
      this.$nextTick(() => {
        this.showHeaderColumnStatesMenu = true
        this.$nextTick(() => {
          util.positionPopupMenu(e, 'state-options-menu', stateOptionsMenuLeft, topOffset, {})
        })
      })
      this.focusedField = fieldName
    },

    showUniqueValueFilterOptions (fieldName, refEl, e) {
      // TODO - test this. Not activated in Oma?
      const topOffset = 0
      this.uniqueValueFilterOptions = []
      if (this.$store.getters.layoutProfileItemsByName[fieldName].unique_values_support) {
        const uniqueValueFilterOptionsMenuLeft = refEl.offsetLeft + 2
        this.$store.dispatch('loadFieldUniqueValues', fieldName).then(() => {
          if (this.focusedField !== fieldName || this.uniqueValueFilterOptions.length < 1) {
            return
          }
          this.$nextTick(() => {
            this.showHeaderColumnUniqueValuesMenu = true
            this.$nextTick(() => {
              util.positionPopupMenu(e, 'unique-value-options-menu', uniqueValueFilterOptionsMenuLeft, topOffset, {})
            })
          })
        })
      }
    },

    showDateSelectPopup (fieldName, refEl, e) {
      if (!['datetime', 'date'].includes(this.listFieldsByName[fieldName]?.type)) { return }
      this.listHeaderDateFilterLeft = refEl.offsetLeft - this.scrolledLeft + 2
      this.listHeaderDateFilterField = fieldName
      const topOffset = this.$store.getters.listHeaderColumnTitleMenuTopOffset
      const menuOffsetLeft = refEl.offsetLeft - this.scrolledLeft + 2
      this.$nextTick(() => {
        util.positionPopupMenu(e, 'date-filter-menu', menuOffsetLeft, topOffset, {})
      })
    },

    // Remove focus from search input field
    blurHeaderSearchInput (fieldName) {
      this.$refs[fieldName + '_input'].blur()
    },

    showHeaderFieldMenu (fieldName, e) {
      const topOffset = 0
      const leftOffset = -16 //  + this.scrolledLeft
      this.listHeaderDateFilterField = null
      this.blurHeaderSearchInput(fieldName)
      this.listColumnMenuField = this.listColumnMenuField !== fieldName ? fieldName : null
      this.$nextTick(() => {
        util.positionPopupMenu(e, 'list-header-menu', leftOffset, topOffset, {
          addOffset: false
        })
      })
    },

    mouseDownOnMenu (fieldName) {
      if (this.layoutProfileIsLocked) { return }
      this.timer = setTimeout(() => {
        this.activateColumnDrag(fieldName)
      }, columnDragStartDelay)
    },

    mouseDownOnHeaderIcons (e) {
      // setting mousedown coords because listViewMouseDownHandler is not called
      // even without .stop mousedown here
      this.mouseDownCoords.x = e.pageX
      this.mouseDownCoords.y = e.pageY
    },

    mouseUpOnMenu (e) {
      if (this.layoutProfileIsLocked) { return }
      if (!this.draggingFieldName && !this.resizingFieldName) {
        clearTimeout(this.timer)
        e.stopPropagation()
      }
    },

    activateColumnDrag (fieldName) {
      this.draggingFieldName = fieldName
    },
  },
}
</script>

<style lang="scss">
.header-input {
  i {
    color: #aaa;
    cursor: pointer;
  }
	.fa-bars {
		font-size: 14px !important;
		position: absolute !important;
		top: -3px;
		padding: 8px 4px 8px 2px;
		right: -3px;
	}
	.fa-sort-up {
		font-size: 16px !important;
		padding: 7px 2px 0 2px;
		position: absolute !important;
		top: -3px;
		right: 16px;
	}
	.fa-sort-down {
		font-size: 16px !important;
		padding: 3px 2px 4px 2px;
		position: absolute !important;
		top: 1px;
		right: 16px;
	}
	.todo-v-input__icon {
		height: 14px !important;
		min-width: 14px !important;
		width: 14px !important;
	}
}
.list-columns {
  .v-input input {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #F57C00 !important;
  }
}
.v-input--is-disabled {
  input {
    /* font-size: 12px !important; */
  }
  .v-input__append-inner, .v-input__append-inner>div {
    color: #aaa !important;
  }
}
.v-input__append-outer {
	margin-left: 2px !important;
}
.sort-active {
	color: red !important;
}
.sort-active-secondary {
	color: #F57F17 !important;
}
.header-input-icons {
	position: relative;
	width: 27px;
}
.header-input-icon {
	position: relative;
	width: 14px;
}
.todo-TODO-v-text-field--outline {
	>.v-input__control {
		>.v-input__slot {
			background: white !important;
		}
	}
}
.v-input--is-disabled:not(.v-input--is-readonly) {
  pointer-events: auto !important;
}
</style>
