var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"item-form-container pa-0 form-visual-demo",attrs:{"fill-height":"","fluid":""}},[_c('div',{staticClass:"containers-outer"},[_c('div',{staticClass:"container-form"},[_c('v-container',{staticClass:"field-set-form pa-0",attrs:{"fluid":""}},[_c('table',{staticClass:"field-set-form-inner-container",staticStyle:{"width":"100%"}},_vm._l((_vm.fields),function(field,i){return _c('tr',{key:i},[_c('td',{staticClass:"single-field-set"},[_c('FormField',_vm._b({attrs:{"edit":false,"field":{
                  ...field,
                  label: field.title + ' show view',
                }}},'FormField',_vm.bindProps,false))],1),_c('td',{staticClass:"single-field-set"},[_c('FormField',_vm._b({attrs:{"edit":true,"field":{
                  ...field,
                  label: field.title + ' edit readonly',
                  writable: false,
                }}},'FormField',_vm.bindProps,false))],1),_c('td',{staticClass:"single-field-set"},[_c('FormField',_vm._b({attrs:{"edit":true,"field":{
                  ...field,
                  label: field.title + ' edit view',
                  writable: true,
                }}},'FormField',_vm.bindProps,false))],1),_c('td',{staticClass:"multiple-field-sets"},[_c('FormField',_vm._b({attrs:{"edit":false,"field":{
                  ...field,
                  label: field.title + ' show view',
                }}},'FormField',_vm.bindProps,false))],1),_c('td',{staticClass:"multiple-field-sets"},[_c('FormField',_vm._b({attrs:{"edit":true,"field":{
                  ...field,
                  label: field.title + ' edit readonly',
                  writable: false,
                }}},'FormField',_vm.bindProps,false))],1),_c('td',{staticClass:"multiple-field-sets"},[_c('FormField',_vm._b({attrs:{"edit":true,"field":{
                  ...field,
                  label: field.title + ' edit view',
                  writable: true,
                }}},'FormField',_vm.bindProps,false))],1)])}),0)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }