
import util from '@/utilities/sharedUtilities'
import { LP } from '@/types/LP.types'
import { Field } from '@/types/FieldTypes'

export default {
  name: 'ContentFiles',

  props: {
    field: {
      type: Object as () => LP.Item,
      required: true,
    },
    cellValue: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    files (): Field.File[] {
      return this.cellValue && this.cellValue.value || []
    }
  },

  methods: {
    showFilesList (e, items) {
      if (e && util.clickOrDrag(e, this.$store.state.mouseDownCoords) === 'drag') {
        return
      }
      e.stopPropagation()
      this.$nextTick(() => {
        this.$store.commit('updateValues', {
          showHasManyListMenu: true,
          hasManyListItems: items,
        })
        this.positionPopup(e)
      })
    },

    positionPopup (e) {
      const topOffset = 0
      const leftOffset = -10
      this.$nextTick(() => {
        util.positionPopupMenu(e, 'has-many-list-menu', leftOffset, topOffset, {})
      })
    }
  }
}
