
import methods from './../methods'
import state from './../../store/state'
import { createHelpers } from 'vuex-map-fields'
import translateAttribute from '@/utilities/translateAttribute'
import { FieldType, LPI } from '@/types/LP.types'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

interface FieldCategory {
  types: FieldType[]
  icon: string
  text?: string
}
type ExtendedFieldType = FieldType | 'list' | 'html'

type FieldCategoriesMap = {
  [key in ExtendedFieldType]?: FieldCategory
}

const fieldCategories: FieldCategoriesMap = {
  string: { types: ['string', 'search_string', 'password'], icon: '', text: 'A' },
  text: { types: ['text', 'richtext', 'password'], icon: '', text: '[A]' },
  numeric: { types: ['numeric', 'decimal', 'percentage'], icon: 'fa-1' },
  address: { types: ['address'], icon: 'fa-location-dot' },
  datetime: { types: ['datetime', 'date', 'date_range'], icon: 'fa-calendar' },
  reference: { types: ['static_list', 'dynamic_list', 'reference', 'polymorphic_autocomplete', 'link', 'belongs_to', 'has_one'], icon: 'fa-link' },
  list: { types: ['has_many_reference', 'has_many', 'has_and_belongs_to_many'], icon: 'fa-list-ol' },
  quantity: { types: ['quantity'], icon: 'fa-1' },
  boolean: { types: ['boolean'], icon: 'fa-check-square' },
  price: { types: ['price'], icon: 'fa-euro-sign' },
  file: { types: ['file', 'image', 'flash', 'video', 'pdf'], icon: 'fa-paperclip' },
  process: { types: ['process', 'state', 'process_events', 'process_state'], icon: 'fa-play' },
  html: { types: ['raw', 'raw_full'], icon: 'fa-code' },
}

export default {
  name: 'ItemFieldSelector',

  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    render: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: String,
      default: null,
    },
    showItemFieldMenu: {
      type: Function,
      default: () => {},
    },
    toggleLayoutEditorSelectedField: {
      type: Function,
      default: () => {},
    },
  },

  data () {
    return {
      searchTerm: null,
      selectedCategory: null,
      fieldCategories,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    firstSelectedField () {
      return this.fieldsComputed.filter(field => this.selectedLayoutEditorFields.includes(field.name))?.[0]
    },

    iconsByType () {
      const iconsByType = {}
      Object.keys(this.fieldCategories).forEach(category => {
        this.fieldCategories[category].types.forEach(type => {
          iconsByType[type] = this.fieldCategories[category]
        })
      })
      return iconsByType
    },

    visibleFieldSetIds () {
      const fieldSets = this.layoutContainerFieldSets[this.selectedLayoutProfileIdByModel[this.resource]] || []
      return fieldSets.map(i => i.id)
    },

    fieldsComputed () {
      const fields = this.fields.map(field => {
        field.label = this.getFieldLabel(field)
        return field
      })
      return fields
        .filter(field => {
          if (!field.isLPI) { // Skip form extra types here
            return false
          }
          // Exclude for non-aava (admin) users
          if (['readers', 'writers'].includes(field.name) && !this.$store.getters.isAdminUser) {
            return false
          }
          const searchMatch = !this.searchTerm || field.label.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
          const typeMatch = !this.selectedCategory || this.selectedCategory.types.includes(field.type)
          return searchMatch && typeMatch
        })
        .sort((a, b) => a.label < b.label ? -1 : 1)
        .sort((a, b) => a.type > b.type ? -1 : 1)
    },
  },

  methods: {
    ...methods,

    highlightField (field) {
      if (!document.getElementsByClassName('form-field-' + field.name)?.[0]) { return }
      this.highlightedFieldName = field.name
      this.$vuetify.goTo('.form-field-' + field.name, {
        duration: 300,
        easing: 'easeInOutCubic',
        offset: 50,
        container: '.item-form-container',
      })
    },

    selectCategory (category) {
      this.selectedCategory = this.selectedCategory === category ? null : category
    },

    isFieldSelected (field) {
      return this.selectedLayoutEditorFields.includes(field.name)
    },

    chipColor (field) {
      if (this.isFieldSelected(field)) {
        // return 'red'
      }
      return field.visible && this.fieldInFieldSet(field) ? '#E0E0E0' : 'white'
    },

    fieldClasses (field: LPI) {
      return [
        'form-field-selectable',
        'field-set-field',
        'mb-1',
        'pl-0',
        'le-select-' + field.name,
        (this.isFieldSelected(field) && 'selected-layout-editor-field'),
      ]
    },

    getFieldLabel (field) {
      return translateAttribute(this.resource, field.name, this.locale, this.$i18n, 'show')
    },

    fieldInFieldSet (field) {
      return field.layout_container_field_set &&
        field.layout_container_field_set.id &&
        this.visibleFieldSetIds.includes(field.layout_container_field_set.id)
    },

    fieldIcon (field) {
      if (!this.iconsByType[field.type]?.icon && !this.iconsByType[field.type]?.text) {
        console.warn('Handle missing file type:', field.type)
        return 'fa-check'
      }
      return this.iconsByType[field.type].icon
    },

    fieldIconText (field) {
      return this.iconsByType[field.type]?.text
    },
  }
}
