<template>
  <div
    v-if="showDatePickerMenu"
    id="date-time-picker-container"
    ref="date-time-picker-container"
    class="elevation-5"
  >
    <v-row
      wrap
      no-gutters
      class="datetime-picker-container"
    >
      <v-col
        :cols="datePickerDateOnly ? 12 : 6"
        class="no-show-date-picker"
      >
        <v-date-picker
          v-model="datePickerSelectedDateTime.date"
          color="orange"
          header-color="grey"
          next-icon="fa-arrow-circle-right"
          prev-icon="fa-arrow-circle-left"
          first-day-of-week="1"
          locale-first-day-of-year="4"
          :locale="locale"
          :show-week="true"
          @change="dateSelected()"
        />
      </v-col>
      <v-col
        v-if="!datePickerDateOnly"
        cols="6"
        class="no-show-date-picker"
      >
        <v-time-picker
          v-model="datePickerSelectedDateTime.time"
          color="orange"
          header-color="grey"
          format="24hr"
        />
      </v-col>
    </v-row>
    <v-row
      class="date-picker-buttons py-3"
      no-gutters
    >
      <v-col
        cols="12"
        align="center"
      >
        <v-btn
          fab
          class="elevation-0 mr-3"
          color="grey"
          dark
          small
          @click.stop="showDatePickerMenu = false"
        >
          <v-icon>
            fa-times
          </v-icon>
        </v-btn>
        <v-btn
          fab
          :class="'elevation-0 ' + (!datePickerDateOnly ? 'mr-3' : '')"
          color="grey"
          dark
          small
          @click.stop="emptyDateTime()"
        >
          <v-icon>
            fa-eraser
          </v-icon>
        </v-btn>
        <v-btn
          v-if="!datePickerDateOnly"
          fab
          class="elevation-0"
          color="green darken-1"
          dark
          small
          @click.stop="showDatePickerMenu = false"
        >
          <v-icon>
            fa-check
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import Vue from 'vue'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'DateTimePickerMenu',

  computed: {
    ...mapFields(Object.keys(state)),
  },

  watch: {
    showDatePickerMenu (value) {
      if (value) {
        setTimeout(() => {
          if (this.showDatePickerMenu) {
            document.addEventListener('click', this.clickListener)
          }
        }, 300) // Wait for click event end, otherwise closes popup menu immediately for smaller screens
      } else {
        document.removeEventListener('click', this.clickListener)
      }
    }
  },

  created () {
    document.addEventListener('keyup', this.listenKeyUp)
  },

  destroyed () {
    document.removeEventListener('keyup', this.listenKeyUp)
  },

  methods: {
    ...methods,

    dateSelected () {
      if (this.datePickerDateOnly) {
        // Set timeout for click not to trigger click listeners behind date picker popup
        // Ex: list view date search popup behind date picker
        setTimeout(() => {
          this.showDatePickerMenu = false
        }, 50)
      }
    },

    emptyDateTime () {
      Vue.set(this.datePickerSelectedDateTime, 'date', '')
      Vue.set(this.datePickerSelectedDateTime, 'time', '')
      this.showDatePickerMenu = false
    },

    clickListener (e) {
      if (this.showDatePickerMenu) {
        const el = this.$refs['date-time-picker-container']
        if (el && !el.contains(e.target)) {
          this.showDatePickerMenu = false
        }
      }
    },

    // Keys are listened in the body but this component prevents keyup triggers
    listenKeyUp (e) {
      if (!this.showDatePickerMenu) { return }
      switch (e.key) {
        case 'Escape':
          this.escapeKeyHandler()
          break
      }
    },
  }
}
</script>

<style lang="less">
#date-time-picker-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background: white;
  overflow: hidden; /* to force border radius */
  border-radius: 6px;
}
.datetime-picker-container {
	background: #fff;
}
.no-show-date-picker {
	.v-card {
		box-shadow: none !important;
	}
	.v-picker {
		border-radius: 0 !important;
	}
  /* Fix to set toolbar height same as date picker has. Used to be same height, but Vuetify changed something in new version. */
  .v-picker__title {
    height: 88px;
    padding-top: 8px;
  }
}
.v-date-picker-header {
  .fa-arrow-circle-right, .fa-arrow-circle-left {
    height: 32px !important;;
    font-size: 32px !important;;
    width: 32px  !important;;
    color: #aaa !important;
  }
}
.date-picker-buttons {
  background: #ddd;
}
</style>
