import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import '@fortawesome/fontawesome-free/css/all.css'
import VueI18n from 'vue-i18n'
import i18n from './locale/i18n'
import VTooltip from 'v-tooltip'
import vuetify from './plugins/vuetify'
import VueQuillEditor from 'vue-quill-editor'
import './registerServiceWorker'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import VueFullscreen from 'vue-fullscreen'
import VueSvgGauge from 'vue-svg-gauge'

import VuePivottable from 'vue-pivottable'
// With the new version the import does not.
// Copied the css file and importing in App.vue. Perhaps will work in future version.
// import 'vue-pivottable/dist/vue-pivottable.css'

Vue.use(VuePivottable)
Vue.use(VueSvgGauge)

if (!['dev.test', 'localhost'].includes(window.location.hostname)) {
  Raven.config('https://978dfe9b935a4570b47f0addb69045d1@sentry.aavasoftware.com/30',
    {
      // TODO in webpack version 5 can't use this
      environment: 'undefined' // (process && process.env.NODE_ENV) ? process.env.NODE_ENV :
    })
    .addPlugin(RavenVue as Raven.RavenPlugin, Vue)
    .install()
}

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(VueQuillEditor)
Vue.use(VueFullscreen)
Vue.use(require('vue-shortkey'))

Vue.use(VTooltip, {
  defaultDelay: { show: 300, hide: 100 },
  // For has-many list first row to set tooltip to the top (not to cover the input)
  defaultBoundariesElement: document.body,
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
