<template>
  <v-container
    :style="alternativeViewStyle"
    class="white px-4 timeline-view"
    fluid
  >
    <div v-if="!loading">
      <v-row dense>
        <v-col cols="12">
          <v-toolbar
            color="grey lighten-2"
            class="elevation-0"
          >
            <v-container
              class="pa-0"
              fluid
            >
              <v-row dense>
                <v-col cols="3">
                  <v-toolbar-title>
                    {{ $i18n.t('aava.index.timeline.title') }}
                  </v-toolbar-title>
                </v-col>
                <v-col
                  cols="6"
                  class="text-center align-self-center align-content-center"
                >
                  <ListHiddenFilters />
                </v-col>
                <v-col cols="3">
                  <v-btn
                    :disabled="saving || refreshing"
                    :dark="!saving && !refreshing"
                    class="run-report"
                    color="green darken-1"
                    block
                    @click="updateHandler"
                  >
                    <v-icon
                      small
                      class="mr-3"
                      color="white"
                    >
                      fa-sync-alt
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-toolbar>
          <v-toolbar
            color="grey lighten-3"
            class="elevation-0"
          >
            <v-container
              fluid
              class="px-0"
            >
              <v-row dense>
                <v-col
                  v-for="(fieldName, index) in periodFields"
                  :key="index + '_dt'"
                  class="timeline-select"
                >
                  <v-autocomplete
                    v-model="configuration[fieldName + '_attribute']"
                    :items="dateFields"
                    :label="$i18n.t('aava.index.timeline.' + fieldName + '_attribute')"
                    item-text="translation"
                    item-value="name"
                    clear-icon="fa-times"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
                <v-col
                  v-for="(fieldName, index) in groupFields"
                  :key="index"
                  class="timeline-select"
                >
                  <v-autocomplete
                    v-model="configuration[fieldName + '_attribute']"
                    :items="textFields"
                    :label="$i18n.t('aava.index.timeline.' + fieldName + '_attribute')"
                    item-text="translation"
                    item-value="name"
                    clear-icon="fa-times"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
          class="pt-2"
        >
          <div id="timeline-div-ruler" />
        </v-col>
        <v-col
          v-for="(timelineGroup, index) in timelineGroups"
          :key="index + '_tg'"
          cols="12"
        >
          <h3>{{ timelineGroup }}</h3>
          <div :id="'timeline-group-div-' + index" />
        </v-col>
      </v-row>
    </div>
    <v-progress-linear
      v-else
      :indeterminate="true"
      height="3"
      color="teal"
    />
  </v-container>
</template>

<script>
import listFilters from './../../store/_listFilters'
import moment from 'moment'
import ListHiddenFilters from './../ListHeaderActions/ListHiddenFilters'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import { DataSet } from 'vis-data'
import state from '@/store/state'
import sharedComputed from '@/sharedComputed'
import timelineMethods from './timelineMethods'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

const baseVisOptions = {
  zoomable: true,
  zoomMin: 28800000, // 8 hours
  zoomMax: 31556952000, // 1 year
  orientation: 'top',
  clickToUse: true,
}

const isReferenceType = (type) => {
  return ['polymorphic_autocomplete', 'reference', 'state'].includes(type)
}

const queryAttributeForField = (configuration, prefix) => {
  const type = configuration[`${prefix}_attribute_type`]
  let attr = configuration[`${prefix}_attribute`]
  if (isReferenceType(type)) attr += ' summary'
  if (type === 'state') attr += ',initial_state,final_state,background_color,text_color'
  return attr
}

export default {
  name: 'TimelineView',

  components: {
    ListHiddenFilters
  },

  data () {
    return {
      colorMap: {},
      timelineColors: null,
      dataGroups: new DataSet(),
      dataGroupsByTimelineGroup: [],
      dataItems: new DataSet(),
      end: null,
      endMoment: null,
      groupFields: ['timeline_group', 'group', 'color_group'],
      loading: true,
      periodFields: ['start_time', 'end_time'],
      rangeEnd: null,
      rangeStart: null,
      refreshing: false,
      ruler: null,
      saving: false,
      start: null,
      startMoment: null,
      textColorMap: {},
      textFieldTypes: ['polymorphic_autocomplete', 'reference', 'state', 'string', 'search_string'],
      timeline: null,
      timelineGroups: [],
      timelineItems: [],
      timelineItemsByTimelineGroup: [],
      timelines: [],
      visOptions: baseVisOptions,
      configuration: {
        start_time_attribute: '',
        start_time_attribute_type: '',
        end_time_attribute: '',
        end_time_attribute_type: '',
        timeline_group_attribute: '',
        timeline_group_attribute_type: '',
        group_attribute: '',
        group_attribute_type: '',
        rangeChangeTimer: null,
      }
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
    ...sharedComputed,

    startTimeAttribute () { return this.configuration.start_time_attribute },

    endTimeAttribute () { return this.configuration.end_time_attribute },

    timelineGroupAttribute () { return this.configuration.timeline_group_attribute },

    groupAttribute () { return this.configuration.group_attribute },

    groupIsReference () {
      return !!(this.configuration.group_attribute_type &&
        isReferenceType(this.configuration.group_attribute_type))
    },

    textFields () {
      return this.layoutProfileItems
        .filter(field => this.textFieldTypes.includes(field.type))
        .map(field => {
          field.translation = this.$i18n.t(this.objectClass + '.attributes.' + field.name)
          return field
        })
        .sort((prev, cur) => (prev.translation > cur.translation)
          ? 1
          : ((cur.translation > prev.translation) ? -1 : 0))
    },

    dataGroupsByTimelineGroupComputed () {
      const dataGroups = []
      this.dataGroupsByTimelineGroup.forEach((groups, timelineIndex) => {
        dataGroups[timelineIndex] = new DataSet()
        dataGroups[timelineIndex].update(groups.map((groupInfo, index) => {
          return {
            id: index + 1,
            content: typeof groupInfo === 'object' ? groupInfo.summary : groupInfo
          }
        }))
      })
      return dataGroups
    },

    dateFields () {
      return this.layoutProfileItems
        .filter(field => ['date', 'datetime'].includes(field.type))
        .map(field => {
          field.translation = this.$i18n.t(this.objectClass + '.attributes.' + field.name)
          return field
        })
        .sort((prev, cur) => (prev.translation > cur.translation)
          ? 1
          : ((cur.translation > prev.translation) ? -1 : 0))
    },

    queries () {
      const queries = ['summary']
      if (this.configuration.start_time_attribute) {
        queries.push(this.configuration.start_time_attribute)
      }
      if (this.configuration.end_time_attribute) {
        queries.push(this.configuration.end_time_attribute)
      }
      this.groupFields.forEach(field => {
        if (this.configuration[field + '_attribute']) {
          queries.push(queryAttributeForField(this.configuration, field))
        }
      })
      return queries
    },

    filters () {
      return [...listFilters.getSearchFilters(this.$store.state), {
        key: '_' + this.startTimeAttribute,
        value: '..' + moment(this.endMoment).format('DD.MM.YYYY HH:mm')
      }, {
        key: '_' + this.endTimeAttribute,
        value: moment(this.startMoment).format('DD.MM.YYYY HH:mm') + '..'
      }, {
        key: 'limit',
        value: '*'
      }]
    },

    rulerOptions () {
      return {
        ...this.visOptions,
        ...{
          start: this.startMoment,
          end: this.endMoment,
          height: '48px'
        }
      }
    },

    options () {
      return {
        ...this.visOptions,
        ...{
          start: this.startMoment,
          end: this.endMoment,
          stack: true,
          multiselect: true,
          editable: {
            add: true,
            updateTime: true,
            updateGroup: true,
            remove: false
          },
          margin: {
            item: {
              horizontal: 0,
              vertical: 5
            },
            axis: 5,
          },
          snap: null,
          onUpdate: (item) => this.saveItem(item),
          onMove: (item) => this.saveItem(item),
        },
      }
    },

    listPermanentFiltersCheckString () {
      const filters = []
      Object.keys(state.listFilters).forEach(filterName => {
        if (state.listFilters[filterName]) {
          filters.push(filterName)
        }
      })
      return filters.join(',')
    }
  },

  watch: {
    listPermanentFiltersCheckString (value, valueBefore) {
      if (!valueBefore) { return } // Don't run on first load
      setTimeout(() => {
        this.initialize()
      }, 500)
    },

    objectClass (value) {
      if (!value) { return }
      this.initialize()
    },

    startTimeAttribute (value) {
      if (!this.layoutProfileItemExists(value)) { return }
      this.configuration.start_time_attribute_type = this.$store.getters
        .layoutProfileItemsByName[value].type
    },

    endTimeAttribute (value) {
      if (!this.layoutProfileItemExists(value)) { return }
      this.configuration.end_time_attribute_type = this.$store.getters
        .layoutProfileItemsByName[value].type
    },

    timelineGroupAttribute (value) {
      if (!this.layoutProfileItemExists(value)) { return }
      this.configuration.timeline_group_attribute_type = this.$store.getters
        .layoutProfileItemsByName[value] &&
        this.$store.getters.layoutProfileItemsByName[value].type
    },

    groupAttribute (value) {
      if (!this.layoutProfileItemExists(value)) { return }
      this.configuration.group_attribute_type = this.$store.getters
        .layoutProfileItemsByName[value] &&
        this.$store.getters.layoutProfileItemsByName[value].type
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    ...methods,
    ...timelineMethods,
  },
}
</script>

<style lang="scss">
.v-input__icon--clear {
  i {
    font-size: 16px;
  }
}
.timeline-select {
  z-index: 11 /* fix timeline being on top of select menus */
}
</style>
