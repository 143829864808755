<template>
  <div
    :class="classes"
    :style="style"
    @mouseout="mouseOut(rowPoolIndex, $event)"
    @mouseover="mouseOver(rowPoolIndex, $event)"
  >
    <div
      v-if="listItem && listItem.deleted"
      :style="contentCellWide"
      class="content-cell conten-cell-wide"
    >
      <i class="list-item-deleted">
        {{ $i18n.t('aava.messages.removed') }}
      </i>
    </div>
    <div
      v-if="showItemLoader"
      :style="contentCellWide"
      class="content-cell content-cell-wide"
    >
      <div class="content-item-loader">
        <v-progress-linear
          :background-color="itemIndex % 2 === 0 ? '#f7f7f7' : '#e8e8e8'"
          :color="itemIndex % 2 === 0 ? '#e8e8e8' : '#f7f7f7'"
          height="5"
          value="100"
          rounded
          indeterminate
        />
      </div>
    </div>
    <template v-else-if="!measuringColumnWidths || rowsToMeasureWidthsFrom.includes(rowPoolIndex)">
      <div
        v-tooltip.right="(itemIndex + 1) +' / ' + listTotalCount"
        class="item-row-number"
        :style="itemRowNumberStyle"
      />
      <ContentCell
        v-for="(fieldName, cellIndex) in contentCells"
        :key="cellIndex + '_' + listItem.id + '_' + fieldName"
        :ref="'ContentCell'"
        :list-item="listItem"
        :cell-index="cellIndex"
        :field="visibleColumnsByName[fieldName]"
        :item-index="itemIndex"
        :background-altered="(listItem && listItem.id === selectedId)
          || (listItem && listItem.selected)"
      />
    </template>
  </div>
</template>

<script>
import methods from './../methods'
import ContentCell from './ContentCell'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ContentItem',

  components: {
    ContentCell,
  },

  props: {
    listItem: {
      type: Object,
      default: () => null,
    },

    itemIndex: {
      type: Number,
      default: null,
    },

    rowPoolIndex: {
      type: Number,
      default: null,
    },

    listTableWidth: {
      type: Number,
      default: null,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    showItemLoader () {
      return this.loadingListItems || !this.listItem || (this.listItem?.id === this.reFetchingItemId)
    },

    contentCells () {
      return !this.measuringColumnWidths
        ? this.cellPool
        : this.layoutProfileItems
          .filter(field => field.visible)
          .map(field => field.name)
    },

    itemRowNumberStyle () {
      const padding = 12
      return {
        height: (this.rowHeight + padding) + 'px'
      }
    },

    contentCellWide () {
      return {
        paddingLeft: this.scrolledLeft + 'px'
      }
    },

    classes () {
      return {
        'content-item': true,
        'content-item-odd': this.itemIndex % 2 !== 0,
        'content-item-even': this.itemIndex % 2 === 0,
        ['content-item-' + (this.listItem && this.listItem.id)]: true,
        'content-item-selected': !!(this.listItem && this.listItem.selected),
        'content-item-selected-one': !!(this.listItem && this.listItem.id === this.selectedId)
      }
    },

    style () {
      const style = {
        transform: 'translateY(' + (this.itemIndex * (this.rowPadding + this.rowHeight)) + 'px)',
        width: this.listTableWidth + 'px'
      }
      if (this.listItem?.id === this.listEdit?.current?.item?.id) { style.zIndex = 1 }
      return style
    }
  },

  methods: {
    ...methods,

    mouseOut () {
      this.hoverRowIndex = null
    },

    mouseOver (index) {
      this.hoverRowIndex = index + this.offsetItems
    },
  },
}
</script>

<style lang="stylus">
.item-row-number {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
}
.list-item-deleted
  margin-left 15px
.content-cell-wide
  position absolute
  width 100%
.content-item-loader
  max-width 180px
  padding-left 15px
  padding-top 6px
</style>
