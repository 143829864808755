
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
import TextField from './../Form/TextField.vue'
import { DashboardTypes } from '../../types/DashboardTypes'

const { mapFields }: any = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'DashboardTabForm',

  components: {
    TextField,
  },

  props: {
    item: {
      type: Object,
      default: () => { return {} },
    },
  },

  data () {
    return {
      saving: false,
      formData: {
        name: '',
      } as DashboardTypes.TabFormData,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    isNew () {
      return !this.item.id
    },
  },

  watch: {
    'item.id' (value) {
      if (!value) {
        this.setFormData()
      }
    },
  },

  created () {
    this.setFormData()
  },

  methods: {
    ...methods,

    saveForm () {
      this.$emit('save', this.formData)
      this.closeMenu()
    },

    setFormData () : void {
      this.formData = {
        id: this.item?.id,
        name: this.item?.name
      }
    },

    closeMenu () {
      this.$emit('close')
    },
  },
}
