import colors from './chartColors'

const legendLabelsGlobal = {
//   boxWidth: 10,
//   usePointStyle: true,
//   fontColor: 'grey',
//   afterFit: function (scale) {
//     scale.width = 280
//   },
}

const layoutGlobal = {
  padding: {
    // top: -40,
  }
}
const Color = require('color')

// var canvas = document.getElementById('canvas')

// var gradientThisWeek = canvas.getContext('2d').createLinearGradient(0, 0, 0, 150)
// gradientThisWeek.addColorStop(0, '#5555FF')
// gradientThisWeek.addColorStop(1, '#9787FF')

export default [
  {
    name: 'projects',
    key: 'projects.list.title',
    icon: 'fa-folder-open',
    title: 'Stock levels',
    chartInfo: {
      type: 'chart.js',
      chartType: 'line',
      chartData: {
        datasets2: [{
          label: 'My First Dataset',
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }],
        datasets:
          [{
            label: 'WH1',
            data: [22, 82, 32, 30, 16, 35, 17, 35, 23, 10, 25, 35],
            pointRadius: 0,
            color: 'grey',
            backgroundColor: Color(colors.purple_d1).fade(0.5).hsl().string(),
            // TODO - https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/createLinearGradient
            // Like here - https://codepen.io/rozklad/pen/jaNrWW
            createLinearGradient: {
              color1: '#5555FF',
              color2: '#9787FF',
            },
          }, {
            label: 'WH2',
            data: [23, 17, 45, 90, 100, 75, 44, 62, 32, 17, 44, 10],
            pointRadius: 0,
            color: 'grey',
            backgroundColor: Color(colors.blue_d2).fade(0.5).hsl().string(),
          }],
        labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S', 'M', 'T', 'W', 'T', 'F']
      },
      chartOptions: {
        title: { text: '', display: false },
        legend: {
          display: true,
          labels: legendLabelsGlobal,
        },
        scales: {
          x: {
            ticks: {
              display: false
            },
            grid: {
              display: false
            }
          },
          y: {
            min: 0,
            max: 100,
            grid: {
              display: true
            },
            ticks: {
              display: true,
              beginAtZero: true,
            }
          },
        },
      },
    },
    levels: 7,
    level: 5,
    points: 494,
    color: 'green',
    enabled: true,
  },
  {
    name: 'projects',
    key: 'projects.list.title',
    icon: 'fa-folder-open',
    title: 'Lead time',
    chartInfo: {
      type: 'chart.js',
      chartType: 'line',
      chartData: {
        datasets:
          [{
            data: [22, 82, 32, 30, 16, 35, 17, 35, 23, 17, 25, 35],
            pointRadius: 0,
            steppedLine: true,
            fill: true,
            createLinearGradient: {
              color1: '#5555FF',
              color2: '#9787FF',
            },
          }, {
            data: [23, 17, 45, 90, 100, 75, 44, 82, 32, 17, 44, 10],
            pointRadius: 0,
            fill: true,
            backgroundColor_: 'linear-gradient(#5555FF, #9787FF)',
          }],
        labels: ['', '', '', '', '', '', '', '', '', '', '', '']
      },
      chartOptions: {
        title: { text: '', display: false },
        legend: { display: false },
        scales: {
          x: {
            ticks: {
              display: false
            },
            grid: {
              display: false
            }
          },
          y: {
            min: 0,
            max: 100,
            grid: {
              display: false
            },
            ticks: {
              display: true,
              beginAtZero: true,
            }
          },
        },
      },
    },
    levels: 7,
    level: 5,
    points: 494,
    color: 'green',
    enabled: true,
  },
  {
    name: 'offers',
    key: 'sales_offers.list.title',
    icon: 'fa-file-invoice-dollar',
    background: 'linear-gradient(#66BB6A, #0097A7)',
    title: 'Inventory accuracy',
    titleColor: 'white',
    chartInfo: {
      type: 'chart.js',
      chartType: 'bar',
      chartData: {
        datasets:
          [{
            data: [16, 35, 17, 82, 32, 66],
            barPercentage: 1,
            backgroundColor: ['white', 'white', 'white', 'white', 'white', 'white'],
          },
          ],
        labels: [16, 35, 17, 82, 32, 66],
      },
      chartOptions: {
        title: { text: '', display: false },
        legend: { display: false },
        animation: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
              beginAtZero: true,
            }
          },
        },
      },
    },
  },
  {
    name: 'projects',
    key: 'projects.list.title',
    icon: 'fa-folder-open',
    background: 'white',
    title: 'Backorder rate',
    chartInfo: {
      type: 'chart.js',
      chartType: 'line',
      chartData: {
        datasets:
          [{
            data: [16, 35, -17, 35, 22, 82, 32, 66, 23, 17, 35, 42],
            backgroundColor: 'white',
          }],
        labels: ['', '', '', '', '', '', '', '', '', '', '', '']
      },
      chartOptions: {
        title: { text: '', display: false },
        legend: { display: false },
        scales: {
          x: {
            ticks: {
              display: false
            },
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            },
            ticks: {
              display: true,
              beginAtZero: true,
            }
          },
        },
      },
    },
    levels: 7,
    level: 5,
    points: 494,
    color: 'green',
    enabled: true,
  },
]
