<template>
  <div
    :class="classes"
    :style="style"
  >
    <ContentItem
      v-for="(itemIndex, rowPoolIndex) in rowPoolComputed"
      :key="rowPoolIndex"
      :list-item="listItems[itemIndex]"
      :item-index="itemIndex"
      :row-pool-index="rowPoolIndex"
      :list-table-width="listTableWidth"
    />
    <div v-if="listTotalCount === 0 && !loadingListItems">
      <div class="list-no-rows">
        {{ $i18n.t('aava.general.no_objects') }}
      </div>
    </div>
    <div
      v-if="listTableHeight === firefoxBrowserHeightLimit"
      class="list-max-limit-warning text-xs-center"
    >
      {{ $i18n.t('aava.index.messages.list_max_height_firefox_warning') }}
    </div>
  </div>
</template>

<script>
import ContentItem from './ContentItem'
import methods from './../methods'
import { createHelpers } from 'vuex-map-fields'
import state from './../../store/state'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

const { detect } = require('detect-browser')
const browser = detect()

export default {
  name: 'ListContent',

  components: {
    ContentItem,
  },

  data () {
    return {
      // Because there is alo a limit for translateY 8947848px, it has to be lower than
      // 17895708px which is max div height
      firefoxBrowserHeightLimit: 8500000
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    classes () {
      return [
        'list-table',
        (this.showListColumnBorder ? 'list-with-column-border' : ''),
        (this.$store.getters.inListEditMode && 'list-edit-mode'),
      ]
    },

    rowPoolComputed () {
      return this.loadingListItems
        // Ensure table is full of loader bars during list item load
        ? [...Array(100)].map((v, i) => i)
        : this.rowPool
    },

    listTableHeight () {
      let totalHeight = (this.rowHeight + this.rowPadding) * this.listTotalCount
      if (totalHeight >= this.firefoxBrowserHeightLimit &&
        browser && browser.name === 'firefox'
      ) {
        // Firefox is not able to show div height more than 17895708px
        totalHeight = this.firefoxBrowserHeightLimit
      }
      return totalHeight
    },

    listTableWidth () {
      let totalWidth = 0
      this.layoutProfileItems
        .filter(field => field.visible)
        .forEach(item => {
          totalWidth += (item.userFixedWidth || item.forcedWidth) || 500
        })
      return totalWidth
    },

    style () {
      const style = {
        height: this.listTableHeight + 'px',
        width: this.listTableWidth + 'px'
      }
      if (this.listTotalCount > 0) {
        style.overflow = 'hidden'
      }
      return style
    },

    showListColumnBorder () {
      return true
      // Apr 2023 decided to always show (Aava-Vue-497)
      // return this.$store.state.settings?.list_show_columns_separator_line === 'true' || this.$store.state.settings?.list_columns_separator_line_color
    },
  },

  methods: {
    ...methods,
  }
}
</script>

<style lang="stylus">
.list-table
  position relative
  display table
  border-collapse collapse
  margin-top 0 /* Now showing horizontal border, changed to 0 to show that. Was: -1px */
  border-bottom: 1px solid var(--list_columns_separator_line_color);
  background repeating-linear-gradient(
    180deg,
    var(--list_row_odd_background_color),
    var(--list_row_odd_background_color) calc(var(--row-height) + 12px),
    var(--list_row_even_background_color) calc(var(--row-height) + 12px),
    var(--list_row_even_background_color) calc((var(--row-height) + 12px) * 2)
    )
.list-no-rows
  font-size 22px
  padding 20px 10px
  background white
.reference-icon
  float left
  margin-right 4px
  font-size 10px
.list-max-limit-warning
  position absolute
  left 30px
  padding 10px 40px
  bottom -1px
  line-height 25px
  border 1px solid gray
  height 45px
  background #fff
  font-size 16px
  border-radius 5px 5px 0px 0px
</style>
