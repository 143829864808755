<template>
  <div
    class="has-many-reference-item d-flex"
  >
    <a
      :href="referenceItemUrl"
      :style="style"
      class="no-text-select"
      @click="checkAndStopSimpleMouseClick($event)"
      @mouseup="mouseUpHandler($event)"
    >
      <!-- i class="fa fa-link reference-icon" / -->
      <div v-html="linkName" />
    </a>
    <div
      v-if="!lastItem"
      class="has-many-line-separator"
    />
  </div>
</template>

<script>
import util from '../../../utilities/sharedUtilities'
import listItemCellMethods from './../../../methods/listItem/listItemCellMethods'
import listItemMethods from './../../../methods/listItem/listItemMethods'

export default {
  name: 'ContentHasManyReferenceItem',

  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },

    lastItem: {
      type: Boolean,
      default: false,
    },

    field: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    style () {
      return this.item['@association_style'] || ''
    },

    referenceItemUrl () {
      if (!this.item || !this.item['@class']) { return '' }
      return '#' + util.objectClassUnderscoredName(this.item['@class']) + '/' + this.item.id
    },

    linkName () {
      return this.item[(this.field?.reference_attribute) || 'summary'] || this.item.filename || '-'
    },
  },

  methods: {
    ...listItemMethods,
    ...listItemCellMethods,

    mouseUpHandler (event) {
      if (this.checkAndStopMetaKeyPress(event)) { return }
      event.stopPropagation()
      event.preventDefault()
      // If reference item is an attachment, open attachment, not model item record
      if (this.item['@class'] === 'Attachment') {
        this.openAttachmentFile(this.item)
        return
      }
      this.openReferenceLink(
        this.item.id,
        { className: this.item['@class'], event }
      )
    },
  }
}
</script>
