import { AxiosResponse } from 'axios'
import { LP } from '@/types/LP.types'
import Raven from 'raven-js'

export default {
  saveListFieldVisibility (layoutProfileItemId, visible) {
    const queries = [{
      key: 'visible',
      value: visible,
    }, {
      key: 'id',
      value: layoutProfileItemId,
    }]
    const filters = [{
      key: 'q',
      value: '!id',
    }]
    return this.sendPutRequest('/api/layout_profile_items/' + layoutProfileItemId, filters, queries)
  },

  saveListFieldEditableValue (layoutProfileItemId, editable_in_list) {
    const queries = [{
      key: 'editable_in_list',
      value: editable_in_list,
    }, {
      key: 'id',
      value: layoutProfileItemId,
    }]
    const filters = [{
      key: 'q',
      value: '!id',
    }]
    return this.sendPutRequest('/api/layout_profile_items/' + layoutProfileItemId, filters, queries)
  },

  saveListPermanentFilter (layoutProfileItemId, permanentFilterValue) {
    const queries = [{
      key: 'custom_value',
      value: permanentFilterValue,
    }, {
      key: 'id',
      value: layoutProfileItemId,
    }]
    const filters = [{
      key: 'q',
      value: '!id',
    }]
    return this.sendPutRequest('/api/layout_profile_items/' + layoutProfileItemId, filters, queries)
  },

  getLayoutProfileItems (layoutProfileId: number): Promise<AxiosResponse> {
    const queries = []
    const filters: Record<string, string | boolean> = {}
    if (window.location.hash.includes('myProfile')) {
      filters.my_profile = true
    }
    return new Promise(resolve => {
      this.sendRequest('/gorilla/layout_profiles/' + layoutProfileId + '/items', filters, queries).then((response: AxiosResponse) => {
        if (response.data.items) {
          response.data.items = response.data.items.sort((a, b) => {
            return (a.sort_order > b.sort_order) ? 1 : -1
          })
          // Set attributes from field-set config
          response.data.container_field_sets = response.data.container_field_sets?.map((fieldSet: LP.FieldSet) => {
            try {
              if (fieldSet.config) {
                const config = JSON.parse(fieldSet.config);
                ['bgColor'].forEach(fieldName => {
                  fieldSet[fieldName] = config[fieldName]
                })
              }
            } catch (e) {
              console.warn('FS not valid JSON', fieldSet.config, fieldSet)
            }
            return fieldSet
          })
          // Parse Container values from "config" JSON
          response.data.containers = response.data.containers?.map((container: LP.Container) => {
            container.showInfoText = false
            container.infoTextValues = {}
            try {
              if (container.config) {
                const config = JSON.parse(container.config)
                container.showInfoText = config.showInfoText || false
                container.infoTextValues = config.infoTextValues || {}
              }
            } catch (e) {
              // Critical to capture this error, possible data loss if JSON is broken
              // backend may do some altering of the JSON when sanitizing or smt else
              Raven.captureMessage('Error parsing container config', {
                level: 'error',
                extra: {
                  container,
                  config: container.config,
                  response,
                  e,
                }
              })
              alert('Error happened while parsing container info texts')
              console.warn('Container not valid JSON', container.config, container)
            }
            return container
          })
        }
        resolve(response)
      })
    })
  },

}
