
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import { AxiosResponse } from 'axios'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'NoSeSharedNotifications',

  data () {
    return {
      tab: null,
      headers: [
        {
          text: this.$i18n.t('aava.no_se.status'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.owner'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.name'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.type'),
          align: 'left',
          sortable: false
        },
        {
          text: this.$i18n.t('aava.no_se.description'),
          align: 'left',
          sortable: false
        }
      ]
    }
  },

  computed: {
    ...mapFields(Object.keys(state))
  },

  methods: {
    subscribe (item, subscribe) {
      this.$store.dispatch('noSeSubscribe', { item, subscribe }).then((success: boolean) => {
        if (success) {
          item.subscribed = subscribe
        }
      })
    },
  },
}
