<template>
  <v-col cols="12">
    <DateTime
      v-if="filter.type === 'date'"
      v-model="filterValues[filter.name]"
      :date-only="true"
      :field="{}"
      :label="filter.title"
    />
    <StaticDynamicList
      v-else-if="filter.type === 'select'"
      v-model="filterValues[filter.name]"
      :options="filter.options"
      :label="filter.title"
      :field="{}"
      spellcheck="false"
      dense
    />
  </v-col>
</template>

<script>
import DateTime from '@/components/Form/DateTime'
import StaticDynamicList from '@/components/Form/StaticDynamicList'

export default {
  name: 'CompatibilityViewReportFilter',

  components: {
    StaticDynamicList,
    DateTime,
  },

  props: {
    value: {
      type: [String, Number, Boolean],
      default: () => {},
    },
    filter: {
      type: Object,
      default: () => {},
    },
    report: {
      type: Object,
      default: () => {},
    },
  },

  data () {
    return {
      loading: false,
      content: '',
    }
  },

  computed: {
    viewUrl () {
      return '/compatibility/' + this.modelName + '/' + this.viewName
    },

    filterValues () {
      return this.report.filterValues
    },
  },
}
</script>
