<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-for="(chart, i) in [...Array(4)]"
        :key="i"
        :style="'height: ' + (blockHeight * 1.5) + 'px;'"
        cols="12"
        lg="6"
      >
        <KPI_ModalChart
          :layout="layout"
          :title="layout.chartNames[i]"
          :chart="charts[i]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import KPI_ModalChart from '../KPI_ModalChart'
export default {
  name: 'Layout_4grid',
  components: { KPI_ModalChart },
  props: {
    layout: {
      type: Object,
      default: () => {},
    },
    charts: {
      type: Array,
      default: () => {},
    },
    blockHeight: {
      type: Number,
      default: 300,
    }
  },
}
</script>
