import methods from './../methods'
import util from '../../utilities/sharedUtilities'
import listViewAPI from '@/store/api'
import listFilters from './../../store/_listFilters'
import Confirm from '@/methods/confirm'
import BreakdownDataProcessor from './BreakdownDataProcessor'
import BreakdownViewLine from '@/components/BreakdownView/BreakdownViewLine.vue'
import ListHiddenFilters from '@/components/ListHeaderActions/ListHiddenFilters.vue'
import { createHelpers } from 'vuex-map-fields'
import state from '@/store/state'
import breakdownMethods from '@/components/BreakdownView/breakdownMethods'
import breakdownExcel from '@/components/BreakdownView/breakdownExcel'
import sharedComputed from '@/sharedComputed'
import { AxiosResponse } from 'axios'
import { LP } from '@/types/LP.types'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

const BREAKDOWN_ATTRIBUTE_TYPES = ['price', 'quantity', 'boolean', 'numeric', 'decimal', 'percentage']

const dateOptionsForDateField = (name, translation, i18n) => {
  return {
    [name]: translation,
    [name + '@daily']: translation + ' - ' + i18n.t('aava.index.time_categories.daily'),
    [name + '@weekday']: translation + ' - ' + i18n.t('aava.index.time_categories.weekday'),
    [name + '@weekly']: translation + ' - ' + i18n.t('aava.index.time_categories.weekly'),
    [name + '@monthly']: translation + ' - ' + i18n.t('aava.index.time_categories.monthly'),
    [name + '@quarterly']: translation + ' - ' + i18n.t('aava.index.time_categories.quarterly'),
    [name + '@yearly']: translation + ' - ' + i18n.t('aava.index.time_categories.yearly'),
  }
}

const datetimeOptionsForDatetimeField = (name, translation, i18n) => {
  const timeOptions = {
    [name + '@hourly']: translation + '- ' + i18n.t('aava.index.time_categories.hourly'),
    [name + '@part_of_day']: translation + ' - ' + i18n.t('aava.index.time_categories.part_of_day')
  }
  return { ...timeOptions, ...dateOptionsForDateField(name, translation, i18n) }
}

const totalsCollectionDefault = {
  items: {
    counts: {},
    ids: [],
    subgroups: [],
    sums: {}
  }
}

export default {
  name: 'BreakdownView',

  components: {
    BreakdownViewLine,
    ListHiddenFilters
  },

  data () {
    return {
      cancelling: false,
      count: null,
      initialized: false,
      loadingData: false,
      loadProgressPc: 0,
      pagesLoaded: 0,
      refreshing: false,
      saving: false,
      selectedAttributes: [],
      selectedAttributesLimit: 4,
      totalsCollection: totalsCollectionDefault,
      generationTime: '',
      sortColumn: null,
      sortOrder: null,
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),
    ...sharedComputed,

    hasFilters () {
      return this.layoutProfileItems.filter((field: LP.Item) => this.listFilters[field.name]).length > 0
    },

    attributeOptionsByFieldName () {
      let options = {}
      this.layoutProfileItems
        .filter((field: LP.Item) => field.type !== 'has_many_reference')
        .forEach((field: LP.Item) => {
          const translation = this.$i18n.t(this.objectClass + '.attributes.' + field.name)
          if (field.type === 'date') {
            options = { ...options, ...dateOptionsForDateField(field.name, translation, this.$i18n) }
          } else if (field.type === 'datetime') {
            options = {
              ...options,
              ...datetimeOptionsForDatetimeField(field.name, translation, this.$i18n)
            }
          } else {
            options = {
              ...options,
              ...{
                [field.name]: translation
              }
            }
          }
        })
      return options
    },

    attributeOptionsArray () {
      interface option {
        name: string,
        translation: string,
      }
      const options: option[] = []
      Object.keys(this.attributeOptionsByFieldName).forEach(name => {
        options.push({
          name,
          translation: this.attributeOptionsByFieldName[name]
        })
      })
      return options.sort((a, b) => (a.translation > b.translation)
        ? 1
        : ((b.translation > a.translation) ? -1 : 0))
    },

    buttonDisabled () {
      const filtersSet = this.selectedAttributes.length && !!this.selectedAttributes[0]
      return !filtersSet || this.saving || this.refreshing || this.cancelling || this.loadingData ||
        this.count === 0
    },

    buttonLoading () {
      return this.saving || this.refreshing || this.cancelling || this.loadingData
    },

    breakdownAttributes () {
      return this.layoutProfileItems
        .filter((field: LP.Item) => field.visible && BREAKDOWN_ATTRIBUTE_TYPES.includes(field.type))
        .map((field: LP.Item) => field.name)
    },

    breakdownDateCategory () {
      return this.selectedAttributes
        .filter(attribute => attribute && attribute.includes('@'))
        .join(',')
    },

    breakdownGrouping () {
      return this.selectedAttributes
        .filter(attribute => attribute)
        .map(attribute => attribute.split('@')[0])
        .join(',')
    },

    layoutProfileItemsByName () {
      return this.$store.getters.layoutProfileItemsByName
    },

    selectedAttributesCombinedTitle () {
      return this.selectedAttributes.filter(option => option)
        .map(option => this.attributeOptionsByFieldName[option]).join(' / ')
    },

    pageSize () {
      if (this.count / 10 < 25) {
        return 25
      } else if (this.count / 10 > 100) {
        return 100
      }
      return Math.floor(this.count / 10)
    },

    pages () {
      return Math.ceil(this.count / this.pageSize)
    },

    queries () {
      const queries = [...this.breakdownAttributes,
        ...this.selectedAttributes
          .filter(attribute => attribute)
          .map(attribute => attribute.split('@')[0])]
      // Get unique values of queries (if both in breakdown attributes and in selected attributes)
      // Otherwise api will return values * 2, if same query parameter is twice
      return Array.from(new Set(queries))
    },

    filters () {
      let filters = [...listFilters.getSearchFilters(this.$store.state),
        ...[{
          key: 'breakdown_grouping',
          value: this.breakdownGrouping
        }, {
          key: 'breakdown_date_category',
          value: this.breakdownDateCategory
        }]]

      if (this.listTargetAssoc) {
        filters = [...filters,
          { key: '_target_resources', value: this.listTargetResources },
          { key: '_target_id_or_token', value: this.listTargetId },
          { key: '_target_member', value: this.listTargetAssoc }
        ]
      }

      return filters
    },

    hiddenFields () {
      return this.layoutProfileItems.filter((field: LP.Item) => {
        return this.listFilters[field.name] && (!field.visible || this.layoutProfileView !== 'list')
      })
    },

    // Get distinct units by breakdown attributes
    // So can hide it, if is same for all the rows
    singleUnitAttributes () {
      return this.breakdownAttributes.filter(attr => {
        return this.countAttributeUnit(attr)
      })
    },
  },

  watch: {
    selectedLayoutProfileId (value) {
      if (!value) { return }
      this.initialize()
    },
  },

  created () {
    this.initialize()
  },

  mounted () {
    window.addEventListener('beforeprint', this.beforePrintFilter)
    window.addEventListener('afterprint', this.afterPrintFilter)
  },

  beforeDestroy () {
    window.removeEventListener('beforeprint', this.beforePrintFilter)
    window.removeEventListener('afterprint', this.afterPrintFilter)
  },

  methods: {
    ...methods,
    ...breakdownMethods,
    ...breakdownExcel,

    headerClicked (column) {
      // default initial sort order of a column to desc
      if (!this.sortColumn || (column !== this.sortColumn)) { this.sortOrder = null }

      this.sortColumn = column
      this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc'
    },

    excelContent () {
      const source = this.$i18n.t('layout_profiles:show.title') + ': ' + this.selectedLayoutProfile.name
      const filters = Object.keys(this.listFilters).map(fieldName => {
        const value = fieldName === 'main_object_state'
          ? this.$store.getters.listFiltersGetStateDisplayValue
          : this.listFilters[fieldName]

        return this.$i18n.t(this.objectClass + '.attributes.' + fieldName) + ': ' + value
      })
      const opts = {
        objectClass: this.objectClass,
        selectedAttributes: this.selectedAttributes.filter(option => option)
          .map(option => this.attributeOptionsByFieldName[option]),
        breakdownAttributes: this.breakdownAttributes,
        totalsCollection: this.totalsCollection,
        listFilters: this.listFilters,
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder,
        comments: [source, ...filters],
      }
      this.createExcelFile(opts)
    },

    beforePrintFilter () {
      const body = document.querySelector('body') as HTMLBodyElement
      body.style.visibility = 'hidden'
    },

    afterPrintFilter () {
      const body = document.querySelector('body') as HTMLBodyElement
      body.style.visibility = 'visible'
    },

    printContent () {
      const body = document.querySelector('body') as HTMLBodyElement
      body.style.visibility = 'hidden'
      window.print()
      body.style.visibility = 'visible'
    },

    hiddenFieldValue (fieldName) {
      if (fieldName === 'main_object_state') {
        return this.$store.getters.listFiltersGetStateDisplayValue
      }
      return this.listFilters[fieldName]
    },

    countCellStyle (type) {
      return {
        width: (99.99 / this.breakdownAttributes.length) + '%',
        textAlign: ['quantity', 'price'].includes(type) ? 'right' : 'left'
      }
    },

    initialize () {
      this.initialized = false
      this.resetBreakdownData()
      this.$store.dispatch('loadListLayoutProfileItems').then(() => {
        this.setSelectedAttributesFromConfig()
        this.loadBreakdownCount().then(() => { this.initialized = true })
      })
    },

    setSelectedAttributesFromConfig () {
      const config = this.selectedLayoutProfile.timelineConfiguration
      this.selectedAttributes = config?.breakdown_configuration?.orderColumns
        ? config.breakdown_configuration.orderColumns.filter((i) => i)
        : this.selectedAttributes
    },

    loadBreakdownCount () {
      return new Promise(resolve => {
        listViewAPI.fetchListItems(this.objectClass, this.filters, [],
          { count: true }
        ).then((result: AxiosResponse) => {
          this.count = result.data.count
          resolve(true)
        })
      })
    },

    loadBreakdownItems () {
      const maxItems = (this.systemConfigs && this.systemConfigs.breakdownWarnLimit) || 10000
      if (this.count > maxItems) {
        Confirm.request(this.$i18n.t('aava.confirmations.big_breakdown'), () => {
          this.startBreakdownItemsLoad()
        })
      } else {
        this.startBreakdownItemsLoad()
      }
    },

    startBreakdownItemsLoad () {
      this.resetBreakdownData()
      this.loadingData = true
      this.loadProgressPc = 1
      this.loadBreakdownItemsOnPages({ pageNo: 1 })
    },

    resetBreakdownData () {
      this.totalsCollection = util.clone(totalsCollectionDefault)
      this.loadingData = false
      this.loadProgressPc = 0
    },

    loadBreakdownItemsOnPages ({ pageNo }) {
      if (this.cancelling) {
        this.cancelling = false
        this.resetBreakdownData()
        return
      }
      // User filters set in list view and add offset + limit
      const filters = [...this.filters,
        { key: 'offset', value: (pageNo * this.pageSize) - (this.pageSize) },
        { key: 'limit', value: this.pageSize },
        { key: 'order', value: 'created_by asc' },
      ]
      this.$store.dispatch('loadBreakdownItems', {
        filters,
        queries: this.queries,
      })
        .then((result: AxiosResponse) => {
          this.loadProgressPc = 1 + (99 / this.pages) * pageNo
          if (!result.data.items) {
            this.loadingData = false
            this.resetBreakdownData()
            // Errors are shown by 'globalErrorDisplay'
          } else {
            // Merge page data
            BreakdownDataProcessor._mergeCollection(result.data, this.totalsCollection)
            if (pageNo < this.pages) {
              // Load  next page
              this.loadBreakdownItemsOnPages({ pageNo: pageNo + 1 })
            } else {
              // Set all loaded (load progress percent = 100)
              if (this.totalsCollection.items.ids.length > 0) {
                this.loadProgressPc = 100
              }
              setTimeout(() => {
                // Give user slight glance to see loader bar 100%
                this.loadingData = false
              }, 300)
            }
          }
        })
    },

    saveConfig () {
      this.saving = true
      this.$store.dispatch('saveBreakdownConfig', {
        orderColumns: this.selectedAttributes
      }).then(() => {
        this.saving = false
      })
    },

    requestCancel () {
      this.loadingData = false
      this.cancelling = true
      this.resetBreakdownData()
    },

    loadBreakdown () {
      this.generationTime = util.currentDateTime(this.locale)
      this.saveConfig()
      this.loadBreakdownCount().then(() => {
        this.loadBreakdownItems()
      })
    },
  },
}
