<template>
  <v-menu
    :z-index="160"
    offset-y
    close-on-click
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="'#ddd'"
        class="text-none"
        text
        tile
        depressed
        v-bind="attrs"
        v-on="on"
      >
        <i class="fa fa-arrows-alt-v icon-right-space" />
        {{ btnText }}
      </v-btn>
    </template>
    <div class="popup-items-container">
      <div
        v-for="lanes in options"
        :key="lanes"
        class="popup-menu-el"
        @click="clickHandler(lanes)"
      >
        <v-list-item-title>{{ lanes }}</v-list-item-title>
      </div>
    </div>
  </v-menu>
</template>

<script>
import methods from '@/components/methods'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ListTextLanesMenu',

  data () {
    return {
      options: [1, 2, 3, 4, 5, 10, 15, 20],
    }
  },

  computed: {
    ...mapFields(Object.keys(state)),

    btnText () {
      return this.textLanes + ' ' +
        (this.$vuetify.breakpoint.lgAndUp && this.splitMode !== 'vertical'
          ? this.textLanes > 1
            ? this.$i18n.t('aava.index.lines')
            : this.$i18n.t('aava.index.line')
          : '')
    },
  },

  methods: {
    ...methods,

    clickHandler (lanes) {
      this.setTextLanes(lanes, { byUser: true })
      this.resetOverflowingTextIndicators()
    },
  },
}
</script>
