<template>
  <div
    :style="{
      paddingTop: images.length
        ? '3px' // Badge is vertically in the center for 1 content row
        : '',
    }"
  >
    <div
      v-if="images.length > 1"
      class="has-many-count-round mt-1"
    >
      {{ images.length }}
    </div>
    <Images
      v-model="images"
    />
  </div>
</template>

<script>
import Images from '@/components/Form/Images'

export default {
  name: 'ContentImages',

  components: { Images },

  props: {
    field: {
      type: Object,
      default: () => {}
    },
    cellValue: {
      type: Object,
      default: () => {}
    },
  },

  computed: {
    config () {
      return this.$store.state.config
    },

    images: {
      get () {
        return this.cellValue && this.cellValue.value || []
      },
      set (value) {
        this.$set(this.cellValue, 'value', value)
      },
    },
  },
}
</script>
