import clearCache from '@/utilities/clearCache'

export default {
  sortByHeaderField (fieldName, event, direction = null) {
    // Skip if Layout Profile is locked
    if (this.selectedLayoutProfile.locked) {
      // return
    }
    if (this.blurHeaderSearchInput) {
      this.blurHeaderSearchInput(fieldName)
    }
    this.$store.commit('updateValues', {
      showHeaderColumnStatesMenu: false,
      showHeaderColumnUniqueValuesMenu: false
    })
    document.getElementById('vueList').scrollTop = 0
    this.$store.dispatch('sortByField', {
      fieldName,
      secondary: event.ctrlKey || event.metaKey,
      direction,
      updateRemote: !this.selectedLayoutProfile.locked,
    })
  },

  listFiltersRemove (fieldName) {
    this.focusedFieldFilterValue = ''
    this.$store.dispatch('removeListFilter', fieldName).then(() => {
      this.showHeaderColumnStatesMenu = false
      this.showHeaderColumnUniqueValuesMenu = false
      this.reloadListItems()
    })
  },

  listFiltersRemovePermanent (fieldName) {
    clearCache('lp_data_' + this.selectedLayoutProfileId)
    this.focusedFieldFilterValue = ''
    this.$store.dispatch('removeListPermanentFilter', fieldName).then(() => {
      if (this.$store.state.layoutProfileView === 'list') {
        this.reloadListItems()
      }
    })
  },

  listFiltersAddPermanent (fieldName) {
    clearCache('lp_data_' + this.selectedLayoutProfileId)
    this.$store.dispatch('saveListPermanentFilter', fieldName).then(() => {
      this.reloadListItems()
    })
  }
}
