<template>
  <div class="flash-messages">
    <div
      v-for="(message, index) in flashMessages"
      :key="index"
      class="flash-message"
      @click="clickHandler(message)"
    >
      <v-alert
        :type="message.type === 'flash' ? 'info' : message.type"
        :class="message.type + '-flash-message'"
        prominent
      >
        <v-container class="fill-height pa-0">
          <v-row class="center align-content-start">
            <div
              v-if="message.type === 'error'"
              class="error-display-date-time"
            >
              {{ errorDisplayDateTime }}
            </div>
            <v-col
              :class="'grow ' + (message.type === 'error' ? 'pb-5' : '')"
              v-html="message.message || message.text"
            />
            <v-col
              v-if="!message.hideCloseButton"
              class="shrink fill-height align-content-start"
            >
              <v-btn
                :icon="message.type !== 'flash'"
                :color="message.type === 'flash' ? '#aaa' : ''"
                class="elevation-0"
              >
                <template
                  v-if="message.type === 'flash'"
                >
                  OK
                </template>
                <v-icon v-else>
                  fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-alert>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import api from '../../store/api'
import state from '../../store/state'
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField'
})

export default {
  name: 'FlashMessages',

  computed: {
    // TODO - do need whole state here?
    ...mapFields(Object.keys(state)),

    errorDisplayDateTime () {
      return moment().format('DD.MM.YYYY HH.mm.ss')
    },

    // For testing style
    // flashMessages2 () {
    //   return [
    //     { message: 'Objektin päivitys onnistui!', type: 'success', expires: 2000, hideCloseButton: true, key: '0.b3vjyz1nioh' },
    //     // { message: 'Objektin päivitys onnistui!', type: 'success', expires: 2000, hideCloseButton: true, key: '0.b3vjyz1nioh' },
    //   ]
    // },
  },

  methods: {
    clickHandler (message) {
      if (message.type === 'flash') {
        api.sendFlashMessageReceipt(message)
      }
      this.$store.dispatch('clearFlashMessageByKey', message.key)
    },
  }
}
</script>

<style lang="scss">
.flash-messages {
  cursor: pointer;
  min-width: 400px;
  max-width: 800px;
  position: absolute;
  top: 150px;
  right: 20px;
  z-index: 1010;
  .v-alert {
    padding: 14px !important;
    border-radius: 8px !important;
    .row {
      position: relative;
      .error-display-date-time {
        font-style: italic;
        font-size: 12px;
        position: absolute;
        bottom: 0;
        right: 5px;
      }
    }
  }
}
</style>
