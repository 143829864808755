<template>
  <v-btn
    :color="'orange darken-1'"
    class="text-none"
    text
    @click.stop="clearSearch"
  >
    <i class="fa fa-eraser icon-right-space" />
    {{ btnText }}
  </v-btn>
</template>

<script>
import methods from '@/components/methods'
import state from '@/store/state'
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'getField',
  mutationType: 'updateField',
})

export default {
  name: 'ListClearSearchButton',

  computed: {
    ...mapFields(Object.keys(state)),

    btnText () {
      return this.$vuetify.breakpoint.lgAndUp && this.splitMode !== 'vertical'
        ? this.$i18n.t('aava.index.clear_search')
        : ''
    },
  },

  methods: {
    ...methods,

    clearSearch () {
      this.$store.dispatch('clearTemporarySearchFilters')
      this.reloadListItems()
    },
  },
}
</script>
