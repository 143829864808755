import Vue from 'vue'
import storeShared from './../../_storeShared'
import util from '../../../utilities/sharedUtilities'
import listQueries from '@/store/_listQueries'
import listFilters from './../../_listFilters'
import router from './../../../router'
import api from '@/store/api'
import i18n from '@/locale/i18n'
import { EventAction } from '@/types/EventAction'
import { Types } from '@/types/AppTypes'
import { AxiosResponse } from 'axios'
import { ListEditSaveJob } from '@/types/ListEdit'

// Set payload data in case user is saving with event action button
// In this case we may want to ask for user input for one action parameter
// And also handle user confirmation
const setSaveItemPayloadData = (
  event: EventAction,
  filters: object,
  executeEventName: string | undefined,
  confirmations: string | undefined,
  actionParameters: object
) => {
  if (executeEventName) {
    filters['~execute_event'] = executeEventName
    filters['~keep_token'] = 'true'
    filters['~execute_event_params'] = JSON.stringify(actionParameters)
    // TODO - old portal sends DELETE as an additional request, why?
  }
  if (confirmations) {
    // Ask from the server if confirmation is needed
    // When needed, ask user confirmation with askAndSendEventActionConfirmationForSaveIfNeeded
    // when confirmed, same method is called again with confirmations = confirm
    filters['~confirmations'] = confirmations
    // Ask for user to input value for one action parameter
    // Technically supporting user-action-parameter-prompt and confirmation
    // though haven't found a real life case for this.
    if (confirmations === 'required') { // Only prompt user input on first saveItem
      actionParameters = event?.query?.identifier
        ? { [event.query.identifier]: prompt(event.query?.title!, event.query?.default!) }
        : {}
      if (Object.values(actionParameters).some(parameter => parameter === null)) {
        // User clicked cancel when prompted and probably doesn't want to continue.
        return false
      }
    }
  }
  return true
}

export interface saveActionPayload {
  resource: string,
  item: Types.Item,
  queries?: string[],
  filters?: string[],
  executeEventName?: string,
  event: EventAction,
  confirmations?: string,
  actionParameters?: object,
  showMessage?: boolean,
}

export default {
  // Save item data over the api
  // Also supports sending event action and asking for user confirmation
  // It is a bit overcomplicated, the way confirmation works, ideally UI should already know
  // if user confirmation is needed. Not the best solution to send same save api call twice :(
  // this is handling event action in edit view, similar logic is in show or list view,
  // where sendProcessEvent is used
  saveItem: ({ dispatch }, {
    resource,
    item,
    queries = [],
    filters = [],
    executeEventName = '',
    event,
    confirmations = 'required',
    actionParameters = {},
    showMessage = true,
  }: saveActionPayload) => {
    return new Promise(resolve => {
      if (!setSaveItemPayloadData(event, filters, executeEventName, confirmations, actionParameters)) {
        // User cancelled from prompt dialog
        return resolve(null)
      }
      api.saveItem(resource, item, queries, filters)
        .then(originalResult => {
          dispatch('askAndSendEventActionConfirmationForSaveIfNeeded', {
            resource, item, queries, filters, executeEventName, event, originalResult, actionParameters,
          }).then((finalResult: AxiosResponse | false) => {
            dispatch('globalErrorDisplay', { response: finalResult, context: 'save item ' + resource + '/' + (item.id || item.token) })
            if (confirmations !== 'confirm') { // Avoid doing everything double, when action needs confirmation
              if (finalResult && finalResult.data?.status === 'ok' && showMessage) {
                dispatch('addFlashMessage', {
                  message: item.id ? i18n.t('aava.messages.object_updated') : i18n.t('aava.messages.object_created'),
                  type: 'success',
                  expires: 2000,
                  hideCloseButton: true,
                })
              }
            }
            resolve(finalResult)
          })
        })
    })
  },

  // In case of event action needs user confirmation - item edit form
  // ask user confirmation and send saveItem second time with ©confirmations = confirm
  // Bad and complex solution, but no good alternative for now
  askAndSendEventActionConfirmationForSaveIfNeeded: ({ dispatch }, {
    resource, item, queries, filters, executeEventName, event, originalResult, actionParameters,
  }) => {
    return new Promise(resolve => {
      const message = originalResult?.data?.messages?.[0]?.text
      if (originalResult.status === 406 && message) {
        if (window.confirm(message)) {
          const confirmations = originalResult?.data?.messages?.[0]?.context || 'confirm'
          dispatch('saveItem', {
            resource,
            item,
            queries,
            filters,
            executeEventName,
            event,
            actionParameters,
            confirmations,
            showMessage: false,
          })
            .then(confirmedResult => {
              resolve(confirmedResult)
            })
        } else {
          resolve(false)
        }
      } else {
        resolve(originalResult)
      }
    })
  },

  setStoredIndex: ({ state }, { className, id }) => {
    if (!className || !id) { return }
    if (className !== state.objectClass) { return }
    const conditions = {};
    [
      ...listFilters.getSearchFilters(state),
      ...listFilters.getSuperSearchFilters(state.superSearchTerm),
    ].forEach(filter => {
      conditions[filter.key.substring(1)] = filter.value
    })
  },

  listItemOpenById: ({ state, dispatch }, {
    className,
    id,
    // When opening target item from list view
    // It's not possible to keep targetItem and split mode for another class
    // active at the same time, so we need to skip target item check from router params
    skipTargetItem,
  }) => {
    return new Promise(resolve => {
      dispatch('setStoredIndex', {
        className: className || state.objectClass,
        id
      })
      state.selectedId = !className || className === state.objectClass
        ? id
        : null
      const routerProps = router.currentRoute.params
      if (!skipTargetItem && routerProps?.targetResource && routerProps?.targetId) {
        router.push({
          path: '/' + (className || state.objectClass) + '/for/' +
          routerProps.targetResource + '/' +
          routerProps.targetId + '/' +
          routerProps.targetField + '/' +
          id + '/show'
        })
      } else {
        router.push({ path: '/' + (className || state.objectClass) + '/' + id })
      }
      resolve(true)
    })
  },

  loadHasManyListFor: ({ state }, { forId, field }) => {
    const queries = [field.reference_attribute, '@association_style']
    return api.loadHasManyListFor(
      field.reference_class,
      field.name,
      state.objectClass,
      forId,
      queries,
      state.hasManyListItemsFetchCount
    )
  },

  showHasManyListItems: ({ state, dispatch, getters }, { items, field, listItem }) => {
    return new Promise(resolve => {
      if (getters.hasManyFieldsWithData.includes(field.name)) {
        Vue.nextTick(() => {
          state.hasManyListItems = items
          state.showHasManyListMenu = true
          resolve(true)
        })
      } else {
        Vue.nextTick(() => {
          dispatch('loadHasManyListFor', {
            forId: listItem.id,
            field,
          }).then((response: AxiosResponse) => {
            state.hasManyListItems = response.data.items ?? []
            state.hasManyListItemsCount = response.data.total ?? 0
            state.showHasManyListMenu = true
            Vue.nextTick(() => {
              resolve(true)
            })
          })
        })
      }
    })
  },

  // In case of processEvent needs user confirmation
  // ask user confirmation and send sendProcessEvent second time with ©confirmations = confirm
  askAndSendEventActionConfirmationIfNeeded: ({ dispatch }, {
    objectClass, id, event, index, view, originalResult, parent,
  }: {
    objectClass: string,
    id: number | string,
    originalResult: AxiosResponse,
    view: string,
    event: EventAction,
    index: number,
    parent: Types.Item,
  }): Promise<AxiosResponse | null> => {
    return new Promise(resolve => {
      const message = originalResult?.data?.messages?.[0]?.text
      if (originalResult.status === 406 && message) {
        if (window.confirm(message)) {
          const confirmations = originalResult?.data?.messages?.[0]?.context || 'confirm'
          dispatch('sendProcessEvent', {
            id,
            view,
            index,
            event,
            objectClass,
            confirmations,
            parent,
          })
            .then((confirmedResult: AxiosResponse) => {
              resolve(confirmedResult)
            })
        } else {
          resolve(null)
        }
      } else {
        resolve(originalResult)
      }
    })
  },

  sendProcessEvent: ({ state, dispatch }, {
    objectClass, id, event, index,
    view = 'list',
    // For first request default is 'required'
    // When confirmation is required, sendProcessEvent is called again with confirmations = confirm
    confirmations = 'required',
    actionParameters,
    parent,
  }: {
    objectClass: string,
    view: string,
    id: string | number,
    event: EventAction,
    index: number,
    confirmations: string,
    actionParameters: object,
    parent: Types.Item,
  }): Promise<AxiosResponse | null> => {
    // Note - when confirmation is required, this action is called twice
    // First to send action and get failed response back that confirmation is needed
    // Then same action again with confirmation
    return new Promise(resolve => {
      if (!state.sendingEventActionForIds.includes(id)) { // On has-many list it is set before, as queue is used and loader is needed
        Vue.set(state.sendingEventActionForIds, state.sendingEventActionForIds.length, id)
      }
      // Queries are included to update item in the list without requesting item data with additional api call
      // Old portal does it differently: request 1 is for event action, request 2 is to update whole list
      let queries = listQueries.get(state.layoutProfileItems, state.selectedLayoutProfile, {
        locale: state.locale,
      }).map(query => {
        return { key: 'q[]', value: query }
      })
      if (view !== 'list') {
        queries = [{ key: 'q', value: '!id' }]
      }
      api.sendProcessEvent(objectClass, id, event.identifier, queries, confirmations, actionParameters, parent)
        .then((originalResult: AxiosResponse) => {
          // Some event actions may need additional user confirmation
          // finalResponse is sent to the original sendProcessEvent which will take care of error handling, success message etc
          dispatch('askAndSendEventActionConfirmationIfNeeded', {
            objectClass, id, event, index, view, originalResult, parent,
          }).then((finalResult: AxiosResponse | null) => {
            dispatch('globalErrorDisplay', { response: finalResult, context: 'Send event action ' + objectClass + '/' + id + ' ' + event.identifier })
            Vue.delete(state.sendingEventActionForIds,
              state.sendingEventActionForIds.indexOf(id)
            )
            if (confirmations !== 'confirm') { // Avoid doing everything double, when action needs confirmation
              if (finalResult?.data.status === 'ok') {
                if (view === 'list') {
                  Vue.set(state.listItems, index, storeShared.completeListItemData(finalResult.data.item))
                }
                dispatch('addFlashMessage', {
                  message: i18n.t('aava.messages.object_updated'),
                  type: 'success',
                  expires: 2000,
                  hideCloseButton: true,
                })
              }
            }
            resolve(finalResult)
          })
        })
    })
  },

  // 1. From Item view - sendProcessEventOrAction
  // 2. From Item view - sendProcessActionAfterEditSave
  //    Special case:
  //    processAction after save, but before item data re-load
  // 3. From any other case - sendProcessEventForContentItem
  dispatchProcessEventOrAction: ({ dispatch }, { event, item, view, index, objectClass, parent }: {
    event: EventAction,
    item: Types.Item,
    view: 'list' | 'item',
    index: number,
    objectClass: string,
    parent: Types.Item,
  }): Promise<AxiosResponse | null> => {
    return new Promise(resolve => {
      // When message is prompted before event
      // + ask user input
      const promptMessage = (
        event.query?.title || // For processEvent
        event.query?.label // For processAction
      ) ?? ''
      const actionParameters = event?.query?.identifier
        ? { [event.query.identifier]: prompt(promptMessage, event.query?.default!) }
        : {}
      if (Object.values(actionParameters).some(parameter => parameter === null)) {
        // User clicked cancel when prompted and probably doesn't want to continue.
        return resolve(null)
      }

      const dispatchArgs = event.type === 'processEvent'
        ? ['sendProcessEvent', { ...item, id: item.id, objectClass, event, view, index, actionParameters, parent }]
        : ['sendProcessAction', { id: item.id, action: event, actionParameters, objectClass, parent }]
      dispatch(...dispatchArgs).then(resolve)
    })
  },

  sendProcessAction: ({ state, dispatch }, { id, action, actionParameters, objectClass, parent }: {
    id: number | string,
    action: EventAction,
    actionParameters: object,
    objectClass: string,
    parent: Types.Item,
  }): Promise<AxiosResponse> => {
    return new Promise(resolve => {
      const params = { ids: [id], value: actionParameters }
      api.sendApiActionCall(objectClass, action.identifier, params, parent).then((response: AxiosResponse) => {
        if (state.sendingEventActionForIds.includes(id)) {
          Vue.delete(state.sendingEventActionForIds,
            state.sendingEventActionForIds.indexOf(id)
          )
        }
        dispatch('globalErrorDisplay', { response, context: 'Send action ' + objectClass + ' ' + action.identifier })
        resolve(response)
      })
    })
  },

  updateItemInTheListById: ({ state, dispatch }, id) => {
    // If no id - refresh list
    if (!id) {
      dispatch('reloadListItems', { updateCounts: true })
      return
    }
    // Load list item data
    api.fetchListItems(
      state.objectClass,
      { _unique_ids: id },
      listQueries.get(state.layoutProfileItems, state.selectedLayoutProfile, {
        locale: state.locale,
      }), {}
    ).then((response: AxiosResponse) => {
      if (response.data.items?.[0]) {
        const item = response.data.items[0]
        if (!item) { return }
        const index = state.listItems.findIndex((listItem: Types.Item) => listItem && listItem.id === item.id)
        if (index > -1) {
          Vue.set(state.listItems, index, storeShared.completeListItemData(item))
        }
      }
    })
  },

  updateListItemDataAfterCellEdit: ({ state }, { item, job }: {
    item: Types.Item,
    job: ListEditSaveJob,
  }) => {
    if (!item?.id) {
      return
    }
    const index = state.listItems.findIndex((listItem: Types.Item) => listItem && listItem.id === item.id)
    const listItem = state.listItems[index]
    if (index > -1) {
      item = storeShared.completeListItemData(item)
      // Field by field
      Object.keys(item).forEach(fieldName => {
        const apiValue = item[fieldName]
        const currentValue = listItem[fieldName]
        const apiValueBefore = state.listEdit.serverItems[item.id!]?.[fieldName]
        const valueBeforeSave = job.itemDataBeforeSave[fieldName]
        // const valueOnJobCreate = job.itemDataOnJobCreate[fieldName]
        // Special checks for the field that was changed
        if (fieldName === job.field.name) {
          console.log('__From-server__', apiValue)
          const valueChangedByUser = util.isItemFieldValueChanged(job.field, currentValue, valueBeforeSave)
          if (!valueChangedByUser) {
            Vue.set(listItem, fieldName, apiValue)
          } else {
            console.log('SKIP - changed_by_the_user?', valueChangedByUser, valueBeforeSave, currentValue)
          }
        } else if (JSON.stringify(apiValue) !== JSON.stringify(apiValueBefore)) {
          // const proceed = currentValue === valueBeforeSave && valueBeforeSave === valueOnJobCreate
          // console.log('c h a n g e d _ v a l u e', fieldName,
          //  'current:', currentValue, 'api:', apiValue, 'apiValueBefore:', apiValueBefore, 'beforeSave:', valueBeforeSave)
          const valueChangedByUser = util.isItemFieldValueChanged(job.field, currentValue, apiValueBefore)
          // TODO or saving?
          // const isInQueue = getters.listEditCellQueueKeys.includes(job.id + '_' + fieldName)
          const isInQueue = false
          if (!valueChangedByUser && !isInQueue) {
            Vue.set(listItem, fieldName, apiValue)
          } else if (isInQueue) {
            // console.log('::SKIP -in queue', fieldName)
          } else {
            // console.log(':::SKIP - valueChangedByUser new value: ', apiValue, ' for ', fieldName, ' value form ', apiValueBefore, ' to ', currentValue)
          }
        }
      })
      // Update server item
      state.listEdit.serverItems[item.id!] = JSON.parse(JSON.stringify(item))
    }
  },
}
