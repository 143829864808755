<template>
  <v-container fluid>
    <v-row>
      <v-col
        :style="'height: ' + (blockHeight * 2) + 'px;'"
        cols="12"
      >
        <KPI_ModalChart
          :layout="layout"
          :title="layout.chartNames[0]"
          :chart="charts[0]"
        />
      </v-col>
      <v-col
        :style="'height: ' + blockHeight + 'px;'"
        cols="12"
      >
        <KPI_ModalChart
          :layout="layout"
          :title="layout.chartNames[1]"
          :chart="charts[1]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import KPI_ModalChart from '../KPI_ModalChart'
export default {
  name: 'Layout_1LargeWithHorizontalGauges',

  components: {
    KPI_ModalChart,
  },

  props: {
    layout: {
      type: Object,
      default: () => {},
    },
    charts: {
      type: Array,
      default: () => {},
    },
    blockHeight: {
      type: Number,
      default: 300,
    }
  },
}
</script>
