
import colorPresets from '@/components/App/colorPresets'

export default {
  name: 'ColorPicker',

  props: {
    value: {
      type: String,
      default: undefined,
    },
    colorPreset: {
      type: String,
      default: 'buttonBg',
    },
    buttonStyle: {
      type: String,
      default: 'regular',
    },
  },

  data () {
    return {
      colorPicker: false,
    }
  },

  computed: {
    valueComputed: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value || '#AAAAAA'
      },
    },

    presetColors () {
      return colorPresets[this.colorPreset]
    },
  },

  methods: {
    select (color) {
      this.valueComputed = color
      this.$emit('change')
    },
  },
}
