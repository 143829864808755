<template>
  <div>
    <div
      style="border-radius: 50px;
                  padding: 1px 10px 1px 45px;
                  border: 0 solid #ddd;
                  position: absolute;
                  bottom: 0;
                  left: 130px;
                  font-weight: 700;
                  background: #ddd;
                  text-shadow: 1px 2px 4px white;
                  text-shadow: 1px 1px 2px white, 0 0 25px white, 0 0 5px white;
                  color: #444;
                  height: 16px;
                  width: 200px;
                  overflow: hidden;"
    >
      <div
        style="position: absolute;
                    left: 0;

                    top: 0;
                    text-align: right;
                    background_: rgba(76, 175, 79, 0.8);
                    background: #ccc;
                    border-radius: 50px;
                    width: 150px;
                    height: 16px;"
        class="level-bar"
      />
      {{ block.points }} / 500
    </div>
  </div>
</template>
<script>
export default {
  name: 'LevelBar',
  props: {
    block: {},
  },
}
</script>
