<template>
  <v-container
    class="pa-0 ma-0 html-with-preview"
    fluid
  >
    <template v-if="field.multi_language">
      <v-row
        v-for="(fieldLocale, index) in $store.getters.availableContentLocalesSorted"
        :key="index"
        no-gutters
      >
        <v-col
          cols="6"
          class="pr-2"
        >
          <TextArea
            v-model="item[field.name + '_' + fieldLocale]"
            :item="item"
            :field="field"
            :label="label + ' (HTML) ' + fieldLocale"
            spellcheck="false"
          />
        </v-col>
        <v-col cols="6" class="pl-1">
          <div
            class="item-show-field"
          >
            <div
              class="item-show-label"
            >
              <span>{{ label + ' (' + $i18n.t('aava.general.preview') + ') ' + fieldLocale }}</span>
            </div>
            <div class="item-show-value">
              <iframe
                :srcdoc="item[field.name + '_' + fieldLocale]"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <v-row
      v-else
      no-gutters
    >
      <v-col
        cols="6"
        class="pr-2"
      >
        <TextArea
          v-model="item[field.name]"
          :item="item"
          :field="field"
          :label="label + ' (HTML)'"
          spellcheck="false"
        />
      </v-col>
      <v-col cols="6" class="pl-1">
        <div
          class="item-show-field"
        >
          <div
            class="item-show-label"
          >
            <span>{{ label + ' (' + $i18n.t('aava.general.preview') + ') ' }}</span>
          </div>
          <div class="item-show-value">
            <iframe
              :srcdoc="item[field.name]"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import TextArea from '@/components/Form/TextArea'

export default {
  name: 'HTMLWithPreview',

  components: {
    TextArea,
  },

  props: {
    item: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: null,
    },
    field: {
      type: Object,
      default: () => {},
    },
  },

  methods: {},
}
</script>

<style lang="scss">
.html-with-preview {
  .item-show-label {
    /* Exception to use show label in edit mode */
    text-shadow:
            0 -4px 3px var(--editable-input-bg),
            0 -4px 3px var(--editable-input-bg),
            0 -4px 6px var(--editable-input-bg),
            0 -4px 6px var(--editable-input-bg),
            0 0 6px var(--editable-input-bg),
            0 0 6px var(--editable-input-bg),
            0 0 6px var(--editable-input-bg),
            0 0 3px var(--editable-input-bg),
            0 0 3px var(--editable-input-bg),
            0 0 3px var(--editable-input-bg),
            0 0 3px var(--editable-input-bg),
            0 0 3px var(--editable-input-bg),
  ;
  }
  textarea {
    height: 500px !important;
  }
  .item-show-field {
    background: white;
    margin-bottom: 12px;
  }
  iframe {
    width:100%;
    height: 482px;
    border: none;
  }
}
</style>
